import React,{Fragment,useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import List from '@material-ui/core/List';
import { Grid } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import AvatarIcon from "./../../../common/Avatar/AvatarIcon";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Loader from "../../../../assets/jss/components/Loader";
import Avatar from '@material-ui/core/Avatar';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import DialogContentText from '@material-ui/core/DialogContentText';
import Badge from '@material-ui/core/Badge';
import DialogTitle from '@material-ui/core/DialogTitle';
import Header from "./Header"
import CreateRoomDialog from "../CreateRoomDialog/CreateRoomDialog"
import {default_group,default_user,getLastDateTimeMessage} from "./../../../../utils/utils"
import { getSchoolId } from '../../../SchoolProfile/components/GlobalComponents/GlobalFields';
import "./index.css"

const useStyles = makeStyles((theme) => ({
  scrollContent: {
    height: '70vh',
    // paddingRight: "15px",
    // paddingLeft: "10px",
    overflowX: 'hidden',
    overflowY: 'hidden',
    '&:hover': {
      overflowY: "scroll",
      // background: "#f00",
   },
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  inline: {
    display: 'inline',
  },
}));

export const ConfirmationDialog=({open,handleClose,confirmationDialogAction,title="",message="",buttontitle=""})=> {
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{padding:"16px 24px 2px"}}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmationDialogAction} color="primary" style={{color:"red"}}>
            {buttontitle}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default function ChatSidebar({users={},groups={},handleCreateRoomAction,chatrooms={},me={},selectChatroomAction,deleteChatAction,roomInfoAction,leaveChatAction,goToChatroom={}}) {
  const classes = useStyles();
  let history = useHistory();
  const [openRoomDialog,setOpenRoomDialog]=useState(false);
  const [roomType,setRoomType]=useState("");
  const [selectedRoomIndex, setSelectedRoomIndex] = React.useState(null);
  const [anchorElChatroom, setAnchorElChatroom] = React.useState(null);
  const [selectedHorizonChatroom, setSelectedHorizonChatroom] = React.useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState({open:false,title:"",message:"",buttontitle:""});
  console.log(goToChatroom, chatrooms, "Chatroom")
  useEffect(()=>{
    if(history.location?.state?.chatrooms&& typeof history.location?.state?.chatrooms?.roomdetail==="object"){
      const {location:{state}}=history;
      setSelectedRoomIndex(state.chatrooms?.roomdetail?.key);
      selectChatroomAction(state.chatrooms?.roomdetail);
    }
    if(Object.keys(goToChatroom).length!==0){
      setSelectedRoomIndex(goToChatroom?.key);
      selectChatroomAction(goToChatroom);
    }
  },[chatrooms,goToChatroom])
  const getRoomKeys=(rooms)=>{
    let roomkeys = []
    if (!((rooms === undefined) || (rooms === null))) {
        roomkeys = Object.values(rooms).filter(room=> (room.visibleRoom));
    }
    roomkeys.sort((akey, bkey)=> (bkey.details.lastmsgtimestamp - akey.details.lastmsgtimestamp));
    return roomkeys;
  }
  const handleMoreHorizonClick = (event,roomdetailItem) => {
    setSelectedHorizonChatroom(roomdetailItem);
    setAnchorElChatroom(event.currentTarget);
  };

  const handleMoreHorizonClose = () => {
    setAnchorElChatroom(null);
  };
  const handleDeleteChat=()=>{
    if(selectedHorizonChatroom?.details?.isGroupChat){
      setShowConfirmationDialog({
        open:true,
        title:"Leave Group?",
        message:"Are you sure you want to leave this group?",
        buttontitle:"Leave"
      })
    } else{
      setShowConfirmationDialog({
        open:true,
        title:"Delete conversation?",
        message:"This will permanently delete the conversation history.",
        buttontitle:"Delete"
      })
    }
    setAnchorElChatroom(null);
  }
  const handleRoomInfo=()=>{
    roomInfoAction(selectedHorizonChatroom);
    setAnchorElChatroom(null);
  }
  const handleConfirmationDialog=()=>{
    if(selectedHorizonChatroom?.details?.isGroupChat){
      leaveChatAction(getSchoolId(),selectedHorizonChatroom.key,me,true,selectedHorizonChatroom.details.users);
    } else{
      deleteChatAction(selectedHorizonChatroom);
    }
    setShowConfirmationDialog({...showConfirmationDialog,open:false});
  }
  const handlePressChatrooms=(roomdetailItem)=>{
    selectChatroomAction(roomdetailItem);
    setSelectedRoomIndex(roomdetailItem.key);
    history.push({
      pathname: '/chats',
      state: { chatrooms:{roomdetail:roomdetailItem} }
    })
  }
  const renderLastMsgTitleOnChatroom=(lastMsg,roomdetailItem)=>{
    const {key}=roomdetailItem;
    const {notifications}=chatrooms;
    // console.log(notifications[key]?.count,key,notifications,"renderLastMsgTitleOnChatroom")
    let msgContent=()=>{
      if(lastMsg?.image){
         return(<div style={{display:"flex",flex:1}}>
          <ImageOutlinedIcon style={{height:18,width:18,marginRight:2}}/>
          <span style={{height:18,whiteSpace:"pre",marginRight:"20px",flex:1,overflow: "hidden",textOverflow: "ellipsis"}}>Photo</span>
          {(notifications&& notifications[key]?.count!==0)&&<Badge badgeContent={notifications[key]?.count} className="chatroom-badge" color="secondary"/>}
          </div>)
      } else if(lastMsg?.video){
          return(<div style={{display:"flex",flex:1}}>
            <VideocamOutlinedIcon style={{height:18,width:18,marginRight:2}}/>
            <span style={{height:18,whiteSpace:"pre",marginRight:"20px",flex:1,overflow: "hidden",textOverflow: "ellipsis"}}>Video</span>
            {(notifications&& notifications[key]?.count!==0)&&<Badge badgeContent={notifications[key]?.count} className="chatroom-badge" color="secondary"/>}
            </div>)
      } else{
      return(<>
          <span style={{height:18,whiteSpace:"pre",marginRight:"20px",flex:1,overflow: "hidden",textOverflow: "ellipsis"}}>
            {lastMsg?.text}
          </span>
          {(notifications&& notifications[key]?.count!==0)&&<Badge badgeContent={notifications[key]?.count} className="chatroom-badge" color="secondary"/>}
        </>)
      }
    }
    return(
      <Typography
        component="div"
        style={lastMsg===null?{height:16}:{}}
        variant="body2"
        className="chat-item-description">
        {/* roomdetailItem.details.isGroupChat&& */}
        {(lastMsg!==null)&&<Typography
            component="span"
            variant="body2"
            className={classes.inline}
            color="textPrimary">
            {`${me.id===lastMsg?.user?._id?"You":lastMsg?.user?.name}: `}
          </Typography>}
          {msgContent()}
        </Typography>
    )
  }
  const renderChatroomMenuBar=()=>{
    return(
      <Menu
        id="simple-menu"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorElChatroom}
        keepMounted
        open={Boolean(anchorElChatroom)}
        onClose={handleMoreHorizonClose}
      >
        {/* <MenuItem onClick={handleRoomInfo}>Room Info</MenuItem> */}
        <MenuItem onClick={handleDeleteChat}>
          {!selectedHorizonChatroom?.details?.isGroupChat?"Delete chat":"Leave group"}
        </MenuItem>
        {/* <MenuItem onClick={handleMoreHorizonClose}>Logout</MenuItem> */}
      </Menu>
    )
  }
  const renderChatRoomList=()=>{
    const {isloadingMessages,rooms,isLoading,notifications}=chatrooms;
    let roomkeys = getRoomKeys(rooms) || [];
    // console.log(roomkeys,"roomkeys")
    // console.log(history,selectedRoomIndex,"historyuseEffect")
    // if(isloadingMessages||isLoading){
    //   return <Loader/>;
    // }
    return(
      <Grid item id="custom-scroll" className={classes.scrollContent}>
      <List>
        {roomkeys.length!==0 && roomkeys.map(room=>{
          let userids = [];
          let roomdetailItem = room;
          Object.keys(roomdetailItem.details.users).forEach(key=> {
              if (key === undefined) return null;
              if (key !== me.id) {
                userids.push(roomdetailItem.details.users[key]);
              }
          });
          if (userids.length === 0){
              return null;
          }
          let roomName = roomdetailItem.details.isGroupChat ? roomdetailItem.details.name : userids[Object.keys(userids)[0]].display_name;
          let photourl = roomdetailItem.details.isGroupChat 
                ? (roomdetailItem.details.groupPhoto === undefined ? "" : roomdetailItem.details.groupPhoto)
                : userids[Object.keys(userids)[0]].profile_photo
          if (photourl === undefined) return null;
      
          let profilePhotoURI="";
          if (roomdetailItem.details.isGroupChat) {
              if (photourl === "<DEFAULT>" || photourl === "") {
                  profilePhotoURI = default_group;
              } else {
                  profilePhotoURI = photourl.replace('http:', 'https:');
              }
          } else {
              if (photourl === "<DEFAULT>" || photourl === "") {
                  profilePhotoURI = default_user;
              } else {
                  profilePhotoURI = photourl.replace('http:', 'https:');
              }
          }
          let lastMsg=null;
          if(room.messages&&room.messages.length!==0){
            lastMsg=room.messages.slice(-1).pop();
          }
          //console.log(profilePhotoURI,"profilePhotoURI")
          return(
          <div key={room.key}>
          <div className="chat-item-container">
        <ListItem 
          className="chat-item" 
          onClick={()=>handlePressChatrooms(roomdetailItem)} 
          // button
          selected={roomdetailItem.key === selectedRoomIndex}
        >
        <ListItemAvatar>
            <Avatar alt={roomName} src={profilePhotoURI} heigth={40} width={40} style={{}}/>     
        </ListItemAvatar>
        <ListItemText
          primary={
            <div className="chat-roomname-root">
              <Typography
                component="div"
                variant="body1"
                style={{fontWeight:500,fontFamily: "sans-serif !important"}}
                className="chat-roomname"
                color="textPrimary">
                {roomName}
              </Typography>
              <span className="time">
                {getLastDateTimeMessage(lastMsg?.orderBy)}
              </span>
            </div>
          }
          secondary={renderLastMsgTitleOnChatroom(lastMsg,roomdetailItem)}
          />
  </ListItem>

  <MoreHorizIcon onClick={(event)=>handleMoreHorizonClick(event,roomdetailItem)} className="horizon-icon"/>
  </div>
  <Divider variant="inset" component="li" />
  </div>
        )})}
      </List>
        </Grid>
    )
  }
  return (
    <Grid item className="chat-sidebar-container" id="chat-sidebar-container" style={{position:"relative"}}>
      <CreateRoomDialog
      open={openRoomDialog}
      toggleAction={()=>{setOpenRoomDialog(false)}}
      users={users}
      groups={groups}
      roomType={roomType}
      handleCreateRoomAction={handleCreateRoomAction}
      me={me}
      />
     <Header 
      openRoomDialog={()=>{setOpenRoomDialog(true)}}
      roomTypeAction={(type)=>{setRoomType(type)}}
     />
     {renderChatRoomList()}
     {renderChatroomMenuBar()}
     <ConfirmationDialog 
      open={showConfirmationDialog.open} 
      handleClose={()=>{setShowConfirmationDialog({...showConfirmationDialog,open:false})}} 
      confirmationDialogAction={handleConfirmationDialog}
      title={showConfirmationDialog.title}
      message={showConfirmationDialog.message}
      buttontitle={showConfirmationDialog.buttontitle}
    />
    </Grid>
  );
}
