import React from "react"
import {connect} from "react-redux";
import {Avatar, Icon, List, Select, Switch, Tooltip} from "antd";
import {getColor, SelectLabelInput} from "../../../../GlobalComponents/GlobalFields";
import {studentStatusList} from "../../../../GlobalComponents/optionValues";
import {Grid} from "@material-ui/core";
import cloneDeep from "lodash/cloneDeep";
import {CSVLink} from "react-csv";
import AllergyImage from "../../../../../../../assets/images/allergy.png";
import _ from "lodash";


class Student extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            studentStatus: ["Active"],
            isLoading: true,
            isAllergies: false,
            selectedGroup: "all",
            selectedStudent: [],
            searchKeys: ""
        }
    }

    async componentWillMount() {
        const { selectedStudent = [] } = this.props;
        this.setState({
            isLoading: false,
            selectedStudent
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { selectedStudent = []  } = nextProps || {}
        this.setState({
            isLoading: false,
            selectedStudent
        })
    }

    getAllStudentsList = () => {
        const { selectedStudent, searchKeys, selectedGroup, studentStatus, isAllergies } = this.state
        let dataItem = []
        let duplicateData = cloneDeep(selectedStudent)
        if(!searchKeys && selectedGroup === 'all'){
            dataItem = duplicateData
        } else {
            dataItem = (duplicateData || []).filter(p => (p.firstName || "").toString().toUpperCase().includes(searchKeys.toUpperCase()) || (p.lastName || "").toString().toUpperCase().includes(searchKeys.toUpperCase()))
        }
        if((studentStatus || []).length) {
            dataItem = dataItem.filter(data => studentStatus.some(status => status === data.status))
        }
        if(isAllergies) {
            dataItem = (dataItem || []).filter(data => data.allergies)
        }
        return dataItem
    }

    onDownloadList = () => {
        const { currentUser: { newUserInfo: { groupcache: groupList = [] } }, userCache } = this.props;
        let list = []
        let array = this.getAllStudentsList() || []

        array.forEach(stu => {
            let parent1Name = "-"
            let parent1Email = "-"
            let parent1Phone = "-"
            let parent2Name = "-"
            let parent2Email = "-"
            let parent2Phone = "-"
            const { firstName, lastName, group_ids, status, allergies, parents = [], dateOfBirth } = stu || {}
            let groups = []
            {Array.isArray(group_ids) && (group_ids || []).map((group) => {
                if(groupList[group] === undefined){ return  }
                groups.push(groupList[group].attributes.groupname || "")
            })}

            (parents || []).forEach((val, ind) => {
                const currentParent = (userCache && userCache[val]) || {}
                const { firstName = "", lastName = "", email_id = "", mobile = "" } = currentParent || {}
                if(firstName || lastName) {
                    if(ind === 0) {
                        parent1Name = `${firstName || ""} ${lastName || ""}`
                        parent1Email = email_id || "-"
                        parent1Phone = mobile || "-"
                    }
                    if(ind === 1) {
                        parent2Name = `${firstName || ""} ${lastName || ""}`
                        parent2Email = email_id || "-"
                        parent2Phone = mobile || "-"
                    }
                }
            })

            list.push({
                firstName,
                lastName,
                groups,
                status,
                allergies: allergies || "-",
                parent1Name,
                parent2Name,
                parent1Email,
                parent2Email,
                parent1Phone,
                parent2Phone,
                dateOfBirth: dateOfBirth || "-"
            })
        })
        return list
    }

    render() {
        const { parentLoad } = this.props
        const { studentStatus, isLoading, isAllergies } = this.state
        const headers = [
            { label: "First Name", key: "firstName" },
            { label: "Last Name", key: "lastName" },
            { label: "Parent 1", key: "parent1Name" },
            { label: "Parent 1 Email", key: "parent1Email" },
            { label: "Parent 1 Phone", key: "parent1Phone" },
            { label: "Parent 2", key: "parent2Name" },
            { label: "Parent 2 Email", key: "parent2Email" },
            { label: "Parent 2 Phone", key: "parent2Phone" },
            { label: "Groups", key: "groups" },
            { label: "Status", key: "status" },
            { label: "Allergies", key: "allergies" },
            { label: "Date of Birth", key: "dateOfBirth" }
        ];
        var sortedselectedStudent = _.orderBy(this.getAllStudentsList(), 'firstName', 'asc')
        return(
            <div>
                <div className="flex align-item-center">
                    <Select
                        // size={'large'}
                        placeholder={'Student Name'}
                        style={{width: 225}}
                        className={'mr-4'}
                        onChange={(value) => this.setState({selectedGroup: value})}
                    >
                    </Select>
                    <SelectLabelInput
                        style={{width: 400}}
                        mode="multiple"
                        name="studentStatus"
                        onChange={(val) => this.setState({"studentStatus": val})}
                        placeholder="Student Status"
                        options={studentStatusList || {}}
                        required
                        value={studentStatus || []}
                    />
                    <CSVLink data={this.onDownloadList() || []} headers={headers} className="ml-2" filename="Student.csv">
                        <Icon type="download" style={{fontSize: 30, color: '#3cc89c'}}/>
                    </CSVLink>
                    <div className="ml-3 fs-15 mr-2">Children's with Allergies:</div>
                    <Switch
                        onChange={() => this.setState({isAllergies: !isAllergies})}
                        checked={isAllergies || false}
                        size={'small'}
                    />
                </div>

                <Grid className="rounded-1rem bg-white shadow mt-8 student-content">
                    <Grid item xs={12}>
                        <div /* className="student-dashboard-infinite-container" */ >
                            <List
                                className="demo-loadmore-list"
                                loading={isLoading || parentLoad}
                                itemLayout="horizontal"
                                dataSource={sortedselectedStudent}
                                renderItem={(item, index) => {
                                    const { image = "", profile_photo = "", firstName = "", lastName = "", allergies = "" } = item || {}
                                    return(
                                        <List.Item className="cursor-pointer">
                                            <List.Item.Meta
                                                avatar={
                                                    (profile_photo || image) ?
                                                        <Avatar src={profile_photo || image} /> :
                                                        <Avatar
                                                            aria-label="recipe"
                                                            style={{backgroundColor: getColor(index)}}
                                                        >
                                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                        </Avatar>
                                                }
                                                title={
                                                    <div
                                                        className="mt-3 fs-16 flex"
                                                    >
                                                        <div onClick={() => this.props.history.push(`/admin/student/view/${item.id}`)}>{firstName} {lastName}</div>
                                                        { allergies ?
                                                            <div style={{marginLeft: 5}}>
                                                                <Tooltip title={allergies}>
                                                                    <img src={AllergyImage} style={{width: 12, marginBottom: 10}} />
                                                                </Tooltip>
                                                            </div> : null }
                                                    </div>
                                                }
                                            />

                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    userCache: state.currentUser.newUserInfo.usercache
})

export default connect(mapStateToProps,null)(Student)