import React, { Component } from 'react';
import {
    withStyles, Button, Grid,
    IconButton, Typography
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import parentService from '../service/parent';
import CloudDownload from '@material-ui/icons/CloudDownload'
import Message from '@material-ui/icons/Message'
import photoService from '../service/photos';
//import Gallery from 'react-grid-gallery';
import { message, Modal } from 'antd';
import {fetchPhotos} from '../redux/actions/photos'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css"
import "../css/photos.css"
import "video-react/dist/video-react.css";
import { Player } from 'video-react';
import CircularProgress from '@material-ui/core/CircularProgress'
const maxImagesToLoad = 20;
const styles = theme => ({

    root: {
        flexGrow: 1,
        flex : 1
    },
    card: {
        width: 250,
        margin: theme.spacing.unit
    },
    content: {
        width: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2
    },
    graph_card: {
        width: 520,
        margin: theme.spacing.unit
    },
    graph_content: {
        width: 520,
    },
    chart: {
        margin: 'auto',
        paddingTop: '10px'
    },
    subtext1: {
        clear: 'left',
        margin: 0,
        position: 'relative',
    },
    subtext2: {
        color: '#9e9e9e',
        position: 'absolute',
        right: '-6px',
        top: '-2px',
    },
    table: {
        minWidth: 150,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    button: {
        color: "white"
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    }

});



class Photos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _Groups: [],
            classid: 1,
            currentImage : 0,
            images : [],
            allimages : [],
            imagecount : 0,
            selectedImages : [],
            loadingPhotos : false,
            visible : false
        }
        this.downLoadImage = this.downLoadImage.bind(this)
        this.onCurrentImageChange = this.onCurrentImageChange.bind(this)
        this._renderVideo = this._renderVideo.bind(this)
        this.showModal = this.showModal.bind(this)
        this.handleOk = this.handleOk.bind(this)
        this._renderCustomControls = this._renderCustomControls.bind(this)
        this._onImageLoad = this._onImageLoad.bind(this)
        this._onSlide = this._onSlide.bind(this)
    }

    showModal(){
        this.setState({
            visible : true
        })
    }

    handleOk(){
        this.setState({
            visible : false
        })
    }

    _onImageLoad(){
        //console.log('Image Load')
    }

    // onSelectImage (index, image) {
    //     var images = this.state.images.slice();
    //     var img = images[index];
    //     var _img = this.state.selectedImages
    //     if(img.hasOwnProperty("isSelected")) {
    //         img.isSelected = !img.isSelected;
    //         _img = _img.filter(function(value, index, arr){
    //             return value !== img.src;
    //         });
    //     }
    //     else {
    //         img.isSelected = true;
    //         _img.push(img.src)
    //     }
    //
    //
    //     this.setState({
    //         images: images,
    //         selectedImages: _img
    //     });
    //
    // }

    _renderVideo(item) {
        return (
            <div className='image-gallery-video'>
                <div>

                <Player
                        className='video-react'
                        playsInline
                        fluid={false}
                        src={item.original}
                    />
                </div>

            </div>
        )
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.photos.photo_list !== this.props.photos.photo_list){
            this.setState({
                loadingPhotos : false
            })
        }
    }

    componentWillReceiveProps(newProps){
        if (newProps.photos.photo_list !== this.props.photos.photo_list){
            var imgs = []
            if (newProps.photos.photo_list === undefined){
                return
            }
            newProps.photos.photo_list.forEach(function(photo) {
                if (photo.media_type === 'vid'){
                    photo.renderItem = this._renderVideo
                }
                photo.originalTitle = photo.posttext
                imgs.push(photo)
            }, this)
            let numImages = imgs.length
            this.setState({
                imagecount : numImages,
                images : imgs.splice(0, maxImagesToLoad),
                allimages : imgs,
                selectedImages : []
            })
            //console.log(this._imageGallery)
            if (this._imageGallery !== undefined && this._imageGallery !== null  && this._imageGallery.length > 0){
                this._imageGallery.slideToIndex(0)
            }

        }
    }

    componentWillMount() {
        var username = this.props.currentUser.auth.idToken.payload.email
        this.getUserInfo(username);

    }

    loadMore = () => {
        var _allimgs = this.state.allimages
        var _imgs = _allimgs.splice(0, maxImagesToLoad)
        this.setState({
            images: this.state.images.concat(_imgs),
            allimages : _allimgs
        });
    };
    _onSlide = (nextIndex) => {
        if (this.state.images.length - nextIndex <= 5) {
            this.loadMore();
        }
        this.setState({ currentImage: nextIndex });
    };

    getUserInfo = async (username) => {
        try {
            const body = { email : username }
            //console.log(body)
            const res = await parentService.getUserDetailByID(body);
            //console.log(res)
            if (res.user) {
                const groups = res.user.groups;
                const currentParent = { ...res.user, groups };
                this.setState({ currentParent });
                this.setState({classid : groups[0]})
                this.fetchPhotoByClass( groups[0])
            }
            if (res.errorMessage) {
                message.error('something not right!')
            }

        } catch (error) {
            message.error('something not right!')
        }

    }

    handleSelectChange = event => {
        this.setState({
            classid : event.target.value,
            loadingPhotos : true
        })
        this.fetchPhotoByClass(event.target.value);

    };


    fetchPhotoByClass = async (classid) => {
        const { dispatch } = this.props;
        if (classid) {
            const body = { groupid : classid}
            const { data } = await photoService.getPhotos(body);
            // this.setState({ tableRow: data, data, search: '', });
            dispatch(fetchPhotos(data));
        }
    }

    onCurrentImageChange(index) {
        this.setState({ currentImage: index });
    }



    downLoadImage(){
        let idx = this._imageGallery.getCurrentIndex();
        const dataUrl =
            this.state.images[idx].original
        const dataUrlArray = dataUrl.split('/').reverse();
        const filename = dataUrlArray[0];
        if (filename.endsWith("mp4")){
            var FileSaver = require('file-saver');
            FileSaver.saveAs(dataUrl, filename, { autoBom: true });

        }else {
            function toDataURL(url, callback) {
                const httpRequest = new XMLHttpRequest();
                httpRequest.onload = function () {
                    const fileReader = new FileReader();
                    fileReader.onloadend = function () {
                        callback(fileReader.result);
                    }
                    fileReader.readAsDataURL(httpRequest.response);
                };

                httpRequest.open('GET', url + "?x-request=" + Math.floor(Math.random() * 1000000), true);
                httpRequest.responseType = 'blob';
                httpRequest.send();
            }

            toDataURL(dataUrl, (dataUrl) => {
                const a = document.createElement("a");
                a.href = dataUrl;
                a.setAttribute("download", filename);
                const b = document.createEvent("MouseEvents");
                b.initEvent("click", false, true);
                a.dispatchEvent(b);
                return false;
            })
        }



    }

    render() {
       
        const { classes} = this.props;
        var groups = this.props.Groups === undefined ? [] : this.props.Groups
        var showspinner = () => {
            if (this.state.loadingPhotos) {
                return (
                    <CircularProgress
                        className={classes.spinner}
                        size={20}
                    />
                )
            } else {
                return null

            }

        }
        //console.log(this.state.images)
        var _ImageGallery = (this.state.images === undefined || this.state.images.length ===0 ) ? (<div style={{alignContent:'center', paddingTop : 30, }}><Typography style={{fontColor : 'blue', fontSize : 20}}>No Images Found !</Typography></div>)
                                                                                                : ( <ImageGallery ref={i => this._imageGallery = i}
                                                                                                            showIndex={false}
                                                                                                            lazyLoad={true}
                                                                                                            slideDuration={0}
                                                                                                            showPlayButton={false}
                                                                                                            thumbnailPosition={"bottom"} items={this.state.images}
                                                                                                            renderCustomControls={this._renderCustomControls}
                                                                                                            onScreenChange={this._onImageLoad}
                                                                                                            onSlide={this._onSlide}
        />)
            return (
                <div className={classes.root}>
                <Grid container className={classes.root} spacing={24}>
                    <Grid item xs={12}>
                        <Grid container justify="center">
                            <Grid item>
                            </Grid>
                        </Grid>
                        <Grid item style={{marginLeft: 10}}>
                            <b>
                                Group : {' '}
                            </b>
                            <FormControl className={classes.formControl}>
                                <Select
                                    value={this.state.classid || 1}
                                    onChange={this.handleSelectChange}
                                    displayEmpty
                                    name="classid"
                                    className={classes.selectEmpty}>
                                    {Object.keys(groups).map((key, index) => ( (
                                        <MenuItem
                                            key={groups[key].groupid}
                                            value={groups[key].groupid}
                                        >
                                            {groups[key].GroupName.replace(/_/g, ' ')}
                                        </MenuItem>
                                    )))}

                                </Select>

                            </FormControl>
                            {_ImageGallery}
                            {showspinner()}
                        </Grid>
                        <Grid item xs={12}>
                            {/*<Gallery images={this.state.images}*/}
                            {/*enableLightbox={true}*/}
                            {/*enableImageSelection={false}*/}
                            {/*currentImageWillChange={this.onCurrentImageChange}*/}
                            {/*onSelectImage={this.onSelectImage.bind(this)}*/}
                            {/*customControls={[*/}
                            {/*<Button className={classes.button} style={{backgroundColor : 'gray'}}*/}
                            {/*onClick={this.downLoadImage}*/}
                            {/*>Download</Button>*/}

                            {/*]}*/}
                            {/*/>*/}


                        </Grid>
                    </Grid>
                </Grid>
            </div>
            );

    }

    _renderCustomControls() {
        if (this._imageGallery === undefined) return null
        if (this._imageGallery === null) return null
        if (this.state.images.length === 0) return null
        let idx = this._imageGallery.getCurrentIndex();
        let description =
            this.state.images[idx].posttext
        var showInformation = () => {
            if (description !== "") {
                return (
                    <span>
                        <IconButton style={{zIndex: 10, color: 'gray'}} onClick={this.showModal}
                            ><Message/>
                            </IconButton>
                            <Modal
                                title="Posted Message"
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                onCancel={this.handleOk}
                                footer={[
                                    <Button key="submit" type="primary" onClick={this.handleOk}>
                                        OK
                                    </Button>,
                                ]}
                            >
                                <p>{description}</p>
                            </Modal>
                    </span>

                )
            }
            else return null
        }
        return (
                <div style={{flexDirection : 'column' }}>
                    <IconButton style={{zIndex: 10, color: 'gray'}}
                                onClick={this.downLoadImage}><CloudDownload/></IconButton>
                    {showInformation()}
                    <span style={{color : 'white'}}>
                        {(this.state.currentImage +1) + ' of ' + this.state.imagecount}
                    </span>
                </div>
            )
}







}

const mapStateToProps = state => ({
    Groups: state.currentUser.userInfo.Groups,
    currentUser : state.currentUser,
    photos : state.photos,
    ...state.attendance
});

const component = withStyles(styles)(Photos);

export default connect(mapStateToProps)(component)