import React, {useState, useEffect, PureComponent, Component} from 'react';
import {Popover, Button, Tooltip, Input, Spin} from 'antd';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton';
import Flag from '@material-ui/icons/Flag';
import SaveIcon from '@material-ui/icons/Save';
import {CommentOutlined, HistoryOutlined, PushpinFilled, PushpinOutlined} from '@ant-design/icons';
import recordPlannerService from '../../../service/recordplanner'
import {connect} from "react-redux";
import {AgGridReact, AgGridColumn} from '@ag-grid-community/react';
import {showGroups} from "../../SchoolProfile/components/GlobalComponents/GlobalFields";
import moment from "moment";
import Scale from '../Scale'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import FlagIcon from '@material-ui/icons/Flag';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';

const {TextArea} = Input;

class skillCellRenderer extends PureComponent {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
        this.scaleHash = _.keyBy(Scale, 'rating')
        //console.log('props', this.props)
        this.state = {
            popupvisible: false,
            commentvisible: false,
            historyVisible : false,
            loadingHistory : false,
            modalTitle : '',
            auditData : [],
            commentText: props.value?.comment === undefined ? '' : props.value?.comment,
            flag : this.props.value?.flag === undefined ? false : this.props.value.flag,
            rating : this.props.value?.rating === undefined ? '-' : this.props.value.rating,
        };
    }


    saveComment() {
        let data = {
            'rating': this.props.value?.rating,
            'flag': this.state.flag,
            'comment': this.state.commentText
        }
        console.log('dataComment', data)
        this.props.setValue(data)
        this.setState({popupvisible: false})
    }

    btnClickedHandler(scale, clickedFlag) {
        console.log(this.props.value)
        console.log(this.state)
        this.setState({flag : scale.flag, rating : scale.rating})
        let data = {
            'rating': scale.rating,
            'flag': this.state.flag,
            'comment': this.state.commentText
        }
        console.log('data', data)
        this.props.setValue(data)
        this.setState({popupvisible: false})

    }

    commentChanged = (e) => {
        this.setState({
            commentText: e.target.value
        })
    }

    getModalTitle = () => {


        let modalTitle = 'History -  ' + this.props?.data.skill[this.props.data.skill.length-1] + ' lesson for ' + this.props?.colDef?.headerName

        return modalTitle
    }

    flagClicked = (event) => {
        console.log(event.target.checked)
        console.log(this.props.value)
        console.log(this.state)
        this.setState({flag : event.target.checked})

        let data = {
            'rating': this.state.rating,
            'flag': event.target.checked,
            'comment': this.state.commentText
        }
        console.log('data', data)
        this.props.setValue(data)
        this.setState({popupvisible: false})

    }


    getContent() {
        //console.log('Scale', this.props.Scale)
        let scaleButton = (scale) => {
            if (scale.flag) {
                return (
                    <Tooltip title={scale.name} key={scale.name}>
                        {/*<Button onClick={() => {*/}
                        {/*    this.btnClickedHandler(scale, true)*/}
                        {/*}}*/}
                        {/*        style={{backgroundColor: scale.color, color: 'blue', border: 0}}>*/}
                        {/*    <EmojiObjectsIcon  style={{color : '#EAD673'}}/>*/}
                        {/*</Button>*/}
                        <FormControlLabel
                            value="top"
                            control={<Checkbox color="primary" checked={this.state.flag}
                                               onChange={this.flagClicked}
                                               icon={<PushpinOutlined  style={{fontSize : '20px', color : 'orangered'}}/>}
                                               checkedIcon={<PushpinFilled  style={{fontSize : '20px', color : 'orangered'}} />}
                                               ontSize="small"/>}
                            label=""
                            style={{padding : 0}}
                            labelPlacement="top"
                        />
                    </Tooltip>
                )
            }
            let style = {}
            if (scale.rating !== this.props.value?.rating){
                style = {color : scale.color, backgroundColor: 'white', fontWeight: 'bold'}
            }else{
                style = {backgroundColor : scale.color, color : 'white'}
            }
            return (
                <Tooltip title={scale.name} key={scale.name}>
                    <Button style={style}
                            onClick={() => {
                                this.btnClickedHandler(scale, false)
                            }}>{scale.rating}</Button>
                </Tooltip>
            )
        }
        let buttonData = Scale.map((scale) => {
            return scaleButton(scale)
        })
        return (
            <div>
                <div>
                    {buttonData}
                    <Tooltip title={'Add Comment'}>
                        <Button onClick={() => {
                            this.setState({commentvisible: !this.state.commentvisible})
                        }}
                                style={{backgroundColor: 'white', color: this.state.commentText.length > 0 ?
                                        'red' : 'gray', border: 0}}>
                            <CommentOutlined/>
                        </Button>
                    </Tooltip>
                    <Tooltip title={'Show History'}>
                        <Button onClick={() => {
                            this.setState({popupvisible: false})
                            this.props.showAuditHistory(this.props?.colDef?.field,
                                                        this.props?.colDef?.headerName, this.props.data.id,
                                                this.props?.data.skill[this.props.data.skill.length-1]);
                        }
                        }
                                style={{backgroundColor: 'white', color:  'gray', border: 0}}>
                            <HistoryOutlined />
                        </Button>
                    </Tooltip>
                </div>
                {this.state.commentvisible &&
                <div style={{marginTop: 5}}>
                    <TextArea rows={2} style={{width: 200}} placeholder="Enter Comments here"
                              defaultValue={this.state.commentText}
                              onChange={this.commentChanged}/>
                    <IconButton color="primary" onClick={() => {
                        this.saveComment()
                    }}
                                aria-label="Save Comment" component="span">
                        <SaveIcon/>
                    </IconButton>

                </div>
                }

            </div>
        )
    }


    renderGridButton() {
        let cellValue = this.props.value === undefined ? '-' : this.props.value?.rating
        let bgColor = this.scaleHash[cellValue]?.color === undefined ? 'white' : this.scaleHash[cellValue].color
        if (this.props.value?.flag) {
            return (
                <Tooltip title={this.scaleHash[cellValue]?.name}>
                <div style={{backgroundColor: bgColor, cursor : 'pointer',
                    borderWidth : '0px', borderColor : 'blue', textAlign: 'center', color: 'white',
                    borderStyle : 'solid',height : '37px',
                    width: '100%'}}>
                    <span>{cellValue}</span>
                    <PushpinFilled  style={{color : 'orangered', fontSize : '12px', position : 'absolute'}}/>
                </div>
                </Tooltip>
            )
        }

        return (
            <Tooltip title={this.scaleHash[cellValue]?.name}>
            <div style={{backgroundColor: bgColor, color: 'white',cursor : 'pointer',
                width: '100%', textAlign: 'center'}}>
                {cellValue}
            </div>
            </Tooltip>
        )
    }

    render() {
        // if (this.props?.colDef?.field =="241") {
        //     console.log('SK->', this.props?.colDef?.field + this.props.data?.id)
        // }
        if (this.props.node.group) return null
        return (
            <Popover
                visible={this.state.popupvisible}
                trigger="click"
                onVisibleChange={this.handleVisibleChange} content={this.getContent()}>
                {this.renderGridButton()}
            </Popover>
        )

    }

    handleVisibleChange = visible => {
        if (!this.state.historyVisible){
            this.setState({popupvisible: visible});
        }

    };


}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(skillCellRenderer)