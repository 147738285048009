import React, { Component, useEffect } from 'react';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

export default function ImageLightbox({isOpen,onCloseAction,images=[]}) { 
    const [photoIndex,setPhotoIndex]=useState(0);
    return (
        isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={onCloseAction}
            onMovePrevRequest={() =>{
                setPhotoIndex((photoIndex + images.length - 1) % images.length);
            }}
            onMoveNextRequest={() =>{
                setPhotoIndex((photoIndex + 1) % images.length);
            }}
          />
        )
    )
}