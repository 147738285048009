import {Auth, API} from 'aws-amplify'
import firebase from 'firebase'
import _, {get} from 'lodash'
import sha256 from 'sha256';
import store from "./../redux/store";
import moment from "moment"
import { MESSAGES_PER_REFRESH } from '../utils/utils';

class FirebaseApi {
    constructor() {}
    getFirebase() {
        return firebase
    }

    startChatTypingListener(school_id, roomkey) {
        return dispatch => {
        }
    }

    newfeedrefreshListener(school_id, groupids){
        //console.log('Updating Post for Group->' + groupids)
        return dispatch => {
            let callbackFunction = (snap) => {
                //console.log('snap', snap)
                dispatch({type: 'LOAD_NEWSFEED_ALL', payload: {}})
            }
             groupids.forEach((groupid) => {
                let path = '/apps/' + school_id + '/posts/latestpost/' + groupid
                //console.log('Registering Listener on '  + path)
                firebase.database().ref(path).on("value", callbackFunction)
            })
        }
    }

    stopChatTypingListener(school_id, roomkey) {
        var userRoomPath = '/apps/' + school_id + '/rooms/' + roomkey + '/details/typing'
        var roomRef = firebase.database().ref(userRoomPath)
        roomRef.off('value')
        return dispatch => {}
    }

    inviteUsersToChat(school_id, roomkey, selectedUsers,allusers,me) {
        //console.log('Adding User ' , selectedUsers)
        var updatedUserData = {}
        let selecteduserKeys = Object.keys(selectedUsers)
        var userPath = '/apps/' + school_id + '/users/'
        for (var i = 0;i< selecteduserKeys.length ; i++){
            let user = selectedUsers[selecteduserKeys[i]]
            //console.log('Adding User ', user)
            let displayname = user.display_name === undefined ? user.firstName + ' ' + user.lastName : user.username
            updatedUserData['/apps/' + school_id + '/rooms/' + roomkey + '/details/users/' + user.userid] = {
                display_name: displayname,
                profile_photo: user.profile_photo,
                username: user.username === undefined ? user.userid : user.username,
                joinedTs: Date.now()
            }
            updatedUserData[userPath + user.userid + '/rooms/' + roomkey + '/visible/'] = true
            updatedUserData[userPath + user.userid + '/rooms/' + roomkey + '/refresh/'] = Date.now()
            this.sendSystemMessage(school_id, roomkey, `${displayname||user?.attributes?.firstName} was added to the conversation`,allusers,me)
        }
        //console.log('updatedUserData=>', updatedUserData)
        firebase.database().ref().update(updatedUserData, function (error) {
            if (error) {
                //console.log('Error updating data:', error)
            }
            return "ERROR"
        })
    }

    forceRefreshRoom(school_id, roomkey, userkeys){
        let userPath = '/apps/' + school_id + '/users/'
        let now = Date.now()
        var updatedUserData = {}
        for (var i=0; i < userkeys.length ; i++){
            let userid = userkeys[i]
            updatedUserData[userPath + userid + '/rooms/' + roomkey + '/refresh/'] = now
        }
        //console.log('updatedUserData', updatedUserData)
        firebase.database().ref().update(updatedUserData, function (error) {
            if (error) {
                //console.log('Error updating data:', error)
            }
            return "ERROR"
        })
    }

    stopRoomListener(school_id, me) {
        var userRoomPath = '/apps/' + school_id + '/users/' + me.id + '/rooms/'
        var roomRef = firebase.database().ref(userRoomPath)
        roomRef.off('value')
    }

    deleteChat(school_id, roomkey, me, users){
        //console.log(users)
        let userKeys = Object.keys(users)
        for (var i=0;i < userKeys.length; i++){
            var userRoomPath = '/apps/' + school_id + '/users/' + userKeys[i]
            //console.log('Processing userRoomPath', userRoomPath)
            firebase.database().ref(userRoomPath + '/rooms/' + roomkey).remove()
            firebase.database().ref(userRoomPath + '/notifications/rooms/' + roomkey).remove()
        }

        firebase.database().ref('/apps/' + school_id + "/rooms/" + roomkey).remove()
        firebase.database().ref('/apps/' + school_id + "/notifications/rooms/" + roomkey).remove()
        return dispatch => {
            dispatch({type: 'CLEAR_ROOM', payload: {roomkey}})
        }
    }

    leaveChat(school_id, roomkey, user, isGroupChat=false,allusers) {
        //console.log('user to delete ', user)
        let userid = user.userid || user.id
        var userRoomPath = '/apps/' + school_id + '/users/' + userid
        //console.log('userRoomPath', userRoomPath)
        //console.log('Deleting ' + userRoomPath + '/rooms/' + roomkey)
        //console.log('Deleting ' + userRoomPath + '/notifications/rooms/' + roomkey)
        //remove room visibility
        firebase.database().ref(userRoomPath + '/rooms/' + roomkey).update({visible: false})
        //remove room notification and count
        firebase.database().ref(userRoomPath + '/notifications/rooms/' + roomkey).remove()
        if (isGroupChat) {
            //remove from details
            //console.log('Removing ' + '/apps/' + school_id + "/rooms/" + roomkey + '/details/users/' + userid)
            firebase.database().ref('/apps/' + school_id + "/rooms/" + roomkey + '/details/users/' + userid).remove()
            this.sendSystemMessage(school_id,roomkey,`${user.display_name||user?.attributes?.firstName} left the chat`,allusers,user);
        }
        return dispatch => {
            dispatch({type: 'CLEAR_ROOM', payload: {roomkey}})
        }
    }

    sendSystemMessage(school_id, roomkey, msgtext,users,me) {
            var removeUserMessageKey = firebase.database().ref('/apps/' + school_id + '/rooms/' + roomkey + '/messages/').push().key
            //send system message
            const message={
                _id: removeUserMessageKey,
                orderBy: new Date().getTime(),
                system: true,
                text: msgtext,
                user: {_id: 'system',name:"system",avatar:""}
            }
            firebase.database().ref('/apps/' + school_id + '/rooms/' + roomkey + '/messages/' + removeUserMessageKey).set(message);
            store.dispatch(this.sendChatMessage(school_id, roomkey, [message], users, me, false));
    }

    DeInit(){
        firebase.auth().signOut().then(function() {
            //console.log('Signed out of firebase')
        }, function(error) {
            //console.log(error)
        });
    }

    sendChatMessage(school_id, roomkey, message, users, me, sendNotification) {
          return async  (dispatch, getState)=> {
            var roomPath = '/apps/' + school_id + '/rooms/'
            var notificationPath = '/apps/' + school_id + '/notifications/rooms/'
            var userPath = '/apps/' + school_id + '/users/'
            var myuserid = me.id
            var mydisplayname = me.display_name || me.attributes.firstName + ' ' + me.attributes.lastName
            var msgs = message[0]
            var now = new Date().getTime()
            if(!msgs?.text){
                now = msgs?.orderBy
            }
            msgs['orderBy'] = now
            msgs['sent'] = true
            let sendDispatch = false
            var updatedUserData = {}
            var msgkey = msgs._id
            if (msgkey.length <= 0) {
                msgkey = firebase.database().ref(roomPath + roomkey + '/messages/').push().key
            }
            updatedUserData[roomPath + roomkey + '/messages/' + msgkey] = msgs

            // update room's lastmsgtimestamp
            var roomDetailsRef = firebase.database().ref(roomPath + roomkey + '/details/')
            // //console.log(roomDetailsRef,"roomDetailsRef",firebase.database().ref(roomPath + roomkey + '/messages/'))
            roomDetailsRef.update({lastmsgtimestamp: now}, function (error) {
            })

            dispatch({type: 'ADD_CHAT_MESSAGE', payload: {msgs: msgs, roomkey}});
            // let roompath = '/apps/' + school_id + '/rooms/' + roomkey;
            // let roomdata = await firebase.database().ref(roompath).once('value').then(function (snapshot) {
            //     let data = snapshot.val()
            //     return data
            // })
            // dispatch({type: 'GET_MESSAGES_FULLFILLED',payload: {'roomkey': roomkey, 'msgs': roomdata.messages, userid: me.id}})
            // //console.log('RoomData', roomdata);
            // Added Notifications to Users
            var notifyUsers = [];
            // update the stats for all user in chat
            for (var user in users) {
                if (user !== myuserid) {
                    notifyUsers.push(user)
                    var ref = firebase.database().ref(userPath + user + '/notifications/rooms/' + roomkey + '/count/')
                    ref.transaction(function (data) {
                        data = data === null ? 1 : data + 1
                        return data
                    })
                    updatedUserData[userPath + user + '/notifications/rooms/' + roomkey + '/lastmsg/'] = now;
                    //If User has the room visibility as false. Enable it. Its for the first message to room
                    updatedUserData[userPath + user + '/rooms/' + roomkey + '/visible/'] = true;
                }
            }
            // update the stats for user himself
            updatedUserData[userPath + me.id + '/notifications/rooms/' + roomkey + '/lastmsg/'] = now;
            updatedUserData[userPath + me.id + '/notifications/rooms/' + roomkey + '/count/'] = 0;

            // if (sendNotification) {
            //     var textmsg = mydisplayname + ' sent a Chat message'
            //     updatedUserData[notificationPath + roomkey + '/' + msgkey + '/user'] = myuserid
            //     updatedUserData[notificationPath + roomkey + '/' + msgkey + '/title'] = textmsg
            //     updatedUserData[notificationPath + roomkey + '/' + msgkey + '/notify'] = notifyUsers
            //     if (message[0].text !== undefined) {
            //         updatedUserData[notificationPath + roomkey + '/' + msgkey + '/message'] = message[0].text
            //         sendDispatch = true
            //     }
            //     if (message[0].image !== undefined) {
            //         updatedUserData[notificationPath + roomkey + '/' + msgkey + '/image'] = message[0].image
            //     }
            //     if (message[0].video !== undefined) {
            //         updatedUserData[notificationPath + roomkey + '/' + msgkey + '/video'] = message[0].video
            //     }
            //     let token = store.getState().currentUser.auth.idToken.jwtToken
            // this.sendPushNotification('Chat Notification', textmsg,null,notifyUsers,null,token)            
            // }
            //console.log(updatedUserData)
            firebase.database().ref().update(updatedUserData, function (error) {
                if (error) {

                }
            })
            if (sendDispatch) {
                    let _msg = Object.assign({}, msgs)
                    _msg['sent'] = false
                    dispatch({type: 'ADD_PENDING_CHAT_MESSAGE', payload: {msgs: _msg, roomkey}})
            }
        }
    }
     
    // sendPushNotification(title, body, group_ids, user_ids, exclude_user_id, token) {
    //     let apiName = EnvConfig.API_NAME
    //     let path = '/sendPushNotifications'
    //     var bodyjson = {
    //         title : title,
    //         body : body,
    //     }
    //     bodyjson['group_ids'] = group_ids === null ? [] : group_ids
    //     bodyjson['user_ids'] = user_ids === null ? [] : user_ids
    //     bodyjson['exclude_user_id'] = exclude_user_id === null? [] : exclude_user_id
    //     let myInit = {
    //         headers: {
    //             'Authorization': token
    //         }, body : bodyjson,
    //         response: true
    //     }
    //     //console.log(myInit)
    //     var response = API.post(apiName, path, myInit).then(response => {
    //         //console.log(response)
    //         return response
    //     }).catch(error => {
    //         //console.log(error.response)
    //         return error
    //     })
    //     return response
    // }

    registerDeviceforNotification(devicetoken,custom_user_data,attributes, token) {
        const init = {
            body:{
                token: devicetoken,
                device_type : "gcm"
            }
        }
        return API.post('user/create_platform_endpoint', '', init).then(res => res).catch(err => err);
    }

    startRoomListener(school_id, me) {
        //console.log("me", me)
        var userRoomPath = '/apps/' + school_id + '/users/' + me.id + '/rooms/'
        //console.log('Enabling User Room Listener ' + userRoomPath)
        // Start Room Listener
        return function (dispatch, getState) {
            dispatch({type: 'LOAD_MESSAGE_ROOMS_LOADING', payload: {rooms: {}}})
            //console.log('Starting Listener for ' + userRoomPath + ' .....')
            firebase.database().ref(userRoomPath).on("value", function (snapshot) {
                //console.log('Found Snapshot ->', snapshot)
                if (!snapshot.exists()) {
                    dispatch({type: 'LOAD_MESSAGE_ROOMS', payload: {rooms: {}}})
                    return
                }
                var roomlist = snapshot.val();
                //console.log('roomlist', roomlist)
                //Get Current Store
                //console.log('store', getState())
                var {chatrooms } = getState()
                //console.log('store', chatrooms)
                //console.log('# of rooms', Object.keys(chatrooms.rooms).length)
                if (roomlist === null) return
                var roomlistkeys = []
                var nextroomlistkeys = Object.keys(roomlist)
                var currentroomlistkeys = Object.keys(chatrooms.rooms)
                //go thru all the rooms found and see which ones needs to be refreshed
                for (var x=0;x < nextroomlistkeys.length;x++){
                    let newkey = nextroomlistkeys[x]
                    if (currentroomlistkeys.includes(newkey)){
                        if (roomlist[newkey].refresh > chatrooms.rooms[newkey].refresh){
                            //console.log('Found Notice to Refresh')
                            roomlistkeys.push(newkey)
                        }
                    }else{
                        roomlistkeys.push(newkey)
                    }
                }
                //console.log('roomlistkeys',roomlistkeys)
                var promises = []
                for (var i = 0; i < roomlistkeys.length; i++) {
                    //Send Dispatch for All Active Rooms
                    //console.log('0--->', roomlist[roomlistkeys[i]])
                    if (roomlist[roomlistkeys[i]].visible == true) {
                        //console.log('Getting Room', roomlistkeys[i])
                        let roompath = '/apps/' + school_id + '/rooms/' + roomlistkeys[i]
                        let roomdata = firebase.database().ref(roompath).once('value').then(function (snapshot) {
                            let data = snapshot.val()
                            //console.log('RoomData', data)
                            return data
                        })
                        //roomData[roomlistkeys[i]] = roomdata
                        promises.push(roomdata)
                    } else {
                        //console.log('Skipping Room', roomlistkeys[i])
                    }
                }
                //console.log(promises)
                Promise.all(promises).then((values) => {
                    //console.log(values)
                    var roomdata = {}
                    for (var x = 0; x < values.length; x++) {
                        if ('key' in values[x]) {
                            roomdata[values[x].key] = values[x]
                            roomdata[values[x].key]['refresh'] = roomlist[values[x].key].refresh === undefined ? 0 : roomlist[values[x].key].refresh
                        }
                    }
                    //console.log('LOAD_MESSAGE_ROOMS', roomdata)
                    dispatch({type: 'LOAD_MESSAGE_ROOMS', payload: {rooms: roomdata, userid: me.id}})
                    //console.log('DONE DISPATCH for LOAD_MESSAGE_ROOMS')
                })

            })
        }
    }

    clearChatReadNotifications(school_id, me, roomKey){
        var updatedUserData = {}
        updatedUserData['/apps/' + school_id + '/users/' + me.id + '/notifications/rooms/' + roomKey + '/count/'] = 0;
        firebase.database().ref().update(updatedUserData, function (error) {
            if (error) {}
        })
    }

    startMessageListener(school_id, me) {
        var userNotificationRoomPath = '/apps/' + school_id + '/users/' + me.id + '/notifications/rooms/'
        //console.log('Enabling Message Listener')
        return function (dispatch) {
            //console.log('Setting Listener')
            firebase.database().ref(userNotificationRoomPath).on("value", function (snapshot) {
                let starttime = new Date()
                if (!snapshot.exists()) {
                    //console.log('Nothing to Monitor yet !')
                    return 1
                }
                var roomstatsfromFirebase = snapshot.val()
                //console.log('roomstatsfromFirebase',roomstatsfromFirebase)
                dispatch({type : 'LOAD_NOTIFICATIONS', payload : {notifications : roomstatsfromFirebase}})
                var roomState = store.getState().chatrooms;
                //console.log('roomState', roomState)
                if (roomState.isloadingMessages) {
                    //console.log('Messaged are currently being loaded .... exiting check ...')
                    return 1
                }
                //console.log('Processing Messages .....')
                //-----------------------------
                var roomMsgstoGet = []
                var roomstatsfromFirebaseKeys = Object.keys(roomstatsfromFirebase)

                for (var i = 0; i < roomstatsfromFirebaseKeys.length; i++) {
                    let firebaseroomkey = roomstatsfromFirebaseKeys[i]
                    let firebaseroom = roomstatsfromFirebase[firebaseroomkey]
                    // Check to see which room has an update compred to store
                    if (Object.keys(roomState.rooms).includes(firebaseroomkey)) {
                        //console.log(' roomState.rooms[firebaseroomkey]', roomState.rooms[firebaseroomkey])
                        //console.log(' roomState.rooms', roomState.rooms)
                        if (firebaseroom.lastmsg !== roomState.rooms[firebaseroomkey].details.lastmsgtimestamp) {
                            //console.log('New Messages in Room ', firebaseroomkey)
                            let joinedTs = get(roomState.rooms[firebaseroomkey], 'details.users.' + me.id + '.joinedTs', 0)
                            //console.log('user joined : ' + joinedTs + ' while last TS ' + roomState.rooms[firebaseroomkey].details.lastmsgtimestamp)
                            let sinceTime = joinedTs > roomState.rooms[firebaseroomkey].details.lastmsgtimestamp ? joinedTs : roomState.rooms[firebaseroomkey].details.lastmsgtimestamp
                            roomMsgstoGet.push({roomkey: firebaseroomkey, sinceTime: sinceTime})
                        } else {
                            //console.log('No new Messages in Room ', firebaseroomkey)
                        }
                    }
                    // Need to test and see if this else makes sense to comment out
                    // } else {
                    //     let joinedTs = get(roomState.rooms[firebaseroomkey], 'details.users.' + me.id + '.joinedTs', 0)
                    //     //console.log('user joined : ' + joinedTs + ' while last TS ' + 0)
                    //     roomMsgstoGet.push({roomkey: firebaseroomkey, sinceTime: joinedTs})
                    // }
                }
                //Download messages ......
                var promises = []
                for (var i = 0; i < roomMsgstoGet.length; i++) {
                    let roompath = '/apps/' + school_id + '/rooms/' + roomMsgstoGet[i].roomkey
                    //console.log('Getting Messages from ' + roompath + ' since ' + roomMsgstoGet[i].sinceTime)
                    var ref = firebase.database().ref(roompath)
                    var msgref = ref.child('messages').orderByChild('orderBy').limitToLast(15).startAt(roomMsgstoGet[i].sinceTime)

                    //console.log('roomMsgstoGet[i].roomkey', roomMsgstoGet[i].roomkey)
                    var msgdatapromise = msgref.once('value',  (snap)=> {
                        let p = snap.val()
                        return p
                    }).then(value => {
                        let msgdata = value.val()
                        //console.log('Value->', msgdata)
                        let roomkey = value.ref.path.toString().replace('/apps/' + school_id + '/rooms/','').replace('/messages','')

                        dispatch({
                            type: 'GET_MESSAGES_FULLFILLED',
                            payload: {'roomkey': roomkey, 'msgs': msgdata, userid: me.id}
                        })
                    })
                }
            })
        }
    }

    // setListenerAppEnableStatus(school_id) {
    //     //console.log('Enabling App Status Listener ')
    //     return function (dispatch) {
    //         //console.log('Setting Listner')

    //         firebase.database().ref("/apps/" + school_id + "/status").on("value", function (snapshot) {
    //             if (snapshot.exists()) {
    //                 var data = snapshot.val();
    //                 //console.log('setAppStatus', data)
    //                 dispatch({type: 'SET_APP_ENABLE_STATUS', payload: {data}})
    //                 //console.log('DONE DISPATH')
    //             }else{
    //                 let ref = firebase.database().ref("/apps/" + school_id + "/status")
    //                 let updates = {}
    //                 updates['online'] = 'true'
    //                 updates['disabletext'] = ''
    //                 updates['disabletextdesc'] = 'true'

    //                 ref.update(updates).then(function () {
    //                     //console.log("Write completed");
    //                     return resolve(updates);
    //                 }).catch(function (error) {
    //                     console.error("Write failed: ", error);
    //                     return reject(error);
    //                 })

    //             }

    //         })
    //     }
    // }



    Init(config) {
        //console.log('In Init')
        if (!firebase.apps.length) {
            firebase.initializeApp(config)
            //console.log('Done Initializing App')
        }else{
            //console.log('FIREBASE ALREADY INITIALIZED !!!')
        }  
    }

    signIn(token) {
        //console.log('Signing In ....',token)
        return firebase.auth().signInWithCustomToken(token)
    }

    getPrivateChatHash(user, me) {
        var userids = []
        //console.log('user', user)
        //console.log('me', me)

        userids.push(me.id)
        userids.push(user.userid)
        userids.sort()
        return userids.join('-')
    }

    async getPrivateRoomKey(school_id, userHashKey) {
        var roomPath = '/apps/' + school_id + '/rooms/'
        var chatPath = '/apps/' + school_id + '/chats/'
        ////console.log('Checking =>' + chatPath + userHashKey)
        var newRoomKey = firebase.database().ref(chatPath + userHashKey).once('value').then(function (snapshot) {
            if (snapshot.exists()) {
                ////console.log('Room has already been created')
                let key = snapshot.val()
                return key
            } else {
                ////console.log('Room does not exist')
                var newKey = firebase.database().ref().child(roomPath).push().key
                ////console.log(newKey)
                return newKey
            }
        })
        return newRoomKey
    }

    async startPrivateChat(school_id, user, me) {
        var userHashKey = this.getPrivateChatHash(user, me)
        ////console.log('userhashkey', userHashKey,school_id, user, me)
        let userHashVal = await sha256(userHashKey)
        ////console.log('room hash->' + userHashVal)
        var users = [user, me]
        ////console.log(users)
        var roomkey = await this.getPrivateRoomKey(school_id, userHashVal)
        // let currentChatRooms = miscutils.getChatStats()
        let currentChatRooms = store.getState().chatrooms;
        ////console.log('RoomKey =>', roomkey,currentChatRooms,store.getState())
        // If room already exists in Cache dont create new one !
        if (!Object.keys(currentChatRooms.rooms).includes(roomkey)){
            ////console.log(users, me)
            await this.createRoom(school_id, roomkey, users, me, userHashVal, false,"","");
        }
        return roomkey;
    }

    async startGroupChat(school_id, users, me,roomName, roomPhoto) {
        let userHashVal = await sha256(roomName + Date.now())
        ////console.log('room hash->' + userHashVal)
        users.push(me)
        ////console.log(users)
        var roomkey = await this.getPrivateRoomKey(school_id, userHashVal)
        ////console.log('roomkey->', roomkey)
        await this.createRoom(school_id,roomkey, users, me, userHashVal, true,roomName, roomPhoto)
        return roomkey
    }

    updateGroupChatDetails(school_id, roomkey, me, groupname, photourl, selectedUsers, oldUsers){
        var existingUsersKeys = Object.keys(oldUsers)
        ////console.log('existingUsersKeys', existingUsersKeys)
        // Note :  user will have key as user_example_com while selected user will have user@example.com
        let selecteduserKeys = _.map(selectedUsers, 'id')
        ////console.log('new users', selecteduserKeys)
        let adduser = {}

        // Get Users that need to be removed
        let removeuser = []
        for(var x = 0; x < existingUsersKeys.length;x++ ){
            let userid =  existingUsersKeys[x]
            if (selecteduserKeys.includes(userid)){
                if (me.id !== userid){
                    ////console.log('removing ', userid)
                    removeuser.push(userid)
                }
            }
        }
        // Get Users that need to be added
        let selectedUserbyEmailKeys = Object.keys(selectedUsers)
        let addedUsers = {}
        for(var x = 0; x < selectedUserbyEmailKeys.length;x++ ){
            let email =  selectedUserbyEmailKeys[x]
            if (!existingUsersKeys.includes(selectedUsers[email].id)){
                if (me.id !== selectedUsers[email].id){
                    ////console.log('adding ', selectedUsers[email].id)
                    addedUsers[selectedUsers[email].id] = selectedUsers[email]
                }
            }
        }
        var roomPath = '/apps/' + school_id + '/rooms/'
        var userPath = '/apps/' + school_id + '/users/'
        var roomDetailsRef = firebase.database().ref(roomPath + roomkey + '/details/')
        roomDetailsRef.update({
            lastmsgtimestamp: Date.now(),
            name: groupname,
            groupPhoto : photourl,
        }, function (error) {})
        let updatedUserData = {}
        updatedUserData[userPath + me.id + '/rooms/' + roomkey + '/refresh/'] = Date.now()
        firebase.database().ref().update(updatedUserData, function (error) {
            if (error) {
                ////console.log('Error updating data:', error)
            }
            return "ERROR"
        })
        ////console.log('removeuser',  removeuser)
        //Removing Users
        for (var i=0; i < removeuser.length ; i++){
            let user = oldUsers[removeuser[i]]
            user['userid'] = removeuser[i]
            store.dispatch(this.leaveChat(school_id, roomkey, user , true,oldUsers)) 
        }

        ////console.log('addedUsers',  addedUsers)
        if (Object.keys(addedUsers).length > 0) {
            this.inviteUsersToChat(school_id, roomkey, addedUsers,oldUsers,me)
        }
    }

    async createRoom(school_id, roomkey, users, me, userHashVal, isGroupChat, roomName = "",roomPhoto = "") {
        ////console.log('createRoomahsan', roomPhoto, roomName,school_id, roomkey, users, me, userHashVal, isGroupChat)
        var updatedUserData = {}
        var roomPath = '/apps/' + school_id + '/rooms/'
        var userPath = '/apps/' + school_id + '/users/'
        var chatPath = '/apps/' + school_id + '/chats/'
        var roomAlreadyExists = await this.checkifRoomExists(school_id, roomkey)
        var now = Date.now()
        ////console.log(users)
        for (var i = 0, len = users.length; i < len; i++) {
            let user = users[i]

            if (roomAlreadyExists) {
                ////console.log('Room Already Exists !')
                ////console.log('user.userid === me.id', user.userid, me.id)
                // Assuming the chat is deleted, rejoin to see new messages
                if (user.userid === me.id) {
                    updatedUserData[roomPath + roomkey + '/details/users/' + me.id] = {
                        display_name: user.display_name,
                        profile_photo: user.profile_photo,
                        username: user.username,
                        joinedTs: now
                    }
                    updatedUserData[userPath + user.userid + '/rooms/' + roomkey + '/visible/'] = true
                    updatedUserData[userPath + user.userid + '/rooms/' + roomkey + '/refresh/'] = now
                    ////console.log('Set Joined T = self =>' + user.userid)
                }
            } else {
                if (!isGroupChat) {
                    firebase.database().ref(roomPath + roomkey + '/details/').set({
                        name: 'Private Chat',
                        isGroupChat: false,
                        typing: false,
                        lastmsgtimestamp: 0
                    })
                }else{
                    firebase.database().ref(roomPath + roomkey + '/details/').set({
                        name: roomName,
                        isGroupChat: true,
                        groupPhoto : roomPhoto,
                        owner : me.id,
                        typing: false,
                        lastmsgtimestamp: 0
                    })
                }
                ////console.log('NEW CHAt !')
                ////console.log(user)
                updatedUserData[roomPath + roomkey + '/details/users/' + user.userid] = {
                    display_name: user.display_name || user.firstName + ' ' + user.lastName,
                    profile_photo: user.profile_photo,
                    username: user.id,
                    joinedTs: Date.now()
                }
                if (!isGroupChat){
                    updatedUserData[userPath + user.userid + '/rooms/' + roomkey + '/visible/'] = (user.userid === me.id) ? true : false
                }else{
                    updatedUserData[userPath + user.userid + '/rooms/' + roomkey + '/visible/'] = true
                }

                updatedUserData[userPath + user.userid + '/rooms/' + roomkey + '/refresh/'] = now
            }

        }
        if (!roomAlreadyExists) {
            updatedUserData[roomPath + roomkey + '/details/userkey'] = userHashVal
            updatedUserData[roomPath + roomkey + '/key/'] = roomkey
            updatedUserData[chatPath + userHashVal] = roomkey
        }
        ////console.log('updatedUserData', updatedUserData)
        if (isGroupChat) {
            let display_name = me.display_name
            this.sendSystemMessage(school_id, roomkey, `${display_name} started a Group Conversation`,users, me)
        }
        firebase.database().ref().update(updatedUserData, function (error) {
            if (error) {
                ////console.log('Error updating data:', error)
            }
            return "ERROR"
        })
        return roomkey;
    }



    registerUser(school_id, user) {
        return new Promise(function (resolve, reject) {
            var userObj = {}
            userObj['firstName'] = get(user,'attributes.firstName','')
            userObj['lastName'] = get(user,'attributes.lastName','')
            userObj['profile_photo'] = get(user,'attributes.profile_photo','')
            userObj['rooms/dummyroom'] = { 'visible' : false}
            userObj['email'] = user.email_id
            var updates = userObj;
            var firebaseUserSettingsRef = firebase.database().ref('/apps/' + school_id + '/users/' + user.id);
            ////console.log('userObj',firebaseUserSettingsRef)
            firebaseUserSettingsRef.update(updates).then(function () {
                ////console.log("Write completed");
                return resolve(updates);
            }).catch(function (error) {
                console.error("Write failed: ", error);
                return reject(error);
            });
        });
    }

    getFirebaseToken(uid, school_id, awstoken) {
        const init = {
            body:{uid,school_id}
        }
        return API.post('firebasetoken', '', init).then(res => res);
    }


//     getFirebaseToken(uid, school_id, awstoken) {
//         var startTime = Date.now();
//         let apiName = EnvConfig.API_NAME
//         let path = '/firebasetoken'
//         var data = {
//             headers: {
//                 'Authorization': awstoken
//             },
//             body: {
//                 uid, school_id
//             }
//         }
//         ////console.log(data)
//         var response = API.post(apiName, path, data).then(response => {
//             ////console.log(response)
//             var endtime = Date.now();
//             var timediff = endtime - startTime
//             miscutils.captureStat('API:firebase', 'getToken', timediff)
//             ////console.log('Time to get FirebaseToken: ' + timediff)
//             return response
//         }).catch(error => {
//             ////console.log(error.response)
//             return error
//         })
//         ////console.log(response)
//         return response
//     }
//     createGroup(group_name, group_owner, group_members, app_id, attributes, invited_members) {
//         var path = '/apps/' + app_id + '/groups/';
//         // ////console.log("path", path);
//         var group = {};
//         group.name = group_name;
//         group.owner = group_owner;
//         group.members = group_members;
//         group.iconURL = "NOICON";
//         group.createdOn = firebase.database.ServerValue.TIMESTAMP;
//         if (attributes) {
//             group.attributes = attributes;
//         }
//         if (invited_members) {
//             group.invited_members = invited_members;
//         }
//         ////console.log("creating group " + JSON.stringify(group) + " to " + path);
//         return firebase.database().ref(path).push(group);
//     }

    async checkifRoomExists(school_id, roomkey) {
        let roomPath = '/apps/' + school_id + '/rooms/' + roomkey + '/details/users/'
        return firebase.database().ref(roomPath).once("value").then(function (snapshot) {
            ////console.log(snapshot)
            return snapshot.exists();
        })
    }

// //    registerGoogleClassroomStat(school_id, username, googleobj){
// //         try {
// //             var userobj = googleobj.user
// //             ////console.log('Storing stats in GoogleClassroom')
// //             firebase.database().ref('/apps/' + school_id + '/GoogleClassroom/' + username).update({
// //                 familyname: userobj['familyName'],
// //                 name: userobj['name'],
// //                 timeaccess : moment().format('MMMM Do YYYY, h:mm:ss a'),
// //                 platform : Platform.OS,
// //                 googleclassroomemail: userobj['email'],
// //                 givenName: userobj['givenName']

// //             }).catch((error => {
// //                 //console.log(error)
// //                 //console.log('Error Updating firebase')
// //             }))
// //             try{
// //                 var ref = firebase.database().ref('/apps/' + school_id + '/GoogleClassroom/' + username + '/count')
// //                 ref.transaction(function (data) {
// //                     data = data === null ? 1 : data + 1
// //                     return data
// //                 })
// //             }catch(e){
// //                 //console.log(e)
// //                 //console.log('Error ')
// //             }
// //         }catch (e) {
// //             console.warn(e)
// //         }
// //     }

//     notifyPostinFirebase(school_id, groupid) {
//         //console.log('Updating Post for Group->' + groupid)

//         firebase.database().ref('/apps/' + school_id + '/posts/latestpost/' + groupid).set(Date.now())
//     }
}

const instance = new FirebaseApi()
Object.freeze(instance)
export default instance


