import { API } from 'aws-amplify';
import {
    takeEvery,
    fork,
    all,
    put,
    select
} from 'redux-saga/effects';
import {
    FETCH_STAFF,
    fetchStaff,
    FETCH_STUDENTS,
    fetchStudents
} from '../actions/post-manage';
import { REQUEST } from '../actions/common';
import { getUserRole } from "../../utils/utils";

/* async function saveInAWSStorage(files) {
    return await Promise.all(files.map(async (file) => {
        const path = `${moment().year()}/${moment().month() + 1}/${moment().date()}/${v4()}.${file.type.split('/')[1]}`;
        const {
            key
        } = await Storage.put(path, file, {
            contentType: file.type
        });
        return await Object.assign(file, {
            preview: URL.createObjectURL(file),
            key: `public/${key}`
        })
    }));
} */


function* fetchStaffGenerator(action) {
    try {

        const users = yield select(state => state.currentUser.newUserInfo.usersList);

        let usersList = []
        users.forEach(user => {
            if((user.group_ids || []).includes(Number(action.query))) {
                if(getUserRole(user, "teacher") || getUserRole(user, "admin")) {
                    usersList.push(user)
                }
            }
        })

        yield put(fetchStaff.success(usersList))
    } catch (error) {
        yield put(fetchStaff.failure(error))
    }
}

function* fetchStudentsGenerator(action) {
    try {

        const childList = yield select(state => state.currentUser.newUserInfo.childList);

        let students = []
        childList.forEach(child => {
            if(Array.isArray(child.group_ids) && (child.group_ids || []).includes(Number(action.query))) {
                students.push(child)
            }
        })

        yield put(fetchStudents.success(students))
    } catch (error) {
        yield put(fetchStudents.failure(error))
    }
}
/** watcher */

function* watcherfetchStaff() {
    yield takeEvery(FETCH_STAFF[REQUEST], fetchStaffGenerator)
}

function* watcherfetchStudents() {
    yield takeEvery(FETCH_STUDENTS[REQUEST], fetchStudentsGenerator)
}

export default function* rootPostManage() {
    yield all([
        fork(watcherfetchStaff),
        fork(watcherfetchStudents),
    ]);
}