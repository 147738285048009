import {
    API
} from 'aws-amplify';
import moment from "moment";
import {
    takeEvery,
    fork,
    all,
    put,
    select
} from 'redux-saga/effects'

import { reset } from "redux-form";
import { REQUEST } from '../actions/common';
import { closeDialogBox, showToastMessage } from "../actions/component";

import {
    fetchPost, FETCH_POST,
    CREATE_POST, createPost,
    EDIT_POST, editPost,
    DELETE_POST, deletePost,
    SAVE_COMMENT, saveComment,
    UPDATE_COMMENT, updateComment,
    DELETE_COMMENT, deleteComment,
    LIKE_POST, likePost,
    UNLIKE_POST, unlikePost, APPLY_POST_FILTER,
    FETCH_PINNED_POST, fetchPinnedPost, POST_VIEW, getPostView
} from '../actions/feed-post'

import {
    fetchDraftPost,
    fetchSchedulePost,
    fetchReviewPost
} from '../actions/breifcase'
import store from "../store";


function* fetchFeedPost(action) {
    try {
        const init = {
            body: action.query
        }
        const payload = yield API.post('get_posts', '', init)
        if (!payload.errorMessage) {
            yield put(fetchPost.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchPost.failure(error))
    }
}

function* sfetchPinnedPost(action) {
    try {
        const init = {
            body: action.query
        }
        const payload = yield API.post('get_posts', '', init)
        if (!payload.errorMessage) {
            yield put(fetchPinnedPost.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchPinnedPost.failure(error))
    }
}

function* createFeedPost(action) {
    try {
        const init = {
            body: action.query
        }

        const userDetails = yield createUserObjectwithParentOf(action.query.attributes.groupIds[0] || "", "Me")
        init.body.attributes.postedby = userDetails
        init.body.attributes.created_by = userDetails.id

        yield API.post('add_posts', '', init)
        console.log({init})
        const query = yield select(state => state.feed.query);

        const query1 = yield select(state => state.feed.pinned.query)

        yield query.page = 0;
        yield query1.page = 0;

        let isRefreshed = true

        yield put(createPost.success());
        if (action.query.attributes.inDraft) {
            isRefreshed = false
            yield put(showToastMessage({message: 'your draft post will be visible in breifcase', type: 'success'}));
        }else if(action.query.attributes && action.query.attributes.scheduleDateTime) {
            isRefreshed = false
            yield put(showToastMessage({message: 'your schedule post will be visible in breifcase', type: 'success'}));
        } else if(action.query.attributes && (action.query.attributes.reviewerList || []).length) {
            isRefreshed = false
            yield put(showToastMessage({message: 'your review post will be visible in breifcase', type: 'success'}));
        }
        yield put(reset('managePost'));
        if(isRefreshed) {
            yield put(fetchPost.request(query));
            yield put(fetchPinnedPost.request(query1));
        }

    } catch (error) {

    }
}

function* createEditPost(action) {
    try {

        let post_time = new Date(action.query.post_time) > moment().utc() ? new Date(action.query.post_time) : moment.utc()
        const init = {
            body: {
                id: action.query.id,
                attributes: action.query.attributes,
                media: action.query.media,
                post_time
            }
        }

        const { status, errorMessage } = yield API.post('edit_posts', '', init)

        if (status === "success") {
            const groups = yield select(state => (state.currentUser.newUserInfo.groupList || []).map(group => group.id))
            const query = yield select(state => state.feed.query);
            const query1 = yield select(state => state.briefcase.draft.query);
            const query3 = yield select(state => state.briefcase.schedule.query);
            const query4 = yield select(state => state.briefcase.review.query);
            const query2 = yield select(state => state.feed.pinned.query)


            yield query.page = 0;
            yield query.groups = (!!(query.groups || []).length) ? query.groups : groups || []
            yield query1.page = 0;
            yield query1.groups = (!!(query.groups || []).length) ? query1.groups : groups || []
            yield query2.page = 0;
            yield query2.groups = (!!(query.groups || []).length) ? query1.groups : groups || []
            yield query3.page = 0;
            yield query3.groups = (!!(query.groups || []).length) ? query1.groups : groups || []
            yield query4.page = 0;
            yield query4.groups = (!!(query.groups || []).length) ? query1.groups : groups || []

            if(action.query.isFromBriefCase) {
                yield put(fetchDraftPost.request(query1))
                yield put(fetchSchedulePost.request(query3))
                yield put(fetchReviewPost.request(query4))
            } else {
                yield put(fetchPost.request({ ...query, groups: query1.groups }))
                yield put(fetchPinnedPost.request(query2));
            }

            yield put(closeDialogBox());
            yield put(editPost.success());
        } else {
            const error = { message: errorMessage }
            throw error
        }

    } catch (error) {
        console.log(error.message)
    }
}

function* createDeletePost(action) {
    const init = {
        body: {
            id: action.query.id
        }
    }
    const query = yield select(state => state.feed.query);
    const query1 = yield select(state => state.briefcase.draft.query);
    const query2 = yield select(state => state.briefcase.schedule.query);
    const query3 = yield select(state => state.briefcase.review.query);
    const query4 = yield select(state => state.feed.pinned.query)

    yield query1.page = 0;
    yield query2.page = 0;
    yield query3.page = 0;
    yield query4.page = 0;

    yield API.post('delete_posts', '', init)
    yield put(deletePost.success(init.body))

    if(action.query.isFromBriefCase) {
        yield put(fetchDraftPost.request(query1))
        yield put(fetchSchedulePost.request(query2))
        yield put(fetchReviewPost.request(query3))
    } else {
        yield put(fetchPost.request(query))
        yield put(fetchPinnedPost.request(query4))
    }
}

function* createSaveComment(action) {
    try {
        const init = {
            body: {
                id: action.query.postid,
                comment: {
                    commentText: action.query.comment,
                    commentedBy: yield createUserObjectwithParentOf(action.query.groupId || "", "Me")
                }
            }
        }

        const posts = yield select(state => state.feed.posts);
        const post = posts.find(post => post.id === action.query.postid);

        const response = yield API.post('add_comments', '', init);
        if(response.status === "success") {
            post.user_comments = {
                ...post.user_comments,
                [response.data]: {comment: init.body.comment}
            }
            yield put(saveComment.success(post));
        }

    } catch (error) {

    }
}

function* createUpdateComment(action) {
    try {
        const init = {
            body: action.query
        }
        const posts = yield select(state => state.feed.posts);
        const post = posts.find(post => post.id === action.query.id);

        const response = yield API.post('edit_comments', '', init);
        if(response.status === "success") {
            post.user_comments[action.query.comment_id] = { ...post.user_comments[action.query.comment_id], comment: { ...post.user_comments[action.query.comment_id].comment, commentText: action.query.comment.commentText } }
            yield put(updateComment.success(post));
        }
    } catch (error) {

    }
}

function* createDeleteComment(action) {
    try {
        const init = {
            body: {
                id : action.query.postid,
                comment_id : action.query.comment_id
            }
        }
        const posts = yield select(state => state.feed.posts);
        const post = posts.find(post => post.id === action.query.postid);

        const response = yield API.post('delete_comments', '', init);
        if(response.status === "success") {
            delete post.user_comments[action.query.comment_id]
            yield put(deleteComment.success(post));
        }
    } catch (error) {

    }
}

export const createUserObjectwithParentOf = async (groupid = null, userid = "Me") => {
    try {
        const newUserInfo = store.getState().currentUser.newUserInfo;
        const { user: { attributes, id, child_ids }, usercache, childgroupcache } = newUserInfo || {}
        let userState = {}
        var userObj = {}
        console.log('userid', userid)
        if (userid === "Me") {
            userState = attributes
            userState['id'] = id
        } else {
            if (usercache[userid] !== undefined) {
                userState = usercache[userid]
            } // else return getAlumniUserObject(userid)
        }
        let childGroupCache = childgroupcache
        console.log('userState', userState)
        userObj['id'] = userState.id
        userObj['firstName'] = userState.firstName
        userObj['lastName'] = userState.lastName
        userObj['profile_photo'] = userState.profile_photo
        // IF groupid is null then dont need to calculate parentof
        if (groupid == null) {
            userObj['display_name'] = userState.firstName + ' ' + userState.lastName
            userObj['parent_of'] = ''
            return userObj
        }
        // Get Parent of ......
        let parent_of = ''
        console.log('groupid', groupid)
        console.log('userState.childgroupcache', childGroupCache)
        let childCache = childGroupCache[groupid]
        let groupcacheKeys = Object.keys(childGroupCache[groupid])
        let mychildids = []
        console.log('childCache',childCache)
        mychildids = (userid === "Me") ? child_ids : userState.child_ids
        for (var x = 0; x < mychildids.length; x++) {
            let childid = mychildids[x]
            console.log('childid',childid, x)
            if (childid in childCache) {
                console.log('childCache[childid]',childCache[childid])
                parent_of = parent_of === '' ?
                    parent_of + childCache[childid].firstName + ' ' + childCache[childid].lastName[0] :
                    parent_of + ',' + childCache[childid].firstName + ' ' + childCache[childid].lastName[0]
            }
        }
        userObj['display_name'] = userState.firstName + ' ' + userState.lastName
        userObj['parent_of'] = parent_of.trim() === '' ? "" : ' (Parent of ' + parent_of + ')'
        console.log('userObj',userObj)
        return userObj
    } catch (e) {
        console.log('in Error')
        console.warn(e)
        throw(e)
        // return getAlumniUserObject("")
    }
}

function* createLikePost(action) {
    const data = yield createUserObjectwithParentOf(action.query.attributes.groupIds[0], "Me")
    data['reaction'] = ["like"]

    const init = {
        body: {
            id: action.query.id,
            user_reactions: data
        }
    }

    const posts = yield select(state => state.feed.posts);
    const post = posts.find(post => post.id === action.query.id);

    const response = yield API.post('post_like', '', init)
    if(response.status === "success") {
        post.reactions = {
            ...post.reactions,
            [data.id]: data
        }
        yield put(likePost.success(post));
    }
}

function* createUnlikePost(action) {
    const init = {
        body: {
            id: action.query.id,
            user_reactions: null
        }
    }

    const user = yield select(state => state.currentUser.newUserInfo.user);
    const posts = yield select(state => state.feed.posts);
    const post = posts.find(post => post.id === action.query.id);

    const response = yield API.post('post_like', '', init)
    if(response.status === "success") {
        post.reactions[user.id] = null
        yield put(unlikePost.success(post));
    }
}

function* fetchFeedPostWithFilter(action) {
    const query = yield select(state => state.feed.query);
    query.groups = action.payload;
    yield put(fetchPost.request(query))
}

function* feedPostView(action) {
    const init = {
        body: {
            id: action.query.id
        }
    }

    const posts = yield select(state => state.feed.posts);
    const post = posts.find(post => post.id === action.query.id);

    const response = yield API.post('post_view', '', init);
    if(response.status === "success") {
        post.views.total_count++
        yield put(getPostView.success(post))
    }
}

/** watcher  */

function* watcherPostView() {
    yield takeEvery(POST_VIEW[REQUEST], feedPostView)
}

function* watcherPostFilter() {
    yield takeEvery(APPLY_POST_FILTER, fetchFeedPostWithFilter)
}

function* watcherFetchFeedPost() {
    yield takeEvery(FETCH_POST[REQUEST], fetchFeedPost)
}

function* watcherFetchPinnedPost() {
    yield takeEvery(FETCH_PINNED_POST[REQUEST], sfetchPinnedPost)
}

function* watcherCreateFeedPost() {
    yield takeEvery(CREATE_POST[REQUEST], createFeedPost)
}

function* watcherEditPost() {
    yield takeEvery(EDIT_POST[REQUEST], createEditPost)
}

function* watcherDeletePost() {
    yield takeEvery(DELETE_POST[REQUEST], createDeletePost)
}

function* watcherSaveComment() {
    yield takeEvery(SAVE_COMMENT[REQUEST], createSaveComment)
}

function* watcherUpdateComment() {
    yield takeEvery(UPDATE_COMMENT[REQUEST], createUpdateComment);
}

function* watcherDeleteComment() {
    yield takeEvery(DELETE_COMMENT[REQUEST], createDeleteComment);
}

function* watcherLikeComment() {
    yield takeEvery(LIKE_POST[REQUEST], createLikePost);
}

function* watcherUnlikeComment() {
    yield takeEvery(UNLIKE_POST[REQUEST], createUnlikePost);
}

export default function* rootCache() {
    yield all([
        fork(watcherPostView),
        fork(watcherPostFilter),
        fork(watcherCreateFeedPost),
        fork(watcherEditPost),
        fork(watcherDeletePost),
        fork(watcherFetchFeedPost),
        fork(watcherFetchPinnedPost),
        fork(watcherSaveComment),
        fork(watcherUpdateComment),
        fork(watcherDeleteComment),
        fork(watcherLikeComment),
        fork(watcherUnlikeComment),
    ]);
}