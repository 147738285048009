import React from 'react';
import _ from 'lodash'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Card,
    Grid,
    CardHeader,
    CardContent,
    Tooltip,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    Typography,
    Container,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {red} from '@material-ui/core/colors';
import Launch from '@material-ui/icons/Launch';
import Assessment from '@material-ui/icons/Assessment'

import {
    MoreHoriz, FileCopy, Delete,
} from '@material-ui/icons';

import '../../../node_modules/ag-grid-community/dist/styles/ag-grid.css'
import '../../../node_modules/ag-grid-community/dist/styles/ag-theme-material.css';
import {email} from "../CreatePost/reduxForm/validation";

export default function EmailStats(props) {
    console.log(props)

    function getEmailStatus(parentId) {
        let email_status = ''
        let status = props.status[0].statuses[props.newUserInfo.usercache[parentId].email_id]
        if (status === undefined) {
            email_status = 'UNKNOWN'
        }
        if (status === null) {
            email_status = 'SENT'
        }
        if (status === "Delivery") {
            email_status = 'SENT'
        }
        if (status === "Bounce") {
            email_status = 'BOUNCED'
        }
        return email_status;
    }

    function getStudentsandChildren() {
        let data = []
        props.groups.forEach((grpId)=> {
            console.log('grpId', grpId)
            // ADD CHILDREN .....
            let childList = props.newUserInfo.childList
            console.log(childList)
            childList = _.filter(childList, function (o) {
                console.log(o)
                if (o.group_ids.includes(grpId)) {
                    return o
                }
            })
            childList.forEach((child) => {
                let row = {}
                let parents = []

                console.log(child)
                child.parents.forEach((parentId) => {
                    console.log(parentId)
                    if (props.newUserInfo.usercache[parentId] !== undefined) {
                        let parent = {}
                        let row = {}
                        console.log(props.newUserInfo.usercache[parentId].email_id)
                        parent['Email'] = props.newUserInfo.usercache[parentId].email_id
                        let email_status = getEmailStatus(parentId);

                        parent['EmailStats'] = email_status
                        row['GroupName'] = props.newUserInfo?.groupcache[grpId]?.attributes?.groupname
                        row['Student'] = child.firstName + ' ' + child.lastName
                        row['ParentName'] = props.newUserInfo.usercache[parentId].firstName + ' ' + props.newUserInfo.usercache[parentId].lastName
                        row['Email'] = props.newUserInfo.usercache[parentId].email_id
                        row['EmailStatus'] = email_status
                        data.push(row)
                    } else {
                        console.log('Could not find User - ' + parentId)
                    }
                })

            })

            // ADD STAFF .........
            console.log('props.newUserInfo.usergroupcache', props.newUserInfo.usergroupcache)

            for (const [key, obj] of Object.entries(props.newUserInfo.usergroupcache[grpId].staff)) {
                let row = {}
                row['GroupName'] = props.newUserInfo?.groupcache[grpId]?.attributes?.groupname
                row['Student'] = '-'
                row['ParentName'] = obj['firstName'] + ' ' + obj['lastName']
                row['Email'] = obj['email_id']
                row['EmailStatus'] = getEmailStatus(obj['id'])
                data.push(row)
            }
            console.log('data', data)
        })

        return (
            <div className="ag-theme-material" style={{height: 600, width: '100%'}}>

                <AgGridReact id={2} rowData={data}
                             rowSelection={'single'}
                             defaultColDef={{
                                 editable: false,
                                 sortable: true,
                                 flex: 1,
                                 minWidth: 100,
                                 filter: true,
                                 resizable: true,
                             }}
                >
                    <AgGridColumn headerName={'Class'} field={'GroupName'} sortable={true} filter={true}/>
                    <AgGridColumn headerName={'Student'} field={'Student'} sortable={true} filter={true}/>
                    <AgGridColumn headerName={'Parent/Staff'} field={'ParentName'} sortable={true} filter={true}/>
                    <AgGridColumn headerName={'Email'} field={'Email'} sortable={true} filter={true} width={300}/>
                    <AgGridColumn headerName={'Status'} field={'EmailStatus'} sortable={true} filter={true}/>
                </AgGridReact>

            </div>
        )
    }

    if (props.status.length === 0) {
        return (
        <div>No Data Found</div>
        )
    } else {
        return (
            <div>
                {getStudentsandChildren()}
                {/*{props.groups.map(grp => {*/}
                {/*    return (*/}
                {/*        <div>*/}
                {/*            {props.newUserInfo?.groupcache[grp]?.attributes?.groupname}*/}
                {/*            {getStudentsandChildren(grp)}*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*})}*/}
            </div>

        )
    }
}