import React, { Component } from 'react'

export class ProgressReport extends Component {
  render() {
    return (
      <div>
      Viral, Put the Progress Report Code Here
      </div>
    )
  }
}

export default ProgressReport
