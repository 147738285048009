import {
    Auth,
    API
} from 'aws-amplify';
import { getSchoolId } from "../component/SchoolProfile/components/GlobalComponents/GlobalFields"
import awsConfig from "../utils/aws-config";
const authenticateUserApi = (username, password) => {
    return Auth.signIn(username, password)
        .then(user => user)
        .catch(err => err)
}

const getUserDetail = () => {

    return API.get('get_user_info', '', '')
        .then(response => response)
        .catch(error => error)

}

const getNewUserDetail = () => {
    const init = {
        body: {
            //school_id: getSchoolId().toString()
            sys : "web"
        }
    }
    return API.post('get_new_user_info', '', init)
        .then(response => response)
        .catch(error => error)

}

const resetPassword = (user) => {
    const init = {
        body: {
            user
        }
    }
    return API.post('reset_password', '', init).then(res => res).catch(error => error)
}

const forgotPasswordSubmit = (username, code, new_password) => {
    return Auth.forgotPasswordSubmit(username, code, new_password)
}

function transformCreateSchoolToPayload(firstName, lastName, email, schoolName, schoolWebsite){
    const payload={};
    payload["user"] = {};
    payload["user"]["attributes"] = {};

    payload["user"]["attributes"]["email"] = email;
    payload["user"]["attributes"]["firstName"] = firstName;
    payload["user"]["attributes"]["lastName"] = lastName;
    payload["user"]["attributes"]["mobile"] = '';
    payload["user"]["username"] = email;

    payload["school"] = {};
    payload["school"]["attributes"] = {};
    payload["school"]["attributes"]["schoolName"] = schoolName;
    payload["school"]["attributes"]["schoolWebsite"] = schoolWebsite;

    return payload;
}

const createNewSchool = (firstName, lastName, email, schoolName, schoolWebsite) => {
    let body = transformCreateSchoolToPayload(firstName, lastName, email, schoolName, schoolWebsite)
    const init = {
            body,
            response: true
    }
    console.log(API)
    let url = awsConfig.aws_cloud_logic_custom[0].endpoint + '/school/create'
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body : JSON.stringify(body)
    };
    console.log(requestOptions)
    console.log(body)
    return fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data
        }).catch(error => {
                    console.log(error)
                    return error
                })

    // return API.post('generic', '/school/create',init)
    //     .then(res => {
    //         console.log(res)
    //         return res
    //     })
    //     .catch(error => {
    //         console.log(error)
    //         return error
    //     })
}

const authService = {
    authenticateUserApi,
    getUserDetail,
    getNewUserDetail,
    resetPassword,
    forgotPasswordSubmit,
    createNewSchool
}

export default authService;