import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NumericInput from "../../common/numericInput/NumericInput";
import { Grid, withStyles } from '@material-ui/core';
import {getCurrentSchoolId} from "../../../utils/utils";

const styles = theme => ({
    textAlign: {
        textAlign: 'center',
    },
    margin: {
        margin: theme.spacing(1),
        height: 35
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

class Rsvp extends React.Component {

    static defaultProps = {
        readOnly: false
    }

    state = {
        will_attend: 'No',
        adults: 0,
        children: 0,
        school_id: getCurrentSchoolId(this.props.currentUser || {})
    }

    handleClick = (value) => (event) => {
        this.setState({
            will_attend: value
        }, () => {
            if (value !== "Yes") {
                const data = {
                    event_id: this.props.eventId,
                    ...this.state
                }
                this.props.updateRSVP(data);
            }
        })
    };

    handleNumericInput = (name) => (value) => {
        this.setState({ [name]: value })
    }

    handleConfirmButton = (event) => {
        event.preventDefault();
        const data = {
            event_id: this.props.eventId,
            // event_type: 2,
            ...this.state,
        }
        this.props.updateRSVP(data);
    }

    render() {
        const { will_attend } = this.state;
        const { classes, readOnly } = this.props;
        return (
            <>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                >
                    <Grid container item xs={6} direction="row" justify="space-evenly">
                        <Button
                            variant="outlined"
                            className="yesbutton"
                            disabled={readOnly}
                            onClick={this.handleClick("Yes")}
                        >
                            Yes
                        </Button>

                        <Button
                            variant="outlined"
                            className="maybebutton"
                            disabled={readOnly}
                            onClick={this.handleClick("Maybe")}
                        >
                            May Be
                        </Button>
                        <Button
                            variant="outlined"
                            className="nobutton"
                            disabled={readOnly}
                            onClick={this.handleClick("No")}
                        >
                            No
                        </Button>
                    </Grid>
                    {will_attend === "Yes" &&
                        (<>
                            <Grid container item xs={12} justify="center">
                                <Typography color="primary" variant="h6" className="rsvpYesTitle">
                                    Select the total number of people attending
                                    </Typography>
                            </Grid>
                            <Grid container item xs={2} justify="center" spacing={1}  >
                                <Grid container item xs={6} direction="column"  >
                                    <span className={classes.textAlign} >Child(ren)</span>
                                    <NumericInput onChange={this.handleNumericInput('children')} />
                                </Grid>

                                <Grid container item xs={6} direction="column" >
                                    <span className={classes.textAlign} >Adult(s)</span>
                                    <NumericInput onChange={this.handleNumericInput('adults')} />
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} justify="center">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    aria-label="Confirm"
                                    className={classes.margin}
                                    onClick={this.handleConfirmButton}
                                >
                                    Confirm
                                </Button>
                            </Grid>
                        </>)
                    }

                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(Rsvp);