import React from 'react';
import "../index.css"


const styles = ({
    root: {
        flexGrow: 1,
    },
    table : {

    },
    rowheader : {
        backgroundColor : 'lightgray',
        textAlign : 'center',
        height : "10px",
        fontSize : 8,
        borderWidth : '1px' ,
        borderColor : 'black'
    },
    
    gutter : {
        backgroundColor : 'white',
        width: 10,
        borderColor : 'black',
        borderRightWidth : '0px' ,
        borderLeftWidth : '1px' ,
        borderTopWidth : '0px',
        borderBottomWidth : '0px',

        height: 40
    },
    slicedefault : {
        width : 100,

        borderWidth : '1px' ,
        fontSize : 8
        
    },
    sliceprogress : {
        width : 100,
        backgroundColor : '#CCFFCB',
        fontSize : 8
    },
    slicecompleted : {
        width : 100,
        backgroundColor : '#75DBCD',
        fontSize : 8
    },
    slicepublished : {
        width : 100,
        backgroundColor : '#7371FC',
        fontSize : 8
    },
    rowslices : {
        textAlign : 'center',
        borderColor : 'gray',
        border : '1px' ,
       
    }

});

class ProgressReportStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slicestatus : {},
        }
    };

    componentDidMount() {
        let {module} = this.props
        console.log(module)
        let slicestatus = {}
        for (var i =0; i< module.data.length; i++ ){
            //console.log(module.data[i])
            let year = module.data[i].year
            let slice = module.data[i].slice
            !(year in slicestatus) && (slicestatus[year] = {})
            !(slice in slicestatus[year]) && (slicestatus[year][slice] = {})
            slicestatus[year][slice] = module.data[i]
        }
        //console.log(slicestatus)
        this.setState({
            slicestatus
        })

    }

    renderyears(){
        let {module} = this.props
        var yearsrender = () => {
            var data = []
            for(var j = 1; j < module.module_years+1; j++){
                
               
                    data.push(<td style={{borderWidth : 1, borderColor : 'black'}} colSpan={module.module_slices}>Year {j}</td>)
                    data.push(<td style={styles.gutter} >&nbsp;</td>)
    
            }
            return data
        }
        return yearsrender()
    }

    getStatusCell(year,slice){
        if(Object.keys(this.state.slicestatus).length == 0){
            return (<td style={styles.slicedefault}>Not Started</td>)
        }else{
            if ((this.state.slicestatus[year] == undefined) || (this.state.slicestatus[year][slice] == undefined)){
                // console.log('-----------------------')
                // console.log(this.state.slicestatus)
                // console.log('yr->' + year + 'slc->' + slice)
                // console.log(this.props.module)
                return (<td style={styles.slicedefault}>Not Started</td>)
                //console.log('-----------------------')
            }else{
                if (this.state.slicestatus[year][slice].status === 1){
                    return(<td style={styles.sliceprogress}>In Progress</td>)
                }
                if (this.state.slicestatus[year][slice].status === 2){
                    return(<td style={styles.slicecompleted}>Completed</td>)
                }
                if (this.state.slicestatus[year][slice].status === 3){
                    return(<td style={styles.slicepublished}>Published</td>)
                }
            }
        }
    }

    renderslices() {
        let {module} = this.props
        var slicesrender = () => {
            var data = []
            for(var j = 1; j < module.module_years+1; j++){
                for(var i = 1; i < module.module_slices+1; i++){
                    data.push(this.getStatusCell(j,i))  
                }
                data.push(<div style={styles.gutter}>&nbsp;</div>)
            }
            return data
        }
        return slicesrender()
    }


    render() {
        let {module} = this.props
        return (
            <div style={{paddingTop : 5, paddingBottom : 10}}>
                
                <table style={styles.table}>
                    <tr style={styles.rowheader}>
                        {this.renderyears()}
                    </tr>
                    <tr style={styles.rowslices}>
                        {this.renderslices()}
                    </tr>
                </table>
            </div>
        )
    }
}

export default ProgressReportStatus;