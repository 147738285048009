import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {
    TableFooter,
    Table, Checkbox, Typography
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import './mycss.css';


function CheckBoxHeader(props) {
  const { semesters, onCheckBoxChange, selectedYearSlice, categories } = props;

  const textHeader = () => {
      let text = ""

      categories.forEach((x, index) => {
          x.grid.forEach((y, subIndex) => {
              if((index === 0 && subIndex === 0) || (y.skill || "").length > (text || "").length) {
                  text = y.skill
              }
          })
      })

      return text
  }

  return (
    <Table
      style={{
        paddingTop: '-10px',
        marginTop: '-15px',
        backgroundColor: '#FAFAFA',
      }}
      stickyHeader={true}
      className="progress-report-table"
    >
      <TableFooter>
        <TableRow className="mystrip">

          <TableCell
            component="td"
            scope="row"
            colSpan={4}
            className="mystrip"
            style={{
              padding: '5px',
            }}
          >
            <span
              style={{
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'flex-start',
                paddingLeft: '10px',
              }}
            >
              <Typography variant="subtitle1" style={{ fontSize: '12px', visibility: "hidden" }}>
                {textHeader()}
              </Typography>
            </span>
          </TableCell>

          {semesters.map((semestersOfYears, i) =>
            semestersOfYears.map((semester, index) => {
              const year = i + 1
              const slice = index + 1
              const checked = (selectedYearSlice || []).some(x => (x || []).toString() === `${year}${slice}`)
              return (
                <TableCell
                  scope="col"
                  align="center"
                  className="mystrip checkbox"
                  key={`xx${index}`}
                  style={{ fontWeight: 'bold', padding: '5px' }}
                >
                  <Checkbox
                      checked={checked}
                      onChange={() => onCheckBoxChange(i + 1, index + 1)}
                  />
                </TableCell>
              );
            }),
          )}
        </TableRow>
      </TableFooter>
    </Table>
  );
}

CheckBoxHeader.propTypes = {
  semesters: PropTypes.any,
  onCheckBoxChange: PropTypes.func,
  selectedYearSlice: PropTypes.array
};

export default CheckBoxHeader;
