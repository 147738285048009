const validateData = (name, value) => {
    switch (name) {
        case "firstName":
            if (!value) {
                return "First name is Required";
            } else {
                return "";
            }
        case "lastName":
            if (!value) {
                return "Last name is Required";
            } else {
                return "";
            }
        case "status":
            if (!value) {
                return "Status is Required";
            } else {
                return "";
            }
        // case "gender":
        //     if (!value) {
        //         return "Gender is Required";
        //     } else {
        //         return "";
        //     }
        // case "dateOfBirth":
        //     if (!value) {
        //         return "Date Of Birth is Required";
        //     } else {
        //         return "";
        //     }
        case "homeRoom":
            if (!value) {
                return "Home Room is Required";
            } else {
                return "";
            }
        // case "relation":
        //     if (!value) {
        //         return "Relation is Required";
        //     } else {
        //         return "";
        //     }
        // case "isPickup":
        //     if (!value) {
        //         return "Pickup is Required";
        //     } else {
        //         return "";
        //     }
        case "specialDiet":
            if (!value) {
                return "Special Diet is Required";
            } else {
                return "";
            }
        case "height":
            if (!value) {
                return "Height is Required";
            } else {
                return "";
            }
        case "weight":
            if (!value) {
                return "Weight is Required";
            } else {
                return "";
            }
        case "medications":
            if (!value) {
                return "Medications is Required";
            } else {
                return "";
            }
        case "diseases":
            if (!value) {
                return "Diseases is Required";
            } else {
                return "";
            }
        case "vaccinations":
            if (!value) {
                return "Vaccinations is Required";
            } else {
                return "";
            }
        case "bloodGroup":
            if (!value) {
                return "Blood Group is Required";
            } else {
                return "";
            }
        case "doctorName":
            if (!value) {
                return "Doctor Name is Required";
            } else {
                return "";
            }
        // case "address1":
        //     if (!value) {
        //         return "Address1 is Required";
        //     } else {
        //         return "";
        //     }
        // case "city":
        //     if (!value) {
        //         return "City is Required";
        //     } else {
        //         return "";
        //     }
        // case "state":
        //     if (!value) {
        //         return "State is Required";
        //     } else {
        //         return "";
        //     }
        // case "postalCode":
        //     if (!value) {
        //         return "Postal Code is Required";
        //     } else {
        //         return "";
        //     }
        case "role":
            if (!(value || []).length) {
                return "Role is Required";
            } else {
                return "";
            }
        case "email":
            if (!value) {
                return "Email is Required";
            } else if (
                !value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)
            ) {
                return "Enter a valid email address";
            } else {
                return "";
            }
        // case "mobile":
        //     if (!value) {
        //         return "Mobile number is Required";
        //     } else if (!value.toString().match(/^([0-9]{10})$/)) {
        //         return "Mobile Number can be must be 10 digit.";
        //     } else {
        //         return "";
        //     }
        default: {
            return "";
        }
    }
};

export { validateData };