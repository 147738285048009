import React from 'react';
import {connect} from 'react-redux'
import { List, Icon, Button, Modal, Tooltip, Switch } from 'antd'
import { Grid } from "@material-ui/core";
import {
    getSchoolId, getUserDetailsByUserId, getUserRole,
    openNotificationWithIcon,
    SelectLabelInput, SelectParentInput, SelectUserRoleInput,
    TextLabelInput
} from "../GlobalComponents/GlobalFields";
import {createChild, createUser} from "../../ApiServices";
import * as routes from "../../../../routes/path";
import {studentStatusList} from "../GlobalComponents/optionValues";


class AddQuickMultipleStudent extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            studentList: [
                { firstName: '', lastName: '', homeRoom: '', status: 'Active', isExistingParent: false, parentFirstName: '', parentLastName: '', parentEmail: '', parentsId: '', parentsRole: [] },
                { firstName: '', lastName: '', homeRoom: '', status: 'Active', isExistingParent: false, parentFirstName: '', parentLastName: '', parentEmail: '', parentsId: '', parentsRole: [] },
                { firstName: '', lastName: '', homeRoom: '', status: 'Active', isExistingParent: false, parentFirstName: '', parentLastName: '', parentEmail: '', parentsId: '', parentsRole: [] },
            ],
            errors: {},
            parentsDetail: {},
            selectedParentId: '',
            selectedChildIndex: -1,
            isModal: false,
            isImageUploading: false
        }
    }

    onChange = (event, index) => {
        let { studentList } = this.state
        const { name, value } = event.target
        studentList[index][name] = value
        this.setState({
            studentList
        })
    }

    onRemoveStudent = (index) => {
        let { studentList } = this.state
        studentList.splice(index, 1)
        this.setState({
            studentList
        })
    }

    onAddStudent = () => {
        let { studentList } = this.state
        studentList.push({ firstName: '', lastName: '', homeRoom: '', status: 'Active', isExistingParent: false, parentFirstName: '', parentLastName: '', parentEmail: '', parentsId: '', parentsRole: [] })
        this.setState({
            studentList
        })
    }

    onDisabled = () => {
        const { studentList } = this.state
        let isDisabled = true
        studentList.forEach(student => {
            const { firstName, lastName, homeRoom, status, parentFirstName, parentLastName, parentEmail, parentRole } = student
            if(firstName && lastName && homeRoom && status && parentFirstName && parentLastName && parentEmail && (parentRole || []).length) {
                isDisabled = false
            }
        })
        return isDisabled
    }

    onSaveStudent = () => {
        const { studentList } = this.state

        if((studentList || []).length) {
            let isInvalid = false
            for(let i in studentList) {
                const { firstName, lastName, homeRoom, status, parentFirstName, parentLastName, parentEmail, parentRole } = studentList[i]
                if(firstName && lastName && homeRoom && status && parentFirstName && parentLastName && (parentRole || []).length && !parentEmail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
                    isInvalid = true
                    openNotificationWithIcon('error', `Please enter valid parent email ${studentList[i].parentEmail}`)
                }
            }
            if(isInvalid) return;
        }

        const isDuplicate = this.onCheckDuplicateEmail()
        if((isDuplicate || []).length) {
            let duplicate = false
            for(let i in isDuplicate) {
                if(isDuplicate[i]) {
                    duplicate = true
                    openNotificationWithIcon('error', `Parents Email ${isDuplicate[i]} should be unique`)
                }
            }
            if(duplicate) return
        }

        let studentPayload = []
        let parentPayload = []

        studentList.forEach(student => {
            const { firstName, lastName, homeRoom, status, parentFirstName, parentLastName, parentEmail, parentRole, parentsId } = student

            if(firstName && lastName && homeRoom && status && parentFirstName && parentLastName && parentEmail && (parentRole || []).length) {
                const studentFiled = {
                    firstName, lastName, dateOfBirth: null, gender: '', status, profile_photo: '',
                    primaryLanguage: "", ethnicity: "", homeLanguage: "", othersRoom: [], homeRoom
                }

                let parentObject = {
                    role: {[getSchoolId()]: getUserRole(parentRole || [])},
                    child_ids: [],
                    username: parentEmail,
                    attributes: {
                        firstName: parentFirstName, lastName: parentLastName, email: parentEmail, mobile: '', isPickup: '', relation: '', profile_photo: '', isNewsletterReviewer: false
                    },
                    parentsId
                }
                const studentObject = {
                    attributes: {
                        scheduleList: [],
                        primaryAddress: {},
                        secondaryAddressList: [],
                        medicalField: {},
                        ...studentFiled
                    },
                    auth_users: [],
                    group_ids: [Number(homeRoom)],
                    parents: [],
                    school_id: getSchoolId()
                }
                parentPayload.push(parentObject)
                studentPayload.push(studentObject)
            }
        })

        console.log({studentPayload, parentPayload})

        Modal.confirm({
            title: `Do you want to create ${(studentPayload || []).length} new student?`,
            onOk: async ()  => {
                return new Promise(async (resolve, reject) => {
                    let created = []
                    for(let i in parentPayload) {
                        if(parentPayload[i].parentsId) {
                            studentPayload[i].parents = [parentPayload[i].parentsId]
                        } else {
                            const parentResponse = await createUser(parentPayload[i])
                            if(parentResponse && parentResponse.status === "success") {
                                studentPayload[i].parents = [parentResponse.user_id]
                            } else {
                                const message = parentResponse.message === "User already exists in system." ?
                                    "Email already exists in System for another user. Please use different email" : parentResponse.message
                                openNotificationWithIcon('error', message || 'Something went wrong')
                            }
                        }
                    }

                    for(let i in studentPayload) {
                        const studentResponse = await createChild(studentPayload[i])
                        if(studentResponse && studentResponse.status === "success") {
                            created.push(i)
                        } else {
                            openNotificationWithIcon('error', studentResponse.message || 'Something went wrong')
                        }
                    }

                    if((created || []).length) {
                        openNotificationWithIcon('success', "Students create successfully")
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
                        setTimeout(() => {
                            this.props.history.push(routes.STUDENT_DASHBOARD)
                        }, 500)
                    }

                }).catch(() => console.log('Oops errors!'));
            },
            okText: 'Create New Student',
            onCancel() {},
        });
    }

    onCheckDuplicateEmail = () => {
        const { studentList } = this.state
        const uniq = (studentList || [])
            .map((name) => {
                return {
                    count: 1,
                    name: name.parentEmail.toLowerCase()
                }
            })
            .reduce((a, b) => {
                a[b.name] = (a[b.name] || 0) + b.count
                return a
            }, {})

        return Object.keys(uniq).filter((a) => uniq[a] > 1)
    }

    onExistingParentChange = (value, index) => {
        let { studentList } = this.state
        studentList[index].isExistingParent = value
        studentList[index].parentFirstName = ''
        studentList[index].parentLastName = ''
        studentList[index].parentEmail = ''
        studentList[index].parentRole = []
        studentList[index].parentsId = ''
        this.setState({
            studentList,
            selectedChildIndex: value ? index : -1,
            isModal: !!value,
            selectedParentId: ''
        })
    }

    onSelectParent = () => {
        let { parentsDetail, selectedChildIndex, studentList } = this.state
        if(selectedChildIndex !== -1) {
            studentList[selectedChildIndex].parentFirstName = parentsDetail.firstName
            studentList[selectedChildIndex].parentLastName = parentsDetail.lastName
            studentList[selectedChildIndex].parentEmail = parentsDetail.email
            studentList[selectedChildIndex].parentRole = parentsDetail.role
            studentList[selectedChildIndex].parentsId = parentsDetail.id
        }
        this.setState({
            isModal: !this.state.isModal,
            parentsDetail: {},
            selectedChildIndex: -1,
            selectedParentId: ''
        })
    }

    onCloseModal = () => {
        this.setState({
            isModal: !this.state.isModal,
            parentsDetail: {},
            selectedChildIndex: -1,
            selectedParentId: ''
        })
    }

    onGetUserDetail = async () => {
        let { selectedParentId, parentsDetail } = this.state
        this.setState({
            isParentSearching: true
        })
        const data = await getUserDetailsByUserId(selectedParentId)
        if(data) {
            const { firstName, lastName, email } = data.attributes || {}
            parentsDetail.firstName = firstName
            parentsDetail.lastName = lastName
            parentsDetail.email = email
            parentsDetail.id = data.id

            let roles = []
            const object = (data.role || {})[getSchoolId()] || {}
            Object.keys(object || {}).forEach(x => {
                if(data.role[getSchoolId()][x]) {
                    roles.push(x)
                }
            })
            parentsDetail.role = roles
        }
        this.setState({
            parentsDetail,
            isParentSearching: false
        })
    }

    render() {
        const { currentUser: { newUserInfo: { groupcache: groupList = [], usersList = [] } } } = this.props;
        const { studentList, isModal, selectedParentId, isParentSearching, parentsDetail }  = this.state
        return(
            <div className="student-dashboard">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <div className="back">
                                <Tooltip title={"Back To Student Dashboard"} placement="bottomLeft">
                                    <Icon
                                        type="left"
                                        className="color-white cursor-pointer"
                                        onClick={() => this.props.history.push(routes.STUDENT_DASHBOARD)}
                                    />
                                </Tooltip>
                            </div>
                            <h3 className="mb-0 color-white">Add New Students</h3>
                        </div>
                    </div>
                </div>
                <div className={`student content`}>
                    <Grid item xs={12}>

                        <div className="student-table-header" style={{background: '#ebeff4'}}>
                            <List.Item>
                                <div style={{width: 50}}>{" "}</div>
                                <List.Item.Meta title={"First Name"}/>
                                <List.Item.Meta title={"Last Name"}/>
                                <List.Item.Meta title={"Home Room"}/>
                                <List.Item.Meta title={"Status"}/>
                                <List.Item.Meta title={"Existing Parent"}/>
                                <List.Item.Meta title={"Parents First Name"}/>
                                <List.Item.Meta title={"Parents Last Name"}/>
                                <List.Item.Meta title={"Parents Email"}/>
                                <List.Item.Meta title={"Parents Role"}/>
                                <div style={{width: 50}}>{" "}</div>
                            </List.Item>
                        </div>

                        <Grid item xs={12}>
                            <div>
                                <List
                                    className="demo-loadmore-list"
                                    loading={false}
                                    itemLayout="horizontal"
                                    dataSource={studentList || []}
                                    renderItem={(record, index) => {
                                        const { firstName = "", lastName = "", status = "", homeRoom, parentFirstName = "", parentLastName = "", parentEmail = "", parentRole = [], isExistingParent } = record || {}
                                        return(
                                            <List.Item>
                                                <div className="text-center" style={{width: 50}}>
                                                    {index + 1}
                                                </div>
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="firstName"
                                                                value={firstName}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="First Name"
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="lastName"
                                                                value={lastName}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="Last Name"
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <SelectLabelInput
                                                                name="homeRoom"
                                                                onChange={(value) => this.onChange({target: { name: "homeRoom", value }}, index)}
                                                                options={groupList || {}}
                                                                value={homeRoom}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <SelectLabelInput
                                                                name="status"
                                                                onChange={(value) => this.onChange({target: { name: "status", value }}, index)}
                                                                placeholder="Select a Status"
                                                                options={studentStatusList || []}
                                                                value={status}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div>
                                                            <Switch onClick={(checked) => this.onExistingParentChange(checked, index)} checked={isExistingParent || false} size="small" />
                                                            {
                                                                isExistingParent ?
                                                                    <div>
                                                                        <small className="cursor-pointer" onClick={() => this.onExistingParentChange(true, index)} style={{color: '#007ad9'}}>Change Parent</small>
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="parentFirstName"
                                                                value={parentFirstName}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="Parent First Name"
                                                                disabled={isExistingParent}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="parentLastName"
                                                                value={parentLastName}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="Parent Last Name"
                                                                disabled={isExistingParent}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="parentEmail"
                                                                value={parentEmail}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="Parents Email"
                                                                disabled={isExistingParent}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <SelectUserRoleInput
                                                                name="parentRole"
                                                                value={parentRole}
                                                                onChange={(value) => this.onChange({target: { name: "parentRole", value }}, index)}
                                                                placeholder="Parents Role"
                                                                disabled={isExistingParent}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <div className="text-center" style={{width: 50}}>
                                                    <Icon type={"delete"} className="color-red fs-16" onClick={() => this.onRemoveStudent(index)}/>
                                                </div>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>

                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                            <div>
                                <Button
                                    size="default"
                                    className={'review-button'}
                                    onClick={this.onAddStudent}
                                >
                                    + Add Another Student
                                </Button>
                            </div>
                            <div>
                                <Button
                                    size="default"
                                    className={'review-button'}
                                    disabled={this.onDisabled()}
                                    onClick={this.onSaveStudent}
                                >
                                    Save Students
                                </Button>
                            </div>
                        </div>

                    </Grid>
                </div>

                <Modal
                    title={"Select Existing parent"}
                    width={450}
                    visible={isModal}
                    onOk={this.onSelectParent}
                    onCancel={this.onCloseModal}
                    okButtonProps={{disabled: !parentsDetail.id}}
                    okText="Select Parent"
                >
                    <div>
                        <div className="rounded-1rem bg-white shadow" style={{padding: 20, marginBottom: 20}}>
                            <SelectParentInput
                                label="Select Parent"
                                onChange={(value) => this.setState({selectedParentId: value})}
                                placeholder="Select a Parent"
                                options={usersList || []}
                                value={selectedParentId || ''}
                                style={{width: 300}}
                            />
                            <div style={{marginTop: 15}}>
                                <Button
                                    disabled={!selectedParentId || isParentSearching}
                                    onClick={this.onGetUserDetail}
                                    type='primary'
                                    loading={isParentSearching}
                                >
                                    Select
                                </Button>
                            </div>
                        </div>

                        {
                            parentsDetail.id ?
                                <div>
                                    <div className="flex"><div className="fw-600 w-100-px">First Name :</div><div>{parentsDetail.firstName || "-"}</div></div>
                                    <div className="flex"><div className="fw-600 w-100-px">Last Name :</div><div>{parentsDetail.lastName || "-"}</div></div>
                                    <div className="flex"><div className="fw-600 w-100-px">Email :</div><div>{parentsDetail.email || "-"}</div></div>
                                </div> : null
                        }

                    </div>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser,
})

export default connect(mapStateToProps, null)(AddQuickMultipleStudent)