import React from 'react';
import {Document, Page, Text, View, StyleSheet} from '@react-pdf/renderer';
import moment from "moment";

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 20
const COLN_WIDTH = (100 - COL1_WIDTH) / 4
const styles = StyleSheet.create({
    body: {
        padding: 10
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol1Header: {
        width: COL1_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol1: {
        width: COL1_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500
    },
    tableCell: {
        margin: 5,
        fontSize: 10
    }
});


export const PrintVolunteerPreview = (props) => {
    return (
        <Document>
            <Page style={styles.body}>
                {
                    props.event.volunteer.map((data, index) => {

                        return (
                            <View>
                                <Text key={index.toString()}
                                      style={{color: "#05b9a4", paddingBottom: 10, paddingTop: 10}}>
                                    {moment(data.event_date).format("LL")}
                                </Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol1Header}>
                                            <Text style={styles.tableCellHeader}>Time</Text>
                                        </View>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.tableCellHeader}>Request</Text>
                                        </View>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.tableCellHeader}>Comment</Text>
                                        </View>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.tableCellHeader}>Filled</Text>
                                        </View>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.tableCellHeader}>Participants</Text>
                                        </View>
                                    </View>

                                    {
                                        data.slotTime.map(slotTimeData => (
                                            slotTimeData.spot !== undefined && slotTimeData.spot.map((sportData, index) => (
                                            <View style={styles.tableRow} key={index}>
                                                <View style={styles.tableCol1}>
                                                    <Text style={styles.tableCell}>
                                                        {moment(slotTimeData.start_time).format("hh:mm a")}
                                                        -
                                                        {moment(slotTimeData.end_time).format("hh:mm a")}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>
                                                        {sportData && sportData.description}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>
                                                        {sportData && sportData.comment}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>
                                                        {`${props.eventResponse && props.eventResponse.userResponse.find(res => res.spotId === sportData.id) ?
                                                            props.eventResponse && props.eventResponse.userResponse.find(res => res.spotId === sportData.id).childs.length : 0} Out of ${sportData.person}`}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>
                                                        {
                                                            props.eventResponse && props.eventResponse.userResponse.find(res => res.spotId === sportData.id) &&
                                                            props.eventResponse && props.eventResponse.userResponse.find(res => res.spotId === sportData.id).childs.map((child, index) =>  (
                                                                `${child && child.firstname && child.firstname} ${child && child.lastname || ""}${props.eventResponse.userResponse.find(res => res.spotId === sportData.id).childs.length === index +1 ? "" : "," }`
                                                            ))
                                                        }
                                                    </Text>
                                                </View>
                                            </View>

                                            ))
                                        ))
                                    }
                                </View>
                            </View>
                        )
                    })
                }
            </Page>
        </Document>
    )
};

