import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Moment from "react-moment";
import { withStyles } from "@material-ui/core/styles";
import {
    Field, Fields, FieldArray,
    Form
} from 'redux-form'

import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import blue from "@material-ui/core/colors/blue";
import AccessTime from "@material-ui/icons/AccessTime";
import VideocamIcon from "@material-ui/icons/Videocam";
import PDFIcon from "@material-ui/icons/DockTwoTone";
import InsertIcon from "@material-ui/icons/CameraAlt";
import ReplyIcon from "@material-ui/icons/Send";

import BootstrapInput from "../common/BootstrapInput";

import { Select, Input, FileUpload, DatePicker } from "./reduxForm";
import { POST_TYPE } from "./reduxForm/context";
import ImageList from "./ImageList";
import VideoList from "./VideoList";
import ReviewList from "./reduxForm/ReviewList";
import PinnedDialog from "./reduxForm/PinnedComponent";
import ChildList from "./reduxForm/ChildList";
import PDFList from './PDFList';
import userAvatar from "../../assets/images/user-avtar.png"
import { isUserParent } from "../../utils/utils"

const propTypes = {
    classes: PropTypes.object.isRequired
};

const ITEM_HEIGHT = 48;
const PaperProps = {
    style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 200,
    },
}

const MenuProps = { PaperProps }

const styles = theme => ({
    card: {
        backgroundColor: "#f6f6f6",
        maxWidth: 700,
    },
    textAreaStyle: {
        backgroundColor: "#fff",
        padding: "15px 20px",
        width: "100%"
    },
    chip: {
        margin: '0px !important',
        color: 'darkblue',
        cursor: "pointer",
    },
    absolute: {
        position: "absolute",
        bottom: theme.spacing(1) * 2,
        right: theme.spacing(1) * 3
    },

    buttonSuccess: {
        backgroundColor: blue[500],
        "&:hover": {
            backgroundColor: blue[700]
        }
    },
    postFooter: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "0px",
        paddingBottom: "0px",
        alignItems:'center'
    },
    fontBold: {
        fontWeight: 'bold'
    },
    cardBorder: {
        borderRadius: '10px'
    },
    header: {
        backgroundColor: '#e2e2e2',
        padding: '6px 13px'
    },
    mt5: {
        marginTop: '5px',
        lineHeight: 'normal'
    },
    postImage: {
        flexWrap: 'unset',
        marginTop: '10px'
    },
    avatar: {
        margin: '5px 0px 0px 15px'
    },
});

function ChildListData(props) {
    const {childids} = props;
    if(childids.input && childids.input.value && childids.input.value.length){
        props.getStudentInfo(childids.input && childids.input.value && childids.input.value.length)
    }else {
        props.getStudentInfo(null)
    }
    return null
}

class PostForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openStudentDialog: false,
        };
    }

    componentDidMount() {
        const { fetchStaffAction, GroupOptions, fetchStudentsAction, FormValues: { groupids } } = this.props;
        const groupid = localStorage.getItem('groupids');

        if (groupids) {
            fetchStaffAction.request(groupids);
            fetchStudentsAction.request(groupids);
        } else  if (groupid) {
            this.props.change('groupids', groupid);
            fetchStaffAction.request(groupid);
            fetchStudentsAction.request(groupid);
        } else if (GroupOptions.length > 0) {
            const value = GroupOptions[0].value
            fetchStaffAction.request(value);
            fetchStudentsAction.request(value);
        }
    }

    deleteScheduledTime = e => {
        this.props.change('posttime', '')
    };

    handleChangeGroupId = (value) => {
        const {
            fetchStudentsAction,
            fetchStaffAction,
        } = this.props;

        fetchStaffAction.request(value);
        fetchStudentsAction.request(value);
        localStorage.setItem('groupids', value)
    }

    handleStudent = (value) => {
        if (value === 'STUDENT') {
            this.setState({ openStudentDialog: true });
        } else {
            this.setState({ openStudentDialog: false });
        }
    }

    closeChildList = () => {
        this.setState({ openStudentDialog: false });
    }

    getStudentInfo = (count) =>{
        this.setState({
            totalStu: count || null
        })
    }

    render() {
        const { openStudentDialog, totalStu } = this.state;
        const { classes, currentUser: { newUserInfo: { user = {} } }, handleSubmit, FormValues, pristine, submitting } = this.props;
        const { attributes: { firstName = "", profile_photo = ""  } } = user || {};
        const { posttime = null, img = [], vid = [], pdf = [], groupids = null } = FormValues

        return (
            <Grid className={classes.card}>
                <Form onSubmit={handleSubmit(this.props.handleFormSubmit)}>
                    <Card className={classes.cardBorder}>
                        <Grid container className={classes.header} >
                            <Grid item xs={3}>
                                <Typography variant="subtitle1" className={classNames(classes.mt5, classes.fontBold)} >
                                    New Post
                                </Typography>
                            </Grid>

                            <Grid container item spacing={1} xs={9} justify="flex-end"  >
                                <Grid item>
                                    <Field
                                        name="groupids"
                                        component={Select}
                                        MenuProps={MenuProps}
                                        wrapperInput={<BootstrapInput name="groupids" />}
                                        options={this.props.GroupOptions}
                                        onSelectChange={this.handleChangeGroupId}
                                    />
                                </Grid>

                                <Grid item>
                                    <Field
                                        name="posttype"
                                        component={Select}
                                        MenuProps={MenuProps}
                                        wrapperInput={<BootstrapInput name="postType" />}
                                        options={POST_TYPE}
                                        totalStu={totalStu}
                                        onSelectChange={this.handleStudent}
                                    />

                                    {openStudentDialog &&
                                        <Fields
                                            names={['child_mode', 'childids']}
                                            component={ChildList}
                                            options={this.props.studentsList}
                                            groupids={groupids}
                                            handleClose={this.closeChildList}
                                        />}
                                    {
                                        !openStudentDialog &&
                                        <Fields
                                            names={['childids']}
                                            component={ChildListData}
                                            getStudentInfo={this.getStudentInfo}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.postImage}>
                            <Avatar
                                className={classes.avatar}
                                aria-label="Recipe"
                                src={profile_photo}
                            >
                                {(firstName || " ")[0]}
                            </Avatar>

                            <Field
                                name="post"
                                component={Input}
                                className={classNames("w-full", classes.textAreaStyle)}
                                placeholder="Write something.."
                                multiline
                                rows="4"
                                margin="none"
                                disableUnderline

                            />
                        </Grid>

                        {posttime && (
                            <Chip
                                icon={<AccessTime />}
                                label={<Moment format="MMMM Do YYYY, hh:mm a" date={posttime} />}
                                onDelete={this.deleteScheduledTime}
                                className={classes.chip}
                                variant="outlined"
                            />
                        )}

                        <FieldArray name="img" component={ImageList} list={img} />
                        <FieldArray name="vid" component={VideoList} list={vid} />
                        <FieldArray name="misc" component={PDFList} list={pdf} />

                        <Grid container className={classes.postFooter} >
                            <Grid item xs={11}>

                                { isUserParent(user) ? null :
                                    <Fields
                                        names={['review_mode', 'review_users']}
                                        component={ReviewList}
                                        options={this.props.staffList}
                                        groupids={groupids}
                                    />
                                }

                                { isUserParent(user) ? null :
                                    <Field
                                        name="posttime"
                                        component={DatePicker}
                                    />
                                }

                                <Field
                                    name="img"
                                    component={FileUpload}
                                    label="Attach Image"
                                    type="file"
                                    accept="image/*"
                                    imagePrev={true}
                                    multiple={true}
                                    icon={<InsertIcon style={{ fontSize: "small" }} />}
                                />

                                <Field
                                    name="vid"
                                    component={FileUpload}
                                    label="Attach Video"
                                    type="file"
                                    accept={[".mp4", ".mkv", ".avi"]}
                                    imagePrev={true}
                                    multiple={true}
                                    icon={<VideocamIcon style={{ fontSize: "small" }} />}
                                />

                                <Field
                                    name="misc"
                                    component={FileUpload}
                                    label="Attach Doc"
                                    type="file"
                                    accept={[".pdf", ".xls", ".doc", ".docx", ".xlsx"]}
                                    imagePrev={true}
                                    multiple={true}
                                    icon={<PDFIcon style={{ fontSize: "small" }} />}
                                />

                                { isUserParent(user) ? null :
                                    <Fields
                                        names={['pinned_mode', 'pinned_topic', 'pinned_until']}
                                        component={PinnedDialog}
                                        label="Pin Post"
                                    />
                                }
                            </Grid>

                            <Grid item xs={1} container justify="flex-end">
                                <IconButton
                                    type="submit"
                                    aria-label="Reply"
                                    color="primary"
                                    // style={{ transform: 'scaleX(-1)' }}
                                    disabled={pristine || submitting}
                                >
                                    <ReplyIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Form>
            </Grid>
        );
    }
}

PostForm.propTypes = propTypes;

export default withStyles(styles)(PostForm);
