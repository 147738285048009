import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import dates from "../common/dates";
import AddEventButton from "./addEventButton/AddEventButton";
import EventPreview from './EventPreview';
import Invitation from "./MyInVitiation";
import DraftInvitation from "./MyInVitiation/DraftInvitation";

import GroupImg from "../../assets/images/group.png";
import ParentImg from "../../assets/images/parents.png";
import Lounge from "../../assets/images/lounge.PNG";
import OrangeClass from "../../assets/images/orange_class.PNG";
import {isGenericEvent} from "../../shared/enums";

const localizer = momentLocalizer(moment);


const styles = theme => ({
  root: {
    width: "100%"
  },
  lineprogress: {
    top: 0
  },
  cardTitleText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  filter: {
    color: '#3f51b5',
    cursor: 'pointer'
  },
  tabRoot: {
    flexGrow: 1,
    marginBottom: 15
  },
  fab: {
    margin: theme.spacing.unit,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3,
    zIndex: 4
  }
});

function Event({ event: { attributes: { groups = [], event_title = "", event_color = "" } = {} } = {} }) {
  const avtarstyle = { width: "20px", height: "20px", marginRight: "2px", fontSize: "12px" }
  let image = '';
  let content = '';
  if ((groups || []).length === 1) {
    // if(event.groups[0].groupName === "Lounge"){
    //     image = Lounge;
    // }else if(event.groups[0].groupName === "Orange_Class"){
    //     image = OrangeClass;
    // }else {
    //     image = GroupImg;
    // }
    // content = (event.groups[0].groupName).charAt(0);
    if ((groups || [])[0].icon !== ''){
      image = Lounge
    } else {
      image = GroupImg
    }
  } else if ((groups || []).length > 1) {
    image = GroupImg;
  } else {
    image = ParentImg;
  }

  return (
      <span style={{ display: "flex" }}>
        <Avatar alt="group" src={image} style={avtarstyle}>{content}</Avatar>
        <strong style={{ color: event_color === "#000000" ? "white" : "black", fontSize: "12px" }}>{event_title || ""}</strong>
          {/* {event.desc && ":  " + event.desc} */}
      </span>
  );
}

function EventAgenda({ event }) {
  return (
      <span>
      <em style={{ color: "magenta" }}>{event.attributes.event_title}</em>
        {/* <p>{event.desc}</p> */}
    </span>
  );
}

function combineTimeAndDate(date, time) {
  const _date = moment(`${date}`).format('YYYY-MM-DD')
  const _time = moment(`${time}`).format('HH:mm:ss')
  return moment(`${_date} ${_time}`, 'YYYY-MM-DD HH:mm:ss').toDate()
}

class CalenderView extends React.Component {
  state = {
    anchorElFilter: null,
    filtertype: 'upcoming',
    defaultDate: new Date(),
    activeTab: 0
  }

  componentDidMount() {
    const { location: { search = "" } = {} } = this.props
    this.props.getEvent.request();
    this.props.resetStepperActiveStep();
    if(search) {
      const parsed = queryString.parse(search)
      if(parsed.id) {
        this.toggleEditModal({event_id: Number(parsed.id)})
      }
    }
  }

  componentWillMount() {
    const date = localStorage.getItem("defaultDate") || ""
    if(date) {
      this.setState({
        defaultDate: new Date(date)
      })
      localStorage.removeItem("defaultDate")
    }
  }

  toggleEditModal = event => {
    this.props.setCurrentEventId(event.event_id)
    this.props.openDialogAction('OPEN_PREVIEW_SCREEN')
  };

  onClickOnInviation = id => {
    const { events } = this.props;
    const event = events.find(event => event.event_id === id)
    this.toggleEditModal(event);
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    const backgroundColor = event.attributes.event_color === "#000000" ? "rgb(123 160 192)" : event.attributes.event_color || '#d6cbd4';
    const style = { backgroundColor: backgroundColor };
    return { style: style };
  };

  handleFilter = (event) => {
    this.setState({ anchorElFilter: event.currentTarget });
  }

  handleFilterClick = (type) => () => {
    this.setState({ filtertype: type || 'all' },()=>{
      const body = {}
      body.fltr = this.state.filtertype;
      this.props.fetchFilterEvent.request(body);
      this.handleFilterClose();
    })
  }

  handleFilterClose = () => {
    this.setState({ anchorElFilter: null });
  }

  newDataSet = () => {
    // const body = {}
    // body.fltr = this.state.filtertype;
    // this.props.fetchFilterEvent.request(body);
  }

  onGetEvents = (type) => {
    let { events = [], userInfo = {} } = this.props
    let calender = []
    let drafts = []
    let pending = []

    events = events.reverse()

    events.forEach(event => {
      const { attributes: { event_type = "", draft = false, invitees = {} } = {} } = event
      if(draft) {
        drafts.push(event)
      } else if(!isGenericEvent(event_type)) {
        const invite = invitees && invitees[userInfo.id] || {}
        if(Object.keys(invite || {}).length) {
          if(invite.will_attend === null || !invite.will_attend) {
            pending.push(event)
            calender.push(event)
          } else {
            calender.push(event)
            pending.push(event)
          }
        }
      } else {
        calender.push(event)
      }
    })

    if(type === "calender") {
      return calender || []
    } else if(type === "draft") {
      return drafts || []
    } else if(type === "pending") {
      return pending || []
    } else {
      return []
    }

  }

  onTabChange = (event, value) => {
    this.setState({
      activeTab: value
    })
  }

  render() {
    const { classes, events, dialogProps, setInitialValue, filterUserResponse, count, currentEvent, userInfo } = this.props;
    const { anchorElFilter, defaultDate, activeTab } = this.state;
    const openFilter = Boolean(anchorElFilter);
    const filterId = openFilter ? 'filter-popover' : undefined;

    const { open, typeOfDialog } = dialogProps
    const max = dates.add(dates.endOf(new Date(2015, 17, 1), "day"), -1, "hours")
    filterUserResponse && filterUserResponse.sort(function (a, b) {
      return b.event_id - a.event_id;
    });

    return (
      <div className={classes.root}>
        <Grid container>
          <Paper className={classes.tabRoot}>
            <Tabs
              value={activeTab}
              onChange={this.onTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label={<div className={`fs-16 ${activeTab === 0 ? "font-bold" : ""}`}>Calender</div>} />
              <Tab label={<div className={`fs-16 ${activeTab === 1 ? "font-bold" : ""}`}>Draft</div>} />
              <Tab label={<div className={`fs-16 ${activeTab === 2 ? "font-bold" : ""}`}>SignUp</div>} />
            </Tabs>
          </Paper>

          <Grid item xs={12} >
            <Grid container spacing={1}>
              {
                activeTab === 0 ?
                  <Grid item xs={12}>
                    <Card >
                      <CardContent className="calendarContainer" style={{ height: "100vh", overflowY: "auto" }}>

                        <Calendar
                          step={60}
                          popup
                          localizer={localizer}
                          showMultiDayTimes
                          max={max}
                          defaultView={"month"}
                          views={["month", "week", "day"]}
                          defaultDate={defaultDate}
                          events={this.onGetEvents("calender")}
                          eventPropGetter={(this.eventStyleGetter)}
                          onSelectEvent={this.toggleEditModal}
                          startAccessor={event => combineTimeAndDate(event.start_date, event.attributes.all_day_event ? moment().startOf('day').toDate() : event.start_date)}
                          endAccessor={(event) => combineTimeAndDate(event.end_date, event.attributes.all_day_event ? moment().endOf('day').toDate() : event.end_date)}
                          tooltipAccessor={event => (event.attributes.event_title)}
                          components={{ event: Event, agenda: { event: EventAgenda } }}
                        />

                      </CardContent>
                    </Card>
                  </Grid> : null
              }

              {
                activeTab === 1 ?
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          {(this.onGetEvents("draft") || []).map((data, index) =>
                            data &&
                            (
                              <Grid item xs={12} sm={6} md={4} lg={3} key={index.toString()}>
                                <DraftInvitation
                                  onClickOnInviation={this.onClickOnInviation}
                                  data={data}
                                  key={data.event_id}
                                  loginUser={userInfo || {}}
                                  userRole={userInfo && userInfo.role}
                                />
                              </Grid>
                            )
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> : null
              }

              {
                activeTab === 2 ?
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Invitation
                              onClickOnInviation={this.onClickOnInviation}
                              events={this.onGetEvents("pending")}
                              loginUser={userInfo || {}}
                              userRole={userInfo && userInfo.role}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid> : null
              }
            </Grid>
          </Grid>
        </Grid>

        <AddEventButton userInfo={this.props.userInfo} fabClass={classes.fab} />

        {typeOfDialog === "OPEN_PREVIEW_SCREEN" && currentEvent &&
          <EventPreview
            open={open}
            onClose={this.props.closeDialogAction}
            setInitialValue={setInitialValue}
            event={currentEvent}
            newDataSet={this.newDataSet}
            eventResponse={this.props.eventResponse}
            history={this.props.history}
            currentUser={this.props.userInfo}
            groupcache={this.props.groupcache}
            childrencache={this.props.childrencache}
            sendRsvpEventResponseAction={this.props.sendRsvpEventResponseAction}
            deleteEventAction={this.props.deleteEventAction}
            setEventResponse={this.props.setEventResponse}
            transferOwnerShip={this.props.transferOwnerShip}
            fetchEventResponseAction={this.props.fetchEventResponseAction}
            closeDialogAction={this.props.closeDialogAction}
            removeChildEventAction={this.props.removeChildEventAction}
            isAdmin={this.props.isAdmin}
          />
        }

        {/*<Popover
            id={filterId}
            open={openFilter}
            anchorEl={anchorElFilter}
            onClose={this.handleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
        >
          <Grid container direction="column">
            <span className={classes.filter} style={{ padding: 10 }} onClick={this.handleFilterClick('upcoming')}>Upcoming Signups({count && count.upcoming})</span>
            <span className={classes.filter} style={{ padding: 10 }} onClick={this.handleFilterClick('past')}>Past Signups({count && count.past})</span>
            <span className={classes.filter} style={{ padding: 10 }} onClick={this.handleFilterClick(null)}>All Signups({count && count.all})</span>
          </Grid>
        </Popover>*/}

      </div>
    );
  }
}

CalenderView.propTypes = {
  classes: PropTypes.object
};

export default withRouter(withStyles(styles)(CalenderView));


