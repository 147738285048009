import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Amplify from 'aws-amplify'
import * as routes from './path';
import awsConfig from '../utils/aws-config';
import Layout from '../layout/layout';
import Login from '../container/login';
import Signup from '../component/Signup'
import EducatorSignup from "../component/Signup/educator";
import NewSchoolSignup from '../component/Signup/newschool';
import Welcome from '../component/Welcome';
import ProtectRoute from '../component/common/ProtectedRoute';
import LoginRoute from '../component/common/LoginRoute';
import SignupCode from '../component/Signup/code'
import 'react-quill/dist/quill.snow.css';

export default function Routes() {
  Amplify.configure(awsConfig)
    console.log('In Route Index')
  return (
    <BrowserRouter>
      <Switch>
        <LoginRoute component={Welcome} path={routes.WELCOME} />
        <LoginRoute component={NewSchoolSignup} path={routes.SIGN_UP_EDUCATOR_NEW}/>
        <LoginRoute component={EducatorSignup} path={routes.SIGN_UP_EDUCATOR}/>
        <LoginRoute component={SignupCode} path={routes.SIGN_UP_CODE}/>
        <LoginRoute component={Signup} path={routes.SIGN_UP} />
        <LoginRoute component={Login} path={routes.SIGN_IN} />
        <ProtectRoute component={Layout} path={routes.ROOT} />
      </Switch>
    </BrowserRouter>
  );
}

