import React from 'react';
import {connect} from 'react-redux'
import {Modal} from 'antd'
import { Grid, TextField } from "@material-ui/core";
import GradeInput from "../../GradeInput";
import "./modal.css"


class ScaleModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            scaleName: "",
            grades: []
        }
    }

    componentDidMount() {
        this.setState({
            scaleName: this.props.scaleName || "",
            grades: this.props.grades || []
        })
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onGradeModify = grades => {
        this.setState({
            grades
        });
    };

    onGradeAdded = grade => {
        const { grades } = this.state;
        grades.push(grade);
        this.setState({
            grades
        });
    };

    onScaleSave = () => {
        const { scaleName, grades } = this.state
        this.props.onScaleSave({scaleName, grades})
    }

    render() {
        const { onClose, isScaleSaving } = this.props;
        const { scaleName, grades } = this.state;

        return(
            <Modal
                title="Scales"
                visible={true}
                onOk={this.onScaleSave}
                onCancel={onClose}
                okButtonProps={{disabled: !(scaleName && (grades || []).length), loading: isScaleSaving}}
                cancelButtonProps={{disabled: isScaleSaving}}
            >
                <Grid item md={10}>
                    <TextField
                        className="w-100-p"
                        label="Scale Title"
                        name="scaleName"
                        value={scaleName}
                        onChange={this.onChange}
                    />
                </Grid>
                <Grid item md={12}>
                    <GradeInput
                        onSaveScale={this.handleOnSaveScale}
                        onDelete={this.onGradeModify}
                        grades={grades}
                        onGradeAdded={this.onGradeAdded}
                        onScaleEdit={this.onGradeModify}
                    />
                </Grid>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    groupList: state.currentUser.newUserInfo.groupList
})

export default connect(mapStateToProps, null)(ScaleModal)