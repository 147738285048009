import { API } from 'aws-amplify';

const getModuleHighlights = (body = {}) => {
    const init = {
        body
    }
    return API.post('get_module_highlights', '', init).then(res => res);
}

const progressReportService = {
    getModuleHighlights
}

export default progressReportService;