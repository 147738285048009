import React from 'react';
import {connect} from 'react-redux'
import {List, Icon, Button, Modal, Tooltip} from 'antd'
import { Grid } from "@material-ui/core";
import {
    capitalizeFirstLetter,
    getSchoolId,
    openNotificationWithIcon,
    SelectLabelInput,
    TextLabelInput,
    SelectUserRoleInput,
    getUserRole
} from "../GlobalComponents/GlobalFields";
import {createUser} from "../../ApiServices";
import * as routes from "../../../../routes/path";
import { teacherLevelList } from "../GlobalComponents/optionValues";


class AddQuickMultipleStaff extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            staffList: [
                { firstName: '', lastName: '', email: '', homeRoom: '', groups: [], role: [], teacherLevel: '' },
                { firstName: '', lastName: '', email: '', homeRoom: '', groups: [], role: [], teacherLevel: '' },
                { firstName: '', lastName: '', email: '', homeRoom: '', groups: [], role: [], teacherLevel: '' },
            ],
            errors: {},
            isImageUploading: false
        }
    }

    onChange = (event, index) => {
        let { staffList } = this.state
        const { name, value } = event.target
        staffList[index][name] = value
        this.setState({
            staffList
        })
    }

    onRemoveStaff = (index) => {
        let { staffList } = this.state
        staffList.splice(index, 1)
        this.setState({
            staffList
        })
    }

    onAddStaff = () => {
        let { staffList } = this.state
        staffList.push({ firstName: '', lastName: '', email: '', homeRoom: '', groups: [], role: [], teacherLevel: '' })
        this.setState({
            staffList
        })
    }

    onDisabled = () => {
        const { staffList } = this.state
        let isDisabled = true
        staffList.forEach(staff => {
            const { firstName, lastName, email, homeRoom, role, teacherLevel } = staff
            let isRequires = false
            if((role || []).includes("teacher")) {
                isRequires = firstName && lastName && email && homeRoom && (role || []).length && teacherLevel
            } else {
                isRequires = firstName && lastName && email && homeRoom && (role || []).length
            }
            if(isRequires) {
                isDisabled = false
            }
        })
        return isDisabled
    }

    onSaveStaff = () => {
        const { staffList } = this.state

        if((staffList || []).length) {
            let isInvalid = false
            for(let i in staffList) {
                const { firstName, lastName, email, homeRoom, role, teacherLevel } = staffList[i]
                let isRequires = false
                if((role || []).includes("teacher")) {
                    isRequires = firstName && lastName && email && homeRoom && (role || []).length && teacherLevel && !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                } else {
                    isRequires = firstName && lastName && email && homeRoom && (role || []).length && !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                }
                if(isRequires) {
                    isInvalid = true
                    openNotificationWithIcon('error', `Please enter valid email ${staffList[i].email}`)
                }

            }
            if(isInvalid) return;
        }

        const isDuplicate = this.onCheckDuplicateEmail()
        if((isDuplicate || []).length) {
            let duplicate = false
            for(let i in isDuplicate) {
                if(isDuplicate[i]) {
                    duplicate = true
                    openNotificationWithIcon('error', `Email ${isDuplicate[i]} should be uniq`)
                }
            }
            if(duplicate) return
        }

        let staffPayload = []

        staffList.forEach(staff => {
            const { firstName, lastName, email, homeRoom, groups, role, teacherLevel } = staff

            let isRequires = false
            if((role || []).includes("teacher")) {
                isRequires = firstName && lastName && email && homeRoom && (role || []).length && teacherLevel
            } else {
                isRequires = firstName && lastName && email && homeRoom && (role || []).length
            }
            if(isRequires) {

                let group_ids = []
                if((groups || []).length) {
                    if((groups || []).includes(homeRoom)) {
                        group_ids = groups
                    } else {
                        group_ids = (groups || []).concat([homeRoom])
                    }
                } else {
                    group_ids = [homeRoom]
                }

                const staffObject = {
                    role: { [getSchoolId()]: getUserRole(role) },
                    // school_ids: [getSchoolId()],
                    child_ids: [],
                    group_ids,
                    username: email,
                    attributes: {
                        firstName, lastName, profile_photo: '', hours: '', role, notes: '', email, teacherLevel, homeRoom, groups, dateOfBirth: '', hireDate: '', certificationList: '', primaryAddress: {}
                    }
                }

                staffPayload.push(staffObject)
            }
        })

        console.log({staffPayload})

        Modal.confirm({
            title: `Do you want to create ${(staffPayload || []).length} new staff?`,
            onOk: async ()  => {
                return new Promise(async (resolve, reject) => {
                    let created = []

                    for(let i in staffPayload) {
                        const staffResponse = await createUser(staffPayload[i])
                        if(staffResponse && staffResponse.status === "success") {
                            created.push(i)
                        } else {
                            const message = staffResponse.message === "User already exists in system." ?
                                "Email already exists in System for another user. Please use different email" : staffResponse.message
                            openNotificationWithIcon('error', message || 'Something went wrong')
                        }
                    }

                    if((created || []).length) {
                        openNotificationWithIcon('success', "staffs create successfully")
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
                        setTimeout(() => {
                            this.props.history.push(routes.STAFF_DASHBOARD)
                        }, 500)
                    }

                }).catch(() => console.log('Oops errors!'));
            },
            okText: 'Create New Staff',
            onCancel() {},
        });
    }

    onCheckDuplicateEmail = () => {
        const { staffList } = this.state
        const uniq = (staffList || [])
            .map((name) => {
                return {
                    count: 1,
                    name: name.email.toLowerCase()
                }
            })
            .reduce((a, b) => {
                a[b.name] = (a[b.name] || 0) + b.count
                return a
            }, {})

        return Object.keys(uniq).filter((a) => uniq[a] > 1)
    }

    render() {
        const { currentUser: { newUserInfo: { groupcache: groupList = [] } } } = this.props;
        const { staffList }  = this.state
        return(
            <div className="staff-dashboard">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <div className="back">
                                <Tooltip title={"Back To Staff Dashboard"} placement="bottomLeft">
                                    <Icon
                                        type="left"
                                        className="color-white cursor-pointer"
                                        onClick={() => this.props.history.push(routes.STAFF_DASHBOARD)}
                                    />
                                </Tooltip>
                            </div>
                            <h3 className="mb-0 color-white">Add New Staffs</h3>
                        </div>
                    </div>
                </div>
                <div className={`staff content`}>
                    <Grid item xs={12}>

                        <div className="student-table-header" style={{background: '#ebeff4'}}>
                            <List.Item>
                                <div style={{width: 50}}>{" "}</div>
                                <List.Item.Meta title={"First Name"}/>
                                <List.Item.Meta title={"Last Name"}/>
                                <List.Item.Meta title={"Email"}/>
                                <List.Item.Meta title={"Home Room"}/>
                                <List.Item.Meta title={"Group"}/>
                                <List.Item.Meta title={"Role/Access"}/>
                                <List.Item.Meta title={"Teacher Level"}/>
                                <div style={{width: 50}}>{" "}</div>
                            </List.Item>
                        </div>

                        <Grid item xs={12}>
                            <div>
                                <List
                                    className="demo-loadmore-list"
                                    loading={false}
                                    itemLayout="horizontal"
                                    dataSource={staffList || []}
                                    renderItem={(record, index) => {
                                        const { firstName = "", lastName = "", role = [], email = "", homeRoom = "", groups = [], teacherLevel = "" } = record || {}
                                        return(
                                            <List.Item>
                                                <div className="text-center" style={{width: 50}}>
                                                    {index + 1}
                                                </div>
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="firstName"
                                                                value={firstName}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="First Name"
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="lastName"
                                                                value={lastName}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="Last Name"
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <TextLabelInput
                                                                name="email"
                                                                value={email}
                                                                onChange={(event)=> this.onChange(event, index)}
                                                                placeholder="Email"
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <SelectLabelInput
                                                                name="homeRoom"
                                                                // mode="multiple"
                                                                onChange={(value) => this.onChange({target: { name: "homeRoom", value }}, index)}
                                                                options={groupList || {}}
                                                                value={homeRoom}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <SelectLabelInput
                                                                name="groups"
                                                                mode="multiple"
                                                                onChange={(value) => this.onChange({target: { name: "groups", value }}, index)}
                                                                options={groupList || {}}
                                                                value={groups}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <SelectUserRoleInput
                                                                name="role"
                                                                onChange={(value) => this.onChange({target: { name: "role", value }}, index)}
                                                                placeholder="Select a role"
                                                                value={role}
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <List.Item.Meta
                                                    title={
                                                        <div className="pr-4">
                                                            <SelectLabelInput
                                                                name="teacherLevel"
                                                                onChange={(value) => this.onChange({target: { name: "teacherLevel", value: (value || "").toLowerCase() }}, index)}
                                                                placeholder="Select a Teacher Level"
                                                                options={teacherLevelList || []}
                                                                value={capitalizeFirstLetter(teacherLevel) }
                                                            />
                                                        </div>
                                                    }
                                                />
                                                <div className="text-center" style={{width: 50}}>
                                                    <Icon type={"delete"} className="color-red fs-16" onClick={() => this.onRemoveStaff(index)}/>
                                                </div>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>

                        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                            <div>
                                <Button
                                    size="default"
                                    className={'review-button'}
                                    onClick={this.onAddStaff}
                                >
                                    + Add Another Staff
                                </Button>
                            </div>
                            <div>
                                <Button
                                    size="default"
                                    className={'review-button'}
                                    disabled={this.onDisabled()}
                                    onClick={this.onSaveStaff}
                                >
                                    Save Staff(s)
                                </Button>
                            </div>
                        </div>

                    </Grid>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser,
})

export default connect(mapStateToProps, null)(AddQuickMultipleStaff)