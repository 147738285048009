import React from 'react';
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import cloneDeep from "lodash/cloneDeep";
import {Button, List, Icon, Avatar, Tag, Select, Dropdown, Menu, Tooltip, Spin, Switch, Checkbox, Modal} from 'antd';
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import {
    getColor, getSchoolId, openNotificationWithIcon, SelectLabelInput,
    TextLabelInput, showGroups
} from "../GlobalComponents/GlobalFields";
import CreateParent from "../../components/Parents/components/CreateParent";
import * as routes from "../../../../routes/path";
import {studentStatusList} from "../GlobalComponents/optionValues";
import authService from "../../../../service/auth";
import {newUserInfoSuccess} from "../../../../redux/actions/current-user";
import {deleteChild, inviteUser} from "../../ApiServices";
import AllergyImage from "../../../../assets/images/allergy.png";
import "../school.css"
import "./student.css"

const { Option } = Select

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class StudentDashboard extends React.Component {
    state = {
        allGroupsList: [],
        dataList: [],
        studentStatus: ["Active"],
        isLoading: false,
        isAllergies: false,
        selectedGroup: "all",
        isActive: "quick",
        searchKeys: "",
        orderBy: "",
        registerStatus: "",
        selected: []
    };

    componentWillMount(){
        this.fetchStudentsList()
    }

    fetchStudentsList = async () => {
        this.setState({isLoading: true})
        const newUserResponse = await authService.getNewUserDetail();
        if(newUserResponse && newUserResponse.data) {
            this.props.onSetUserInfo(newUserResponse.data)
        }
        const { currentUser: { childList = [], usercache = {} } } = this.props;

        let allStudentsList = []
        childList.forEach(child => {
            let parent = []
            if((child.parents || []).length) {
                child.parents.forEach(parentId => {
                    if(usercache && usercache[parentId] && Object.keys(usercache[parentId] || {}).length) {
                        parent.push(usercache[parentId])
                    }
                })
            }
            // child.parents = parent
            allStudentsList.push({...child, parents: parent})
        })

        this.setState({
            allStudentsList: cloneDeep(allStudentsList),
            isLoading: false
        });
    }

    getAllStudentsList = () => {
        const { allStudentsList, searchKeys, selectedGroup, studentStatus, orderBy, isAllergies, registerStatus } = this.state
        let dataItem = cloneDeep(allStudentsList) || []

        if(!searchKeys && !registerStatus && selectedGroup === 'all'){
            return dataItem
        } else {
            dataItem = (dataItem || []).filter(p => {
                const { firstName = "", lastName = "", parents = [] } = p || {}
                return((firstName || "").toString().toLowerCase().includes(searchKeys.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(searchKeys.toLowerCase())) ||
                    (parents || []).some((parent) => (parent.firstName || "").toString().toLowerCase().includes(searchKeys.toLowerCase()) || (parent.lastName || "").toString().toLowerCase().includes(searchKeys.toLowerCase()) ||
                        (parents || []).some((parent) => (parent.email_id || "").toString().toLowerCase().includes(searchKeys.toLowerCase())))
            })

            if(selectedGroup !== 'all') {
                dataItem = (dataItem || []).filter(child => (child.group_ids || []).some(group => selectedGroup === group));
            }

            if(registerStatus) {
                const active = registerStatus === "Active"
                const invited = registerStatus === "Invited"
                const notInvited = registerStatus === "Not Invited"
                dataItem = (dataItem || []).filter(data => {
                    const { parents = [] } = data || {}
                    return (parents || []).some((parent) => active ? parent.active : invited ? (!parent.active && parent.invited) : (notInvited === !parent.active && !parent.invited))
                })
            }
        }
        if((studentStatus || []).length) {
            dataItem = (dataItem || []).filter(data => studentStatus.some(status => status === data.status))
        }

        if(isAllergies) {
            dataItem = (dataItem || []).filter(data => data.allergies)
        }

        if(orderBy) {
            dataItem = (dataItem || []).sort((a, b) => (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0))
        } else {
            (dataItem || []).sort(function (a, b) {
                return b.id - a.id;
            });
        }

        return dataItem || []
    }

    onDownloadList = () => {
        const { currentUser: { groupcache: groupList = [] } } = this.props;
        let list = []
        let array = this.getAllStudentsList() || []

        array.forEach(stu => {
            let parent1Name = "-";
            let parent1Email = "-";
            let parent1Phone = "-";
            let parent2Name = "-";
            let parent2Email = "-";
            let parent2Phone = "-";
            const { firstName, lastName, group_ids, status, allergies, parents = [], dateOfBirth } = stu || {}
            let groups = []
            {Array.isArray(group_ids) && (group_ids || []).map((group) => {
                if(groupList[group] === undefined){ return  }
                groups.push(groupList[group].attributes.groupname || "")
            })}

            (parents || []).forEach((parent, ind) => {
                const { firstName = "", lastName = "", email_id = "", mobile = "" } = parent || {}
                if(firstName || lastName) {
                    if(ind === 0) {
                        parent1Name = `${firstName || ""} ${lastName || ""}`
                        parent1Email = email_id || "-"
                        parent1Phone = mobile || "-"
                    }
                    if(ind === 1) {
                        parent2Name = `${firstName || ""} ${lastName || ""}`
                        parent2Email = email_id || "-"
                        parent2Phone = mobile || "-"
                    }
                }
            })

            list.push({
                firstName,
                lastName,
                groups,
                status,
                allergies: allergies || "-",
                parent1Name,
                parent2Name,
                parent1Email,
                parent2Email,
                parent1Phone,
                parent2Phone,
                dateOfBirth: dateOfBirth || "-"
            })
        })
        return list
    }

    addStudentMenu = () => {
        const { isActive  } = this.state
        return(
            <Menu className="add-student-menu">
                <div>
                    <div className="header-menu">

                        <Grid container spacing={6} className="grid-menu">
                            <Grid
                                item xs={6}
                                className={isActive === "quick" ? "active" : "inactive"}
                                onClick={() => this.setState({isActive: 'quick'})}
                            >
                                Add Multiple Students
                            </Grid>
                            <Grid
                                item xs={6}
                                className={isActive === "detail" ? "active" : "inactive"}
                                onClick={() => this.setState({isActive: 'detail'})}
                            >
                                Add Student
                            </Grid>
                        </Grid>

                    </div>

                    {
                        isActive === 'quick' ?
                            <div className="student-button">
                                <Button type="primary" onClick={() => this.props.history.push(routes.ADD_STUDENT)}>Add Multiple Students</Button>
                            </div> :
                            <div className="student-button">
                                <Button type="primary" onClick={() => this.props.history.push('/admin/student/edit/new')}>Add Student with Details</Button>
                            </div>
                    }

                </div>
            </Menu>
        )
    }

    onInviteUser = async (record, index) => {
        let { allStudentsList } = this.state
        const childIndex = (allStudentsList || []).findIndex(x => x.id === record.id)
        const key = index === 0 ? "parent1" : "parent2"
        if(childIndex !== -1) {
            allStudentsList[childIndex][key] = true
        }
        this.setState({
            allStudentsList
        })

        if(record.parents[index].email_id) {
            const payload = {
                school_id: getSchoolId(),
                username: record.parents[index].email_id || ""
            }

            const invite = await inviteUser(payload)
            if(invite && invite.status === "success") {
                openNotificationWithIcon('success', 'invitation sent successfully')
                allStudentsList[childIndex][key] = false
                this.setState({
                    allStudentsList
                })
                this.fetchStudentsList()
            } else {
                openNotificationWithIcon('error', invite.message || 'something went wrong')
                allStudentsList[childIndex][key] = false
                this.setState({
                    allStudentsList
                })
            }
        }
    }

    onAllSelect = (e) => {
        let selected = []
        if(e.target.checked) {
            selected = (this.getAllStudentsList() || []).map(x => x.id)
        } else {
            selected = []
        }
        this.setState({
            selected
        })
    }

    onSelect = (id ) => {
        let { selected } = this.state
        if((selected || []).includes(id)) {
            selected = (selected || []).filter(x => x !== id)
        } else {
            selected.push(id)
        }
        this.setState({
            selected
        })
    }

    onDeleteStaffs = () => {
        const { selected } = this.state
        Modal.confirm({
            title: `Are you sure you want to delete ${(selected || []).length} students?`,
            onOk: async () => {

                let isDeleted = 0
                for (let i in selected) {
                    const payload = {
                        school_id: getSchoolId(),
                        child_id: selected[i]
                    }
                    const response = await deleteChild(payload)
                    if(response && response.status === "success") {
                        isDeleted++
                    } else {
                        openNotificationWithIcon('error', response.message || 'Something went wrong')
                    }
                }

                if(isDeleted) {
                    openNotificationWithIcon('success', "children's deleted successfully")
                    this.setState({
                        selected: []
                    })
                    this.fetchStudentsList()
                } else {
                    openNotificationWithIcon('error', 'Something went wrong')
                }
            },
            onCancel() {},
        });
    }

    onDeleteStudent = (id) => {
        Modal.confirm({
            title: `Are you sure you want to delete this student?`,
            onOk: async () => {
                const payload = {
                    school_id: getSchoolId(),
                    child_id: id
                }
                const response = await deleteChild(payload)
                if(response && response.status === "success") {
                    openNotificationWithIcon('success', response.message || "user deleted successfully")
                    this.setState({
                        selected: []
                    })
                    this.fetchStudentsList()
                } else {
                    openNotificationWithIcon('error', response.message || 'Something went wrong')
                }
            },
            onCancel() {},
        });
    }

    render() {
        const { classes, currentUser: { groupcache: groupList = [] } } = this.props;
        const { isLoading, searchKeys, studentStatus, orderBy, isAllergies, selected, registerStatus } = this.state;
        console.log({registerStatus})
        const headers = [
            { label: "First Name", key: "firstName" },
            { label: "Last Name", key: "lastName" },
            { label: "Parent 1", key: "parent1Name" },
            { label: "Parent 1 Email", key: "parent1Email" },
            { label: "Parent 1 Phone", key: "parent1Phone" },
            { label: "Parent 2", key: "parent2Name" },
            { label: "Parent 2 Email", key: "parent2Email" },
            { label: "Parent 2 Phone", key: "parent2Phone" },
            { label: "Groups", key: "groups" },
            { label: "Status", key: "status" },
            { label: "Allergies", key: "allergies" },
            { label: "Date of Birth", key: "dateOfBirth" }
        ];
        return (
            <div className="student-dashboard">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <div className="back">
                                <Tooltip title={"Back To Admin Dashboard"} placement="bottomLeft">
                                    <Icon
                                        type="left"
                                        className="color-white cursor-pointer"
                                        onClick={() => this.props.history.push(routes.MAIN_DASHBOARD)}
                                    />
                                </Tooltip>
                            </div>
                            <h3 className="mb-0 color-white">Student Dashboard</h3>
                        </div>
                        <div>
                            <Dropdown overlay={this.addStudentMenu} trigger={['click']} placement="bottomLeft" overlayClassName="footer-menu">
                                <Button size="large">Add Student</Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={`student ${classes.root} content`}>
                    <div className="p-0">
                        <div className="flex mt-8 pb-3 align-item-center">
                            <Select
                                placeholder={'Select Class'}
                                style={{width: 225}}
                                className={'mr-4'}
                                onChange={(value) => this.setState({selectedGroup: value})}
                            >
                                <Option value={'all'}>All Classes</Option>
                                {
                                    groupList && Object.keys(groupList).map((key, index) => {
                                        const name = (groupList[key] && groupList[key].attributes && groupList[key].attributes.groupname) || ""
                                        return(
                                            <Option key={index.toString()} value={groupList[key].id}>{name}</Option>
                                        )
                                    })
                                }
                            </Select>
                            <TextLabelInput
                                placeholder="Name/Email"
                                prefix={<Icon type="search" />}
                                className="mr-4"
                                style={{width: 180}}
                                value={searchKeys}
                                onChange={(e) => this.setState({searchKeys: e.target.value})}
                            />
                            <SelectLabelInput
                                style={{width: 180}}
                                mode="multiple"
                                name="studentStatus"
                                onChange={(value) => this.setState({"studentStatus": value})}
                                placeholder="Student Status"
                                options={studentStatusList || {}}
                                value={studentStatus || []}
                                className="mr-4"
                            />
                            <SelectLabelInput
                                style={{width: 180}}
                                onChange={(value) => this.setState({"registerStatus": value || ""})}
                                placeholder="Registration Status"
                                options={["Active", "Invited", "Not Invited"] || {}}
                                value={registerStatus || []}
                                allowClear={true}
                            />

                            <CSVLink data={this.onDownloadList() || []} headers={headers} className="ml-2" filename="Student.csv">
                                <Icon type="download" style={{fontSize: 30, color: '#3cc89c'}}/>
                            </CSVLink>

                            <div className="ml-3 fs-15 mr-2">Children's with Allergies:</div>
                            <Switch
                                onChange={() => this.setState({isAllergies: !isAllergies})}
                                checked={isAllergies || false}
                                size={'small'}
                            />

                            {
                                (selected || []).length ?
                                    <div className="mt-1 ml-12" >
                                        <Icon type="delete" style={{ fontSize: '25px', color: '#cc2224' }} onClick={this.onDeleteStaffs}/>
                                    </div> : null
                            }

                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="flex" style={{alignItems: 'center', marginBottom: 10}}>
                            <div style={{marginRight: 15, fontSize: 15}}>Order by:</div>
                            <div className="cursor-pointer" onClick={() => this.setState({orderBy: 'firstName'})} style={orderBy === 'firstName' ? {fontWeight: 600}: {}}>First Name</div>
                            <div className="cursor-pointer" style={orderBy === 'lastName' ? {fontWeight: 600, borderLeft: '1px solid', marginLeft: 10, paddingLeft: 10} : {borderLeft: '1px solid', marginLeft: 10, paddingLeft: 10}} onClick={() => this.setState({orderBy: 'lastName'})}>Last Name</div>
                        </div>
                        <div>
                            {(this.getAllStudentsList() || []).length} Students Total
                        </div>
                    </div>

                    { (selected || []).length ? <div style={{fontSize: 15}}>{(selected || []).length} Selected</div> : null }
                    <Grid className="rounded-1rem bg-white shadow mt-8" style={{padding: 20}}>
                        <div className="student-table-header" style={{background: '#ebeff4', padding: '0px 10px'}}>
                            <List.Item>
                                <div style={{width: 50}}>
                                    <Checkbox
                                        checked={(selected || []).length && (selected || []).length === (this.getAllStudentsList() || []).length}
                                        onChange={this.onAllSelect}
                                    />
                                </div>
                                <List.Item.Meta title={"Student"}/>
                                <List.Item.Meta title={"parents"}/>
                                <List.Item.Meta title={"User Registration"}/>
                                <List.Item.Meta title={"Groups"}/>
                                <div style={{width: 50}}>{" "}</div>
                            </List.Item>
                        </div>
                        <Grid item xs={12}>
                            <div /* className="student-dashboard-infinite-container" */ >
                                <List
                                    className="demo-loadmore-list"
                                    loading={isLoading}
                                    itemLayout="horizontal"
                                    dataSource={this.getAllStudentsList()}
                                    renderItem={(item, index) => {
                                        const { image = "", profile_photo = "", firstName = "", lastName = "", group_ids = [], allergies = "" } = item || {}
                                        return(
                                            <List.Item>
                                                <div style={{width: 50, paddingLeft: 15}}>
                                                    <Checkbox checked={(selected || []).includes(item.id)} onChange={() => this.onSelect(item.id)} />
                                                </div>
                                                <List.Item.Meta
                                                    avatar={
                                                        (profile_photo || image) ?
                                                            <Avatar src={profile_photo || image} /> :
                                                            <Avatar
                                                                aria-label="recipe"
                                                                style={{backgroundColor: getColor(index)}}
                                                            >
                                                                {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                            </Avatar>
                                                    }
                                                    title={
                                                        <div
                                                            className="mt-3 fs-16 cursor-pointer flex"
                                                            // onClick={() => this.props.history.push(`/admin/student/view/${item.id}`)}
                                                        >
                                                            <div onClick={() => this.props.history.push(`/admin/student/view/${item.id}`)}>{firstName} {lastName}</div>
                                                            { allergies ?
                                                                <div style={{marginLeft: 5}}>
                                                                    <Tooltip title={allergies}>
                                                                        <img src={AllergyImage} style={{width: 12, marginBottom: 10}} />
                                                                    </Tooltip>
                                                                </div> : null }
                                                        </div>
                                                    }
                                                />

                                                <List.Item.Meta
                                                    title={
                                                        <div>
                                                            {
                                                                (item.parents || []).map((parent, ind) => {
                                                                    const { firstName = "", lastName = "", profile_photo = "", image = "", id = "" } = parent || {}
                                                                    return(
                                                                        <div key={ind}>
                                                                            <div className="inline-flex cursor-pointer" onClick={() => this.props.history.push(`/admin/parent/view/${id}`)}>
                                                                                {
                                                                                    (profile_photo || image) ?
                                                                                        <Avatar src={profile_photo || image} style={{width: 35, height: 35}}/> :
                                                                                        <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index), width: 35, height: 35, lineHeight: 2.5}}>
                                                                                            {`${(firstName && firstName.toString() || "").charAt(0).toUpperCase()}${(lastName && lastName.toString() || "").charAt(0).toUpperCase()}`}
                                                                                        </Avatar>
                                                                                }
                                                                                <p className="m-2">{`${firstName && firstName.toString() || ""} ${lastName && lastName.toString() || ""}`}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            {((item.parents || []).length === 0 || (item.parents || []).length === 1) && <CreateParent length={(item.parents || []).length || 0} childId={item.id} fetchStudentsList={this.fetchStudentsList}/>}
                                                        </div>
                                                    }
                                                />

                                                <List.Item.Meta
                                                    title={
                                                        <div>
                                                            {
                                                                (item.parents || []).map((parent, index) => {
                                                                    const { invited = false, active = false } = parent || {}
                                                                    return(
                                                                        <div key={index.toString()} style={index === 0 ? {marginBottom: 5} : {}}>
                                                                            {
                                                                                (item.parent1 === true && index === 0 || item.parent2 === true && index === 1) ?
                                                                                    <Spin/> :
                                                                                    <>
                                                                                        {
                                                                                            invited && active ?
                                                                                                <span>Active</span> :
                                                                                                invited ?
                                                                                                    <>
                                                                                                        <div className="color-green">Invitation Sent</div>
                                                                                                        <Button size='small' onClick={() => this.onInviteUser(item, index)}>Resend Invite</Button>
                                                                                                    </> :
                                                                                                    <Button size='small' onClick={() => this.onInviteUser(item, index)}>Invite</Button>
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                />

                                                <List.Item.Meta
                                                    title={showGroups(group_ids || [], groupList || [])}
                                                />

                                                <div style={{width: 50, paddingRight: 15}}>
                                                    <Icon type="delete" style={{ fontSize: '25px', color: '#cc2224' }} onClick={() => this.onDeleteStudent(item.id)} />
                                                </div>
                                            </List.Item>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

StudentDashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(StudentDashboard))