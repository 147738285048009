import React, { Component } from 'react'
import {connect} from "react-redux";
import {compose} from "redux";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { Grid, withStyles } from '@material-ui/core';
import {Button, Spin} from "antd";
import ModuleCard from './components/ModuleCard'
import progressReportService from '../../service/progressReport'
import moduleService from './components/service/module'
import * as Routes from '../../routes/path';
import {getCurrentSchoolId} from "../../utils/utils";
import {openNotificationWithIcon} from "../SchoolProfile/components/GlobalComponents/GlobalFields";


const useStyles = theme => ({
    root: {
        display: 'flex',
    },
    title: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6),
        marginLeft: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    item: {
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
});

class ProgressReport extends Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            highlights: []
        }
    }

    async componentDidMount() {
        this.getModuleList()
    }

    getModuleList = async () => {
        this.setState({
            isLoading: true
        })

        const data = await progressReportService.getModuleHighlights({})
        if(data && data.status === "success") {
            // let datas = []
            // const list = [1,2,3,4,5,6,7,8,9,0,11,21,223,45,84,1458,48,141,84,1,8145,15841,51,841,5841,1,84,18,415,84,5,514,84]
            // list.forEach(x => {
            //     datas.push(data.data[0])
            // })
            this.setState({
                // reportsList: datas || [],
                reportsList: data.data || [],
                isLoading: false
            })
        } else {
            this.setState({
                reportsList: [],
                isLoading: false
            })
        }
    }

    onEditModule = (m) => {
        this.props.history.push(`/modules/edit-module/${m.id}`);
    }

    onCloneModule = async (moduleToClone) => {
        const data = (this.state.reportsList || []).find(x => x.id === moduleToClone.id)

        if(data && Object.keys(data).length) {
            data.modul = moduleToClone.name;
            data.module_name = moduleToClone.name;
            data.school_id = getCurrentSchoolId(this.props.currentUser)

            const response = await moduleService.addModule(data)
            if(response.status === "success"){
                this.getModuleList()
            }
        }

    }

    onDeleteModule = async (m) => {
        const response = await moduleService.deleteModule({module_id: m.id, school_id: getCurrentSchoolId(this.props.currentUser)})
        if(response.status === "error"){
            openNotificationWithIcon('error', response.message || 'Something went wrong')
        } else {
            this.getModuleList()
        }
    }

    handleAddModule = () => {
        this.props.history.push(Routes.ADD_MODULE_ROUTE);
    };

    render() {
        const { reportsList, isLoading } = this.state
        const { classes, role, currentUser, isAdmin, history } = this.props

        if(isLoading) {
            return <div className="text-center mt-60"><Spin/></div>
        }

        const modules = (reportsList || []).map((module, index) => (
            <Grid item className={`${classes.item} ml-0-xs`} key={module.modul}>
                <ModuleCard
                    module={module}
                    title={module.modul}
                    // isDeleting={deleting}
                    onEditModule={this.onEditModule}
                    onCloneModule={this.onCloneModule}
                    onDeleteModule={m => this.onDeleteModule(m)}
                    role={role}
                    isAdmin={isAdmin}
                    index={index}
                />
            </Grid>
        ));
        return(
            <div style={{marginTop: "-10px"}}>

                {
                    isAdmin ?
                        <div className="green-button text-center mb-3">
                            <Button shape="round" size="large" className="mr-3" style={{background: 'rgb(57 101 178)'}}>
                                <span style={{color: 'white'}}>Create/Mark Progress Report</span>
                            </Button>
                            <Button shape="round" size="large" onClick={() => history.push(Routes.ProgressReportAdmin)}>
                                <span style={{color: '#3cc89c'}}>Publish/Email Progress Report</span>
                            </Button>
                        </div> : null
                }

                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    className={`${classes.root} jc-xs`}
                >
                    { isAdmin && (
                        <Grid item className={`${classes.item} ml-0-xs`} key="not-defend-g0">
                            <ModuleCard
                                cardType="addModule"
                                handle={this.handleAddModule}
                                key="not-defend-1"
                            />
                        </Grid>
                    )}
                    {modules}

                </Grid>
            </div>
        )
    }
}


ProgressReport.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    // role: (state.currentUser.userInfo.role) || "",
    currentUser: state.currentUser.newUserInfo.user,
    isAdmin: state.currentUser.newUserInfo.isAdmin
    // state
})

const enhance = compose(
    withRouter,
    withStyles(useStyles, 'some style'),
    connect(mapStateToProps, null),
)

export default enhance(ProgressReport);