import React from "react"
import {connect} from "react-redux";
import {Avatar, List, Tag} from "antd";
import {getColor, getSchoolId, showGroups} from "../../../../GlobalComponents/GlobalFields";
import {Grid} from "@material-ui/core";


class Staff extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            studentStatus: ["Active"],
            isLoading: true,
            selectedGroup: "all",
            selectedStaff: [],
            searchKeys: ""
        }
    }

    async componentWillMount() {
        const { selectedStaff = [] } = this.props;
        this.setState({
            isLoading: false,
            selectedStaff
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { selectedStaff = []  } = nextProps || {}
        this.setState({
            isLoading: false,
            selectedStaff
        })
    }

    render() {
        const { currentUser: { newUserInfo: { groupcache: groupList = [] } }, parentLoad } = this.props;
        const { isLoading, selectedStaff } = this.state
        return(
            <div>
                <Grid className="rounded-1rem bg-white shadow mt-8 staff-content">

                    <div className="data-table-header">
                        <List.Item>
                            <List.Item.Meta title={"Staff"}/>
                            <List.Item.Meta title={"Role/Teacher Level"}/>
                            <List.Item.Meta title={"Groups"}/>
                        </List.Item>
                    </div>
                    <Grid item xs={12}>
                        <div /* className="student-dashboard-infinite-container" */ >
                            <List
                                className="demo-loadmore-list"
                                loading={isLoading || parentLoad}
                                itemLayout="horizontal"
                                dataSource={selectedStaff || []}
                                renderItem={(item, index) => {
                                    const { profile_photo = "", firstName, lastName, email, group_ids = [], role = {}, teacherLevel = "" } = item || {}

                                    let roles = []
                                    const object = (role || {})[getSchoolId()] || {}
                                    Object.keys(object || {}).forEach(x => {
                                        if(role[getSchoolId()][x]) {
                                            roles.push(x)
                                        }
                                    })

                                    return(
                                        <List.Item className="cursor-pointer">
                                            <List.Item.Meta
                                                avatar={
                                                    (profile_photo) ?
                                                        <Avatar src={profile_photo} /> :
                                                        <Avatar
                                                            aria-label="recipe"
                                                            style={{backgroundColor: getColor(index)}}
                                                        >
                                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                        </Avatar>
                                                }
                                                title={
                                                    <div
                                                        className="mt-3 fs-16"
                                                        onClick={() => this.props.history.push(`/admin/staff/view/${item.id}`)}
                                                    >
                                                        {firstName} {lastName}
                                                    </div>
                                                }
                                            />
                                            <List.Item.Meta
                                                title={
                                                    <div>
                                                        <span>{ (roles || []).map((x, index) => `${(x || "").charAt(0).toUpperCase() + (x || "").slice(1)}${(roles || []).length - 1 === index ? "" : ", "}`) }</span> /
                                                        <span>{ (teacherLevel || "") ? (teacherLevel || "").charAt(0).toUpperCase() + (teacherLevel || "").slice(1) : "-" }</span>
                                                    </div>
                                                }
                                            />
                                            <List.Item.Meta
                                                title={showGroups(group_ids || [], groupList || [])}
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

export default connect(mapStateToProps,null)(Staff)