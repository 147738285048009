import React from 'react';
import { fade, withStyles,createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import AttachementButton from "./AttachementButton"
import "video-react/dist/video-react.css";
import { Player } from 'video-react';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      secondary: {
        main: '#11cb5f',
      },
    },
});
const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    // display: 'none',
    flex:1,
    display: 'flex',
    alignItems: "flex-end",
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: "flex-end",
    },
  },
});

class Footer extends React.Component{
  state={
    msgText:"",
    attachFiles: [],
    videoFileUrl:null,
    videoFilePath:[],
    attachFilesUri:[],
    openAttachementButton:false,
  }

  handleKeyDown = (e)=> {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      this.handleSubmit(e)
    }
  };
  handleSubmit=(e)=>{
    e.preventDefault();
    if(this.state.msgText!==""||this.state.attachFilesUri.length||this.state.videoFileUrl){
      this.props.sendMessageAction(this.state.msgText);
      this.setState({msgText:"",attachFiles:[],attachFilesUri:[],videoFilePath:[],videoFileUrl:null},()=>{
          this.props.sendAttachFileAction(this.state.attachFiles);
      });
    }
  }

  handleAttachFiles= (event)=>{
    const name = event.target.accept.includes('image') ? 'images' : 'videos';
    // console.log(event,name,event.target.files,"handleAttachFiles")
    let files = event.target.files;
    let newFileList = Array.from(files);
    if (files && files.length !== 0 && name === "images") {
      this.setState({ attachFiles: [...this.state.attachFiles, ...newFileList], videoFileUrl: null, videoFilePath: [] });
      Object.values(files).forEach((file,idx)=>{
        this.getBase64(file, (result) => {
          this.setState({ attachFilesUri:[...this.state.attachFilesUri,result] },()=>{
            this.props.sendAttachFileAction(this.state.attachFiles);
          });
        });
      });
    } else {
      this.setState({ videoFileUrl: URL.createObjectURL(newFileList[0]), videoFilePath: [newFileList[0]], attachFilesUri: [], attachFiles: [] }, () => {
        this.props.sendAttachFileAction(this.state.videoFilePath);
      });
    }    
  }
  // console.log(this.state.attachFiles,this.state.attachFilesUri,"handleSendAttachFiles")
  getBase64=(file, cb)=> {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ()=> {
        cb(reader.result)
    };
    reader.onerror = (error)=> {
        //console.log('Error: ', error);
    };
  }
  handlePressDeleteAttach=(fileUri,idx)=>{
    let attachFiles=this.state.attachFiles.filter((file,index)=>(idx!==index));
    let attachFilesUri=this.state.attachFilesUri.filter((fileUri,index)=>(idx!==index));
    this.setState({attachFilesUri,attachFiles},()=>{
      this.props.sendAttachFileAction(this.state.attachFiles);
    });
  }
  _renderVideo = () => {
    // console.log(this.state.videoFileUrl,this.state.videoFilePath,"handleAttachFiles")
    return (
      this.state.videoFileUrl!==null && (
        <div className='image-gallery-video' style={{background:"#fff",height:"120px",width:"120px"}}>
            <Player
                className='video-react'
                playsInline
                fluid={false}
                src={this.state.videoFileUrl}
                height={120}
                width={120}
            />
        </div>)
    )
}
  render(){
    const { classes } = this.props;
    return (
      <div className={classes.grow}>
        <ThemeProvider theme={theme}>
        <AppBar position="static" className="footer" color="primary">
          <Toolbar>
          <div style={{flex:1,backgroundColor: "#eee",borderRadius: "10px"}}>
            <div className="attach-section">
              {this.state.attachFilesUri.map((fileUri,idx)=>(
              <div className="item-container" key={"file"+idx}>
                <img
                  src={fileUri}
                  height={120}
                  width={120}
                  className="item"
                  />
                <CloseIcon className="item-icon" onClick={()=>this.handlePressDeleteAttach(fileUri,idx)}/>
              </div>
              ))}
              {this._renderVideo()}
            </div>
            <div className={classes.sectionDesktop}>
              <div style={{display:"flex",alignItems:"flex-end",justifyContent:"center"}}>
              <AttachementButton
                direction="up"
                open={this.state.openAttachementButton}
                hidden={false}
                onHiddenAction={()=>{}}
                onCloseAction={()=>{this.setState({openAttachementButton:false})}}
                onClickAction={()=>{this.setState({openAttachementButton:true})}}
                selectAttachFilesAction={this.handleAttachFiles}
              />
              </div>
              <form
              onSubmit={this.handleSubmit}
              onKeyDown={(e) => {this.handleKeyDown(e, this.handleSubmit)}}
              className="chat-input-box">            
            <TextareaAutosize
              rowsMax={4}
              aria-label="maximum height"
              placeholder="Type a message"
              value={this.state.msgText}
              onChange={(e)=>{ this.setState({msgText:e.target.value}) }}
              className="chat-input"
              />
             <Button 
             color="primary" 
             className="button"
             type="submit">
              SEND</Button>
              </form>
            </div>
          </div>
          </Toolbar>
        </AppBar>
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Footer);