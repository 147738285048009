import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Field } from 'redux-form';
import RenderLabelTimePicker from "../../common/reduxForm/RenderLabelTimePicker";
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import moment from "moment";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    card: {
        display: "flex",
        minHeight: "300px"
    },
    details: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: "1 0 auto"
    },
    cover: {
        height: 400,
        margin: "-50px 0px",
    },
    coverContent: {
        padding: 25
    },
    rsvpIcon: {
        padding: "20px 0px 0px 15px",
        height: 70,
    },
    rsvpDateTime: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-end",
        flexDirection: "row-reverse",
        textAlign: "right"
    },
    rsvpDateTimeChild: {
        flex: "0 0 calc(70% - 10px)"
    },
    rsvpDateTimeChildimg: {
        flex: "0 0 calc(30% - 10px)"
    },
    dateTimeCard: {
        padding: 20,
        boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
        borderRadius: "12px"
    },
    dateTitme: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#05b9a4",
    },
    slotTime: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#000",
        border: "1px solid rgba(143, 143, 150, 0.38)",
        borderRadius: "50px",
        padding: "0px 10px",
        textAlign: "center"
    },
    breakTag: {
        textAlign: "center",
        marginTop: "10px"
    },
    breakInput: {
        width: "30px",
        margin: "0 5px"
    },
    control: {
        margin: "25px 0px"
    },
    addTime: {
        border: "1px solid #05b9a4",
        backgroundColor: "#05b9a4",
        color: "#fff",
        borderRadius: "50px",
        position: "absolute",
        bottom: "20px",
        left: "43%",
        "&:hover": {
            backgroundColor: "#05b9a4",
        }
    }
});

class TimeSlots extends Component {

    state = {
        breaks: 15,
    }

    componentDidMount() {
        const { fields } = this.props;
        if (!fields.length) {
            fields.push({ start_time: moment(), end_time: moment().add(30, 'minute'), count: 0, users: [] });
        }
    }

    onChangeBreaks = (e) => {
        const { name, value } = e.target;
        if (!isNaN(value)) {
            this.setState({ [name]: Number(value) })
        }
    }

    handleAddTime = (e) => {
        const { fields } = this.props;
        if (fields.length > 0) {
            const meetingtime = moment(fields.get(fields.length - 1).end_time).diff(moment(fields.get(fields.length - 1).start_time), 'minutes');
            const start_time = moment(fields.get(fields.length - 1).end_time, "HH:mm").add(this.state.breaks, 'minutes');
            const end_time = moment(start_time, "HH:mm").add(meetingtime, 'minutes');

            fields.push({ start_time, end_time, count: 0, users: [] })
        } else {

            fields.push({ start_time: moment(), end_time: moment().add(30, 'minute'), count: 0, users: [] })
        }
    }

    deleteData = (index) =>{
        const { fields } = this.props;
        let allData = []
        let deleted = false
        allData = fields.getAll()
        if(allData.length){
            fields.removeAll()
            deleted = true
        }
        if(deleted){
            const finalData = allData.filter((o,i) => i !== index)
            setTimeout(function(){
                finalData.forEach(item =>{
                    fields.push(item)
                })
            }.bind(this),30);
        }
    }

    render() {
        const { breaks } = this.state;
        const { classes, fields } = this.props;
        return (
            <Grid>
                {fields.map((slot, index) => (
                    <Grid container key={index} className="slots">
                        <Grid item xs={3}>
                            <Field
                                name={`${slot}.start_time`}
                                component={RenderLabelTimePicker}
                                className="renderLableTimePicker"
                            />
                        </Grid>
                        <Grid item xs={3} >
                            <Grid container>
                                <Grid item xs={10} >
                                    <Field
                                        name={`${slot}.end_time`}
                                        component={RenderLabelTimePicker}
                                        className="renderLableTimePicker"
                                    />
                                </Grid>
                                <Grid item xs={2} className="deleteIcon">
                                    <Button onClick={() => this.deleteData(index)}>
                                        <Icon>delete</Icon>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}

                <Grid container>
                    <Grid item xs={12} >
                        <Button
                            className={classes.addTime}
                            variant="outlined"
                            onClick={this.handleAddTime}>
                            Add Time
                        </Button>
                    </Grid>
                </Grid>
                <Grid container style={{ marginBottom: 15 }}>
                    <Grid item xs={12} className={classes.breakTag}>Breaks Between Conferences</Grid>
                    <Grid item xs={12} className={classes.breakTag}>
                        <input
                            className={classes.breakInput}
                            name="breaks"
                            value={breaks}
                            onChange={this.onChangeBreaks} />
                        Min
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}


export default withStyles(styles)(TimeSlots)
