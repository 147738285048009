

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, withStyles, Fab, Container, Button} from "@material-ui/core";
import Image from "../../assets/images/welcome/360.png"
import * as routes from "../../routes/path";
import ButtonBase from '@material-ui/core/ButtonBase';
import TextArea from "antd/es/input/TextArea";
import awsConfig from "../../utils/aws-config";

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        padding: '5em'
    },
    image : {
        width : 100,
        marginLeft : 10,
        marginTop : 10
    }
});

const AppHeader = ({classes,}) => {


    return (
        <a href='https://www.360memos.com'>

            <div style={{height : 70}}>
                <img className={classes.image} src={awsConfig.app_logo} />
            </div>
        </a>
    );

}


export default withStyles(styles)(AppHeader);