import store from "./../redux/store";
import FirebaseApiInstance from '../service/chat';
const { getCurrentSchoolId } = require('./utils')

const config = {
  apiKey: "AIzaSyAEmajaE8r0dBX_2RGuluRz-UBMGZVUkc8",
  authDomain: "hmsgenericprod.firebaseapp.com",
  databaseURL: "https://hmsgenericprod.firebaseio.com",
  projectId: "hmsgenericprod",
  storageBucket: "hmsgenericprod.appspot.com",
  messagingSenderId: "560354801078",
  appId: "1:560354801078:web:d46afe2ea6853fab4d3d17",
  measurementId: "G-6KHS3DF65B"
};

export const firebaseInit= async(user)=>{
  const usertoken = store.getState().currentUser.auth.idToken.jwtToken
  console.log('user->', user)
  //const {newUserInfo:{user}} = store.getState().currentUser;

  const firebaseToken = await FirebaseApiInstance.getFirebaseToken(user.id, getCurrentSchoolId(user),usertoken)
  FirebaseApiInstance.Init(config)
  FirebaseApiInstance.signIn(firebaseToken.token)
  // FirebaseApiInstance.registerUser(getCurrentSchoolId(), user)
  return FirebaseApiInstance;
}

