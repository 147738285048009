import {
    createSelector
} from 'reselect';

const getPosts = state => state.feed.posts;

const getPinnedPosts = state => state.feed.pinned.posts;

const getUserCache = state => state.currentUser.newUserInfo.usercache || {};

const getPostsWithUser = createSelector(
    [getPosts, getUserCache], (posts = [], userCache) => {
        if (posts.length > 0 && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.user_comments && Object.values(post.user_comments || []).map((comment, index) => ({
                        ...comment,
                        id: Object.keys(post.user_comments)[index] || "",
                        user: userCache[comment.user_id]
                    })),
                    // user: userCache[post.user]
                };
            });
        }
        return [];
    }
)

const getPinnedPostsWithUser = createSelector(
    [getPinnedPosts, getUserCache], (posts = [], userCache) => {
        if (posts.length > 0 && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.user_comments && Object.values(post.user_comments || []).map((comment, index) => ({
                        ...comment,
                        id: Object.keys(post.user_comments)[index] || "",
                        user: userCache[comment.user_id]
                    })),
                    // user: userCache[post.user]
                };
            });
        }
        return [];
    }
)




export const FeedSelector = {
    getPostsWithUser,
    getPinnedPostsWithUser
};