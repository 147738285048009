import React from 'react';
import { Link } from 'react-router-dom';
import { Card, withStyles, Fab} from "@material-ui/core";

import * as routes from "../../routes/path";
import awsConfig from '../../utils/aws-config'

import Image from "../../assets/images/welcome/360.png"
import ImageHudson from "../../assets/images/hudson-school.png"

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 900,
        marginTop: '6em',
        padding: '6em'
    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 13,
        color: '#6E75FE',
    }
});

const Welcome = ({ classes }) => (
    <div className={classes.main}>
        <div className={classes.card}>
            <div className={classes.header}>
                ENABLE EVERYDAY COMMUNICATION
            </div>

            <div className="text-center mt-2">
                <b className="text-15">BUILD</b>
            </div>

            <div className="text-center mt-2">
                <small style={{color: "gray"}}>A SPACE FOR FAMILIES AND <br/> STAFF TO CONNECT</small>
            </div>

            <div className="text-center">
                {awsConfig.is_generic ?
                    <img src={Image}/>
                    :
                    <img src={ImageHudson}/>
                }
                    </div>
            <div className="w-100-p">
                {awsConfig.is_generic ?
            <div className="w-100-p">
                <Link to={routes.SIGN_UP}>
                <Fab variant="extended" size="small" className="w-100-p">
                    Sign Up
                </Fab>
                </Link>
            </div>
                    : null}

            <div className="w-100-p mt-3">
                <Link to={routes.SIGN_IN}>
                    <Fab variant="extended" size="small" color="primary" className="w-100-p">
                        Sign In
                    </Fab>
                </Link>
            </div>
            </div>

        </div>
    </div>
)


export default withStyles(styles)(Welcome);