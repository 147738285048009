'use strict'

import _, {get} from 'lodash'
import update from 'immutability-helper'
// import {GiftedChat} from 'react-native-gifted-chat'
// import * as miscutils from '../utils/misc'

export default function reducer(state = {
    rooms: {},
    error: {},
    isloadingMessages: false,
    totalnotifications : 0,
    localnotification  : [],
    notifications : null,
    lastRefreshTimeStamp : Date.now()
}, action) {
    switch (action.type) {
        case 'LOAD_MESSAGE_ROOMS_LOADING' : {
            return {
                ...state,
                isloadingMessages: true,
                lastRefreshTimeStamp : Date.now()
            }

        }


        case 'LOAD_NOTIFICATIONS':
        {
            var newNotifications = action.payload.notifications
            var count = 0
            if (newNotifications !== null) {
                for (var key in newNotifications) {
                    // if (state?.rooms[key]?.visibleRoom === true) {
                        // console.log('roomstatsfromFirebase',count,newNotifications,newNotifications[key].count)
                        count += newNotifications[key].count
                    // }
                }
            }
            var diff = findoutNewMessages(newNotifications, state.notifications)
            if (diff !== null) {
                var newdiff = update(state.localnotification, {$push: [diff]})
                return {
                    ...state,
                    localnotification: newdiff,
                    notifications: newNotifications,
                    totalnotifications: count
                }
            } else {
                return {
                    ...state,
                    notifications: newNotifications,
                    totalnotifications: count
                }
            }
        }

        case 'CLEAR_ROOMS' : {
            return {
                ...state,
                rooms:  {},
                isloadingMessages: true,
                lastRefreshTimeStamp : Date.now()
            }
        }

        case 'CLEAR_ROOM' : {
            let newrooms={};
            Object.values(state.rooms).forEach(room=>{
                if(room.key===action.payload.roomkey){
                    newrooms={...newrooms};
                } else{
                    newrooms={...newrooms,[room.key]:room};
                }
            })
            return {
                ...state,
                rooms: newrooms,
                isloadingMessages: true,
                lastRefreshTimeStamp : Date.now()
            }
        }

        case 'LOAD_MESSAGE_ROOMS': {
            //console.log(action.payload)
            var newrooms = Object.assign({}, state.rooms)
            //console.log('newrooms->', newrooms)
            var roomkeys = Object.keys(action.payload.rooms)
            for (var x = 0; x< roomkeys.length;x++){
                var roomkey = action.payload.rooms[roomkeys[x]].key
                //console.log('roomkey',roomkey)
                var val = action.payload.rooms[roomkey]
                //console.log('val',val)

                newrooms[roomkey] = val
                newrooms[roomkey]['visibleRoom'] = true
                if  (newrooms[roomkey]['messages'] !== undefined){
                    //console.log('details.users.' + action.payload.userid + '.joinedTs')
                    let joinedTsforUser = get(newrooms[roomkey], 'details.users.' + action.payload.userid + '.joinedTs', 0)
                    //console.log('RoomKey -> ' + roomkey + ' joinedT->' + joinedTsforUser)
                    var msgs = _.orderBy(newrooms[roomkey]['messages'], ['orderBy'], ['asc'])
                    msgs = _.filter(msgs, function(m) {
                        return m.orderBy > joinedTsforUser;
                    });
                    //console.log('Sorted Msgs', msgs)
                    //console.log('Sorted Msgs2', msgs)
                    newrooms[roomkey]['messages'] = msgs
                }

            }

            //console.log('assigned ....', newrooms)
            return {
                ...state,
                rooms: newrooms,
                isLoading: false,
                isloadingMessages: false
            }
        }

        case 'GET_MESSAGES_FULLFILLED' : {
            //console.log('In GET MSGS FULLFILLED')
            var key = action.payload.roomkey
            var msgs = Object.values(action.payload?.msgs||{})
            msgs =  _.orderBy(msgs, ['orderBy'], ['asc'])
            var starttime = Date.now()
            var newrooms = Object.assign(state.rooms)
            //console.log('state.rooms[key]', state.rooms[key])
            if (state.rooms[key] !== undefined && state.rooms[key].messages === undefined) {
                newrooms = update(state.rooms, {
                    [key]: {'messages': {$set: []}}
                })
            } else {
                let existingmsgs = Object.values(Object.assign({}, state.rooms[key]?.messages)||{})
                let newIds =  _.map(msgs, '_id')
                for (var i=0;i<newIds.length;i++){

                    _.remove(existingmsgs,function (existingmsg) {
                        return existingmsg['_id'] == newIds[i]
                    });
                }
                let newmsgs = existingmsgs.concat(msgs)
                newrooms[key]['messages'] = Object.values(newmsgs)
                let lasttimestamp = newmsgs[0]?.orderBy
                newrooms[key]['details']['lastmsgtimestamp'] = lasttimestamp
            }
            var endtime = Date.now()
            //console.log('Time to process messages : ', endtime-starttime)
            //console.log('newsrooms', newrooms)
            return {
                ...state,
                rooms:  newrooms,
                isLoading: false,
                isloadingMessages: false
            }
        }

        case 'ADD_PENDING_CHAT_MESSAGE': {
            var key = action.payload.roomkey
            var msgs = Object.assign({},action.payload.msgs)
            msgs['pending'] = true
            //console.log('msgs', msgs)
            //console.log('room', state.rooms[key])
            if (state.rooms[key] !== undefined && state.rooms[key].messages === undefined) {
                var newrooms = update(state.rooms, {
                    [key]: {'messages': {$set: []}}
                })
            } else {
                var newrooms = Object.assign(state.rooms)
                if (state.rooms[key] !== undefined && state.rooms[key].messages.length > 0 && msgs._id === state.rooms[key].messages[0]._id) {
                    return {
                        ...state
                    }
                }
            }
            if (state.rooms[key] !== undefined ) {
                var newrooms2 = update(newrooms, {
                    [key]: {'messages': {$unshift: [msgs]}}
                })
                //console.log(newrooms2)
            }

            return {
                ...state,
                rooms: newrooms2,
                liveRoom: key
            }
        }

        case 'ADD_CHAT_MESSAGE': {
            var key = action.payload.roomkey
            var msgs = Object.assign({},action.payload.msgs)
            msgs['sent'] = true
            //console.log('msgs', msgs)
            //console.log('room', state.rooms[key])
            if (state.rooms[key] !== undefined && state.rooms[key].messages === undefined) {
                var newrooms = update(state.rooms, {
                    [key]: {'messages': {$set: []}}
                })
            } else {
                var newrooms = Object.assign(state.rooms)
                if (state.rooms[key] !== undefined && state.rooms[key].messages.length > 0 && msgs._id === state.rooms[key].messages[0]._id) {
                    return {
                        ...state
                    }
                }
            }
            if (state.rooms[key] !== undefined ) {
                var newrooms2 = update(newrooms, {
                    [key]: {'messages': {$push: [msgs]}}
                })
                //console.log(newrooms2,"newrooms2")
            }
            let lasttimestamp = msgs.orderBy;
            newrooms[key]['details']['lastmsgtimestamp'] = lasttimestamp;
            return {
                ...state,
                rooms: newrooms2,
                liveRoom: key
            }
        }
    }
    return state
}

function findoutNewMessages (newNotifications, oldNotifications) {
    var newMessage = null
    if (oldNotifications === null || newNotifications === null) return null

    for (var key in newNotifications.Rooms) {
        var newCount = newNotifications.Rooms[key]
        if (oldNotifications.Rooms[key] === undefined) {
            if (newMessage === null) { newMessage = {} }
            newMessage[key] = newNotifications.Rooms[key]
        } else {
            var oldCount = oldNotifications.Rooms[key]
            if (newCount > oldCount) {
                if (newMessage === null) { newMessage = {} }
                newMessage[key] = newCount - oldCount
            }
        }
    }
    //console.log(newMessage)
    return newMessage
}