import React from "react"
import {connect} from "react-redux";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {
    Table,
    InputNumber,
    Radio,
    Icon,
    Select,
    Input,
    Modal,
    Tooltip,
    Button,
    DatePicker
} from "antd";
import {Grid, Paper, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {getSchoolId, getFormattedDate, openNotificationWithIcon} from "../../GlobalComponents/GlobalFields";
import { preCheckinObject } from "../../GlobalComponents/optionValues";
import {
    createStudentPreCheckin,
    getChildAttendance
} from "../../../ApiServices";
import authService from "../../../../../service/auth";
import {newUserInfoSuccess} from "../../../../../redux/actions/current-user";
import PendingImage from "../../../../../assets/images/triangle.png";
import PreCheckinImage from "../../../../../assets/images/pre_checkin_form.png";

const { TextArea } = Input
const { Option } = Select

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class PreCheckin extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            isModal: false,
            students: [],
            duplicateStudents: [],
            errorIndex: [],
            commentObject: {},
            selectedDate: new Date()
        }
    }

    componentWillMount() {
        this.fetchChildAttendance()
    }

    fetchChildAttendance = async () => {
        this.setState({
            isLoading: true
        })
        const newUserResponse = await authService.getNewUserDetail();
        if(newUserResponse && newUserResponse.data) {
            this.props.onSetUserInfo(newUserResponse.data)
        }
        const { currentUser: { user = {}, childrencache = {} } } = this.props;

        let childs = []
        if(user && user.id) {

            const payload = {
                school_id: 1,
                att_date: getFormattedDate(this.state.selectedDate)
            }

            const response = await getChildAttendance(payload)

            for(let i in user.child_ids) {

                let childBody = {}

                if(childrencache && childrencache[user.child_ids[i]] && Object.keys(childrencache[user.child_ids[i]]).length) {
                    childBody = {...childrencache[user.child_ids[i]]}
                }

                if(response && response.status === "success") {
                    const object = (response.data || []).find(x => x.child_id === user.child_ids[i])
                    if(object && Object.keys(object).length) {
                        childBody.pre_checkin = object.pre_checkin && Object.keys(object.pre_checkin || {}).length ? object.pre_checkin : cloneDeep(preCheckinObject)
                    } else {
                        childBody.pre_checkin = cloneDeep(preCheckinObject)
                    }
                } else {
                    childBody.pre_checkin = cloneDeep(preCheckinObject)
                }

                childs.push(childBody)

            }

        }

        this.setState({
            childList: cloneDeep(childs),
            duplicateChildList: cloneDeep(childs),
            isLoading: false
        })
    }

    onCovidChange = (e, key, index) => {

        if(String(key) === "1" && e.target.value) {
            let that = this
            Modal.error({
                title: 'Please contact the school before coming in to share more details.',
                onOk() {
                    let { childList } = that.state

                    childList[index].pre_checkin.covid.covid_detail[String(key)].description = ""
                    childList[index].pre_checkin.covid.covid_detail[String(key)].valid = e.target.value

                    that.setState({
                        childList
                    })
                },
                onCancel() {
                    console.log("--")
                }
            })
        } else {
            let { childList } = this.state

            childList[index].pre_checkin.covid.covid_detail[String(key)].description = ""
            childList[index].pre_checkin.covid.covid_detail[String(key)].valid = e.target.value

            this.setState({
                childList
            })
        }
    }

    getChildren = (valueObj, key, index) => (
        <div className="flex align-item-center">
            <Radio.Group value={valueObj.valid} onChange={(e) => this.onCovidChange(e, key, index)}>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
            </Radio.Group>
            {
                valueObj.valid ?
                    <div>
                        <Tooltip title={valueObj.description} >
                            <Icon type="message" theme="filled" className="cursor-pointer color-red" onClick={() => this.onCommentModalChange(key, index)} />
                        </Tooltip>
                    </div> : null
            }
        </div>
    )

    onAttendanceChange = (value, index) => {
        let { childList } = this.state
        childList[index].pre_checkin = {
            ...childList[index].pre_checkin,
            covid: {
                ...childList[index].pre_checkin.covid,
                status: {
                    status: value,
                    comment: ""
                },
            }
        }
        this.setState({
            childList
        })
    }

    onTemperatureChange = (value, index) => {
        const { childList } = this.state
        childList[index].pre_checkin.covid.temperature = value
        this.setState({
            childList
        })
    }

    getColumns = () => {
        return [
            {
                title: 'Child Name',
                width: 150,
                fixed: 'left',
                render: (record) => {
                    const covidObject = (((record.pre_checkin || {}).covid || {}).covid_detail || {}) || {}
                    const array = Object.keys(covidObject || {}).map(x => covidObject[x].valid)
                    return(
                        <div>
                            {`${record.firstName || ""} ${record.lastName || ""}`}
                            { (array || []).includes(true) ? <img src={PendingImage} style={{width: 18}} /> : null }
                        </div>
                    )
                },
            },
            {
                title: 'Decision for today',
                width: 200,
                fixed: 'left',
                render: (record, row, index) => {
                    const value = (((record.pre_checkin || {}).covid || {}).status || {}).status || ""
                    return(
                        <div>
                            <Select
                                style={{width: 150}}
                                value={value}
                                onChange={(value) => this.onAttendanceChange(value, index)}
                            >
                                <Option value="inclass">In Class</Option>
                                <Option value="virtual">Virtual</Option>
                                <Option value="excusedabsence">Excused Absence</Option>
                            </Select>
                        </div>
                    )
                },
            },
            {
                title: 'Temperature',
                width: 120,
                render: (record, row, index) => {
                    const value = ((record.pre_checkin || {}).covid || {}).temperature || ""
                    return(
                        <div>
                            <InputNumber
                                style={{width: 75}}
                                value={value}
                                onChange={(value) => this.onTemperatureChange(value, index)}
                            />
                            { (this.state.errorIndex || []).includes(index) ? <small className="color-red">Required</small> : null }
                        </div>
                    )
                },
            },
            {
                title: 'Fever Reducing Medication Administered?',
                width: 200,
                render: (record, text, index) => {
                    const key = "0"
                    const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
                    return(
                        this.getChildren(valueObj, key, index)
                    )
                }
            },
            {
                title: 'Have you or anyone in your household travelled to a state or country listed on the Covid-19 travel advisory list in the last 14 days?',
                width: 200,
                render: (record, text, index) => {
                    const key = "1"
                    const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
                    return(
                        this.getChildren(valueObj, key, index)
                    )
                }
            },
            {
                title: 'Close contact with Anyone Diagnosed with COVID-19 in the Past 14 Days?',
                width: 200,
                render: (record, text, index) => {
                    const key = "2"
                    const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
                    return(
                        this.getChildren(valueObj, key, index)
                    )
                }
            },
            {
                title: 'Child/Staff Symptoms(s)?',
                children: [
                    {
                        title: 'Cough, Shortness of Breath, Trouble Breathing',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "3"
                            const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
                            return(
                                this.getChildren(valueObj, key, index)
                            )
                        }
                    },
                    {
                        title: 'headache, Fever, Muscle pain, Chills, Repeated Shaking with Chills, New Loss of Taste or Smell',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "4"
                            const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
                            return(
                                this.getChildren(valueObj, key, index)
                            )
                        }
                    },
                ],
            },
            {
                title: 'Household Member Symptoms(s)?',
                children: [
                    {
                        title: 'Cough, Shortness of Breath, Trouble Breathing',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "5"
                            const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
                            return(
                                this.getChildren(valueObj, key, index)
                            )
                        }
                    },
                    {
                        title: 'headache, Fever, Muscle pain, Chills, Repeated Shaking with Chills, New Loss of Taste or Smell',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "6"
                            const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
                            return(
                                this.getChildren(valueObj, key, index)
                            )
                        }
                    },
                ],
            },
            {
                title: 'Form Filled By',
                width: 150,
                render: (record) => {
                    const value = (record.pre_checkin || {}).check_in_by || ""
                    return(
                        <div>
                            { value }
                        </div>
                    )
                }
            },
            {
                title: 'Signature',
                width: 130,
                render: (record) => {
                    const value = ((record.pre_checkin || {}).covid || {}).signature || ""
                    return(
                        <div>
                            <img src={this.getImage(value)} style={{width: 55}} />
                        </div>
                    )

                }
            }
        ];
    }

    getImage = (string) => {
        let src = ""
        if(string) {
            src = "data:image/png;base64," + string
        }
        return src
    }

    commentModal = () => {
        const { isModal, commentObject } = this.state
        if(!isModal) return;
        return(
            <Modal
                title="Comment"
                visible={isModal}
                onOk={this.onCommentSave}
                onCancel={this.onCommentModalChange}
                width={400}
                okButtonProps={{disabled: !(commentObject.description || "")}}
            >
                <div>
                    <TextArea rows={4} name="description" value={commentObject.description} onChange={(e) => this.setState({commentObject: {...commentObject, description: e.target.value}})} />
                </div>
            </Modal>
        )
    }

    onCommentModalChange = (key, index) => {
        const { isModal, childList } = this.state
        let commentObject = {}
        if(!isModal) {
            commentObject.index = index
            commentObject.key = String(key)
            commentObject.description = childList[index].pre_checkin.covid.covid_detail[String(key)].description || ""
        }
        this.setState({
            isModal: !isModal,
            commentObject
        })
    }

    onCommentSave = () => {
        const { commentObject, childList } = this.state
        childList[commentObject.index].pre_checkin.covid.covid_detail[commentObject.key].description = commentObject.description || ""
        this.setState({
            childList,
            commentObject: {},
            isModal: false
        })
    }

    getAttendanceList = () => {
        const { childList } = this.state

        const studentArray = cloneDeep(childList)

        return (studentArray || []).map((x, index) => ({...x, rowKey: index}))
    }

    getChanges = (isDisabled) => {
        const { childList, duplicateChildList, selectedDate } = this.state

        if(!(childList || []).length) {
            return []
        }

        let preCheckin = []
        let errorIndex = []

        childList.forEach((x, index) => {
            if(JSON.stringify(x.pre_checkin) !== JSON.stringify(duplicateChildList[index].pre_checkin)) {

                const value = (((x.pre_checkin || {}).covid || {}).status || {}).status || ""
                const temperature = ((x.pre_checkin || {}).covid || {}).temperature || ""
                if(value === "inclass" && !temperature) {
                    errorIndex.push(index)
                }
                preCheckin.push({
                    school_id: getSchoolId(),
                    child_id: x.id,
                    pre_checkin: x.pre_checkin,
                    att_date: getFormattedDate(selectedDate)
                })
            }
        })

        if(isDisabled) {
            return (preCheckin || []).length
        }

        return { preCheckin, errorIndex }
    }

    onSubmit = async () => {

        const { preCheckin, errorIndex } = this.getChanges()

        if((errorIndex || []).length) {
            openNotificationWithIcon('error', 'Temperature is required')
            return this.setState({ errorIndex })
        } else {
            this.setState({ errorIndex: [] })
        }

        this.setState({
            attendanceProcess: true,
            isLoading: true
        })

        let success = 0
        let error = 0

        if((preCheckin || []).length) {
            for(let i in preCheckin) {
                const response = await createStudentPreCheckin(preCheckin[i])
                if(response && response.status === "success") {
                    success++
                } else {
                    error++
                }
            }
        }

        this.setState({
            attendanceProcess: false,
            isLoading: false
        })

        this.fetchChildAttendance()

    }

    render() {
        const { classes } = this.props
        const { isLoading, attendanceProcess, selectedDate } = this.state
        return(
            <div className="user-profile">
                <div className="main-header">
                    <div className="header-content" style={{padding: '8px 0px'}}>
                        <div className="header-content1">
                            <div><img src={PreCheckinImage} style={{width: 100}} /></div>
                            <h3 className="mb-0 color-white ml-10">
                                Pre Checkin Form For Today
                            </h3>
                        </div>
                    </div>
                </div>
                <div className={`student ${classes.root} content`}>
                    { this.commentModal() }
                    <div className="text-center mb-4">
                        <DatePicker
                            format={'LL'}
                            allowClear={false}
                            value={selectedDate ? moment(selectedDate, 'LL') : null}
                            disabled
                            size="large"
                        />
                    </div>
                    <Table
                        columns={this.getColumns()}
                        rowKey="rowKey"
                        loading={isLoading}
                        pagination={false}
                        dataSource={this.getAttendanceList() || []}
                        bordered={true}
                        header
                        scroll={{ x: 2200 }}
                        sticky
                    />
                    <div className="mt-4">
                        <Button
                            type="primary"
                            onClick={this.onSubmit}
                            disabled={!(this.getChanges(true)) || !(this.getAttendanceList() || []).length || false}
                            loading={attendanceProcess}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

PreCheckin.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(PreCheckin))