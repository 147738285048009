import React from "react"
import {Grid} from "@material-ui/core";
import {Avatar, Button, Icon, Input, List, Modal, Select, Switch, Spin} from "antd";
import {getColor, getSchoolId, openNotificationWithIcon} from "../../../GlobalComponents/GlobalFields";
import cloneDeep from "lodash/cloneDeep";
import {connect} from "react-redux";
import {updateGroup} from "../../../../ApiServices";
import {newUserInfoSuccess} from "../../../../../../redux/actions/current-user";

const { Option } = Select


class AddCurrentStudentStaffModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isSaving: false,
            selectedStudentsKeys: [],
            searchStudent: "",
            searchCurrentStudent: "",
            studentGroup: "active",
            studentObject: {
                allStudentList: [],
                alreadySelectedStudentList: [],
                currentStudentList: []
            }
        }
    }

    async componentWillMount(){
        let { currentUser: { newUserInfo : { childList = [], usersList = [] } }, selectedStudent = [], selectedUsers = [], isStudent } = this.props;
        let { studentObject } = this.state

        if(isStudent) {
            studentObject.allStudentList = cloneDeep(childList)
            studentObject.alreadySelectedStudentList = cloneDeep(selectedStudent)
            studentObject.currentStudentList = cloneDeep(selectedStudent)
        } else {
            usersList.forEach(user => {
                const isAdmin = ((((user || {}).role) || {})[getSchoolId()] || {}).admin
                const isTeacher = ((((user || {}).role) || {})[getSchoolId()] || {}).teacher
                if(isAdmin || isTeacher) {
                    studentObject.allStudentList.push(user)
                }
            })
            // studentObject.allStudentList = cloneDeep(usersList)
            studentObject.alreadySelectedStudentList = cloneDeep(selectedUsers)
            studentObject.currentStudentList = cloneDeep(selectedUsers)
        }

        this.setState({
            studentObject
        })
    }

    getAllStudentsList = () => {
        const { studentObject, searchStudent, studentGroup } = this.state
        const { allStudentList, currentStudentList } = studentObject || {}

        let dataItem = cloneDeep(allStudentList)
        dataItem = allStudentList.filter(x => !(currentStudentList.some(y => x.id === y)))

        if(searchStudent){
            dataItem = (dataItem || []).filter(p => {
                const { firstName = "", lastName = "" } = p || {}
                return((firstName || "").toString().toLowerCase().includes(searchStudent.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(searchStudent.toLowerCase()))
            })
        }
        if(studentGroup !== 'active' && studentGroup !== 'inactive') {
            dataItem = (dataItem || []).filter(child => child.group_ids.some(group => studentGroup === group));
        }
        if(studentGroup === 'active') {
            dataItem = (dataItem || []).filter(child => child.status === "Active");
        }
        if(studentGroup === 'inactive') {
            dataItem = (dataItem || []).filter(child => child.status !== "Active");
        }

        dataItem = (dataItem || []).sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0))

        return dataItem
    }

    onStudentSelectDeselect = (id) =>{
        let { selectedStudentsKeys } = this.state

        if((selectedStudentsKeys || []).includes(id)){
            selectedStudentsKeys = (selectedStudentsKeys || []).filter(x => x !== id)
        }else {
            (selectedStudentsKeys || []).push(id)
        }
        this.setState({
            selectedStudentsKeys
        })
    }

    onSelectAllStudent = () => {
        const studentList = this.getAllStudentsList()
        this.setState({
            selectedStudentsKeys: (studentList || []).map(x => x.id) || []
        })
    }

    onSelectedStudentToCurrent = () => {
        const { selectedStudentsKeys, studentObject } = this.state
        studentObject.currentStudentList = (studentObject.currentStudentList || []).length ? (studentObject.currentStudentList || []).concat(selectedStudentsKeys) : selectedStudentsKeys || []
        this.setState({
            studentObject,
            selectedStudentsKeys: []
        })
    }

    getCurrentStudentList = () =>{
        const { searchCurrentStudent, studentObject } = this.state
        const { allStudentList, currentStudentList } = studentObject || {}

        let dataItem = cloneDeep(allStudentList)
        dataItem = allStudentList.filter(x => currentStudentList.some(y => x.id === y))

        if(!searchCurrentStudent){
            return dataItem
        }

        dataItem = (dataItem || []).filter(p => {
            const { firstName = "", lastName = "" } = p || {}
            return((firstName || "").toString().toLowerCase().includes(searchCurrentStudent.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(searchCurrentStudent.toLowerCase()))
        })
        return dataItem
    }

    onRemoveCurrentStudent = (id) => {
        const { studentObject } = this.state
        const { currentStudentList } = studentObject || {}
        studentObject.currentStudentList = (currentStudentList || []).filter(x => x !== id)
        this.setState({
            studentObject
        })
    }

    onUpdateGroup = async () => {
        let { groupDetails, onClose, onFetchInfo, isStudent } = this.props
        let { studentObject } = this.state
        const { alreadySelectedStudentList, currentStudentList } = studentObject || {}

        const newChild = (currentStudentList || []).filter(x => !((alreadySelectedStudentList || []).some(y => x === y)))
        const deletedChild = (alreadySelectedStudentList || []).filter(x => !(currentStudentList || []).some(y => x === y))

        groupDetails.added_children_ids = []
        groupDetails.deleted_children_ids = []
        groupDetails.added_user_ids = []
        groupDetails.deleted_user_ids = []

        if(isStudent) {
            groupDetails.added_children_ids = newChild
            groupDetails.deleted_children_ids = deletedChild
        } else {
            groupDetails.added_user_ids = newChild
            groupDetails.deleted_user_ids = deletedChild
        }

        this.setState({
            isSaving: true
        })
        const response = await updateGroup({...groupDetails, group_id: groupDetails.id})
        if(response && response.status === "success") {

            onFetchInfo()
            openNotificationWithIcon('success', response.message)
            setTimeout(() => {
                this.setState({
                    isSaving: false
                })
                onClose()
            }, 1000)

        } else {
            openNotificationWithIcon('error', response.message || 'Something went wrong')
            this.setState({
                isSaving: false
            })
        }

    }

    render() {
        const { onClose, currentUser: { newUserInfo : { groupcache: groupList = [] } }, isStudent } = this.props;
        const { selectedStudentsKeys, isSaving, studentObject, studentGroup } = this.state
        const { allStudentList = [] } = studentObject || {}
        return(
            <div>
                <Modal
                    title={`Add ${isStudent ? "Student" : "Staff"}`}
                    width={750}
                    visible={true}
                    onOk={this.onUpdateGroup}
                    onCancel={onClose}
                    okText={"Save"}
                    okButtonProps={ isSaving ? { disabled: true, loading: <Spin/> } : {}}
                >
                    <div className="add-to-group">
                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={6}>
                                <div className={'flex justify-between'}>
                                    <h4>Add</h4>
                                    <Select
                                        placeholder={'Select Classes'}
                                        style={{width: 225}}
                                        onChange={(value) => this.setState({studentGroup: value})}
                                        value={studentGroup}
                                    >
                                        <Option value={'active'}>- Active Students - </Option>
                                        <Option value={'inactive'}>- InActive Students - </Option>
                                        {
                                            groupList && Object.keys(groupList).map((key, index) => {
                                                if (!groupList[key].attributes.is_archive) {
                                                    const name = (groupList[key] && groupList[key].attributes && groupList[key].attributes.groupname) || ""
                                                    return (
                                                        <Option key={index.toString()}
                                                                value={groupList[key].id}>{name}</Option>
                                                    )
                                                }
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className="bg-white shadow" style={{padding: 10}}>
                                    <Input
                                        className="w-100 mb-2"
                                        placeholder="Search"
                                        onChange={(e) => this.setState({searchStudent: e.target.value})}
                                    />
                                    <div className="demo-infinite-container" style={{height: 400}}>
                                        <List
                                            dataSource={this.getAllStudentsList() || []}
                                            renderItem={(item, index) => {
                                                const { id, profile_photo = "", firstName = "", lastName = "" } = item || {}
                                                return(
                                                    <List.Item key={id} onClick={() => this.onStudentSelectDeselect(id)}>
                                                        <List.Item.Meta
                                                            style={{alignItems: 'center'}}
                                                            avatar={
                                                                profile_photo ?
                                                                    <Avatar src={profile_photo} /> :
                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                        {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                                    </Avatar>
                                                            }
                                                            title={<div>{firstName} {lastName}</div>}
                                                        />
                                                        <div>
                                                            <Switch
                                                                onChange={() => this.onStudentSelectDeselect(id)}
                                                                checked={selectedStudentsKeys.includes(id)}
                                                                size={'small'}
                                                            />
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                        >
                                        </List>
                                    </div>
                                    <div className="text-right pt-12">
                                        <Button
                                            onClick={this.onSelectAllStudent}
                                            disabled={(selectedStudentsKeys || []).length === (allStudentList || []).length || !(this.getAllStudentsList() || []).length}
                                            size="small"
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            onClick={() => this.setState({selectedStudentsKeys: []})}
                                            style={{marginLeft: 5}}
                                            disabled={!(selectedStudentsKeys || []).length}
                                            size="small"
                                        >
                                            Deselect All
                                        </Button>
                                        <Button
                                            className="review-button"
                                            disabled={!(selectedStudentsKeys || []).length}
                                            onClick={this.onSelectedStudentToCurrent}
                                            size="small"
                                            style={{marginLeft: 5}}
                                        >
                                            Add Selected ({(selectedStudentsKeys || []).length || 0})
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <h4>Current {isStudent ? "Student" : "Staff"}</h4>
                                <div className="bg-white shadow" style={{padding: 10}}>
                                    <Input
                                        className="w-100 mb-2"
                                        placeholder="Search"
                                        onChange={(e) => this.setState({searchCurrentStudent: e.target.value})}
                                    />
                                    <div className="demo-infinite-container" style={{height: 400}}>
                                        <List
                                            dataSource={this.getCurrentStudentList()}
                                            renderItem={(item, index) => {
                                                const { id, profile_photo = "", firstName = "", lastName = "" } = item || {}
                                                return(
                                                    <List.Item key={id}>
                                                        <List.Item.Meta
                                                            style={{alignItems: 'center'}}
                                                            avatar={
                                                                profile_photo ?
                                                                    <Avatar src={profile_photo} /> :
                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                        {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                                    </Avatar>
                                                            }
                                                            title={<div>{firstName} {lastName}</div>}
                                                        />
                                                        <div><Icon type="delete" onClick={() => this.onRemoveCurrentStudent(id)} className="text-danger fs-15" theme="filled" /></div>
                                                    </List.Item>
                                                )
                                            }}
                                        >
                                        </List>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddCurrentStudentStaffModal)