import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware();
var composeEnhancer = null;
if (process.env.NODE_ENV == 'development'){
  composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}else{
  composeEnhancer =  compose;
}

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware,thunk)),
);

sagaMiddleware.run(rootSaga);
export default store;