
export const EVENT_TYPES = Object.freeze({
    "Generic_Event": "generic",
    "RSVP_Event": "rsvp",
    "Parent_Teacher_Event": "pta",
    "Volunteer_Event": "volunteer"
});

export const isGenericEvent = (type) => type === "generic"
export const isRSVPEvent = (type) => type === "rsvp"
export const isPTAEvent = (type) => type === "pta"
export const isVolunteerEvent = (type) => type === "volunteer"
