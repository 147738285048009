import { FETCH_CHILD_REPORT_LIST_SUCCESS, FETCH_CHILD_REPORT_LIST_FAILURE ,FETCH_SCALES_SUCCESS,FETCH_SCALES_FAILURE} from "../actions/reportadmin";


const INITIAL_STATE = {
    data: [],
    scales : null,
    error: null,
    isLoading: false,
}

const child = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CHILD_REPORT_LIST_SUCCESS:
            return {
                ...state,
                data: action.data,
            }
        case FETCH_CHILD_REPORT_LIST_FAILURE:
            return {
                ...state,
                data: null,
            }

        case FETCH_SCALES_SUCCESS:
                return {
                    ...state,
                    scales: action.data,
                }
                
        case FETCH_SCALES_FAILURE:
            return {
                ...state,
                scales: null,
            }
        default:
            return state
    }
}

export default child;