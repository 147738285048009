import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames"
import { makeStyles } from "@material-ui/styles"
import FaceIcon from '@material-ui/icons/Face';
import { Chip, Badge, DialogActions, DialogContent } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import blue from '@material-ui/core/colors/blue';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.1),
        color: 'darkblue',
        cursor: "pointer",
        fontSize:"12px"
    },
    selectedChip: {
        margin: theme.spacing(1),
        color: 'red',
        cursor: "pointer"
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
}));


function UsersDialog(props) {
    const classes = useStyles();
    const { open, onClose, selectedValues = [], items } = props;
    const [checked, setChecked] = React.useState(selectedValues);
    useEffect(() => {
        setChecked(selectedValues)
    }, [selectedValues])

    function handleClose() {
        onClose(checked);
    }

    function handleListItemClick(value) {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="simple-dialog-title">
            <DialogTitle id="simple-dialog-title">Select Teacher</DialogTitle>
            <DialogContent>
                <List>
                    {items.map((item, index) => (
                        <ListItem button onClick={() => handleListItemClick(item.id)} key={index}>
                            <Checkbox checked={checked.indexOf(item.id) !== -1} tabIndex={-1} disableRipple />
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${item.firstName || ""} ${item.lastName || ""}`} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UsersDialog.propTypes = {
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

function ReviewList(props) {
    const { options, review_mode, review_users, groupids } = props;
    const classes = useStyles();
    const [invisible, setInvisible] = React.useState(!review_mode.input.value);
    const [open, setOpen] = React.useState(false);
    const [selectedValues, setSelectedValues] = React.useState(review_users.input.value);
    const [groupid, setGroupId] = React.useState(groupids)
    const badgeContent = selectedValues ? selectedValues.length : 0

    // if (invisible) {
    //     review_users.input.onChange([])
    //     review_mode.input.onChange(false)
    // }

    useEffect(() => {
        
        function resetInput() {
            setSelectedValues([])
            setInvisible(true)
            review_users.input.onChange([])
            review_mode.input.onChange(false)
        }
        if (groupid !== groupids) {
            setGroupId(groupids)
            resetInput();
        }

    }, [groupids])

    useEffect(() => {
        if (!review_users.input.value) {
            setSelectedValues([])
            setInvisible(true)
        }
    }, [review_users])

    function handleClickOpen() {
        setOpen(true);
    }

    const handleClose = value => {
        setOpen(false);
        setSelectedValues(value);
        review_users.input.onChange(value)
        if (value.length > 0) {
            setInvisible(false);
            review_mode.input.onChange(true)
        } else {
            setInvisible(true);
            review_mode.input.onChange(false)
        }
    };


    return (
        <>
            <Badge color="secondary" badgeContent={badgeContent} invisible={invisible}>
                <Chip
                    icon={<FaceIcon style={{ fontSize: "medium" }} />}
                    label="Review"
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    className={classnames({
                        [classes.chip]: invisible,
                        [classes.selectedChip]: !invisible,

                    })}
                />
            </Badge>

            <UsersDialog
                items={options}
                selectedValues={selectedValues}
                open={open}
                onClose={handleClose}
            />

        </>
    )
}

export default ReviewList
