import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getEvent, setInitialValue, handleRsvpFilterTab,
  fetchEventResponse, handleRsvpSearchRecord, updateRsvpResponseType,
  getEventById,
  resetStepperActiveStep
} from '../redux/actions/event';
import { EventSelector } from "../redux/selector";
import UpdateRsvp from "../component/Event/UpdateRsvp/UpdateRsvp";
import { openDialogBox, closeDialogBox } from '../redux/actions/component';


const mapStateToProps = state => {
  return {
    // events: EventSelector.getGroupWithNameFromEventId(state),
    event: state.event.eventById,
    rsvpFilterValue: state.event.rsvpFilterValue,
    rowsRsvp: EventSelector.getDataForRsvp(state),
    currentUser: state.currentUser.newUserInfo.user,
    dialogProps: state.component.dialog,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setInitialValue: bindActionCreators(setInitialValue, dispatch),
    getEvent: bindActionCreators(getEvent, dispatch),
    getEventById: bindActionCreators(getEventById, dispatch),
    handleRsvpFilterAction: bindActionCreators(handleRsvpFilterTab, dispatch),
    fetchEventResponseAction: bindActionCreators(fetchEventResponse, dispatch),
    handleRsvpSearchAction: bindActionCreators(handleRsvpSearchRecord, dispatch),
    updateRsvpEventResponseAction: bindActionCreators(updateRsvpResponseType, dispatch),
    openDialogAction: bindActionCreators(openDialogBox, dispatch),
    closeDialogAction: bindActionCreators(closeDialogBox, dispatch),
    resetStepperActiveStep: bindActionCreators(resetStepperActiveStep, dispatch),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UpdateRsvp)
