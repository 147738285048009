import { API } from 'aws-amplify';

const getReportforGroups = (body) => {
    const init = {
        body
    }
    return API.post('report-get-group', '', init).then(res => res);
}

const getScales = () => {
    const init = {
        
    }
    return API.post('report-get-scales', '', init).then(res => res);
}

const getPDFReport = (body) => {
    const init = {
        body
    }
    return API.post('report-get-pdf', '', init).then(res => res);
}

const markasPublished = (body) => {
    const init = {
        body
    }
    return API.post('mark-as-published', '', init).then(res => res);
}

const reportService = {
    getReportforGroups,getScales, getPDFReport, markasPublished
}

export default reportService;