import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import RsvpAttend from './RsvpAttend';
import { Typography } from '@material-ui/core';
import EventService from '../../../service/event';

function RsvpResponse(props) {

    const { event, readOnly, currentUser, getEventResponse } = props
    const { user_response = null } = event

    const [isModified, setIsModified] = useState(false)

    useEffect(() => {

        function checkIsModified() {
            const { attributes: { invitees = {} } = {} } = event || {}
            const isModified = !!(((invitees || {})[currentUser.id] || {}).will_attend || "");
            setIsModified(isModified)
        }

        checkIsModified()
    }, [props.event])

    const updateRSVP = async (data) => {
        // props.sendRsvpEventResponseAction.request(data);
        const response = await EventService.updateRsvpEventResponse(data)
        if(response && response.status === "success") {
            getEventResponse()
        }
    }

    function handleUpdateClick(args) {
        setIsModified(false)
    }

    const getResponse = () => {
        const { attributes: { invitees = {} } = {} } = event || {}
        const response = ((invitees || {})[currentUser.id] || {}) || {}
        return response
    }

    console.log({event})
    return (
        <>
            <Typography style={{textAlign: "center", background: "rgb(112, 146, 190)", lineHeight: 3}}>
                <span style={{fontSize: 14, color: "white", fontWeight: "bold"}}>Current RSVP  : </span>
                {<span style={{color: "chartreuse"}}>RSVP {(getResponse() || {}).will_attend || "Pending"}</span>}
                {/*{isModified && getResponse() &&
                    <span>
                        <span style={{color: "chartreuse"}}>{user_response.attend} </span>
                        <span style={{fontSize: 14, color: "white", fontWeight: "bold"}}>
                            ( {user_response.adult} adult {' '} , {user_response.children} children )
                        </span>
                    </span>
                }*/}
            </Typography>

            {isModified &&
                <Typography color="primary" variant="h6" className="rsvpUpdate" onClick={handleUpdateClick}>
                    UPDATE RSVP
                </Typography>
            }

            {!isModified &&
                <>
                    <Typography color="primary" variant="h6" className="rsvpAttend">
                        Will You Attend?
                    </Typography>
                    <RsvpAttend
                        readOnly={readOnly}
                        eventId={event.event_id}
                        updateRSVP={updateRSVP}
                        currentUser={props.currentUser || {}}
                    />
                </>
            }
        </>
    )
}

RsvpResponse.propTypes = {
    event: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool
}

RsvpResponse.defaultPropTypes = {
    readOnly: false
}

export default RsvpResponse