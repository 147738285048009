import React, { Component } from "react";
import PropTypes from "prop-types";
import { PDFViewer } from '@react-pdf/renderer';
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Storage } from "aws-amplify";
import Popover from '@material-ui/core/Popover';
import renderHTML from 'react-render-html';
import Moment from 'react-moment';
import Chip from '@material-ui/core/Chip';
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from "@material-ui/core";
import Linkify from "react-linkify";
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {notification} from "antd";
import Card from "@material-ui/core/Card/Card";
import CalImg from "../../../assets/images/calendar-2.png";
import AlarmImg from "../../../assets/images/alarm-clock.png";
import LocationImg from "../../../assets/images/location.png";
import GroupImg from "../../../assets/images/group.png";
import * as Route from "../../../routes/path";
import {getCurrentSchoolId, toDataURL, isUserParent, getEventsChild, onSetupEvent} from "../../../utils/utils";
import {isGenericEvent, isPTAEvent, isRSVPEvent, isVolunteerEvent} from "../../../shared/enums";
import withDialog from "../../common/hoc/Dialog";
import RsvpResponse from "../RsvpAttend";
import AsyncImage from "../../common/reduxForm/AsyncImage";
import PreviewMeeting from "../../Event/MeetingDateTime/PreviewMeeting";
import PreviewVolunteer from "../Volunteer/PreviewVolunteer";
import TransferDialog from './TransferDialog';
import {PrintEventPreview} from "./PrintEventPreview";
import {PrintVolunteerPreview} from "../Volunteer/PrintVolunteerPreview";
import EventService from "../../../service/event";

const styles = theme => ({
  root: {
    flexGrow: 1
  },

  titleImage: {
    // width: "100%",
    objectFit: "cover",
  },
  dialogTitle: {
    // boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    padding: 0,
    height: '280px'
  },
  headerBtn: {
    margin: 'auto 0',
    color: '#05b9a4'
  },
  moreSubTitle: {
    padding: 10,
    textAlign: 'center',
    color: '#3c3989',
    cursor: 'pointer'
  },
  title: {
    fontSize: '40px',
    color: '#3f51b5',
  },
  location: {
    alignItems: 'center',
    marginTop: '25px'
  },
  alignment: {
    marginBottom: '20px',
  },
  clockImg: {
    height: '30px',
    width: '30px',
    float: 'left',
    margin: '5px'
  },
  description: {
    border: '1px solid #e2e2e2',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '20px'
  }
});

const other = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  }
}

class EventPreview extends Component {
  state = {
    open: false,
    pdfOpen: false,
    openReminder: false,
    anchorEl: null,
    attachment: [],
    loggedInChildList: [],
    anchorElOpenMore: null,
    openTransfer: false,
    transferEmail: '',
    ptaEventResponse: {}
  };

  componentDidMount() {
    const { fetchEventResponseAction, event: { attributes: { child_ids = [], groups = [], event_type = "" }, event_id = "" } = {} } = this.props;
    const type = (groups || []).length ? "groups" : "childs"
    if (isRSVPEvent(event_type) || isPTAEvent(event_type) || isVolunteerEvent((event_type))) {
      fetchEventResponseAction.request({ event_id: event_id })
      this.getEventResponse()

    } else {
      this.setState({
        ptaEventResponse: this.props.event || {}
      })
    }
    this.getLoggedinStudentList(type, type === "groups" ? groups : child_ids);
  }

  getEventResponse = async () => {
    const { event: { id = "" } } = this.props
    const response = await EventService.getEventById(id)
    if(response && response.status === "error") {

    } else {
      const object = onSetupEvent(response || {})
      this.setState({
        ptaEventResponse: object
      })
    }
  }

  async getLoggedinStudentList(type, ids) {
    const { childrencache, currentUser = {} } = this.props

    const array = getEventsChild(childrencache, type, ids, currentUser)
    this.setState({ loggedInChildList: array || [] })
  }

  handleClickButton = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  handleManage = () => {
    const { event: { attributes: { event_type = "" } = {}, event_id = "" } = {} } = this.props

    this.props.setInitialValue(this.props.event);
    this.props.closeDialogAction();
    if (isGenericEvent(event_type)) {
      this.props.history.push(Route.MANAGE_EVENT + '/' + event_id);
    } else if (isRSVPEvent(event_type)) {
      this.props.history.push(Route.EDIT_RSVP_EVENT + '/' + event_id);
    } else if (isPTAEvent(event_type)) {
      this.props.history.push(Route.MEETING_EVENT_SIGNUP + '/' + event_id);
    } else if (isVolunteerEvent(event_type)) {
      this.props.history.push(Route.VOLUNTEER_EVENT_SIGNUP + '/' + event_id);
    }
  }

  handleCopy = () => {
    const { event: { attributes: { event_type = "" } = {}, event_id = "", id = "" } = {} } = this.props

    if(event_id || id) {
      delete this.props.event.event_id;
      delete this.props.event.id;
    }

    this.props.setInitialValue(this.props.event);
    if (isGenericEvent(event_type)) {
      this.props.history.push(Route.MANAGE_EVENT);
    } else if (isRSVPEvent(event_type)) {
      this.props.history.push(Route.MANAGE_RSVP_EVENT);
    } else if (isPTAEvent(event_type)) {
      this.props.history.push(Route.MEETING_EVENT);
    } else if (isVolunteerEvent(event_type)) {
      this.props.history.push(Route.VOLUNTEER_EVENT);
    }
  }

  handleDownload = async (file) => {
    const dataUrl = await Storage.get(file.key, { level: "public" });

    toDataURL(dataUrl, (dataUrl) => {
      const a = document.createElement("a");
      a.href = dataUrl;
      a.setAttribute("download", file.name);
      const b = document.createEvent("MouseEvents");
      b.initEvent("click", false, true);
      a.dispatchEvent(b);
      return false;
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDeleteEvent = () => {
    const { event, deleteEventAction, currentUser = {} } = this.props
    deleteEventAction.request({ event_id: event.event_id, school_id: getCurrentSchoolId(currentUser) })
  }

  handleMoreClick = (event) => {
    this.setState({
      anchorElOpenMore: event.currentTarget
    });
  }

  handleMoreClose = () => {
    this.setState({
      anchorElOpenMore: null
    });
  }

  handleTransferOpen = () => {
    this.setState({
      openTransfer: true,
      anchorElOpenMore: null
    });
  }

  handleTransferClose = () => {
    this.setState({
      openTransfer: false,
    });
  }

  handleTransferOk = (value) => {
    // email validation
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(value)) {
      const { transferOwnerShip, event } = this.props;
      const body = {
        eventId: event.event_id,
        email: value
      }
      transferOwnerShip.request(body);
      this.setState({
        openTransfer: false,
        transferEmail: value
      });
    } else {
      this.setState({
        transferEmail: ''
      });
      console.log('invalid email');
    }

  }

  componentWillUnmount(){
    this.props.newDataSet("off")
  }

  handlePreview = () => {
    this.setState({ pdfOpen: true, data: {} });
  };

  getStudentName = (id) => {
    const { childrencache } = this.props
    const { firstName = "", lastName = "" } = childrencache && childrencache[id] || {}
    if(firstName || lastName) {
      return `${firstName || ""} ${lastName || ""}`
    }
    return ""
  }

  getGroupName = (id) => {
    const { groupcache } = this.props
    const { attributes: { groupname = "" } = {} } = groupcache && groupcache[id] || {}
    if(groupname) {
      return groupname
    }
    return ""
  }

  onShareableLinkCopy = () => {
    notification.open({
      message: 'Copied',
      placement: 'bottomLeft',
      style: {background: 'green'}
    });
  }

  render() {
    const { classes, event, currentUser, sendRsvpEventResponseAction } = this.props;
    const {
      open,
      anchorEl,
      anchorElOpenMore,
      openReminder,
      openTransfer,
      transferEmail,
      loggedInChildList,
      ptaEventResponse
    } = this.state;

    const { user, creator = "", attributes: { event_title = "", location = "", event_description = "", event_type = "", event_img_url = [], groups = [], child_ids = [], attachments = [], all_day_event, limit = "" } = {} } = event;

    const openMore = Boolean(anchorElOpenMore);
    const moreId = open ? 'simple-popover' : undefined;

    const isOwner = creator === currentUser.id || !isUserParent(currentUser)
    const isRsvp = isRSVPEvent(event_type)
    const isMeeting = isPTAEvent(event_type)
    const isVolunteer = isVolunteerEvent(event_type)

    return (
      <div className={classes.root}>
        <DialogTitle id="confirmation-dialog-title" className={classes.dialogTitle} >
          {/*<div className="cardComponent">
            <span >
              <AsyncImage className={classes.titleImage} src={event_img_url[0].key} style={{width: "35%", borderBottomLeftRadius: 20, borderTopLeftRadius: 20}}/>
            </span>
            <span style={{padding: "30px", fontSize: 35, color: '#3f51b5'}}> {event_title || ""} </span>
          </div>*/}
          <Card style={{ display: 'flex' }} className="cardComponent" >
            <AsyncImage style={{ width: 'auto', height: 'fit-content', minHeight: 280 }} className={classes.cover} src={(event_img_url || []).length && (event_img_url || [])[0].key || ""} />
            <Grid container justify="center" style={{ width: '50%', alignItems: 'center' }}>
              <span className={classes.title}> {event_title || ""} </span>
            </Grid>
          </Card>
        </DialogTitle>

        <DialogContent style={{ overflowY: "hidden" }} >
          <div >
            <Grid item xs={12}>

              <Grid container justify="space-between" style={{ margin: '10px 0' }}>
                <Grid style={{ marginBottom: '5px' }}/>
                  {/*<span className={classes.title}> {event_title || ""} </span>
                </Grid>*/}
                <Grid>
                  <Grid>
                    { isOwner && (
                      <Button
                        color="primary"
                        className={classes.headerBtn}
                        onClick={this.handleManage}>Manage</Button>
                    )}
                  </Grid>
                  <Popover
                    id={moreId}
                    open={openMore}
                    anchorEl={anchorElOpenMore}
                    onClose={this.handleMoreClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Grid container direction="column">
                      <span className={classes.moreSubTitle} onClick={this.handleDeleteEvent}                  >
                        Delete Event
                      </span>
                      <span className={classes.moreSubTitle} onClick={this.handleCopy}>
                        Copy Event
                      </span>
                      {/*<span className={classes.moreSubTitle} onClick={this.handleTransferOpen}>
                        Transfer Ownership
                      </span>*/}
                      <CopyToClipboard text={`${window.location.origin}/event?id=${event.id}`} onCopy={this.onShareableLinkCopy}>
                        <span className={classes.moreSubTitle}>
                          Shareable link
                        </span>
                      </CopyToClipboard>
                    </Grid>
                  </Popover>

                  <TransferDialog
                    open={openTransfer}
                    value={transferEmail}
                    onClose={this.handleTransferClose}
                    onTransfer={this.handleTransferOk}
                  />

                  <Grid>
                    {isOwner && (
                      <Button
                        className={classes.headerBtn}
                        onClick={this.handleMoreClick}
                      >
                        More
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <div>
                <div>
                  { ((groups || []).length || (child_ids || []).length) && (
                    <Popover
                      id="render-props-popover"
                      open={open}
                      anchorEl={anchorEl}
                      onClose={this.handleClose}
                      {...other}
                      className="groupPopper"
                    >
                      <Typography
                        color="primary"
                        variant="h6"
                        className="eventDialogSubTitle"
                      >
                        <p>
                          {(groups || []).map((group, index) => (
                            <span
                              className="groupChip"
                              key={index.toString()}
                            >
                              {this.getGroupName(group) || ""}
                            </span>
                          ))}
                          {(child_ids || []).map((group, index) => (
                            <span
                              className="groupChip"
                              key={index.toString()}
                            >
                              {this.getStudentName(group) || ""}
                            </span>
                          ))}
                        </p>
                      </Typography>
                    </Popover>
                  )}

                  <Grid container justify="space-around" className={classes.alignment} >
                    <div>
                      <img
                        className={classes.clockImg}
                        src={CalImg}
                        alt="previcon"
                      />
                      <p className="timeSection">
                        <Moment format="ddd D, MMMM YYYY">
                          {event.start_date}
                        </Moment>
                        <br />
                        <Moment format="ddd D, MMMM YYYY">
                          {event.end_date}
                        </Moment>
                      </p>
                    </div>
                    <div>
                      <img
                        className={classes.clockImg}
                        alt="previcon"
                        src={AlarmImg}
                      />
                      <p className="timeSection">
                        {
                          all_day_event ?
                            <div style={{marginTop: 8}}>All day event</div> :
                            <>
                              <Moment format="hh:mm a">
                                {event.start_time}
                              </Moment>
                              <br />
                              <Moment format="hh:mm a">
                                {event.end_time}
                              </Moment>
                            </>
                        }
                      </p>
                    </div>

                    <div className="eventDialogcontent centerContent rsvp-around box">
                      <div>
                        <img
                          className="prevIcon1"
                          alt="previcon"
                          src={GroupImg}
                          style={{ marginRight: '5px', height: "20px", position: "relative", cursor: "pointer" }}
                          onClick={this.handleClickButton}
                        />
                        {groups && !!groups.length &&
                          <p className="locationSection" >
                            {(groups || []).length} Groups  {/*{this.getGroupName(groups[0]) || ""}*/}
                          </p>
                        }
                        {child_ids && !!child_ids.length &&
                          <p className="locationSection" >
                            {(child_ids || []).length} Students
                          </p>
                        }
                      </div>
                    </div>

                    { location &&
                      <div>
                          <img
                            className="prevIcon1"
                            src={LocationImg}
                            alt="previcon"
                            style={{ height: "20px" }}
                          />
                          <p className="locationSection" >
                            {location}
                          </p>
                        </div>
                    }
                  </Grid>

                  {
                    isRsvp && (limit !== 100000) &&
                    <Grid container justify="space-around" className={classes.alignment} >
                      <div>
                        Limit: {limit}
                      </div>
                    </Grid>
                  }

                  {event_description && <div className={classes.description}> <Linkify> {renderHTML(event_description)} </Linkify> </div>}
                </div>

                {isMeeting || isVolunteer  ?
                  <Typography variant="subtitle1" style={{textAlign: "right"}}>
                    <PDFIcon style={{fontSize: "xx-large", color: "blue", cursor: "pointer"}} onClick={this.handlePreview}/>
                  </Typography> : null
                }

                <Dialog
                  disableBackdropClick
                  disableEscapeKeyDown
                  open={this.state.pdfOpen}
                  fullWidth
                  fullScreen
                >
                  <DialogContent>
                    <PDFViewer style={{ width: '100%', height: '100%' }}>
                      {isMeeting && <PrintEventPreview {...this.props} event={ptaEventResponse || event || {}} childList={loggedInChildList || []} />}
                      {isVolunteer && <PrintVolunteerPreview {...this.props} /> }
                    </PDFViewer>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => this.setState({pdfOpen: false})}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                        onClick={() => this.setState({pdfOpen: false})}
                        color="primary"
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>

                {isRsvp &&
                  <RsvpResponse
                    event={ptaEventResponse || event}
                    sendRsvpEventResponseAction={sendRsvpEventResponseAction}
                    currentUser={currentUser || {}}
                    getEventResponse={this.getEventResponse}
                  />
                }

                {isMeeting &&
                  <PreviewMeeting
                    event={ptaEventResponse || event || {}}
                    readOnly={false}
                    data={loggedInChildList}
                    setEventResponse={this.props.setEventResponse}
                    eventResponse={this.props.eventResponse}
                    currentUser={currentUser}
                    removeChildEventAction={this.props.removeChildEventAction}
                    isAdmin={this.props.isAdmin}
                    getEventResponse={this.getEventResponse}
                  />
                }

                {isVolunteer &&
                  <PreviewVolunteer
                    event={event}
                    readOnly={false}
                    data={loggedInChildList}
                    setEventResponse={this.props.setEventResponse}
                    eventResponse={this.props.eventResponse}
                    email={currentUser.email}
                    removeChildEventAction={this.props.removeChildEventAction}
                  />
                }

                {(attachments && !!attachments.length) && (
                  <div className="eventDialogcontent eventContainer">
                    <strong className="note">Attachments</strong>
                    <br />
                    <div className="customRow">
                      {attachments && attachments.map((attachment, key) => (
                        <Chip
                          key={key}
                          label={attachment.name}
                          color="primary"
                          variant="outlined"
                          onClick={e => { this.handleDownload(attachment) }}
                        />
                      ))}
                    </div>
                  </div>
                )}

              </div>
            </Grid>
          </div>
        </DialogContent>
      </div >
    );
  }
}


EventPreview.propTypes = {
  classes: PropTypes.object.isRequired,
};

const WrapperStyle = withStyles(styles)(EventPreview);

export default withDialog(WrapperStyle) 