import React, { useState, useEffect } from 'react'
import {Avatar} from 'antd'
export const StudentAvatar = (params) => {
    const stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const hexToRgb = (hex) => {
        if (!hex || hex === undefined || hex === '') {
            return undefined;
        }

        const result =
            /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : undefined;
    }

    const rgbToYIQ = ({ r, g, b }) => {
        return ((r * 299) + (g * 587) + (b * 114)) / 1000;
    }


    const contrast = (colorHex) => {
        if (colorHex === undefined) {
            return '#000';
        }

        const rgb  = hexToRgb(colorHex);

        if (rgb === undefined) {
            return '#000';
        }

        return rgbToYIQ(rgb) >= 128 ? '#000' : '#fff';
    }

    let initial =  params.studentObj?.firstName[0] + params.studentObj?.lastName[0]
    let bgColor =  stringToColor(params.studentName)
    let studentChip = params.studentObj.profile_photo !== "" ?
                <Avatar size={30} alt={params.studentName} src={params.studentObj.profile_photo}></Avatar> :
                <Avatar size={30} style={{backgroundColor : bgColor, color : contrast(bgColor)}}>{initial}</Avatar>
    return (
        studentChip
    )
}