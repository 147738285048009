import React, { Component } from 'react';
import {
    Input as MartialInput,
} from '@material-ui/core';


class Input extends Component {
    render() {
        const { input, ...rest } = this.props;
        return (
            <MartialInput
              {...input}
              {...rest}

            />
        )
    }
}

export default Input
