const Scale = [
        {
        'rating': '-',
        'name': 'None',
        'color': 'white'
    },{
        'rating': 'I',
        'name': 'Introduced',
        'color': '#9BC4FD'
    },
    {
        'rating': 'W',
        'name': 'Working',
        'color': '#A195EE'
    },
    {
        'rating': 'M',
        'name': 'Mastered',
        'color': '#88D18A'
    },
    {
        'rating': 'N',
        'name': 'Needs More',
        'color': '#F3BE7C'
    },
    {
        'rating': 'F',
        'name': 'Plan',
        'flag': true,
        'color': 'white'
    }

]

export default Scale