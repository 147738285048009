import React from 'react';
import { Button, Card, makeStyles, withStyles, Typography, IconButton, Grid, LinearProgress, DialogTitle,
  DialogContent, DialogActions, Dialog, CircularProgress, TextField} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import "../progressReport.css"


const cardHeight = 280;
const cardWidth = 290;
const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  subTitle1: {
    height: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'rgb(84, 99, 214)',
  },
  addIcon: {
    marginBottom: theme.spacing(1),

    width: 30,
    height: 30,
    color: 'rgb(84, 99, 214)',
  },
  addTitle: {
    textAlign: 'center',
    fontSize: '24px',
  },
  addCard: {
    display: 'flex',
    color: 'gray',
    height: cardHeight,
    minHeight: cardHeight,
    width: cardWidth,
    cursor: 'pointer',
    borderRadius: '10px',
    // border: '3px solid rgb(84, 99, 214)'
  },
  card: {
    display: 'flex',
    minHeight: cardHeight,
    width: cardWidth,
    borderRadius: '10px',
    paddingBottom: '12px',
    paddingTop: '12px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0.2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  cardHeader: {
    width: '100%',
    padding: theme.spacing(1),
  },
  avatar: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 30,
    height: 30,
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    color: '#000000',
    fontSize: 21,
    textAlign: "center"
  },

  tx: {
    textAlign: 'center',
  },
  txx: {
    paddingRight: theme.spacing(7),
    fontWeight: 'bolder',
  },
  bolderTitle: {
    fontWeight: 'bolder',
  },
  greenStatus: {
    color: '#3CC89C',
    fontSize: '14px',
  },
  subContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  markingStatus: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
  },
  subCotnainerTitle: {
    color: '#C4C4C4',
    fontSize: '12px',
    fontWeight: 'Bold',
  },
  subCotnainerContent: {
    fontSize: '11.2px',
  },
  t2: {
    paddingTop: theme.spacing(0.5),
  },
  t1: {
    // marginTop: - theme.spacing(.5),
  },
  progressRoot: {
    flexGrow: 1,
    maxWidth: 130,
    paddingRight: theme.spacing(0.5),
    marginTop: -theme.spacing(0.5),
    // paddingTop: theme.spacing(0.5),
  },
  floatText: {
    paddingRight: theme.spacing(0.5),
  },
  progress: {
    colorPrimary: '#3CC89C',
  },
  actions: {
    marginTop: theme.spacing(1),
    fontWeight: 'bolder',
  },
  btn: {
    fontWeight: 'bold',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  icon: {
    // color: '#CECECE',
    color: 'silver',
    fontSize: 'medium',
  },
}));


function ModuleCard(props) {
  const { cardType, isAdmin, index } = props;
  const classes = useStyles();
  const [values, setValues] = React.useState({
    open: false,
    cloneDialogIsOpen: false,
    moduleToCloneName: '',
  });
  const [success, setSuccess] = React.useState(false);

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(
    () => () => {
      clearTimeout(timer.current);
    },
    [],
  );

  const handleCloneCancel = () =>
    setValues({ ...values, cloneDialogIsOpen: false });

  function handleCloneButtonClick() {
    const { module } = props;
    module.name = values.moduleToCloneName;
    props.onCloneModule(module);
    setValues({ ...values, cloneDialogIsOpen: false });
  }

  function handleButtonClick() {
    if (!props.isDeleting) {
      props.onDeleteModule(props.module);
      timer.current = setTimeout(() => {
        setValues({ ...values, open: false });
      }, 2000);
    }
  }

  const handleCancel = () => {
    setValues({ ...values, open: false });
  };

  const handleOpenCloneModuleDialog = () =>
    setValues({ ...values, cloneDialogIsOpen: true });

  function handleDisplayGroupDetails(index) {
    props.history.push({
      pathname: `/groups/list/${props.module.id}`,
      state: { backgroundColor: getColors(index) },
    });
  }

  function getColors(index) {
    let color = ""

    if(index === 0) {
      color = "#FCCB2A"
    } else if(index === 1) {
      color = "#849B47"
    } else if(index === 2) {
      color = "#D6591E"
    } else if(index === 3) {
      color = "#5271FF"
    } else if(index === 4) {
      color = "#32737E"
    } else if(index === 5) {
      color = "#004AAD"
    } else if(index === 6) {
      color = "#000000"
    } else if(index === 7) {
      color = "#17141B"
    } else if(index === 8) {
      color = "#354CA5"
    } else if(index === 9) {
      color = "#6B2C44"
    }

    return color
  }

  function onDeleteModule() {
    setValues({ ...values, open: true });
  }

  function onEditModule() {
    props.onEditModule(props.module);
  }

  const card =
    cardType === 'addModule' ? (
      <Card className={classes.addCard} onClick={props.handle}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <AddIcon className={classes.addIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.subTitle1}>New Report</Typography>
          </Grid>
        </Grid>
      </Card>
    ) : (
      <Card className={`${classes.card} image${index % 10}`}>
        <Grid container direction="column" style={{background: "white", margin: "70px 15px"}}>
          {isAdmin && (
            <Grid
              className={classes.cardHeader}
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  width: '80%',
                  justifyContent: 'flex-start',
                }}
              >
                <IconButton color="inherit" size="small">
                  <RemoveIcon
                    className={classes.icon}
                    onClick={onDeleteModule}
                  />
                </IconButton>
              </Grid>

              <Grid item>
                <IconButton color="inherit" size="small">
                  <CopyIcon
                    className={classes.icon}
                    onClick={handleOpenCloneModuleDialog}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="inherit" size="small">
                  <EditIcon
                    className={classes.icon}
                    onClick={onEditModule}
                    fontSize="small"
                  />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Grid container justify="center" className={`cursor-pointer align-item-center ${isAdmin ? "" : "h-full"}`} style={{padding: '0px 15px'}} onClick={() => handleDisplayGroupDetails(index % 10)}>
            <Grid item>
              <Typography className={classes.cardTitle}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      {card}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={values.open}
      >
        <DialogTitle id="confirmation-dialog-title">
          Are you sure you want to delete this module ?
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="caption">{props.title}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={props.isDeleting}
              onClick={handleButtonClick}
            >
              Delete
            </Button>
            {props.isDeleting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={values.cloneDialogIsOpen}
      >
        <DialogTitle id="confirmation-dialog-titlexrx">
          Enter your new template name, please ?
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            placeholder="enter module name"
            onChange={e =>
              setValues({ ...values, moduleToCloneName: e.target.value })
            }
            value={values.moduleToCloneName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloneCancel} color="primary">
            Cancel
          </Button>
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={buttonClassname}
              onClick={handleCloneButtonClick}
            >
              Clone
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

ModuleCard.propTypes = {
  cardType: PropTypes.string,
  handle: PropTypes.any,
  history: PropTypes.any,
  title: PropTypes.string,
  module: PropTypes.any,
  onDeleteModule: PropTypes.any,
  onEditModule: PropTypes.any,
  isDeleting: PropTypes.any,
  onCloneModule: PropTypes.any,
  role: PropTypes.any,
};

export default withRouter(ModuleCard);
