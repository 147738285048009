import {
    REQUEST,
    SUCCESS,
    FAILURE,
    action,
    createRequestTypes
} from './common';

export const POST_COMMENT = createRequestTypes('POST_COMMENT');
export const postComment = {
    request: query => action(POST_COMMENT[REQUEST], {query}),
    success: payload => action(POST_COMMENT[SUCCESS], {payload}),
    failure: error => action(POST_COMMENT[FAILURE], {error}),
};

export const GET_HISTORY = createRequestTypes('GET_HISTORY');
export const getHistory = {
    request: query => action(GET_HISTORY[REQUEST], {query}),
    success: payload => action(GET_HISTORY[SUCCESS], {payload}),
    failure: error => action(GET_HISTORY[FAILURE], {error}),
};