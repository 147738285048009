import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Field } from "redux-form";
import RenderSwitch from '../../common/reduxForm/RenderSwitch';
import RenderTextField from "../../common/reduxForm/RenderTextField";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    control: {
        padding: theme.spacing(1) * 2,
    },
    textField: {
        width: '100%',
    },
    chip: {
        margin: theme.spacing(1) / 2,
    },
    parentOpenLink: {
        cursor: 'pointer'
    },
});

class RsvpSetting extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} className={classes.control}>
                        <Field
                            name={`attributes.limit`}
                            type="number"
                            component={RenderTextField}
                            className={classes.textField}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(RsvpSetting);
