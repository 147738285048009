import React from 'react';
import {connect} from 'react-redux'
import {Modal} from 'antd'
import { FormControl, Grid, InputLabel, MenuItem, Select
} from "@material-ui/core";
import "./modal.css"


class TimelineModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            num_years: "",
            num_partitions: ""
        }
    }

    componentDidMount() {
        this.setState({
            num_years: this.props.num_years || "",
            num_partitions: this.props.num_partitions || ""
        })
    }

    onChange = (event, key) => {
        this.setState({
            [key]: event.target.value
        })
    }

    onSetValues = () => {
        const { onSetValues, onClose } = this.props
        const { num_years, num_partitions } = this.state
        onSetValues({num_years, num_partitions})
        onClose()
    }

    render() {
        const { onClose } = this.props;
        const { num_years, num_partitions } = this.state;

        return(
            <Modal
                title="Set Timeline"
                visible={true}
                onOk={this.onSetValues}
                onCancel={onClose}
                okButtonProps={{disabled: !(num_partitions && num_years)}}
            >
                <Grid
                    container
                    direction="row"
                    justify="space-around"
                    style={{ paddingTop: '20px', marginBottom: '20px' }}
                >
                    <Grid item md={4} xs={12} className="mb-10-xs">
                        <FormControl className="w-100-p min-w-128" >
                            <InputLabel htmlFor="years"># of Years</InputLabel>
                            <Select
                                value={num_years}
                                onChange={(e) => this.onChange(e, "num_years")}
                                inputProps={{
                                    //   name: '# of Years',
                                    id: 'years',
                                }}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={4} xs={12}>
                        <FormControl className="w-100-p min-w-128" >
                            <InputLabel htmlFor="semesters">
                                # of Semesters
                            </InputLabel>
                            <Select
                                value={num_partitions}
                                onChange={(e) => this.onChange(e, "num_partitions")}
                                inputProps={{
                                    //   name: '# of Years',
                                    id: 'semesters',
                                }}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    groupList: state.currentUser.newUserInfo.groupList
})

export default connect(mapStateToProps, null)(TimelineModal)