import React from 'react';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import ProgressBarWithNumber from "../../common/ProgressBarWithNumber";
import ParentImg from "../../../assets/images/21.png";
import GenericImg from "../../../assets/images/20.png";
import VolunteerImg from "../../../assets/images/22.png";
import RsvpImg from "../../../assets/images/23.png";
import {isGenericEvent, isPTAEvent, isRSVPEvent, isVolunteerEvent} from "../../../shared/enums";
import Grid from "@material-ui/core/Grid";


function Invitation(props) {
    const { data, loginUser, userRole, events } = props
    const getInvitation = (data) => {
        const { user, creator = "", event_id = "", start_date = "", attributes: { event_title = "", event_type = "", invitees = {} } = {}, percentage = 0, userResponse = [], user_response = [] } = data || {};
        const isEventOwner = loginUser.id === creator
        const avtarstyle = { width: "25px", height: "25px" }
        const response = isVolunteerEvent(event_type) ? userResponse : user_response
        const invite = invitees && invitees[loginUser.id] || {}
        return(
            <div className="invitation" onClick={() => props.onClickOnInviation(event_id)} key={event_id}>
                <Typography color="primary" variant="h6">
                    <span className="invitationTitle">{event_title}</span>
                    { isRSVPEvent(event_type) && (
                        <span className="titleIntend">
                            <Moment utc format="ddd D, MMMM YYYY">
                                {start_date}
                            </Moment>
                        </span>
                    )}

                    {response && response.length > 0 && !isRSVPEvent(event_type) && (
                        <span className="titleIntend">
                            <Moment utc format="ddd D, MMMM YYYY">
                                {`${response[0].event_date}`}
                            </Moment>
                        </span>
                    )}

                    <span style={{float: "right"}}>
                        { isVolunteerEvent(event_type) ?
                            <img alt="group" src={VolunteerImg} style={avtarstyle} title="Volunteer Event"/> :
                            isPTAEvent(event_type) ?
                                <img alt="group" src={ParentImg} style={avtarstyle} title="Parent-Teacher Event"/> :
                                isRSVPEvent(event_type) ?
                                    <img alt="group" src={RsvpImg} style={avtarstyle} title="Rsvp Event"/> :
                                    isGenericEvent(event_type) ?
                                        <img alt="group" src={GenericImg} style={avtarstyle} title="Generic Event"/> :
                                        null

                        }
                    </span>

                </Typography>

                {/* <Typography color="primary" variant="h6" className="invitationSubtitle">
                    Our yearly fair to bring out community togethers....
                </Typography> */}

                {(response && isRSVPEvent(event_type)) && (
                    <Typography color="primary" variant="h6">
                        <span className="response">Your response:</span>
                        <span className="responseAns">
                            {`${invite && invite.will_attend || ""}( ${invite && invite.adults} adults ${invite && invite.children} kids)`}
                        </span>
                    </Typography>
                )}

                {/*{(response && response.length > 0 && !isRSVPEvent(event_type)) && (
                    <span>
                        {(userRole === "admin" || userRole === "teacher") && (data && (isPTAEvent(event_type) || isVolunteerEvent(event_type))) ?
                            <span
                                className="responseAns">{`${response && response.length} out of ${total_slots} filled`}</span> :
                            <Typography color="primary" variant="h6">
                                <span className="response">Your response:</span>
                                <span className="responseAns">
                                    {`${response[0].firstname} ${response[0].lastname} conference spot is on `}
                                            <Moment utc format="ddd D, MMMM YYYY">
                                            {`${response[0].event_date}`}
                                        </Moment> at &nbsp;
                                            <Moment format="hh:mm a" date={response[0].start_time}/> - <Moment format="hh:mm a"
                                                                                                               date={response[0].end_time}/>
                                </span>
                            </Typography>
                        }
                    </span>
                )}*/}

                {/*{(response && response.length === 0 || !response) && (
                    <span>
                        {(userRole === "admin" || userRole === "teacher") && (data && (isPTAEvent(event_type) || isVolunteerEvent(event_type))) ?
                            <span
                                className="responseAns">{`${response && response.length} out of ${total_slots} filled`}</span> :
                            <Typography color="primary" variant="h6">
                                <span className="response">Your response:</span>
                                <span className="responsePending"> Pending</span>
                            </Typography>
                        }
                    </span>
                )}*/}

                {isEventOwner && (
                    <ProgressBarWithNumber percentage={percentage}/>
                )}
            </div>
        )
    }

    return (
        <div>
            <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item md={6} className="text-center"><h6 className="mb-0">My Signups</h6></Grid>
                <Grid item md={6} className="text-center"><h6 className="mb-0">Pending Invitations</h6></Grid>
                <Grid item md={6}>
                    {
                        (events || []).map((data, index) => {
                            const { attributes: { invitees = {} } = {} } = data || {};
                            const invite = invitees && invitees[loginUser.id] || {}
                            return(
                                invite.will_attend ? getInvitation(data) : null
                            )
                        })
                    }
                </Grid>
                <Grid item md={6}>
                    {
                        (events || []).map((data, index) => {
                            const { attributes: { invitees = {} } = {} } = data || {};
                            const invite = invitees && invitees[loginUser.id] || {}
                            return(
                                invite.will_attend === null || !invite.will_attend ? getInvitation(data) : null
                            )
                        })
                    }
                </Grid>
            </Grid>

        </div>
    );
}

export default Invitation;