import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Check User Status
// Check Onboarding then role (expert or doctor)
// if there is no onboarding status then have to redirect to resprctive dashboard

const LoginRoute = ({ component: Component, ...rest }) => {
    const userToken = localStorage.getItem('hmUser');

    if (rest.location.state?.forceLogOut){
        if (userToken){
            localStorage.removeItem('hmUser')
            console.log('deleted User')
        }
    }
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    (userToken && !rest.location.state?.forceLogOut?
                        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                        :
                        <Component {...props} />
                    )
                )
            }
            }
        />
    );
};

export default LoginRoute