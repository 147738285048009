import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    withStyles, Grid, Card, Avatar,
    Typography, Button, Input, Select, MenuItem
} from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import { message, Button as AntdButton } from 'antd';
import CustomLoadingOverlay from "../component/common/customLoadingOverlay.jsx";
import {Modal } from 'antd';

import reportService from '../service/report'
import { fetchChildReportListSuccess , fetchScalesSuccess } from '../redux/actions/reportadmin';
import ProgressReportStatus from '../component/ProgressReportStatus'
import DialogContent from '@material-ui/core/DialogContent';
import Loader from "../assets/jss/components/Loader";
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Iframe from 'react-iframe'
import {getCurrentSchoolId} from "../utils/utils";
import * as Routes from '../routes/path';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    row: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(1) * 2,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1) * 2,
    },
    select: {
        margin: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },

});

class ProgressReportAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openPDF: false,
            childData : null,
            isLoading : false,
            publishingModal : false,
            publishTotal : 0,
            publishSuccess : 0,
            publishFailure : 0,
            publishingCurrent : 0,
            publishingDone : false,
            failedPublish : [],
            columnDefs: this.createColumnDefs(),
            frameworkComponents: {
                customLoadingOverlay: CustomLoadingOverlay,
            },
            loadingOverlayComponent: "customLoadingOverlay",
            loadingOverlayComponentParams: { loadingMessage: "Loading Students.... Please wait.." },
            onGridReady: this.onGridReady,
            rowData: [],
            scales : null,
            currentStudent: {},
            currentParent: {},
            openParent: false,
            style: {
                width: '1000',
                height: '100%'
            },
            pdfData : null,
            selectedGroup : "",
            selectedRow: [],
            isPublishing: false
        }
    }

    componentDidMount() {
        const { groupList } = this.props;

        const groupId = (groupList || []).length && groupList[0].id || ""

        if(groupId !== "") {
            this.fetchStudentforReport(groupId);
            this.setState({ selectedGroup : groupId });
        }
       
    }

    createColumnDefs() {
        return [
            {
                headerName: "First Name",
                field: "firstname",
                width: 75,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
            },
            { headerName: "Last Name",
                field: "lastname", width: 75 ,

                autoHeight: true},
            {
              headerName: "Modules",
              field: "modules",
              width: 500,
              cellRendererFramework: this.moduleRender,
              autoHeight: true
          },
            {
                headerName: "Action",
                field: "childid",
                width: 100,
                cellRendererFramework: this.actionColumnRenderer,
                autoHeight: true
            },
        ]
    }

    lastNameRender = event => {

        return (
            <div style={{ display : 'flex',height : 190,
                        justifyContent : 'center', alignItems : 'center'}}>
                {event.value}
            </div>
    )
    }

    moduleRender = event => {
        console.log('event', event)
      if(event.data.modules === undefined) {
          return null
      }
      let modules = Object.keys(event.data.modules)
      console.log(modules)
      return (
        <Fragment>
          {modules.map((modulekey, index) => (
            <Grid key={index} container >
              <Grid item xs={12} sm={2}>
               {event.data.modules[modulekey].module_name}
              </Grid>
              <Grid item xs={12} sm={9}>
                <ProgressReportStatus module={event.data.modules[modulekey]}/>
              </Grid>
            </Grid>
          ))}

        </Fragment>
      )
    }

    actionColumnRenderer = event => {
        if(event.data.modules === undefined) {
            return null
        }
      let modules = Object.keys(event.data.modules)
      if (modules.length === 0) return null
        return (
            <Grid  style={{paddingTop : 10}}>
                <Grid spacing={1}>
                <AntdButton type="link" onClick={(e) => this.handleClickOpen(event)}>View Report</AntdButton>
                </Grid>
                <Grid  spacing={1}>
                    <AntdButton type="link" onClick={(e) => this.handleClickSend(event)}>Publish Report & Email</AntdButton>
                </Grid>
            </Grid>
        )
    }

    fetchStudentforReport = async (groupid) => {
      const { dispatch, currentUser } = this.props;
      const body = {
          "group_id" : groupid,
          school_id: getCurrentSchoolId(currentUser)
      }
      const data  = await reportService.getReportforGroups(body);
      const _scales = await reportService.getScales()
      var rowdata = []
      Object.keys(data).forEach(key => {
        var row = {}
        console.log(key)
        row['childid'] = key
        row['firstname'] = data[key].firstname
        row['lastname'] = data[key].lastname
        row['modules'] = data[key].modules
        rowdata.push(row)

      });
      
      if (rowdata.length === 0){
          rowdata = null
      }
      console.log(rowdata)
      var scales = {}
      _scales.data.map((scl)=>{
          scales[scl.id] = scl
      })
        //
      this.setState({ rowData: rowdata , scales : scales, selectedRow: []});
      dispatch(fetchChildReportListSuccess(data))
      dispatch(fetchScalesSuccess(scales))
      setTimeout(() => {
          this.gridApi.resetRowHeights();
          this.gridApi.deselectAll()

          setTimeout(() => {
              this.setState({
                  selectedRow: []
              })
          }, 500)
      }, 100);
      this.stopLoading();
    }

    search = ev => {
        const value = ev.target.value ? ev.target.value.toString().trim() : '';
        this.gridApi.setQuickFilter(value);
    }

    handleSelectChange = (event) => {
        const { value } = event.target;
        // const { child: { childList } } = this.props;
        this.setState({ selectedGroup : value });

        this.fetchStudentforReport(value)
        setTimeout(() => {
            this.gridApi.resetRowHeights();
        }, 100);
    }

    viewReportPDF = async (childid) => {

        message.info('Running Report ...')
        this.setState({isLoading : true})
        const { currentUser } = this.props;
        var body = {
            child_id : childid,
            school_id: getCurrentSchoolId(currentUser)
        }
        const data  = await reportService.getPDFReport(body);
        console.log(data)
        if (data.status === 'success'){
            this.setState({pdfData : data.pdf, openPDF : true, isLoading : false})
        }else{
            message.error('Error Opening Report, Message ' + data.message, 7)
            this.setState({isLoading :false})
        }
      }

      sendReportPDF= async (childid) => {
        //this.startLoading();
        message.info('Generating and Sending Report ...')
        this.setState({isLoading : true})
        const { dispatch, currentUser } = this.props;
        // console.log(userInfo)
        var body = {
            "child_id" : childid,
            "email" : true,
            school_id: getCurrentSchoolId(currentUser)
        }
        const data  = await reportService.getPDFReport(body);
        console.log(data)
        if (data.status === 'success'){
            //window.atob(data.pdf)
            message.info('Report published and emailed to Parents !')
            this.setState({isLoading :false})
            this.fetchStudentforReport(this.state.selectedGroup);
        }else{
            
            this.setState({isLoading :false})
            message.error('Error Sending Report, Message ' + data.message, 7)
        }
      }

    handleClickOpen = (event) => {
        const { data } = event;
        const { childid } = data
        this.viewReportPDF(childid)
    };

    handleClickSend= async (event) => {
        const { data } = event;
        const { childid } = data

        message.info('Publishing report ...')
        let resp = await reportService.markasPublished({"child_id" : parseInt(childid,10), school_id: getCurrentSchoolId(this.props.currentUser)})
        if (resp.status === "success"){
            this.sendReportPDF(childid)
        } else {
            message.error('Error Publish Report. Message : ' + resp.message, 10)
        }
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();

        this.startLoading();
    }

    onColumnResized(event) {
        if (event.finished) {
            this.gridApi.resetRowHeights();
        }
    }

    startLoading = () => {
        if (this.gridApi)
            this.gridApi.showLoadingOverlay();
    }

    stopLoading = () => {
        this.gridApi.hideOverlay();
    }

    renderLoading(){
        if (this.state.isLoading){
            return (<Loader/>)
        }else{
            return null
        }
    }

    renderPrintDlg (){
      if (this.state.openPDF)
      {
      return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.openPDF}
        fullWidth
        fullScreen
      >
        <DialogContent>
        <Iframe
        width="100%"
        height="100%"
            src={"data:application/pdf;base64," + this.state.pdfData}>
        </Iframe>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({openPDF : false})}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.setState({openPDF: false })}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )}else{
      return null
    }
      }

    onRowSelect = (row) => {
        const { selectedRow } = this.state

        const childId = row.data.childid
        const index = (selectedRow || []).findIndex(x => x === childId)

        if(index === -1) {
            selectedRow.push(childId)
        } else {
            selectedRow.splice(index, 1)
        }

        this.setState({
            selectedRow
        })
    }

    onPublishReport = async () => {
        const { selectedRow } = this.state
        console.log(selectedRow)
        this.setState({
            isPublishing: true,
            publishingModal : true,
            publishingCurrent : 0,
            publishingDone : false,
            publishTotal : selectedRow.length,
            failedPublish : []
        })

        message.info('Publishing report ...')

        let success = []
        let failed = []
        let failedMessage = ""

        for (const i in selectedRow) {
            this.setState({
                publishingCurrent : this.state.publishingCurrent + 1
            })
            const { currentUser } = this.props;

            let resp = await reportService.markasPublished({"child_id" :parseInt(selectedRow[i],10), school_id: getCurrentSchoolId(this.props.currentUser)})
            if (resp.status === "success"){
                //***** SUCCESS MARK ******
                let body = {
                    child_id :  parseInt(selectedRow[i],10),
                    email : true,
                    school_id: getCurrentSchoolId(currentUser)
                }
                let resp  = await reportService.getPDFReport(body);
                if (resp.status === "success") {
                    this.setState({
                        publishSuccess: this.state.publishSuccess + 1
                    })

                    success.push(selectedRow[i])
                }else{
                    failed.push(selectedRow[i])
                    this.setState({
                        publishFailure : this.state.publishFailure + 1,
                        failedPublish : failed
                    })
                }
            } else {
                failed.push(selectedRow[i])
                this.setState({
                    publishFailure : this.state.publishFailure + 1,
                    failedPublish : failed
                })
                failedMessage = 'Error Publish Report. Message : ' + resp.message
            }
            //***** Next Child ********

        }
        console.log('failed', failed)
        this.setState({
            isPublishing: false,
            publishingDone : true,

        })

        console.log('NU', this.props.NU)
        this.fetchStudentforReport(this.state.selectedGroup);

    };

    onPublishReport2 = async () => {
        const { selectedRow } = this.state
        console.log(selectedRow)
        this.setState({
            isPublishing: true,
            publishingModal : true,
            publishingCurrent : 1,
            publishTotal : selectedRow.length
        })

        message.info('Publishing report ...')

        let success = []
        let failed = []
        let failedMessage = ""

        for (const i in selectedRow) {
            let resp = await reportService.markasPublished({"child_id" : parseInt(selectedRow[i],10), school_id: getCurrentSchoolId(this.props.currentUser)})
            if (resp.status === "success"){
                this.setState({
                    publishSuccess : this.state.publishSuccess + 1
                })
                success.push(selectedRow[i])
            } else {
                failed.push(selectedRow[i])
                this.setState({
                    publishFailure : this.state.publishFailure + 1
                })
                failedMessage = 'Error Publish Report. Message : ' + resp.message
            }
        }

        if((success || []).length) {
            message.info('Generating and Sending Report ...')
            this.setState({isLoading : true})
            const { currentUser } = this.props;

            let reportSuccess = 0
            let reportFailed = 0
            let errorMessage = ""

            for (const i in success) {
                const body = {
                    child_id : success[i],
                    email : true,
                    school_id: getCurrentSchoolId(currentUser)
                }

                const data  = await reportService.getPDFReport(body);

                if (data.status === 'success'){
                    reportSuccess += 1
                } else{
                    reportFailed += 1
                    errorMessage = 'Error Sending Report, Message ' + data.message
                }
            }

            if(reportSuccess) {
                message.info(`${reportSuccess} Reports published and emailed to Parents !`)
                this.setState({
                    isLoading: false,
                    isPublishing: false,
                    selectedRow: []
                })
                this.fetchStudentforReport(this.state.selectedGroup);
            } else {
                this.setState({
                    isLoading: false,
                    isPublishing: false
                })
                message.error(errorMessage, 7)
            }

        } else {
            this.setState({
                isPublishing: false
            })
            message.error(failedMessage, 10)
        }

    };


    render() {
        const { classes, groupList, history } = this.props;
        const { style, selectedGroup, selectedRow, isPublishing } = this.state;
        console.log({state: this.state.selectedRow})
        return (
            <div className={classes.root}>
                <div className="green-button text-center mb-3">
                    <AntdButton shape="round" size="large" className="mr-3" onClick={() => history.push(Routes.ProgressReport)}>
                        <span style={{color: '#3cc89c'}}>Create/Mark Progress Report</span>
                    </AntdButton>
                    <AntdButton shape="round" size="large" style={{background: 'rgb(57 101 178)'}}>
                        <span style={{color: 'white'}}>Publish/Email Progress Report</span>
                    </AntdButton>
                </div>
                <Modal
                    title="Publishing Report Please wait ..."
                    centered
                    Info
                    closable={false}
                    okButtonProps={{ disabled:  !this.state.publishingDone, children: "Close"  }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    visible={this.state.publishingModal}
                    onOk={() => {
                        this.setState({
                            publishingModal : false,
                            publishingCurrent : 0,
                            publishSuccess : 0,
                            publishFailure : 0,
                        })
                    }}
                >
                    <p>Publishing {this.state.publishingCurrent} of {this.state.publishTotal}</p>
                    <p>Succeeded : {this.state.publishSuccess}</p>
                    <p>Failed : {this.state.publishFailure}</p>
                    {this.showFailedStudents()}
                </Modal>
                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card className={classNames(classes.paper)}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography className={classes.typography} variant="h6" >Progress Report Admin</Typography>
                                </Grid>

                                <Grid item xs={9} alignContent="stretch" justify="flex-end" container className="items-center">
                                    <AntdButton type="primary" disabled={!(selectedRow || []).length} loading={isPublishing} onClick={this.onPublishReport}>Publish Report & Email</AntdButton>
                                    <Typography className={classes.typography} align='center' variant="subtitle1" >Group : </Typography>
                                    <Select
                                        className={classes.select}
                                        align='center'
                                        displayEmpty
                                        value={selectedGroup || ""}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="selectedGroup" id="category-helper" />}
                                    >
                                        {
                                            (groupList || []).map((group, index) => (
                                            <MenuItem value={group.id} key={index.toString()}>{group.attributes.groupname}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={40}>
                    <Grid item xs={12}>
                        <Card >
                                <div id="myGrid"  className="ag-theme-balham"  style={{height: 600, width: '100%'}}>

                                <AgGridReact
                                    rowHeight={100}
                                    frameworkComponents={this.state.frameworkComponents}
                                    loadingOverlayComponent={this.state.loadingOverlayComponent}
                                    loadingOverlayComponentParams={this.state.loadingOverlayComponentParams}
                                    animateRows={true}
                                    enableFilter={true}
                                    enableColResize={true}
                                    gridAutoHeight={true}
                                    onColumnResized={this.onColumnResized.bind(this)}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    onGridReady={this.state.onGridReady}
                                    rowSelection={'multiple'}
                                    onRowSelected={this.onRowSelect}
                                />
                            </div>
                        </Card>
                    </Grid>
                </Grid> 
                {this.renderPrintDlg()}
                {this.renderLoading()}
            </div >
        );
    }

    showFailedStudents() {
        if (this.state.failedPublish.length > 0){
            console.log('childrencache', this.props.childrencache)
            let names = []
            for (var i = 0;  i < this.state.failedPublish.length; i++){
                let childid = this.state.failedPublish[i]
                let child = this.props.childrencache[childid]
                console.log('child ', child)
                names.push(child.firstName + ' ' + child.lastName)
            }

            return (
                <div style={{marginTop : 10, color : "red"}}>
                    Failed to publish reports to {names.join(',')}
                </div>
            )
        }else{
            return null
        }
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo.user,
    groupList: state.currentUser.newUserInfo.groupList,
    childrencache : state.currentUser.newUserInfo.childrencache
})

export default connect(mapStateToProps)(withStyles(styles)(ProgressReportAdmin));


