import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import makeAnimated from 'react-select/animated';
// import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
// import '@szhsin/react-menu/dist/index.css';
// import dots from './dots.png';
import "../../../css/plan.css";
import "./index.css";
import CancelIcon from '@material-ui/icons/Cancel';
import {Popover, Tooltip, Input, Modal, Spin, Tag} from 'antd';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button, Chip, TextField } from '@material-ui/core';
import {BulbTwoTone, CommentOutlined, HistoryOutlined} from '@ant-design/icons';
import SaveIcon from '@material-ui/icons/Save';
import Scale from '../Scale'
import TextArea from 'antd/es/input/TextArea';
import ActionBar from './actionBar'
import {StudentAvatar} from './studentAvatar'
import _ from "lodash";
const Container = styled.div`
  width: 100%;
  background-color: #E0F1F5;
  border: 1px solid #2D798B;
  padding-left: 2px;
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
`;


export default class Studentchip extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            studentColor : null
        }

        this.scaleHash = _.keyBy(Scale, 'rating')


    }

    getRating(studentid) {
        let rating = this.props.hash[this.props.skillId] === undefined ? 'F' :
            this.props.hash[this.props.skillId][studentid] === undefined ? 'F' :
                this.props.hash[this.props.skillId][studentid]
        let color = this.scaleHash[rating].color
        //console.log('scale', rating, color, this.props.studentid)
        return color == 'white' ? null : color

    }


    render(){
        //console.log('studentColor', this.studentColor)
        if (this.props?.noOverLay){
            return (
                <Tag color={this.getRating(this.props.studentid)} closable={this.props.closeable} title={this.props.label} onClose={this.props.closeStudent}>
                    {this.props.label}
                  </Tag>
            )
        }


        return (
            <ActionBar skillid={this.props.taskObj.skillId} group_id={this.props.taskObj.group_id}
                         refreshData={()=> this.props.refreshData()}
                         studentid={this.props.studentid}
                         dashboard_id={this.props.taskObj.dashboard_id}>
                <Tooltip title={this.props.label}>
                    <StudentAvatar studentObj={this.props.studentObj}
                                   studentName={this.props.label}/>
                </Tooltip>
            </ActionBar>
        )
    }

    render2(){
        return (
        <Container>
            <div style={{
                padding : 3
            }}>{this.props.label}</div>
            <div style={{justifyContent : 'flex-end', cursor : 'pointer', backgroundColor : this.studentColor}}>
                <ActionBar skillid={this.props.taskObj.skillId}
                           group_id={this.props.taskObj.group_id}
                           refreshData={()=> this.props.refreshData()}
                             studentid={this.props.studentid}
                             dashboard_id={this.props.taskObj.dashboard_id}>
                        <CancelIcon/>
                </ActionBar>
            </div>
        </Container>
        )
    }

}

