import React, { Component } from 'react';
import MainDashboard from "./components/AdminDashboard";
import "./components/school.css"

class MainSchoolProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return(
            <div className="main">
                {/*<h1>Main School Profile Dashboard</h1>*/}

                <MainDashboard {...this.props} />

            </div>
        )
    }
}

export default MainSchoolProfile
