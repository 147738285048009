import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Icon, Avatar, Button, Dropdown, Menu, Tabs, Spin, Tooltip, Radio} from 'antd';
import { withStyles } from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import {
    capitalizeFirstLetter,
    getSchoolId,
    openNotificationWithIcon,
    SelectLabelInput,
    TextLabelInput,
    SelectParentInput,
    SelectUserRoleInput,
    getUserRole
} from "../../../../GlobalComponents/GlobalFields";
import Student from "./component/Student";
import Staff from "./component/Staff";
import Parent from "./component/Parent";
// import Attendance from "./component/Attendance";
import Attendance from "../../../../Groups/components/GroupProfile/component/Attendance";
import Schedule from "./component/Schedule";
import AddCurrentStudentStaffModal from "./AddCurrentStudentStaffModal";
import {createChild, createUser} from "../../../../../ApiServices";
import authService from "../../../../../../../service/auth";
import {newUserInfoSuccess} from "../../../../../../../redux/actions/current-user";
import {teacherLevelList} from "../../../../GlobalComponents/optionValues";
import * as routes from "../../../../../../../routes/path";
import "../../Group.css"
import "../../../../school.css"

const { TabPane } = Tabs

const inactive = {
    color: 'hsla(0,0%,100%,.5)',
    textAlign: 'center'
}

const active = {
    color: 'white',
    borderBottom: '2px solid black',
    textAlign: 'center'
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class GroupProfile extends React.Component {
    state = {
        selectedStudent: [],
        selectedStaff: [],
        selectedParent: [],
        studentStatus: ["Active"],
        groupDetails: {},
        createField: {},
        isCreate: false,
        isOpen: false,
        isLoading: false,
        dismissMenu: false,
        groupId: null,
        isActive: "current",
        isActiveTab: "1",
        parentType: 'existing',
        selectedExistingParentId: ""
    };

    async componentWillMount() {
        this.fetchGroupData()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { location: { pathname }  } = this.props
        const { location: { pathname: currentPath }  } = nextProps || {}
        if(pathname !== currentPath) {
            this.fetchGroupData()
        }
    }

    fetchGroupData = async () => {
        this.setState({
            isLoading: true
        })
        const newUserResponse = await authService.getNewUserDetail();
        if(newUserResponse && newUserResponse.data) {
            this.props.onSetUserInfo(newUserResponse.data)
        }
        const { location: { state: { details: groupDetails = {} } }, match, currentUser: { newUserInfo: { childList, usersList } } } = this.props || {};
        const { id } = match && match.params || {}
        let selectedStudent = []
        let selectedStaff = []
        let selectedParent = []
        if(id) {
            childList.forEach(student => {
                if(Array.isArray(student.group_ids) && (student.group_ids || []).includes(Number(id))) {
                    selectedStudent.push(student)
                }
            })
            usersList.forEach(user => {
                if((user.group_ids || []).includes(Number(id))) {
                    const isAdmin = ((((user || {}).role) || {})[getSchoolId()] || {}).admin
                    const isTeacher = ((((user || {}).role) || {})[getSchoolId()] || {}).teacher
                    const isParent = ((((user || {}).role) || {})[getSchoolId()] || {}).parent
                    if(isAdmin || isTeacher) {
                        selectedStaff.push(user)
                    }
                    if(isParent) {
                        selectedParent.push(user)
                    }
                }
            })
        }
        setTimeout(() => {
            this.setState({
                isLoading: false,
                groupDetails,
                isActiveTab: (this.state.isActiveTab === "4" && ((groupDetails || {}).attributes || {}).is_attendance === false) ? "1" : this.state.isActiveTab,
                selectedStudent,
                selectedStaff,
                selectedParent,
                groupId: Number(id)
            })
        }, 500)
    }

    onTabChange = (tab) => {
        this.setState({
            isActiveTab: tab,
            createField: {},
            isActive: "current",
        })
    }

    addStudentStaffMenu = () => {
        const { isActive, createField, isCreate, isActiveTab, selectedExistingParentId, parentType } = this.state
        const { firstName = "", lastName = "", parentName = "", parentEmail = "", email = "", role = [], teacherLevel = "" } = createField || {}
        let isDisabled = false
        let newButtonLabel = ""
        let currentButtonLabel = ""
        let onCreateMethod = null
        if(isActiveTab === "1") {
            isDisabled = !firstName || !lastName || isCreate
            newButtonLabel = "Create New Student"
            currentButtonLabel = "Add Current Student"
            onCreateMethod = this.onCreateNewStudent
            if(!isDisabled) {
                if(parentType === 'existing') {
                    isDisabled = !selectedExistingParentId
                } else {
                    isDisabled = !parentName || !parentEmail
                }
            }
        }
        if(isActiveTab === "2") {
            isDisabled = !firstName || !lastName || !email || !(role || []).length || isCreate
            newButtonLabel = "Create New Staff"
            currentButtonLabel = "Add Current Staff"
            onCreateMethod = this.onCreateNewStaff
            if(!isDisabled && !(role || []).includes("admin")) {
                isDisabled = !teacherLevel
            }
        }
        return(
            <Menu className="add-staff-student-menu">
                <div>
                    <div className="header-menu">

                        <Grid container spacing={6} className="grid-menu">
                            <Grid
                                item
                                xs={6}
                                style={isActive === 'current' ? active : inactive}
                                onClick={() => this.setState({isActive: 'current', createField: {}, selectedExistingParentId: '', parentType: 'existing'})}
                            >
                                Current
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={isActive === 'new' ? active : inactive}
                                onClick={() => this.setState({isActive: 'new', createField: {}, selectedExistingParentId: '', parentType: 'existing'})}
                            >
                                New
                            </Grid>
                        </Grid>

                    </div>

                    {
                        isActive === 'new' ?
                            <Grid container spacing={6} className="grid-textfield">
                                { this.getInputFields(isActiveTab === "2") }
                            </Grid> : null
                    }

                    {
                        isActive === 'new' ?
                            <div className="child-button">
                                <Button
                                    disabled={isDisabled}
                                    onClick={onCreateMethod}
                                    type="primary"
                                >
                                    { isCreate ? <Spin className="mr-2"/> : null } { newButtonLabel }
                                </Button>
                            </div> :
                            <div className="student-button">
                                <Button
                                    onClick={this.onModalChange}
                                    type="primary"
                                >
                                    { currentButtonLabel }
                                </Button>
                            </div>
                    }
                </div>
            </Menu>
        )
    }

    getInputFields = (isStaff) => {
        const { currentUser: { newUserInfo: { usersList = [] } } } = this.props;
        const { createField, selectedExistingParentId, parentType } = this.state
        const { firstName = "", lastName = "", parentName = "", parentEmail = "", email = "", role = [], teacherLevel = "" } = createField || {}
        return(
            <>
                <Grid item xs={12}>
                    <TextLabelInput
                        name="firstName"
                        value={firstName}
                        onChange={this.onCreateFieldChange}
                        placeholder="First Name"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextLabelInput
                        name="lastName"
                        value={lastName}
                        onChange={this.onCreateFieldChange}
                        placeholder="Last Name"
                    />
                </Grid>
                {
                    !isStaff ?
                        <>
                            <Grid item xs={12}>
                                <Radio.Group onChange={(e) => this.setState({parentType: e.target.value, selectedExistingParentId: '', createField: {...createField, parentName: "", parentEmail: ""}})} value={parentType}>
                                    <Radio value={'existing'}>Select Existing Parent</Radio>
                                    <Radio value={'create'}>Create New Parent</Radio>
                                </Radio.Group>
                            </Grid>
                            {
                                parentType === 'existing' ?
                                    <Grid item xs={12}>
                                        <SelectParentInput
                                            // label="Select Parent"
                                            onChange={(value) => this.setState({selectedExistingParentId: value})}
                                            placeholder="Select a Parent"
                                            options={usersList || []}
                                            value={selectedExistingParentId || ''}
                                            style={{width: 200}}
                                        />
                                    </Grid> :
                                    <>
                                        <Grid item xs={12}>
                                            <TextLabelInput
                                                name="parentName"
                                                value={parentName}
                                                onChange={this.onCreateFieldChange}
                                                placeholder="Parents Name"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextLabelInput
                                                name="parentEmail"
                                                value={parentEmail}
                                                onChange={this.onCreateFieldChange}
                                                placeholder="Parents Email"
                                            />
                                        </Grid>
                                    </>
                            }
                        </> :
                        <>
                            <Grid item xs={12}>
                                <TextLabelInput
                                    name="email"
                                    value={email}
                                    onChange={this.onCreateFieldChange}
                                    placeholder="Email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectUserRoleInput
                                    name="role"
                                    onChange={(value) => this.onCreateFieldChange({target: { name: "role", value }})}
                                    placeholder="Select a role"
                                    value={role}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectLabelInput
                                    name="teacherLevel"
                                    onChange={(value) => this.onCreateFieldChange({target: {name: "teacherLevel", value: (value || "").toLowerCase()}})}
                                    placeholder="Select a Teacher Level"
                                    options={teacherLevelList || []}
                                    value={capitalizeFirstLetter(teacherLevel) }
                                />
                            </Grid>
                        </>
                }
            </>
        )
    }

    onCreateFieldChange = (event) => {
        const { name, value } = event.target
        this.setState({
            createField: {
                ...this.state.createField,
                [name]: value
            }
        })
    }

    onCreateNewStudent = async () => {
        const { createField, parentType, selectedExistingParentId } = this.state
        const { firstName = "", lastName = "", parentName = "", parentEmail = "", role = [] } = createField || {}
        if(!parentEmail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && parentType === 'create') {
            openNotificationWithIcon('error', `Please enter valid parent email ${parentEmail}`)
            return
        }
        this.setState({isCreate: true})
        const studentFiled = {
            firstName, lastName, dateOfBirth: null, gender: '', status: 'Active', profile_photo: '',
            primaryLanguage: "", ethnicity: "", homeLanguage: "", othersRoom: [], homeRoom: this.state.groupId
        }
        const payload = {
            attributes: {
                scheduleList: [],
                primaryAddress: {},
                secondaryAddressList: [],
                medicalField: {},
                ...studentFiled
            },
            auth_users: [],
            group_ids: [studentFiled.homeRoom],
            parents: [],
            school_id: getSchoolId()
        }

        if(parentType === 'existing' && selectedExistingParentId) {
            payload.parents = [selectedExistingParentId]
        } else {
            const name = parentName.split(" ")
            const parentPayload = {
                role: {[getSchoolId()]: {admin: false, parent: true, teacher: false}},
                // role: {[getSchoolId()]: getUserRole(role)},
                child_ids: [],
                username: parentEmail,
                attributes: {
                    firstName: (name && name[0] || ""), lastName: (name && name[1] || ""), email: parentEmail, mobile: '', isPickup: '', relation: '', profile_photo: '', isNewsletterReviewer: false
                }
            }

            const parentResponse = await createUser(parentPayload)
            if(parentResponse && parentResponse.status === "success") {
                payload.parents = [parentResponse.user_id]
            } else {
                const message = parentResponse.message === "User already exists in system." ?
                    "Email already exists in System for another user. Please use different email" : parentResponse.message
                return openNotificationWithIcon('error', message || 'Something went wrong')
            }
        }

        if((payload.parents || []).length) {
            this.onStudentStaffServiceCall(payload)
        } else {
            openNotificationWithIcon('error', 'Something went wrong')
        }
    }

    onCreateNewStaff = async () => {
        const { createField, groupId } = this.state || {}
        const { firstName = "", lastName = "", email = "", role = [], teacherLevel = "" } = createField || {}
        this.setState({isCreate: true})

        const payload = {
            role: {[getSchoolId()]: getUserRole(role)},
            // school_ids: [getSchoolId()],
            child_ids: [],
            group_ids: [groupId],
            username: email,
            attributes: {
                firstName,
                lastName,
                // role,
                email,
                teacherLevel,
                profile_photo: "",
                hours: 0,
                notes: "",
                groups: [],
                homeRoom: groupId,
                dateOfBirth: null,
                hireDate: null,
                certificationList: [],
                primaryAddress: {}
            }
        }

        this.onStudentStaffServiceCall(payload)
    }

    onStudentStaffServiceCall = async (payload) => {
        const response = this.state.isActiveTab === "1"  ? await createChild(payload) : await createUser(payload)
        if(response && response.status === "success") {
            this.fetchGroupData()
            openNotificationWithIcon('success', response.message)
            setTimeout(() => {
                this.setState({
                    isCreate: false,
                    dismissMenu: true,
                    createField: {},
                    selectedExistingParentId: '',
                    parentType: 'existing'
                })
            }, 500)
        } else {
            const message = this.state.isActiveTab !== "1" && response.message === "User already exists in system." ?
                "Email already exists in System for another user. Please use different email" : response.message
            openNotificationWithIcon('error', message || 'Something went wrong')
            this.setState({isCreate: false})
        }
    }

    onGroupChange = (group, id) => {
        this.props.history.push({
            pathname: `/classes/view/${id}`,
            state:{
                details: group
            }
        })
    }

    groupListMenu = () => {
        const { currentUser: { newUserInfo: { groupcache: groupList = {} } } } = this.props;
        return(
            <Menu className="group-list-popup-menu">
                {
                    groupList && Object.keys(groupList).map((key, index) => {
                        if((((groupList || {})[key] || {}).attributes || {}).is_public) return
                        const name = (groupList[key] && groupList[key].attributes && groupList[key].attributes.groupname) || ""
                        const id = groupList[key].id
                        return(
                            <Menu.Item
                                key={id.toString()}
                                onClick={() => this.onGroupChange(groupList[key], id)}
                                style={this.state.groupId === id ? {background: '#e6f7ff'} : {}}
                            >
                                {name}
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        )
    }

    onModalChange = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    onDisMissMenuChange = () => {
        this.setState({
            dismissMenu: false
        })
    }

    render() {
        const { classes } = this.props;
        const { groupDetails, isActiveTab, selectedStudent, selectedStaff, selectedParent, isLoading, isOpen, groupId, dismissMenu } = this.state;
        const { attributes } = groupDetails || {}
        const { groupname = "", profile_photo,  image, is_attendance = false } = attributes || {}

        let object = {}
        if(isOpen || dismissMenu) {
            object.visible = false
            if(dismissMenu) {
                this.onDisMissMenuChange()
            }
        }

        return (
            <div className="class-profile">
                { isOpen ?
                    <AddCurrentStudentStaffModal
                        onClose={this.onModalChange}
                        selectedStudent={(selectedStudent || []).map(student => student.id)}
                        selectedUsers={(selectedStaff || []).map(x => x.id)}
                        groupDetails={groupDetails}
                        onFetchInfo={this.fetchGroupData}
                        isStudent={isActiveTab === "1"}
                        {...this.props}
                    /> : null
                }
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <div className="back">
                                <Tooltip title={"Back To Class List"} placement="bottomLeft">
                                    <Icon
                                        type="left"
                                        className="color-white cursor-pointer"
                                        onClick={() => this.props.history.push(routes.CLASSES_DASHBOARD)}
                                    />
                                </Tooltip>
                            </div>
                            {
                                (profile_photo || image) ?
                                    <Avatar src={profile_photo || image} size={50}/> :
                                    <Avatar size={50} style={{backgroundColor: 'white'}}>
                                        <span className="color-black">{`${(groupname || "").substr(0, 2).toUpperCase()}`}</span>
                                    </Avatar>
                            }
                            <Dropdown overlay={this.groupListMenu} trigger={['click']} overlayClassName="footer-menu">
                                <span className="cursor-pointer d-flex" style={{alignItems: 'baseline'}}>
                                  <h3 className="mb-0 ml-20 color-white">{groupname}</h3><Icon type="caret-down" className="ml-1 color-white"/>
                                </span>
                            </Dropdown>
                        </div>
                        {
                            (isActiveTab === "1" || isActiveTab === "2") ?
                                <div>
                                    <Dropdown overlay={this.addStudentStaffMenu} {...object} trigger={['click']} placement="bottomLeft" overlayClassName="footer-menu">
                                        <Button size={"large"}>{ isActiveTab === "1" ? "Add Student" : "Add/Update Staff"}</Button>
                                    </Dropdown>
                                </div> : null
                        }
                    </div>
                </div>

                <div className={`student ${classes.root} content`}>
                    <Tabs activeKey={isActiveTab} onChange={this.onTabChange}>
                        <TabPane tab={`Students (${isLoading ? "counting" : (selectedStudent || []).length || 0})`} key="1">
                            <Student groupId={groupId} selectedStudent={selectedStudent || []} {...this.props} parentLoad={isLoading} />
                        </TabPane>
                        <TabPane tab={`Staff (${isLoading ? "counting" : (selectedStaff || []).length || 0})`} key="2">
                            <Staff groupId={groupId} selectedStaff={selectedStaff || []} {...this.props} parentLoad={isLoading} />
                        </TabPane>
                        <TabPane tab={`Parents (${isLoading ? "counting" : (selectedParent || []).length || 0})`} key="3">
                            <Parent groupId={groupId} selectedParent={selectedParent} selectedStudent={selectedStudent || []} fetchGroupData={this.fetchGroupData} {...this.props} parentLoad={isLoading} />
                        </TabPane>
                        {
                            is_attendance ?
                                <TabPane tab="Attendance" key="4">
                                    <Attendance groupId={groupId} selectedStudent={selectedStudent || []} selectedStaff={selectedStaff || []} parentLoad={isLoading} {...this.props} />
                                </TabPane> : null
                        }
                        {/*<TabPane tab="Schedule" key="5">
                            { isLoading ? <div className="text-center"><Spin/></div> : <Schedule groupId={groupId} selectedStudent={selectedStudent || []} /> }
                        </TabPane>*/}
                    </Tabs>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

GroupProfile.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(GroupProfile))