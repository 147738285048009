import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Card,
    Grid,
    CardHeader,
    CardContent,
    Tooltip,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    Typography,
    Container,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {red} from '@material-ui/core/colors';
import Launch from '@material-ui/icons/Visibility';
import Assessment from '@material-ui/icons/Assessment'

import {
    MoreHoriz, FileCopy, Delete,
} from '@material-ui/icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
    showGroups
} from "../../../component/SchoolProfile/components/GlobalComponents/GlobalFields";
import _ from 'lodash'
import {Col, FormGroup} from "reactstrap";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

import '../../../../node_modules/ag-grid-community/dist/styles/ag-grid.css'
import '../../../../node_modules/ag-grid-community/dist/styles/ag-theme-material.css';

import {isUserParent} from "../../../utils/utils";
import {DataGrid} from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    card: {
        // maxWidth: 450,
        borderRadius: 10,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    label: {
        textAlign: "right",
        color: "#9D9D9D"
    },
    title: {
        color: 'red',
    }
}));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function RecipeReviewCard(props) {

    const {data = [], currentUser = {}, MyLetter = {}, tabActive} = props || {}
    const {draft = [], review = [], publish = [], schedule = []} = MyLetter || {}

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event, item) => {
        console.log(item)
        setAnchorEl(event.currentTarget);
        props.setNewsItem(item)
    };

    const handleClose = (type) => {
        setAnchorEl(null);
        if (type === "copy") {
            props.doCopy(props.newsItem)
        }
        if (type === "delete") {
            props.doDeleteOpen(props.newsItem)
        }
    };

    const getGroupName = (id) => {
        const data = (MyLetter.groups || []).find(x => x.groupid === id)
        return (data || {}).groupname || ""
    }


    var filtered_data = _.filter(data, function (o) {
        return o.status_id == tabActive;
    });


    console.log(filtered_data)

    const getReportTitle = (params) => {
        return params.data.structure.letter_title
    }
    const getCreateDate = (params) => {
        return moment(params.data.structure.create_date).format('D MMM YY hh:mm a')
    }

    const getUpdatedDate = (params) => {
        let dt = params.data.structure.updated_date === null ? params.data.structure.create_date : params.data.structure.updated_date
        return moment(dt).format('D MMM YY hh:mm a')
    }

    const getCreatedBy = (params) => {
        return props.getUserInfo(params.data.created_by, 'display_name')
    }

    const reviewRenderer = (params) => {

        let htmldata = params.data.reviewers.map((reviewer) => {
            return props.getUserInfo(reviewer, "display_name")
        })
        let html = htmldata.join(',')
        return (<div>{html}</div>)
    }

    const getScheduledDate = (params) => {
        return moment.utc(params.data.publish_date).local().format('D MMM YY hh:mm a')
    }
    const groupsRenderer = (params) => {
        let html = showGroups(params.data.group_ids, props.groupCache)

        return (<div>{html}</div>)
    }

    const getcolumnDefs = () => {
        if (tabActive === 0) {
            var cols = [
                {headerName: "Last Update Date (EST)", valueGetter: getUpdatedDate,  sort : 'desc', comparator: (valueA, valueB, nodeA, nodeB, isInverted) => moment(valueA) - moment(valueB),sortable: true, filter: true},
                {headerName: "Report Title",   valueGetter: getReportTitle, width: 300, sortable: true, filter: true},
                {headerName: "Groups", cellRenderer: "groupsRenderer", width: 300, sortable: false, filter: false},
                {headerName: "Created By", valueGetter: getCreatedBy, sortable: false, filter: false},
                {headerName: 'Action', width: 170, cellRenderer: "draftActionRendered"},
            ]

            return cols
        }
        if (tabActive === 1) {
            var cols = [
                {headerName: "Created Date (EST)", valueGetter: getCreateDate,  sort : 'desc', comparator: (valueA, valueB, nodeA, nodeB, isInverted) => moment(valueA) - moment(valueB),sortable: true, filter: true},
                {headerName: "Report Title", valueGetter: getReportTitle, width: 300, sortable: true, filter: true},
                {headerName: "Groups", cellRenderer: "groupsRenderer", width: 300, sortable: false, filter: false},
                {headerName: "Reviewers", cellRenderer: "reviewRenderer", sortable: true, filter: true},
                {headerName: "Published By", valueGetter: getCreatedBy, sortable: true, filter: true},
                {headerName: 'Action', width: 170, cellRenderer: "reviewActionRendered"}
            ]

            return cols
        }
        if (tabActive === 2) {
            var cols = [
                {headerName: "Published Date (EST)", valueGetter: getScheduledDate, sort : 'desc', comparator: (valueA, valueB, nodeA, nodeB, isInverted) => moment(valueA) - moment(valueB), sortable: true, filter: true},
                {headerName: "Report Title", valueGetter: getReportTitle, width: 300, sortable: true, filter: true},
                {headerName: "Groups", cellRenderer: "groupsRenderer", width: 300, sortable: false, filter: false},
                {headerName: "Published By", valueGetter: getCreatedBy, sortable: true, filter: true},
                {headerName: 'Action', width: 170, cellRenderer: "publishActionRendered"}
            ]

            return cols
        }
        if (tabActive === 3) {
            var cols = [
                {headerName: "Scheduled Date (EST)", valueGetter: getScheduledDate, comparator: (valueA, valueB, nodeA, nodeB, isInverted) => moment(valueA) - moment(valueB),sortable: true, filter: true},
                {headerName: "Report Title", valueGetter: getReportTitle, width: 300, sortable: false, filter: false},
                {headerName: "Groups", cellRenderer: "groupsRenderer", width: 300, sortable: true, filter: true},
                {headerName: "Published By", valueGetter: getCreatedBy, sortable: true, filter: true},
                {headerName: 'Action', width: 170, cellRenderer: "scheduledActionRendered"},
            ]

            return cols
        }
    }


    function onRowDoubleClicked(params) {
        props.goPreview(params.data)
    }

    if (Object.keys(MyLetter).length === 0) {

        return (
            <div>Loading ...</div>
        )
    } else {

        const openButton = (params)=>{
            return (
                <Tooltip title="Open">
                    <IconButton aria-label="Launch" style={{color: 'lightsteelblue'}} onClick={() => {
                        props.goPreview(params.data)
                    }}>
                        <Launch/>
                    </IconButton>
                </Tooltip>
            )
        }
        const copyButton = (params) => {
            return (
                <Tooltip title="Copy">
                    <IconButton aria-label="Copy" style={{color: 'lightsteelblue'}} onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        console.log('copyong ....')
                        props.doCopy(params.data)
                    }}>
                        <FileCopy/>
                    </IconButton>
                </Tooltip>
            )
        }
        const deleteButton = (params) => {
            return (
                <Tooltip title="Delete">
                    <IconButton aria-label="Delete" style={{color: 'palevioletred'}} onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        props.doDeleteOpen(params.data)
                    }}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            )
        }

        const draftActionRendered = (params) => {
            return (
                <div>
                    {openButton(params)}
                    {copyButton(params)}
                    {deleteButton(params)}
                </div>
            )
        }
        const reviewActionRendered = (params) =>{

            return (
                <div>
                    {openButton(params)}
                    {deleteButton(params)}
                </div>
            )

          }
            const publishActionRendered = (params) =>{

                return (
                    <div>
                        {openButton(params)}
                        {copyButton(params)}
                        <Tooltip title="Stats">
                            <IconButton aria-label="Stats" style={{color: 'green'}} onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                props.openPublishedData(params)
                            }}>
                                <Assessment/>
                            </IconButton>
                        </Tooltip>
                    </div>
                )

            }
            const scheduledActionRendered = (params) =>{
                return (
                    <div>
                        {openButton(params)}
                        {deleteButton(params)}
                    </div>
                )

            }

        return (
            <div>

                <div className={classes.root}>

                    <div className="ag-theme-material" style={{height: 800, width: '100%'}}>
                        {/*<DataGrid autoHeight={true} rows={filtered_data} columns={columns}  />*/}
                        <AgGridReact id={2} rowData={filtered_data}
                                     rowSelection={'single'}
                                     onRowDoubleClicked={onRowDoubleClicked}
                                     defaultColDef={{
                                         editable: false,
                                         sortable: true,
                                         flex: 1,
                                         minWidth: 100,
                                         resizable: true,
                                         filter : "agTextColumnFilter"
                                     }}
                                     columnDefs={getcolumnDefs()}
                                     frameworkComponents={{
                                         "groupsRenderer": groupsRenderer,
                                         "reviewRenderer": reviewRenderer,
                                         "draftActionRendered": draftActionRendered,
                                         "reviewActionRendered": reviewActionRendered,
                                         "scheduledActionRendered": scheduledActionRendered,
                                         "publishActionRendered": publishActionRendered,

                                     }}/>

                    </div>

                    <CardContent>
                        {!isUserParent(props.currentUser)
                            ? <Typography
                                className={props.classes.boldText + ' ' + props.classes.itemcenter}
                            >
                                {(draft || []).length === 0 && tabActive === 0 ? ' No Data Found' : ''}
                                {(review || []).length === 0 && tabActive === 1 ? ' No Data Found' : ''}
                                {(publish || []).length === 0 && tabActive === 2 ? ' No Data Found' : ''}
                                {(schedule || []).length === 0 && tabActive === 3 ? ' No Data Found' : ''}

                            </Typography>
                            : <Typography
                                className={props.classes.boldText + ' ' + props.classes.itemcenter}
                            >
                                {(publish || []).length === 0 ? ' No Data Found' : ''}

                            </Typography>}
                    </CardContent>

                </div>
            </div>
        );
    }
}