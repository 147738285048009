import React, { Fragment } from 'react';
import * as routes from './path';
import { Route } from 'react-router-dom';

import Dashboard from '../container/Dashboard';
import SchoolProfile from '../component/SchoolProfile';
import RecordLesson from '../component/Lessons/Record'
import Lesson from "../component/Lessons/Lesson"
import StudentDashboard from '../component/SchoolProfile/components/Students/Dashboard';
import EditStudent from '../component/SchoolProfile/components/Students/components/CreateStudent';
import StudentProfile from '../component/SchoolProfile/components/Students/components/StudentProfile';
// import AttendanceDetails from '../component/SchoolProfile/components/Students/components/StudentProfile/AttendanceDetails';
import StudentAttendance from '../component/SchoolProfile/components/Students/components/StudentProfile/NewAttendance';
import AddQuickMultipleStudent from '../component/SchoolProfile/components/Students/AddQuickMultipleStudent';

import StaffDashboard from '../component/SchoolProfile/components/Staffs/Dashboard';
import EditStaff from '../component/SchoolProfile/components/Staffs/components/CreateStaff';
import StaffProfile from '../component/SchoolProfile/components/Staffs/components/StaffProfile';
import StaffAttendance from '../component/SchoolProfile/components/Staffs/components/StaffProfile/StaffAttendance';
import AddQuickMultipleStaff from '../component/SchoolProfile/components/Staffs/AddQuickMultipleStaff';

import GroupsDashboard from '../component/SchoolProfile/components/Groups/Dashboard';
import EditGroups from '../component/SchoolProfile/components/Groups/components/CreateGroup';
import GroupProfile from '../component/SchoolProfile/components/Groups/components/GroupProfile';

import Students from '../container/student';
import Users from '../container/users';
import Parent from '../container/parent';
import Staff from '../container/staff';
import Group from '../container/group';
import AddStudentsBulk from '../container/addStudentsBulk';
// import GroupDetail from '../container/groupDetail';
// import AttendanceSummary from '../container/AttendanceSummary';
import ParentManageForm from '../component/parentManage';
import StaffManageForm from '../component/staffManage';
import UserManageForm from '../component/studentManage';
import Photos from '../container/photos'
import ProgressReport from '../container/ProgressReport'
import ProgressReportAdmin from '../container/ProgressReportAdmin'
import awsConfig from '../utils/aws-config';
import ParentProfile from "../component/SchoolProfile/components/Parents/components/ParentProfile";
import Plan from "../container/Plan";


export default function AdminRoutes() {
    return (
        <Fragment>
            <Route component={Dashboard} path={routes.DASHBOARD} exact />

            <Route component={SchoolProfile} path={routes.MAIN_DASHBOARD} exact />

            <Route component={StudentProfile} path={routes.STUDENT_VIEW} exact />
            <Route component={EditStudent} path={routes.STUDENT_EDIT} exact />
            <Route component={StudentDashboard} path={routes.STUDENT_DASHBOARD} exact />
            {/*<Route component={AttendanceDetails} path={routes.ATTENDANCE_VIEW} exact />*/}
            <Route component={StudentAttendance} path={routes.ATTENDANCE_VIEW} exact />
            <Route component={AddQuickMultipleStudent} path={routes.ADD_STUDENT} exact />

            <Route component={StaffDashboard} path={routes.STAFF_DASHBOARD} exact />
            <Route component={StaffProfile} path={routes.STAFF_VIEW} exact />
            <Route component={EditStaff} path={routes.STAFFS_EDIT} exact />
            <Route component={StaffAttendance} path={routes.STAFF_ATTENDANCE_VIEW} exact />
            <Route component={AddQuickMultipleStaff} path={routes.ADD_STAFF} exact />

            <Route component={ParentProfile} path={routes.PARENT_VIEW} exact />

            <Route component={GroupsDashboard} path={routes.GROUPS_DASHBOARD} exact />
            <Route component={GroupProfile} path={routes.GROUPS_VIEW} exact />
            <Route component={EditGroups} path={routes.GROUPS_EDIT} exact />

            <Route component={Students} path={routes.USER} exact />
            <Route component={UserManageForm} path={routes.USERS_ADD} exact />
            <Route component={UserManageForm} path={routes.USERS_EDIT} exact />
            <Route component={Users} path={routes.USERS} exact />
            <Route component={Parent} path={routes.PARENTS} exact />
            <Route component={ParentManageForm} path={routes.PARENTS_ADD} exact />
            <Route component={ParentManageForm} path={routes.PARENTS_EDIT} exact />
            <Route component={Staff} path={routes.STAFF} exact />
            <Route component={StaffManageForm} path={routes.STAFF_ADD} exact />
            <Route component={StaffManageForm} path={routes.STAFF_EDIT} exact />
            <Route component={Group} path={routes.GROUP} exact />
            <Route component={AddStudentsBulk} path={routes.GROUPBULK} exact />
            {/*<Route component={GroupDetail} path={routes.GROUPINFO} exact />*/}
            {/*<Route component={AttendanceSummary} path={routes.StudentSummary} exact />*/}
            {/* <Route component={Photos} path={routes.Photos} exact /> */}
            <Route component={ProgressReportAdmin} path={routes.ProgressReportAdmin} exact />
            {/*<Route component={Lesson} path={routes.LESSON} exact />*/}


        </Fragment>
    );
}

