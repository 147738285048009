import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { compose } from 'redux';
import {connect} from "react-redux";
import {
  Button,
  Card,
  Grid,
  Typography,
  withStyles,
  IconButton,
  Paper,
  InputBase,
  Avatar,
  LinearProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {Button as AntdButton, Icon, Spin, Tooltip} from "antd";
import moduleService from '../service/module';
import './stylesGroupList.css';
import * as Routes from "../../../../routes/path";


const useStyles = theme => ({
  searchRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    height: 40,
    backgroundColor: '#CECECE',
    opacity: 0.45,
    color: 'black',
    marginBottom: theme.spacing(3),
  },
  searchContainer: {
    // marginBottom: theme.spacing(3),
  },
  Avatar: {
    margin: 10,
    width: 30,
    height: 30,
  },
  input: {
    marginLeft: 8,
    flex: 1,
    color: 'black',
    fontSize: '12px',
  },
  iconButton: {
    padding: 10,
    color: 'black',
  },
  root: {
    display: 'flex',
  },
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(6),

    fontSize: '20px ',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: '16px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  progressRoot: {
    flexGrow: 1,
    maxWidth: 180,
    minWidth: 180,
    paddingTop: theme.spacing(0.5),
  },
  item: {
    marginBottom: theme.spacing(0),
  },
  innerCard: {
    paddingLeft: theme.spacing(3),
  },
  progressText: {
    paddingLeft: theme.spacing(1),
  },
  customProgress: {},
});

class GroupsList extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading: false,
      values: {
        search: '',
        module: {
          modul: '',
          module: '',
        },
      }
    }
  }

  async componentDidMount() {
    const { match: { params: { moduleId = "" } = {} } = {} } = this.props
    this.setState({
      isLoading: true
    })

    const response = await moduleService.getModuleById(parseInt(moduleId));
    if(response && response.status === "success") {
      this.setState({
        values: {
          ...this.state.values,
          module: response.data
        },
        isLoading: false
      })
    } else {
      this.setState({
        isLoading: false
      })
    }
  }

  handleViewStudents = (moduleId, groupName, color) => {
    this.props.history.push({
      pathname: `/groups/students/${moduleId}/${groupName}`,
      state: { backgroundColor: color },
    });
  };

  getGroupsList = () => {
    const { groupList = [] } = this.props
    const { values: { module: { groups = {} } = {}, selectedGroup: { name = "" } = {}, search = "", markFilter = "" } = {} } = this.state

    let groupsList = (groups && Object.keys(groups || {}) || []).map(x => ({...groups[x], groupId: x}))

    groupsList = (groupsList || []).filter(g => (g.name || "").toLowerCase().includes(search.toLowerCase()))

    groupsList.forEach((group, index) => {
      const groupObj = (groupList || []).find(x => (x.id || "").toString() ===  (group.groupId || "").toString())
      const data = group.childids.filter(child => this.getChildGroups(child.childid, (groupObj || {}).id))
      group.childids = data || []
      group.total = (data || []).length
    })

    return groupsList || []
  }

  getChildGroups = (childId, groupId) => {
    const { childrenCache } = this.props

    const data = childrenCache && childrenCache[childId] || {}
    return ((data.group_ids || []).some(x => (x || "").toString() === (groupId || "").toString())) || false
  }


  render() {
    const { classes, match: {params}, location: { state: { backgroundColor = "" } } = {}, isAdmin, history } = this.props
    const { values, isLoading } = this.state

    console.log({props: this.props})

    if(isLoading) {
      return <div className="text-center mt-60"><Spin/></div>
    }

    return(
      <div className="report-group-list">

        {
          isAdmin ?
            <div className="green-button text-center mb-5">
              <AntdButton shape="round" size="large" className="mr-3" onClick={() => history.push(Routes.ProgressReport)}>
                <span style={{color: '#3cc89c'}}>Create/Mark Progress Report</span>
              </AntdButton>
              <AntdButton shape="round" size="large" onClick={() => history.push(Routes.ProgressReportAdmin)}>
                <span style={{color: '#3cc89c'}}>Publish/Email Progress Report</span>
              </AntdButton>
            </div> : null
        }

        <div className="group-list">
          <div className="main-header" style={{background: backgroundColor}}>
            <div className="header-content">
              <div className="header-content1">
                <div className="back">
                  <Tooltip title={"Back"} placement="bottomLeft">
                    <Icon
                      type="left"
                      className="color-white cursor-pointer"
                      onClick={() => window.history.back()}
                    />
                  </Tooltip>
                </div>
                <h3 className="mb-0 color-white">{values.module.modul}</h3>
              </div>
              <div>
              </div>
            </div>
          </div>

          <div className="content">
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              className={classes.root}
              width="100%"
            >
              <Grid container md={4} xs={12} className={classes.searchContainer}>
                <Paper className={classes.searchRoot}>
                  <InputBase
                      className={classes.input}
                      placeholder="Search"
                      value={values.search}
                      onChange={e => this.setState({values: {...values, search: e.target.value} })}
                      inputProps={{ 'aria-label': 'Search Google Maps' }}
                  />
                  <IconButton className={classes.iconButton} aria-label="Search">
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid container className={classes.item} xs={12} md={12}>
                <Card className="w-100-p">
                  <Table className="progress-report-table">
                    <TableBody>
                      { (this.getGroupsList() || []).map(group => (
                        <div key={group.name} style={{ display: 'flex' }}>
                          <TableRow
                            component="li"
                            style={{ width: '100%', display: 'table' }}
                            key={group}
                            onClick={() => this.handleViewStudents(params.moduleId, group.name, backgroundColor)}
                          >

                            <TableCell md={4} className="moduleName">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  marginTop: '5px',
                                  alignItems: 'center',
                                }}
                              >
                                <Avatar className={classes.Avatar}>
                                  {group.name.substring(0, 1)}
                                </Avatar>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: 'bold',
                                      paddingTop: '2px',
                                    }}
                                >
                                  {group.name}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                    style={{
                                      fontSize: '10px ',
                                      marginTop: '5px',
                                      marginLeft: '5px',
                                    }}
                                >
                                  {group.total} students
                                </Typography>
                              </div>
                            </TableCell>

                            <TableCell md={5} className="button-md">
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                              >
                                <Button
                                    onClick={() =>
                                        this.handleViewStudents(params.moduleId, group.name, backgroundColor)
                                    }
                                    color="primary"
                                >
                                  <Typography
                                      variant="subtitle1"
                                      style={{
                                        fontSize: '11px',
                                        fontWeight: 'bold',
                                        color: '#3f51b5'
                                      }}
                                  >
                                    View Students
                                  </Typography>
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                          <Divider />
                        </div>
                      ))}
                    </TableBody>
                  </Table>
                </Card>
              </Grid>

            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  groupList: state.currentUser.newUserInfo.groupList || [],
  childrenCache: state.currentUser.newUserInfo.childrencache || [],
  isAdmin: state.currentUser.newUserInfo.isAdmin
})

const enhance = compose(
    withRouter,
    withStyles(useStyles, 'some style'),
    connect(mapStateToProps, null),
)

export default enhance(GroupsList);
