import {
    Auth
} from 'aws-amplify';

// AWS Mobile Hub Project Constants
const setHeader = async () => {
    const token = (await Auth.currentSession()).idToken.jwtToken;
    return {
        'Authorization': token,
        'content-type': 'application/json'
    }
};

const api_env = "/prod/"

const API = {
    'graphql_endpoint': 'https://3tfntf2atnfszabdcv3wsjjoa4.appsync-api.us-east-1.amazonaws.com/graphql',
    'graphql_headers': async () => {
        const token = (await Auth.currentSession()).idToken.jwtToken;
        return ({
            'Authorization': token
        })
    },
    // 'graphql_endpoint_iam_region': 'my_graphql_apigateway_region',
    'endpoints': [
        {
            'name': "reset_password",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/resetpasswordflow",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_user_info",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/me",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_child_of_user",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/me/children",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_students",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/child",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "add_students",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/child/add",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "edit_students",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/child/update",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_student",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/child/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "search_user",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/search",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "bulupload",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/bulkupload",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "bulupload_status",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/bulkupload/status",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_users",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "add_users",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/add",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "add_users_db",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/adddb",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "edit_users",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/update",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_users",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_users_db",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/deletedb",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_groups",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/groups",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "add_group",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/groups/add",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_group",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/groups/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "update_group",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/groups/update",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_users_count",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/dashboard/usercount",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_students_count",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/dashboard/studentcount",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_lastlogin",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/lastlogin",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_user_detail",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/details",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_user_child_spouse",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/users/students",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "add_groups_students_add",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/groups/students/add",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "child_group_delete",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/child/group-delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_child_by_id",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/child/id",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name':"get_loggedin_user_child",
            'endpoint':"https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/me/children",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "unmarked_attendance",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/attendance/today/unmarked",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "summary_attendance",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/attendance/today/summary",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "summarybyclass",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/attendance/summarybyclass",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "child_attendance",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/child/attendance",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "mark_attendance",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/attendance/markattendance",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "user_cache",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "config/usercache",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_posts",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "add_posts",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/add",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "edit_posts",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/edit",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_posts",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "add_comments",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/comments/add",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "edit_comments",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/comments/edit",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_comments",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/comments/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "post_like",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/react",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "post_unlike",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/unlike",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "post_view",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "posts/view",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_staff",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "group/staff",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_student",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "group/children",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "create_event",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/create",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "edit_event",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/edit",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_event",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_event",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/get",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "set_rsvp_event_response",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/event-response",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "update_rsvp_event_response",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/update-event-response",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get-event-response",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/get-event-response",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get-user-response",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/get-user-response",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "change-owner",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/change-owner",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "spot-delete",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/spot/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "event-child-delete",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/child/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "getchildrenforgroups",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "group/getchildrenforgroups",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "getChildrenForTeacherGroups",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "group/getchildrenforteachergroups",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get_photos",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/photos",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "get-users",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "events/get-users",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "send-email",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "email/generic",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "report-get-group",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/by-group",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "report-get-scales",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/scales",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader

        },
        {
            'name': "admin_groups",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "admin/groups",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "newsletter_get",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "newsletter/get",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "newsletter_get_stats",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "newsletter_email_status",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "group_staff",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "newsletter/get_group_people",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "newsletter_delete",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "newsletter/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "newsletter_edit",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "newsletter/edit",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "newsletter_create",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "newsletter/add",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "report-get-pdf",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/create/pdf",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "report-get-pdfv2",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/create/pdfv2",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "mark-as-published",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/update/status",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add-update-pin",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/addupdatepin",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "create_user",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/create",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "update_user",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/update",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "delete_user",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_user_details",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/get",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "create_child",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "child/create",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "update_child",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "child/update",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_child_info",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "child/get",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "delete_child",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "child/delete",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "create_group",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "group/create",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "update_group",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "group/update",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_group_list",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "group/getlist",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_new_user_info",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/me",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "invite_user",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/invite",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_child_attendance",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "get_checkin_data",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_staff_attendance",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "get_staff_checkin_data",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "child_checkin",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "checkin",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "child_pre_checkin",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "precheckin",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "staff_checkin",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "staff_checkin",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "staff_pre_checkin",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "staff_precheckin",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_checkin_data_by_child_id",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "get_checkin_data_by_child_id",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_staff_checkin_data_by_staff_id",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "get_staff_checkin_data_by_staff_id",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "firebasetoken",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "firebasetoken",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "user/create_platform_endpoint",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "user/create_platform_endpoint",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_module_highlights",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/module-highlights",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_scales",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/scales",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_groups_report",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/groups",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_module_details",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/module-details",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add_module",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/add/module",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "delete_module",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/delete/module",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add_scale",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/add/scale",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name': "delete_scale",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/delete/scale",
            'service': 'lambda',
            'region': 'us-east-1',
            'custom_header': setHeader,
        },
        {
            'name' : "update_semester",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/update/status",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_student_semester_details",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/studentsemesterdetails",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_marks_for_child",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/get/child-marks",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_PDFforModule",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/create/pdf",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_PDFforModulev2",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/create/pdfv2",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add_update_marks",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "report/add/marks",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "create_school",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "school/create",
            'service': "lambda",
            'region': "us-east-1"
        },
        {
            'name' : "newsletter_templates",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "newsletter/templates",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "dashboard_templates",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/get_templates",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "audit_history",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/get_history",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add_dashboard_template",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/add_template",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add_dashboard_entry",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/add_entry",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add_dashboard_grade",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/add_grade_entry",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_tasks",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/get_tasks",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "get_dashboard_markings",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/get_markings",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "add_comment",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/update_dashboard_comment",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "flagged_students",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/get_flagged",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {
            'name' : "set_schedule",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/set_schedule",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        },
        {

            'name' : "add_task",
            'endpoint': "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com" + api_env + "dashboard/add_task",
            'service': "lambda",
            'region': "us-east-1",
            'custom_header': setHeader
        }
    ]
};

export default API;