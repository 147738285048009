import { API } from 'aws-amplify';

const getChildMarks = body => {
  const init = {
    body
  };
  return API.post('get_marks_for_child', '', init);
};
const getParentChildMarks = () => {
  const init = {
    body: {},
  };
  return API.post('get_marks_for_child', '', init);
};
const addUpdateMarks = data => {
  const init = {
    body: data,
  };
  return API.post('add_update_marks', '', init);
};
const getSemesterDetails = body => {
  const init = {
    body
  };
  return API.post('get_student_semester_details', '', init);
};

const getPDFforModule = body => {
  const init = {
    body
  };
  console.log(body);
  return API.post('get_PDFforModulev2', '', init);
};

const marksService = {
  getChildMarks,
  addUpdateMarks,
  getParentChildMarks,
  getSemesterDetails,
  getPDFforModule,
};

export default marksService;
