import {
    API
} from 'aws-amplify';
import {
    takeEvery,
    fork,
    all,
    put,
    select
} from 'redux-saga/effects'

import { REQUEST, SUCCESS } from '../actions/common';
import store from "../store";
import { getHistory, GET_HISTORY, postComment, POST_COMMENT } from '../actions/observation';

function* fetchHistory(action) {
    try {
        const init = {
            body: action.query
        }
        if(!action.query){
            let body = action.payload?.body;
            delete body.comment;
            init.body = body;
        }
        const payload = yield API.post('audit_history', '', init)
        if (!payload.errorMessage) {
            yield put(getHistory.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(getHistory.failure(error))
    }
}

function* saveComment(action) {
    
    try {
        const init = {
            body: action.query
        }
        const payload = yield API.post('add_comment', '', init)
        if (!payload.errorMessage) {
            yield put(postComment.success({payload,body:action.query}))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(postComment.failure(error))
    }
}

function* watcherFetchHistory() {
    yield takeEvery(GET_HISTORY[REQUEST], fetchHistory)
}

function* watcherSaveComment() {
    yield takeEvery(POST_COMMENT[REQUEST], saveComment)
}

function* watcherSaveCommentSuccess() {
    yield takeEvery(POST_COMMENT[SUCCESS], fetchHistory)
}

export default function* rootObservation() {
    yield all([,
        fork(watcherFetchHistory),
        fork(watcherSaveComment),
        fork(watcherSaveCommentSuccess),
    ]);
}