import React, {Component, useRef} from 'react'
import {withStyles} from '@material-ui/core/styles';
import {
    Save,
    Title,
    TextFields,
    Image,
    Videocam,
    Attachment,
    Clear,
    Description,
    RemoveCircle,
    Edit,
    AddCircle,
    Visibility,
    Cancel
} from '@material-ui/icons';
import { Select as SelectAntd} from 'antd';
import _ from "lodash"
import {IconButton, Toolbar, Button, Typography, TextField, AppBar, Grid, Card, CardContent, MenuItem} from "@material-ui/core";
import InputBase from '@material-ui/core/InputBase';
import Select from "react-select";
import DatePicker from "react-datepicker";
import {SketchPicker} from "react-color";
import {Editor} from "primereact/editor";
import ReactHtmlParser from "react-html-parser";
import Paper from "@material-ui/core/Paper/Paper";
import 'react-quill/dist/quill.snow.css';
import awsConfig from "../../../utils/aws-config";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import {getRandomString} from "../../../utils/utils";
import moment from "moment";
import {Storage} from "aws-amplify";

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "rgb(245, 245, 245)",
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#2684FF',
            boxShadow: '0 0 0 1px #2684FF',
        },
    },
}))(InputBase);

const customStyles = {
    control: (base, state) => ({
        ...base,
        background: "rgb(245, 245, 245)",
        fontSize: '16px',
        // match with the menu
        borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
// Overwrittes the different states of border
// borderColor: state.isFocused ? "yellow" : "green",
// Removes weird border around container
// boxShadow: state.isFocused ? null : null,
// "&:hover": {
//     // Overwrittes the different states of border
//     borderColor: state.isFocused ? "red" : "blue"
        // }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 0,
// kill the gap
        marginTop: 0
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    })
};

export class CreateNewsLetter extends Component {

    constructor(props) {
        super(props);
        this.editor = null;
        this.isEditorLoaded = false;
        this.isComponentMounted = false;
        console.log('-------', props)
        let reviewList = []
        if ((props.createNewsLetter.group || []).length) {
            reviewList = this.refreshReviewList((props.createNewsLetter.group || []).length ? props.createNewsLetter.group : []);
        }
        this.state = {
            reviewList: reviewList,
            htmlEditor: this.props.createNewsLetter.newsLetterId !== '' ?
                (this.props.createNewsLetter.structure?.is_new == 1 || this.props.createNewsLetter.structure?.content.length == 0 ? "NEW" : "OLD") : "NEW"
        }
        this.handleGroupChangeLocal = this.handleGroupChangeLocal.bind(this)
        console.log('createNewsLetter props', this.props.createNewsLetter)
        console.log('state.htmlEditor', this.state.htmlEditor)

    }

    async  uploadFile(file) {
        const rString = getRandomString()
        const fileName = (file.name || "").split(".")

        const path = `newsletter/${moment().year()}/${moment().month() + 1}/${moment().date()}/${fileName[0]}_${rString}.${fileName[1]}`;
        console.log('putting ' + file.type)
        let stat = await Storage.put(path, file, {contentType: file.type});
        console.log('stat', stat)
        return path;
    }

    handleGroupChangeLocal(event) {
        let reviewList = this.refreshReviewList((event || []).length ? event : []);
        this.setState({
            reviewList
        })
        this.props.handleGroupChange(event)
    }

    handlechangeTemplate = (templateId) => {
        console.log('event', templateId)
        let templateJSONDict = _.chain(this.props.MyLetter.templates)
            .keyBy('id')
            .mapValues('templatejson')
            .value();
        let templateJSON = templateJSONDict[templateId]
        this.editor.loadDesign(templateJSON)
    }

    refreshReviewList(event = []) {
        const {MyLetter, createNewsLetter, handleChangeMultiple} = this.props
        let reviewList = []

        event.forEach(evt => {
            Object.values(((((MyLetter || {}).reviewList || {})[evt.groupid] || {})['staff'] || {})).map((user) => {
                if (user.role[this.props.school_id].communications && !(reviewList || []).some(x => x.value === user.id)) {
                    reviewList.push({
                        value: user.id,
                        label: user.firstName + ' ' + user.lastName,
                        email: user.id
                    })
                }
            })
        })

        let reviewerList = []

        createNewsLetter.reviews.forEach(review => {
            if ((reviewList || []).some(x => x.value === review.value)) {
                reviewerList.push(review)
            }
        })
        handleChangeMultiple(reviewerList)

        console.log('reviewList', reviewList)
        return reviewList
    }
     getEditorRef = ()=>{
        if (this.editor === null) {
            console.log('Null .. Returning')
            return null;
        }else{
            if (typeof this.editor.loadDesign !== "function"){
                return this.editor.editor
            }else{
                return this.editor
            }

        }

    }
    getSelectedCreatedBy = (id) => {
        const list = this.props.getUserList()
        if (id) {
            return (list || []).filter(x => x.value === id)
        }
        return []
    }

    radioChange = (event) => {
        console.log(event.target.value)
        this.setState({htmlEditor: event.target.value})
    }

    imageCallBack = (file, done) =>{
        console.log('file', file)
        let pathPromise = this.uploadFile(file.attachments[0])
        console.log('path uploaded ->', pathPromise)
        pathPromise.then(url=> {
            console.log('res', url)
            done({progress : 100, url : awsConfig.media_url + 'public/' + url})
        })
    }

    componentDidMount() {
        this.isComponentMounted = true;
        console.log('Component is mounted')
        this.loadTemplate();

    }
    loadTemplate = () => {
        console.log('this.editor', this.editor)
        console.log('his.props.createNewsLetter', this.props.createNewsLetter)
        if (this.editor === null) {
            console.log('Null .. Returning')
            return;
        }else{
            if (typeof this.editor.loadDesign !== "function"){
                this.editor = this.editor.editor
                console.log('switched ....')
            }
            console.log('Not Null')
        }
        if (!this.isEditorLoaded || !this.isComponentMounted) return;
        console.log('this.props.createNewsLetter.structure?.designjson', this.props.createNewsLetter.structure?.designjson)

        this.editor.loadDesign(this.props.createNewsLetter.structure?.designjson)
        this.editor.registerCallback('image', this.imageCallBack);


    }




    render() {

        const {
            classes,
            createNewsLetter,
            profilePlaceholder,
            selectProfileImage,
            handleChangeMultiple,
            MyLetter,
            handleGroupChange,
            handleDateChange,
            handleTitlehange,
            openPicker,
            backgroudColor,
            closePicker,
            selectPicker,
            styles,
            styles1,
            openTitlePicker,
            titlePicker,
            closeTitlePicker,
            selectTitlePicker,
            addMenu,
            closeNewTopic,
            cancelNewTopic,
            handleChangeUpdateTitle,
            addSubOption,
            addiconImage,
            addNewTopic,
            selectedContent,
            selectImage,
            selectVideo,
            selectDocument,
            removeItem,
            editContent,
            removeImage,
            openImageModal,
            addMenuOption,
            setisPreview,
            setisPublish,
            setisPublishNew,
            submitData,
            submitDataNew,
            doUpdateStatus,
            doUpdateStatusNew,
            isTeacher,
            isAdmin,
            isParent,
            onEditPreview,
            getUserList,
            onChangeCreatedBy
        } = this.props
        const reviewDisabled = (!(createNewsLetter.title && (createNewsLetter.group).length && createNewsLetter.created_by) || !(createNewsLetter.reviews.length))
        const groupDisabled = isAdmin ? createNewsLetter.newsLetterStatus === 2 : (createNewsLetter.newsLetterStatus === 2 || createNewsLetter.newsLetterStatus === 3 || createNewsLetter.newsLetterStatus === 1)
        console.log('createNewsLetter ', createNewsLetter)
        console.log('this.props.MyLetter', this.props.MyLetter.templates)
        var templates = this.props.MyLetter.templates.map(item => {
            return { "label": item.name, "value": item.id, "templateJSON" : item.templatejson };
        });
        console.log('templates', templates)
        return (
            <Grid
                container
                spacing={0}
                className={classes.borderRadius10}
            >
                {createNewsLetter.newsLetterId === '' &&
                    <div className='row' style={{width : '600px'}}>
                        <RadioGroup  row aria-label="gender" name="gender1" value={this.state.htmlEditor} onChange={this.radioChange}>
                            {/*<FormControlLabel value="NEW" control={<Radio/>} label="Beta"/>*/}
                            {/*<FormControlLabel value="OLD" control={<Radio/>} label="Legacy"/>*/}
                        </RadioGroup>
                        <div style={{marginLeft : 15, marginRight : 5, marginTop : 3}}>Select Template</div>
                        <SelectAntd
                            isMulti={false}
                            placeholder="Select Template"
                            onChange={this.handlechangeTemplate}
                            style={{width : '200px'}}

                        >
                            {this.props.MyLetter.templates.map(item => (
                                <SelectAntd.Option key={item.id} value={item.id}>
                                    {item.name}
                                </SelectAntd.Option>
                            ))}
                        </SelectAntd>
                    </div>
                }
                {console.log('templates->', this.props.MyLetter)}
                {console.log('MyLetter.groups', MyLetter.groups)}
                <Grid container spacing={1}>

                    <Grid item xs={this.state.htmlEditor == "OLD" ? 4 : 2}>
                        <Paper className={classes.paper}>
                            <div>
                                <img
                                    className={classes.avatarImage}
                                    src={createNewsLetter.profileImage ? `${awsConfig.media_url}public/${createNewsLetter.profileImage}` : profilePlaceholder}
                                    onError={e => (e.target.src = profilePlaceholder)}
                                />
                            </div>
                            <input
                                accept="image/*"
                                className={classes.input}
                                id="profile-images"
                                type="file"
                                onChange={event => selectProfileImage(event)}
                            />
                            {createNewsLetter.isEdit &&
                                <label
                                    htmlFor="profile-images"
                                    className={classes.itemcenter}
                                >
                                    <Button
                                        component="span"
                                        className={classes.avataruploadButton}
                                    >
                                        <AddCircle style={{color: "rgb(3, 191, 208)"}}/>
                                    </Button>
                                </label>
                            }

                            <div style={{textAlign: "left", fontSize: 17, fontWeight: 600, color: "black"}}><span>Newsletter Title <span
                                style={{color: "red", fontSize: 20}}>*</span></span></div>
                            <BootstrapInput
                                id="demo-customized-textbox"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                placeholder="Newsletter Title"
                                value={createNewsLetter.title}
                                onChange={handleTitlehange}
                                disabled={!createNewsLetter.isEdit}
                                style={{backgroundColor: "rgb(245, 245, 245)"}}
                            />

                            <div style={{marginTop: 12}}/>

                            <div style={{textAlign: "left", fontSize: 17, fontWeight: 600, color: "black"}}><span>Select Group <span
                                style={{color: "red", fontSize: 20}}>*</span></span></div>
                            {console.log('createNewsLetter', createNewsLetter.newsLetterStatus, createNewsLetter.isEdit)}
                            <Select
                                isMulti={true}
                                placeholder="Select Group"
                                value={createNewsLetter.group}
                                onChange={this.handleGroupChangeLocal}
                                options={MyLetter && MyLetter.groups}
                                isDisabled={groupDisabled}
                                styles={customStyles}
                            />
                            <div style={{textAlign: "left", fontSize: 17, fontWeight: 600, color: "black"}}>Select
                                Reviewer
                            </div>
                            <Select
                                isMulti={true}
                                placeholder="Select Reviewer"
                                value={createNewsLetter.reviews}
                                onChange={handleChangeMultiple}
                                options={this.state.reviewList}
                                isDisabled={(createNewsLetter.newsLetterStatus === 3)}
                                styles={customStyles}
                            />
                            <div style={{textAlign: "left", fontSize: 17, fontWeight: 600, color: "black"}}>Email :
                                Sender <span style={{color: "red", fontSize: 20}}>*</span></div>
                            <Select
                                placeholder="Select"
                                value={this.getSelectedCreatedBy(createNewsLetter.created_by)}
                                onChange={onChangeCreatedBy}
                                options={getUserList() || []}
                                styles={customStyles}
                            />

                            <div style={{marginTop: 15}}/>

                            <div style={{marginTop: 8}}/>

                            {/*<Grid container spacing={1}>

    <Grid item xs={6}>
    <TextField
id="outlined-margin-dense"
fullWidth
margin="dense"
variant="outlined"
value="Newsletter Date"
style={{backgroundColor: "rgb(245, 245, 245)", fontSize: 17, fontWeight: 900, color: "black", pointerEvents: "none", cursor: "not-allowed"}}
/>
</Grid>
<Grid item xs={6}>
    <DatePicker
placeholderText={'Select Date'}
className={classes.datePickerFilter}
selected={createNewsLetter.date}
onChange={handleDateChange}
readOnly={!createNewsLetter.isEdit}
style={{background: "rgb(245, 245, 245)"}}
/>
</Grid>
</Grid>*/}
                            {this.state.htmlEditor == "OLD" &&
                                <Grid container spacing={1}>

                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-margin-dense"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            value="Letter Background"
                                            style={{
                                                backgroundColor: "rgb(245, 245, 245)",
                                                fontSize: 17,
                                                fontWeight: 900,
                                                color: "black",
                                                pointerEvents: "none",
                                                cursor: "not-allowed"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className={classes.selectColorButton} style={{textAlign: "left"}}>
                                            <div
                                                style={{
                                                    width: "70px",
                                                    height: "30px",
                                                    background: "#fff",
                                                    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                                                    cursor: "pointer", borderRadius: 5, marginLeft: 10
                                                }}
                                                onClick={openPicker}
                                            >
                                                <div style={{
                                                    width: 70,
                                                    height: 30,
                                                    borderRadius: 5,
                                                    background: styles.color.background
                                                }}/>
                                            </div>

                                            {backgroudColor.displayColorPicker
                                                ? <div style={styles.popover}>
                                                    <div
                                                        style={styles.cover}
                                                        onClick={closePicker}
                                                    />
                                                    <SketchPicker
                                                        color={createNewsLetter.background}
                                                        onChange={selectPicker}
                                                    />
                                                </div>
                                                : null}

                                        </div>
                                    </Grid>
                                </Grid>
                            }
                            {this.state.htmlEditor == "OLD" &&
                                <Grid container spacing={1}>

                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-margin-dense"
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            value="Topic Background"
                                            style={{
                                                backgroundColor: "rgb(245, 245, 245)",
                                                fontSize: 17,
                                                fontWeight: 900,
                                                color: "black",
                                                pointerEvents: "none",
                                                cursor: "not-allowed"
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {createNewsLetter.isEdit &&
                                            <div
                                                // style={{marginTop: 5}}
                                                className={classes.selectColorButton}
                                            >
                                                <div
                                                    style={{
                                                        width: "70px",
                                                        height: "30px",
                                                        background: "#fff",
                                                        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                                                        cursor: "pointer", borderRadius: 5, marginLeft: 10
                                                    }}
                                                    onClick={openTitlePicker}
                                                >
                                                    <div style={{
                                                        width: 70,
                                                        height: 30,
                                                        borderRadius: 5,
                                                        background: styles1.color.background
                                                    }}/>
                                                </div>

                                                {titlePicker.displayColorPicker ?
                                                    <div style={styles1.popover}>
                                                        <div
                                                            style={styles1.cover}
                                                            onClick={closeTitlePicker}
                                                        />
                                                        <SketchPicker
                                                            color={createNewsLetter.titleBackground}
                                                            onChange={selectTitlePicker}
                                                        />
                                                    </div>
                                                    : null
                                                }

                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Paper>
                    </Grid>

                    {this.state.htmlEditor == "OLD" ?
                        this.getLeegacyEditor(classes, createNewsLetter, addMenu, closeNewTopic, cancelNewTopic, handleChangeUpdateTitle, addSubOption, addNewTopic, selectedContent, selectImage, selectVideo, selectDocument, removeItem, editContent, removeImage, openImageModal, addMenuOption) :
                        this.getNewEditor(classes, createNewsLetter, addMenu, closeNewTopic, cancelNewTopic, handleChangeUpdateTitle, addSubOption, addNewTopic, selectedContent, selectImage, selectVideo, selectDocument, removeItem, editContent, removeImage, openImageModal, addMenuOption)}
                </Grid>

                <Grid
                    justify="space-between" // Add it here :)
                    container

                    spacing={24}
                >
                    <Grid/>


                    <Grid item>
                        {this.state.htmlEditor == "OLD" && addMenu.newsletter &&
                            addMenu.newsletter.length > 0 &&
                            !createNewsLetter.newsLetterId &&
                            <div className={classes.itemcenter}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => setisPreview(true)}
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Preview
                                </Button>

                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => setisPublish(true)}
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Schedule to Publish
                                </Button>
                                {createNewsLetter.newsLetterStatus !== 1 && createNewsLetter.newsLetterStatus !== 3 &&
                                    <Button
                                        size="small"
                                        // variant="outlined"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => submitData(1)}
                                        disabled={reviewDisabled}
                                    >
                                        Send for review
                                    </Button>
                                }

                                {(
                                        createNewsLetter.newsLetterStatus !== 1 ) &&
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => submitData(0)}
                                        disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                    >
                                        Save Draft
                                    </Button>
                                }
                            </div>}
                        {console.log('createNewsLetter', createNewsLetter)}
                        {addMenu.newsletter &&
                            addMenu.newsletter.length > 0 &&
                            createNewsLetter.isEdit &&
                            createNewsLetter.newsLetterId &&
                            <div className={classes.itemcenter}>

                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => onEditPreview(true)}
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Preview
                                </Button>
                                {
                                    !isParent ?
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => setisPublish(true)}
                                            disabled={isTeacher}
                                        >
                                            Publish
                                        </Button> : null
                                }
                                {createNewsLetter.newsLetterStatus !== 1 && createNewsLetter.newsLetterStatus !== 3 &&
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        disabled={reviewDisabled}
                                        onClick={() => doUpdateStatus(1)}
                                    >
                                        Send for review
                                    </Button>
                                }
                                {(createNewsLetter.newsLetterStatus !== 1) && (createNewsLetter.newsLetterStatus !== 3) && (createNewsLetter.newsLetterStatus !== 0) &&
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => doUpdateStatus(0)}
                                    >
                                        Save Draft
                                    </Button>
                                }
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => submitData(4, "done")}
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => submitData(4)}
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Done
                                </Button>
                            </div>}
                        {console.log('addMenu', addMenu)}
                        {console.log('createNewsLetter', createNewsLetter)}
                        {this.state.htmlEditor == "NEW" &&
                            !createNewsLetter.newsLetterId &&
                            <div className={classes.itemcenter}>

                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => {
                                        let edtr = this.getEditorRef()
                                        edtr.exportHtml((data) => {
                                            const { design, html } = data;
                                            console.log('exportHtml', html);
                                            setisPublishNew( true ,html, design)
                                        });

                                    }}
                                    o
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Schedule to Publish
                                </Button>
                                {createNewsLetter.newsLetterStatus !== 1 && createNewsLetter.newsLetterStatus !== 3 &&
                                    <Button
                                        size="small"
                                        // variant="outlined"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => {
                                            let edtr = this.getEditorRef()
                                            edtr.exportHtml((data) => {
                                                const { design, html } = data;
                                                console.log('exportHtml', html);
                                                submitDataNew( 1, "", html, design)
                                            });

                                        }}
                                        disabled={reviewDisabled}
                                    >
                                        Send for review
                                    </Button>
                                }

                                {(
                                        createNewsLetter.newsLetterStatus !== 1 ) &&
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => {
                                            console.log('emailEditorRef', this.emailEditorRef)
                                            let edtr = this.getEditorRef()
                                            edtr.exportHtml((data) => {
                                                const { design, html } = data;
                                                console.log('exportHtml', html);
                                                submitDataNew( 0, "", html, design)
                                            });

                                        }}
                                        disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                    >
                                        Save Draft
                                    </Button>
                                }
                            </div>}
                        {console.log('createNewsLetter', createNewsLetter)}
                        {console.log('this.state.htmlEditor', this.state.htmlEditor)}
                        {this.state.htmlEditor == "NEW" &&
                            createNewsLetter.isEdit &&
                            createNewsLetter.newsLetterId &&
                            <div className={classes.itemcenter}>

                                {
                                    !isParent ?
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={() => {
                                                let edtr = this.getEditorRef()
                                                edtr.exportHtml((data) => {
                                                    const { design, html } = data;
                                                    console.log('exportHtml', html);
                                                    setisPublishNew( true ,html, design)
                                                });
                                            }}
                                            disabled={isTeacher}
                                        >
                                            Publish
                                        </Button> : null
                                }
                                {createNewsLetter.newsLetterStatus !== 1 && createNewsLetter.newsLetterStatus !== 3 &&
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        disabled={reviewDisabled}
                                        onClick={() => {
                                            let edtr = this.getEditorRef()
                                            edtr.exportHtml((data) => {
                                                const { design, html } = data;
                                                console.log('exportHtml', html);
                                                doUpdateStatusNew(1,html, design)

                                            });

                                        }}
                                    >
                                        Send for review
                                    </Button>
                                }
                                {(createNewsLetter.newsLetterStatus !== 1) && (createNewsLetter.newsLetterStatus !== 3) && (createNewsLetter.newsLetterStatus !== 0) &&
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        onClick={() => {
                                            let edtr = this.getEditorRef()
                                            edtr.exportHtml((data) => {
                                                const { design, html } = data;
                                                console.log('exportHtml', html);
                                                doUpdateStatusNew(0,html, design)

                                            });
                                        }}
                                    >
                                        Save Draft
                                    </Button>
                                }
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => {

                                        let edtr = this.getEditorRef()
                                        edtr.exportHtml((data) => {
                                            const { design, html } = data;
                                            submitDataNew( 4, "done", html, design)
                                        });

                                    }}
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Update
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => {
                                        let edtr = this.getEditorRef()
                                        edtr.exportHtml((data) => {
                                            const { design, html } = data;
                                            console.log('exportHtml', html);
                                            submitDataNew( 4, "", html, design)
                                        });

                                    }}
                                    disabled={!(createNewsLetter.title && (createNewsLetter.group || []).length && createNewsLetter.created_by)}
                                >
                                    Done
                                </Button>
                            </div>}

                    </Grid>
                </Grid>

            </Grid>

        )
    }
    onDesignLoad = (data) => {
        console.log('onDesignLoad', data);
    };

    onLoad = () => {
        this.isEditorLoaded = true;
        this.loadTemplate();
    }



    getNewEditor(classes, createNewsLetter, addMenu, closeNewTopic, cancelNewTopic, handleChangeUpdateTitle, addSubOption, addNewTopic, selectedContent, selectImage, selectVideo, selectDocument, removeItem, editContent, removeImage, openImageModal, addMenuOption) {
        return ( <Grid item xs={10} style={{height : "100%"}}>

            <EmailEditor ref={editor => this.editor = editor}
                         minHeight={'600px'}
                         options={{
                             appearance: { theme: "light" },
                             tools : {image: {
                                     properties: {
                                         src: {
                                             value: {
                                                 url: awsConfig.media_placeholder_url,
                                                 width: 500,
                                                 height: 100
                                             }
                                         }
                                     }}}}}
                         onLoad={this.onLoad} />

        </Grid>)
    }

    getLeegacyEditor(classes, createNewsLetter, addMenu, closeNewTopic, cancelNewTopic, handleChangeUpdateTitle, addSubOption, addNewTopic, selectedContent, selectImage, selectVideo, selectDocument, removeItem, editContent, removeImage, openImageModal, addMenuOption) {
        return <Grid item xs={8}>


            <Card
                className={classes.card}
                style={{
                    background: createNewsLetter.background
                        ? createNewsLetter.background
                        : 'none',
                }}
            >

                <CardContent>

                    {addMenu.newsletter &&
                        addMenu.newsletter.length > 0 &&
                        addMenu.newsletter.map((item, i) => {
                            return (
                                <div key={i.toString()}>
                                    <br/>
                                    {item.isEdit &&
                                        <div>
                                            <IconButton
                                                className={classes.saveButtons}
                                                aria-label="Save"
                                                onClick={() => closeNewTopic(i)}
                                            >
                                                <Save/> Save
                                            </IconButton>
                                            <IconButton
                                                className={classes.saveButtons}
                                                aria-label="Cancel"
                                                onClick={() => cancelNewTopic(i)}
                                            >
                                                <Cancel/> Cancel
                                            </IconButton>
                                            <Editor
                                                style={{height: '300px', marginTop: 20}}
                                                placeholder={item.type === "DESCRIPTION" ? "Add Text" : "Add Title"}
                                                value={item.textTitle}
                                                onTextChange={e => handleChangeUpdateTitle(e.htmlValue, i)}
                                            />
                                        </div>
                                    }

                                    {!item.isEdit &&
                                        <div>
                                            {createNewsLetter.isEdit &&
                                                <AppBar
                                                    position="static"
                                                    className={classes.bottomAppbar}
                                                >
                                                    <Toolbar
                                                        className={classes.nopadding}
                                                        style={{marginTop: -20}}
                                                    >

                                                        <div>
                                                            <Button
                                                                className={classes.addMenuButton}
                                                                onClick={() => addSubOption(i)}
                                                            >
                                                                <AddCircle style={{color: "rgb(3, 191, 208)"}}/>
                                                            </Button>
                                                        </div>

                                                        <div style={{display: 'flex'}}>
                                                            {item.isOpenChild &&
                                                                <div style={{display: 'flex'}}>
                                                                    <Button
                                                                        className={classes.addbutton}
                                                                        onClick={() => addNewTopic(1, i)}
                                                                    >
                                                                        <Title/>
                                                                        <p className={classes.addTitle}>
                                                                            Title
                                                                        </p>
                                                                    </Button>
                                                                    <Button
                                                                        color="secondary"
                                                                        className={classes.addbutton}
                                                                        onClick={() => selectedContent(1, i)}
                                                                    >
                                                                        <TextFields/>
                                                                        <p className={classes.addTitle}>
                                                                            Text
                                                                        </p>
                                                                    </Button>
                                                                    <input
                                                                        accept="image/*"
                                                                        className={classes.input}
                                                                        id="contained-button-images"
                                                                        type="file"
                                                                        multiple
                                                                        onChange={event => selectImage(event, 1, i)}
                                                                    />
                                                                    <label
                                                                        htmlFor="contained-button-images"
                                                                        className={classes.itemcenter}
                                                                    >
                                                                        <Button
                                                                            component="span"
                                                                            className={classes.addbutton}
                                                                        >
                                                                            <Image/>
                                                                            <p className={classes.addTitle}>
                                                                                Image
                                                                            </p>
                                                                        </Button>
                                                                    </label>

                                                                    <input
                                                                        accept="video/mp4,video/x-m4v,video/*"
                                                                        className={classes.input}
                                                                        id="contained-button-video"
                                                                        type="file"
                                                                        onChange={event => selectVideo(event, 1, i)}
                                                                    />
                                                                    <label
                                                                        htmlFor="contained-button-video"
                                                                        className={classes.itemcenter}
                                                                    >
                                                                        <Button
                                                                            component="span"
                                                                            className={classes.addbutton}
                                                                        >
                                                                            <Videocam/>
                                                                            <p className={classes.addTitle}>
                                                                                Video
                                                                            </p>
                                                                        </Button>
                                                                    </label>

                                                                    <input
                                                                        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                                        className={classes.input}
                                                                        id="contained-button-file"
                                                                        type="file"
                                                                        onChange={event => selectDocument(event, 1, i)}
                                                                    />
                                                                    <label
                                                                        htmlFor="contained-button-file"
                                                                        className={classes.itemcenter}
                                                                    >
                                                                        <Button
                                                                            component="span"
                                                                            className={classes.addbutton}
                                                                        >
                                                                            <Attachment/>
                                                                            <p className={classes.addTitle}>
                                                                                Document
                                                                            </p>
                                                                        </Button>
                                                                    </label>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Toolbar>
                                                </AppBar>
                                            }

                                            {item.type === 'TITLE' && item.textTitle &&

                                                <Typography
                                                    className={classes.rootList}
                                                    style={{
                                                        backgroundColor: createNewsLetter.titleBackground,
                                                    }}
                                                >
                                                    {createNewsLetter.isEdit &&
                                                        <IconButton
                                                            className={classes.removeButton}
                                                            aria-label="Delete"
                                                            onClick={() => removeItem(i)}
                                                            style={{marginTop: -14, marginRight: -10}}
                                                        >
                                                            <RemoveCircle/>
                                                        </IconButton>
                                                    }

                                                    {createNewsLetter.isEdit &&
                                                        <Button
                                                            className={classes.editButton}
                                                            aria-label="Edit"
                                                            onClick={() => editContent(i)}
                                                            style={{marginLeft: 4}}
                                                        >
                                                            <label style={{fontSize: '12px', marginTop: 4}}>Edit</label>
                                                        </Button>
                                                    }

                                                    <p className={classes.topictitle} style={{padding: 0}}>
                                                        {item.textTitle ? ReactHtmlParser(item.textTitle) : ''}
                                                    </p>
                                                </Typography>
                                            }
                                        </div>
                                    }

                                    {item.type === 'DESCRIPTION' && item.textTitle && !item.isEdit &&
                                        <div className={classes.descriptionBox}>
                                            {createNewsLetter.isEdit &&
                                                <IconButton
                                                    className={classes.removeButton}
                                                    aria-label="Delete"
                                                    onClick={() => removeItem(i)}
                                                    style={{marginTop: -14, marginRight: -10}}
                                                >
                                                    <RemoveCircle/>
                                                </IconButton>
                                            }
                                            {createNewsLetter.isEdit &&
                                                <Button
                                                    className={classes.editButton}
                                                    aria-label="Edit"
                                                    onClick={() => editContent(i)}
                                                    style={{}}
                                                >
                                                    <label style={{fontSize: '12px', marginTop: 4}}>Edit</label>
                                                </Button>
                                            }

                                            <p className="editorClass" style={{padding: 0}}>
                                                {' '}
                                                {item.textTitle ? ReactHtmlParser(item.textTitle) : ''}
                                            </p>

                                        </div>
                                    }

                                    {item.type !== 'TITLE' && item.type !== 'DESCRIPTION' &&
                                        <div>
                                            {!item.isEdit &&
                                                item.type !== 'IMAGES' &&
                                                createNewsLetter.isEdit &&
                                                <IconButton
                                                    className={classes.removeButton}
                                                    aria-label="Clear"
                                                    onClick={() => removeItem(i)}
                                                    style={{marginTop: -14, marginRight: -10}}
                                                >
                                                    <RemoveCircle/>
                                                </IconButton>
                                            }
                                            {item.type === 'IMAGES' &&
                                                <div className={classes.imageContainer}>
                                                    {item.multipleImage &&
                                                        item.multipleImage.length > 0 &&
                                                        item.multipleImage.map((imgitem, j) => (
                                                            <div
                                                                key={j.toString()}
                                                                className={classes.imageContent}
                                                            >
                                                                {createNewsLetter.isEdit &&
                                                                    <IconButton
                                                                        className={classes.removeButton}
                                                                        aria-label="Clear"
                                                                        onClick={() => removeImage(i, j)}
                                                                        style={{marginTop: -14, marginRight: -10}}
                                                                    >
                                                                        <RemoveCircle/>
                                                                    </IconButton>
                                                                }
                                                                <img
                                                                    className={classes.mediaimage}
                                                                    src={`${awsConfig.media_url}public/${imgitem.showImage}`}
                                                                    title="Contemplative Reptile"
                                                                    onClick={() => openImageModal(imgitem.showImage)}
                                                                />
                                                            </div>
                                                        ))}

                                                </div>
                                            }

                                            {item.type === 'VIDEOS' &&
                                                <video
                                                    style={{
// width: '90%',
                                                        height: 250,
                                                        marginLeft: 50,
                                                    }}
                                                    controls
                                                >
                                                    <source
                                                        src={`${awsConfig.media_url}public/${item.showVideo}`}
                                                        // type={item.showVideo.file.type}
                                                    />
                                                    Your browser does not support HTML5 video.
                                                </video>
                                            }

                                            {item.type === 'DOCUMENTS' &&
                                                <div className={classes.attachmentPreview}>
                                                    <a
                                                        download={`${awsConfig.media_url}public/${item.val}`}
                                                        href={`${awsConfig.media_url}public/${item.val}`}
                                                        title={`attachment-${i}`}
                                                        target="_blank"
                                                    >
                                                        <Description
                                                            style={{fontSize: 50}}
                                                        />
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    }

                                </div>
                            )
                        })}

                    {createNewsLetter.isEdit &&
                        <AppBar
                            position="static"
                            className={classes.bottomAppbar}
                        >
                            <Toolbar className={classes.nopadding}>

                                <div>
                                    <Button
                                        className={classes.addMenuButton}
                                        onClick={() => addMenuOption(0, 0)}
                                    >
                                        <AddCircle style={{color: "rgb(3, 191, 208)"}}/>
                                    </Button>
                                </div>
                                <div>
                                    {addMenu.isOpen &&
                                        <div style={{display: 'flex'}}>
                                            <Button
                                                className={classes.addbutton}
                                                onClick={() => addNewTopic(0, 0)}
                                            >
                                                <Title/>
                                                <p className={classes.addTitle}>Title</p>

                                            </Button>
                                            <Button
                                                className={classes.addbutton}
                                                onClick={() => selectedContent(0, 0)}
                                            >
                                                <TextFields/>
                                                <p className={classes.addTitle}>Text</p>

                                            </Button>
                                            <input
                                                accept="image/*"
                                                multiple
                                                className={classes.input}
                                                id="contained-button-images"
                                                type="file"
                                                onChange={event => selectImage(event, 0, 0)}
                                            />
                                            <label
                                                htmlFor="contained-button-images"
                                                className={classes.itemcenter}
                                            >
                                                <Button
                                                    component="span"
                                                    className={classes.addbutton}
                                                >
                                                    <Image/>
                                                    <p className={classes.addTitle}>Image</p>
                                                </Button>
                                            </label>

                                            <input
                                                accept="video/mp4,video/x-m4v,video/*"
                                                className={classes.input}
                                                id="contained-button-video"
                                                type="file"
                                                onChange={event => selectVideo(event, 0, 0)}
                                            />
                                            <label
                                                htmlFor="contained-button-video"
                                                className={classes.itemcenter}
                                            >
                                                <Button
                                                    component="span"
                                                    className={classes.addbutton}
                                                >
                                                    <Videocam/>
                                                    <p className={classes.addTitle}>Video</p>
                                                </Button>
                                            </label>

                                            <input
                                                accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                className={classes.input}
                                                id="contained-button-file"
                                                type="file"
                                                onChange={event => selectDocument(event, 0, 0)}
                                            />
                                            <label
                                                htmlFor="contained-button-file"
                                                className={classes.itemcenter}
                                            >
                                                <Button
                                                    component="span"
                                                    className={classes.addbutton}
                                                >
                                                    <Attachment/>
                                                    <p className={classes.addTitle}>Document</p>
                                                </Button>
                                            </label>
                                        </div>}

                                </div>
                            </Toolbar>
                        </AppBar>
                    }

                </CardContent>

            </Card>

        </Grid>;
    }
}


export default CreateNewsLetter
