import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cloneDeep from "lodash/cloneDeep";
import {Button, List, Avatar, Icon, Menu, Dropdown, Spin, Tooltip} from 'antd';
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import {TextLabelInput, getColor, getSchoolId, openNotificationWithIcon} from "../../GlobalComponents/GlobalFields";
import {createGroup} from "../../../ApiServices";
import authService from "../../../../../service/auth";
import {newUserInfoSuccess} from "../../../../../redux/actions/current-user";
import "../../school.css"
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Attendance from "../../../../../assets/images/attendance.png";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class GroupsDashboard extends React.Component {

    state = {
        staffList: [],
        selectedGroup: "all",
        searchKeys: "",
        newRoomName: "",
        is_public: false,
        is_attendance: false,
        groupList: [],
        isSaving: false,
        isLoading: true
    }

    async componentDidMount() {
        this.fetchGroupsList();
    }

    fetchGroupsList = async () => {
        const newUserResponse = await authService.getNewUserDetail();
        if(newUserResponse && newUserResponse.data) {
            this.props.onSetUserInfo(newUserResponse.data)
        }
        let { currentUser: { newUserInfo: { groupList = [] }  } } = this.props;
        groupList = (groupList || []).filter(x => !x.attributes.is_public)
        this.setState({
            groupList,
            isLoading: false
        });
    }

    getAllGroupList = () => {
        const {groupList, searchKeys} = this.state
        let dataItem = []
        if(!searchKeys){
            dataItem = cloneDeep(groupList)
        } else {
            let duplicateData = cloneDeep(groupList)
            dataItem = (duplicateData || []).filter(p => {
                const { groupname = "" } = p.attributes || {}
                return((groupname || "").toLowerCase().includes(searchKeys.toLowerCase()))
            })
        }
        dataItem.sort(function (a, b) {
            return b.id - a.id;
        });
        return dataItem
    }

    getStudentCount = (id) => {
        const { currentUser: { newUserInfo: { childList } } } = this.props || {};
        let length = 0
        childList.forEach(child => {
            if(Array.isArray(child.group_ids) && (child.group_ids || []).includes(Number(id))) {
                length += 1
            }
        })
        return length
    }

    getParentCount = (id) => {
        const { currentUser: { newUserInfo: { usersList } } } = this.props || {};
        let length = 0
        usersList.forEach(user => {
            if((user.group_ids || []).includes(Number(id))) {
                const isParent = ((((user || {}).role) || {})[getSchoolId()] || {}).parent
                if(isParent) {
                    length += 1
                }
            }
        })
        return length
    }

    getStaffCount = (id) => {
        const { currentUser: { newUserInfo: { usersList } } } = this.props || {};
        let length = 0
        usersList.forEach(user => {
            if((user.group_ids || []).includes(Number(id))) {
                const isStaff = ((((user || {}).role) || {})[getSchoolId()] || {}).teacher
                const isAdmin = ((((user || {}).role) || {})[getSchoolId()] || {}).admin
                if(isStaff || isAdmin) {
                    length += 1
                }
            }
        })
        return length
    }

    addNewClassMenu = () => {
        const { newRoomName, is_public, is_attendance, isSaving } = this.state
        return(
            <Menu className="add-class-menu">
                <div>
                    <div className="header-menu">

                        <Grid container spacing={6} className="grid-menu">
                            <Grid item xs={12} className="color-white text-center">Create New Class</Grid>
                        </Grid>

                    </div>

                    <Grid container spacing={6} className="textfield">
                        <Grid item xs={12}>
                            <TextLabelInput
                                name="newRoomName"
                                value={newRoomName}
                                onChange={(e) => this.setState({newRoomName: e.target.value})}
                                placeholder="Class Name"
                                size="default"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox
                                color="primary"
                                checked={is_public || false}
                                onChange={(e) => this.setState({is_public: e.target.checked})}
                            />
                            Public
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox
                                color="primary"
                                checked={is_attendance || false}
                                onChange={(e) => this.setState({is_attendance: e.target.checked})}
                            />
                            Attendance
                        </Grid>
                    </Grid>

                    <div className="create-class">
                        <Button
                            onClick={this.onCreateClass}
                            disabled={!newRoomName || isSaving}
                            type="primary"
                        >
                            { isSaving ? <Spin className="mr-2"/> : null } Create Class
                        </Button>
                    </div>

                </div>
            </Menu>
        )
    }

    onCreateClass = async () => {
        const { newRoomName, is_public, is_attendance } = this.state
        this.setState({
            isSaving: true
        })
        const payload = {
            attributes: {
                groupname: newRoomName || "",
                description: "",
                profile_photo: "",
                is_public,
                is_attendance
            },
            school_id: getSchoolId(),
            child_ids: [],
            user_ids: []
        }

        const response = await createGroup(payload)
        if(response && response.status === "success") {
            this.fetchGroupsList();
            setTimeout(() => {
                openNotificationWithIcon('success', response.message)
                this.setState({
                    isSaving: false,
                    newRoomName: ""
                })
            }, 500)
        } else {
            openNotificationWithIcon('error', response.message || 'Something went wrong')
            this.setState({
                isSaving: false
            })
        }
    }

    render() {
        const { classes } = this.props;
        const {searchKeys, isLoading} = this.state
        return (
            <div className="group-dashboard">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <h3 className="mb-0 color-white">Class List</h3>
                        </div>
                        <div>
                            <Dropdown overlay={this.addNewClassMenu} trigger={['click']} placement="bottomLeft" overlayClassName="footer-menu">
                                <Button size="large" >Add Class</Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={`student ${classes.root} content`}>
                    <div>
                        <div className="p-0">
                            <div className="mt-8 div-content">
                                <TextLabelInput
                                    placeholder="Search by Name"
                                    prefix={<Icon type="search" />}
                                    className="mr-4"
                                    style={{width: '225'}}
                                    value={searchKeys}
                                    onChange={(e) => this.setState({searchKeys: e.target.value})}
                                />
                                <div>{(this.getAllGroupList() || []).length} Rooms Total</div>
                            </div>
                        </div>
                        <Grid className="rounded-1rem bg-white shadow mt-8" style={{padding: 20}}>
                            <div className="student-table-header" style={{background: '#ebeff4', padding: '0px 10px'}}>
                                <List.Item>
                                    <List.Item.Meta title={"CLASS NAME"}/>
                                    <List.Item.Meta title={"CREATE MEMBERS"}/>
                                    {/*<List.Item.Meta title={"STUDENT"}/>*/}
                                    {/*<List.Item.Meta title={"PARENT"}/>*/}
                                    <List.Item.Meta title={<div className="text-right">ACTION</div>}/>
                                </List.Item>
                            </div>
                            <Grid item xs={12}>
                                <div /* className="staff-dashboard-infinite-container" */ style={{border: 'none'}}>
                                    <List
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        loading={isLoading}
                                        dataSource={this.getAllGroupList()}
                                        renderItem={(item, index) => {
                                            const { profile_photo = "", groupname = "", is_attendance = false } = item.attributes || {}
                                            return(
                                                <List.Item
                                                    // onClick={() => this.props.history.push(`/admin/groups/view/${item.groupid}`)}
                                                    onClick={() =>
                                                        this.props.history.push({
                                                            pathname: `/classes/view/${item.id}`,
                                                            state:{
                                                                details: item
                                                            }
                                                        })}
                                                >
                                                    <List.Item.Meta
                                                        className={"cursor-pointer"}
                                                        avatar={
                                                            profile_photo ?
                                                                <Avatar src={profile_photo} /> :
                                                                <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                    {`${(groupname || "").substr(0, 2).toUpperCase()}`}
                                                                </Avatar>
                                                        }
                                                        title={
                                                            <div
                                                                className="mt-3 fs-16 cursor-pointer flex"
                                                                // onClick={() => this.props.history.push(`/admin/student/view/${item.id}`)}
                                                            >
                                                                <div >{groupname}</div>
                                                                { is_attendance ?
                                                                    <div style={{marginLeft: 5}}>
                                                                        <Tooltip title="attendance enabled">
                                                                            <img src={Attendance} style={{width: 18, marginBottom: 10}} alt="attendance_enable" />
                                                                        </Tooltip>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        }
                                                    />
                                                    <List.Item.Meta
                                                        title={
                                                            <div>
                                                                {`(${this.getStudentCount(item.id)} Students, ${this.getStaffCount(item.id)} Staff)`}
                                                            </div>
                                                        }
                                                    />
                                                    <List.Item.Meta
                                                        className="text-right"
                                                        title={
                                                            <div>
                                                                <Icon type="delete" style={{ fontSize: '25px', color: '#cc2224' }} />
                                                            </div>
                                                        }
                                                    />
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

GroupsDashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(GroupsDashboard))