import React from 'react';
import {connect} from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import {Modal, Icon} from 'antd'
import {TextLabelInput} from "../../../../SchoolProfile/components/GlobalComponents/GlobalFields";
import {
    Avatar, Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import "./modal.css"


class SelectGroupModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            search: "",
            selectedGroups: []
        }
    }

    componentDidMount() {
        this.setState({
            selectedGroups: this.props.selectedGroups || []
        })
    }

    handleAssignNewGroup = groupId => {
        const { selectedGroups } = this.state;
        selectedGroups.push(groupId);
        this.setState({ selectedGroups });
    }

    handleUnAssignGroup = group => {
        let { selectedGroups } = this.state;
        selectedGroups = (selectedGroups || []).filter(x => x !== group)
        this.setState({ selectedGroups });
    };

    onGetFilteredGroupsList = () => {
        const { groupList } = this.props;
        const { search } = this.state;

        let dataArray = cloneDeep(groupList || [])

        if(search) {
            dataArray = (dataArray || []).filter(p => {
                const { groupname = "" } = p.attributes || {}
                return((groupname || "").toLowerCase().includes(search.toLowerCase()))
            })
        }

        // dataArray.sort(function (a, b) {
        //     return b.attributes.groupname - a.attributes.groupname;
        // });

        return dataArray
    }

    onSetValues = () => {
        const { onSetValues, onClose } = this.props
        onSetValues({groups: this.state.selectedGroups})
        onClose()
    }

    render() {
        const { onClose } = this.props;
        const { search, selectedGroups } = this.state;
        console.log({selectedGroups})
        return(
            <Modal
                className="group-modal"
                title="Select Groups"
                visible={true}
                onOk={this.onSetValues}
                onCancel={onClose}
                okButtonProps={{disabled: !(selectedGroups || []).length}}
            >
                <div>
                    <TextLabelInput
                        placeholder="Search By Name"
                        prefix={<Icon type="search" />}
                        className="mr-4 w-100-p"
                        // style={{width: 180}}
                        value={search}
                        onChange={(e) => this.setState({search: e.target.value})}
                    />

                    <Grid item md={12}>
                        <div>
                            <List>

                                {(this.onGetFilteredGroupsList() || []).map(group => (
                                    <ListItem
                                        key={group.id}
                                        style={{
                                            borderBottom: '0.6px solid #EFEFEF',
                                            paddingLeft: '0px',
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>{group.attributes.groupname.substring(0, 1)} </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={group.attributes.groupname} />
                                        <ListItemSecondaryAction>
                                            {!(selectedGroups || []).some(
                                                el => el === group.id,
                                            ) ? (
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Delete"
                                                    onClick={() => this.handleAssignNewGroup(group.id)}
                                                >
                                                    <AddIcon
                                                        style={{
                                                            background: '#212B9B',
                                                            borderRadius: '50%',
                                                            color: 'white',
                                                            height: '20px',
                                                            width: '20px',
                                                        }}
                                                    />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Added"
                                                    onClick={() => this.handleUnAssignGroup(group.id)}
                                                >
                                                    <CheckIcon
                                                        style={{
                                                            background: '#212B9B',
                                                            borderRadius: '50%',
                                                            padding: '2px',
                                                            color: 'white',
                                                            height: '20px',
                                                            width: '20px',
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Grid>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    groupList: state.currentUser.newUserInfo.groupList
})

export default connect(mapStateToProps, null)(SelectGroupModal)