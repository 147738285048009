import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import eventService from '../../../service/event';
import Loader from "../../../assets/jss/components/Loader";

import withDialog from "../../common/hoc/Dialog";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  textField: {
    width: "93%",
    margin: "auto 30px"
  },
  titleImage: {
    width: "100%",
    objectFit: "cover",
  },
  dialogTitle: {
    boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    padding: 0
  },
  chip: {
    margin: 5
  },
  emailContainer: {
    border: "1px solid #efefef",
    padding: "20px",
    margin: "auto 30px"
  },
  customlabel: {
    fontSize: "16px",
    padding: "20px 30px",
    color: "black"
  }
});

class Message extends Component {
  state = {
    image: "",
    startDateformat: "",
    endDateFormat: "",
    startTimeFormat: "",
    endTimeFormat: "",
    open: false,
    openReminder: false,
    isLoading: false,
    positionTop: 200, // Just so the popover can be spotted more easily
    positionLeft: 400, // Same as above
    anchorReference: 'anchorEl',
    anchorEl: '',
    attachment: [],
    value: '',
    emails: [],
    multiline: '',
    subject: '',
    selectedType: ""
  };

  // async componentWillMount() {
  //   console.log({props: this.props})
  //  await this.getEmailIds(this.props.event_id)
  //   // console.log("==================", {data})
  // }

  getEmails = (type) => {
    const { row = [] } = this.props

    let array = []
    row.forEach(x => {
      if(type === x.will_attend || !type) {
        array.push(x.email)
      }
    })

    return array
  }

  handleClose = () => {
    this.props.closeDialogAction("CLOSE_DIALOG_BOX")
  };

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value
    });
  };

  handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();
      var email = this.state.value.trim();
      if (email) {
        this.setState({
          emails: [...this.state.emails, email],
          value: ''
        });
      }
    }
  };

  handleDelete = (toBeRemoved) => {
    this.setState({
      emails: this.state.emails.filter(email => email !== toBeRemoved)
    });
  };

  handleChangeValue = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  getEmailIds = async (eventId, value) => {
      const body = { "event_id": eventId }
      if(value){
        body.users = value
      }
      const data = await eventService.getUserEmailIds(body)
      this.setState({
          isLoading: false,
          emails: data && data.emails || []
      })
  }

  handleClick =  (type) => {
      this.setState({
        // isLoading: true,
        selectedType: type
      }, /*async () => await this.getEmailIds(this.props.event_id, type) */ )
  }

  handleSendEmail = () => {
      const { emails, subject, multiline } = this.state
      const body = {
          "recipients": emails || [],
          "subject": subject,
          "html": `<html><p>${multiline}</p></html>`,
          "text": "Hello there"
      }

      this.setState({
        isLoading: true
      },async () => {
        const response = await eventService.sendEmail(body)
        if(response && response.status === "success"){
          this.props.closeDialogAction("CLOSE_DIALOG_BOX")
        }
      })
  }

  render() {
    const { classes, currentUser: { email_id = "" } } = this.props;
    const {
      multiline,
      subject,
      emails,
      isLoading,
      selectedType
    } = this.state;

    return (
      <div className={classes.root}>
        { isLoading ? <Loader/> : null }
        <DialogContent className={classes.dialogContent}>
          <div className={classes.rowC}>
            <Grid item xs={12}>
              <div className="eventDialogTitleMessage">
                <div className="titleDiv">Send Message</div>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    color="primary"
                    variant="h6"
                    className={classes.customlabel}
                  >
                    From: {email_id} ( A copy of the message will also be sent to the sender)
                </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                  <div className="rsvp-around button-section">
                    <Button variant="outlined" color="secondary" className="sendMsgbutton" value="" onClick={() => this.handleClick("")}>
                      Show Everyone
                    </Button>
                    <Button variant="outlined" color="primary" value="Yes" className="sendMsgbutton" onClick={() => this.handleClick("Yes")}>
                      Yes RSVP
                    </Button>
                    <Button variant="outlined" color="primary" value="No" className="sendMsgbutton" onClick={() => this.handleClick("No")}>
                      No RSVP
                    </Button>
                    <Button variant="outlined" color="primary" value="Maybe" className="sendMsgbutton" onClick={() => this.handleClick("Maybe")}>
                      Maybe RSVP
                    </Button>
                    <Button variant="outlined" color="primary" value="No Reply" className="sendMsgbutton" onClick={() => this.handleClick("No Reply")}>
                      Not yet Replied
                    </Button>
                  </div>
                </Grid>
              </Grid>
              <div>

                {/* <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      id="toEmailAddress"
                      className={classes.textField}
                      placeholder="Enter Email Address"
                      margin="normal"
                      variant="outlined"
                      value={this.state.value}
                      onChange={this.handleChange}
                      onKeyDown={this.handleKeyDown}
                    />
                  </Grid>
                </Grid> */}
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      className={classes.customlabel}
                    >
                      To:
                </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.emailContainer}>
                    { (this.getEmails(selectedType) || []).map(email => (
                      <Chip
                        key={email}
                        label={email}
                        color="primary"
                        variant="outlined"
                        onDelete={() => this.handleDelete(email)}
                        className={classes.chip}
                      />
                    ))}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      className={classes.customlabel}
                    >
                      Subject:
                </Typography>

                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="subject"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      value={subject}
                      name="subject"
                      onChange={this.handleChangeValue('subject')}

                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h6"
                      className={classes.customlabel}
                    >
                      Message:
                </Typography>

                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="Message"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      rowsMax="4"
                      multiline
                      name="multiline"
                      onChange={this.handleChangeValue('multiline')}
                      value={multiline}

                    />
                  </Grid>
                </Grid>
                <Grid>
                  <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                    <div className="rsvp-evenly button-section">
                      <Button
                          variant="outlined"
                          color="secondary"
                          className="yesbutton"
                          value="yes"
                          onClick={this.handleSendEmail}
                          disabled={emails && !emails.length || !subject || !multiline}
                      >
                        SEND
                      </Button>

                      <Button variant="outlined" color="primary" value="maybe" className="yesbutton" onClick={this.handleClose}>
                        CANCEL
                          </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </div>
        </DialogContent>
      </div>
    );
  }
}


Message.propTypes = {
  classes: PropTypes.object.isRequired
};

const wrapperStyle = withStyles(styles)(Message)
export default withDialog(wrapperStyle)
