import React, {useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {Auth} from 'aws-amplify'
// Check User Status
// Check Onboarding then role (expert or doctor)
// if there is no onboarding status then have to redirect to resprctive dashboard

const LoginRoute = ({ component: Component, ...rest }) => {
    const userToken = localStorage.getItem('hmUser');
    const [loginIssue, setloginIssue] = useState(null)
      function getUserSession(){
        return   Auth.currentSession().then((user)=>{
            console.log(user)
            return user
        }).catch((err)=>{
            console.log('Err->' + err)
            setloginIssue(true)
            return null
        })

    }
    useEffect( () => {
        console.log('-->' + loginIssue)
        const user =   getUserSession()
        console.log('--<' + loginIssue)

    },[{Component, ...rest }])


    return (
        <Route
            {...rest}
            render={props => {
                const { location: { pathname = "", search = "" } = {} } = props || {}
                let redirectUrl = ""
                if((pathname || "").includes("event") && search) {
                    redirectUrl = `${pathname}${search}`
                }
                return (
                    (userToken && !loginIssue?
                        <Component {...props} />
                        :
                        <Redirect to={{ pathname: '/welcome', state: { from: props.location, redirectUrl,forceLogOut : loginIssue } }} />
                    )
                )
            }
            }
        />
    );
};

export default LoginRoute