import {API} from "aws-amplify";


export const createUser = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('create_user', '', init)
    } catch (e) {

    }
}

export const updateUser = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('update_user', '', init)
    } catch (e) {

    }
}

export const deleteUser = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('delete_user', '', init)
    } catch (e) {

    }
}

export const getUserDetails = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('get_user_details', '', init)
    } catch (e) {

    }
}

export const createChild = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('create_child', '', init)
    } catch (e) {

    }
}

export const updateChild = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('update_child', '', init)
    } catch (e) {

    }
}

export const getChildInfo = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('get_child_info', '', init)
    } catch (e) {

    }
}

export const deleteChild = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('delete_child', '', init)
    } catch (e) {

    }
}

export const createGroup = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('create_group', '', init)
    } catch (e) {

    }
}

export const updateGroup = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('update_group', '', init)
    } catch (e) {

    }
}

export const getGroupList = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('get_group_list', '', init)
    } catch (e) {

    }
}

export const inviteUser = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('invite_user', '', init)
    } catch (e) {

    }
}


// Attendance //

export const getChildAttendance = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('get_child_attendance', '', init)
    } catch (e) {

    }
}

export const getStaffAttendance = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('get_staff_attendance', '', init)
    } catch (e) {

    }
}

export const createStudentCheckin = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('child_checkin', '', init)
    } catch (e) {

    }
}

export const createStudentPreCheckin = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('child_pre_checkin', '', init)
    } catch (e) {

    }
}

export const createStaffCheckin = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('staff_checkin', '', init)
    } catch (e) {

    }
}

export const createStaffPreCheckin = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('staff_pre_checkin', '', init)
    } catch (e) {

    }
}

export const getStudentCheckinData = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('get_checkin_data_by_child_id', '', init)
    } catch (e) {

    }
}

export const getStaffCheckinData = async (payload) => {
    try {
        const init = { body: payload }
        return await API.post('get_staff_checkin_data_by_staff_id', '', init)
    } catch (e) {

    }
}