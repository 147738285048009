import { Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import moment from "moment";
import { v4 } from 'uuid';
import FirebaseApiInstance from '../../service/chat';
import awsConfig from "./../../utils/aws-config"

export function finishPickPhotoviaAWS(school_id, roomkey, users, me, path, isVideo) {
    let remotefilename = ""
    let fileType = "image/jpeg"
    if (isVideo) {
        fileType = "video/mp4"
        remotefilename = 'Chat/ChatVideo_' + Date.now() + '.mp4'
    }else{
        remotefilename = 'Chat/ChatImage_' + Date.now() + '.mp4'
    }
    return async function (dispatch) {
        let file=[]
        if (isVideo) { 
            file = await Promise.all(path.map(async(newfile) => (uploadS3(newfile,newfile.name))));
        } else {
            file = await Promise.all(path.map(async(file)=>{
                const image = await resizeFile(file);
                return uploadS3(image,file.name)
            }));
        }
        file = file.map(newfile=>(getUploadS3File(newfile.key)));
        // console.log(file,path,"uploadS3file");
        if (file.length !== 0) {
            //var users = rooms[roomkey].details.users
            let messageId = 'temp-id-' + Math.round(Math.random() * 1000000000)
            if (isVideo) {
                var message = {
                    _id: messageId,
                    createdAt: new Date(),
                    orderBy: new Date().getTime(),
                    user: {
                        _id: me.id,
                        avatar: me.profile_photo,
                        name: me.display_name
                    },
                    video: file
                }
            } else {
                var message = {
                    _id: messageId,
                    createdAt: new Date(),
                    orderBy: new Date().getTime(),
                    user: {
                        _id: me.id,
                        avatar: me.profile_photo,
                        name: me.display_name
                    },
                    image: file
                }
            }
        } else {
            // Alert.alert('Error', 'Error Uploading Image !')
        }
        //console.log('MessageAhsan', message)
        message['sent'] = false
        //console.log(message,"MessageAhsan",school_id, roomkey, [message], users, me, false)
        // dispatch({type: 'ADD_PENDING_CHAT_MESSAGE', payload: {msgs: message, roomkey}})
        dispatch(FirebaseApiInstance.sendChatMessage(school_id, roomkey, [message], users, me, false)) 
    }
}

    export function getUploadS3File(key){
        // return await Storage.get(key)
        return `${awsConfig.media_url}public/${key}`;
    }
    async function uploadS3(file,filename) {
        const path = `${moment().year()}/${moment().month() + 1}/${moment().date()}/${v4()}.${filename.split('.').pop()}`;
        const response = await Storage.put(path, file, { contentType: file.type });
        // console.log(response,path,file,filename,"uploadS3file");
        return Object.assign({ key: response.key, name: filename });
    }

    const resizeFile = (file) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
        },
        'blob'
        );
    });

export function inviteUsersToChat(school_id, roomkey, selectedUsers, oldusers) {
    //console.log('In Action')
    //console.log(selectedUsers)
    //console.log(oldusers)
    return undefined;
}
