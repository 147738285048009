import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});


export const MyDocument = ({rowsRsvp, adult, children}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View >
                <View style={{ marginTop: '10', flexDirection: 'row' }}>
                    <Text
                        style={{
                            fontSize: '16',
                            width: "33%",
                            fontWeight: 'bold',
                            padding: 20,
                            borderBottom: '1px solid black',
                        }}
                        className="underLine"
                    >
                        Name
                    </Text>
                    <Text
                        style={{
                            fontSize: '16',
                            width: "33%",
                            fontWeight: 'bold',
                            padding: 20,
                            borderBottom: '1px solid black',
                        }}
                        className="underLine"
                    >
                        Email
                    </Text>
                    <Text
                        style={{
                            fontSize: '16',
                            width: "33%",
                            fontWeight: 'bold',
                            padding: 20,
                            borderBottom: '1px solid black',
                        }}
                        className="underLine"
                    >
                        Status
                    </Text>
                </View>

                { rowsRsvp && rowsRsvp.length ? rowsRsvp.map((comment, index) => {
                    adult += comment.adults
                    children += comment.children
                    return (
                        <View style={{ marginTop: '10', flexDirection: 'row' }} key={index.toString()}>
                            <Text
                                style={{
                                    fontSize: '10',
                                    width: "33%",
                                    paddingLeft: 20,
                                }}
                            >
                                {comment.first_name || ""} {comment.last_name || ""}, {comment.childName || ""}
                            </Text>
                            <Text
                                style={{
                                    fontSize: '10',
                                    width: "33%",
                                    paddingLeft: 20,
                                }}
                            >
                                {comment.email || ""}
                            </Text>
                            <Text
                                style={{
                                    fontSize: '10',
                                    width: "33%",
                                    paddingLeft: 20,
                                }}
                            >
                                Adult: {comment.adults},
                                Children: {comment.children}
                            </Text>
                        </View>

                    )
                } ) :
                    <View style={{ marginTop: '10', flexDirection: 'row' }}>
                        <Text style={{
                            fontSize: '10',
                            width: "33%",
                            paddingLeft: 20,
                        }}/>
                        <Text
                            style={{
                                fontSize: '10',
                                width: "33%",
                                paddingLeft: 20,
                            }}
                        >
                            No Record Found!
                        </Text>
                        <Text style={{
                            fontSize: '10',
                            width: "33%",
                            paddingLeft: 20,
                        }}/>
                    </View>
                }

                { rowsRsvp && rowsRsvp.length ?
                    <View style={{ marginTop: '10', flexDirection: 'row' }} >
                        <Text style={{
                            width: "33%",
                            paddingLeft: 20,
                        }}/>
                        <Text style={{
                            width: "33%",
                            paddingLeft: 20,
                        }}/>
                        <Text
                            style={{
                                fontSize: '10',
                                fontWeight: 'bold',
                                width: "33%",
                                paddingLeft: 20,
                            }}
                        >
                           Total Adult : {adult},&nbsp;
                           Total Children : {children}
                        </Text>

                    </View> : null
                }

            </View>
        </Page>
    </Document>
);