import React from 'react';
import {connect} from 'react-redux'
import {Radio, Modal, Spin, Button} from 'antd';
import {Grid, Container, Card, CardHeader} from "@material-ui/core";
import {
    getFileUrl,
    ImageUpload,
    SelectLabelInput,
    TextLabelInput,
    uploadFile,
    DateLabelInput,
    openNotificationWithIcon,
    getSchoolId, NumberLabelInput, getDays, TimeLabelInput,
} from "../../../GlobalComponents/GlobalFields";
import moment from "moment/moment";
import {
    countryOptionList,
    ethnicityList,
    relationList,
    studentStatusList,
    studentEnrollmentList
} from "../../../GlobalComponents/optionValues";
import { updateChild } from "../../../../ApiServices";
import IconButton from "@material-ui/core/IconButton";
import ClearTwoToneIcon from "@material-ui/icons/ClearTwoTone";
import _ from 'lodash'

class EditInfoModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            studentDetails: {},
            familyFiled: {
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                relation: "",
                profile_photo: '',
                isPickup: ''
            },
            scheduleFields: {
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                selectedDays: {},
                classType: 'inClass'
            },
            errors: {},
            otherRooms: [],
            isImageUploading: false,
            isSaving: false,
            scheduleList: props.scheduleList
        }
    }

    handleChangeTime = (time, key) => {
        this.setState({
            scheduleFields: {
                ...this.state.scheduleFields,
                [key]: time
            }
        })
    };


    componentWillMount() {
        const {attributes = {}, studentDetails = {}, editInfo = {}} = this.props
        let otherRooms = []
        let groupList = Object.keys(_.keyBy(this.props.currentUser.newUserInfo.groupList, 'id'))

        console.log('this.props', this.props)
        if ((studentDetails.group_ids || []).length && attributes.homeRoom) {
            otherRooms = (studentDetails.group_ids || []).filter(x => (![attributes.homeRoom].includes(x) )
                    && groupList.includes(x.toString()))
        }
        let homeRoom = homeRoom !== undefined && groupList.includes(homeRoom) ? homeRoom : ""
        console.log('homeRoom', homeRoom)
        this.setState({
            fields: attributes,
            familyFiled: editInfo,
            studentDetails,
            otherRooms,
            homeRoom
        })
    }

    onProfileChange = async (info, name) => {
        const {fields, familyFiled} = this.state
        if (info.file.status === 'uploading') {
            this.setState({isImageUploading: true})
            return;
        }

        if (info.file.status === 'done') {
            let key = await uploadFile(info.file.originFileObj);
            let imageURL = await getFileUrl(key);
            if (name === "family") {
                this.setState({
                    familyFiled: {
                        ...familyFiled,
                        profile_photo: imageURL
                    },
                    isImageUploading: false
                })
            } else {
                this.setState({
                    fields: {
                        ...fields,
                        profile_photo: imageURL
                    },
                    isImageUploading: false
                })
            }
        }
    }

    handelOnChange = (event) => {
        const {fields} = this.state
        const {name, value} = event.target
        this.setState({
            fields: {
                ...fields,
                [name]: value
            }
        })
    }

    handelOnScheduleChange = (e, type) => {
        const {
            studentFiled,
            parentFiled1,
            parentFiled2,
            familyFiled,
            medicalField,
            primaryAddress,
            scheduleFields
        } = this.state
        let values = {}
        if (type === "scheduleFields") {
            values = {
                scheduleFields: {
                    ...scheduleFields,
                    selectedDays: {
                        ...scheduleFields.selectedDays,
                        [e.target.name]: e.target.checked
                    }
                }
            }
        }
        this.setState({...values})
    }


    handelOnDateChange = (key, date, val, type) => {
        const {fields} = this.state
        let dateValues = {}
        if (type === "student") {
            dateValues = {
                fields: {
                    ...fields,
                    [key]: key === "dateOfBirth" ? date : val
                }
            }
        }
        this.setState({...dateValues})
    }

    handelSelect = (key, val, type) => {
        let select = {}
        const {fields} = this.state
        if (type === "student") {
            select = {
                fields: {
                    ...fields,
                    [key]: val
                }
            }
        }
        this.setState({...select})
    }

    onUpdateProfile = async () => {
        const {fields, studentDetails, familyFiled, otherRooms} = this.state
        const {editType} = this.props
        this.setState({
            isSaving: true
        })

        let groupIds = []
        if ((otherRooms || []).length) {
            if ((otherRooms || []).includes(fields.homeRoom)) {
                groupIds = otherRooms
            } else {
                groupIds = (otherRooms || []).concat([fields.homeRoom])
            }
        } else {
            groupIds = [fields.homeRoom]
        }

        const authUsers = studentDetails.auth_users || []
        if (editType === "family") {
            if (familyFiled.index || familyFiled.index === 0) {
                authUsers[familyFiled.index] = familyFiled
            } else {
                authUsers.push(familyFiled)
            }
        }
        const {studentDetails: {group_ids = []}} = this.props
        const removed_group_ids = (group_ids || []).filter(x => !(groupIds).includes(x))

        const payload = {
            ...studentDetails,
            auth_users: authUsers,
            attributes: fields,
            school_id: getSchoolId(),
            child_id: studentDetails.id,
            group_ids: groupIds,
            parents: (studentDetails.parents || []).map(x => x.id),
            removed_group_ids
        }

        const response = await updateChild(payload)
        if (response && response.status === "success") {
            openNotificationWithIcon('success', response.message)
            this.setState({
                isSaving: false
            })
            this.props.onUpdateProfile(payload)
        } else {
            openNotificationWithIcon('error', response.message || 'Something went wrong')
            this.setState({
                isSaving: false
            })
        }
    }

    onMedicalChange = (event) => {
        const {name, value} = event.target
        let {fields} = this.state
        this.setState({
            fields: {
                ...fields,
                medicalField: {
                    ...fields.medicalField,
                    [name]: value
                }
            }
        })
    }

    onFamilyChange = (event) => {
        const {name, value} = event.target
        let {familyFiled} = this.state
        this.setState({
            familyFiled: {
                ...familyFiled,
                [name]: value
            }
        })
    }

    onAddressChange = (event) => {
        const {name, value} = event.target
        let {fields} = this.state
        this.setState({
            fields: {
                ...fields,
                primaryAddress: {
                    ...fields.primaryAddress,
                    [name]: value
                }
            }
        })
    }

    handleRemoveAdded = (index, key) => {
        console.log(key)
        console.log(this.state)
        const array = this.state[key]

        array.splice(index, 1);
        this.setState({
            [key]: array
        })
    }

    handelOnDateChange = (key, date, val, type) => {
        const {scheduleFields, studentFiled} = this.state
        let dateValues = {}
        if (type === "student") {
            dateValues = {
                studentFiled: {
                    ...studentFiled,
                    [key]: key === "dateOfBirth" ? date : val
                }
            }
        } else if (type === "scheduleFields") {
            dateValues = {
                scheduleFields: {
                    ...scheduleFields,
                    [key]: date
                }
            }
        }
        this.setState({...dateValues})
    }

    addStudentSchedule = () => {
        const {scheduleFields, scheduleList, selectedScheduleIndex} = this.state
        console.log(this.state)
        let validationErrors = {};
        Object.keys(scheduleFields).forEach(name => {
            if (name === 'startTime' || name === 'endTime') return;
            if (!(scheduleFields && scheduleFields[name]) && name !== "endDate" && name !== "selectedDays") {
                validationErrors[name] = "Required";
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            this.setState({scheduleError: validationErrors});
            return;
        }
        console.log('scheduleList', scheduleList)
        if (selectedScheduleIndex === 0 || selectedScheduleIndex) {
            scheduleList[selectedScheduleIndex] = scheduleFields
        } else {
            scheduleList.push(scheduleFields)
        }

        this.setState({
            scheduleList,
            scheduleError: {},
            scheduleFields: {
                startDate: null,
                endDate: null,
                startTime: null,
                endTime: null,
                selectedDays: {},
                classType: 'inClass'
            },
            selectedScheduleIndex: null
        })
    }

    render() {
        const {
            isOpen,
            handleModal,
            editType,
            authUsers,
            currentUser: {newUserInfo: {groupcache: groupList = []}}
        } = this.props
        const {
            fields,
            errors,
            isImageUploading,
            isSaving,
            familyFiled,
            familyError,
            otherRooms,
            scheduleList
        } = this.state
        const {
            image,
            profile_photo,
            firstName,
            lastName,
            homeLanguage,
            dateOfBirth,
            gender,
            studentEnrollment,
            primaryLanguage,
            ethnicity,
            status,
            homeRoom,
            othersRoom,
            medicalField,
            primaryAddress
        } = fields || {}
        const {primaryDoctor, primaryDoctorContact, currentMedications, allergies} = medicalField || {}
        const {
            address1 = "",
            address2 = "",
            address3 = "",
            city = "",
            state = "",
            postalCode = "",
            country = ""
        } = primaryAddress || {}
        const {selectedScheduleIndex, scheduleFields, scheduleError} = this.state;
        const {selectedDays: {mon, tue, wed, thu, fri, sat, sun}} = scheduleFields || {}
        console.log('studentEnrollment', studentEnrollment)

        return (
            <Modal
                title="Edit Profile"
                width={750}
                visible={isOpen}
                onOk={this.onUpdateProfile}
                onCancel={() => handleModal("")}
                okText={"Save"}
                okButtonProps={isSaving ? {loading: <Spin/>} : {}}
            >
                <div>
                    <Container>
                        {
                            editType === 'student' ?
                                <>
                                    <div>
                                        <ImageUpload
                                            isImgUpload={isImageUploading}
                                            imageUrl={profile_photo || image}
                                            handleImage={this.onProfileChange}
                                        />
                                    </div>

                                    <Grid container spacing={6} style={{marginTop: 0}}>

                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="firstName"
                                                value={firstName}
                                                onChange={this.handelOnChange}
                                                placeholder="First Name"
                                                label="First Name"
                                                required
                                                error={errors && errors.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="lastName"
                                                value={lastName}
                                                onChange={this.handelOnChange}
                                                placeholder="Last Name"
                                                label="Last Name"
                                                required
                                                error={errors && errors.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DateLabelInput
                                                label="Date Of Birth"
                                                name="dateOfBirth"
                                                onChange={(date, dateString) => this.handelOnDateChange('dateOfBirth', dateString, date, 'student')}
                                                placeholder="Date Of Birth"
                                                // required
                                                format="MM/DD/YYYY"
                                                value={dateOfBirth ? moment(dateOfBirth) : null}
                                                error={errors && errors.dateOfBirth}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Gender"
                                                name="gender"
                                                onChange={(val) => this.handelSelect("gender", val, 'student')}
                                                placeholder="Select a Gender"
                                                // required
                                                options={["Male", "Female", "Other"] || []}
                                                value={gender}
                                                error={errors && errors.gender}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Status"
                                                name="status"
                                                onChange={(val) => this.handelSelect("status", val, 'student')}
                                                placeholder="Select a Status"
                                                required
                                                options={studentStatusList || []}
                                                value={status}
                                                error={errors && errors.status}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Enrollment"
                                                name="enrollment"
                                                onChange={(val) => this.handelSelect("studentEnrollment", val, 'student')}
                                                placeholder="Select an Enrollment"

                                                options={studentEnrollmentList || []}
                                                value={studentEnrollment == undefined ? studentEnrollmentList[0] : studentEnrollment}
                                                error={errors && errors.status}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="primaryLanguage"
                                                value={primaryLanguage}
                                                onChange={this.handelOnChange}
                                                placeholder="Primary language"
                                                label="Primary language"
                                                error={errors && errors.primaryLanguage}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="homeLanguage"
                                                value={homeLanguage}
                                                onChange={this.handelOnChange}
                                                placeholder="Home language"
                                                label="Home language"
                                                error={errors && errors.homeLanguage}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Ethnicity"
                                                name="ethnicity"
                                                onChange={(val) => this.handelSelect("ethnicity", val, 'student')}
                                                placeholder="Select a Ethnicity"
                                                options={ethnicityList || []}
                                                value={ethnicity}
                                                error={errors && errors.ethnicity}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Home Room"
                                                name="homeRoom"
                                                onChange={(val) => this.handelSelect("homeRoom", val, 'student')}
                                                placeholder="Select a home room"
                                                options={groupList || {}}
                                                value={homeRoom}
                                                required
                                                error={errors && errors.homeRoom}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Others Room"
                                                mode="multiple"
                                                name="othersRoom"
                                                onChange={(value) => this.setState({otherRooms: value})}
                                                placeholder="Others Room"
                                                options={groupList || {}}
                                                value={otherRooms}
                                            />
                                        </Grid>
                                    </Grid>
                                </> : null
                        }

                        {
                            editType === 'medical' ?
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="primaryDoctor"
                                            value={primaryDoctor || ""}
                                            onChange={this.onMedicalChange}
                                            placeholder="Primary Doctor Name"
                                            label="Primary Doctor Name"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NumberLabelInput
                                            name="primaryDoctorContact"
                                            value={primaryDoctorContact || ""}
                                            onChange={(value) => this.onMedicalChange({
                                                target: {
                                                    name: 'primaryDoctorContact',
                                                    value
                                                }
                                            })}
                                            placeholder="Primary Doctor Contact"
                                            label="Primary Doctor Contact"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="currentMedications"
                                            value={currentMedications || ""}
                                            onChange={this.onMedicalChange}
                                            placeholder="Current Medications"
                                            label="Current Medications"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="allergies"
                                            value={allergies || ""}
                                            onChange={this.onMedicalChange}
                                            placeholder="Allergies"
                                            label="Allergies"
                                        />
                                    </Grid>
                                </Grid> : null
                        }

                        {
                            editType === 'address' ?
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="address1"
                                            value={address1 || ""}
                                            onChange={this.onAddressChange}
                                            placeholder="Address1"
                                            label="Address 1"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="address2"
                                            value={address2 || ""}
                                            onChange={this.onAddressChange}
                                            placeholder="Address2"
                                            label="Address 2"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="address3"
                                            value={address3 || ""}
                                            onChange={this.onAddressChange}
                                            placeholder="Address3"
                                            label="Address 3"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="city"
                                            value={city || ""}
                                            onChange={this.onAddressChange}
                                            placeholder="City"
                                            label="City"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="state"
                                            value={state || ""}
                                            onChange={this.onAddressChange}
                                            placeholder="State"
                                            label="State"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="postalCode"
                                            value={postalCode || ""}
                                            onChange={this.onAddressChange}
                                            placeholder="Postal Code"
                                            label="Postal Code"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectLabelInput
                                            label="Country"
                                            name="country"
                                            onChange={(value) => this.onAddressChange({
                                                target: {
                                                    name: 'country',
                                                    value
                                                }
                                            })}
                                            placeholder="Select a country"
                                            options={countryOptionList || []}
                                            value={country || ""}
                                        />
                                    </Grid>

                                </Grid> : null
                        }
                        {
                            editType === "family" &&
                            <>
                                <div>
                                    <ImageUpload
                                        isImgUpload={isImageUploading}
                                        imageUrl={familyFiled.profile_photo || image}
                                        handleImage={(info) => this.onProfileChange(info, "family")}
                                    />
                                </div>

                                <Grid container spacing={6} style={{marginTop: 0}}>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="firstName"
                                            value={familyFiled.firstName}
                                            onChange={this.onFamilyChange}
                                            placeholder="First Name"
                                            label="First Name"
                                            // required
                                            error={familyError && familyError.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="lastName"
                                            value={familyFiled.lastName}
                                            onChange={this.onFamilyChange}
                                            placeholder="Last Name"
                                            label="Last Name"
                                            // required
                                            error={familyError && familyError.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextLabelInput
                                            name="email"
                                            value={familyFiled.email}
                                            onChange={this.onFamilyChange}
                                            placeholder="Email"
                                            label="Email"
                                            // required
                                            error={familyError && familyError.email}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NumberLabelInput
                                            name="mobile"
                                            value={familyFiled.mobile}
                                            onChange={(value) => this.onFamilyChange({target: {name: "mobile", value}})}
                                            placeholder="Mobile"
                                            label="Mobile"
                                            // required
                                            error={familyError && familyError.mobile}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectLabelInput
                                            label="Relation"
                                            name="relation"
                                            onChange={(value) => this.onFamilyChange({
                                                target: {
                                                    name: "relation",
                                                    value
                                                }
                                            })}
                                            placeholder="Select a relation"
                                            options={relationList || []}
                                            value={familyFiled.relation}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="p-card-title">
                                            <div className="multiExpLblBlk font-bold fs-14">
                                                Approved for Pickup
                                            </div>
                                            <div>
                                                <Radio.Group name="isPickup" onChange={this.onFamilyChange}
                                                             value={familyFiled.isPickup}>
                                                    <Radio value="yes">Yes</Radio>
                                                    <Radio value="no">No</Radio>
                                                </Radio.Group>
                                            </div>
                                            <span
                                                className="text-red form-control-sm fs-8">{familyError && familyError.isPickup}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {
                            editType === "schedule" &&
                            <>
                                <Grid item xs={12}>
                                    <Grid container spacing={12}>
                                        <Grid item xs={6}>
                                            <DateLabelInput
                                                label="Start date"
                                                name="startDate"
                                                onChange={(date, dateString) => this.handelOnDateChange('startDate', dateString, date, 'scheduleFields')}
                                                placeholder="Start date"
                                                // required
                                                // format="MM/DD/YYYY"
                                                value={scheduleFields && scheduleFields.startDate ? moment(scheduleFields.startDate) : null}
                                                error={scheduleError && scheduleError.startDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DateLabelInput
                                                label="End date"
                                                name="endDate"
                                                onChange={(date, dateString) => this.handelOnDateChange('endDate', dateString, date, 'scheduleFields')}
                                                placeholder="End date"
                                                format="MM/DD/YYYY"
                                                value={scheduleFields && scheduleFields.endDate ? moment(scheduleFields.endDate) : null}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TimeLabelInput
                                                label="Start Time"
                                                name="startTime"
                                                onChange={(time) => this.handleChangeTime(time, 'startTime')}
                                                placeholder="Start Time"
                                                // required
                                                value={scheduleFields && scheduleFields.startTime}
                                                error={scheduleError && scheduleError.startTime}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TimeLabelInput
                                                label="End Time"
                                                name="endTime"
                                                onChange={(time) => this.handleChangeTime(time, 'endTime')}
                                                placeholder="End Time"
                                                // required
                                                value={scheduleFields && scheduleFields.endTime}
                                                error={scheduleError && scheduleError.endTime}
                                            />
                                        </Grid>
                                    </Grid>

                                    <div className="mt-24">
                                        <Radio.Group
                                            onChange={(event) => this.handleChangeTime(event.target.value, "classType")}
                                            value={scheduleFields && scheduleFields.classType}>
                                            <Radio value={'inClass'}>In class</Radio>
                                            <Radio value={'virtual'}>Virtual</Radio>
                                        </Radio.Group>
                                    </div>

                                    <div className="w-100 mt-12">
                                        <div className="multiExpLblBlk font-bold text-10">
                                            Days of the week
                                        </div>

                                        <div className="weekDays-selector my-xl-2">
                                            <input onChange={(e) => this.handelOnScheduleChange(e, 'scheduleFields')}
                                                   type="checkbox"
                                                   name="mon"
                                                   id="weekday-mon"
                                                   className="weekday"
                                                   checked={mon || false}
                                            />
                                            <label htmlFor="weekday-mon">Mo</label>
                                            <input onChange={(e) => this.handelOnScheduleChange(e, 'scheduleFields')}
                                                   type="checkbox"
                                                   name="tue"
                                                   id="weekday-tue"
                                                   className="weekday"
                                                   checked={tue || false}
                                            />
                                            <label htmlFor="weekday-tue">Tu</label>
                                            <input onChange={(e) => this.handelOnScheduleChange(e, 'scheduleFields')}
                                                   type="checkbox"
                                                   name="wed"
                                                   id="weekday-wed"
                                                   className="weekday"
                                                   checked={wed || false}
                                            />
                                            <label htmlFor="weekday-wed">We</label>
                                            <input onChange={(e) => this.handelOnScheduleChange(e, 'scheduleFields')}
                                                   type="checkbox"
                                                   name="thu"
                                                   id="weekday-thu"
                                                   className="weekday"
                                                   checked={thu || false}
                                            />
                                            <label htmlFor="weekday-thu">Th</label>
                                            <input onChange={(e) => this.handelOnScheduleChange(e, 'scheduleFields')}
                                                   type="checkbox"
                                                   name="fri"
                                                   id="weekday-fri"
                                                   className="weekday"
                                                   checked={fri || false}
                                            />
                                            <label htmlFor="weekday-fri">Fr</label>
                                            <input onChange={(e) => this.handelOnScheduleChange(e, 'scheduleFields')}
                                                   type="checkbox"
                                                   name="sat"
                                                   id="weekday-sat"
                                                   className="weekday"
                                                   checked={sat || false}
                                            />
                                            <label htmlFor="weekday-sat">Sa</label>
                                            <input onChange={(e) => this.handelOnScheduleChange(e, 'scheduleFields')}
                                                   type="checkbox"
                                                   name="sun"
                                                   id="weekday-sun"
                                                   className="weekday"
                                                   checked={sun || false}
                                            />
                                            <label htmlFor="weekday-sun">Su</label>
                                        </div>

                                    </div>

                                    <div className="text-right">
                                        <Button className="add-button" onClick={this.addStudentSchedule}>
                                            {(selectedScheduleIndex === 0 || selectedScheduleIndex) ? "Update" : "Add"}
                                        </Button>
                                    </div>

                                </Grid>
                                <div>
                                    {(scheduleList || []).map((fields, index) => {
                                        console.log('fields', fields)
                                        let startTime = moment(fields.startTime).format('LT')
                                        let endTime = moment(fields.endTime).format('LT')
                                        return (
                                            <div key={index.toString()}>
                                                <Card className="shadow rounded-1rem mt-8">
                                                    <CardHeader
                                                        action={
                                                            <IconButton aria-label="settings"
                                                                        onClick={() => this.handleRemoveAdded(index, 'scheduleList')}>
                                                                <ClearTwoToneIcon/>
                                                            </IconButton>
                                                        }
                                                        title={
                                                            <div onClick={() =>
                                                                console.log('Clicked ', index)
                                                                //this.setState({scheduleFields: fields, selectedScheduleIndex: index})
                                                            }>

                                                                <div className="fs-16">
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={6}>
                                                                            <span
                                                                                className="fw-600">Start date:</span> {fields.startDate && moment(fields.startDate).format("MM/DD/YYYY")}
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <span
                                                                                className="fw-600">End date:</span> {fields.endDate && moment(fields.endDate).format("MM/DD/YYYY")}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div className="fs-16">
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={6}>
                                                                            <span
                                                                                className="fw-600">Start Time:</span> {startTime}
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <span
                                                                                className="fw-600">End Time:</span> {endTime}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div className="fs-16">
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={6}>
                                                                            <span
                                                                                className="fw-600">Selected Days:</span>
                                                                            {fields.selectedDays &&
                                                                            Object.keys(fields.selectedDays).map((day, i) => fields.selectedDays[day] ?
                                                                                <span key={i.toString()}
                                                                                      style={{marginRight: 5}}>{`${getDays(day)}${Object.keys(fields.selectedDays).length - 1 === i ? "" : ","}`}</span> : null)
                                                                            }
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <span
                                                                                className="fw-600">{fields.classType == "inClass" ? "In Class" : "Virtual"}</span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }
                    </Container>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser
})

export default connect(mapStateToProps, null)(EditInfoModal)