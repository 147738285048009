import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import Fab from '@material-ui/core/Fab';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import PhotoOutlinedIcon from '@material-ui/icons/PhotoOutlined';

const useStyles = makeStyles((theme) => ({
  speedDial: {
  },
}));

const actions = [
  { icon: <PhotoOutlinedIcon />, name: 'Image',accept:"image/*" },
  { icon: <VideocamOutlinedIcon />, name: 'Video',accept:"video/*" },
];

export default function AttachementButton({direction="up",open=false,hidden=false,onHiddenAction,onCloseAction,onClickAction,selectAttachFilesAction}) {
  const classes = useStyles();
  return (
      <div className="attach-button">
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon />}
          onClose={onCloseAction}
          onOpen={onClickAction}
          open={open}
          direction={direction}
          >
          {actions.map((action) => (
              <Fab size="small" onClick={onCloseAction} aria-label="add" hidden={!open} style={{backgroundColor: "#fff",marginTop:15}} color="inherit" variant="contained" component="label">
               {action.icon}
               <input
                    type="file"
                    style={{ display: "none" }}
                    accept={action.accept}
                    className="raised-button-file"
                    onChange={selectAttachFilesAction}
                    // accept="image/x-png,image/gif,image/jpeg,video/mp4,video/x-m4v,video/*"
                    // multiple
                />
             </Fab>
          ))}
        </SpeedDial>
    </div>
  );
}
