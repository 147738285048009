import { createSelector } from 'reselect';
import _ from 'lodash';
import moment from 'moment';
import { isPTAEvent } from '../../shared/enums/eventType';
import { getEventsChild } from '../../utils/utils';

const getGroupCache = state => state.currentUser.newUserInfo.groupcache;
const getUserCache = state => state.currentUser.newUserInfo.usercache;
const getChildrenCache = state => state.currentUser.newUserInfo.childrencache;
const currentUser = state => state.currentUser.newUserInfo.user;

const getEventList = state => state.event.eventList;
const getEventById = state => state.event.eventById;
const rsvpEventResponse = state => state.event.eventById;
const rsvpFilterValue = state => state.event.rsvpFilterValue;
const searchText = state => state.event.searchText;
const userRSVPEventResponseSelector = state => state.event.userRSVPEventResponse;
const getCurrentEventSelector = state => state.event.currentEventId

const findGroupName = (groupCache) => (group) => {
    // const groupName = (groupCache[group] && Object.keys(groupCache[group]).length) ? groupCache[group].GroupName : String(group)
    // const profile_photo = (groupCache[group] && Object.keys(groupCache[group]).length) ? groupCache[group].profile_photo : ''
    const { id = "", attributes: { groupname = "", profile_photo = "" } } = (groupCache || {})[group] || {}
    return {
        groupId: id || "",
        groupName: groupname || "",
        icon: profile_photo || ""
    }
}

function checkUserStatus(event, currentUser) {
    let result = {
        attend: 'Not Sure',
        children: 0,
        adult: 0
    }
    if (event.event_response.length > 0) {
        const filterUser = event.event_response.find(response => response.user === currentUser)
        if (filterUser) {
            const { user, ...rest } = filterUser
            result = { ...rest }
        }
    }
    return result;
}

function filterByResponse(res, rsvpFilterValue) {
    if (rsvpFilterValue !== 'Sent') {
        if (res.will_attend === rsvpFilterValue) {
            return res
        }
        if (rsvpFilterValue === 'No Reply' && res.will_attend !== 'Yes' && res.will_attend !== 'No' && res.will_attend !== 'Maybe') {
            return res
        }
    } else {
        return res
    }
}

function filterRow(data, text) {
    const searchString = text.toLowerCase()
    if (searchString !== '') {
        const newTableRow = data.filter(row => (
            row.name.toLowerCase().includes(searchString) ||
            row.email.toLowerCase().includes(searchString)
        ))
        return newTableRow;
    } else {
        return data;
    }
}

function convertToMoment(dates) {
    if (Array.isArray(dates)) {
        const result = dates.map(date => ({
            ...date,
            event_date: moment.utc(date.event_date).toDate(),
            slotTime: Array.isArray(date.slotTime) ?
                date.slotTime.map(slot => ({
                    ...slot,
                    start_time: moment(slot.start_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    end_time: moment(slot.end_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    spot: slot.spots || [],
                })) : [],
        }))
        return result
    }
    return []
}

const getGroupWithName = createSelector(
    [getEventList, getGroupCache, getUserCache, userRSVPEventResponseSelector],
    (eventList = [], groupCache = {}, userCache = {}, rsvpEvent = []) => {
        if (
            eventList.length > 0
            //&& rsvpEvent.length > 0
            && Object.keys(groupCache).length
            && Object.keys(userCache).length
        ) {
            // const updatedEventList = eventList.map(event => {
            //     if (isPTAEvent(event.attributes.event_type)) {
            //         return (event.parentTeacherResponse || []).map(eventSlot => {
            //             const startDateObj = {
            //                 start_date: eventSlot.event_date,
            //                 end_date: eventSlot.event_date,
            //                 start_time: eventSlot.slotTime[0].start_time,
            //                 end_time: eventSlot.slotTime[eventSlot.slotTime.length - 1].end_time
            //             }
            //             return Object.assign({}, event, startDateObj);
            //         })
            //     }
            //
            //     if (event.event_type === 4 && event.volunteerDetail) {
            //         const { volunteer, daterange, daterangetype, selectedDays, volunteertype } = event && event.volunteerDetail || {};
            //         const _daterange = daterange ? { from: moment(daterange.from).toDate(), to: moment(daterange.to).toDate() } : {};
            //
            //         return volunteer && volunteer.length && volunteer.map(eventSlot => {
            //             const length = eventSlot && eventSlot.slotTime && eventSlot.slotTime.length
            //             const startDateObj = {
            //                 start_date: eventSlot.event_date,
            //                 end_date: eventSlot.event_date,
            //                 start_time: eventSlot && eventSlot.slotTime && eventSlot.slotTime[0] && eventSlot.slotTime[0].start_time,
            //                 end_time: eventSlot && eventSlot.slotTime && eventSlot.slotTime[length - 1] && eventSlot.slotTime[length - 1].end_time
            //             }
            //
            //             return Object.assign({}, event, startDateObj, { daterange: _daterange }, { daterangetype }, { selectedDays }, { volunteertype });
            //         })
            //     }
            //     return event;
            // });

            const updatedEventList = []

            eventList.forEach(event => {
                const schedule = event.attributes.schedule
                if (isPTAEvent(event.attributes.event_type)) {

                    let arrays = []

                    Object.keys(schedule).forEach(t => {
                        let aa = {slotTime: []}
                        aa.event_date = moment(t, moment.defaultFormat).toDate() // moment(t).format()
                        Object.keys(schedule[t]).forEach(x => {
                            const c = (x || "").split("-")
                            const _time = moment(c[0], 'HH:mm')
                            const _time1 = moment(c[1], 'HH:mm')
                            aa.slotTime.push({start_time: moment(_time).format(), end_time: moment(_time1).format(), ...schedule[t][x]})
                        })
                        arrays.push(aa)
                    })
                    const dateList = (arrays || []).map(x => moment(x.event_date))
                    updatedEventList.push({
                        ...event, parentTeacherResponse: arrays,
                        start_date_time: moment.min(dateList).toISOString(),
                        end_date_time: moment.max(dateList).toISOString()
                    })
                } else {
                    updatedEventList.push(event)
                }
            })

            return _.flattenDepth(updatedEventList, 1).map(event => {
                const findRsvpEvent = rsvpEvent.length > 0 && rsvpEvent.find(item => item.event_id === event.event_id)
                const { attributes: { event_img_url = "", groups, child_ids, notify_days_before_event, attachments } } = event || {}
                const selected = (groups || []).length ? "group" : (child_ids || []).length ? "parents" : ""
                return {
                    ...event,
                    attributes: {
                        ...event.attributes,
                        groupParentSelection: selected,
                        event_img_url: [{ key: event_img_url || "1562189344301", name: 'default.jpg' }],
                        attachments: (attachments || []).map((attachment, index) => ({
                            key: attachment,
                            name: `attachment${index + 1}`
                        }))
                    },
                    // start_date: event.start_date && moment.utc(event.start_date),
                    // end_date: event.end_date && moment.utc(event.end_date),
                    start_date: event.start_date_time || "",
                    end_date: event.end_date_time || "",
                    start_time: event.start_date_time || "",
                    end_time: event.end_date_time || "",
                    volunteer: event.volunteerDetail ? convertToMoment(event.volunteerDetail.volunteer) : [],
                    parentTeacherResponse: event.parentTeacherResponse ? convertToMoment(event.parentTeacherResponse) : [],
                    user_response: findRsvpEvent ? findRsvpEvent.user_response : null,

                    event_id: event.id,
                    notifyOneDayAgo: (notify_days_before_event || []).includes(1) || false,
                    notifyTwoDayAgo: (notify_days_before_event || []).includes(2) || false,
                    notifyThreeDayAgo: (notify_days_before_event || []).includes(3) || false
                };
            });
        }
        return []

    })

const getGroupWithNameFromEventId = createSelector(
    [getEventById, getGroupCache, getUserCache],
    (eventList = [], groupCache = {}, userCache = {}) => {
        if (eventList.length > 0 && Object.keys(groupCache).length && Object.keys(userCache).length) {
            return eventList.map(event => {
                const slotTime = event.volunteerDetail ? event.volunteerDetail.volunteer[0].slotTime.map(slot => {
                    const object = { ...slot }
                    object.spot = slot.spots
                    return object
                }) : [];
                const { attributes: { event_img_url = "", groups = [], child_ids = [], notify_days_before_event = [] } } = event || {}
                const selected = (groups || []).length ? "group" : (child_ids || []).length ? "parents" : ""
                return {
                    ...event,
                    attributes: {
                        ...event.attributes,
                        groupParentSelection: selected,
                        event_img_url: [{ key: event_img_url, name: '' }],
                        attachments: event.attachments ? event.attachments.split(',').map((attachment, index) => ({
                            key: attachment,
                            name: `attachment${index + 1}`
                        })) : [],
                    },
                    // start_date: event.start_date && moment.utc(event.start_date),
                    // end_date: event.end_date && moment.utc(event.end_date),
                    //  start_time: event.start_time && moment.utc(event.start_time),
                    //  end_time: event.end_time && moment.utc(event.end_time),
                    start_date: event.start_date_time || "",
                    end_date: event.end_date_time || "",
                    start_time: event.start_date_time || "",
                    end_time: event.end_date_time || "",
                    daterange: (event.volunteerDetail && event.volunteerDetail.daterange) ? { from: moment(event.volunteerDetail.daterange.from).toDate(), to: moment(event.volunteerDetail.daterange.to).toDate() } : {},
                    daterangetype: event.volunteerDetail ? event.volunteerDetail.daterangetype : null,
                    selectedDays: event.volunteerDetail &&  event.volunteerDetail.selectedDays  ?[... event.volunteerDetail.selectedDays] : [],
                    volunteertype: event.volunteerDetail ? event.volunteerDetail.volunteertype : null,
                    volunteer: event.volunteerDetail ? convertToMoment(event.volunteerDetail.volunteer) : [],
                    slotTime: slotTime,
                    parentTeacherResponse: convertToMoment(event.parentTeacherResponse),
                    // groups: event.groups ? event.groups.filter(group => group).map(findGroupName(groupCache)) : [],
                    // parents: event.users ? event.users.split(',').map(parent => userCache[parent]) : [],
                    // img_url: [{ key: event.img_url, name: '' }],
                    // attachments: event.attachments ? event.attachments.split(',').map((attachment, index) => ({
                    //     key: attachment,
                    //     name: `attachment${index + 1}`
                    // })) : []

                    event_id: event.id,
                    notifyOneDayAgo: (notify_days_before_event || []).includes(1) || false,
                    notifyTwoDayAgo: (notify_days_before_event || []).includes(2) || false,
                    notifyThreeDayAgo: (notify_days_before_event || []).includes(3) || false
                };
            });
        }
        return []
    })

const getDataForRsvp = createSelector(
    [rsvpEventResponse, getUserCache, rsvpFilterValue, searchText],
    (event = {}, userCache = {}, rsvpFilterValue, searchText) => {
        if (Object.keys(event).length && Object.keys(userCache).length) {
            const { attributes: { invitees = {} }, event_id = "", id = "" } = event || {}

            let row = []

            invitees && Object.keys(invitees).forEach(invite => {
                const { email_id, firstName = "", lastName = "" } = userCache[invite] || {}
                const { first_name = "", last_name = "", children = "", adults, will_attend = "Not Sure" } = invitees[invite] || {}

                const first = firstName || first_name || ""
                const last = lastName || last_name || ""

                row.push({
                    ...invitees[invite],
                    id: invite,
                    event_id: event_id || id,
                    name: `${first || ""} ${last || ""}`,
                    childName: "",
                    email: email_id || "",
                    children,
                    adults,
                    will_attend: (will_attend === null || !will_attend) ? "Not Sure" : will_attend
                })
            })

            const filtered = row.filter(res => filterByResponse(res, rsvpFilterValue))
            return filterRow(filtered, searchText)
        }
        return []

    })

const getCurrentEvent = createSelector(
    [getGroupWithName, getCurrentEventSelector],
    (events = [], currentEventId = "") => {
        if (events.length > 0 && currentEventId) {
            return events.find(event => event.id.toString() === (currentEventId || "").toString())
        }
        return null
    }
)

const getEventsChilds = createSelector(
    [getEventById, getChildrenCache, currentUser],
    (event = {}, childrencache = {}, user = {}) => {

        if(event && Object.keys(event).length) {
            const { attributes: { child_ids = [], groups = [] } = {} } = event || {}

            const type = (groups || []).length ? "groups" : "childs"
            const ids = type === "groups" ? groups : child_ids

            const array = getEventsChild(childrencache, type, ids, user)

            return array || []
        }
    }
)

export const EventSelector = {
    getGroupWithName,
    getGroupWithNameFromEventId,
    getDataForRsvp,
    getCurrentEvent,
    getEventsChilds
};