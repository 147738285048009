import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Avatar, Button, Dropdown, Icon, List, message, Spin, Tag, Tooltip} from 'antd';
import { Container, Grid } from "@material-ui/core";
import {getColor, getSchoolId, ProfileAvatar} from "../../../GlobalComponents/GlobalFields";
import * as routes from "../../../../../../routes/path";
import EditProfileModal from "./EditProfileModal";
import {getStaffCheckinData, getUserDetails} from "../../../../ApiServices";
import "../../../school.css"
import "../../staff.css"
import PieChart from "react-minimal-pie-chart";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class StaffProfile extends React.Component {
    state = {
        profile_photo: "",
        isImgUpload: false,
        isOpen: false,
        isLoading: false,
        staffDetails: {},
        attendanceCount: {},
        partEdit: null
    };

    componentWillMount() {
        this.getUsersInfo()
    }

    getUsersInfo = async () => {
        const { match: { params: { id } } } = this.props;
        this.setState({
            isLoading: true
        })
        let staffDetails = {}
        const payload = {
            user_id: id,
            school_id: getSchoolId()
        }
        this.setAttendanceSummary(id)
        const response = await getUserDetails(payload)
        if(response && response.status === "success") {
            staffDetails = response.data
        } else {
            staffDetails = {}
        }
        this.setState({
            staffDetails,
            isLoading: false
        })
    }

    setAttendanceSummary = async (id) => {
        const payload = {
            school_id: getSchoolId(),
            staff_id: id
        }

        let totalDays = 0
        let absent = 0
        let tardy = 0
        let present = 0
        let virtual = 0

        const response = await getStaffCheckinData(payload)
        if(response && response.status === "success") {

            const absentArray = ["excusedabsence", "unexcusedabsence"]
            const tardyArray = ["excusedtardy", "unexcusedtardy"]

            response.data.forEach(x => {
                const array = x.attendance && Object.keys(x.attendance || {})
                const value = ((array || []).length && array[0]) || ""
                if(absentArray.includes(value)) {
                    absent++
                } else if(tardyArray.includes(value)) {
                    tardy++
                } else if(value === "in" || value === "checkedout") {
                    present++
                } else if(value === "virtual") {
                    virtual++
                }
                totalDays++
            })

        }

        this.setState({
            attendanceCount: {
                totalDays,
                absent,
                tardy,
                present,
                virtual
            }
        })
    }

    handleModal = (value) => {
        this.setState({
            isOpen: !this.state.isOpen,
            partEdit: value
        })
    }

    onUpdateProfile = (staffData) => {
        this.setState({
            staffDetails: staffData || {},
            isOpen: false,
            partEdit: null
        })
        this.getUsersInfo()
    }

    render() {
        const { classes, currentUser: { groupcache: groupList = {}, childrencache = {}, isTeacher = false } } = this.props;
        const { isOpen, staffDetails, partEdit, isLoading, attendanceCount } = this.state
        const { attributes, group_ids, role = {}, child_ids = [], id = "" } = staffDetails || {}
        const { firstName = "", lastName = "", email = "", profile_photo = "", image = "", mobile = "", notes = "", dateOfBirth = "", homeRoom = "", groups = [],
            certificationList = [], hireDate, hours = "", teacherLevel = "", primaryAddress = {} } = attributes || {}
        const { address1 = "", address2 = "", address3 = "", city = "", state = "", postalCode = "",  country = "" } = primaryAddress || {}
        const { totalDays = 0, absent = 0, present = 0, virtual = 0, tardy = 0 } = attendanceCount || {}

        let isAddressAvailable = true
        if(address1 || address2 || address3 || city || state || postalCode || country) {
            isAddressAvailable = false
        }

        let roleArray = []
        const object = role[getSchoolId()] || {}
        Object.keys(object || {}).forEach(x => {
            if(role[getSchoolId()][x]) {
                roleArray.push(x)
            }
        })

        return (
            <div className="user-profile">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <div className="back">
                                <Tooltip title={"Back"} placement="bottomLeft">
                                    <Icon
                                        type="left"
                                        className="color-white cursor-pointer"
                                        onClick={() => window.history.back()}
                                    />
                                </Tooltip>
                            </div>
                            {
                                (profile_photo || image) ?
                                    <Avatar src={profile_photo || image} size={50}/> :
                                    <Avatar size={50} style={{backgroundColor: 'white'}}>
                                        <span className="color-black">
                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                        </span>
                                    </Avatar>
                            }
                            <h3 className="mb-0 color-white ml-20">{firstName || ""} {' '} {lastName || ""}</h3>
                        </div>
                    </div>
                </div>
                <div className={`student ${classes.root} content`}>
                    {
                        isLoading ? <div className="text-center"> <Spin/> </div> :
                            <Grid container spacing={6} /* style={{marginTop: 70}} */ >
                                {
                                    isOpen &&
                                    <EditProfileModal
                                        attributes={attributes}
                                        staffDetails={staffDetails}
                                        isOpen={isOpen}
                                        handleModal={this.handleModal}
                                        onUpdateProfile={this.onUpdateProfile}
                                        partEdit={partEdit}
                                    />
                                }
                                <Grid item xs={7}>
                                    <Container className="rounded-1rem bg-white p-4 shadow">
                                        <Grid container spacing={6} style={{marginTop: 0}}>
                                            <Grid item xs={6} style={{borderRight: "1px solid #eee"}}>
                                                <div>
                                                    <Grid container spacing={3} /* className="mt-20" */ >
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">First Name :</div>
                                                                <div>{firstName || "-"}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">Last Name :</div>
                                                                <div>{lastName || "-"}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">Date Of Birth :</div>
                                                                <div>{dateOfBirth || "-"}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">Email :</div>
                                                                <div>{email || "-"}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">Mobile :</div>
                                                                <div>{mobile || "-"}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">Role :</div>
                                                                <div>{ (roleArray || []).map((x, index) => `${(x || "").charAt(0).toUpperCase() + (x || "").slice(1)}${(roleArray || []).length - 1 === index ? "" : ", "}`) }</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">Teacher Level :</div>
                                                                <div>{(teacherLevel || "").charAt(0).toUpperCase() + (teacherLevel || "").slice(1) || "-"}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="flex">
                                                                <div className="fw-600 w-100-px">Phone :</div>
                                                                <div>{mobile || "-"}</div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs className="flex">
                                                            <div className="fw-600 w-100-px">Home Room :</div>
                                                            <div className="fs-16">
                                                                <div>
                                                                    {([homeRoom || ""] || []).map((group, ind) => {
                                                                        if(groupList[group] === undefined){ return  }
                                                                        const { attributes } = (groupList && groupList[group]) || {}
                                                                        return(
                                                                            <Tag className="student-profile-tags" key={ind}>
                                                                                {attributes.groupname.replace("_", " ")}
                                                                            </Tag>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs>
                                                            <div className="fw-600">Groups :</div>
                                                            <div>
                                                                {(group_ids || []).map((group, ind) => {
                                                                    if(groupList[group] === undefined || group === homeRoom){ return  }
                                                                    return(
                                                                        <Tag className="mb-2 student-profile-tags" key={ind}>
                                                                            {groupList[group].attributes && groupList[group].attributes.groupname}
                                                                        </Tag>
                                                                    )
                                                                })}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <div>
                                                    <div className="text-right">
                                                        <Icon type="edit" theme="filled" className="edit-icon" onClick={() => this.handleModal(1)}/>
                                                    </div>
                                                    <div className="inline-flex">
                                                        <div className="fw-600">Notes</div>
                                                    </div>
                                                    <div>
                                                        <p className="text-justify">
                                                            {notes}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>
                                <br/>
                                <Grid item xs={5}>
                                    <div className="rounded-1rem bg-white p-4 shadow">
                                        <div className="text-right">
                                            <Icon type="edit" theme="filled" className="edit-icon" onClick={() => this.handleModal(2)}/>
                                        </div>
                                        <div className="fw-600">Certifications</div>
                                        {
                                            (certificationList || []).map((item, index) =>{
                                                return(
                                                    <div key={index.toString()}>
                                            <span >
                                                {item}
                                            </span>
                                                    </div>
                                                )
                                            })
                                        }
                                        <br/>
                                        <div className="fw-600">Hire Date</div>
                                        <div className="student-color">{hireDate || "-"}</div>
                                        <br/>
                                        <div className="fw-600">Professional Development Hours</div>
                                        <div className="student-color">{hours ? `${hours} hrs` : "-" }</div>
                                    </div>
                                    <br/>
                                    <div>
                                        <h4>Address</h4>
                                        <div className="rounded-1rem bg-white p-40 shadow">
                                            <Grid container spacing={6}>
                                                <Grid item xs={12}>
                                                    <div className="flex justify-between">
                                                        {
                                                            isAddressAvailable ? <div>No address available.</div> :
                                                                <div>
                                                                    <div className="flex"><div className="fw-600 w-100-px">Address 1 :</div><div>{address1 || "-"}</div></div>
                                                                    <div className="flex"><div className="fw-600 w-100-px">Address 2 :</div><div>{address2 || "-"}</div></div>
                                                                    <div className="flex"><div className="fw-600 w-100-px">Address 3 :</div><div>{address3 || "-"}</div></div>
                                                                    <div className="flex"><div className="fw-600 w-100-px">City :</div><div>{city || "-"}</div></div>
                                                                    <div className="flex"><div className="fw-600 w-100-px">State :</div><div>{state || "-"}</div></div>
                                                                    <div className="flex"><div className="fw-600 w-100-px">Postal Code :</div><div>{postalCode || "-"}</div></div>
                                                                    <div className="flex"><div className="fw-600 w-100-px">Country :</div><div>{country || "-"}</div></div>
                                                                </div>
                                                        }
                                                        <div className="text-right">
                                                            <Icon type="edit" theme="filled" className="edit-icon" onClick={() => this.handleModal(3)}/>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                </Grid>

                                <Grid item xs={7}>
                                    <h4>Children's</h4>
                                    <Container className="rounded-1rem bg-white p-40 shadow">
                                        <Grid container spacing={6}>
                                            <Grid item xs={12}>
                                                {
                                                    !!(child_ids || []).length ?
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={child_ids}
                                                            renderItem={(item, index) => {
                                                                const { profile_photo = "", firstName = "", lastName = "", status = "", id = "" } = childrencache && childrencache[item] || {}
                                                                if(!id) return <div/>;
                                                                return(
                                                                    <List.Item
                                                                        onClick={
                                                                            () => this.props.history.push(isTeacher ? `/student/view/${id}` : `/admin/student/view/${id}`)
                                                                        }
                                                                    >
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <div className="cursor-pointer">
                                                                                    {
                                                                                        profile_photo ? <Avatar src={profile_photo} aria-label="recipe" style={{backgroundColor: getColor(index)}} /> :
                                                                                            <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                                                {`${(firstName || "").charAt(0).toUpperCase()}${(lastName || "").charAt(0).toUpperCase()}`}
                                                                                            </Avatar>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            title={<div className="fs-15 cursor-pointer">{firstName} {lastName}</div>}
                                                                            description={<span className="cursor-pointer">{status}</span>}
                                                                        />
                                                                    </List.Item>
                                                                )
                                                            }}
                                                        /> :
                                                        <div>No Child's available.</div>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>

                                <Grid item xs={5}>
                                    <h4>Attendance Summary</h4>
                                    <Container className="rounded-1rem bg-white p-40 shadow">
                                        <Grid container spacing={6}>
                                            <Grid item xs={12}>
                                                <div className="flex justify-center mt-12">
                                                    <div>
                                                        <PieChart
                                                            animate={false}
                                                            animationDuration={500}
                                                            animationEasing="ease-out"
                                                            cx={50}
                                                            cy={50}
                                                            data={[
                                                                {
                                                                    color: '#3cc89c',
                                                                    title: 'One',
                                                                    value: 20
                                                                },
                                                                {
                                                                    color: '#d12323',
                                                                    title: 'Two',
                                                                    value: 20
                                                                },
                                                                {
                                                                    color: '#303998',
                                                                    title: 'Three',
                                                                    value: 60
                                                                }
                                                            ]}
                                                            label={false}
                                                            labelPosition={50}
                                                            labelStyle={{
                                                                fill: '#121212',
                                                                fontFamily: 'sans-serif',
                                                                fontSize: '5px'
                                                            }}
                                                            lengthAngle={360}
                                                            lineWidth={15}
                                                            onClick={undefined}
                                                            onMouseOut={undefined}
                                                            onMouseOver={undefined}
                                                            paddingAngle={0}
                                                            radius={50}
                                                            rounded
                                                            startAngle={0}
                                                            style={{
                                                                height: '75px',
                                                                width: '75px'
                                                            }}
                                                            viewBoxSize={[
                                                                100,
                                                                100
                                                            ]}
                                                        />
                                                    </div>
                                                    <div className="pl-8">
                                                        <div className="pb-2">
                                                            <span>Total Days - <b>{totalDays}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Absent - <b>{absent}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Present - <b>{present}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Virtual - <b>{virtual}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Tardy - <b>{tardy}</b></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h5
                                                    className="text-center mt-8 cursor-pointer fs-16"
                                                    style={{color: '#212b9b'}}
                                                    onClick={() => this.props.history.push({
                                                        pathname: isTeacher ? `/staff/attendance/${id}` : `/admin/staff/attendance/${id}`,
                                                        state: {
                                                            firstName: firstName,
                                                            lastName: lastName,
                                                            // groups: group_ids,
                                                            profile_photo: profile_photo,
                                                            id: id
                                                        }
                                                    })}
                                                >
                                                    <b>View Attendance</b>
                                                </h5>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>

                                {/*<Container>
                                    <div className="p-card-title text-center pb-3 mt-12">
                                        <Button
                                            className="review-button"
                                            onClick={() => this.props.history.push(routes.STAFF_DASHBOARD)}
                                        >
                                            Done
                                        </Button>
                                    </div>
                                </Container>*/}
                            </Grid>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

StaffProfile.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps,null)(withStyles(styles)(StaffProfile))