import { API } from 'aws-amplify';




const getDashboardTemplates =  (body) => {
    const init = {
        body
    }
    return  API.post('dashboard_templates', '', init).then(res => res);
}


const addDashboardTemplates =  (body) => {
    const init = {
        body
    }
    return  API.post('add_dashboard_template', '', init).then(res => res);
}


const addDashboardEntry =  (body) => {
    const init = {
        body
    }
    return  API.post('add_dashboard_entry', '', init).then(res => res);
}


const addDashboardGrade =  (body) => {
    const init = {
        body
    }
    return  API.post('add_dashboard_grade', '', init).then(res => res);
}


const getDashboardMarkings = (body) => {
    const init = {
        body
    }
    return  API.post('get_dashboard_markings', '', init).then(res => res);

}

const setStudentSchedule = (body) => {
    const init = {
        body
    }
    return  API.post('set_schedule', '', init).then(res => res);

}

const getAuditHistory = (body) => {
    const init = {
        body
    }
    return  API.post('audit_history', '', init).then(res => res);

}

const getFlaggedStudents = (body) => {
    const init = {
        body
    }
    return  API.post('flagged_students', '', init).then(res => res);

}

const addTask = (body) => {
    const init = {
        body
    }
    return API.post('add_task', '', init).then(res => res);
}

const getTasks = (body) => {
    const init = {
        body
    }
    return API.post('get_tasks', '', init).then(res => res);
}



const recordPlannerService = { getDashboardTemplates, addDashboardTemplates,addDashboardEntry, getDashboardMarkings, getAuditHistory, getFlaggedStudents, setStudentSchedule, addTask, addDashboardGrade, getTasks }

export default recordPlannerService;