import React, { Component, Fragment } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { Chip, Grid } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import Collapse from '@material-ui/core/Collapse';
import classnames from 'classnames'
import Card from '@material-ui/core/Card';
import TextEllipses from '../TextEllipses/TextEllipses';
import CardContent from '@material-ui/core/CardContent';
import ImagePreview from '../FeedCard/ImagePreview';
import VideoPlayer from '../VideoPlayer';
import moment from 'moment';
import PDFIcon from "@material-ui/icons/DockTwoTone";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopOverMenu from '../PopOverMenu/PopOverMenu';
import {POST_TYPE} from "../CreatePost/reduxForm/context";
import { getUserRole } from "../../utils/utils"
import awsConfig from "../../utils/aws-config"


const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(1) * 2,
    },
    mb10: {
        marginBottom: '10px'
    },
    fontBold: {
        fontWeight: 'bold',
        textTransform: 'capitalize'
    },
    card: {
        maxWidth: 450,
        width: 390,
        marginBottom: theme.spacing(1) * 2,
        borderRadius: '0px',
        padding: '10px'
    },
    cardShadow: {
        borderRadius: '15px',
        boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.05)'
    },
    label: {
        padding: '0 10px',
        height: '20px',
        borderRadius: '10px',
        border: 'solid 1px #3cc89c',
        backgroundColor: '#ffffff',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width:'50%  '
    },
    club: {
        fontSize: '12px',
        color: '#3cc89c',
        textTransform: 'capitalize'
    },
    pinnedDate: {
        fontSize: '12px',
        color: '#212b9b'
    },
    posted: {
        fontSize: '12px',
        textAlign: 'left',
        color: '#cecece'
    },
    padding: {
        padding: '16px 16px 0 16px !important',
    },
    moreBtn: {
        width: '100%',
        background: 'aliceblue',
        border: 0,
        cursor: 'pointer',
        borderRadius: '10px'
    }
});

class PinnedPost extends Component {
    state = {
        expanded: false,
    };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    handleEditPost = () => {
        const { data: obj } = this.props;
        const { attributes: { childIds = [], groupIds = [], postText = "", reviewerList = [], pinnedTopic = "", pinnedUntil = "" }, media: { images = [], videos, misc = [] } } = obj || {}
        const data = {
            postid: obj.id,
            groupids: groupIds[0],
            post: postText,
            img: [...(images || []).map(image => image[1])],
            vid: [...videos],
            misc: [...misc] || [],
            posttype: POST_TYPE[0].value,
            review_users: reviewerList,
            review_mode: !!(reviewerList || []).length,
            childids: childIds,
            draft_mode: 0,
            groupnames: '',// obj.groupnames,
            pinned_post: 1,
            pinned_topic: pinnedTopic,
            pinned_until: pinnedUntil,
            payload: obj
        }

        if (data.childids.length > 0) {
            data.posttype = POST_TYPE[2].value
        }

        this.props.formInitializeAction('editEvent', data)
        this.props.openDialogAction('EDIT_POST_FORM')
    }

    handleDeletePost = () => {
        const { data } = this.props;
        this.props.deletePostAction.request({id: data.id});
        // setTimeout(() => {
        //     this.props.loadPinPost(1)
        // },2000)
    };


    render() {
        const { classes, data, currentUser: { newUserInfo: { user = {} } }, getUserInfo, getPostTime, getGroupName } = this.props;
        const { attributes, media, post_time = "" } = data;
        const { postText = "", pinnedTopic = "", postedby = {}, pinnedUntil = "", created_by = "", groupIds = [] } = attributes || {};
        const { images = [], videos = [], misc = [] } = media || {};
        // const { user } = data
        const Images = (images || []).map(image => image[1]);
        // const videos = data.videos;
        // const misc = data && data.misc;
        const isPostOwner = getUserRole(user, "admin") || (user.id === postedby.id);
        return (
            <Grid>
                <Fragment>
                    <Card className={classnames(classes.card, classes.cardShadow)}>
                        <Grid style={{background: "rgb(155, 179, 240)", borderRadius: 10, color: "white", lineHeight: 2, marginBottom: 5, padding: "0 10px 0 10px", fontWeight: "bold"}}>
                            {pinnedTopic}
                        </Grid>
                        <Grid container justify="space-between">
                            <Grid className={classes.label}>
                                <span className={classes.club}>{getGroupName(groupIds[0] || "")}</span>
                            </Grid>
                            <Grid style={{ alignSelf: 'center', marginBottom: 25 }}>
                                <span className={classes.pinnedDate}> Pinned till {moment(pinnedUntil).format('MMM Do')}</span>
                            </Grid>
                            <Grid>
                                { isPostOwner ?
                                    <PopOverMenu
                                        icon={<MoreVertIcon/>}
                                        handleEditPost={this.handleEditPost}
                                        handleDeletePost={this.handleDeletePost}
                                    /> : null
                                }
                            </Grid>
                        </Grid>
                        <CardContent className={classes.padding}>
                            <Typography component="div" >
                                <TextEllipses lines={'1'} text={postText || pinnedTopic} />
                            </Typography>
                            {!this.state.expanded && (Images.length || videos.length || misc.length) ?
                                <button className={classes.moreBtn} onClick={this.handleExpandClick}>
                                    see media
                                </button> : null }
                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                {!!Images.length && (
                                    <ImagePreview
                                        Images={Images}
                                        data={data}
                                        currentUser={this.props.currentUser}
                                        getUserInfo={getUserInfo}
                                        getGroupName={getGroupName}
                                    />
                                )}
                                <br />
                                {!!videos.length && videos.map(video =>
                                    (<VideoPlayer
                                        key={video}
                                        posterUrl=""
                                        videoUrl={video}
                                    />))}
                                {!!videos.length && misc && misc.length ? <br/> : null}
                                {!!misc.length && misc.map((doc, index) =>
                                    (<a
                                        key={index.toString()}
                                        href={`${awsConfig.media_url}${doc.uri || doc.url || doc}`}
                                        target="_blank"
                                        style={{marginRight: 5}}
                                    >
                                        <Chip
                                            icon={<PDFIcon />}
                                            label={doc.name || doc.filename || `Doc${index+1}`}
                                            className={classes.chip}
                                            variant="outlined"
                                            style={{cursor: "pointer"}}
                                        />
                                    </a>))}
                                <button className={classes.moreBtn} onClick={this.handleExpandClick}>
                                    hide media
                                </button>
                            </Collapse>
                            <Grid container justify="space-between">
                                <span className={classes.posted}> Posted {getPostTime(post_time)}</span>
                                <span className={classes.posted} style={{ textTransform: 'capitalize' }}> by {getUserInfo(created_by, "display_name") || postedby.display_name}</span>
                            </Grid>
                        </CardContent>
                    </Card>
                </Fragment>
            </Grid>
        )
    }
}

export default withStyles(styles)(PinnedPost);