import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from "moment";

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 10
const COLN_WIDTH = (100 - COL1_WIDTH) / 2

const styles = StyleSheet.create({
    body: {
        padding: 10
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol1Header: {
        width: COL1_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeader: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol1: {
        width: COL1_WIDTH + '%',
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol: {
        width: COLN_WIDTH + "%",
        borderStyle: BORDER_STYLE,
        borderColor: BORDER_COLOR,
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: 5,
        fontSize: 12,
        fontWeight: 500
    },
    tableCell: {
        margin: 5,
        fontSize: 10
    }
});


export const PrintEventPreview = (props) => {

    const getStudentName = (child_ids) => {
        const { childList } = props
        const detail = (childList || []).find(x => ((child_ids || []).includes(x.id))) || {}
        const name = detail && Object.keys(detail).length ? `${detail.firstName || ""} ${detail.lastName || ""}` : "-"
        return name || "-"
    }

    return(
        <Document>
            <Page size="A4" style={styles.body}>
                {(props.event.parentTeacherResponse || []).map((data, index) => (
                    <View>
                        <Text key={index.toString()} style={{color: "#05b9a4", paddingBottom: 10, paddingTop: 10}}>
                            {moment(data.event_date).format("LL")}
                        </Text>
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol1Header}>
                                    <Text style={styles.tableCellHeader}>#</Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>Spots</Text>
                                </View>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>Participant</Text>
                                </View>
                            </View>
                            { (data.slotTime || []).map((slotTimeData, index) => {
                                return(
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol1}>
                                            <Text style={styles.tableCell}>{index}</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>
                                                {moment(slotTimeData.start_time).format("hh:mm a")}
                                                -
                                                {moment(slotTimeData.end_time).format("hh:mm a")}
                                            </Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>
                                                {(slotTimeData.child_ids || []).length ? getStudentName(slotTimeData.child_ids || []) : "-" }
                                            </Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                ))}
            </Page>
        </Document>
    )
} ;