import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Task from './task';
import MyTask from './mytask'
import moment from 'moment';
import AddLesson from './addLesson.jsx';
import recordPlannerService from "../../../service/recordplanner";
import {openNotificationWithIcon} from "../../SchoolProfile/components/GlobalComponents/GlobalFields";
import {Modal,  Form,
  Input,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch} from 'antd'
import AddTask from './addTask';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { Collapse } from 'antd';
import {connect} from "react-redux";

import {Typography, Grid, Button} from '@material-ui/core';

const { Option } = Select
const { Panel } = Collapse;
const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  background-color: #e8f9f7;
  height: fit-content;
  max-height: 70vh;
  margin-top: 50px; 
  scrollbar-width : 'thin';
`;
const Title = styled.h4`
  padding: 8px;
  width: 80%;
  margin: auto;
  text-align: center;
  // background-color: inherit;
`;

const StyledPanel = styled(Panel)`
  &&& 
  .ant-collapse-content {
    background: lightgrey;
    
  }
  .ant-collapse-content-box {
    padding : 0px
  }
`;

const Title2 = styled.h5`
  padding: 8px;
  width: 80%;
  margin: auto;
  // background-color: inherit;
`;
const TaskList = styled.div`
  padding: 5px;
  transition: background-color 0.2s ease;
  // background-color: ${(props) =>
    props.isDraggingOver ? 'skyblue' : 'white'};
  // background-color: skyblue;
  flex-grow: 1;
  overflow: auto;
`;
// min-height: 100px;
// max-height: 80vh;

// const Button = styled.button`
//   border: none;
//   color: #00333b;
//   background-color: inherit;
//   font-weight: 600;
// `;

// const AddLesson = styled.div``;

// const ButtonBar = styled.div`
//   display: flex;
//   flex-direction: row;
//   margin: 2%;
//   justify-content: space-between;
// `;

const randomGenerator = () =>
  (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);


// Modal + Form for "Add Lesson"
// Allows selecting multiple students and lessons, and returns that object
const MyModalForm = Form.create({ name: 'form_in_modal' })(
    class extends React.Component {

    constructor(props){
        super(props);
    }

      render() {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator } = form;

        return (

        <Modal
            title="Add Lesson"
            visible={ visible }
            onCancel={ onCancel }
            onOk={ onCreate }>
            <Form layout="vertical" name="addLessonForm">
                <Form.Item label="Lessons">
                    {getFieldDecorator('lessons')(
                        <Select
                        filterOption={(input, option) =>
                            this.props.formatSearchString(option.props.children).indexOf(input.toLowerCase()) >= 0 ||
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        allowClear
                        placeholder={'Select Lessons'}
                        style={{width: '100%'}}
                        >
                            {this.props.getAllLessons}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                label="Students"
                rules={[
                    {
                        required: true,
                        message: 'Please select Students',
                        type: 'array',
                    },
                ]}
                >
                    {getFieldDecorator('students')(
                        <Select
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        allowClear
                        placeholder={'Select Students'}
                        style={{width: '100%'}}
                        >
                            {this.props.getAllStudents}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        </Modal>
        );
      }
    },
  );

class Column extends React.Component {
  state = {
    addLesson: {
      show: false,
      topicSelected: false,
      columnId: '',
    },
    tags: [],
    addTask: {
      show: false,
      columnId: '',
      title : '',
      description: ''
    },
    lessonText: '',
    selectedDays: [
      moment(this.props.selectedDays[0]).format('LL'),
      moment(this.props.selectedDays[1]).format('LL'),
      moment(this.props.selectedDays[2]).format('LL'),
      moment(this.props.selectedDays[3]).format('LL'),
      moment(this.props.selectedDays[4]).format('LL'),
      moment(this.props.selectedDays[5]).format('LL'),
      moment(this.props.selectedDays[6]).format('LL'),
    ],
    modalVisible: false,
  };
  onLessonAdd = (e) => {
    this.setState({
      ...this.state,
      addLesson: { ...this.state.addLesson, show: true },
    });
    // alert('add lesson');
  };

  onCancel = () => {
    this.setState({
      ...this.state,
      addLesson: { ...this.state.addLesson, show: false },
      addTask: { ...this.state.addTask, show: false },
      lessonText: '',
      tags: []
    });
  };

  enterKeyFunc = (e) => {
    if (e.keyCode === 13) {
      this.addLesson();
    }
  };

  addLesson = () => {
    
    this.props.tasks.push({
      id: randomGenerator(),
      content: this.state.lessonText,
      students: this.state.tags,
      type: 'lesson',
    });
    console.log(this.props.tasks)
    this.setState({
      ...this.state,
      addLesson: { ...this.state.addLesson, show: false },
      lessonText: '',
      tags: []
    });
  };

  async addTask(){

   console.log(this.state.addTask)
    let body = {
      "school_id" : this.props.school_id[0],
      "attributes": {
        "title": this.state.addTask.title,
        "description" : this.state.addTask.description,
      },
      "userid": [
       this.props.userInfo.user.id
      ],
      "group_id": [
       this.props.groupId
      ],
      "taskdate": [
      this.props.coldate
      ]
    }
    console.log('props', this.props)
    console.log('body', body)
    recordPlannerService.addTask(body).then((ret)=>{
      console.log(ret)
      if (ret.status === 'success'){
        openNotificationWithIcon('success', 'Task Added Successfully !')
      }else{
        openNotificationWithIcon('error', 'Error Adding Task !')

      }
      this.clearAddTask()

    })

  };

  onTaskAdd = (e) => {
    this.setState({
      ...this.state,
      addTask: { ...this.state.addTask, show: true },
    });
    // alert('add lesson');
  };

  onTagsAdd = (e) => {
    console.log(e);
    this.setState((prev) => ({
      ...prev,
      tags: [...e],
    }));
  };

  handleTextChange = (e) => {
    this.setState({ ...this.state, lessonText: e.target.value });
  };

  onRightClick = (e) => {};


    showModal = () => {
        this.setState({ modalVisible: true });
    };

    handleCancel = () => {
        this.setState({ modalVisible: false });
    };

    handleCreate = () => {
        const { form } = this.formRef.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            var data = [];
            var lessons = values.lessons;
            var students = values.students;

            for (var lesson_num in lessons) {
                var lesson_key = lessons[lesson_num];
                for (var student_num in students) {
                    var rating;
                    var student_key = students[student_num]
                    if (this.props.studentRatingHash[lesson_key] === undefined || this.props.studentRatingHash[lesson_key][student_key] === undefined) {
                        rating = "-";
                    }
                    else {
                        rating = this.props.studentRatingHash[lesson_key][student_key];
                    }
                    let activedates = (this.props.studentDateHash[lesson_key] === undefined || this.props.studentDateHash[lesson_key][student_key] == undefined) ? [] : this.props.studentDateHash[lesson_key][student_key];
                    if (this.props.coldate === null) {
                        activedates.push("UNPLANNED")
                    }
                    else {
                        activedates.push(this.props.coldate);
                    }
                    var entry = {
                        "school_id": this.props.school_id[0],
                        "dashboard_id": this.props.lessonsHash[lesson_key]['dashboard_id'],
                        "child_id": student_key,
                        "skill_id": lesson_key,
                        "flag": 1,
                        "rating": rating,
                        "dates": activedates,
                        "comment": "",
                        "group_id" :  this.props.groupId
                    }
                    data.push(entry)
                }
            }

            recordPlannerService.addDashboardEntry(data).then((ret)=>{
                if (ret.status === 'success') {
                    form.resetFields();
                    this.setState({ modalVisible: false });
                    this.props.refreshData();
                } else {
                    alert('Error processing. Please try again.');
                    console.log('handleCreate(): ERROR adding, ret: ', ret);
                }
            })
        });
    };

    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    // Take lessonsHash (from Plan.jsx) and generate <Option> objects to be shown in the multi-selector (within "Add Lesson" modal)
    getAllLessons = () => {
        var allLessons = [];

        for (var key in this.props.lessonsHash) {
            var good = true;

            // Ignore lessons that already exist
            for (var tmp in this.props.tasks) {
                if (key === this.props.tasks[tmp].id) {
                    good = false;
                    break;
                }
            }

            // Gray out lessons that don't exist for this day
            if (good) {
                allLessons.push(<Option key={key}>{this.props.lessonsHash[key]['formatted_topic']}</Option>);
            }
            else {
                allLessons.push(<Option key={key} disabled>{this.props.lessonsHash[key]['formatted_topic']}</Option>);
            }
        }
        return allLessons;
    };

    // Format input string - remove special characters and extra spaces, then lowercase
    // eg. Converts "A > B > C" to "A B C"
    formatSearchString = (inputStr) => {
        return inputStr.replace(/[^\w ]+/ig, '').replace(/[ ]+/ig, ' ').toLowerCase();
    };

  render() {
    function getCompletedTasks() {
      return this.props.completedTasks.map((task, index) => (
          <Task
              key={task.id}
              task={task}
              index={index}
              isCompleted={true}
              studentDateHash={this.props.studentDateHash}
              studentCompleteDateHash={this.props.studentCompleteDateHash}
              studentRatingHash={this.props.studentRatingHash}
              mycoldate={this.props.column.coldate}
              refreshData={() => this.props.refreshData()}
              onRightClick={this.onRightClick}
              onDelete={this.props.onDelete}
              tags={this.state.tags}
          />
      ));
    }

    var alltasks = this.props.tasks
    console.log('this.props.column.id',this.props.column.id)
    return (
      <Container >
        <Title>{this.props.column.title}</Title>
        <div style={{
            textAlign : 'center'
        }}>
          {this.props.column.title2}</div>

        <Droppable droppableId={this.props.column.id} className='z-index-100' >
          {(provided, snapshot) => (
            <>
              <TaskList
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}>
                {alltasks.map((task, index) => (
                  <Task
                    key={task.id}
                    task={task}
                    index={index}
                    column_id={this.props.column.id}
                    isCompleted={false}
                    studentCompleteDateHash={this.props.studentCompleteDateHash}
                    studentDateHash={this.props.studentDateHash}
                    studentRatingHash={this.props.studentRatingHash}
                    mycoldate={this.props.column.coldate}
                    refreshData={()=> this.props.refreshData()}
                    onRightClick={this.onRightClick}
                    onDelete={this.props.onDelete}
                    tags={this.state.tags}
                  />
                ))}
                {provided.placeholder}
              </TaskList>

        {/* "+ Add Lesson" button and Modal/Form */}



              {/*    <ButtonBar>*/}

              {/*  <Button onClick={this.onTaskAdd}>Add Task</Button>*/}
              {/*</ButtonBar>*/}
                <>
                  {/*{this.state.addLesson.show && (*/}
                  {/*  <AddLesson*/}
                  {/*    addLesson={this.addLesson}*/}
                  {/*    onCancel={this.onCancel}*/}
                  {/*    handleTextChange={this.handleTextChange}*/}
                  {/*    enterKeyFunc={this.enterKeyFunc}*/}
                  {/*    onTagsAdd={this.onTagsAdd}*/}
                  {/*  />*/}
                  {/*  // 	<textarea*/}
                  {/*  // 		rows='2'*/}
                  {/*  // 		cols='39'*/}
                  {/*  // 		onChange={this.handleTextChange}*/}
                  {/*  // 		onKeyDown={this.enterKeyFunc}*/}
                  {/*  // 	></textarea>*/}
                  {/*  // 	<Button onClick={this.addLesson}>Add lesson</Button>*/}
                  {/*  // 	<Button onClick={this.onCancel}> X </Button>*/}
                  {/*  // </AddLesson>*/}
                  {/*)}*/}
                  {/* <Modal title="Add Task" visible={this.state.addTask.show} onOk={()=>{this.addTask()}} onCancel={()=> {
                    this.setState({addTask : {show : false}})
                  }}>
                    <Form
                        labelCol={{span: 6, offset: 1}}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                    >
                      <Form.Item label="Title">
                        <Input value={this.state.addTask.title}
                               onChange={(e)=> this.setState({ addTask : {...this.state.addTask, title : e.target.value }})}
                               autoFocus={true} />
                      </Form.Item>
                      <Form.Item label="Description">
                        <Input.TextArea value={this.state.addTask.description} rows={5} onChange={(e)=> this.setState({ addTask : {...this.state.addTask, description : e.target.value }})}  />
                      </Form.Item>
                    </Form>
                  </Modal> */}
                  {/*{this.state.addTask.show && (*/}
                  {/*  <AddTask*/}
                  {/*    addTask={this.addTask}*/}
                  {/*    onCancel={this.onCancel}*/}
                  {/*    handleTextChange={this.handleTextChange}*/}
                  {/*    enterKeyFunc={this.enterKeyFunc}*/}
                  {/*  />*/}
                  {/*  // 	<textarea*/}
                  {/*  // 		rows='2'*/}
                  {/*  // 		cols='39'*/}
                  {/*  // 		onChange={this.handleTextChange}*/}
                  {/*  // 		onKeyDown={this.enterKeyFunc}*/}
                  {/*  // 	></textarea>*/}
                  {/*  // 	<Button onClick={this.addLesson}>Add Task</Button>*/}
                  {/*  // 	<Button onClick={this.onCancel}> X </Button>*/}
                  {/*  // </AddLesson>*/}
                  {/*)}*/}
                </>

            </>
          )}

        </Droppable>

          <Collapse  style={{backgroundColor : 'lightgray'}} ghost>
              {this.props.completedTasks.length > 0 &&
                  <StyledPanel style={{backgroundColor : 'lightgray'}} header={"Completed (" +this.props.completedTasks.length + ")"}  key="1">

                      <TaskList style={{backgroundColor : this.props.completedTasks.length > 0 ? 'lightgray' : '#e8f9f7'}}>
                          {getCompletedTasks.call(this)}
                      </TaskList>
                  </StyledPanel>}
          </Collapse>
          <Grid container direction="row" spacing={2}>
              <Grid xs={12} item style={{ backgroundColor: '#F5F5F5'}} className='m-2'>
                  <Button
                      color="primary"
                      type="primary"
                      onClick={this.showModal}
                  >
                      <AddIcon fontSize="medium" />
                      <Typography
                          style={{ fontSize: '12px', fontWeight: 'bold' }}
                      >
                          Add Lesson
                      </Typography>
                  </Button>

                  <MyModalForm
                      getAllLessons={this.getAllLessons()}
                      getAllStudents={this.props.formattedStudents}
                      formatSearchString={(inputStr) => this.formatSearchString(inputStr)}
                      wrappedComponentRef={this.saveFormRef}
                      visible={this.state.modalVisible}
                      onCancel={this.handleCancel}
                      onCreate={this.handleCreate}
                  />
              </Grid>
          </Grid>
      </Container>
    );
  }

  clearAddTask() {
    this.setState({addTask : {show : false}})

  }
}


const mapStateToProps = state => ({
  school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
  userInfo : state.currentUser.newUserInfo
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Column)
