import React from "react";
import { makeStyles } from "@material-ui/styles"
import { reduxForm } from 'redux-form';
import PostForm from "../CreatePost/PostForm";

import Dialog from '@material-ui/core/Dialog';
import { POST_TYPE } from "../CreatePost/reduxForm/context";


const validate = values => {
    const errors = {}
    const requiredFields = [
        'groupids'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

const style = makeStyles(theme => ({
    paper: {
        borderRadius: '10px'
    }
}))

function UpdatePost(props) {
    const classes = style();
    const { closeDialogAction, dialogProps, ...other } = props;
    const { open, typeOfDialog } = dialogProps

    function handleFormSubmit(value) {
        const {
            postid,
            groupids,
            posttype,
            post = "",
            posttime = "",
            img = [],
            vid = [],
            review_users = [],
            childids=[],
            misc = [],
            pinned_post,
            pinned_mode = "",
            payload
        } = value;
        // const _img = img.length > 0 ? img.map(image => ({ normal: image })) : []

        // const body = {
        //     postid,
        //     groupids: [groupids],
        //     post,
        //     img: [..._img],
        //     vid: [...vid],
        //     misc: [...misc],
        //     posttime,
        //     review_mode: 0,
        //     review_users: [],
        //     pinned_mode: 0,
        //     pinned_topic: "",
        //     pinned_until:"",
        //     draft_mode: 0,
        //     childids: [],
        // }

        let image = []

        img.forEach(x => {
            if(x.includes("public/")) {
                payload.media.images.forEach(y => {
                    if((y || []).includes(x)) {
                        image.push(y)
                    }
                })
            } else {
                image.push([`public/${x}`, `public/${x}`])
            }

        })

        const data = {
            ...payload,
            attributes: {
                ...payload.attributes,
                // inDraft: false,
                // inReview: false,
                // isPinned: false,
                childIds: childids,
                groupIds: [Number(groupids)],
                postText: post,
                // reviewerList: review_users,
                // scheduleDateTime: ""
            },
            media: {
                images: [...(image || [])],
                videos: [...(vid || []).map(x => x.includes("public/") ? x : `public/${x}`)],
                misc: [...(misc || []).map((x, index) => {
                    let uri = ""
                    if(typeof x === "string") {
                        uri = (x || "").includes("public/") ? x : `public/${x}`
                    } else {
                        uri = (x.uri || x.url || "").includes("public/") ? (x.uri || x.url) : `public/${x.uri || x.url}`
                    }
                    return {name: x.name || x.filename || `Doc${index+1}`, uri}
                })]
            },
        }

        if(pinned_mode === 1){
            data.attributes.isPinned = true
            data.attributes.pinnedTopic = value.pinned_topic || ""
            data.attributes.pinnedUntil = value.pinned_until || ""

            // body.pinned_topic = value.pinned_topic
            // body.pinned_until = value.pinned_until
            // body.pinned_post = 1
        }

        if ((review_users || []).length) {
            data.attributes.inReview = true
            data.attributes.reviewerList = review_users

            // body.review_mode = 1;
            // body.review_users = review_users;
        }

        if (posttype === POST_TYPE[1].value) {
            data.attributes.inDraft = true
            // body.draft_mode = 1;
        }
        if(posttype === POST_TYPE[2].value){
            data.attributes.childIds = childids
            // body.childids = childids
        }

        const { editPostAction } = props;
        editPostAction.request(data);
        // if(pinned_mode === 1){
        //     setTimeout(() => {
        //         props.loadPinPost(1)
        //     },2000)
        // }
    }

    function handleCloseDialog(e) {
        closeDialogAction();
    }

    return (
        <>
            {typeOfDialog === "EDIT_POST_FORM" &&
                (<Dialog
                    classes={{
                        paper: classes.paper
                    }}
                    scroll="body"
                    open={open}
                    onClose={handleCloseDialog}
                >
                    <PostForm
                        {...other}
                        handleFormSubmit={handleFormSubmit}
                    />
                </Dialog>)
            }

        </>
    );
}


const wrapperForm = reduxForm({
    form: 'editEvent',
    validate,
    destroyOnUnmount: true
})(UpdatePost)


export default wrapperForm
