import React from "react"
import {connect} from "react-redux";
import {Avatar, Table, Icon} from "antd"
import moment from "moment"
import {getColor} from "../../../../../GlobalComponents/GlobalFields";

const CURRENT_DATE = new Date()

class Schedule extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedStudent: [],
            firstDay: new Date(CURRENT_DATE.setDate(CURRENT_DATE.getDate() - CURRENT_DATE.getDay()))
        }
    }

    componentWillMount() {
        const { selectedStudent = [] } = this.props;
        this.setState({
            isLoading: false,
            selectedStudent
        })
        // this.getWeekDays()
    }

    // getWeekDays = () => {
    //     let days = []
    //
    //     for (let i = 1; i <= 7; i++) {
    //         const first = CURRENT_DATE.getDate() - CURRENT_DATE.getDay() + i
    //         const day = new Date(CURRENT_DATE.setDate(first)).toISOString().slice(0, 10)
    //         // const date = new Date(CURRENT_DATE.setDate(first))
    //         // days.push({day, date, date1: new Date(date).getDate()})
    //         // days.push({iso: new Date(CURRENT_DATE.setDate(first)).toISOString(), simple: new Date(CURRENT_DATE.setDate(first))})
    //         days.push(day)
    //     }
    //     console.log({days})
    // }

    getHeaderColumn = () => {
        const { firstDay } = this.state
        const sunDay = new Date(firstDay).getDate() // new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getDate()
        const monDay = new Date(firstDay).getDate() + 1 // new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000).getDate()
        const tueDay = new Date(firstDay).getDate() + 2 // new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000).getDate()
        const wednesDay = new Date(firstDay).getDate() + 3 // new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000).getDate()
        const thursDay = new Date(firstDay).getDate() + 4 // new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000).getDate()
        const friDay = new Date(firstDay).getDate() + 5 // new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000).getDate()
        const saturDay = new Date(firstDay).getDate() + 6 // new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).getDate()
        return [
            {
                title: '',
                render: (record, data, index) => {
                    const { profile_photo = "", firstName = "", lastName = "" } = record || {}
                    return(
                        <div className="flex align-center">
                            <div>
                                {
                                    profile_photo ?
                                        <Avatar src={profile_photo} /> :
                                        <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                        </Avatar>
                                }
                            </div>
                            <div className="ml-10">{firstName} {lastName}</div>
                        </div>
                    )
                }
            },
            {
                title: (
                    <div className="text-center">
                        <div>{sunDay}</div>
                        <div>Sunday</div>
                    </div>
                ),
                style: {textAlign: 'center'},
                render: (record, data, index) => {
                    return(
                        <div className="schedule-box">
                            <div className="fs-11"> <b>Blue</b> </div>
                            <div className="fs-11">12:55pm-3.55pm</div>
                        </div>
                    )
                }
            },
            {
                title: (
                    <div className="text-center">
                        <div>{monDay}</div>
                        <div>Monday</div>
                    </div>
                ),
                dataIndex: '',
                render: (record, data, index) => {
                    return(
                        <div className="schedule-box">
                            <div className="fs-11"> <b>Blue</b> </div>
                            <div className="fs-11">12:55pm-3.55pm</div>
                        </div>
                    )
                }
            },
            {
                title: (
                    <div className="text-center">
                        <div>{tueDay}</div>
                        <div>Tuesday</div>
                    </div>
                ),
                dataIndex: '',
                render: (record, data, index) => {
                    return(
                        <div className="schedule-box">
                            <div className="fs-11"> <b>Blue</b> </div>
                        </div>
                    )
                }
            },
            {
                title: (
                    <div className="text-center">
                        <div>{wednesDay}</div>
                        <div>Wednesday</div>
                    </div>
                ),
                dataIndex: '',
                render: (record, data, index) => {
                    return(
                        <div className="schedule-box">
                            <div className="fs-11">12:55pm-3.55pm</div>
                        </div>
                    )
                }
            },
            {
                title: (
                    <div className="text-center">
                        <div>{thursDay}</div>
                        <div>Thursday</div>
                    </div>
                ),
                dataIndex: '',
                render: (record, data, index) => {
                    return(
                        <div >

                        </div>
                    )
                }
            },
            {
                title: (
                    <div className="text-center">
                        <div>{friDay}</div>
                        <div>Friday</div>
                    </div>
                ),
                dataIndex: '',
                render: (record, data, index) => {
                    return(
                        <div className="schedule-box">
                            <div className="fs-11"> <b>Blue</b> </div>
                            <div className="fs-11">12:55pm-3.55pm</div>
                        </div>
                    )
                }
            },
            {
                title: (
                    <div className="text-center">
                        <div>{saturDay}</div>
                        <div>Saturday</div>
                    </div>
                ),
                dataIndex: '',
                render: (record, data, index) => {
                    return(
                        <div className="schedule-box">
                            <div className="fs-11"> <b>Blue</b> </div>
                            <div className="fs-11"> <b>Blue</b> </div>
                        </div>
                    )
                }
            },
        ]
    }

    render() {
        const { selectedStudent } = this.state
        return(
            <div className="class-schedule">
                {/*<div>
                    <div>
                        <Icon type="left" />
                        <Icon type="right" />
                    </div>
                </div>
                <Table
                    columns={this.getHeaderColumn()}
                    dataSource={selectedStudent || []}
                    bordered
                />*/}
                Work in Progress..
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

export default connect(mapStateToProps,null)(Schedule)