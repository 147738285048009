import React, {useState, useEffect} from 'react';
import {Link,useHistory} from 'react-router-dom';
import {Card, withStyles, Button, Container} from "@material-ui/core";
import Image from "../../assets/images/welcome/360.png"
import * as routes from "../../routes/path";
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import authService from '../../service/auth'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {
    ImageUpload,
    TextLabelInput,
    SelectLabelInput,
    DateLabelInput,
    NumberLabelInput,
    TimeLabelInput,openNotificationWithIcon
} from "../../component/SchoolProfile/components/GlobalComponents/GlobalFields"
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    fade,
} from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import AppHeader from "./appheader";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        padding: '5em'
    },
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 1000,

    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 32,
        color: '#6E75FE',
    },
    SigupActionBox: {
        flexDirection: 'column',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 50,
        alignContent: 'center',
        marginBottom: 15,

        width : 600
    },
    textField: {
       paddingBottom : 20,
        width: 600,
        borderWidth : 1,
    },
    button : {
        backgroundColor : '#6E75FE',
        alignItems: 'center',
        paddingLeft : 50,
        paddingRight : 50,
        width : 600,

        color : 'white'
    },
    label : {
        fontWeight: "bold"
    },
    OutlinedInput : {
        borderWidth: '1px',
        borderColor: 'green !important'
    }
});


const NewSchoolSignup = ({classes}) => {
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    const [password, setpassword] = useState('')
    const [email, setemail] = useState('')
    const [schoolName, setschoolName] = useState('')
    const [weburl, setweburl] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, seterrorMessage] = useState('')
    let history = useHistory()
    async function submitClick(){
        try {


            console.log('click')
            console.log('firstname->', firstName)
            let resp = await authService.createNewSchool(firstName, lastName, email, schoolName, weburl)


            if (resp.status === 'success'){
                openNotificationWithIcon('success', "Please check your email for your registration code to continue with the Registration.")
                history.push(routes.SIGN_UP_CODE)
            }
            if (resp.status === 'error') {
                setError(true)
                seterrorMessage(resp.message)
            }else{
                setError(true)

                seterrorMessage("An Unknown Error Occurred ! Please try again")
            }

        }catch (error){
            console.log(error)
            setError(true)
            seterrorMessage('Error Registering School. Please try again')
        }
    }
    function showError(){
        if (error) {
            return (
                <Alert severity="error" style={{marginBottom: 30}}>{errorMessage}</Alert>
            )
        }
    }


    function handleSubmit() {
        // your submit logic
    }
    return (
        <div>
            <AppHeader/>
            <Container className={classes.main}>

            <div className="text-center mt-2">
                <div className={classes.header}>Create an Account</div>
            </div>
            <div className={classes.SigupActionBox}>
                {showError()}
                <ValidatorForm
                    onSubmit={submitClick}
                    onError={(errors) => {
                        console.log(errors)
                    }}
                >
                    <TextValidator required id="standard-required" className={classes.textField}
                                   label="First Name" defaultValue=""
                                   value={firstName}
                                   InputProps={{
                                      className : classes.OutlinedInput

                                       }}
                                   InputLabelProps={{
                                       className: classes.label,
                                   }}
                                   fullWidth
                                   InputProps={{
                                       className : classes.OutlinedInput

                                   }}
                                   variant="outlined"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">

                                           </InputAdornment>
                                       ),
                                   }}
                                   validators={['required','isString']}
                                   onChange={(e) => setfirstName(e.target.value)}
                                   errorMessages={['Please enter your First Name', 'Numeric numbers are not allowed']}
                                  />

                <TextValidator required id="standard-required" className={classes.textField}
                           label="Last Name" defaultValue=""
                               value={lastName}
                           fullWidth
                               InputLabelProps={{
                                   className: classes.label,
                               }}
                               variant="outlined"
                           validators={['required','isString']}
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start">

                                       </InputAdornment>
                                   ),
                               }}
                           onChange={(e) => setlastName(e.target.value)}
                               errorMessages={['Please enter your Last Name']}
                           />

                    <TextValidator required
                        label="Email" id="standard-required" className={classes.textField}
                        fullWidth
                                   InputLabelProps={{
                                       className: classes.label,
                                   }}
                        onChange={(e) => setemail(e.target.value)}
                        name="email"
                                   variant="outlined"
                        value={email}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">

                                </InputAdornment>
                            ),
                        }}
                        validators={['required', 'isEmail']}
                        errorMessages={['Email field is required', 'Email is not valid']}
                    />
                    <TextValidator required id="standard-required" className={classes.textField}
                                   label="School Name" defaultValue=""
                                   fullWidth
                                   InputLabelProps={{
                                       className: classes.label,
                                   }}
                                   variant="outlined"
                                   value={schoolName}
                                   validators={['required']}
                                   onChange={(e) => setschoolName(e.target.value)}
                                   errorMessages={['Please enter your School Name']}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">

                                           </InputAdornment>
                                       ),
                                   }}/>
                    <TextValidator required id="standard-required" className={classes.textField}
                                   label="School Website (e.g. http://www.schoolname.com)" defaultValue=""
                                   fullWidth
                                   InputLabelProps={{
                                       className: classes.label,
                                   }}
                                   variant="outlined"
                                   value={weburl}
                                   validators={['required','matchRegexp:^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$']}
                                   onChange={(e) => setweburl(e.target.value)}
                                   errorMessages={['Please enter your School website', 'Invalid website address !']}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">

                                           </InputAdornment>
                                       ),
                                   }}/>
                <Button
                    className={classes.button}
                    type={'Submit'}

                >
                    Signup
                </Button>
                </ValidatorForm>
            </div>
            <div>
                By clicking Sign Up, I agree to the <a href={'https://www.360memos.com/terms'}>Terms & Conditions and Privacy Policy</a>
            </div>
        </Container>
        </div>
    );

}


export default withStyles(styles)(NewSchoolSignup);