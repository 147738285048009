import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import GeneralDetails from '../component/Event/EventForm';
import Preview from '../component/Event/EventForm/FormPreview';
import EventHead from '../component/Event/EventForm/EventHead';

import {
  addEvent,
  setStepperActiveStep,
  resetStepperActiveStep,
  handleFormInitalValue,
  getEventById,
} from '../redux/actions/event';
import { EVENT_TYPES } from '../shared/enums';
import { getEventInitialValue } from '../shared/consts/eventintialvalue';

const styles = theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
    width: '5%'
  },
  nextButton: {
    width: '5%'
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  section1: {
    marginTop: "-20px",
    height: "220px",
    backgroundColor: "#F2F2F2"
  },
  section2: {
    marginTop: "-65px",
  },
  section3: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepper: {
    backgroundColor: "#F2F2F2"
  }
});

export class AddEditRsvp extends Component {

  componentDidMount() {
    const {  handleFormInitialValue, formInitialValue = {}, match: { params: { event_id = "" } = {} } = {} } = this.props;

    if(event_id) {
      this.props.getEventById.request({ event_id, isEdit: true })
    } else {
      if (Object.keys(formInitialValue).length === 0) {
        const value = getEventInitialValue(EVENT_TYPES.RSVP_Event)
        handleFormInitialValue(value);
      }
    }
  }

  getSteps = () => {
    return ['General Details', 'Preview'];
  }

  changeStep = (activeStep) => {
    const {
      setStepperActiveStep,
      stepper
    } = this.props;
    if (activeStep < stepper.activeStep) {
      setStepperActiveStep(activeStep);
    }
  }

  getActiveStep = () => {
    const {
      stepper: { activeStep },
      setStepperActiveStep,
      formInitialValue = {},
      generalDetailsubmitForm,
      resetStepperActiveStep,
      addEvent,
    } = this.props;

    switch(activeStep) {
      case 1 || '1':
        return (
          <GeneralDetails
            setStepperActiveStep={setStepperActiveStep}
            generalDetailsubmitForm={generalDetailsubmitForm}
            nextStep={activeStep + 1}
          />
        );

      case 2 || '2':
        return (
          <Preview
            rsvp={true}
            addEvent={addEvent}
            event={formInitialValue}
            setStepperActiveStep={setStepperActiveStep}
            resetStepperActiveStep={resetStepperActiveStep}
            previousStep={activeStep - 1}
          />
        );

      default:
        return null;
    }
  }

  render() {
    const { classes, stepper, formInitialValue: { event_id = null } = {} } = this.props;
    const steps = this.getSteps();
    const { activeStep } = stepper;

    return (
      <div className={classes.root}>
        <EventHead title={event_id ? "Update RSVP Event" : "Create RSVP New Event"} subTitle="Cancel" />
        <div className={classes.section1}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                <Stepper activeStep={activeStep} alternativeLabel className="stepper">
                  {steps.map((label, index) => (
                    <Step onClick={() => this.changeStep(index+1)} key={label} className="stepperlink">
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className={classes.section2}>
          <div>
            <div>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={11}>
                  <div className={classes.instructions}>
                    {this.getActiveStep()}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.event
});



const mapDispatchToProps = dispatch => {
  return {
    generalDetailsubmitForm: () => dispatch(submit('generalDetailsForm')),
    addEvent: bindActionCreators(addEvent, dispatch),
    setStepperActiveStep: (params) => dispatch(setStepperActiveStep(params)),
    resetStepperActiveStep: () => dispatch(resetStepperActiveStep()),
    handleFormInitialValue: payload => dispatch(handleFormInitalValue(payload)),
    getEventById: bindActionCreators(getEventById, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddEditRsvp))
