import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { compose } from 'redux';
import {isArray} from "util";
import Iframe from 'react-iframe';
import {
    Button,
    Grid,
    Typography,
    withStyles,
    Table,
    CircularProgress,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Snackbar,
    Backdrop
} from '@material-ui/core';
import deepPurple from '@material-ui/core/colors/deepPurple';
import greyPurple from '@material-ui/core/colors/grey';
import { Button as ANTButton, Tooltip, Icon } from "antd";
import {
    SEMESTER_STATUS,
    SEMESTER_STATUS_COLORS,
    getSemesterStatus,
    getStatusCode,
} from '../../../../shared/semester';
import CategoryCommentAndPhotos from '../CategoryCommentAndPhotos'
import SkillMarks from '../SkillMarks'
import CheckBoxHeader from '../SkillMarks/CheckBoxHeader'
import SemesterSelection from '../SemesterSelection'
import marksService from '../service/marks'
import scaleService from '../service/scale'
import moduleService from '../service/module'
import { getCurrentSchoolId } from "../../../../utils/utils"
import "./groupDetails.css"

const ACTION_TYPES = {
    revert: 'revert',
    complete: 'complete',
    publish: 'publish',
};

const useStyles = theme => ({
    root: {
        display: 'flex',
        paddingTop: theme.spacing(2),
        // border: '1px solid gray',
    },
    title: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        fontWeight: 'bold',
    },
    subTitle: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    item: {
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    itemx: {
        // marginLeft: theme.spacing(3),
    },
    rowContainer: {
        // marginLeft: theme.spacing(3),
    },
    table: {},
    tableTitle: {
        textAlign: 'center',
        paddingBottom: theme.spacing(1),
    },
    seprator: {
        color: 'gray',
    },
    subTableTitle: {
        textAlign: 'center',
        paddingTop: theme.spacing(0),
        marginTop: -theme.spacing(1),
    },
    innerSelectedSemester: {
        padding: theme.spacing(2),
    },
    row: {
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    currentSemesterHeader: {
        color: 'white',
        backgroundColor: deepPurple[500],
    },
    notCurrentSemesterHeader: {},
    currentSemesterContainer: {
        color: 'black',
        backgroundColor: greyPurple[200],
    },
    notCurrentSemesterContainer: {},
    optionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1rem 1rem 0 2rem',
        borderTop: '1px solid lightgrey',
    },
    optionGroup: {
        display: 'flex',
    },
    optionButton: {
        marginRight: '0.5rem',
    },
    inProgressHeader: {
        color: 'white',
        backgroundColor: SEMESTER_STATUS_COLORS[SEMESTER_STATUS.progress],
    },
    commentsBox: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class GroupDetails extends Component {
    constructor(props){
        super(props)
        this.state = {
            errorFeedback: {
                open: false,
                message: '',
            },
            sliceSelDlg: false,
            isLoading: false,
            selectedSlices: [],
            commentState: '',
            allComments: [],
            allAttendances : [],
            pdfLoading: false,
            pdfDownLoading: false,
            values: {
                categories: [],
                studentMarks: [],
                scaleIndex: 0,
                moduleId: 0,
                semesters: [],
                studentScales: [],
                scaleCategoryhash: {},
                scaleCategoryArray: [],
                module: {
                    module: '',
                },
            },
            noMarksMode: false,
            categoriesList: [],
            studentGrades: [],
            finalMarks: {},
            semesterComments: {},
            preview: {
                open: false,
                data: {},
            },
            allMarks: {},
            actionType: {
                action: 'done',
                status: 1,
                message: '',
                shouldGoback: false,
            },
            groupDetails: {
                loading: false
            },
            isSaving: false,
            selectedYearSlice: []
        }
    }

    componentDidMount() {
        this.getStudentDetails()
    }

    getStudentDetails = async () => {
        const { match, currentUser } = this.props
        const { moduleId, childId } = match.params || {}

        if(moduleId && childId) {
            const groupDetails = {}
            groupDetails.studentToGetMarksDetails = {
                sdModuleId: moduleId,
                sdChildId: childId,
            }

            this.setState({
                isLoading: true
            })

            groupDetails.studentMarksDetails = {
                scales: [],
                module: {}
            }
            groupDetails.scales = []
            groupDetails.module = {}

            const scaleResponse = await scaleService.getScales()
            if(scaleResponse && scaleResponse.status === "success") {
                groupDetails.studentMarksDetails.scales = scaleResponse.data
                groupDetails.scales = scaleResponse.data
            }

            const moduleResponse = await moduleService.getModuleById(moduleId)
            if(moduleResponse && moduleResponse.status === "success") {
                groupDetails.studentMarksDetails.module = moduleResponse.data
                groupDetails.module = moduleResponse.data
            }

            groupDetails.sdModuleId = moduleId;
            groupDetails.sdChildId = childId;
            groupDetails.moduleId = moduleId;
            groupDetails.childId = childId;
            groupDetails.loading = false;
            groupDetails.error = false

            const body = {
                child_id: parseInt(childId),
                clazz_id: parseInt(moduleId),
                school_id: getCurrentSchoolId(currentUser)
            }

            const semesterResponse = await marksService.getSemesterDetails(body)

            if(semesterResponse && semesterResponse.status === "success") {
                groupDetails.studentSemesterDetails = semesterResponse.data
            } else {
                groupDetails.studentSemesterDetails = {
                    year: 0,
                    slice: 0,
                    module_detail: {
                        years: 0,
                        slices: 0
                    }
                }
            }

            const response = await marksService.getChildMarks(body);

            if(response && response.status === "success") {
                groupDetails.marks = response.data
            }

            this.setState({groupDetails, isLoading: false, selectedYearSlice: []}, () => this.setupStudentData())
        }
    }

    setupStudentData = () => {
        const { groupDetails, values } = this.state
        const { classes } = this.props
        let semesters = [];
        const categories = [];
        const studentScales = [];
        const scaleCategoryhash = {};
        const scaleCategoryArray = [];
        const { studentMarksDetails, studentSemesterDetails } = groupDetails;
        const allYears = studentMarksDetails.module.years;
        const allSlices = studentMarksDetails.module.slices;

        const currentYear = studentSemesterDetails.year;
        const currentSlice = studentSemesterDetails.slice;

        // const isBreakDownHasSkills = Object.values(
        //     studentMarksDetails.module.course_breakdown,
        // ).some(v => Object.values(v.breakdown).length > 0);

        const allMarks = this.generateInitialMarks();
        console.log('Intial Marks ', allMarks)
        this.setState({allMarks});

        // if (isBreakDownHasSkills && studentMarksDetails.module.course_breakdown) {
        if (studentMarksDetails.module.course_breakdown) {
            semesters = Array.from({ length: allYears }).map((year, yearIndex) =>
                Array.from({ length: allSlices }).map((_, index) => {
                    let isCurrent =
                        yearIndex === parseInt(currentYear, 10) - 1 &&
                        index === parseInt(currentSlice, 10) - 1;
                    // console.log('CY->' + isCurrent + " currentY->" + currentYear + ' yearIndex->' + yearIndex+ " currentSlice->" + currentSlice + ' index->' + index)
                    const currentSemesterStatus = getSemesterStatus(
                        groupDetails.studentMarksDetails.module,
                        groupDetails.childId,
                        yearIndex + 1,
                        index + 1,
                    );
                    // console.log('currentSemesterStatus->' + currentSemesterStatus)
                    // Fix for last completed isCurrent
                    if (isCurrent && currentSemesterStatus === SEMESTER_STATUS.complete) {
                        isCurrent = false;
                    }
                    let className = classes.notCurrentSemesterHeader;
                    if (isCurrent) {
                        className = classes.currentSemesterHeader;
                    } else if (currentSemesterStatus === SEMESTER_STATUS.complete) {
                        className = classes.compelteHeader;
                    } else if (currentSemesterStatus === SEMESTER_STATUS.progress) {
                        className = classes.inProgressHeader;
                    } else if (currentSemesterStatus === SEMESTER_STATUS.published) {
                        className = classes.publishedHeader;
                    } else if (currentSemesterStatus === SEMESTER_STATUS.progress) {
                        className = classes.notCurrentSemesterHeader;
                    }

                    return {
                        isCurrent,
                        className,
                        marksClassName: isCurrent
                            ? classes.currentSemesterContainer
                            : classes.notCurrentSemesterContainer,
                    };
                }),
            );

            // eslint-disable-next-line camelcase
            const { course_breakdown } = studentMarksDetails.module;

            // eslint-disable-next-line camelcase
            if (course_breakdown) {
                Object.entries(course_breakdown).map(([categoryName, value]) => {
                    let scale = { grades: [] };
                    scale = this.search('id', value.scale_id, studentMarksDetails.scales);
                    if (!this.search('id', scale.id, studentScales)) {
                        studentScales.push(scale);
                    }

                    Object.entries(value).map(([subCategory, skill]) => {
                        if(subCategory === "scale_id" || subCategory === "order_id" || subCategory === "stop_marker") {
                            return
                        }
                        const category = {
                            name: categoryName,
                            subCategory: subCategory,
                            skills: [],
                            scale,
                            scale_id: value.scale_id,
                            order_id: value.order_id,
                            subOrder_id: skill.order_id,
                        };

                        // category.skills = Object.keys(value.breakdown);
                        category.skills = Object.entries(skill).map(([skillName, marks]) => ({name: skillName, order_id: (marks || {}).order_id})).filter(x => !["order_id", "stop_marker"].includes(x.name)).sort((a, b) => a.order_id - b.order_id)
                        category.grid = category.skills.map(x => {
                            const g1 = Object.keys(scale.grades).map(mark => ({
                                mark,
                                is_selected: false,
                            }));

                            return {
                                skill: x.name,
                                order_id: x.order_id,
                                stop_marker: skill[x.name]?.stop_marker || "",
                                years: semesters.map((semestersOfYears, yearIndex) => ({
                                    yearIndex,
                                    semesters: semestersOfYears.map((s, sliceIndex) => ({
                                        ...s,
                                        category: categoryName,
                                        subCategory: subCategory,
                                        skill: x.name,
                                        mark: this.getMark(
                                            yearIndex + 1,
                                            sliceIndex + 1,
                                            categoryName,
                                            subCategory,
                                            x.name,
                                        ),
                                        year: yearIndex + 1,
                                        slice: sliceIndex + 1,
                                    })),
                                    ref: React.createRef(),
                                })),
                            };
                        }).sort((a, b) => a.order_id - b.order_id)
                        // const isNewIndex = (categories || []).findIndex(x => x.name === categoryName)
                        categories.push(category);
                    })
                });
            }
            // --------------------------
            // --------------------------
            const categories2 = categories.sort(this.compareCategoryName);
            let scaleCategoryArray2 = [];
            //-------------------------
            categories2.forEach(category => {
                // console.log(category)
                // console.log(scaleCategoryhash)
                // console.log('1-----------')
                if (!(category.scale.id in scaleCategoryhash)) {
                    scaleCategoryhash[category.scale.id] = {};
                    scaleCategoryhash[category.scale.id].name = category.name;
                    scaleCategoryhash[category.scale.id].grades =
                        category.scale.grades;
                    scaleCategoryhash[category.scale.id].id = category.scale.id;
                    scaleCategoryArray2.push(scaleCategoryhash[category.scale.id]);
                }
            });
            scaleCategoryArray2 = scaleCategoryArray2.sort(this.compareScales);
            this.setState({
                values: {
                    ...values,
                    semesters,
                    categories: categories2,
                    studentScales,
                    scaleCategoryhash,
                    scaleCategoryArray: scaleCategoryArray2,
                },
                noMarksMode: false
            });
        } else {
            // eslint-disable-next-line camelcase
            const { course_breakdown } = studentMarksDetails.module;
            let categoriesListTemp = [];
            // eslint-disable-next-line camelcase
            if (course_breakdown) {
                categoriesListTemp = Object.keys(course_breakdown).map(key => ({
                    name: key,
                }));
            }

            this.setState({
                noMarksMode: true,
                categoriesList: categoriesListTemp
            })
        }

    }

    compareScales = (a, b) => {
        // Use toUpperCase() to ignore character casing
        const skillsA = a.name.toUpperCase();
        const skillsB = b.name.toUpperCase();

        let comparison = 0;
        if (skillsA > skillsB) {
            comparison = 1;
        } else if (skillsA < skillsB) {
            comparison = -1;
        }
        return comparison;
    }

    compareCategoryName = (a, b) => a.order_id - b.order_id || a.subOrder_id - b.subOrder_id;

    search = (key, value, inputArray) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < inputArray.length; i++) {
            if (inputArray[i][key] === value) {
                return inputArray[i];
            }
        }
    };

    getMark = (year, slice, category, subCategory, skill) => {
        const { marks = [] } = this.state.groupDetails || {};

        const markDetail = marks.find(
            info => info.year === year && info.slice === slice,
        );

        return (
            markDetail &&
            markDetail.marks &&
            markDetail.marks[category] &&
            markDetail.marks[category][subCategory] &&
            markDetail.marks[category][subCategory][skill] &&
            markDetail.marks[category][subCategory][skill].name
        );
    };

    generateInitialMarks = () => {
        const { groupDetails } = this.state
        const { marks = [] } = groupDetails || {};

        const generatedAllMarks = marks.reduce(
            (result, markInfo) =>
                Object.assign(result, {
                    [markInfo.year * 100 + markInfo.slice]: {
                        year: markInfo.year,
                        slice: markInfo.slice,
                        marks: markInfo.marks
                    },
                }),
            {},
        );

        return generatedAllMarks;
    };

    renderLoading = () => (
        <Grid container justify="center" alignItems="center">
            <Grid item={12} style={{ marginTop: '150px' }}>
                <CircularProgress size={48} className={this.props.classes.buttonProgress} />
            </Grid>
        </Grid>
    );

    handleChangeMark = (
        grades,
        categoryIndex,
        gIndex,
        mark,
        year,
        slice,
        selected,
    ) => {
        const { values, allMarks, finalMarks } = this.state
        const category = values.categories[categoryIndex];

        const adjustedMarks = (allMarks[year * 100 + slice] || { marks: {} }).marks;

        // NOTE: Refelct user's selection on marks
        if (!adjustedMarks[category.name]) {
            adjustedMarks[category.name] = {};
        }

        // adjustedMarks[category.name].scale_id = category.scale_id;
        adjustedMarks[category.name].order_id = category.order_id;

        if (!adjustedMarks[category.name][category.subCategory]) {
            // adjustedMarks[category.name][category.subCategory] = {};
            adjustedMarks[category.name][category.subCategory] = { order_id: category.subOrder_id };
        }

        if (selected) {
            // adjustedMarks[category.name][category.subCategory][category.grid[gIndex].skill] =
            //     mark.mark;
            adjustedMarks[category.name][category.subCategory][category.grid[gIndex].skill] = { name: mark.mark, order_id: category.grid[gIndex].order_id }
        } else {
            // delete adjustedMarks[category.name][category.subCategory][
            //     category.grid[gIndex].skill
            //     ];
            delete adjustedMarks[category.name][category.subCategory][category.grid[gIndex].skill];
        }

        // New for multiple semesters 2019.7.21
        const marks = {
            [year * 100 + slice]: {
                year,
                slice,
                marks: adjustedMarks,
            }
        };

        this.setState({
            allMarks: {
                ...allMarks,
                ...marks
            },
            finalMarks: {
                ...finalMarks,
            },
            grades
        })
    };

    emptyOperationWithComments = (category, comment) => {
        const { allMarks, groupDetails } = this.state
        const { studentSemesterDetails } = groupDetails;

        const {year} = studentSemesterDetails;
        const {slice} = studentSemesterDetails;

        const adjustedMarks = (allMarks[year * 100 + slice] || { marks: {} }).marks;

        // NOTE: Refelct user's selection on marks
        if (!adjustedMarks[category.name]) {
            adjustedMarks[category.name] = {};
        }

        if (!adjustedMarks[category.name].comment) {
            adjustedMarks[category.name].comment = '';
        }

        // New for multiple semesters 2019.7.21
        const marks = {
            [year * 100 + slice]: {
                year,
                slice,
                marks: adjustedMarks,
            },
        };

        this.setState({
            allMarks: {
                ...allMarks,
                ...marks
            }
        })
    };

    handleCommentChange = (category, comment) => {
        this.emptyOperationWithComments(category, comment);

        this.setState({
            semesterComments: {
                ...this.state.semesterComments,
                ...{
                    [category.name]: comment,
                },
            }
        })
    };

    addCommentsandAttendanceToMarks = (marks, year, slice) => {
        const { allComments , allAttendances} = this.state
        allComments.forEach(comment => {
            for (let key in marks) {
                if (
                    key === comment.details.category &&
                    comment.details.subCategory &&
                    year === comment.details.year &&
                    slice === comment.details.semester
                ) {
                    marks[key][comment.details.subCategory].comment = [comment.comment];
                }
            }
        });
        console.log('-->',allAttendances)
        allAttendances.forEach(attendance => {
            console.log(attendance)
            for (let key in marks) {
                if (
                    key === attendance.details.category &&
                    attendance.details.subCategory &&
                    year === attendance.details.year &&
                    slice === attendance.details.semester
                ) {
                    marks[key][attendance.details.subCategory].attendance = attendance.attendance;
                }
            }
        });

        return marks;

    };

    updateMarks = async (selectedSemesters = [], actionTypes, force) => {
        const { match: { params: { childId, moduleId } }, history, currentUser } = this.props
        const { allMarks, groupDetails } = this.state
        const { marks = [] } = groupDetails || {}
        const { status, message, shouldGoback } = actionTypes;

        this.setState({
            isSaving: true
        })

        let markBlankSemester = true;

        const promises = Object.values(allMarks).map(async markInfo => {
            const mark = (marks || []).find(x => x.year === markInfo.year && x.slice === markInfo.slice) || {}
            const statusCode = ((mark || {}).status === 3 && force) ? mark.status : force ? actionTypes.status : selectedSemesters.some(
                item => (item || {}).year === markInfo.year && (item || {}).slice === markInfo.slice,
            )
                ? status
                : getStatusCode(
                    getSemesterStatus(
                        groupDetails.studentMarksDetails.module,
                        childId,
                        markInfo.year,
                        markInfo.slice,
                    ),
                );

            console.log(`Sending Marks for Year:${ markInfo.year  } Slice:${  markInfo.slice}`)
            if (
                selectedSemesters[0].year === markInfo.year &&
                selectedSemesters[0].slice === markInfo.slice
            ) {
                console.log('Sending Semester which was supposed to be saved')
                markBlankSemester = false
            } else {
                // if (
                //     selectedSemesters[0].year < markInfo.year ||
                //     (selectedSemesters[0].year <= markInfo.year &&
                //         selectedSemesters[0].slice <= markInfo.slice)
                // ) {
                //     console.log('THIS SHOULD NOT BE SAVED !!!! STOP THE MADNESS ');
                //     return;
                // }
            }
            const req = {
                marks: this.onCategoriesOrderChange(this.addCommentsandAttendanceToMarks(
                    markInfo.marks,
                    markInfo.year,
                    markInfo.slice,
                )),
                clazz_id: parseInt(moduleId, 10),
                child_id: parseInt(childId, 10),
                status: statusCode,
                slice: markInfo.slice,
                year: markInfo.year,
                school_id: getCurrentSchoolId(currentUser)
            };

            try {
                console.log(`Saving Year ${   markInfo.year  } slice ${  markInfo.slice}`, markInfo.marks)
                const updateMarkResp = await marksService.addUpdateMarks(req);
                return updateMarkResp;
            } catch (e) {
                this.setState({
                    errorFeedback: {
                        ...this.state.errorFeedback,
                        open: true,
                        message: e.message,
                    }
                })
            }

            return null;
        });
        // console.log('markBlankSemester->' + markBlankSemester)
        // console.log(status)
        // if (shouldGoback && status === 1) {
        //     setTimeout(() => {
        //         history.goBack();
        //     }, 1500);
        // }
        // ***** SEND IF SEMESTER IS BLANK !!!!!
        if (markBlankSemester) {
            console.log('Semester is  Blank !!!');
            const req = {
                marks: this.onCategoriesOrderChange(this.addCommentsandAttendanceToMarks(
                    {},
                    selectedSemesters[0].year,
                    selectedSemesters[0].slice,
                )),
                clazz_id: parseInt(moduleId, 10),
                child_id: parseInt(childId, 10),
                status: force ? 2 : actionTypes.status,
                slice: selectedSemesters[0].slice,
                year: selectedSemesters[0].year,
                school_id: getCurrentSchoolId(currentUser)
            };

            try {
                const updateMarkResp = await marksService.addUpdateMarks(req);
                return updateMarkResp;
            } catch (e) {
                this.setState({
                    errorFeedback: {
                        ...this.state.errorFeedback,
                        open: true,
                        message: e.message,
                    }
                })
            }
            // promises[0] = updateMarkResp;
        }

        const result = await Promise.all(promises);

        if (result.some(item => item && item.status === 'success')) {
            this.getStudentDetails()
            this.setState({
                errorFeedback: {
                    ...this.state.errorFeedback,
                    open: true,
                    message,
                },
                isSaving: false
            })

            // if (shouldGoback) {
            //   setTimeout(() => {
            //     history.goBack();
            //   }, 1500);
            // }
        } else {
            // throw new Error('Error occured in fetching data');
            this.setState({
                isSaving: false
            })
        }
    };

    onCategoriesOrderChange = (marks) => {
        const { values: { categories = [] } = {} } = this.state
        Object.entries(marks).map(([category, a]) => {

            Object.entries(a).map(([subCategory, b]) => {
                const index = categories.findIndex(x => x.name === category && x.subCategory === subCategory)

                if(index !== -1) {
                    marks[category][subCategory].order_id = categories[index].subOrder_id
                    marks[category][subCategory].stop_marker = ""
                    marks[category].order_id = categories[index].order_id
                    marks[category].scale_id = categories[index].scale_id

                    Object.entries(b).map(([skill, skills]) => {

                        const skillIndex = (categories[index].grid || []).findIndex(g => g.skill === skill)

                        if(skillIndex !== -1) {
                            marks[category][subCategory][skill] = {...marks[category][subCategory][skill], order_id: categories[index].grid[skillIndex].order_id, stop_marker: categories[index].grid[skillIndex].stop_marker}
                        }

                    })

                }
                return marks
            })
        })

        return marks
    }

    handleMultipleSemesterSelection = (
        selectedSemesters,
        status,
        force = false,
    ) => {
        this.setState({sliceSelDlg: false})
        // console.log('Force Update Flag->' + force)
        if ((selectedSemesters || []).length) {
            this.setState({
                actionType: {
                    ...this.state.actionType,
                    action: ACTION_TYPES.revert,
                    status,
                    message:
                        status === 1
                            ? 'Marks are reverted succesfully'
                            : 'Student marks has been marked as completed successfully !',
                    shouldGoback: false,
                }
            })

            this.updateMarks(
                selectedSemesters,
                {
                    action: ACTION_TYPES.revert,
                    status: status,
                    message:
                        status === 1
                            ? 'Marks are reverted succesfully'
                            : 'Student marks has been marked as completed successfully !',
                    shouldGoback: true,
                },
                force,
            );
        }
    };

    handleOnStudentGradesChanged = grades => {
        this.setState({grades})
    };

    getPrevComments = (year, semester, category) => {
        const { groupDetails } = this.state
        if (isArray(groupDetails.marks)) {
            groupDetails.marks.forEach(element => {
                if (year === element.year && semester === element.slice) {
                    for (let key in element.marks) {
                        if (key === category) {
                            if (element.marks[key].hasOwnProperty('comment')) {
                                this.setState({commentState: element.marks[key].comment[0]})
                            } else this.setState({commentState: 'ABC'})
                        }
                    }
                }
            });
        }
    };

    doHandleCommentSave = (category, comment) => {
        this.setState({
            allComments: [...this.state.allComments, comment]
        })
    };

    doHandleAttendanceSave = (attendance) => {
        console.log(this.state.allAttendances, attendance)
        attendance.attendance.stop_marker = attendance.details.year + "," + attendance.details.semester
        this.setState({
            allAttendances:  [...this.state.allAttendances, attendance]
        })
        console.log(this.state.allAttendances)
    }

    handlePreview = async () => {
        const { match, currentUser } = this.props
        const { moduleId, childId } = match.params || {}
        this.setState({pdfLoading: true})
        const body = {
            child_id: parseInt(childId, 10),
            module_id: parseInt(moduleId, 10),
            school_id: getCurrentSchoolId(currentUser)
        }
        const data = await marksService.getPDFforModule(body);
        if (data.status === 'success') {
            this.setState({preview: {open: true, data} });
        } else {
            this.setState({
                errorFeedback: {
                    ...this.state.errorFeedback,
                    open: true,
                    message: `Error Message : ${  data.message}`,
                }
            });
        }
        // console.log(data)
        this.setState({pdfLoading: false})
    }

    handleDone = async (isRedirecting = false) => {
        const { allMarks, errorFeedback, groupDetails } = this.state
        const { match, currentUser } = this.props
        const { moduleId, childId } = match.params || {}

        this.setState({
            isSaving: true
        })

        console.log('In Handle Done !!!', allMarks)
        const promises = Object.values(allMarks).map(async markInfo => {
            const statusCode = getStatusCode(
                getSemesterStatus(
                    groupDetails.studentMarksDetails.module,
                    childId,
                    markInfo.year,
                    markInfo.slice,
                ),
            );
            console.log('In Handle Done !!!', statusCode)
            const req = {
                marks: this.onCategoriesOrderChange(this.addCommentsandAttendanceToMarks(
                    markInfo.marks,
                    markInfo.year,
                    markInfo.slice,
                )),
                clazz_id: parseInt(moduleId, 10),
                child_id: parseInt(childId, 10),
                status: statusCode,
                slice: markInfo.slice,
                year: markInfo.year,
                school_id: getCurrentSchoolId(currentUser)
            };

            try {
                const updateMarkResp = await marksService.addUpdateMarks(req);
                return updateMarkResp;
            } catch (e) {
                this.setState({
                    errorFeedback: {
                        ...errorFeedback,
                        open: true,
                        message: e.message,
                    }
                });
            }

            return null;
        });

        const result = await Promise.all(promises);

        if (result.some(item => item && item.status === 'success')) {
            this.setState({
                errorFeedback: {
                    ...errorFeedback,
                    open: true,
                    message: 'Student marks has been saved successfully !',
                },
                isSaving: false,
                selectedYearSlice: []
            });

            if(isRedirecting) {
                setTimeout(() => {
                    this.props.history.goBack();
                }, 1500);
            }
        } else {
            // throw new Error('Error occured in fetching data');
            this.setState({
                isSaving: false
            })
        }
    };

    renderMainContent = () => {
        const { noMarksMode, categoriesList, values, studentGrades, commentState, groupDetails = {}, selectedYearSlice } = this.state
        const { classes, match, history, isAdmin, isTeacher, isParent } = this.props
        const { moduleId, childId, studentName } = match.params || {}
        console.log("AAAAAAA")
        if(groupDetails && Object.keys(groupDetails).length === 1){
            return
        }
        console.log("BBBBBB", studentName)
        return(
            groupDetails &&
            !groupDetails.loading && (
                <div>

                    {noMarksMode ? (
                        <div>
                            {categoriesList.map(category => (
                                <Grid container>
                                    <Grid item md={6} className={classes.commentsBox}>
                                        <CategoryCommentAndPhotos
                                            category={category}
                                            //  categoryComments={getCommentsFromCategory(category, 0)}
                                            config={{ hideCategoryName: false }}
                                            module={groupDetails.studentMarksDetails.module}
                                            onCommentChange={c => this.handleCommentChange(category, c)}
                                            key={category.name}
                                            label="Comment"
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    ) : (
                        <div>
                            {
                                !isParent ?
                                    <CheckBoxHeader
                                        semesters={values.semesters}
                                        selectedYearSlice={selectedYearSlice || []}
                                        onCheckBoxChange={this.onCheckBoxChange}
                                        categories={values.categories || []}
                                    /> : null
                            }
                            <div style={{ overflowX: 'auto', maxHeight: 700 }}>
                                <Box
                                    // boxShadow={2}
                                    // borderRadius={5}
                                    // style={{}}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="flex-start"
                                        className={classes.root}
                                    >
                                        <Grid item className={classes.itemx} md={12}>
                                            <Table
                                                style={{
                                                    paddingTop: '-10px',
                                                    marginTop: '-15px',
                                                    backgroundColor: '#FAFAFA',
                                                }}
                                                stickyHeader={true}
                                                className="progress-report-table"
                                            >
                                                {values.categories &&
                                                values.categories.map((catx, categoryIndex) => {
                                                    const generateMarksGrid = catx.grid
                                                    console.log({ generateMarksGrid });
                                                    return (
                                                        <SkillMarks
                                                            indexProp={categoryIndex}
                                                            indexLength={values.categories.length - 1}
                                                            groupData={groupDetails}
                                                            childId={childId}
                                                            currentMarks={(groupDetails.marks || []).length && groupDetails.marks[0] || {}}
                                                            semesters={values.semesters}
                                                            semesterDetails={groupDetails.studentSemesterDetails}
                                                            category={catx}
                                                            module={groupDetails.studentMarksDetails.module}
                                                            history={history}
                                                            categoryIndex={categoryIndex}
                                                            onHandleChangeMark={(
                                                                grades,
                                                                gIndex,
                                                                mark,
                                                                year,
                                                                slice,
                                                                selected,
                                                            ) =>
                                                                this.handleChangeMark(
                                                                    grades,
                                                                    categoryIndex,
                                                                    gIndex,
                                                                    mark,
                                                                    year,
                                                                    slice,
                                                                    selected,
                                                                )
                                                            }
                                                            handleCommentChange={(category, comment) =>
                                                                this.handleCommentChange(category, comment)
                                                            }
                                                            handleMarkComplete={this.handleMultipleSemesterSelection}
                                                            generateMarksGrid={generateMarksGrid}
                                                            handleOnStudentGradesChanged={
                                                                this.handleOnStudentGradesChanged
                                                            }
                                                            studentGrades={studentGrades}
                                                            isAdmin={isAdmin}
                                                            isTeacher={isTeacher}
                                                            isParent={isParent}
                                                            // categoryComments={getCommentsFromCategory(catx, 0)}
                                                            prevComments={this.getPrevComments}
                                                            comments={commentState}
                                                            handleCommentSave={this.doHandleCommentSave}
                                                            handleAttendanceSave = {this.doHandleAttendanceSave}
                                                        />
                                                    )})}
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    )}
                </div>
            )
        )
    }

    renderLoader = () => "Loading..."

    renderPrintDlg = () => {
        const { preview } = this.state
        return(
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={preview.open}
                fullWidth
                fullScreen
            >
                <DialogActions>
                    <Button
                        onClick={() => this.setState({preview: {...preview, open: false} })}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
                <DialogContent>
                    <Iframe
                        width="100%"
                        height="100%"
                        src={`data:application/pdf;base64,${  preview.data.pdf}` }
                    />
                </DialogContent>
            </Dialog>
        )
    }

    renderSnackBar = () => {
        const { errorFeedback } = this.state
        return(
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={errorFeedback.open}
                autoHideDuration={6000}
                onClose={() => this.setState({errorFeedback: {...errorFeedback, open: false }})}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{errorFeedback.message}</span>}
                action={[
                    <Button
                        key="undo"
                        color="secondary"
                        size="small"
                        onClick={() => this.setState({ errorFeedback: {...errorFeedback, open: false }})}
                    >
                        Hide
                    </Button>,
                ]}
            />
        )
    }

    getScales = () => {
        const { classes } = this.props
        const { values: { studentScales = [], scaleCategoryArray = [] } = {} } = this.state
        return(
            (studentScales || []).length > 0 && (
                <Grid
                    item
                    className={`${classes.item} m-0 mb-3`}
                    md={12}
                >
                    <Grid container className="d-column-xs">
                        <Grid item md={12}>
                            <div className="flex">
                                <Typography
                                    style={{ fontSize: '20px ', fontWeight: 'bold', marginRight: 20 }}
                                    variant="h5"
                                >
                                    Scales
                                </Typography>
                                <div>
                                    { (scaleCategoryArray || []).map(scale => (
                                        <>
                                            <span>
                                                <b className="mr-2">{scale.name}:</b>
                                                { Object.entries(scale.grades).map(([key, value], index) => (
                                                    <span className="mr-2">{`${key} - ${value}${index === Object.keys(scale.grades).length - 1 ? "" : ","}`}</span>
                                                ))}
                                            </span> <br/>
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-2 mt-2">
                                <span className="mr-4">
                                    <span className="mr-1" style={{padding: "0 10px", border: "1px solid #4a4949", background: "#cce6f1"}}/>
                                    Marked/Published
                                </span>
                                <span className="mr-4">
                                    <span className="mr-1" style={{padding: "0 10px", border: "1px solid #4a4949", background: "lightgray"}}/>
                                    Master By
                                </span>
                                <span>
                                    <span className="mr-1" style={{padding: "0 10px", border: "1px solid #4a4949", background: "#fff"}}/>
                                    Open for Marking
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )
        )
    }

    onCheckBoxChange = (year, slice) => {
        let { selectedYearSlice } = this.state

        const index = (selectedYearSlice || []).findIndex(x => (x || "").toString() === `${year}${slice}`)
        if(index !== -1) {
            selectedYearSlice.splice(index, 1)
        } else {
            selectedYearSlice.push(`${year}${slice}`)
        }
        
        this.setState({
            selectedYearSlice
        })
    }

    onMarksModifying = async (status) => {
        const { match: { params: { childId, moduleId } }, currentUser } = this.props
        const { allMarks, groupDetails, selectedYearSlice } = this.state
        const { marks = [] } = groupDetails || {}

        this.setState({
            isSaving: true
        })

        const promises = Object.values(allMarks).map(async markInfo => {
            if(!(selectedYearSlice || []).some(x => (x || []).toString() === `${markInfo.year}${markInfo.slice}`)) return null;

            const mark = (marks || []).find(x => x.year === markInfo.year && x.slice === markInfo.slice) || {}
            const statusCode = ((mark || {}).status === 3) ? mark.status : status

            console.log(`Sending Marks for Year:${ markInfo.year  } Slice:${  markInfo.slice}`)

            const req = {
                marks: this.onCategoriesOrderChange(this.addCommentsandAttendanceToMarks(
                    markInfo.marks,
                    markInfo.year,
                    markInfo.slice,
                )),
                clazz_id: parseInt(moduleId, 10),
                child_id: parseInt(childId, 10),
                status: statusCode,
                slice: markInfo.slice,
                year: markInfo.year,
                school_id: getCurrentSchoolId(currentUser)
            };

            try {
                console.log(`Saving Year ${   markInfo.year  } slice ${  markInfo.slice}`, markInfo.marks)
                const updateMarkResp = await marksService.addUpdateMarks(req);
                return updateMarkResp;
            } catch (e) {
                this.setState({
                    errorFeedback: {
                        ...this.state.errorFeedback,
                        open: true,
                        message: e.message,
                    }
                })
            }

            return null;
        });

        const result = await Promise.all(promises);

        if (result.some(item => item && item.status === 'success')) {
            this.getStudentDetails()
            this.setState({
                errorFeedback: {
                    ...this.state.errorFeedback,
                    open: true,
                    message: status === 2 ? 'Student marks has been marked as completed successfully !' : 'Marks are reverted succesfully',
                },
                isSaving: false
            })
        } else {

            this.setState({
                isSaving: false
            })
        }
    };

    onDownloadReport = async () => {
        const { match, currentUser } = this.props
        const { moduleId, childId, studentName } = match.params || {}
        this.setState({pdfDownLoading: true})
        const body = {
            child_id: parseInt(childId, 10),
            // module_id: parseInt(moduleId, 10),
            school_id: getCurrentSchoolId(currentUser)
        }
        const data = await marksService.getPDFforModule(body);
        if (data.status === 'success') {
            // this.setState({preview: {open: true, data} });
            const filename = `${studentName}_report.pdf`;

            const link = document.createElement('a');
            link.href = `data:application/pdf;base64,${  data.pdf}`;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } else {
            this.setState({
                errorFeedback: {
                    ...this.state.errorFeedback,
                    open: true,
                    message: `Error Message : ${  data.message}`,
                }
            });
        }
        // console.log(data)
        this.setState({pdfDownLoading: false})
    }

    render() {
        const { classes, match: { params = {} }, isAdmin, isParent, location: { state: { backgroundColor = "" } } = {} } = this.props
        const { values, pdfLoading, sliceSelDlg, groupDetails, isLoading, isSaving, selectedYearSlice, pdfDownLoading } = this.state
        const { studentMarksDetails: { module: { modul = "" } = {} } = {} } = groupDetails || {}
        const { studentName } = params || {}
        console.log({state: this.state})

        return(
            <div className="progress-report-detail">
                <div className="main-header" style={{background: backgroundColor}}>
                    <div className="header-content">
                        <div id="container">
                            <div id="left">
                                <div className="header-content1">
                                    <div className="back">
                                        <Tooltip title={"Back"} placement="bottomLeft">
                                            <Icon
                                                type="left"
                                                className="color-white cursor-pointer"
                                                onClick={() => window.history.back()}
                                            />
                                        </Tooltip>
                                    </div>
                                    <h3 className="mb-0 color-white">{studentName}</h3>
                                </div>
                            </div>
                            <div id="right" style={!isParent ? {marginTop: -15} : {}}>
                                {
                                    !isParent ?
                                        <ANTButton
                                            type="primary"
                                            className="mr-2 mb-2"
                                            onClick={() => this.onMarksModifying(2)}
                                            disabled={pdfLoading || !(selectedYearSlice || []).length}
                                            // loading={isSaving}
                                        >
                                            Mark as Complete
                                        </ANTButton> : null
                                }

                                {
                                    isAdmin ?
                                        <ANTButton
                                            type="danger"
                                            className="mb-2"
                                            onClick={() => this.onMarksModifying(1)}
                                            disabled={pdfLoading || isSaving || !(selectedYearSlice || []).length}
                                        >
                                            Revert
                                        </ANTButton> : null
                                }
                                <ANTButton
                                    type="default"
                                    className="mr-2"
                                    onClick={this.handlePreview}
                                    // loading={pdfLoading}
                                    disabled={isSaving}
                                >
                                    Preview
                                </ANTButton>
                                {
                                    !isParent ?
                                        <>
                                            <ANTButton
                                                type="primary"
                                                className="mr-2"
                                                onClick={() => this.handleDone(false)}
                                                disabled={pdfLoading}
                                                // loading={isSaving}
                                            >
                                                Save
                                            </ANTButton>
                                            <ANTButton
                                                type="primary"
                                                onClick={() => this.handleDone(true)}
                                                disabled={pdfLoading}
                                            >
                                                Done
                                            </ANTButton>
                                        </> :
                                        <ANTButton
                                            type="primary"
                                            onClick={this.onDownloadReport}
                                            loading={pdfDownLoading}
                                        >
                                            Download Report
                                        </ANTButton>
                                }
                            </div>
                            <div id="center">
                                <h3 className="mb-0 color-white">{modul}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop className={classes.backdrop} open={isSaving || pdfLoading || isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div className="content">
                    {this.getScales()}
                    {groupDetails.loading ? this.renderLoading() : this.renderMainContent()}
                    {pdfLoading ? this.renderLoader() : null}
                    {this.renderPrintDlg()}
                    {this.renderSnackBar()}
                    {sliceSelDlg && (
                        <SemesterSelection
                            setSelectedSlices={() => {}}
                            handleClose={this.handleMultipleSemesterSelection}
                            years={groupDetails.studentMarksDetails.module.years}
                            slices={groupDetails.studentMarksDetails.module.slices}
                        />
                    )}
                </div>
            </div>
        )
    }
}

GroupDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo.user,
    isAdmin: state.currentUser.newUserInfo.isAdmin,
    isTeacher: state.currentUser.newUserInfo.isTeacher,
    isParent: state.currentUser.newUserInfo.isParent
})

const enhance = compose(
    withRouter,
    withStyles(useStyles, 'some style'),
    connect(mapStateToProps, null),
)

export default enhance(GroupDetails);
