import React from "react";
import { connect } from 'react-redux';
import { getSchoolId } from "../component/SchoolProfile/components/GlobalComponents/GlobalFields";
import FirebaseApiInstance from '../service/chat';

class NewNotification extends React.Component{
    componentDidMount(){
        this.StartFirebaseListeners();
    }
    componentDidUpdate(){
        this.StartFirebaseListeners();
    }

    StartFirebaseListeners() {
        console.log('Enabling Listener',this.props.currentUser)
        // this.props.setAppEnableStatus(this.props.user.school_id)
        this.props.startRoomListener(getSchoolId(), this.props.currentUser.user)
        this.props.startMessageListener(getSchoolId(), this.props.currentUser.user)
    }

    render(){
        return(
            <></>
        )
    }
}
const mapStateToProps = state => ({
	currentUser: state.currentUser.newUserInfo || {}
})
const mapDispatchToProps = (dispatch) => {
    return {
        startRoomListener: (school_id, user)=>(dispatch(FirebaseApiInstance.startRoomListener(school_id, user))),
        startMessageListener: (school_id, user)=>(dispatch(FirebaseApiInstance.startMessageListener(school_id, user))),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewNotification);