// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

import API from './awa-api';

//  _    _ __  __  _____   _____  _____   ____  _____
// | |  | |  \/  |/ ____| |  __ \|  __ \ / __ \|  __ \
// | |__| | \  / | (___   | |__) | |__) | |  | | |  | |
// |  __  | |\/| |\___ \  |  ___/|  _  /| |  | | |  | |
// | |  | | |  | |____) | | |    | | \ \| |__| | |__| |
// |_|  |_|_|  |_|_____/  |_|    |_|  \_\\____/|_____/
//
//

const is_generic = false

const awsConfig = {
    // 'aws_app_analytics': 'enable',
    "app_header": "HMS 360",
    is_generic : is_generic,
    school_name : is_generic ? "" : "Hudson Montessori School",
    school_logo: "https://hmsdev-userfiles-mobilehub-717810367.s3.amazonaws.com/public/1600177684282",
    //app_logo : require( '../assets/images/welcome/360.png'),
    app_logo : is_generic ? require( '../assets/images/welcome/360.png') : require( '../assets/images/hudson-school.png'),
    "school_name" : "Hudson Montessori School",
    "school_logo": "https://hmsdev-userfiles-mobilehub-717810367.s3.amazonaws.com/public/1600177684282",
    "media_url": "https://hmsprod-userfiles-mobilehub-1717063866.s3.amazonaws.com/",
 "media_placeholder_url" : "https://via.placeholder.com/500x100?text=IMAGE",
    "app_report_url" : "https://hmsreport.s3.amazonaws.com/index.html",
    "Storage": {
        "bucket": 'hmsprod-userfiles-mobilehub-1717063866', //REQUIRED -  Amazon S3 bucket
        "region" : "us-east-1"
    },
    'aws_cloud_logic': 'enable',
    'aws_cloud_logic_custom': [{
        "id": "5wsopmu9zf",
        "name": "HMS Generic",
        "description": "",
        "endpoint": "https://5wsopmu9zf.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1",
        "paths": [
            "/admin", "/admin/groups", "/admin/users", "/authenticate",
            "/config", "/config/firebase", "/config/usercache", "/group",
            "/posts", "/posts/add", "/posts/comments", "/posts/comments/add",
            "/posts/edit", "/posts/like", "/posts/view", "/unlike", "/user",
            "/user/add", "/user/me", "/user/me/updatepicture","/admin/sendinvitation","/admin/sendinvitation/mark"
        ]
    }],
    'aws_cognito_identity_pool_id': 'us-east-1:243c20e3-92b0-4752-89ee-54e021d9e759',
    'aws_cognito_region': 'us-east-1',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'hmsprod-hosting-mobilehub-1717063866',
    'aws_content_delivery_bucket_region': 'us-east-1',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd3am89fqjer8zw.cloudfront.net',
    'aws_project_id': 'dce8b8c1-1a7c-4885-879a-8ee60af48c42',
    'aws_project_name': 'HMSPROD',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'hmsprod-mobilehub-1717063866',
    'aws_sign_in_enabled': 'enable',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_no5IpCRPE',
    'aws_user_pools_web_client_id': '30p7dbqhqdrv25ti42h62a9g2c',
    API,
}



export default awsConfig;