import React from 'react';
import {connect} from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import {Modal, Radio, Spin, Button} from 'antd'
import {Grid} from "@material-ui/core";
import {
    getFileUrl,
    ImageUpload,
    NumberLabelInput,
    SelectLabelInput,
    TextLabelInput,
    uploadFile,
    capitalizeFirstLetter,
    openNotificationWithIcon,
    getSchoolId,
    SelectParentInput,
    getUserDetailsByUserId,
    SelectUserRoleInput,
    getUserRole
} from "../../../GlobalComponents/GlobalFields";
import { parentsRelation } from "../../../GlobalComponents/optionValues";
import {validateData} from "../../../GlobalComponents/validation";
import {updateChild, createUser, getChildInfo, updateUser} from "../../../../ApiServices";
import './modal.css'


class CreateParentModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            parentType: 'existing',
            selectedParentId: '',
            selectedParentInfo: {},
            isParentSearching: false,
            fields: { profile_photo: '', firstName: '', lastName: '', email: '', mobile: null, isPickup: '', relation: '', role: [] },
            errors: {},
            isImageUploading: false,
            isLoading: false,
            isSaving: false,
            studentDetails: {},
            updateParentDetail: {}
        }
    }

    async componentDidMount() {
        const { updateParentDetail = {} } = this.props
        if(updateParentDetail && updateParentDetail.id) {
            let role = []
            const object = updateParentDetail.role[getSchoolId()]
            Object.keys(object || {}).forEach(x => {
                if(updateParentDetail.role[getSchoolId()][x]) {
                    role.push(x)
                }
            })
            this.setState({
                updateParentDetail,
                fields: {
                    ...this.state.fields,
                    ...updateParentDetail.attributes,
                    role
                } || {},
                parentType: 'create'
            })
        } else {
            this.onFetchStudent();
        }
    }

    onFetchStudent = async () => {
        const { childId } = this.props;
        this.setState({
            isLoading: true
        })
        const payload = {
            child_id: Number(childId),
            school_id: getSchoolId()
        }
        const response = await getChildInfo(payload)
        if(response && response.status === "success") {
            this.setState({
                studentDetails: response.data,
                isLoading: false
            })
        } else {
            this.setState({
                studentDetails: {},
                isLoading: false
            })
        }
    }

    onImageUpload = async (info) => {
        if (info.file.status === 'uploading') {
            this.setState({
                isImageUploading: true
            })
        }
        if (info.file.status === 'done') {
            let key = await uploadFile(info.file.originFileObj);
            let imageURL = await getFileUrl(key);
            this.setState({
                fields: {
                    ...this.state.fields,
                    profile_photo: imageURL
                },
                isImageUploading: false
            })
        }
    }

    handelOnChange = (event) => {
        const { name, value, type, checked } = event.target
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

    onCreateParent = async () => {
        const { fields, studentDetails, selectedParentInfo, parentType, updateParentDetail } = this.state
        const { email, role }  = fields || {}
        const validationErrors = {}
        const studentInfo = cloneDeep(studentDetails || {})

        Object.keys(fields).forEach(name => {
            if(name === "mobile") return
            const error = validateData(name, fields[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            this.setState({ errors: validationErrors });
        } else {
            this.setState({ errors: {} });
        }

        if(Object.keys(validationErrors).length > 0){return}

        if(updateParentDetail && updateParentDetail.id && email) {
            this.setState({isSaving: true})

            let payload = {
                ...updateParentDetail,
                attributes: fields,
                user_id: updateParentDetail.id,
                role: {
                    ...updateParentDetail.role,
                    [getSchoolId()]: getUserRole(role)
                }
            }

            const response = await updateUser(payload)
            if(response && response.status === "success") {
                openNotificationWithIcon('success', "Parent updated successfully")
                this.props.getCreatedParentDetails(true)
                this.setState({isSaving: false})
            } else {
                openNotificationWithIcon('error', response.message || 'Something went wrong')
                this.setState({isSaving: false})
            }
        } else if(studentInfo && studentInfo.id && email) {
            this.setState({isSaving: true})

            let createParent = false
            studentInfo.school_id = getSchoolId()
            studentInfo.child_id = studentInfo.id

            if(selectedParentInfo && selectedParentInfo.id && parentType === 'existing') {
                studentInfo.parents = (studentInfo.parents || []).concat([selectedParentInfo.id])
                createParent = true
            } else {
                const payload = {
                    role: {[getSchoolId()]: getUserRole(role)},
                    child_ids: [studentInfo.id],
                    username: email,
                    attributes: {
                        ...fields
                    },
                    group_ids: studentInfo.group_ids
                }

                const userResponse = await createUser(payload)
                if(userResponse && userResponse.status === "success") {
                    studentInfo.parents = (studentInfo.parents || []).concat([userResponse.user_id])
                    createParent = true
                } else {
                    const message = userResponse.message === "User already exists in system." ?
                        "Email already exists in System for another user. Please use different email" : userResponse.message
                    openNotificationWithIcon('error', message || 'Something went wrong')
                    this.setState({isSaving: false})
                }
            }

            if(createParent) {
                const response = await updateChild(studentInfo)
                if(response && response.status === "success") {
                    this.props.getCreatedParentDetails(true)
                    this.setState({isSaving: false})
                } else {
                    openNotificationWithIcon('error', response.message || 'Something went wrong')
                    this.setState({isSaving: false})
                }
            }

        } else {
            this.props.getCreatedParentDetails(false)
        }
    }

    onGetUserDetail = async () => {
        let { selectedParentId, fields } = this.state
        this.setState({
            isParentSearching: true
        })
        const data = await getUserDetailsByUserId(selectedParentId)
        if(data) {
            const { firstName, lastName, email, mobile, relation, profile_photo, isPickup } = data.attributes || {}

            let role = []
            const object = data.role[getSchoolId()]
            Object.keys(object || {}).forEach(x => {
                if(data.role[getSchoolId()][x]) {
                    role.push(x)
                }
            })

            fields.firstName = firstName
            fields.lastName = lastName
            fields.email = email
            fields.mobile = mobile
            fields.relation = relation
            fields.profile_photo = profile_photo
            fields.isPickup = isPickup
            fields.role = role
        }
        this.setState({
            fields,
            selectedParentInfo: data || {},
            isParentSearching: false
        })
    }

    onParentTypeChange = (e) => {
        this.setState({
            parentType: e.target.value,
            fields: { profile_photo: '', firstName: '', lastName: '', email: '', mobile: null, isPickup: '', relation: '', role: [] },
            selectedParentId: '',
            selectedParentInfo: {},
            errors: {}
        })
    }

    render() {
        const { isModal, closeModal, currentUser: { newUserInfo: { usersList = [] } }, updateParentDetail = {} } = this.props;
        const { fields, errors, isImageUploading, isLoading, isSaving, parentType, selectedParentId, isParentSearching, selectedParentInfo }  = this.state
        const { profile_photo, firstName, lastName, email, mobile, isPickup, relation, role }  = fields || {}
        const parentDisable = (selectedParentInfo.id && parentType === 'existing') || parentType === 'existing'
        return(
            <Modal
                title={updateParentDetail.id ? "Edit Parent" : "Add Parent"}
                width={450}
                visible={isModal}
                onOk={this.onCreateParent}
                onCancel={closeModal}
                okButtonProps={isSaving ? { disabled: true, loading: <Spin/> } : (!(selectedParentInfo || {}).id && parentType === 'existing') ? { disabled: true } : {}}
            >
                <div>
                    {
                        isLoading ? <div className="text-center"> <Spin/> </div> :
                            <Grid item xs={12}>
                                {
                                    updateParentDetail.id ? null :
                                        <Radio.Group onChange={this.onParentTypeChange} value={parentType}>
                                            <Radio value={'existing'}>Select Existing Parent</Radio>
                                            <Radio value={'create'}>Create New Parent</Radio>
                                        </Radio.Group>
                                }
                                {
                                    parentType === 'existing' ?
                                        <div style={{marginTop: 15}}>
                                            <SelectParentInput
                                                label="Select Parent"
                                                name="country"
                                                onChange={(value) => this.setState({selectedParentId: value})}
                                                placeholder="Select a Parent"
                                                options={usersList || []}
                                                value={selectedParentId || ''}
                                                style={{width: 300}}
                                            />
                                            <div style={{marginTop: 15}}>
                                                <Button
                                                    disabled={!selectedParentId || isParentSearching}
                                                    onClick={this.onGetUserDetail}
                                                    type='primary'
                                                    loading={isParentSearching}
                                                >
                                                    Select
                                                </Button>
                                            </div>
                                        </div> : null
                                }

                                {
                                    (parentType === 'existing' && selectedParentInfo.id) || parentType === 'create' ?
                                        <div style={{marginTop: 15}}>
                                            <ImageUpload
                                                isImgUpload={isImageUploading}
                                                imageUrl={profile_photo}
                                                handleImage={(info) => this.onImageUpload(info, 'parent1')}
                                                disabled={parentDisable}
                                            />
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="firstName"
                                                    value={firstName}
                                                    onChange={this.handelOnChange}
                                                    placeholder="First Name"
                                                    label="First Name"
                                                    required
                                                    error={!firstName && errors && errors.firstName}
                                                    disabled={parentDisable}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="lastName"
                                                    value={lastName}
                                                    onChange={this.handelOnChange}
                                                    placeholder="Last Name"
                                                    label="Last Name"
                                                    required
                                                    error={!lastName && errors && errors.lastName}
                                                    disabled={parentDisable}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="email"
                                                    value={email}
                                                    onChange={this.handelOnChange}
                                                    placeholder="Email"
                                                    label="Email"
                                                    required
                                                    error={!email && errors && errors.email}
                                                    disabled={parentDisable || !!updateParentDetail.id}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <SelectUserRoleInput
                                                    label="Role"
                                                    name="role"
                                                    onChange={(value) => this.handelOnChange({target: { name: "role", value }})}
                                                    // placeholder="Select a role"
                                                    required
                                                    value={role}
                                                    error={!(role || []).length && errors && errors.role}
                                                    disabled={parentDisable}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <NumberLabelInput
                                                    className="w-100"
                                                    placeholder="Mobile"
                                                    name="mobile"
                                                    value={mobile}
                                                    onChange={(value)=> this.handelOnChange({target: {name: "mobile", value}})}
                                                    label="Mobile"
                                                    // required
                                                    error={!mobile && errors && errors.mobile}
                                                    disabled={parentDisable}
                                                />
                                            </div>

                                            <div className="p-card-title">
                                                <SelectLabelInput
                                                    label="Relation"
                                                    name="relation"
                                                    onChange={(value)=> this.handelOnChange({target: {name: "relation", value: (value || "").toLowerCase()}})}
                                                    placeholder="Select a relation"
                                                    options={parentsRelation || []}
                                                    value={capitalizeFirstLetter(relation)}
                                                    error={!relation && errors && errors.relation}
                                                    disabled={parentDisable}
                                                />
                                            </div>

                                            <div className="p-card-title">
                                                <div className="multiExpLblBlk font-bold fs-14">
                                                    Approved for Pickup
                                                </div>
                                                <div>
                                                    <Radio.Group name="isPickup" onChange={this.handelOnChange} value={isPickup} disabled={parentDisable}>
                                                        <Radio value="Yes">Yes</Radio>
                                                        <Radio value="No">No</Radio>
                                                    </Radio.Group>
                                                </div>
                                                {/*<span className="text-red form-control-sm fs-8">{errors && errors.isPickup}</span>*/}
                                            </div>
                                        </div> : null
                                }

                            </Grid>
                    }
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

export default connect(mapStateToProps, null)(CreateParentModal)