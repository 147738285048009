import React,{useEffect,useState,useRef} from "react";
import Header from "./Header"
import Footer from "./Footer"
import ChatBubble from "./../ChatBubble/ChatBubble"
import { makeStyles } from '@material-ui/core/styles';
import { v4 as uuidv4 } from 'uuid';
import ProfileDialog from "./../Profile/Profile"
import CreateRoomDialog from "../CreateRoomDialog/CreateRoomDialog"
import "./index.css";

const useStyles = makeStyles((theme) => ({}));

function ChatWindow({chatroomDetail={},me={},sendMessageAction,users={},groups={},handleCreateRoomAction,leaveChatAction,deleteChatAction}){
  const classes = useStyles();
  let messagesEnd = useRef(null);
  let [attachFiles,setAttachFiles]=useState([]);
  let [showProfileDialog,setShowProfileDialog]=useState(false);
  let [openRoomDialog,setOpenRoomDialog]=useState(false);

  useEffect(()=>{
    scrollToBottom();
  });
  const scrollToBottom = () => {
    if(messagesEnd.current!==null){
      messagesEnd.scrollIntoView({block: "end", inline: "nearest"});  
    }
  }
  const handleSendMessage=(message)=>{
      if(Object.keys(chatroomDetail).length!==0){
        const newMessage=[{
          _id:uuidv4(),
          orderBy:new Date().getTime(),
          sent:true,
          text:message,
          user:{_id:me.id,name:`${me.attributes.firstName} ${me.attributes.lastName}`,avatar:me.attributes.profile_photo},
        }]
        const { key: roomkey, details: { users } } = chatroomDetail;
        // console.log(attachFiles,attachFiles[0].type,"handleSendAttachFiles");
        if ((attachFiles[0]?.type||"").includes("video")) {
          sendMessageAction(roomkey,newMessage,users,false,attachFiles,true);
        } else {
          sendMessageAction(roomkey,newMessage,users,false,attachFiles,false);
        }
      }
  }
  const handleSendAttachFiles=(files)=>{
    setAttachFiles(files);
  }
    return(
      <>
      {(Object.keys(chatroomDetail).length!==0)?(
      <div className="chat-window-container">
        <ProfileDialog 
          open={showProfileDialog} 
          onCloseAction={()=>{setShowProfileDialog(!showProfileDialog)}} 
          chatroomDetail={chatroomDetail} 
          me={me}
          users={users}
          groups={groups}
          handleCreateRoomAction={handleCreateRoomAction}
          leaveChatAction={leaveChatAction}
          deleteChatAction={deleteChatAction}
        />
          <Header chatroomDetail={chatroomDetail} me={me} showProfileAction={()=>{setShowProfileDialog(true)}}/> 
          <div className={(attachFiles.length!==0)?`chat-body-attachfiles`:`chat-body`} id="custom-scroll">
            {(chatroomDetail?.messages||[]).map(message=>(
              <ChatBubble message={message} me={me}/>
            ))}
            <div style={{ float:"left", clear: "both" }}
             ref={(el) => { messagesEnd = el; }}>
            </div>
          </div>
          <Footer 
            sendMessageAction={handleSendMessage}
            sendAttachFileAction={handleSendAttachFiles}
          />
    </div>
      ):(null)}
      </>
    )
}

export default ChatWindow;