import React, { Fragment } from 'react';
import * as routes from './path';
import { Route } from 'react-router-dom';

import Dashboard from '../container/Dashboard';
import Students from '../container/student';
import Users from '../container/users';
import Parent from '../container/parent';
import Staff from '../container/staff';
import Group from '../container/group';
import AddStudentsBulk from '../container/addStudentsBulk';
// import GroupDetail from '../container/groupDetail';
// import AttendanceSummary from '../container/AttendanceSummary';
import ParentManageForm from '../component/parentManage';
import StaffManageForm from '../component/staffManage';
import UserManageForm from '../component/studentManage';
import Gallery from '../container/photos'
import NewsFeed from "../container/Home";
import Event from "../container/Event";
import Chat from "../container/Chat";
import NewsLetter from "../component/NewNewsLetter";
import CurrentParentProfile from '../component/SchoolProfile/components/ParentsView/MyProfile';
import PreCheckin from '../component/SchoolProfile/components/ParentsView/PreCheckin';
import StudentProfile from "../component/SchoolProfile/components/Students/components/StudentProfile";
import StudentAttendanceForParent from '../component/SchoolProfile/components/Students/components/StudentProfile/NewAttendance';
import ProgressReport from "../component/ProgressReport";
import AddModule from "../component/ProgressReport/components/AddModule";
import GroupsList from "../component/ProgressReport/components/GroupsList";
import GroupStudents from "../component/ProgressReport/components/GroupStudents";
import GroupDetails from "../component/ProgressReport/components/GroupDetails";
import Plan from "../container/Plan";

export default function ParentRoutes() {
    return (
        <Fragment>
            <Route component={NewsFeed} path={routes.ROOT} exact />
            <Route component={NewsLetter} path={routes.NEWSLETTER} exact />
            <Route component={Chat} path={routes.CHATS} exact />
            <Route component={Event} path={routes.EVENT} exact />
            <Route component={Gallery} path={routes.Photos} exact />
            <Route component={CurrentParentProfile} path={routes.PARENT_PROFILE} exact />
            <Route component={PreCheckin} path={routes.PRE_CHECKIN_FORM} exact />
            <Route component={StudentProfile} path={routes.PARENT_STUDENT_VIEW} exact />
            <Route component={StudentAttendanceForParent} path={routes.STUDENT_ATTENDANCE_VIEW_FOR_PARENT} exact />
            <Route component={ProgressReport} path={routes.ProgressReport} exact />

            {/*<Route component={ProgressReport} path={routes.ProgressReport} exact />*/}
            {/*<Route component={AddModule} path={routes.ADD_MODULE_ROUTE} exact />*/}
            {/*<Route component={AddModule} path={routes.EDIT_MODULE_ROUTE} exact />*/}
            <Route component={GroupsList} path={routes.GROUPS_LIST} exact />
            <Route component={GroupStudents} path={routes.GROUPS_STUDENTS} exact />
            <Route component={GroupDetails} path={routes.GROUP_DETAILS} exact />
            <Route component={Plan} path={routes.PLAN} exact />
        </Fragment>
    );
}

