import React, { Component } from 'react';
import {Button} from 'antd'
import CreateParentModal from './CreateParentModal'

class CreateParent extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isModal: false
        }
    }

    closeModal = () => {
        this.setState({
            isModal: !this.state.isModal
        })
    }

    getCreatedParentDetails = (isFlag) => {
        if(isFlag){
            this.props.fetchStudentsList()
        }
        this.closeModal()
    }


    render() {
        const { childId }  = this.props
        const { isModal }  = this.state
        return(
            <div className="create-parent">
                <div>
                    { (this.props.length === 0 || this.props.length === undefined) ?
                        <Button
                            className="add-button"
                            onClick={this.closeModal}
                            // shape="round"
                            size="default"
                        >
                            Add Parent
                        </Button> :
                        <b className="ml-28 cursor-pointer" style={{color: '#3cc89c'}} onClick={this.closeModal}>+ Add Parent</b>
                    }
                </div>
                { isModal &&
                    <CreateParentModal
                        isModal={isModal}
                        closeModal={this.closeModal}
                        childId={childId || null}
                        getCreatedParentDetails={this.getCreatedParentDetails}
                    />
                }
            </div>
        )
    }
}


export default CreateParent