import {
    createSelector
} from 'reselect';

const getUserInfo = state => state.currentUser.newUserInfo.user;

const getGroupCache = state => state.currentUser.newUserInfo.groupcache;

const getUserCache = state => state.currentUser.newUserInfo.usercache;

const getGroupList = state => state.currentUser.newUserInfo.groupList;

const getChildList = state => state.currentUser.newUserInfo.childList;

const getGroupInfoWithParents = createSelector(
    [getUserInfo, getGroupCache, getUserCache, getGroupList, getChildList], (userInfo = {}, groups = {}, users, groupList = [], childList = []) => {
        const { group_ids: groupsInfo = {} } = userInfo;
        let array = []
        if (Object.keys(groupsInfo).length > 0 && Object.keys(groups).length > 0 && users) {

            (groupList || []).forEach(group => {
                if (!group.attributes.is_archive) {
                    let studentList = []
                    childList.forEach(student => {
                        if (Array.isArray(student.group_ids) && (student.group_ids || []).includes(Number(group.id))) {
                            studentList.push(student)
                        }
                    })
                    array.push({id: group.id, groupName: group.attributes.groupname || "", students: studentList})
                }
                })
            return array
        }
        return [];
    }
)

export const GroupSelector = {
    getGroupInfoWithParents
};