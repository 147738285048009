import moment from 'moment';

export const validate = values => {
    const errors = {attributes: {}}
    const requiredFields = [
        'event_title',
        'event_img_url',
        'groupParentSelection'
    ]


    requiredFields.forEach(field => {
        if (!((values || {}).attributes || {})[field]) {
            errors.attributes[field] = 'Required'
        }
    })

    if (values.start_date && values.end_date) {

        const start_date = moment(moment(values.start_date).format('LL'), 'LL')
        const end_date = moment(moment(values.end_date).format('LL'), 'LL')

        if (!moment(start_date).isSameOrBefore(end_date)) {
            errors['end_date'] = 'Invalid date'
        }

        if (moment(start_date).isSame(end_date)) {
            if (!moment(values.start_time).isSameOrBefore(values.end_time)) {
                errors['end_time'] = 'Invalid time'
            }

        }

    }
   
    return errors
}