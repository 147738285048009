import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AsyncImage from '../common/reduxForm/AsyncImage';
import awsConfig from "../../utils/aws-config"


const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(1) * 2,
    },
    cardContainer: {
        marginTop: theme.spacing(1) * 2
    },
    iconStyle: {
        cursor: "pointer",
        position: "relative",
        float: "right",
        background: "darkblue",
        borderRadius: "20px",
        color: "white",
        top: "-10px",
        right: "15px",
        padding: theme.spacing(1) * 0.5
    },
    imageStyle: {
        width: "100px",
        height: "100px",
        borderRadius: "10px"
    }
});

class ImageList extends Component {
    render() {
        const { classes, fields: list } = this.props;

        return (
            <Grid container className={classes.root} >
                {list.map((field, index, fields) => {
                    const src = fields.get(index) || ""
                    return(
                        <Grid className={classes.cardContainer} item key={index} >
                            <ClearIcon className={classes.iconStyle} onClick={() => { fields.remove(index) }} />
                            {!src.startsWith('https://') &&
                            (<AsyncImage
                                className={classes.imageStyle}
                                src={src}
                                alt="preview"
                            />)
                            }
                            {src.startsWith('https://') &&
                            (<img
                                className={classes.imageStyle}
                                src={`${awsConfig.media_url}${(src || "").includes("public/") ? src : `public/${src}`}`}
                                alt="preview"
                            />)
                            }

                        </Grid>
                    )
                    }

                )}

            </Grid>
        )
    }
}

export default withStyles(styles)(ImageList);