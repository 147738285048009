import React from 'react';
import styled from 'styled-components';
import {Draggable} from 'react-beautiful-dnd';
import Select from 'react-select';
import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
// import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
// import '@szhsin/react-menu/dist/index.css';
// import dots from './dots.png';
import "../../../css/plan.css";
import "./index.css";

import 'react-day-picker/lib/style.css';
import {connect} from "react-redux";

class MyTask extends React.Component {
    constructor(props) {
        super(props);
        console.log('In task ')
    }

    render() {
        return (
            <div>
                My Task
            </div>
        );
    }
}

const
    mapStateToProps = state => ({
        school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
        childcache: state.currentUser.newUserInfo.childrencache || {},
        childgroupcache: state.currentUser.newUserInfo.childgroupcache
    })

const
    mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MyTask)