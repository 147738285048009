import {
    API
} from 'aws-amplify';
import {
    takeEvery,
    fork,
    all,
    put,
} from 'redux-saga/effects';
import {
    REQUEST
} from '../actions/common';
import {
    FETCH_DRAFT_POST,
    fetchDraftPost,
    fetchSchedulePost,
    FETCH_SCHEDULE_POST,
    fetchReviewPost,
    FETCH_REVIEW_POST
} from '../actions/breifcase';

function* fetchDraftPostRequest(action) {
    try {
        const init = {
            body: {
                ...action.query,
                is_pinned: false,
                in_draft: true,
                in_review: false,
                is_scheduled: false
            },
        }
        const payload = yield API.post('get_posts', '', init)
        if (!payload.errorMessage) {
            yield put(fetchDraftPost.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchDraftPost.failure(error))
    }
}

function* fetchReviewPostRequest(action) {
    try {
        const init = {
            body: {
                ...action.query,
                is_pinned: false,
                in_draft: false,
                in_review: true,
                is_scheduled: false
            },
        }
        const payload = yield API.post('get_posts', '', init)
        if (!payload.errorMessage) {
            yield put(fetchReviewPost.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchReviewPost.failure(error))
    }
}


function* fetchSchedulePostRequest(action) {
    try {
        const init = {
            body: {
                ...action.query,
                is_pinned: false,
                in_draft: false,
                in_review: false,
                is_scheduled: true
            },
        }
        const payload = yield API.post('get_posts', '', init)
        if (!payload.errorMessage) {
            yield put(fetchSchedulePost.success(payload))
        } else {
            throw payload.errorMessage
        }
    } catch (error) {
        yield put(fetchSchedulePost.failure(error))
    }
}
/** watcher  */
function* watcherFetchDraftFeedPost() {
    yield takeEvery(FETCH_DRAFT_POST[REQUEST], fetchDraftPostRequest)
}
function* watcherFetchReviewFeedPost() {
    yield takeEvery(FETCH_REVIEW_POST[REQUEST], fetchReviewPostRequest)
}
function* watcherFetchScheduleFeedPost() {
    yield takeEvery(FETCH_SCHEDULE_POST[REQUEST], fetchSchedulePostRequest)
}

export default function* rootBriefCase() {
    yield all([
        fork(watcherFetchDraftFeedPost),
        fork(watcherFetchReviewFeedPost),
        fork(watcherFetchScheduleFeedPost)
    ]);
}