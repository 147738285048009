export const FETCH_CHILD_REPORT_LIST_SUCCESS = 'FETCH_CHILD_REPORT_LIST_SUCCESS';
export const FETCH_CHILD_REPORT_LIST_FAILURE = 'FETCH_CHILD_REPORT_LIST_FAILURE'
export const FETCH_SCALES_SUCCESS = 'FETCH_SCALES_SUCCESS'
export const FETCH_SCALES_FAILURE = 'FETCH_SCALES_FAILURE'

export const fetchChildReportListSuccess = data =>({
    type:FETCH_CHILD_REPORT_LIST_SUCCESS,
    data,
})

export const fetchChildReportListFailure = data =>({
    type:FETCH_CHILD_REPORT_LIST_FAILURE,
    data,
})


export const fetchScalesSuccess = data =>({
    type:FETCH_SCALES_SUCCESS,
    data,
})

export const fetchScalesFailure = data =>({
    type:FETCH_SCALES_FAILURE,
    data,
})
