import React, { Component } from "react";
import { connect } from "react-redux";
import { Storage } from "aws-amplify";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import renderHTML from 'react-render-html';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Popover from '@material-ui/core/Popover';
import Moment from 'react-moment';
import classnames from "classnames";
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Linkify from "react-linkify";

import CalImg from "../../../assets/images/calendar-2.png";
import AlarmImg from "../../../assets/images/alarm-clock.png";
import LocationImg from "../../../assets/images/location.png";
import NotificationImg from "../../../assets/images/notification.png";
import GroupImg from "../../../assets/images/group.png";

import {getEventsChild, toDataURL} from "../../../utils/utils";
import {isGenericEvent, isPTAEvent, isRSVPEvent, isVolunteerEvent} from "../../../shared/enums";
import RsvpResponse from "../RsvpAttend";
import AsyncImage from "../../common/reduxForm/AsyncImage";
import PreviewMeeting from "../../Event/MeetingDateTime/PreviewMeeting";
import PreviewVolunteer from "../../Event/Volunteer/PreviewVolunteer";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    // height: 400,
    // width: '100%',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px'
  },
  centerContent: {
    width: "100%",
    margin: "20px auto",
  },
  title: {
    fontSize: '40px',
    color: '#3f51b5',
  },
  location: {
    alignItems: 'center',
    marginTop: '25px'
  },
  alignment: {
    marginBottom: '20px',
  },
  clockImg: {
    height: '30px',
    width: '30px',
    float: 'left',
    margin: '5px'
  },
  description: {
    border: '1px solid #e2e2e2',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '20px'
  },
  imageContent: {
    margin: '8px',
    height: "20px",
    position: "relative",
    float: 'left'
  }
});

const other = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  }
}

class PreviewComponent extends Component {
  state = {
    anchorEl: null,
    open: false,
    openReminder: false,
    attachment: [],
    loggedinChildList: []
  };

  componentDidMount() {
    this.getLoggedInStudentList();
  }

  getLoggedInStudentList() {
    const { currentUser: { childrencache = {}, user = {} }, event: { attributes: { child_ids = [], groups = [] } } = {} } = this.props;

    const type = (groups || []).length ? "groups" : "childs"
    const ids = type === "groups" ? groups : child_ids

    const array = getEventsChild(childrencache, type, ids, user)
    this.setState({ loggedinChildList: array || [] })
  }

  handleClickButton = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  };

  handleReminder = (event) => {
    this.setState({
      openReminder: true,
      anchorEl: event.currentTarget
    });
  };

  handleDownload = async (file) => {
    const dataUrl = await Storage.get(file.key, { level: "public" });

    toDataURL(dataUrl, (dataUrl) => {
      const a = document.createElement("a");
      a.href = dataUrl;
      a.setAttribute("download", file.name);
      const b = document.createEvent("MouseEvents");
      b.initEvent("click", false, true);
      a.dispatchEvent(b);
      return false;
    })

  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleNextStep = (isDraft) => {
    const {
      history,
      addEvent,
    } = this.props;
    history.isDraft = isDraft
    addEvent.request({ history });
  };

  handlePreviousStep = () => {
    const {
      setStepperActiveStep,
      previousStep
    } = this.props;
    setStepperActiveStep(previousStep);
  };

  getGroupName = (id) => {
    const { currentUser: { groupcache = {} } } = this.props;
    let value = ""
    if(id && groupcache[id] && Object.values(groupcache[id]).length) {
      value = groupcache[id].attributes.groupname
    }
    return value || ""
  }

  getStudentName = (id) => {
    const { currentUser: { childrencache = {} } } = this.props;
    const { firstName = "", lastName = "" } = childrencache && childrencache[id] || {}
    if(firstName || lastName) {
      return `${firstName || ""} ${lastName || ""}`
    }
    return ""
  }

  render() {
    const { classes, event, currentUser: { user = {} } = {} } = this.props;
    const {
      open,
      anchorEl,
    } = this.state;

    const remindersArray = [
      { name: 'notifyOneDayAgo', status: event.notifyOneDayAgo },
      { name: 'notifyTwoDayAgo', status: event.notifyTwoDayAgo },
      { name: 'notifyThreeDayAgo', status: event.notifyThreeDayAgo },
    ]

    const { attributes: { event_img_url = [], event_title = "", location = "", event_description = "", groups = [], child_ids = [], attachments = [], event_type = "", groupParentSelection = "", all_day_event = false, limit = "" } = {} } = event || {}

    const reminders = remindersArray.filter(notify => notify.status === 1 || notify.status === true)
    const isRsvp = isRSVPEvent(event_type)
    const isMeeting = isPTAEvent(event_type)
    const isVolunteer = isVolunteerEvent(event_type)
    const selectedGroup = groupParentSelection === "group"

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.control}>
            <Grid container spacing={5}>
              <Grid item xs={8} className="centerdiv" style={{ textAlign: 'inherit' }}>
                <Card style={{ display: 'flex' }} className="cardComponent" >
                  <AsyncImage style={{ width: 'auto', height: 'fit-content', minHeight: 280 }} className={classes.cover} src={(event_img_url || []).length && (event_img_url || [])[0].key || ""} />
                  <Grid container justify="center" style={{ width: '50%', alignItems: 'center' }}>
                    <span className={classes.title}> {event_title || ""} </span>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={10} className="centerBottomDiv">
                <Card className="cardComponent">
                  <div className={classes.details}>
                    <CardContent
                      className={classnames(classes.content)}
                    >
                      <Grid item style={{ marginTop: '195px' }}>

                        {!isMeeting && ((groups || []).length || (child_ids || []).length) && (
                          <Popover
                            id="render-props-popover"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            {...other}
                            className="groupPopper"
                          >
                            <Typography
                              color="primary"
                              variant="h6"
                              className="eventDialogSubTitle"
                            >
                              <p>
                                {
                                  selectedGroup ? (
                                    (groups || []).length ? groups.map((group, key) => (
                                      <span
                                        className="groupChip"
                                        key={group}
                                      >
                                        {this.getGroupName(group) || ""}
                                      </span>
                                    )) : null
                                  ) : (
                                    (child_ids || []) ? child_ids.map((child, index) => (
                                      <span
                                        className="groupChip"
                                        key={index.toString()}
                                      >
                                        {this.getStudentName(child) || ""}
                                      </span>
                                    )) : null
                                  )
                                }

                              </p>
                            </Typography>
                          </Popover>
                        )}

                        {!isMeeting &&
                          <Grid container justify="space-between" className={classes.alignment} >
                            <div>
                              <img
                                className={classes.clockImg}
                                src={CalImg}
                                alt="previcon"
                              />
                              { isVolunteerEvent(event_type) && event.volunteertype === "1" ?
                                <span style={{float: 'right', marginTop: "10px"}}>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event && event.volunteer && event.volunteer[0] && event.volunteer[0].event_date}
                                  </Moment>
                                </span> :
                                  isVolunteerEvent(event_type) && (event.volunteertype === "2" || event.volunteertype === "3") ?
                                <span style={{float: 'right', marginTop: "10px"}}>Multiple Days</span> :
                                      isVolunteerEvent(event_type) && event.volunteertype === "4" ?
                                <span style={{float: 'right', marginTop: "10px"}}>No Specific day</span> :
                                  event && (isGenericEvent(event_type) || isRSVPEvent(event_type)) ?
                                <span style={{float: 'right'}}>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event && event.start_date}
                                  </Moment>
                                  <br/>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event && event.end_date}
                                  </Moment>
                                </span> :
                                <span style={{float: 'right'}}>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event.start_date}
                                  </Moment>
                                  <br/>
                                  <Moment format="ddd D, MMMM YYYY">
                                    {event.end_date}
                                  </Moment>
                                </span>
                              }
                            </div>

                            <div>
                              <img
                                alt="previcon"
                                src={AlarmImg}
                                className={classes.clockImg}
                              />
                              <span style={{ float: 'right' }}>
                                {
                                  all_day_event ?
                                    <div style={{marginTop: 8}}>All day event</div> :
                                    <>
                                      <Moment format="hh:mm a">
                                        {event.start_time}
                                      </Moment>
                                      <br />
                                      <Moment format="hh:mm a">
                                        {event.end_time}
                                      </Moment>
                                    </>
                                }
                              </span>
                            </div>

                            <div className="eventDialogcontent centerContent rsvp-around box">
                              <div className="prev-section-one">
                                <img
                                  alt="previcon"
                                  src={GroupImg}
                                  className={classes.imageContent}
                                  style={{ cursor:"pointer" }}
                                  onClick={this.handleClickButton}
                                />
                                {
                                  selectedGroup ? (
                                    !!(groups || []).length &&
                                    <p className="locationSection" >
                                      {(groups || []).length} Groups
                                    </p>
                                  ) : (
                                    !!(child_ids || []).length &&
                                    <p className="locationSection" >
                                      {(child_ids || []).length} Students
                                    </p>
                                  )
                                }
                              </div>
                            </div>

                            <div className="prev-section-one">
                              <img
                                className={classes.imageContent}
                                src={LocationImg}
                                alt="previcon"
                              />
                              <p className="locationSection">
                                {location || ""}
                              </p>
                            </div>

                            <div>
                              <img
                                alt="previcon"
                                src={NotificationImg}
                                className={classes.imageContent}
                                onClick={this.handleReminder}
                              />
                              <p className="locationSection">
                                {reminders.length} Reminders
                              </p>
                            </div>
                          </Grid>
                        }

                        {
                          isRsvp &&
                            <Grid container justify="space-around" className={classes.alignment} >
                              <div>
                                Limit: {limit}
                              </div>
                            </Grid>
                        }

                        {event_description && <div className={classes.description}> <Linkify> {renderHTML(event_description)} </Linkify> </div>}

                        { isRsvp &&
                          <RsvpResponse
                            readOnly={true}
                            event={event}
                            currentUser={user}
                          />
                        }


                        {(!!(attachments).length) && (
                          <div className="eventDialogcontent eventContainer">
                            <strong className="note">Attachments</strong>
                            <br />
                            <div className="customRow">
                              {attachments.map((attachment, key) => (
                                <Chip
                                  key={key}
                                  label={attachment.name}
                                  color="primary"
                                  variant="outlined"
                                  onClick={e => { this.handleDownload(attachment) }}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </Grid>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isMeeting && (
          <PreviewMeeting
            event={event}
            readOnly={true}
            data={this.state.loggedinChildList}
          />
        )}

        {isVolunteer && <PreviewVolunteer event={event} readOnly={true} />}

        <div className={classes.actionButton}>
          <Button variant="contained" onClick={this.handlePreviousStep}>
            Back
          </Button>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleNextStep(true)}
              className="mr-4"
            >
              Save to draft
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleNextStep(false)}
            >
              Publish
            </Button>
          </div>
        </div>
      </div>
    );
  }
}


PreviewComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentUser: state.currentUser.newUserInfo || {}
})

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(PreviewComponent)))
