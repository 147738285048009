import React, { Component } from 'react';
import { Select, Spin, Form, Input, DatePicker, Col, Row } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
    withStyles, Button,
    AppBar, Tabs, Typography,
    Tab, Card, CardContent, Paper,
    Avatar, Grid, MenuItem, CardActionArea
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SelectMartial from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import studentService from '../service/student';
import attendanceService from '../service/attendance';
import commonService from '../service/common';
import attendance from '../service/attendance';

const { Option } = Select;
const { Item: FormItem } = Form;
const headerHeight = 120;

const AttendanceEnum = Object.freeze({
    'Present': 1,
    'CheckedOut': 2,
    'UnMarked': 3,
    'Absent': 4,
})

const styles = theme => ({

    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    flex: {
        flex: 1,
    },
    card: {
        minWidth: 275,
        maxWidth: '70%',
        margin: theme.spacing(1),
    },
    sidebarHeader: {
        height: headerHeight,
        minHeight: headerHeight,
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText
    },
    sidebarHeaderInnerSidebar: {
        backgroundColor: 'transparent',
        color: 'inherit',
        height: 'auto',
        minHeight: 'auto'
    },
    header: {
        height: headerHeight,
        minHeight: headerHeight,
        display: 'flex',
        backgroundImage: 'url("/assets/header-bg.png")',
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        backgroundSize: 'cover'
    },
    layoutHeader: {
        height: 260,
        minHeight: 260,
        background: "url('/assets/dark-material-bg.jpg') no-repeat",
        backgroundSize: 'cover',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            height: 180,
            minHeight: 180
        }
    },
    paper: {
        width: 200,
        margin: theme.spacing(1)
    },
    content: {
        width: 200,
    },
    tableContainer: {
        width: '100%',
        marginTop: theme.spacing(1) * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    fab: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(1) * 2,
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

function StatusCard({ classes, ...props }) {
    return (

        <Card className={classes.paper}>
            <CardActionArea onClick={props.onClick}>
                <CardContent className={classes.content}>
                    <Typography variant="body1" component="h2" align="center">
                        {props.title}
                    </Typography>
                    <Typography variant="h6" component="h3" align="center">
                        {props.value}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

class UserManageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentList: [],
            _tableRows: [],
            tableRows: [],
            value: [],
            currentStudent: {},
            optionLoading: false,
            selectedTab: 0,
            isOpenAttendanceModel: false,
            attendance_state: '',
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            const { childid, tab = 0 } = this.props.location.state;
            this.setState({ selectedTab: tab })
            childid && this.fetchChildAttendance(childid);
            childid && this.fetchChildInfo(childid);
        }
    }

    fetchChildInfo = async (childid) => {
        const body = { childid }
        const { data } = await studentService.getChildById(body);
        const currentStudent = data[0];
        this.setState({ currentStudent });
    }

    fetchChildAttendance = async (childid) => {
        const body = { childid };
        const { data } = await attendance.childAttendance(body);
        this.setState({ tableRows: data, _tableRows: data })
    }

    onFormSubmit = async () => {
        this.props.form.validateFieldsAndScroll((err, data) => {
            if (err) {
                console.log(err);
                return;
            }
            if (Object.keys(this.state.currentStudent).length > 0) {
                const { pictureurl, dob, ...rest } = data;
                const body = {
                    photourl: pictureurl,
                    dob: moment(dob).format('MM/DD/YYYY'),
                    ...rest,
                    childid: this.state.currentStudent.childid
                }
                studentService.editStudent(body).then(res => {
                    this.props.history.goBack()
                });
            } else {
                const { dob, ...rest } = data;
                const body = {
                    photourl: '',
                    dob: moment(dob).format('MM/DD/YYYY').toString(),
                    ...rest,
                }
                studentService.addStudent(body).then(res => {
                    this.props.history.goBack()
                });
            }

        });
    }

    handleSearch = async (value) => {
        if (!value) {
            this.setState({
                parentList: []
            });
            return;
        }
        try {
            const val = value.toString().trim();
            this.setState({ optionLoading: true });
            const result = await commonService.searchUser(val);
            const options = result.map((d, index) => <Option key={index} value={d}>{d}</Option>);
            this.setState({
                parentList: options,
                optionLoading: false
            });
        } catch (err) {
            console.log(err);
            this.setState({
                parentList: []
            });
        }
    }

    handleChange = (value) => {
        this.setState({ value });
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    editAttendance = (selectedRow) => {
        const attendance_state = AttendanceEnum[selectedRow.Attendance]
        this.setState({ isOpenAttendanceModel: true, selectedRow, attendance_state });
    };

    closeAttendanceModel = () => {
        this.setState({ isOpenAttendanceModel: false, selectedRow: '' });
    };

    handleChangeAttendanceSelect = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleMarkAttendance = async (event) => {
        const { selectedRow: { childid, attendance_date }, attendance_state, } = this.state;
        const body = {
            childid,
            attendance_state,
            attendance_date
        }
        await attendanceService.markAttendance(body);
        this.closeAttendanceModel();
        this.fetchChildAttendance(childid);

    }

    filterByAttendance = event => {
        const { _tableRows } = this.state;
        const tableRows =
            event !== 'All' ?
                _tableRows.filter(row => row.Attendance === event)
                : _tableRows;
        this.setState({ tableRows })
    }

    render() {
        const {
            classes,
            form: { getFieldDecorator },
            currentUser: { userInfo: { Groups: allgroups = [] } },
        } = this.props;

        const { optionLoading, parentList, _tableRows, tableRows, selectedTab, currentStudent } = this.state;
        const { childid = '', firstname = '', lastname = '', parents = [], dob = '', allergies = '', groups = [] } = currentStudent;

        const _groups = groups.map(id => Object.keys(allgroups).length ? allgroups && allgroups[id] && allgroups[id].groupid : id);

        return (
            <div className={classes.root}>

                <div className={classNames(classes.header, classes.layoutHeader)}>
                    <div className="p-24 flex flex-1 flex-col items-center justify-center md:flex-row md:items-end">
                        <div className="flex flex-1 flex-col items-center justify-center md:flex-row md:items-center md:justify-start">
                            {firstname && <Avatar className="w-80 h-80 mr-8 text-40">{firstname[0].toUpperCase()}</Avatar>}
                            {!firstname &&
                                <Typography className="md:ml-24"  color="inherit">
                                    Add New Student
                                </Typography>
                            }
                            <Typography className="md:ml-24"  color="inherit">
                                {firstname && lastname && `${firstname} ${lastname}`}
                            </Typography>
                        </div>

                        <div className="flex items-center justify-end">
                            {selectedTab === 0 &&
                                <Button className=" mr-8 normal-case" variant="contained" color="primary" aria-label="cancel" onClick={this.onFormSubmit} >save</Button>
                            }
                            <Button className="normal-case" variant="contained" color="secondary" aria-label="save" onClick={() => { this.props.history.goBack() }} >cancel</Button>
                        </div>
                    </div>
                </div>

                <AppBar position="static" color="default">
                    <Tabs
                        value={selectedTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {/* <Tab label="Feed" /> */}
                        <Tab label="Profile" />
                        {childid && <Tab label="Attendance" />}
                    </Tabs>
                </AppBar>
                {selectedTab === 0 &&
                    <TabContainer>
                        <Card className={classes.card}>
                            <CardContent>
                                <Form>
                                    <Row gutter={40}>
                                        <Col span={12}>
                                            <FormItem label="First Name">
                                                {
                                                    getFieldDecorator('firstname', {
                                                        rules: [{ required: true, message: 'Please enter First Name!' }],
                                                        initialValue: firstname
                                                    })(
                                                        <Input width='100%' placeholder="First Name" />
                                                    )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Last Name">
                                                {getFieldDecorator('lastname', {
                                                    rules: [{ required: true, message: 'Please enter Last Name!' }],
                                                    initialValue: lastname
                                                })(
                                                    <Input placeholder="Last Name" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Date Of Birth">
                                                {getFieldDecorator('dob', {
                                                    rules: [{ required: true, message: 'Please enter student DOB!' }],
                                                    initialValue: dob ? moment(dob) : null
                                                })(
                                                    <DatePicker placeholder="DOB" style={{ width: '100%' }} />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Allergies">
                                                {getFieldDecorator('allergies', {
                                                    initialValue: allergies || ''
                                                })(
                                                    <Input placeholder="Allergies" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Parents">
                                                {getFieldDecorator('parents', {
                                                    rules: [{ required: true, message: 'Please select parents!' }],
                                                    initialValue: parents
                                                })(
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        notFoundContent={optionLoading ? <Spin size="small" /> : null}
                                                        placeholder="Please select parents"
                                                        onSearch={this.handleSearch}
                                                        onChange={this.handleChange}
                                                    >
                                                        {parentList}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem label="Groups">
                                                {getFieldDecorator('groups', {
                                                    rules: [{ required: true, message: 'Please select groups!' }],
                                                    initialValue: _groups
                                                })(
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        notFoundContent={optionLoading ? <Spin size="small" /> : null}
                                                        placeholder="Please select groups"
                                                    >
                                                        {Object.keys(allgroups).map((key, index) => <Option key={key} value={allgroups[key].groupid}>{allgroups[key].GroupName}</Option>)}
                                                    </Select>
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>

                                </Form>

                            </CardContent>
                        </Card>
                    </TabContainer>
                }
                {childid && selectedTab === 1 &&
                    <TabContainer>
                        <Grid container >
                            <Grid container item xs={12} spacing={8} justify="space-between">
                                <Grid item>
                                    <StatusCard
                                        classes={classes}
                                        title="Total School Day"
                                        value={_tableRows.length}
                                        onClick={(e) => { this.filterByAttendance('All') }}

                                    />
                                </Grid>
                                <Grid item>
                                    <StatusCard
                                        classes={classes}
                                        title="Present"
                                        value={_tableRows.filter(row => row.Attendance === 'Present').length}
                                        onClick={(e) => { this.filterByAttendance('Present') }}
                                    />
                                </Grid>
                                <Grid item>
                                    <StatusCard
                                        classes={classes}
                                        title="Absent"
                                        value={_tableRows.filter(row => row.Attendance === 'Absent').length}
                                        onClick={(e) => { this.filterByAttendance('Absent') }}

                                    />
                                </Grid>
                                <Grid item>
                                    <StatusCard
                                        classes={classes}
                                        title="Unmarked"
                                        value={_tableRows.filter(row => row.Attendance === 'Unmarked').length}
                                        onClick={(e) => { this.filterByAttendance('Unmarked') }}

                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper className={classes.tableContainer}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell >Attendance</TableCell>
                                                <TableCell >Check In Time</TableCell>
                                                <TableCell >CheckedInBy</TableCell>
                                                <TableCell >Checked Out Time</TableCell>
                                                <TableCell >Checked Out User Type</TableCell>
                                                <TableCell >Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableRows.map((row, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell > {row.attendance_date}</TableCell>
                                                        <TableCell >{row.Attendance}</TableCell>
                                                        <TableCell >{row.CheckedInTS}</TableCell>
                                                        <TableCell >{row.CheckedInBy}</TableCell>
                                                        <TableCell > {row.CheckedOutTS}</TableCell>
                                                        <TableCell >{row.CheckedOutBy}</TableCell>
                                                        <TableCell >
                                                            <Button
                                                                variant="fab"
                                                                mini
                                                                color="primary"
                                                                aria-label="Edit"
                                                                className={classes.fab}
                                                                onClick={(e) => { this.editAttendance(row) }}
                                                            >
                                                                <Icon>edit_icon</Icon>
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </Grid>
                        </Grid>
                    </TabContainer>
                }
                <Dialog
                    fullScreen={this.props.fullScreen}
                    open={this.state.isOpenAttendanceModel}
                    onClose={this.closeAttendanceModel}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Update Attendance Status"}
                    </DialogTitle>
                    <DialogContent>
                        <SelectMartial
                            value={this.state.attendance_state}
                            onChange={this.handleChangeAttendanceSelect}
                            displayEmpty
                            name="attendance_state"
                            className={classes.selectEmpty}
                        >

                            <MenuItem value={1}>Present</MenuItem>
                            <MenuItem value={2}>Checked Out</MenuItem>
                            <MenuItem value={3}>UnMarked</MenuItem>
                            <MenuItem value={4}>Absent</MenuItem>
                        </SelectMartial>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeAttendanceModel} color="primary">
                            cancel
                        </Button>
                        <Button onClick={this.handleMarkAttendance} color="primary" autoFocus>
                            save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
});

const wrappedResponsive = withMobileDialog()(UserManageComponent);
const UserManageWithStyles = connect(mapStateToProps)(withStyles(styles)(wrappedResponsive))

const UserManageForm = Form.create()(UserManageWithStyles);

export default UserManageForm;

export const validate = values => {
    const errors = {}
    const requiredFields = [
        'firstName',
        'lastName',
        'email',
        'favoriteColor',
        'notes'
    ]
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (
        values.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = 'Invalid email address'
    }
    return errors
}