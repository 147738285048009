import React from "react"
import {
    Icon,
    message,
    Upload,
    Input,
    Select,
    DatePicker,
    InputNumber,
    TimePicker,
    Spin,
    notification,
    Avatar,
    Modal, Tag
} from "antd";
import moment from "moment";
import {Storage} from "aws-amplify";
import { colourList, userRoleList } from "./optionValues";
import { getUserDetails } from "../../ApiServices"
import store from '../../../../redux/store'

const { Option } = Select
const { TextArea } = Input;

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

export const ImageUpload = ({name, handleImage, imageUrl, isImgUpload, className, disabled}) => {

    const uploadButton = (
        <div>
            { isImgUpload ?
                <Spin tip={'Uploading...'}/> :
                <>
                    <Icon type={'plus'} />
                    <div className="ant-upload-text">Upload</div>
                </>
            }
        </div>
    );

    return (
        <div className={className || "text-center"}>
            <Upload
                name={name || "avatar"}
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={handleImage}
                disabled={disabled || false}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', /* height: '100%', */ maxHeight: 130, borderRadius: '50%'}} /> : uploadButton}
            </Upload>
        </div>
    )
}

export const TextLabelInput = ({className, style, label, required, error, name, value, onChange, placeholder, size, type, addonAfter, prefix, disabled}) => {

    const props = {
        name,
        value,
        onChange,
        placeholder,
        prefix: prefix,
        type: type || "text",
        size: size || "default",
        addonAfter: addonAfter,
        disabled: disabled || false
    }

    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required ? <span className="icon text-red fs-8 ml-5-px"><i className="fas fa-asterisk extra-small-icon" /></span> : null}
                </div>
            )}
            <Input className="w-100" {...props}/>
            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const NumberLabelInput = ({className, style, label, required, error, name, value, onChange, placeholder, size, type, disabled}) => {

    const props = {
        name,
        value,
        onChange,
        placeholder,
        type: type || "number",
        size: size || "default",
        disabled: disabled || false
    }

    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required ? <span className="icon text-red fs-8 ml-5-px"><i className="fas fa-asterisk extra-small-icon" /></span> : null}
                </div>
            )}
            <InputNumber className="w-100" {...props}/>
            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const SelectLabelInput = ({className, style, label, required, error, name, value, onChange, placeholder, size, options, allowClear, mode, disabled}) => {

    const props = {
        name,
        value,
        onChange,
        placeholder: placeholder,
        mode: mode || "default",
        // defaultValue: defaultValue || "",
        className:"w-100",
        size: size || "default",
        disabled: disabled || false,
        allowClear: allowClear || false
    }

    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required ? <span className="icon text-red fs-8 ml-5-px"><i className="fas fa-asterisk extra-small-icon" /></span> : null}
                </div>
            )}

            <Select {...props}>
                {
                    (name === "othersRoom" || name === "homeRoom" || name === "groups") ?
                        options && Object.keys(options).map((key, index) => {
                            const name = (options[key] && options[key].attributes && options[key].attributes.groupname) || ""
                            return(
                                <Option key={index.toString()} value={options[key].id}>{name}</Option>
                            )
                        }) :
                    (options || []).map((item, i) => (
                        <Option value={item} key={i}>{item}</Option>
                    ))
                }
            </Select>

            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const SelectUserRoleInput = ({className, style, label, required, error, name, value, onChange, placeholder, size, options, allowClear, mode, disabled}) => {

    const props = {
        name,
        value,
        onChange,
        placeholder: placeholder,
        mode: mode || "multiple",
        // defaultValue: defaultValue || "",
        allowClear: allowClear || false,
        className:"w-100",
        size: size || "default",
        disabled: disabled || false
    }

    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required ? <span className="icon text-red fs-8 ml-5-px"><i className="fas fa-asterisk extra-small-icon" /></span> : null}
                </div>
            )}

            <Select {...props}>
                {
                    (userRoleList || []).map((item, i) => (
                        <Option value={item.value} key={i}>{item.label}</Option>
                    ))
                }
            </Select>

            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const SelectParentInput = ({className, style, label, required, error, name, value, onChange, placeholder, size, options, defaultValue, mode}) => {

    const props = {
        showSearch: true,
        name,
        value,
        onChange,
        placeholder: placeholder,
        mode: mode || "default",
        // defaultValue: defaultValue || "",
        className:"w-100",
        size: size || "default"
    }

    options.forEach(user => {
        user.userName = `${user.firstName || ""} ${user.lastName ||""}`
    })
    options && options.sort((a, b) => {
        const genreA = a.userName.toUpperCase();
        const genreB = b.userName.toUpperCase();

        let comparison = 0;
        if (genreA > genreB) {
            comparison = 1;
        } else if (genreA < genreB) {
            comparison = -1;
        }
        return comparison;
    });
    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required ? <span className="icon text-red fs-8 ml-5-px"><i className="fas fa-asterisk extra-small-icon" /></span> : null}
                </div>
            )}

            <Select
                {...props}
                filterOption={(input, option) => (option.props.children || "").toLowerCase().indexOf((input || "").toLowerCase()) >= 0 || (option.props.value || "").toLowerCase().indexOf((input || "").toLowerCase()) >= 0}
            >
                {
                    (options || []).map((item, i) => {
                        const isParent = ((((item || {}).role) || {})[getSchoolId()] || {}).parent
                        if(!isParent) return;
                        return (
                            <Option value={item.id} key={i.toString()}>{`${item.userName} (${item.email_id})`}</Option>
                        )
                    })
                }
            </Select>

            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const DateLabelInput = ({className, style, label, required, error, name, value, onChange, placeholder, size, defaultValue, format}) => {

    const props = {
        name,
        value,
        onChange,
        placeholder,
        size: size || "default",
        className: "w-100",
        format: format || "MM/DD/YYYY",
        defaultValue: defaultValue || null
    }

    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required ? <span className="icon text-red fs-8 ml-5-px"><i className="fas fa-asterisk extra-small-icon" /></span> : null}
                </div>
            )}

            <DatePicker {...props}/>

            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const TimeLabelInput = ({className, style, label, required, error, name, value, onChange, placeholder, size, format, defaultValue}) => {

    const props = {
        name,
        value,
        onChange,
        placeholder,
        size: size || "default",
        className: "w-100",
        format: format || "h:mm A",
        defaultValue: defaultValue || null
    }

    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required ? <span className="icon text-red fs-8 ml-5-px"><i className="fas fa-asterisk extra-small-icon" /></span> : null}
                </div>
            )}

            <TimePicker {...props} use12Hours/>

            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const TextLabelArea = ({className, style, label, required, error, name, value, onChange, placeholder, rows}) => {

    const props = {
        name,
        value,
        onChange,
        placeholder,
        rows: (rows || 4),
    }

    return(
        <div className={className || ""} style={style || {}}>
            {label && (
                <div className="multiExpLblBlk font-bold fs-14">
                    {label}
                    {required && (
                        <span className="icon text-red fs-8 ml-5-px">
                            <i className="fas fa-asterisk extra-small-icon" />
                        </span>
                    )}
                </div>
            )}
            <TextArea className="w-100" {...props}/>
            {error && (
                <span className="is-small text-error text-red fs-10" >
                    {error}
                </span>
            )}
        </div>
    )
}

export const ProfileAvatar = ({size, src, text, background}) => {

    return(
        <>
            { src ?
                <Avatar src={src} size={size || 130}/> :
                <Avatar size={size || 130}>{text || 'AB'}</Avatar>
            }
        </>
    )
}

export const getDays = (day) => {
    if(day === "mon"){
        return "Mo"
    } else if(day === "tue"){
        return "Tu"
    } else if(day === "wed"){
        return "We"
    } else if(day === "thu"){
        return "Th"
    } else if(day === "fri"){
        return "Fr"
    } else if(day === "sat"){
        return "Sa"
    } else if(day === "sun"){
        return "Su"
    } else {
        return ""
    }
}

export const uploadFile = async (file) => {
    let key = '';
    const name = `${moment ().format ('x')}`;
    await Storage.put (name, file, {
        contentType: file.type,
    }).then (
        resp => {
            key = resp.key;
        },
        err => {
            console.log (err);
        }
    );
    return key;
}

export const getFileUrl = async (key) => {
    let url = '';
    url = await Storage.get (key);
    const URL = url.split("?")[0]
    return (URL || url);
}

export const getColor = (index) => {
    const i = index % 10;
    return colourList[i] ? colourList[i].toLowerCase() : colourList[0].toLowerCase();
}

export const openNotificationWithIcon = (type, message) => {
    notification.open({
        message,
        top: 100,
        style: {background: type === 'error' ? 'red' : 'green'}
    });
};

export const capitalizeFirstLetter = (str) => {
    if(typeof str === "string"){
        if(str === "parent + pta"){
            return "Parent + PTA"
        } else if(str === "parent + teacher"){
            return "Parent + Teacher"
        }
        return str.length > 0 ? str[0].toUpperCase() + str.slice(1) : str
    }
    return str
}

export const getSchoolId = () => {
    let user = store.getState().currentUser.newUserInfo.user
    return Object.keys(user.role)[0]
}

export const getRole = (role) => {
    let roleValue = { admin: false, parent: false, teacher: false }
    if(role === "teacher") {
        roleValue.teacher = true
    } else if(role === "admin") {
        roleValue.admin = true
    } else if(role === "teacher + parent") {
        roleValue.teacher = true
        roleValue.parent = true
    } else if(role === "admin + parent") {
        roleValue.admin = true
        roleValue.parent = true
    }
    return roleValue
}

export const getUserRole = (roleList) => {
    return {
        admin: (roleList || []).includes("admin"),
        parent: (roleList || []).includes("parent"),
        teacher: (roleList || []).includes("teacher"),
        communications: (roleList || []).includes("communications"),
        pta: (roleList || []).includes("pta")
    }
}

export const getUserDetailsByUserId = async (userId) => {
    const payload = {
        school_id: getSchoolId(),
        user_id: userId
    }
    const userResponse = await getUserDetails(payload)
    if(userResponse && userResponse.status === "success") {
        return userResponse.data
    }
    return {}
}

export const getFormattedDate = (selectedDate) => {
    const year = moment(selectedDate).year()
    const month = ("0" + (moment(selectedDate).month() + 1)).slice(-2)
    const date = ("0" + (moment(selectedDate).date())).slice(-2)
    return `${String(year)}${String(month)}${String(date)}`
}

export const showMoreGroups = (group_ids, groupList) => {
    Modal.info({
        icon: '',
        content: (
            <div>
                {Array.isArray(group_ids) && (group_ids || []).map((group, ind) => {
                    if(groupList[group] === undefined){ return  }
                    return(
                        <Tag className="mb-2" key={ind} color="cyan">{groupList[group] !== undefined && groupList[group].attributes.groupname.replace("_", " ")}</Tag>
                    )
                })}
            </div>
        ),
        onOk() {},
    });
}

export const showGroups = (group_ids, groupList) => (
    <div>
        {Array.isArray(group_ids) && (group_ids || []).slice(0, 5).map((group, ind) => {
            if(groupList[group] === undefined){ return  }
            return(
                <Tag className="mb-2" key={ind} color="cyan">{groupList[group] !== undefined && groupList[group].attributes.groupname.replace("_", " ")}</Tag>
            )
        })}
        <span className="ml-10 cursor-pointer ws-nowrap" style={{color: "#110cef"}} onClick={() => showMoreGroups(group_ids, groupList)}>{(group_ids || []).length > 5 ? `+${(group_ids || []).length - 5} more` : null}</span>
    </div>
)