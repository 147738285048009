import React from 'react';
import {Paper, Grid, Avatar, Typography, Chip} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PDFIcon from "@material-ui/icons/DockTwoTone";
import ImagePreview from '../FeedCard/ImagePreview'
import TextEllipses from '../TextEllipses/TextEllipses';
import PopOverMenu from '../PopOverMenu/PopOverMenu';
import VideoPlayer from '../VideoPlayer';
import { POST_TYPE } from '../CreatePost/reduxForm/context';
import userAvatar from '../../assets/images/user-avtar.png';
import moment from 'moment';
import AccessTime from "@material-ui/icons/AccessTime";
import Moment from "../CreatePost/PostForm";
import { getPostTime } from "../../utils/utils"
import awsConfig from "../../utils/aws-config";


const useStyles = makeStyles(theme => ({
    cardContent: {
        margin: `${theme.spacing(1) * 3}px ${theme.spacing(1) * 1}px`,
        borderRadius: '15px',
        boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.05)',
        padding: theme.spacing(1) * 1,
        position: 'relative',
        // Selects every two elements among any group of siblings.
        '&:first-child(2n)': {
            marginTop: 0
        },
    },
    cardDetail: {
        display: 'inline-block',
    },
    postAuthor: {
        fontWeight: 700
    },
    alignActionWrp: {
        padding: '8px 0 0 0',
        alignItems: 'center',
        marginBottom: '30px'
    },
    cardDescription: {
        padding: `${theme.spacing(1) / 2}px ${theme.spacing(1) * 1}px`,
    },
    padding: {
        padding: theme.spacing(1) * 2,
    },
    image: {
        width: 128,
        height: 100,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    absoluteIcon: {
        position: "absolute",
        top: "-9px",
        left: "-9px",
        color: '#6593F5',
    },
    actionImage: {
        height: '20px',
        marginRight: '2px',
        marginBottom: '2px',
    },
    likeImage: {
        padding: '12px 5px'
    },
    cardTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '13px',
        fontWeight: 'normal'
    },
    commentImage: {
        margin: '0 5px'
    },
    likeArea: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    p12: {
        padding: '12px'
    },
    avatar: {
        backgroundColor: red[500],
    },
    btnPublish: {
        padding: '4px 12px',
        borderRadius: '27px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#3cc89c !important'
    },
    name: {
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    timeago: {
        fontSize: '14px',
        color: '#cecece'
    },
    wordWrap: {
        marginLeft: '2px'
    }
}));

function BriefCaseCard(props) {
    const classes = useStyles();

    const { data, currentUser: { user = {}, usercache = {}, groupcache = {}, childrencache = {} }, showPopOver, isPublish, isEditOrDelete, type } = props;

    const { attributes, media, post_time = "" } = data;
    const { images = [], videos = [], misc = [] } = media || {};
    const { postedby, postText, groupIds, childIds, reviewerList = [] } = attributes || {};
    const { display_name = "", profile_photo = "", id = "" } = postedby || {};
    const Images = (images || []).map(image => image[1]);

    const isPostOwner = showPopOver && isEditOrDelete;

    const reviewList = (reviewerList || []).filter(x => x !== user.id)

    const obj = data

    function handleEditPost() {
        const { attributes: { childIds = [], groupIds = [], postText = "", reviewerList = [], inDraft = false }, media: { images = [], videos, misc = [] } } = obj || {}
        const data = {
            postid: obj.id,
            groupids: groupIds[0],
            post: postText,
            img: [...(images || []).map(image => image[1])],
            vid: [...videos],
            misc: [...misc] || [],
            posttype: inDraft ? POST_TYPE[1].value : POST_TYPE[0].value,
            review_users: reviewerList,
            review_mode:!!(reviewerList || []).length,
            childids: childIds,
            payload: {...obj, isFromBriefCase: true}
        }

        if ((childIds || []).length) {
            data.posttype = POST_TYPE[2].value
        }

        props.formInitializeAction('editEvent', data)
        props.openDialogAction('EDIT_POST_FORM')
    }

    function handleDeletePost() {
        props.deletePostAction.request({id: data.id, isFromBriefCase: true});
    };

    function handlePublishClick(event) {
        /*    const body1 = {
               groupids: [groupids],
               post,
               img: [...img],
               vid: [...vid],
               posttime,
               review_mode: 0,
               review_users: [],
               pinned_post: 0,
               pinned_topic: "",
               draft_mode: 0,
               childids: []
           } */

        // const body = {
        //     postid: obj.postid,
        //     groupids: [obj.group_id],
        //     post: obj.posttext,
        //     img: obj.images,
        //     vid: obj.videos,
        //
        //     draft_mode: obj.draftflag,
        //     review_mode: obj.reviewflag,
        //     review_users: obj.post_reviews,
        //     pinned_mode: obj.pinnedflag,
        //     pinned_topic: obj.pinned_topic,
        //     pinned_until: obj.pinned_until,
        //     posttime: ""
        // }
        //
        // switch (type) {
        //     case 'draft':
        //         body.draft_mode = 0
        //         props.editPostAction.request(body)
        //         break;
        //     case 'review':
        //         body.review_mode = 0
        //         props.editPostAction.request(body)
        //         break;
        //     case 'schedule':
        //
        //         props.editPostAction.request(body)
        //         break;
        //
        //     default:
        //         return { obj }
        // }

        const { attributes } = data;

        const body = {
            ...data,
            attributes: {
                ...attributes,
                inDraft: false,
                inReview: false,
                isPinned: false,
                // childIds: childids,
                // groupIds: [Number(groupids)],
                // postText: post,
                // reviewerList: review_users,
                // scheduleDateTime: ""
            },
            media: {
                ...media,
                images: [...Images],
                // videos: [...vid]
            },
            post_time: new Date(),
            isFromBriefCase: true
        }
        props.editPostAction.request(body)
    }

    function getUserInfo(id, type) {
        const { profile_photo = "", firstName = "", lastName = "" } = usercache && usercache[id] || {}
        if(type === "image" && profile_photo) {
            return profile_photo || ""
        }
        if(type === "display_name" && (firstName || lastName)) {
            return `${firstName || ""} ${lastName || ""}`
        }
        return ""
    }

    function getGroupName(id) {
        let value = ""
        if(id && groupcache[id] && Object.values(groupcache[id]).length) {
            value = groupcache[id].attributes.groupname
        }
        return value
    }

    return (
        <Paper square={true} className={classes.cardContent}>

            <Grid container>

                <Grid item container alignItems="center" >
                    <Grid item>
                        <Avatar
                            aria-label="Recipe"
                            className={classes.avatar}
                            src={getUserInfo(id, "image") || profile_photo || ""}
                        >
                            {getUserInfo(id, "display_name")[0] || (display_name || "")[0] || ""}
                        </Avatar>
                    </Grid>
                    <Grid item xs={9} className={classes.wordWrap} container justify="space-between">
                        <Grid item>
                            <b>
                                <b>
                                    <span className={classes.name}>{display_name || ""}</span>
                                    <span className={classes.cardTitle}> posted to  </span>
                                    <span className={classes.name}>{getGroupName(groupIds[0] || "")}</span>
                                </b>
                            </b>
                            <Typography>
                                <span className={classes.timeago}>{getPostTime(post_time || "")}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {isPostOwner &&
                            <PopOverMenu
                                icon={<MoreVertIcon />}
                                handleEditPost={handleEditPost}
                                handleDeletePost={handleDeletePost}
                            />
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography component="div" className={classes.cardDescription}>
                        <TextEllipses lines={'3'} text={postText || ""} />
                    </Typography>
                </Grid>

                <Grid item container justify="flex-start">
                    {!!(Images || []).length && (
                        <ImagePreview
                            Images={Images}
                            data={data}
                            currentUser={ {newUserInfo: props.currentUser} }
                            getUserInfo={getUserInfo}
                            getGroupName={getGroupName}
                        />
                        )}
                    <br />
                    {!!(videos || []).length && videos.map(video =>
                        (<div style={{width: "75%"}}>
                            <VideoPlayer
                                key={video}
                                posterUrl=""
                                videoUrl={video}
                            /><br/>
                        </div>))}
                    {!!(misc || []).length && misc.map((doc, index) =>
                        (<a
                            key={index.toString()}
                            href={`${awsConfig.media_url}${doc.uri || doc.url || doc}`}
                            target="_blank"
                            style={{marginRight: 5}}
                        >
                            <Chip
                                icon={<PDFIcon />}
                                label={doc.filename || `Doc${index+1}`}
                                className={classes.chip}
                                variant="outlined"
                                style={{cursor: "pointer"}}
                            />
                        </a>))}
                </Grid>

                { type === "schedule" ?
                    <Grid container item >
                        <Typography  >
                            {/*Schedule for {moment(posttime + ".000Z").format('MMMM Do YYYY, hh:mm a')}*/}
                            Schedule for {moment(post_time + ".000Z").format('MMMM Do YYYY, hh:mm a')}
                        </Typography>
                    </Grid> : null
                }

                {type === "review" && user.id === id ?
                    <Grid item container>
                        <Grid container item justify="center">
                            <Typography variant="h6" className={classes.fontBold} style={{color: "blue"}}>
                                Published for Review
                            </Typography>
                        </Grid>
                        <Grid container item >
                            <Typography className={classes.fontBold}>
                                <span style={{fontWeight: "bold"}}>Reviewers -</span>
                                { (reviewList || []).length ? reviewList.map((u, index) =>
                                    <div key={index.toString()}>{getUserInfo(u, "display_name")}{index + 1 !== (reviewList || []).length ? "," : ""}</div>
                                ) : null}
                            </Typography>
                        </Grid>
                    </Grid> :
                    <Grid item container className={classes.alignActionWrp} justify="center">
                        <Grid item style={{position: 'absolute', bottom: '-15px'}}>
                            <Button disabled={!isPublish} onClick={handlePublishClick} variant="contained"
                                    color="primary" className={classes.btnPublish}>
                                Publish
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>

        </Paper>
    )
}

export default BriefCaseCard;
