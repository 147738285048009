import React from "react";
import BadgeAvatar from "./BadgeAvatar"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import "./index.css"

const useStyles = makeStyles((theme) => ({
    badgeIcon: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: "#3f51b5",
        borderRadius: 20,
        color: "#fff",
      },
}));
{/* id="custom-scroll" */}
export default function UserListView({selectedUsers=[],selectedGroup={},unSelectUserAction}){
    const classes = useStyles();
    return(
        <div className="user-list-container" >
           {selectedUsers.map(user=>(
               <BadgeAvatar
                name={`${user.firstName} ${user.lastName}`}
                renderbadge={()=><CloseIcon className={classes.badgeIcon}/>}
                height={50}
                width={50}
                avatar_photo={user.profile_photo}
                avatar_name={`${user.firstName} ${user.lastName}`}
                selectItemAction={(user)=>{unSelectUserAction(user)}}
                item={user}
               />
           ))}
        </div>
    )
}