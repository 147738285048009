import _ from "lodash"
import {
    GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE,
    FETCH_AUTH_DATA_FAILURE, FETCH_AUTH_DATA_SUCCESS, GET_NEW_USER_INFO_SUCCESS
} from "../actions/current-user";
// import {getCurrentSchoolId} from "../../utils/utils";

const INITIAL_STATE = {
    auth: {},
    userInfo: {},
    newUserInfo: {},
    usergroupcache : {},
    childgroupcache : {}
}

const currentUser = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.data
            }
        case FETCH_AUTH_DATA_SUCCESS:
            return {
                ...state,
                auth: action.data
            }
        case GET_NEW_USER_INFO_SUCCESS:
            const { usercache = [], groupcache = [], childrencache = [], user = {}, usertoken = "" } = action.data || {}
            let userCache = _.keyBy(usercache, 'id')
            let groupCache = _.keyBy(groupcache, 'id')
            let childgroupcache = {}
            let currentChildList = {}
            // console.log('usercache', userCache)
            let school_id = Object.keys((user || {}).role || {})
            let usergroupcache = {}
            for (var key in groupCache){
                // console.log(key)
                let sklid = groupCache[key].school_id
                groupCache[key]['role'] = (user || {}).role[sklid] === undefined ? {} : (user || {}).role[sklid]
                childgroupcache[key] = {}
                usergroupcache[key] = {}
            }
            for (var key in childrencache){
                childrencache[key].group_ids.forEach((groupid)=>{
                    if (childgroupcache[groupid] === undefined || Object.keys(childgroupcache[groupid]) == 0){
                        childgroupcache[groupid] = {}
                    }
                    if ((groupCache[groupid] !== undefined)
                        && (!groupCache[groupid].attributes.is_archive)){
                        currentChildList[childrencache[key].id]= childrencache[key]

                    }
                    childgroupcache[groupid][childrencache[key].id] = childrencache[key]
                })


            }


            for (var key in userCache){
                let groupids = userCache[key].group_ids
                for (let x = 0; x < groupids.length;x++){
                    let grpid = groupids[x]
                    // console.log(grpid, usergroupcache)
                    if (grpid in usergroupcache) {
                        let role = userCache[key].role[school_id[0]]
                        // console.log('usercache[key]',role, school_id[0])
                        if ((role.admin) || (role.teacher)  || (role.communications)){
                            if (!('staff' in usergroupcache[grpid])){
                                usergroupcache[grpid]['staff'] = {}
                            }
                            usergroupcache[grpid]['staff'][key] = userCache[key]
                        }
                        if (role.parent){
                            if (!('parent' in usergroupcache[grpid])){
                                usergroupcache[grpid]['parent'] = {}
                            }

                            usergroupcache[grpid]['parent'][key] = userCache[key]
                        }
                    }
                }
            }

            const role = user.role || {}

            let isAdmin = false
            let isTeacher = false
            let isParent = false
            let communications = false

                if((school_id || []).length) {
                    isAdmin = role[school_id[0]].admin || false
                    isTeacher = (!role[school_id[0]].admin && role[school_id[0]].teacher) || false
                    isParent = (!role[school_id[0]].admin && !role[school_id[0]].teacher && role[school_id[0]].parent) || false
                    communications = role[school_id[0]].communications || false
                }
                //commented by Raj
                //(role[school_id[0]].admin === false && role[school_id[0]].teacher === false && role[school_id[0]].parent === true)

            return {
                ...state,
                newUserInfo: {
                    user : user || {},
                    token : usertoken || "",
                    groupcache : groupCache,
                    usercache : userCache,
                    childrencache : _.keyBy(childrencache, 'id'),
                    currentchildList : _.values(currentChildList),
                    childList: childrencache || [],
                    usersList: usercache || [],
                    groupList: groupcache || [],
                    usergroupcache : usergroupcache,
                    childgroupcache,
                    isAdmin,
                    isTeacher,
                    isParent,
                    communications
                }
            }
        case FETCH_AUTH_DATA_FAILURE:
        case GET_USER_INFO_FAILURE:
            return state
        default:
            return state
    }
}

export default currentUser