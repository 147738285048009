import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import {Button, List, Avatar, Tag, Icon, Spin, Modal, Tooltip, Radio} from 'antd';
import {Card, CardHeader, Container, Grid} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import PieChart from "react-minimal-pie-chart";
import {
    ProfileAvatar,
    getColor,
    getSchoolId,
    openNotificationWithIcon, DateLabelInput, TimeLabelInput, getDays
} from "../../../GlobalComponents/GlobalFields";
import EditInfoModal from "./EditInfoModal"
import * as routes from "../../../../../../routes/path";
import {
    getChildInfo,
    getUserDetails,
    inviteUser,
    updateChild,
    deleteUser,
    getStudentCheckinData
} from "../../../../ApiServices"
import CreateParent from "../../../Parents/components/CreateParent";
import "../../../school.css"
import CreateParentModal from "../../../Parents/components/CreateParent/CreateParentModal";
import authService from "../../../../../../service/auth";
import {newUserInfoSuccess} from "../../../../../../redux/actions/current-user";
import AllergyImage from "../../../../../../assets/images/allergy.png";
import cloneDeep from "lodash/cloneDeep";
import {preCheckinObject} from "../../../GlobalComponents/optionValues";
import moment from "moment";
import ClearTwoToneIcon from "@material-ui/icons/ClearTwoTone";
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class StudentProfile extends React.Component {

    state = {
        isLoading: false,
        studentDetails: {},
        staffFiled: {},
        currentStudent: {},
        editInfo: {},
        isParentModal: false,
        updateParentDetail: {},
        attendanceCount: {},
        siblings: [],
        viewIndex: null,
        isOpen: false,
        editType: '',
        scheduleEdit : false,
        scheduleFields: {startDate: null, endDate: null, startTime: null, endTime: null, selectedDays: {}, classType: 'inClass'},
        selectedScheduleIndex: null,
        scheduleList : []
    }

    handleChangeTime = (time, key) => {
        this.setState({
            scheduleFields: {
                ...this.state.scheduleFields,
                [key]: time
            }
        })
    };

    componentWillMount() {
        this.fetchStudentsList()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { match: { params: { id } }  } = this.props
        const { match: { params: { id: currentId } }  } = nextProps || {}
        if(id !== currentId ) {
            this.fetchStudentsList(currentId)
        }
    }

    handelOnChange = (e, type) =>{
        const {studentFiled, parentFiled1, parentFiled2, familyFiled, medicalField, primaryAddress, scheduleFields} = this.state
        let values = {}
        if(type === "scheduleFields"){
            values = {
                scheduleFields: {
                    ...scheduleFields,
                    selectedDays: {
                        ...scheduleFields.selectedDays,
                        [e.target.name]: e.target.checked
                    }
                }
            }
        }
        this.setState({...values})
    }

    fetchStudentsList = async (currentId) => {
        const { match: { params: { id: studentId } }, currentUser: { usercache = {}, isParent = false } } = this.props;
        const id = currentId || studentId
        this.setState({
            isLoading: true
        })
        let studentDetails = {}
        const payload = {
            child_id: Number(id),
            school_id: getSchoolId()
        }
        this.setAttendanceSummary(Number(id))
        const response = await getChildInfo(payload)
        if(response && response.status === "success") {
            this.getSiblings(response.data)
            let detailedParents = []
            if(response.data && (response.data.parents || []).length) {
                for(let i in response.data.parents) {
                    if(isParent) {
                        const parentDetails = usercache && usercache[response.data.parents[i]]
                        if (parentDetails && Object.keys(parentDetails).length) {
                            const { firstName = "", lastName = "", profile_photo = "", email = "", email_id = "", mobile = "", invited, relation, role = {}, group_ids = [], child_ids = [] } = parentDetails || {}
                            detailedParents.push({
                                id: parentDetails.id,
                                role,
                                attributes: {
                                    firstName, lastName, profile_photo, email: email_id, email_id, mobile, invited, relation
                                },
                                parentDetails,
                                group_ids,
                                child_ids
                            })
                        }
                    } else {
                        const payload = {
                            school_id: getSchoolId(),
                            user_id: response.data.parents[i]
                        }
                        const userResponse = await getUserDetails(payload)
                        if(userResponse && userResponse.status === "success") {
                            detailedParents.push(userResponse.data)
                        }
                    }
                }
            }

            studentDetails = {...response.data, parents: detailedParents}
        } else {
            studentDetails = {}
        }
        let scheduleList = studentDetails?.attributes?.scheduleList

        this.setState({
            studentDetails,
            scheduleList,
            isLoading: false,

        })
    }

    setAttendanceSummary = async (id) => {
        const payload = {
            school_id: getSchoolId(),
            child_id: id
        }

        let totalDays = 0
        let absent = 0
        let tardy = 0
        let present = 0
        let virtual = 0
        let unmarked = 0
        const response = await getStudentCheckinData(payload)
        if(response && response.status === "success") {

            const absentArray = ["excusedabsence", "unexcusedabsence"]
            const tardyArray = ["excusedtardy", "unexcusedtardy"]

            response.data.forEach(x => {
                const array = x.attendance && Object.keys(x.attendance || {})
                const value = ((array || []).length && array[0]) || ""
                if(absentArray.includes(value)) {
                    absent++
                } else if(tardyArray.includes(value)) {
                    tardy++
                } else if(value === "in" || value === "checkedout") {
                    present++
                } else if(value === "virtual") {
                    virtual++
                }else{
                    console.log(x)
                    unmarked++
                }
                totalDays++
            })

        }

        this.setState({
            attendanceCount: {
                totalDays,
                absent,
                tardy,
                present,
                virtual,
                unmarked
            }
        })
    }

    getSiblings = (data) => {
        const { currentUser: { childrencache = {}, usercache = {} } } = this.props

        let siblings = []

        data.parents.forEach(parent => {
            if(usercache && usercache[parent] && Object.keys(usercache[parent]).length) {

                if(usercache[parent] && (usercache[parent].child_ids || []).length) {
                    usercache[parent].child_ids.forEach(id => {
                        if(!(siblings || []).some(x => x.id === id) && data.id !== id && childrencache[id] && Object.keys(childrencache[id] || []).length) {
                            siblings.push(childrencache[id])
                        }
                    })
                }
            }
        })

        this.setState({
            siblings
        })
    }

    handleModal = (type, item) => {
        const dataItem = item || {}
        this.setState({
            isOpen: !this.state.isOpen,
            editType: type,
            editInfo: dataItem
        })
    }

    onUpdateProfile = () => {
        this.setState({
            isOpen: false,
            editType: ''
        })
        this.fetchStudentsList()
    }

    closeModal = () => {
        this.setState({
            isParentModal: false,
            updateParentDetail: {}
        })
    }

    getCreatedParentDetails = () => {
        this.closeModal()
        this.fetchStudentsList()
    }

    handleRemoveAdded =(index, key) => {
        console.log(key)
        console.log(this.state)
        const array = this.state[key]

        array.splice(index,1);
        this.setState({
            [key]: array
        })
    }

    handelOnDateChange = (key, date, val, type) =>{
        const {scheduleFields, studentFiled} = this.state
        let dateValues = {}
        if(type === "student"){
            dateValues = {
                studentFiled: {
                    ...studentFiled,
                    [key]: key === "dateOfBirth" ? date : val
                }
            }
        }else if(type === "scheduleFields"){
            dateValues = {
                scheduleFields: {
                    ...scheduleFields,
                    [key]: date
                }
            }
        }
        this.setState({...dateValues})
    }

    onInviteUser = async (parentId, parentIndex, isReset) => {
        let { studentDetails } = this.state
        const email = studentDetails.parents && studentDetails.parents[parentIndex] && studentDetails.parents[parentIndex].attributes.email || ""

        if(parentIndex !== -1) {
            studentDetails.parents[parentIndex].isInviting = true
        }
        this.setState({
            studentDetails
        })

        if(email) {
            const payload = {
                school_id: getSchoolId(),
                username: email || ""
            }

            const invite = await inviteUser(payload)
            if(invite && invite.status === "success") {
                openNotificationWithIcon('success', isReset ? 'Reset link sent successfully' : 'invitation sent successfully')
                studentDetails.parents[parentIndex].isInviting = false
                this.setState({
                    studentDetails
                })
                this.fetchStudentsList()
            } else {
                openNotificationWithIcon('error', invite.message || 'something went wrong')
                studentDetails.parents[parentIndex].isInviting = false
                this.setState({
                    studentDetails
                })
            }
        } else {
            openNotificationWithIcon('error', 'email address not provide')
            studentDetails.parents[parentIndex].isInviting = false
            this.setState({
                studentDetails
            })
        }
    }

    onDeleteParent = async (details) => {
        Modal.confirm({
            title: `Are you sure you want to delete this parent?`,
            onOk: async () => {
                let { studentDetails } = this.state
                const index = (studentDetails.parents || []).findIndex(x => x.id === details.id)
                if(index !== -1) {
                    studentDetails.parents[index].isDeleting = true
                }
                this.setState({
                    studentDetails
                })

                const payload = {
                    school_id: getSchoolId(),
                    user_id: details.id
                }

                const response = await deleteUser(payload)
                if(index !== -1) {
                    studentDetails.parents[index].isDeleting = false
                }
                if(response && response.status === "success") {
                    openNotificationWithIcon('success', response.message || "Parent delete successfully")
                    const newUserResponse = await authService.getNewUserDetail();
                    if(newUserResponse && newUserResponse.data) {
                        this.props.onSetUserInfo(newUserResponse.data)
                    }
                    this.fetchStudentsList()
                } else {
                    openNotificationWithIcon('error', response.message || 'Something went wrong')
                }
                this.setState({
                    studentDetails
                })
            },
            onCancel() {},
        });
    }

    onDeleteRelative = (index) => {
        let { studentDetails } = this.state
        Modal.confirm({
            title: `Are you sure you want to delete this member?`,
            onOk: async () => {
                studentDetails.auth_users.splice(index, 1)
                const payload = {
                    ...studentDetails,
                    child_id: studentDetails.id,
                    school_id: getSchoolId(),
                    parents: (studentDetails.parents || []).map(x => x.id)
                }

                const response = await updateChild(payload)
                if(response && response.status === "success") {
                    openNotificationWithIcon('success', "Member deleted successfully")
                    this.setState({
                        isSaving: false,
                        viewIndex: -1
                    })
                    this.fetchStudentsList()
                } else {
                    openNotificationWithIcon('error', response.message || 'Something went wrong')
                    this.setState({
                        isSaving: false
                    })
                }
            },
            onCancel() {},
        });
    }

    addStudentSchedule = () => {
        const { scheduleFields, scheduleList, selectedScheduleIndex } = this.state
        console.log(this.state)
        let validationErrors = {};
        Object.keys(scheduleFields).forEach(name => {
            if(name === 'startTime' || name === 'endTime') return;
            if (!(scheduleFields && scheduleFields[name]) && name !== "endDate" && name !== "selectedDays") {
                validationErrors[name] = "Required";
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            this.setState({ scheduleError: validationErrors });
            return;
        }
        console.log('scheduleList', scheduleList)
        if(selectedScheduleIndex === 0 || selectedScheduleIndex){
            scheduleList[selectedScheduleIndex] = scheduleFields
        } else {
            scheduleList.push(scheduleFields)
        }

        this.setState({
            scheduleList,
            scheduleError: {},
            scheduleFields: {startDate: null, endDate: null, startTime: null, endTime: null, selectedDays: {}, classType: 'inClass'},
            selectedScheduleIndex: null
        })
    }

    render() {
        console.log('In Render')
        const { isOpen, studentDetails, isLoading, editType, editInfo, isParentModal, updateParentDetail, siblings, attendanceCount } = this.state
        const { classes, currentUser: { groupcache: groupList = {}, isTeacher, isParent, isAdmin }, match } = this.props;
        const { attributes, group_ids = [], parents = [], auth_users, id } = studentDetails || {}
        const { firstName = "", lastName = "", gender = "", status = "", studentEnrollment="Full Day", homeLanguage = "", ethnicity = "", primaryLanguage = "", profile_photo = "", image = "", dateOfBirth = "", medicalField = {}, primaryAddress = {}, othersRoom = [], homeRoom = "" } = attributes || {}
        const { primaryDoctor = "", primaryDoctorContact = "", currentMedications = "", allergies = "" } = medicalField || {}
        const { address1 = "", address2 = "", address3 = "", city = "", state = "", postalCode = "",  country = "" } = primaryAddress || {}
        const { totalDays = 0, absent = 0, present = 0, virtual = 0, tardy = 0, unmarked = 0 } = attendanceCount || {}
        let isAddressAvailable = true

        if(address1 || address2 || address3 || city || state || postalCode || country) {
            isAddressAvailable = false
        }
        let isScheduleAvailable = studentDetails?.attributes?.scheduleList === undefined ? false : studentDetails?.attributes?.scheduleList.length > 0
        let scheduleList = studentDetails?.attributes?.scheduleList
        const { selectedScheduleIndex, scheduleFields,  scheduleError } = this.state;
        const { selectedDays: { mon, tue, wed, thu, fri, sat, sun } } = scheduleFields || {}

        console.log('scheduleFields', scheduleFields)
        if(isLoading) {
            return <div className="text-center"> <Spin/> </div>
        }
        console.log('state->', this.state)
        console.log('props->', this.props)
        return (
            <div className={`student ${classes.root}`}>
                <div>
                    <div className="text-center mt-40 mb-40"/>
                    <Grid container spacing={6} style={{marginTop: 0}}>
                        <Grid item md={12} lg={6}>
                            <Container className="rounded-1rem bg-white p-40 shadow">
                                <div className="text-right">
                                    <Icon type="edit" theme="filled" className="edit-icon" onClick={() => this.handleModal("student")}/>
                                </div>
                                {
                                    isOpen &&
                                        <EditInfoModal
                                            attributes={attributes}
                                            authUsers={auth_users}
                                            editInfo={editInfo}
                                            studentDetails={studentDetails}
                                            scheduleList={scheduleList}
                                            isOpen={isOpen}
                                            handleModal={this.handleModal}
                                            onUpdateProfile={this.onUpdateProfile}
                                            editType={editType || ""}
                                        />
                                }
                                <Grid container spacing={6} style={{marginTop: 0}}>
                                    <Grid item xs={6} style={{borderRight: "1px solid #eee"}}>
                                        <div>
                                            <div style={{marginTop: "-120px", alignItems: 'flex-end'}} className="flex">
                                                <ProfileAvatar
                                                    size={130}
                                                    src={(profile_photo || image) ? (profile_photo || image) : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS5tB3RmYyV1_SmIZ8n89EW1827lLa7khWE_7McD12NoUz3wK6Z"}
                                                />
                                                { allergies ?
                                                    <div style={{marginLeft: 5}}>
                                                        <Tooltip title={allergies}>
                                                            <img src={AllergyImage} style={{width: 15, marginBottom: 10}} />
                                                        </Tooltip>
                                                    </div> : null }
                                            </div>

                                            <Grid container spacing={3} className="mt-20">
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">First Name :</div>
                                                        <div>{firstName || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">Last Name :</div>
                                                        <div>{lastName || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">Gender :</div>
                                                        <div>{gender || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">DOB :</div>
                                                        <div>{dateOfBirth || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px ws-nowrap">Primary Language :</div>
                                                        <div>{primaryLanguage || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">Home Language :</div>
                                                        <div>{homeLanguage || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">Ethnicity :</div>
                                                        <div>{ethnicity || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">Status :</div>
                                                        <div>{status || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="flex">
                                                        <div className="fw-600 w-100-px">Enrollment :</div>
                                                        <div>{studentEnrollment || "-"}</div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs className="flex">
                                                    <div className="fw-600 w-100-px">Home Room :</div>
                                                    <div className="fs-16">
                                                        <div>
                                                            {([homeRoom || ""] || []).map((group, ind) => {
                                                                if(groupList[group] === undefined){ return  }
                                                                const { attributes } = (groupList && groupList[group]) || {}
                                                                return(
                                                                    <Tag className="student-profile-tags" key={ind}>{attributes.groupname.replace("_", " ")}</Tag>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <div className="fw-600">Others Room :</div>
                                                    <div>
                                                        {(group_ids || []).map((group, ind) => {
                                                            if(groupList[group] === undefined || group === homeRoom) return;
                                                            const { attributes } = (groupList && groupList[group]) || {}
                                                            return(
                                                                <Tag className="student-profile-tags" style={{marginBottom: 5}} key={ind}>{attributes.groupname.replace("_", " ")}</Tag>
                                                            )
                                                        })}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <div>
                                                <div className="fw-600 text-center">Attendance Summary</div>
                                                <div className="flex mt-12">
                                                    <div>
                                                        <PieChart
                                                            animate={false}
                                                            animationDuration={500}
                                                            animationEasing="ease-out"
                                                            cx={50}
                                                            cy={50}
                                                            data={[
                                                                {
                                                                    color: '#3cc89c',
                                                                    title: 'One',
                                                                    value: 20
                                                                },
                                                                {
                                                                    color: '#d12323',
                                                                    title: 'Two',
                                                                    value: 20
                                                                },
                                                                {
                                                                    color: '#303998',
                                                                    title: 'Three',
                                                                    value: 60
                                                                }
                                                            ]}
                                                            label={false}
                                                            labelPosition={50}
                                                            labelStyle={{
                                                                fill: '#121212',
                                                                fontFamily: 'sans-serif',
                                                                fontSize: '5px'
                                                            }}
                                                            lengthAngle={360}
                                                            lineWidth={15}
                                                            onClick={undefined}
                                                            onMouseOut={undefined}
                                                            onMouseOver={undefined}
                                                            paddingAngle={0}
                                                            radius={50}
                                                            rounded
                                                            startAngle={0}
                                                            style={{
                                                                height: '75px',
                                                                width: '75px'
                                                            }}
                                                            viewBoxSize={[
                                                                100,
                                                                100
                                                            ]}
                                                        />
                                                    </div>
                                                    <div className="pl-8">
                                                        <div className="pb-2">
                                                            <span>Total Days - <b>{totalDays}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Absent - <b>{absent}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Present - <b>{present}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Virtual - <b>{virtual}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Tardy - <b>{tardy}</b></span>
                                                        </div>
                                                        <div className="pb-2">
                                                            <span>Unmarked - <b>{unmarked}</b></span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h5
                                                    className="text-center mt-8 cursor-pointer fs-16"
                                                    style={{color: '#212b9b'}}
                                                    onClick={() => this.props.history.push({
                                                        pathname: (isTeacher || isParent) ? `/student/attendance/${match.params.id}` : `/admin/student/attendance/${match.params.id}`,
                                                        state: {
                                                            firstName: firstName,
                                                            lastName: lastName,
                                                            // groups: group_ids,
                                                            profile_photo: profile_photo,
                                                            id: match.params.id
                                                        }
                                                    })}
                                                >
                                                    <b>View Attendance</b>
                                                </h5>

                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                        <Grid item md={12} lg={6}>
                            <Container className="rounded-1rem bg-white p-40 shadow">
                                <Grid container spacing={6} style={{marginTop: 0}}>
                                    { isParentModal &&
                                        <CreateParentModal
                                            isModal={isParentModal}
                                            closeModal={this.closeModal}
                                            childId={null}
                                            getCreatedParentDetails={this.getCreatedParentDetails}
                                            updateParentDetail={updateParentDetail || {}}
                                        />
                                    }
                                    {
                                        (parents || []).map((item, index) => {
                                            const { firstName = "", lastName = "", profile_photo = "", email = "", email_id = "", mobile = "", invited, relation } = item && item.attributes || {}
                                            return(
                                                <Grid key={index} item xs={6} style={{borderRight: index === 0 ? "1px solid #eee" : ""}}>
                                                    <div style={{marginTop: "-100px", textAlign: 'center'}}>
                                                        {
                                                            (profile_photo || image) ?
                                                                <ProfileAvatar
                                                                    size={90}
                                                                    src={profile_photo || image}
                                                                /> :
                                                                <ProfileAvatar
                                                                    size={90}
                                                                    text={`${firstName.toString().charAt(0).toUpperCase()} ${lastName.toString().charAt(0).toUpperCase()}`}
                                                                />
                                                        }
                                                        <div className="fs-18 fw-600">
                                                            <span>{firstName || ""} {lastName || ""}</span>
                                                            <Icon type="edit" theme="filled" className="edit-icon" style={{marginLeft: 5}} onClick={() => this.setState({updateParentDetail: item, isParentModal: true})}/>
                                                        </div>
                                                        <span style={{color: '#c2c2c2'}}>
                                                            Parent
                                                            {/*{ (relation || "") ? (relation || "").charAt(0).toUpperCase() + (relation || "").slice(1) : "Parent" }*/}
                                                        </span>
                                                    </div>
                                                    <div className="pb-4 mt-20 flex">
                                                        <div style={{width: 35}}>
                                                            <i className="fa fa-envelope fa-lg color-active" aria-hidden="true"/>
                                                        </div>
                                                        <span>{email || email_id || "-"}</span>
                                                    </div>
                                                    <div className="pb-4 flex">
                                                        <div style={{width: 35}}>
                                                            <i className="fa fa-phone fa-rotate-90 fa-lg color-active" aria-hidden="true"/>
                                                        </div>
                                                        <span>{mobile || "-"}</span>
                                                    </div>
                                                    <div className="pb-4 flex">
                                                        <div style={{width: 35}}>
                                                            <i className="fa fa-bus fa-lg color-active" aria-hidden="true"/>
                                                        </div>
                                                        <span>{"-" || "Approved for Pickup"}</span>
                                                    </div>
                                                    {
                                                        isAdmin ?
                                                            <>
                                                                <div className="pb-4 flex">
                                                                    <div style={{width: 35}}>
                                                                        <i className="fa fa-clipboard fa-lg color-active" style={{fontSize: 22}} aria-hidden="true"/>
                                                                    </div>
                                                                    {
                                                                        item.isInviting ? <Spin/> :
                                                                            <span>{invited === true ? <Button className="add-button" size="small" onClick={() => this.onInviteUser(item.id, index, true)}>Reset Password</Button> : <Button size='small' onClick={() => this.onInviteUser(item.id, index, false)}>Invite</Button> || "Sign Up Pending"}</span>
                                                                    }
                                                                </div>
                                                                <div className="pb-4 flex">
                                                                    <div style={{width: 35}}>
                                                                        <i className="fa fa-trash fa-lg" style={{fontSize: 20, color: '#ff4d4f'}} aria-hidden="true"/>
                                                                    </div>
                                                                    <Button size="small" type="danger" onClick={() => this.onDeleteParent(item)}>Delete Parent</Button>
                                                                </div>
                                                            </> : null
                                                    }
                                                </Grid>
                                            )
                                        })
                                    }

                                    {
                                        (!(parents || []).length || (parents || []).length === 1) && isAdmin ?
                                            <Grid item xs={6} style={{borderRight: !(parents || []).length ? "1px solid #eee" : ""}}>
                                                <div className="text-center">
                                                    <CreateParent length={0} childId={id} fetchStudentsList={this.fetchStudentsList}/>
                                                </div>
                                            </Grid>: null
                                    }

                                    {
                                        (parents || []).length ?
                                            <div>
                                                <small className="color-red">Parents have access to all Room's the child has access to</small>
                                            </div> : null
                                    }
                                    {
                                        !(parents || []).length && !isAdmin ?
                                            <div>
                                                <div>No Parent's Available</div>
                                            </div> : null
                                    }
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={6} style={{marginTop: 0}}>
                        <Grid item md={12} lg={6}>
                            <h4>Relatives/Family
                                <IconButton aria-label="add" size="small"  onClick={() => this.handleModal("family")}>
                                    <AddIcon/>
                                </IconButton>
                            </h4>

                            <Container className="rounded-1rem bg-white p-40 shadow">
                                <Grid container spacing={6}>
                                    <Grid item xs={12}>

                                        {
                                            (auth_users || []).length ?
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={auth_users}
                                                    renderItem={(item, index) => (
                                                        <List.Item
                                                            actions={[<div>
                                                                { this.state.viewIndex === index ?
                                                                    <>
                                                                        <div className="pb-4 mt-20 flex">
                                                                            <div style={{width: 35}}>
                                                                                <i className="fa fa-envelope fa-lg color-active" aria-hidden="true"/>
                                                                            </div>
                                                                            <span>{item.email}</span>
                                                                        </div>
                                                                        <div className="pb-4 flex">
                                                                            <div style={{width: 35}}>
                                                                                <i className="fa fa-phone fa-rotate-90 fa-lg color-active" aria-hidden="true"/>
                                                                            </div>
                                                                            <span>{item.mobile}</span>
                                                                        </div>
                                                                        <div className="pb-4 flex">
                                                                            <div style={{width: 35}}>
                                                                                <i className="fa fa-bus fa-lg color-active" aria-hidden="true"/>
                                                                            </div>
                                                                            <span>{ item.isPickup === "yes" ? "Approved for Pickup" : '' }</span>
                                                                        </div>
                                                                        <div className="pb-4 flex">
                                                                            <div style={{width: 35}}>
                                                                                <Tooltip title="Edit">
                                                                                    <Icon type="edit" theme="filled" className="edit-icon"
                                                                                          onClick={() => this.handleModal("family", {...item, index: index})}/>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div style={{width: 35}}>
                                                                                <Tooltip title="Delete">
                                                                                    <i className="fa fa-trash fa-lg" style={{color: '#ff4d4f'}} aria-hidden="true" onClick={() => this.onDeleteRelative(index)}/>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div style={{width: 35}}>
                                                                                <Tooltip title="Close">
                                                                                    <Icon type="close" onClick={() => this.setState({viewIndex: -1})} style={{fontSize: 20}} />
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    </> :
                                                                    <Button
                                                                        className="add-button mt-12"
                                                                        shape="round"
                                                                        onClick={() => this.setState({viewIndex: index})}
                                                                    >
                                                                        View Profile
                                                                    </Button> }
                                                            </div>]}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <div className="cursor-pointer">
                                                                        {
                                                                            item.image ? <Avatar src={item.image} aria-label="recipe" style={{backgroundColor: getColor(index)}} /> :
                                                                                <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                                    {`${(item && item.firstName || "").charAt(0).toUpperCase()}${(item && item.lastName || "").charAt(0).toUpperCase()}`}
                                                                                </Avatar>
                                                                        }
                                                                    </div>
                                                                }
                                                                title={<div className="fs-15 cursor-pointer">{item.firstName} {item.lastName}</div>}
                                                                description={<span className="cursor-pointer">{item.relation}</span>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                /> :
                                                <div>No Extended Relatives/Family available.</div>
                                        }
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                        <Grid item md={12} lg={6}>
                            <h4>Address</h4>
                            <Container className="rounded-1rem bg-white p-40 shadow">
                                <Grid container spacing={6}>
                                    <Grid item xs={12}>
                                        <div className="flex justify-between">
                                            {
                                                isAddressAvailable ? <div>No address available.</div> :
                                                    <div>
                                                        <div className="flex"><div className="fw-600 w-100-px">Address 1 :</div><div>{address1 || "-"}</div></div>
                                                        <div className="flex"><div className="fw-600 w-100-px">Address 2 :</div><div>{address2 || "-"}</div></div>
                                                        <div className="flex"><div className="fw-600 w-100-px">Address 3 :</div><div>{address3 || "-"}</div></div>
                                                        <div className="flex"><div className="fw-600 w-100-px">City :</div><div>{city || "-"}</div></div>
                                                        <div className="flex"><div className="fw-600 w-100-px">State :</div><div>{state || "-"}</div></div>
                                                        <div className="flex"><div className="fw-600 w-100-px">Postal Code :</div><div>{postalCode || "-"}</div></div>
                                                        <div className="flex"><div className="fw-600 w-100-px">Country :</div><div>{country || "-"}</div></div>
                                                    </div>
                                            }
                                            <div className="text-right">
                                                <Icon type="edit" theme="filled" className="edit-icon" onClick={() => this.handleModal("address")}/>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={6} style={{marginTop: 0}}>
                        <Grid item md={12} lg={6}>
                            <h4>Siblings</h4>
                            <Container className="rounded-1rem bg-white p-40 shadow">
                                <Grid container spacing={6}>
                                    <Grid item xs={12}>
                                        {
                                            (siblings || []).length ?
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={siblings}
                                                    renderItem={(item, index) => (
                                                        <List.Item onClick={() => isTeacher ? this.props.history.push(`/student/view/${item.id}`) : this.props.history.push(`/admin/student/view/${item.id}`)}>
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <div className="cursor-pointer">
                                                                        {
                                                                            item.profile_photo ? <Avatar src={item.profile_photo} aria-label="recipe" style={{backgroundColor: getColor(index)}} /> :
                                                                                <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                                    {`${(item && item.firstName || "").charAt(0).toUpperCase()}${(item && item.lastName || "").charAt(0).toUpperCase()}`}
                                                                                </Avatar>
                                                                        }
                                                                    </div>
                                                                }
                                                                title={<div className="fs-15 cursor-pointer">{item.firstName} {item.lastName}</div>}
                                                                description={<span className="cursor-pointer">{item.status}</span>}
                                                            />
                                                        </List.Item>
                                                    )}
                                                /> :
                                                <div>No siblings available.</div>
                                        }
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                        <Grid item md={12} lg={6}>
                            <h4>Schedule</h4>
                            <Container className="rounded-1rem bg-white p-40 shadow">
                                <Grid container spacing={6}>
                                    <Grid item xs={12}>
                                        <div> {
                                            !isScheduleAvailable ? <div>No Schedule Set.
                                                    <div className="text-right">
                                                        <Icon type="edit" theme="filled" className="edit-icon" onClick={() => this.handleModal('schedule')}/>
                                                    </div></div> :
                                                <div>
                                                    <Container>

                                                        <div className="text-right">
                                                            <Icon type="edit" theme="filled" className="edit-icon" onClick={() => this.handleModal('schedule')}/>
                                                        </div>
                                                        <Grid container spacing={6} style={{marginTop: 0}}>
                                                            <Grid item xs={12}>

                                                                <div>
                                                                    { (scheduleList || []).map((fields, index) => {
                                                                        console.log('fields',fields)
                                                                        console.log('fields.startTime',fields.startTime)
                                                                        let startTime = moment(fields.startTime).format('LT')
                                                                        let endTime = moment(fields.endTime).format('LT')
                                                                        console.log('starttime', startTime)
                                                                        return(
                                                                            <div key={index.toString()}>
                                                                                <Card className="shadow rounded-1rem mt-8">
                                                                                    <CardHeader

                                                                                        title={
                                                                                            <div onClick={() =>
                                                                                                console.log('Clicked ', index)
                                                                                                //this.setState({scheduleFields: fields, selectedScheduleIndex: index})
                                                                                            }>

                                                                                                <div className="fs-16">
                                                                                                    <Grid container spacing={3}>
                                                                                                        <Grid item xs={6}>
                                                                                                            <span className="fw-600">Start date:</span> {fields.startDate && moment(fields.startDate).format("MM/DD/YYYY")}
                                                                                                        </Grid>
                                                                                                        <Grid item xs={6}>
                                                                                                            <span className="fw-600">End date:</span> {fields.endDate && moment(fields.endDate).format("MM/DD/YYYY")}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </div>
                                                                                                <div className="fs-16">
                                                                                                    <Grid container spacing={3}>
                                                                                                        <Grid item xs={6}>
                                                                                                            <span className="fw-600">Start Time:</span> {startTime}
                                                                                                        </Grid>
                                                                                                        <Grid item xs={6}>
                                                                                                            <span className="fw-600">End Time:</span> {endTime}
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </div>
                                                                                                <div className="fs-16">
                                                                                                    <Grid container spacing={3}>
                                                                                                        <Grid item xs={6}>
                                                                                                            <span  className="fw-600">Selected Days:</span>
                                                                                                            { fields.selectedDays &&
                                                                                                            Object.keys(fields.selectedDays).map((day, i) => fields.selectedDays[day] ? <span key={i.toString()} style={{marginRight: 5}}>{`${getDays(day)}${Object.keys(fields.selectedDays).length - 1 === i ? "" : ","}`}</span> : null)
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        <Grid item xs={6}>
                                                                                                            <span className="fw-600">{fields.classType == "inClass" ? "In Class" : "Virtual"}</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    />
                                                                                </Card>
                                                                            </div>
                                                                        )
                                                                    }) }
                                                                </div>
                                                            </Grid>

                                                        </Grid>

                                                    </Container>
                                                </div>

                                        }</div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={6} style={{marginTop: 0}}>
                        <Grid item md={12} lg={6}>
                            <h4>Medical Report</h4>
                            <Container className="rounded-1rem bg-white p-40 shadow">
                                <div className="text-right">
                                    <Icon type="edit" style={{paddingLeft: 10, paddingRight: 5}} theme="filled" className="edit-icon" onClick={() => this.handleModal("medical")}/>
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <div className="fw-600">Primary Doctor Name</div>
                                        <div className="fs-16">{primaryDoctor || "-"}</div>
                                    </Grid>
                                    <Grid item xs>
                                        <div className="fw-600">Primary Doctor Contact</div>
                                        <div className="fs-16">{primaryDoctorContact || "-"}</div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs>
                                        <div className="fw-600">Current Medications</div>
                                        <div className="fs-16">{currentMedications || "-"}</div>
                                    </Grid>
                                    <Grid item xs>
                                        <div className="fw-600">Allergies</div>
                                        <div className="fs-16">{allergies || "-"}</div>
                                    </Grid>
                                </Grid>

                            </Container>
                        </Grid>
                    </Grid>
                </div>
                {/*<br/>
                <div className="p-card-title text-right mt-16">
                    <Button
                        size="large"
                        className="review-button"
                        onClick={() => this.props.history.push(routes.STUDENT_DASHBOARD)}
                    >
                        Done
                    </Button>
                </div>*/}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

StudentProfile.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(StudentProfile))