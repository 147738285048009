import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import 'react-quill/dist/quill.snow.css';
import {FormGroup, Col, Input, InputGroup, InputGroupAddon} from "reactstrap"
import EmailStats from '../common/EmailStats'
import Iframe from '../common/Iframe'
//import IframeResizer from 'iframe-resizer-react';
import {
    Modal,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Badge,
    DialogContentText,
    Grid,
    Typography,
    Button,
    CardMedia
} from '@material-ui/core';
import {
    Description,
    AddCircle,
    ExpandMore,
    ExpandLess
} from '@material-ui/icons';
import {Storage, API} from 'aws-amplify';
import moment from 'moment';
import Select from 'react-select';
import reactCSS from 'reactcss';
import ReactHtmlParser from 'react-html-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {NewsLetterStyles} from './components/style';
import addiconImage from './../../assets/images/3.png';
import Loader from "./components/Loader"
import NewsLetterContainer from "./components/CreateNewsLetter"
import Paper from "@material-ui/core/Paper/Paper";
import Cards from "./components/card"
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {isUserParent, getCurrentSchoolId, getRandomString} from "../../utils/utils";
import awsConfig from "../../utils/aws-config";

const profilePlaceholder = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlMNPszBzrblPa1wEIpULUqm9W2tDJpDL6lcz5cr6yZSpBjiVhTw&s"

const useStyles = makeStyles(theme => NewsLetterStyles(theme));

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    row: {
        display: 'flex',
        // justifyContent: 'center',
    },
    margin: {
        margin: theme.spacing(2),
    },
// margin: {
//     margin: theme.spacing.unit * 2
// },
    selectedBadge: {
        position: "static",
        backgroundColor: "#3f51b5",
        color: "white"
    },
    publishedBadge: {
        position: "static",
        backgroundColor: "rgb(3, 191, 208)",
        color: "white"
    },
    scheduleBadge: {
        position: "static",
        backgroundColor: "#9b289b",
        color: "white"
    },
    reviewBadge: {
        position: "static",
        backgroundColor: "#0d8ef5",
        color: "white"
    },
    draftBadge: {
        position: "static",
        backgroundColor: "#c87700",
        color: "white"
    },
    nonSelectedBadge: {
        position: "static",
        backgroundColor: "#000000",
        color: "white"
    }
}));

export function NewsLetter(props) {
    const iframeRef = React.createRef();
    var alreadyRendered = false
    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.onload = () => {
                // Trigger iframe resizing after content is loaded.
                console.log('!!!!!! RESIZING !!!!!!!!!')
                window.iFrameResize({}, iframeRef.current);
            };
        }
    }, []);
    useEffect(() => {

        getDataList()
        const interval = setInterval(() => {
            setisRender(true);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    const getDataList = async () => {

        let groups = []
        for (let item of Object.values(props.groupcache)) {
            if (!item.attributes.is_archive) {
                groups.push({
                    value: item.id,
                    label: item.attributes.groupname,
                    groupid: item.id,
                    groupname: item.attributes.groupname
                })
            }
        }
        groups.sort((a, b) => {
// Use the localeCompare method for case-insensitive alphabetical sorting
            return a.label.localeCompare(b.label, undefined, { sensitivity: 'base' });
        });
        const newsLetters = await fetchNewsLetter("")
        const templates = await fetchNewsLetterTemplates()
//console.log('templates', templates)
//console.log('newsLetters', newsLetters)
        const reviews = []
        const reviewList = props.usergroupcache

        let draft = [];
        let review = [];
        let publish = [];
        let schedule = [];
        let currentDate = new Date();
        for (let group of groups) {
            for (let news of newsLetters) {
                if (group.groupid === news.group_ids[0]) {
                    news.groupInfo = group
                }
            }
        }
        newsLetters.sort(function (a, b) {
            if (a.structure) {
                a = new Date(a.structure.letter_date);
                b = new Date(b.structure.letter_date);
            }
            return a > b ? -1 : a < b ? 1 : 0;
        });

        for (let item of newsLetters) {

            if (item.status_id === 0) {
                draft.push(item)
            }
            if (item.status_id === 1) {
                review.push(item)
            }
            if (item.status_id === 2) {
                const date = `${item.publish_date}.000Z`
                if (new Date(date) < currentDate) {
                    publish.push(item);
                } else {
                    item.tmpstatus = item.status_id;
                    item.status_id = 3;
                    schedule.push(item)
                }
            }
        }
        setMyLetter(prevState => {
            return {
                ...prevState,
                draft,
                review,
                publish,
                schedule,
                newsLetters,
                templates,
                reviewList,
                groups,
                newNewsLetter: [],
                loading: true,
                error: false
            }
        })
        setduplicateLetter(prevState => {
            return {
                ...prevState,
                draft,
                review,
                publish,
                schedule,
                newsLetters,
                reviewList,
                groups,
                newNewsLetter: [],
                loading: true,
                error: false
            }
        })
        setIsLoading(false)
    }

    const fetchNewsLetter = async (id) => {

        let init = {
            body: {
                status: isUserParent(props.currentUser) && props.communications ? [2, 1] : isUserParent(props.currentUser) ? [2] : [0, 1, 2],
                groups: props.groupcache && Object.keys(props.groupcache || {}).map(x => Number(x)) || []
            }
        }

        if (id) {
            init.body.id = id
        }
        const response = await API.post('newsletter_get', '', init).then(res => res)

        if (response && response.status === "success") {
            return response && response.data || []
        }
        return []
    }

    const fetchNewsLetterTemplates = async () => {

        let init = {
            body: {
                groups: props.groupcache && Object.keys(props.groupcache || {}).map(x => Number(x)) || []
            }
        }

        const response = await API.post('newsletter_templates', '', init).then(res => res)

        if (response && response.status === "success") {
            return response && response.data || []
        }
        return []
    }

    const fetchNewsLetterStats = async (data) => {
        console.log(data)
        console.log(props)
        let init = {
            body: {
                school_id: parseInt(props.school_id[0]) || null,
                id: data.id
            }
        }

        if (data.id) {
            init.body.id = data.id
        }
        const response = await API.post('newsletter_get_stats', '', init).then(res => res)

        return response
    }


// MyLetter.newNewsLetter

    const classes = useStyles();
    const [userRole, setuserRole] = React.useState(props.role);
    const [MyLetter, setMyLetter] = React.useState({});
    const [duplicateLetter, setduplicateLetter] = React.useState({});
    const [isRender, setisRender] = React.useState(false);
    const [isClone, setisClone] = React.useState(false);
    const [isPublish, setisPublish] = React.useState(false);
    const [designJSON, setdesignJSON] = React.useState({});
    const [html, sethtml] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);

    const [isDeleteModal, setisDeleteModal] = React.useState(false);
    const [isDeleteID, setisDeleteID] = React.useState('');

    const [modalStyle] = React.useState(getModalStyle);
    const [createLetter, setcreateLetter] = React.useState(false);
    const [filterDate, setfilterDate] = React.useState(null);
    const [groupFilter, setgroupFilter] = React.useState(null);
    const [searchFilter, setsearchFilter] = React.useState('');
    const [titlePicker, settitlePicker] = React.useState({
        displayColorPicker: false,
        // background:'',
        color: {
            r: '74',
            g: '123',
            b: '157',
            a: '1',
        },
    });

    const [backgroudColor, setbackgroundColor] = React.useState({
        displayColorPicker: false,
        color: {
            r: '245',
            g: '235',
            b: '235',
            a: '0.2',
        },
    });

    const [group, setgroup] = React.useState('');
    const [tabActive, settabActive] = React.useState(props.role.parent ? 2 : 0);
    const [createNewsLetter, setcreateNewsLetter] = React.useState({
        profileImageUrl: profilePlaceholder,
        profileImage: '',
        profileImageKey: '',
        date: new Date(),
        publish_date: '',
        reviews: [],
        group: [],
        title: '',
        background: '#FFFFFF',
        newsLetterId: '',
        newsLetterStatus: '',
        titleBackground: '#4A7B9D',
        isEdit: true,
        reviewersname: [],
        created_by: ''
    });

    const styles = reactCSS({
        default: {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${backgroudColor.color.r}, ${backgroudColor.color.g}, ${backgroudColor.color.b}, ${backgroudColor.color.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    const styles1 = reactCSS({
        default: {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${titlePicker.color.r}, ${titlePicker.color.g}, ${titlePicker.color.b}, ${titlePicker.color.a})`,
            },
            swatch: {
// padding: '5px',
// background: '#fff',
// borderRadius: '1px',
// boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
// display: 'inline-block',
// cursor: 'pointer',
                width: "70px",
                height: "30px",
                background: "#fff",
                boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
                cursor: "pointer",
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    const [isPreview, setisPreview] = React.useState(false);
    const [showStats, setshowStats] = React.useState(false);
    const [showStatsData, setshowStatsData] = React.useState({});

    const [isEditPreview, setIsEditPreview] = React.useState(false);
    const [isTopbar, setisTopbar] = React.useState(true);
    const [newsItem, setNewsItem] = React.useState({});
    const [isAdvance, setIsAdvance] = React.useState(false);

// const [startDate, setStartDate] = React.useState (new Date ());

    const [duplicateText, setDuplicateText] = React.useState("");
    const [addMenu, setAddmenu] = React.useState({
        isOpen: false,
        newsletter: [],
    });
    const [modal, setmodal] = React.useState({
        opemModal: false,
        imageurl: '',
    });

    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50 // 50 + rand ();
        const left = 50 // 50 + rand ();

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    function doCreateLetter() {
        setcreateNewsLetter({
            profileImageUrl: profilePlaceholder,
            profileImage: '',
            profileImageKey: '',
            date: new Date(),
            publish_date: '',
            reviews: [],
            group: [],
            title: '',
            background: '#FFFFFF',
            newsLetterId: '',
            isEdit: true,
            titleBackground: '#4A7B9D',
            reviewersname: [],
            created_by: ''
        });
        setAddmenu({
            isOpen: false,
            newsletter: [],
        });
        setcreateLetter(!createLetter);
        setisPreview(false);
        setisTopbar(false);
        setshowStats(false)
    }

    function doClose() {
        setisPreview(false);
        setshowStats(false)
        setisClone(false);
        if (createLetter && !isPreview) {
            setcreateLetter(false);
            setisTopbar(true);
        } else {
            if (createNewsLetter.newsLetterId) {
                setcreateLetter(false);
                setisTopbar(true);
            } else {
                setcreateLetter(true);
            }
        }
        setcreateNewsLetter({
            ...createNewsLetter,
            newsLetterId: '',
        });
        setbackgroundColor({
            displayColorPicker: false,
            color: {
                r: '245',
                g: '235',
                b: '235',
                a: '0.2',
            }
        })
        settitlePicker({
            displayColorPicker: false,
            color: {
                r: '57',
                g: '27',
                b: '201',
                a: '1',
            }
        })
    }

    const handleTabChange = status => {
// if (isUserParent(props.currentUser)) {
//     return false;
// } else {
        settabActive(status);
// }
    };

    const onEditPreview = (value) => {
        setisPreview(value)
        setIsEditPreview(value)
    }

    const openImageModal = item => {
        setmodal({
            ...modal,
            opemModal: true,
            imageurl: item,
        });
    };
    const handleClose = () => {
        setmodal({
            ...modal,
            opemModal: false,
            imageurl: '',
        });
    };

    const addMenuOption = (index, status) => {
        let resetMenu = addMenu.newsletter;
        for (let item of resetMenu) {
            item.isOpen = false;
        }
        if (status === 1) {
            resetMenu[index].isOpen = true;
            setAddmenu({
                ...addMenu,
                isOpen: !addMenu.isOpen,
                newsletter: resetMenu,
            });
        } else {
            setAddmenu({
                ...addMenu,
                isOpen: !addMenu.isOpen,
                newsletter: resetMenu,
            });
        }
    };

    const closeNewTopic = index => {
        addMenu.newsletter[index].isEdit = false;
        setAddmenu({
            ...addMenu,
            newsletter: addMenu.newsletter,
        });
        setDuplicateText("")
    };

    const cancelNewTopic = (index) => {
//console.log('Cancel .')
        addMenu.newsletter[index].isEdit = false;
        addMenu.newsletter[index].textTitle = duplicateText;
        setAddmenu({
            ...addMenu,
            newsletter: addMenu.newsletter,
        });
        setDuplicateText("")
    }

    const addNewTopic = (status, index) => {
        if (status === 0) {
            let addContent = addMenu.newsletter;
            addContent.push({
                isEdit: true,
                textTitle: '',
                type: 'TITLE',
                isOpenChild: false,
                displayColorPicker: false,
                background: '',
            });
            setAddmenu({
                ...addMenu,
                newsletter: addContent,
                isOpen: false,
            });
        } else {
            let addContent = addMenu.newsletter;
            addContent[index].isOpenChild = false;
            let addnew = {
                isEdit: true,
                textTitle: '',
                type: 'TITLE',
                displayColorPicker: false,
                background: '',
            };
            addContent.splice(index, 0, addnew);
            setAddmenu({
                ...addMenu,
                newsletter: addContent,
            });
        }
    };

    const addSubOption = index => {
        let addContent = addMenu.newsletter;

        if (addMenu.newsletter[index].isOpenChild) {
            addMenu.newsletter[index].isOpenChild = false;
        } else {
            addMenu.newsletter[index].isOpenChild = true;
        }
        setAddmenu({
            ...addMenu,
            newsletter: addContent,
        });
    };

    const selectedContent = (status, index) => {
        if (status == 0) {
            let addContent = addMenu.newsletter;
            addContent.push({
                isEdit: true,
                textTitle: '',
                type: 'DESCRIPTION',
                isOpenChild: false,
            });
            setAddmenu({
                ...addMenu,
                newsletter: addContent,
                isOpen: false,
            });
        } else {
            let addContent = addMenu.newsletter;
            addContent[index].isOpenChild = false;
            let addnew = {
                isEdit: true,
                textTitle: '',
                type: 'DESCRIPTION',
            };
            addContent.splice(index, 0, addnew);
            setAddmenu({
                ...addMenu,
                newsletter: addContent,
            });
        }
    };

    const editContent = index => {
        addMenu.newsletter[index].isEdit = true;
        setAddmenu({
            ...addMenu,
            newsletter: addMenu.newsletter,
        });
        setDuplicateText(addMenu.newsletter[index].textTitle || "")
    };

    async function uploadFile(file) {
        const rString = getRandomString()
        const fileName = (file.name || "").split(".")
        console.log('fileobj', file)
        const path = `newsletter/${moment().year()}/${moment().month() + 1}/${moment().date()}/${fileName[0]}_${rString}.${fileName[1]}`;

        await Storage.put(path, file, {contentType: file.type});
        return path;
    }

    async function selectImage(event, status, index) {
        if (event.target.files[0]) {
            if (status === 0) {
                let addContent = addMenu.newsletter;
                let multipleImage = [];

                if (event.target.files && event.target.files.length > 0) {
                    for (let items of event.target.files) {
                        let imageURL = await uploadFile(items);

                        multipleImage.push({
                            type: 'IMAGES',
                            showImage: imageURL,
                            key: imageURL,
                            isOpenChild: false,
                        });
                    }
                }
                addContent.push({
                    multipleImage: multipleImage,
                    type: 'IMAGES',
                    isOpenChild: false,
                });
                setAddmenu({
                    ...addMenu,
                    newsletter: addContent,
                    isOpen: false,
                });
            } else {
                let addContent = addMenu.newsletter;
                addContent[index].isOpenChild = false;
                let multipleImage = [];

                if (event.target.files && event.target.files.length > 0) {
                    for (let items of event.target.files) {
                        let imageURL = await uploadFile(items);
                        multipleImage.push({
                            type: 'IMAGES',
                            showImage: imageURL,
                            key: imageURL,
                            isOpenChild: false,
                        });
                    }
                }
                let addnew = {
                    multipleImage: multipleImage,
                    type: 'IMAGES',
                    isOpenChild: false,
                };
                addContent.splice(index, 0, addnew);

                setAddmenu({
                    ...addMenu,
                    newsletter: addContent,
                });
            }
        }
    }

    async function selectVideo(event, status, index) {
        if (event.target.files[0]) {
            if (status === 0) {
                let addContent = addMenu.newsletter;
                let imageURL = await uploadFile(event.target.files[0]);

                addContent.push({
                    type: 'VIDEOS',
                    showVideo: imageURL,
                    key: imageURL,
                    isOpenChild: false
                });
                setAddmenu({
                    ...addMenu,
                    newsletter: addContent,
                    isOpen: false,
                });
            } else {
                let imageURL = await uploadFile(event.target.files[0]);

                let addContent = addMenu.newsletter;
                addContent[index].isOpenChild = false;
                let addnew = {
                    type: 'VIDEOS',
                    showVideo: imageURL,
                    key: imageURL,
                    isOpenChild: false,
                };
                addContent.splice(index, 0, addnew);
                setAddmenu({
                    ...addMenu,
                    newsletter: addContent,
                });
            }
        }
    }

    async function selectDocument(event, status, index) {
        if (event.target.files[0]) {
            if (status === 0) {
                let addContent = addMenu.newsletter;
                let imageURL = await uploadFile(event.target.files[0]);

                addContent.push({
                    type: 'DOCUMENTS',
                    // data: event.target.files[0],
                    doc: imageURL,
                    key: imageURL,
                    isOpenChild: false,
                });
                setAddmenu({
                    ...addMenu,
                    newsletter: addContent,
                    isOpen: false,
                });
            } else {
                let imageURL = await uploadFile(event.target.files[0]);
                let addContent = addMenu.newsletter;
                addContent[index].isOpenChild = false;
                let addnew = {
                    type: 'DOCUMENTS',
                    doc: imageURL,
                    key: imageURL,
                    isOpenChild: false,
                };
                addContent.splice(index, 0, addnew);
                setAddmenu({
                    ...addMenu,
                    newsletter: addContent,
                });
            }
        }
    }

    const handleChangeUpdateTitle = (value, index) => {
        if (value) {
            addMenu.newsletter[index].textTitle = value;
            setAddmenu({
                ...addMenu,
                newsletter: addMenu.newsletter,
            });
        }
    };

    const removeItem = index => {
        addMenu.newsletter.splice(index, 1);
        setAddmenu({
            ...addMenu,
            newsletter: addMenu.newsletter,
        });
    };
    const removeImage = (index, image) => {

        addMenu.newsletter[index].multipleImage.splice(image, 1);
        setAddmenu({
            ...addMenu,
            newsletter: addMenu.newsletter,
        });
    };

    const handleChangeMultiple = value => {
        setcreateNewsLetter({
            ...createNewsLetter,
            reviews: value || [],
        });
    };
    const handleGroupChange = event => {
// setgroup (event);
        setcreateNewsLetter({
            ...createNewsLetter,
            group: event,
        });
    };
    const handleDateChange = event => {
        setcreateNewsLetter({
            ...createNewsLetter,
            date: event,
        });
    };
    const handleTitlehange = event => {
        if (event.target.value) {
            setcreateNewsLetter({
                ...createNewsLetter,
                title: event.target.value,
            });
        } else {
            setcreateNewsLetter({
                ...createNewsLetter,
                title: '',
            });
        }
    };

    async function selectProfileImage(event) {
        if (event.target.files[0]) {
            const imageURL = await uploadFile(event.target.files[0]);

            setcreateNewsLetter({
                ...createNewsLetter,
                profileImage: imageURL,
                profileImageKey: imageURL,
            });
        }
    }

    const openPicker = () => {
        setbackgroundColor({
            ...backgroudColor,
            displayColorPicker: !backgroudColor.displayColorPicker,
        });
    };
    const closePicker = () => {
        setbackgroundColor({
            ...backgroudColor,
            displayColorPicker: false,
        });
    };
    const selectPicker = color => {
        setcreateNewsLetter({
            ...createNewsLetter,
            background: color.hex,
        });
        setbackgroundColor({
            ...backgroudColor,
            color: color.rgb,
        });
    };

    const selectPublishDate = date => {
        setcreateNewsLetter({
            ...createNewsLetter,
            publish_date: date,
        });
// //console.log({date, newDate: new Date( date.getTime() + Math.abs(date.getTimezoneOffset()*60000) ), publish_date: createNewsLetter.publish_date})
    }

    const openTitlePicker = () => {
        settitlePicker({
            ...titlePicker,
            displayColorPicker: true,
        });
    };

    const closeTitlePicker = () => {
        settitlePicker({
            ...titlePicker,
            displayColorPicker: false,
        });
    };

    const selectTitlePicker = color => {
        settitlePicker({
            ...titlePicker,
            color: color.rgb,
        });
        setcreateNewsLetter({
            ...createNewsLetter,
            titleBackground: color.hex,
        });
    };

    const doUpdateStatusNew = async (status, datahtml=html, dataJson = designJSON) => {
        console.log('html', html)
        console.log('Status', status)
        setIsLoading(true)
        let groupID = [];
        if ((createNewsLetter.group || []).length) {
            groupID = (createNewsLetter.group || []).map(x => x.groupid) || [];
        }

        let content = [];
        for (let item of addMenu.newsletter) {
            if (item.type === 'TITLE' || item.type === 'DESCRIPTION') {
                content.push({type: item.type.toUpperCase(), val: item.textTitle});
            }
            if (item.type === 'IMAGES') {
                let images = [];
                if (item.multipleImage && item.multipleImage.length > 0) {
                    for (let items of item.multipleImage) {
                        images.push(items.key);
                    }
                    content.push({type: item.type.toUpperCase(), val: images});
                }
            }
            if (item.type === 'VIDEOS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
            if (item.type === 'DOCUMENTS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
        }

        let reviewList = [];
        for (let item of createNewsLetter.reviews) {
            reviewList.push(item.value);
        }
        let sendRequest = {
            letter_id: createNewsLetter.newsLetterId,
            reviewers: reviewList,
            status: status === 3 ? 2 : status,
            groups: groupID,
            child_ids: [],
            publish_date: createNewsLetter.publish_date ? createNewsLetter.publish_date : '',
            structure: {
                html: datahtml,
                is_new : 1,
                designjson : dataJson,
                letter_title: createNewsLetter.title,
                letter_image: createNewsLetter.profileImageKey,
                letter_date: createNewsLetter.date,
                content: content,
                background_color: createNewsLetter.background,
                title_background: createNewsLetter.titleBackground,
                create_date: createNewsLetter.create_date,
                updated_date: new Date(),
            },
            created_by: createNewsLetter.created_by || props.currentUser.id
        };

// doUpdate (sendRequest);
        const init = {
            body: {
                ...sendRequest
            }
        }
        const reviews = await API.post('newsletter_edit', '', init).then(res => res)
//console.log({reviews})

        const interval = setInterval(() => {
            getDataList()
            if (!MyLetter.newNewsLetter) {
                setisRender(!isRender);
            } else {
                setcreateNewsLetter({
                    ...createNewsLetter,
                    newsLetterId: '',
                });
                setcreateLetter(false);
                setisPreview(false);
                setisClone(false);
                setisTopbar(true);
                setisPublish(false)
                setisDeleteModal(false);
                setbackgroundColor({
                    displayColorPicker: false,
                    color: {
                        r: '245',
                        g: '235',
                        b: '235',
                        a: '0.2',
                    }
                })
                settitlePicker({
                    displayColorPicker: false,
                    color: {
                        r: '57',
                        g: '27',
                        b: '201',
                        a: '1',
                    }
                })
                clearInterval(interval);
            }
        }, 3000);
    }

    const doUpdateStatus = async status => {
        console.log('status-<>', status)
        setIsLoading(true)
        let groupID = [];
        if ((createNewsLetter.group || []).length) {
            groupID = (createNewsLetter.group || []).map(x => x.groupid) || [];
        }

        let content = [];
// for(let item of createNewsLetter.group){
//   groupID.push(item.groupid)
// }
        for (let item of addMenu.newsletter) {
            if (item.type === 'TITLE' || item.type === 'DESCRIPTION') {
                content.push({type: item.type.toUpperCase(), val: item.textTitle});
            }
            if (item.type === 'IMAGES') {
                let images = [];
                if (item.multipleImage && item.multipleImage.length > 0) {
                    for (let items of item.multipleImage) {
                        images.push(items.key);
                    }
                    content.push({type: item.type.toUpperCase(), val: images});
                }
            }
            if (item.type === 'VIDEOS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
            if (item.type === 'DOCUMENTS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
        }

        let reviewList = [];
        for (let item of createNewsLetter.reviews) {
            reviewList.push(item.value);
        }
        let sendRequest = {
            letter_id: createNewsLetter.newsLetterId,
            reviewers: reviewList,
            status: status === 3 ? 2 : status,
            groups: groupID,
            child_ids: [],
            publish_date: createNewsLetter.publish_date ? createNewsLetter.publish_date : '',
            structure: {
                html: document.getElementById("newsletterview").outerHTML,
                letter_title: createNewsLetter.title,
                letter_image: createNewsLetter.profileImageKey,
                letter_date: createNewsLetter.date,
                content: content,
                background_color: createNewsLetter.background,
                title_background: createNewsLetter.titleBackground,
                create_date: createNewsLetter.create_date,
                updated_date: new Date(),
            },
            created_by: createNewsLetter.created_by || props.currentUser.id
        };

// doUpdate (sendRequest);
        const init = {
            body: {
                ...sendRequest
            }
        }
        const reviews = await API.post('newsletter_edit', '', init).then(res => res)
//console.log({reviews})

        const interval = setInterval(() => {
            getDataList()
            if (!MyLetter.newNewsLetter) {
                setisRender(!isRender);
            } else {
                setcreateNewsLetter({
                    ...createNewsLetter,
                    newsLetterId: '',
                });
                setcreateLetter(false);
                setisPreview(false);
                setisClone(false);
                setisTopbar(true);
                setisPublish(false)
                setisDeleteModal(false);
                setbackgroundColor({
                    displayColorPicker: false,
                    color: {
                        r: '245',
                        g: '235',
                        b: '235',
                        a: '0.2',
                    }
                })
                settitlePicker({
                    displayColorPicker: false,
                    color: {
                        r: '57',
                        g: '27',
                        b: '201',
                        a: '1',
                    }
                })
                clearInterval(interval);
            }
        }, 3000);
// return () => clearInterval (interval);
    };

    const setisPublishNew = async (status, html, designjson) => {
        setisPublish(status)
        setdesignJSON(designjson)
        sethtml(html)
    }

    const submitDataNew = async (status, type, html,designjson) => {
        console.log(status, html)
        console.log('type', type)
        // let groupID = [createNewsLetter.group.groupid];
        setIsLoading(true)
        let content = [];
//console.log('createNewsLetter.group', createNewsLetter.group)
        let groupID = [];

        if ((createNewsLetter.group || []).length) {
            groupID = (createNewsLetter.group || []).map(x => x.groupid) || [];
        }
        console.log(groupID)
// for(let item of createNewsLetter.group){
//   groupID.push(item.groupid)
// }
        for (let item of addMenu.newsletter) {
            if (item.type === 'TITLE' || item.type === 'DESCRIPTION') {
                content.push({type: item.type.toUpperCase(), val: item.textTitle});
            }
            if (item.type === 'IMAGES') {
                let images = [];
                if (item.multipleImage && item.multipleImage.length > 0) {
                    for (let items of item.multipleImage) {
                        images.push(items.key);
                    }
                    content.push({type: item.type.toUpperCase(), val: images});
                }
            }
            if (item.type === 'VIDEOS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
            if (item.type === 'DOCUMENTS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
        }
        let reviewList = [];
        for (let item of createNewsLetter.reviews) {
            reviewList.push(item.value);
        }

        if (status === 4) {
            let sendRequest = {
                letter_id: createNewsLetter.newsLetterId,
                reviewers: reviewList,
                status: createNewsLetter.newsLetterStatus === 3 ? 2 : createNewsLetter.newsLetterStatus,
                groups: groupID,
                child_ids: [],
                publish_date: createNewsLetter.publish_date ? createNewsLetter.publish_date : "",
                structure: {
                    html: html,
                    is_new : 1,
                    designjson : designjson,
                    letter_title: createNewsLetter.title,
                    letter_image: createNewsLetter.profileImageKey,
                    letter_date: createNewsLetter.date,
                    content: content,
                    background_color: createNewsLetter.background,
                    title_background: createNewsLetter.titleBackground,
                    create_date: createNewsLetter.create_date || new Date(),
                    updated_date: new Date(),
                },
                created_by: type === "copy" ? props.userid : createNewsLetter.created_by
            };

// doUpdate (sendRequest);
            const init = {
                body: {
                    ...sendRequest
                }
            }
            const reviews = await API.post('newsletter_edit', '', init).then(res => res)
//console.log({reviews})
        } else {
//console.log('Status --->', status)
            let sendRequest = {
                reviewers: reviewList,
                status: type === "copy" ? 0 : status === 3 ? 2 : status,
                groups: groupID,
                is_new : 1,
                child_ids: [],
                userid: props.userid,
                publish_date: createNewsLetter.publish_date ? createNewsLetter.publish_date : '',
                // publish_date: '',
                structure: {
                    html: html,
                    is_new : 1,
                    designjson : designjson,
                    letter_title: createNewsLetter.title,
                    letter_image: createNewsLetter.profileImageKey,
                    letter_date: createNewsLetter.date,
                    content: content,
                    background_color: createNewsLetter.background,
                    title_background: createNewsLetter.titleBackground,
                    create_date: createNewsLetter.create_date || new Date(),
                    updated_date: new Date()
                },
                created_by: type === "copy" ? props.userid : createNewsLetter.created_by
            };

// doCreate (sendRequest);
            const init = {
                body: {
                    ...sendRequest
                }
            }
            const reviews = await API.post('newsletter_create', '', init).then(res => res)
//console.log({reviews})
        }

        const interval = setInterval(() => {
            getDataList()
            if (!MyLetter.newNewsLetter) {
                setisRender(!isRender);
            } else {
                if (type !== "done") {
                    setcreateLetter(false);
                    setisClone(false);
                    setisTopbar(true);
                    setisPublish(false);
                    setisDeleteModal(false);
                    settabActive(type === "copy" ? 0 : tabActive)
                    setbackgroundColor({
                        displayColorPicker: false,
                        color: {
                            r: '245',
                            g: '235',
                            b: '235',
                            a: '0.2',
                        }
                    })
                    settitlePicker({
                        displayColorPicker: false,
                        color: {
                            r: '57',
                            g: '27',
                            b: '201',
                            a: '1',
                        }
                    })
                }
                clearInterval(interval);
            }
        }, 3000);
        return () => clearInterval(interval);
    };

    const submitData = async (status, type) => {
        console.log(type)
        console.log('createNewsLetter', createNewsLetter)
        console.log('createNewsLetter.structure', createNewsLetter.structure)
        console.log('document',document)
        // let groupID = [createNewsLetter.group.groupid];
        setIsLoading(true)
        let content = [];
//console.log('createNewsLetter.group', createNewsLetter.group)
        let groupID = [];

        if ((createNewsLetter.group || []).length) {
            groupID = (createNewsLetter.group || []).map(x => x.groupid) || [];
        }
        console.log(groupID)
// for(let item of createNewsLetter.group){
//   groupID.push(item.groupid)
// }
        for (let item of addMenu.newsletter) {
            if (item.type === 'TITLE' || item.type === 'DESCRIPTION') {
                content.push({type: item.type.toUpperCase(), val: item.textTitle});
            }
            if (item.type === 'IMAGES') {
                let images = [];
                if (item.multipleImage && item.multipleImage.length > 0) {
                    for (let items of item.multipleImage) {
                        images.push(items.key);
                    }
                    content.push({type: item.type.toUpperCase(), val: images});
                }
            }
            if (item.type === 'VIDEOS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
            if (item.type === 'DOCUMENTS') {
                content.push({type: item.type.toUpperCase(), val: [item.key]});
            }
        }
        let reviewList = [];
        for (let item of createNewsLetter.reviews) {
            reviewList.push(item.value);
        }

        if (status === 4) {
            let sendRequest = {
                letter_id: createNewsLetter.newsLetterId,
                reviewers: reviewList,
                status: createNewsLetter.newsLetterStatus === 3 ? 2 : createNewsLetter.newsLetterStatus,
                groups: groupID,
                child_ids: [],
                publish_date: createNewsLetter.publish_date ? createNewsLetter.publish_date : "",
                structure: {
                    html: createNewsLetter.structure.is_new === 1 ? createNewsLetter.structure.html :
                        document.getElementById("newsletterview").outerHTML,
                    is_new : createNewsLetter.structure.is_new,
                    designjson : createNewsLetter.structure.is_new === 1 ?
                        createNewsLetter.structure.designjson : "",
                    letter_title: createNewsLetter.title,
                    letter_image: createNewsLetter.profileImageKey,
                    letter_date: createNewsLetter.date,
                    content: content,
                    background_color: createNewsLetter.background,
                    title_background: createNewsLetter.titleBackground,
                    create_date: createNewsLetter.create_date || new Date(),
                    updated_date: new Date(),
                },
                created_by: type === "copy" ? props.userid : createNewsLetter.created_by
            };

// doUpdate (sendRequest);
            const init = {
                body: {
                    ...sendRequest
                }
            }
            const reviews = await API.post('newsletter_edit', '', init).then(res => res)
//console.log({reviews})
        } else {
//console.log('Status --->', status)
//#TODO: Bug where copy fails unless the template is opened
            let sendRequest = {
                reviewers: reviewList,
                status: type === "copy" ? 0 : status === 3 ? 2 : status,
                groups: groupID,
                child_ids: [],
                userid: props.userid,
                publish_date: createNewsLetter.publish_date ? createNewsLetter.publish_date : '',
                // publish_date: '',
                structure: {
                    html: createNewsLetter.structure?.is_new === 1 ? createNewsLetter.structure.html : document.getElementById("newsletterview").outerHTML,
                    is_new : createNewsLetter.structure?.is_new === undefined ? true : false,
                    designjson : createNewsLetter.structure?.is_new === 1 ? createNewsLetter.structure.designjson : "",
                    letter_title: createNewsLetter.title,
                    letter_image: createNewsLetter.profileImageKey,
                    letter_date: createNewsLetter.date,
                    content: content,
                    background_color: createNewsLetter.background,
                    title_background: createNewsLetter.titleBackground,
                    create_date: createNewsLetter.create_date || new Date(),
                    updated_date: new Date(),

                },
                created_by: type === "copy" ? props.userid : createNewsLetter.created_by
            };

// doCreate (sendRequest);
            const init = {
                body: {
                    ...sendRequest
                }
            }
            const reviews = await API.post('newsletter_create', '', init).then(res => res)
//console.log({reviews})
        }

        const interval = setInterval(() => {
            getDataList()
            if (!MyLetter.newNewsLetter) {
                setisRender(!isRender);
            } else {
                if (type !== "done") {
                    setcreateLetter(false);
                    setisClone(false);
                    setisTopbar(true);
                    setisPublish(false);
                    setisDeleteModal(false);
                    settabActive(type === "copy" ? 0 : tabActive)
                    setbackgroundColor({
                        displayColorPicker: false,
                        color: {
                            r: '245',
                            g: '235',
                            b: '235',
                            a: '0.2',
                        }
                    })
                    settitlePicker({
                        displayColorPicker: false,
                        color: {
                            r: '57',
                            g: '27',
                            b: '201',
                            a: '1',
                        }
                    })
                }
                clearInterval(interval);
            }
        }, 3000);
        return () => clearInterval(interval);
    };

    const doSearch = event => {
        setsearchFilter(event.target.value);

        let filterData = event.target.value
            ? duplicateLetter.newsLetters.filter(
                row =>
                    row.structure.letter_title
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) > -1
            )
            : duplicateLetter.newsLetters;

        setMyLetter({
            ...MyLetter,
            newsLetters: filterData,
        });
    };

    const handlefilterDateChange = event => {
        const result = duplicateLetter.newsLetters.filter(function (item) {
            const {create_date, letter_date} = item && item.structure || {}
            const itemTime = new Date(create_date || letter_date || item && item.publish_date).toDateString();
            return itemTime === new Date(event).toDateString();
        });

        setfilterDate(event);
        setMyLetter({
            ...MyLetter,
            newsLetters: result,
        });
    };

    const changeGroupFilter = event => {

        let filterLetter = [];

        for (let item of duplicateLetter.newsLetters) {
            for (let groups of item.group_ids) {
                if (groups === event.groupid) {
                    filterLetter.push(item);
                }
            }
        }
        setgroupFilter(event);
        setMyLetter({
            ...MyLetter,
            newsLetters: filterLetter,
        });
    };

    const resetFilter = () => {
        setgroupFilter(null);
        setfilterDate(null);
        setsearchFilter('');

        setMyLetter({
            ...MyLetter,
            newsLetters: duplicateLetter.newsLetters,
        });
    };

    const isAdvanceChange = () => {
        setgroupFilter(null);
        setfilterDate(null);
// setsearchFilter ('');
        setIsAdvance(!isAdvance)
        setMyLetter({
            ...MyLetter,
            newsLetters: duplicateLetter.newsLetters,
        });
    }

    async function openPublishedData(items) {
        console.log('here222')
        let data = {}
        setIsLoading(true)
        let response = await fetchNewsLetterStats(items.data)
        console.log(response)
        data['data'] = response
        data['item'] = items.data
        console.log(data)
        setshowStats(true)
        setshowStatsData(data)
        console.log('here')
    }

    async function doCopy(data) {

        setIsLoading(true)
        console.log('copying ', data)
        const item = await fetchNewsLetter(data.id) || {}
        console.log('resp for copy', item)
        if (!Object.keys(item).length) return;
        setIsLoading(false)

        handleClosed()
        let reviews = [];
        for (let _item of item.reviewers) {
            if (_item in props.usercache) {
                let user = props.usercache[_item]
                reviews.push({label: user.firstName + ' ' + user.lastName, id: _item, value: _item, email: user.id});
            }
        }
        console.log(item)
        setcreateNewsLetter({
            ...createNewsLetter,
            profileImage: (item.structure.letter_image !== createNewsLetter.profileImageUrl) ? item.structure.letter_image : createNewsLetter.profileImageUrl,
            profileImageKey: item.structure.letter_image || "",
            date: new Date(item.structure.letter_date),
            reviews: reviews,
            group: getSelectedGroupList(item.group_ids || []),
            title: '',
            structure : item.structure,
            background: item.structure.background_color,
            newsLetterId: item.id,
            isEdit: false,
            newsLetterStatus: item.status === 3 ? item.tmpstatus : item.status,
            created_by: item.created_by || props.currentUser.id
        });


        let itemsData = [];
        for (let item of item.structure.content) {
            if (item.type === 'TITLE' || item.type === 'DESCRIPTION') {
                itemsData.push({type: item.type, textTitle: item.val});
            }
            if (item.type === 'IMAGES') {
                let showImage = [];
                for (let img of item.val) {
                    showImage.push({showImage: img, key: img});
                }
                itemsData.push({type: item.type, multipleImage: showImage});
            }
            if (item.type === 'VIDEOS') {
                itemsData.push({
                    type: item.type,
                    key: item.val,
                    showVideo: [item.val[0]],
                });
            }
            if (item.type === 'DOCUMENTS') {
                itemsData.push({
                    type: item.type,
                    val: [item.val[0]],
                    key: item.val,
                });
            }
        }
        setAddmenu({
            ...addMenu,
            newsletter: itemsData,
        });

        setisClone(true);
    }

    const getSelectedGroupList = (list) => {
        let array = []
        console.log(MyLetter)
        console.log(MyLetter.groups)
        MyLetter.groups.forEach(group => {
            if ((list || []).includes(group.groupid)) {
                array.push(group)
            }
        })
        console.log(array)
        if (array.length === 0) {
            console.log(list)

            //this is because a user is copying a newsletter which he/she does not have access to
            return [MyLetter.groups[0]]
        } else {
            return array
        }

    }

    async function goPreview(data) {
        setIsLoading(true)
        console.log(data)
        console.log(MyLetter)
        const item = await fetchNewsLetter(data.id) || {}
        if (!Object.keys(item).length) return;
        setIsLoading(false)
        console.log('item', item)
        let reviews = [];
        for (let _item of item.reviewers) {
            if (_item in props.usercache) {
                let user = props.usercache[_item]
                reviews.push({label: user.firstName + ' ' + user.lastName, id: _item, value: _item, email: user.id});
            }

        }
        setcreateNewsLetter({
            ...createNewsLetter,
            structure : item.structure,
            profileImage: (item.structure.letter_image !== createNewsLetter.profileImageUrl && item.structure.letter_image !== '') ? item.structure.letter_image : createNewsLetter.profileImageUrl,
            profileImageKey: item.structure.letter_image || "",
            date: new Date(item.structure.letter_date),
            publish_date: item.publish_date ? new Date(item.publish_date) : '',
            reviews: reviews,
            reviewList: props.usergroupcache,
            group: getSelectedGroupList(item.group_ids || []),
            title: item.structure.letter_title,
            background: item.structure.background_color,
            newsLetterId: item.id,
            isEdit: false,
            newsLetterStatus: item.status_id,
            titleBackground: item.structure.title_background,
            reviewersname: item.reviewer_names,
            create_date: (item.structure && item.structure.create_date) || new Date(),
            updated_date: new Date(),
            created_by: item.created_by || props.currentUser.id
        });

        if (item.structure.background_color) {
            const color = hexToRgbA(item.structure.background_color)
            setbackgroundColor({
                displayColorPicker: false,
                color: {
                    r: color[0],
                    g: color[1],
                    b: color[2],
                    a: color[3],
                }
            })
        }
        if (item.structure.title_background) {
            const color = hexToRgbA(item.structure.title_background)
            settitlePicker({
                displayColorPicker: false,
                color: {
                    r: color[0],
                    g: color[1],
                    b: color[2],
                    a: color[3],
                }
            })
        }

        let itemsData = [];
        for (let item of item.structure.content) {
            if (item.type === 'TITLE' || item.type === 'DESCRIPTION') {
                itemsData.push({type: item.type, textTitle: item.val});
            }
            if (item.type === 'IMAGES') {
                let showImage = [];
                for (let img of item.val) {
                    showImage.push({showImage: img, key: img});
                }
                itemsData.push({type: item.type, multipleImage: showImage});
            }
            if (item.type === 'VIDEOS') {
                itemsData.push({
                    type: item.type,
                    key: item.val,
                    showVideo: [item.val[0]],
                });
            }
            if (item.type === 'DOCUMENTS') {
                itemsData.push({
                    type: item.type,
                    val: [item.val[0]],
                    key: item.val,
                });
            }
        }

        setAddmenu({
            ...addMenu,
            newsletter: itemsData,
        });
        setcreateLetter(true);
        setisPreview(true);
        setisTopbar(false);
        setisDeleteModal(false);

    }

    const doEdit = () => {
        let data = []
        // console.log('MyLetter.reviewList', MyLetter.reviewList)

        // console.log('createNewsLetter.reviews', createNewsLetter.reviews)
        // if (createNewsLetter.reviews && createNewsLetter.reviews.length) {
//     createNewsLetter.reviews.forEach(r => {
//         console.log(r)
//         const obj = MyLetter.reviewList.find(m => m.email === r.label)
//         data.push(obj)
//     })
// }
        console.log('-->createNewsLetter', createNewsLetter)
        setcreateNewsLetter({
            ...createNewsLetter,
            //reviews: ((data && data.length) ? data : createNewsLetter.reviews) || [],
            reviews: createNewsLetter.reviews,
            isEdit: true,
        });
        setcreateLetter(true);
        setisTopbar(false);
        setisPreview(false);

    };

    const doDeleteOpen = (item) => {
        handleClosed()
        setisDeleteModal(true);
        setisDeleteID(item.id);
    }

    const doDelete = async () => {
// const sendRequest={
        //     letter_id: isDeleteID
// }
// dodeleteLetter(sendRequest);
        setIsLoading(true)
        const init1 = {
            body: {
                letter_id: isDeleteID,
                school_id: getCurrentSchoolId(props.currentUser)
            }
        }
        const newsList = await API.post('newsletter_delete', '', init1).then(res => res)

        const interval = setInterval(() => {
            getDataList()
            if (!MyLetter.newNewsLetter) {
                setisRender(!isRender);
            } else {
                setcreateLetter(false);
                setisClone(false);
                setisTopbar(true);
                setisPublish(false);
                setisDeleteModal(false);

                clearInterval(interval);
            }
        }, 3000)
        return () => clearInterval(interval);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setNewsItem(item)
    };

    const handleClosed = () => {
        setAnchorEl(null);
        setNewsItem({})
    };

    const hexToRgbA = (hex) => {
        let c = "";
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
// return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
            c = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1'
            return c.split(",")
        }
        throw new Error('Bad Hex');
    }

    const getUserInfo = (id, type) => {
        const {usercache} = props;
        const {profile_photo = "", firstName = "", lastName = ""} = usercache && usercache[id] || {}
        if (type === "image" && profile_photo) {
            return profile_photo || ""
        }
        if (type === "display_name" && (firstName || lastName)) {
            return `${firstName || ""} ${lastName || ""}`
        }
        return ""
    }

    const getUserList = () => {
        const {usersList} = props

        let list = []
        usersList.forEach(user => {
            const isAdmin = ((((user || {}).role) || {})[getCurrentSchoolId(user)] || {}).admin
            const isTeacher = ((((user || {}).role) || {})[getCurrentSchoolId(user)] || {}).teacher
            if (isAdmin || isTeacher) {
                const name = `${user.firstName || ""} ${user.lastName}(${user.email_id})`
                list.push({value: user.id, label: name})
            }
        })

        return list
    }

    const onChangeCreatedBy = object => {
        setcreateNewsLetter({
            ...createNewsLetter,
            created_by: object.value || ""
        });
    };

    const classe = useStyle();

//console.log({condition: createNewsLetter.newsLetterId && !createNewsLetter.isEdit && tabActive !== 2, 1: (createNewsLetter.newsLetterId && !createNewsLetter.isEdit && tabActive !== 2 && isUserParent(props.currentUser) && props.communications), isUserParent: isUserParent(props.currentUser), communications: props.communications})


    // function showIframe(){
    //     if (createNewsLetter.structure.html.length > 0 &&  (!alreadyRendered)) {
    //         let blob = new Blob([createNewsLetter.structure.html], { type: 'text/html' });
    //         console.log('iframeRef', iframeRef)
    //         return (
    //             <IframeResizer
    //                 src={URL.createObjectURL(blob)}
    //                 heightCalculationMethod="lowestElement"
    //                 autoResize={true}
    //                 ref={iframeRef}
    //                 style={{ width: '100%', height : `${iframeRef.scrollHeight}px` }}>
    //             </IframeResizer>)
    //     }
    // }

    function showIframe() {
        console.log('in func lreadyRendered', alreadyRendered)
        if (createNewsLetter.structure.html.length > 0 &&  (!alreadyRendered)) {
            console.log('SHOWING IFRAME !!!!')
            console.log('alreadyRendered', alreadyRendered)
            alreadyRendered = true
            console.log('alreadyRendered', alreadyRendered)
            return (
            <Iframe
                content={createNewsLetter.structure.html}>

            </Iframe>)
        }else{
            console.log('not alreadyRendered')
            return
        }

    }


//console.log('props->', props)
    return (
        <div style={{background: 'rgb(241, 243, 249)'}}>
            {isLoading ? <Loader/> : null}
            <div className={classes.root}>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs>

                            {(userRole.teacher || userRole.admin || (isUserParent(props.currentUser) && props.communications)) &&
                                <div style={{display: 'inherit'}}>
                                    {createNewsLetter.newsLetterId && !createNewsLetter.isEdit && tabActive !== 2 &&
                                        <Button
                                            style={{float: 'right'}}
                                            className={classes.createButton}
                                            onClick={doEdit}
                                        >
                                            Edit
                                        </Button>
                                    }
                                    <Button
                                        style={{float: 'right'}}
                                        className={classes.createButton}
                                        onClick={isEditPreview ? () => onEditPreview(false) : !createLetter ? doCreateLetter : doClose}
                                    >
                                        {createLetter ? 'Cancel' : '' /* 'Create Newsletter' */}
                                    </Button>

                                </div>
                            }

                            {isUserParent(props.currentUser) && !props.communications && createNewsLetter.newsLetterId &&
                                <div>
                                    <Button
                                        style={{float: 'right'}}
                                        className={classes.createButton}
                                        onClick={isEditPreview ? () => onEditPreview(false) : doClose}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            }
                        </Grid>
                    </Grid>
                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth={false}
                        fullWidth={true}
                        aria-labelledby="confirmation-dialog-title"
                        open={showStats}
                    >
                        <DialogTitle id="confirmation-dialog-titlexrx">
                            Stats for Newsletter - {showStatsData.item?.structure?.letter_title || ''}
                        </DialogTitle>
                        <DialogContent dividers>
                            <EmailStats
                                groups={showStatsData.item?.group_ids || []}
                                status={showStatsData?.data || []}
                                newUserInfo={props.newUserInfo}
                            />
                        </DialogContent>
                        <DialogActions>

                            <div className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    newsLetterStatus
                                    onClick={() => {
                                        setshowStats(false)
                                        setIsLoading(false)
                                    }}
                                >
                                    Close
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>


                    {isTopbar &&

                    !isUserParent(props.currentUser) ?
                        <div style={{marginBottom : 5}}>
                            <span style={{cursor: "pointer"}} onClick={doCreateLetter}>
                                <AddCircle
                                    style={{
                                        color: "rgb(198 44 107)",
                                        marginLeft: 10,
                                        fontSize: 25
                                    }}/>&nbsp;
                                <span style={{
                                    color: "rgb(198 44 107)",
                                    fontSize: 25,
                                    fontWeight: 'bold',
                                    textAlign : 'top'
                                }}>Create</span>
                            </span>
                        </div>
                        : null

                    }

                    {isTopbar &&
                        <Grid container spacing={4}>
                            <Grid item xs className={classes.flexInline}>

                                {isAdvance ?
                                    <Grid item xs className={classes.flexInline}>
                                        <Select
                                            placeholder="Select Group"
                                            value={groupFilter}
                                            onChange={changeGroupFilter}
                                            className={classes.groupSelect}
                                            style={{width: 200}}
                                            options={MyLetter.groups}
                                        />

                                        <DatePicker
                                            placeholderText={'Select Date'}
                                            className={classes.datepickerFilter}
                                            selected={filterDate}
                                            onChange={handlefilterDateChange}
                                        />

                                        {/*<Button className={classes.resetButton} onClick={resetFilter}>
    Reset
    </Button>*/}
                                        <div
                                            style={{marginLeft: 20, marginTop: 8, cursor: "pointer"}}
                                            onClick={resetFilter}
                                        >
                                            Reset
                                        </div>

                                    </Grid> : null
                                }

                            </Grid>
                        </Grid>
                    }

                </div>

                {!createLetter &&
                    <div className={classe.row}>
                        {(userRole.teacher || userRole.admin) &&
                            <span>
                                <Button
                                    onClick={() => handleTabChange(0)}
                                    style={tabActive === 0 ? {background: 'rgb(158, 208, 249)'} : {}}
                                >
                                    <Badge
                                        color="secondary"
                                        badgeContent={(MyLetter.draft || []).length}
                                        classes={tabActive !== 0 ? {badge: classe.draftBadge} : {badge: classe.draftBadge}}
                                        style={{zIndex: 0}}
                                    >

                                        <span style={tabActive !== 0 ? {color: "#000000"} : {color: "#3f51b5"}}>
                                            {tabActive === 0 ? <b><u>Draft</u></b> : "Draft"}
                                        </span>

                                    </Badge>
                                </Button>
                                <span style={{marginRight: 25}}/>
                            </span>
                        }

                        {(userRole.teacher || userRole.admin || (userRole.parent && props.communications)) &&
                            <span>
                                <Button
                                    onClick={() => handleTabChange(1)}
                                    style={tabActive === 1 ? {background: 'rgb(158, 208, 249)'} : {}}
                                >
                                    <Badge
                                        color="secondary"
                                        badgeContent={(MyLetter.review || []).length}
                                        classes={tabActive !== 1 ? {badge: classe.reviewBadge} : {badge: classe.reviewBadge}}
                                        style={{zIndex: 0}}
                                    >

                                        <span style={tabActive !== 1 ? {color: "#000000"} : {color: "#3f51b5"}}>
                                            {tabActive === 1 ? <b><u>Review</u></b> : "Review"}
                                        </span>

                                    </Badge>
                                </Button>
                                <span style={{marginRight: 25}}/>
                            </span>
                        }

                        {(userRole.teacher || userRole.admin) &&
                            <span>
                                <Button
                                    onClick={() => handleTabChange(3)}
                                    style={tabActive === 3 ? {background: 'rgb(158, 208, 249)'} : {}}
                                >
                                    <Badge
                                        color="secondary"
                                        badgeContent={(MyLetter.schedule || []).length}
                                        classes={tabActive !== 3 ? {badge: classe.scheduleBadge} : {badge: classe.scheduleBadge}}
                                        style={{zIndex: 0}}
                                    >

                                        <span style={tabActive !== 3 ? {color: "#000000"} : {color: "#3f51b5"}}>
                                            {tabActive === 3 ? <b><u>Schedule</u></b> : "Schedule"}
                                        </span>

                                    </Badge>
                                </Button>
                                <span style={{marginRight: 25}}/>
                            </span>
                        }

                        <Button
                            onClick={() => handleTabChange(2)}
                            style={tabActive === 2 ? {background: 'rgb(158, 208, 249)'} : {}}
                        >
                            <Badge
                                color="secondary"
                                badgeContent={(MyLetter.publish || []).length}
                                classes={tabActive !== 2 ? {badge: classe.publishedBadge} : {badge: classe.publishedBadge}}
                                style={{zIndex: 0}}
                            >
                                <span style={tabActive !== 2 ? {color: "#000000"} : {color: "#3f51b5"}}>
                                    {tabActive === 2 ? <b><u>Published</u></b> : "Published"}
                                </span>
                            </Badge>
                        </Button>
                    </div>
                }

                {!createLetter &&
                    <div>
                        <br/>
                        <Cards
                            data={MyLetter.newsLetters || []}
                            userRole={userRole || ""}
                            currentUser={props.currentUser || {}}
                            groupCache={props.groupcache}
                            tabActive={tabActive}
                            goPreview={goPreview}
                            doCopy={doCopy}
                            openPublishedData={openPublishedData}
                            doDeleteOpen={doDeleteOpen}
                            handleClick={handleClick}
                            setNewsItem={setNewsItem}
                            newsItem={newsItem}
                            MyLetter={MyLetter || {}}
                            classes={classes}
                            getUserInfo={getUserInfo}
                            communications={props.communications || false}
                        />
                    </div>
                }


                {createLetter && !isPreview && !showStats &&
                    <div className={classes.root}>
                        {!isUserParent(props.currentUser) &&
                            <Typography variant="h5" className={classes.title}>
                                {createNewsLetter.newsLetterId === '' ? 'Add' : 'Edit'}
                                {' '}
                                Newsletter
                            </Typography>
                        }
                        {isUserParent(props.currentUser) &&
                            <Typography variant="h5" className={classes.title}>
                                Newsletter Detail
                            </Typography>
                        }

                        <NewsLetterContainer
                            classes={classes}
                            createNewsLetter={createNewsLetter}
                            profilePlaceholder={profilePlaceholder}
                            selectProfileImage={selectProfileImage}
                            handleChangeMultiple={handleChangeMultiple}
                            handleGroupChange={handleGroupChange}
                            handleDateChange={handleDateChange}
                            handleTitlehange={handleTitlehange}
                            openPicker={openPicker}
                            selectPicker={selectPicker}
                            MyLetter={MyLetter}
                            backgroudColor={backgroudColor}
                            closePicker={closePicker}
                            styles={styles}
                            styles1={styles1}
                            openTitlePicker={openTitlePicker}
                            titlePicker={titlePicker}
                            closeTitlePicker={closeTitlePicker}
                            addMenu={addMenu}
                            closeNewTopic={closeNewTopic}
                            cancelNewTopic={cancelNewTopic}
                            handleChangeUpdateTitle={handleChangeUpdateTitle}
                            selectTitlePicker={selectTitlePicker}
                            addSubOption={addSubOption}
                            addiconImage={addiconImage}
                            addNewTopic={addNewTopic}
                            selectedContent={selectedContent}
                            selectImage={selectImage}
                            selectVideo={selectVideo}
                            selectDocument={selectDocument}
                            removeItem={removeItem}
                            editContent={editContent}
                            removeImage={removeImage}
                            openImageModal={openImageModal}
                            addMenuOption={addMenuOption}
                            setisPreview={setisPreview}
                            onEditPreview={onEditPreview}
                            setisPublish={setisPublish}
                            setisPublishNew={setisPublishNew}
                            submitData={submitData}
                            submitDataNew={submitDataNew}
                            doUpdateStatus={doUpdateStatus}
                            doUpdateStatusNew={doUpdateStatusNew}
                            school_id={props.school_id[0]}
                            isTeacher={props.isTeacher || false}
                            isAdmin={props.isAdmin || false}
                            isParent={props.isParent || false}
                            communications={props.communications || false}
                            getUserList={getUserList}
                            onChangeCreatedBy={onChangeCreatedBy}
                        />

                    </div>
                }


                {isPreview &&
                    <div>

                        <Grid
                            container
                            spacing={0}
                            // direction="column"
                            // alignItems="center"
                            // justify="center"
                            className={classes.borderRadius10}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <Paper className={classes.paper} style={{textAlign: "left"}}>

                                        <div style={{display: 'flex'}}>
                                            <div style={{width: '30%'}}>
                                                <img
                                                    src={createNewsLetter.profileImage ? `${awsConfig.media_url}public/${createNewsLetter.profileImage}` : profilePlaceholder}
                                                    alt="Profile image"
                                                    style={{
                                                        float: 200,
                                                        borderRadius: "100%",
                                                        marginBottom: 15,
                                                        border: "1px solid whitesmoke",
                                                        width: "100%",
                                                        height: "auto"
                                                    }}
                                                    title="Profile Image"
                                                    onError={e => (e.target.src = profilePlaceholder)}
                                                />
                                            </div>
                                            <div style={{width: '70%', paddingLeft: "1em"}}>
                                                <h3 style={{fontWeight: 600}}>{createNewsLetter.title || ""}</h3>

                                                {createNewsLetter.publish_date
                                                    ? (
                                                        <h6>
                                                            Published On {
                                                            moment(createNewsLetter.publish_date).format(
                                                                'MM/DD/YYYY'
                                                            )
                                                        }
                                                        </h6>
                                                    ) : null
                                                }

                                                {/*<h6>Groups</h6>*/}
                                                <div style={{marginTop: 20}}>
                                                    {
                                                        (createNewsLetter.group || []).map(group => (
                                                            <Chip
                                                                label={group.groupname || ''}
                                                                variant="outlined"
                                                                className={classes.chip}
                                                                color="primary"
                                                                style={{marginRight: 5, marginBottom: 5}}
                                                            />
                                                        ))
                                                    }
                                                </div>

                                                {/*<h6 style={{marginTop: 10}}>Date</h6>
<h5>
{
    createNewsLetter.date
        ? moment (createNewsLetter.date).format (
            'MM/DD/YYYY'
        )
        : ''
}
</h5>*/}
                                            </div>
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={8}>
                                    <Paper className={classes.papers} style={{
                                        background: createNewsLetter.background
                                            ? createNewsLetter.background
                                            : 'none',
                                    }}>
                                        {showIframe()}
                                        {/*{createNewsLetter.structure.html.length > 0 &&*/}
                                        {/*    <div>*/}
                                        {/*        {ReactHtmlParser(createNewsLetter.structure.html, {transform : (node)=>{*/}
                                        {/*                // do not render any <span> tags*/}
                                        {/*                if (node.type === 'tag' && node.name === 'p1') {*/}
                                        {/*                    var n = node*/}

                                        {/*                    return <div style={{padding : 0}}></div>*/}
                                        {/*                }*/}
                                        {/*        }})}*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        {addMenu.newsletter &&
                                            addMenu.newsletter.length > 0 &&
                                            addMenu.newsletter.map((item, i) => (
                                                <div key={i.toString()}>
                                                    {item.type === 'TITLE' && item.textTitle &&
                                                        <Typography
                                                            className={classes.previewrootList}
                                                            style={{
                                                                backgroundColor: createNewsLetter.titleBackground,
                                                            }}
                                                        >

                                                            <p className={classes.topictitle}>
                                                                {item.textTitle ? ReactHtmlParser(item.textTitle) : ''}
                                                            </p>
                                                        </Typography>
                                                    }
                                                    }

                                                    {item.type === 'DESCRIPTION' && item.textTitle && !item.isEdit &&
                                                        <div
                                                            className={'editorClass ' + classes.previewDescription}
                                                        >
                                                            {item.textTitle ? ReactHtmlParser(item.textTitle) : ''}
                                                        </div>
                                                    }

                                                    {item.type !== 'DESCRIPTION' && item.type !== 'TITLE' &&
                                                        <div className={classes.imageContainer}>
                                                            {item.multipleImage &&
                                                                item.multipleImage.map((imgitem, j) => (
                                                                    <div key={j.toString()}
                                                                         className={classes.imageContent}>
                                                                        <img
                                                                            className={classes.previewMediaImage}
                                                                            src={`${awsConfig.media_url}public/${imgitem.showImage}`}
                                                                            title="Contemplative Reptile"
                                                                            onClick={() => openImageModal(imgitem.showImage)}
                                                                        />
                                                                    </div>
                                                                ))}

                                                            {item.type === 'VIDEOS' &&
                                                                <video
                                                                    style={{
// width: '90%',
                                                                        height: 250,
                                                                        marginLeft: 50,
                                                                    }}
                                                                    controls
                                                                >
                                                                    <source
                                                                        src={`${awsConfig.media_url}public/${item.showVideo}`}
                                                                        // type={item.showVideo}
                                                                    />
                                                                    Your browser does not support HTML5 video.
                                                                </video>
                                                            }

                                                            {item.type === 'DOCUMENTS' &&
                                                                <div className={classes.attachmentPreview}>
                                                                    <a
                                                                        download={`attachment-${i}`}
                                                                        href={`${awsConfig.media_url}public/${item.val}`}
                                                                        title={`attachment-${i}`}
                                                                        target="_blank"
                                                                    >
                                                                        <Description
                                                                            style={{fontSize: 50}}
                                                                        />
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                            ))}
                                    </Paper>
                                </Grid>

                                {tabActive === 4 ?
                                    <Grid
                                        justify="space-between" // Add it here :)
                                        container
                                        spacing={24}
                                    >
                                        <Grid/>

                                        <Grid item>

                                            {createNewsLetter.newsLetterId !== '' && isUserParent(props.currentUser) &&
                                                <div className={classes.itemcenter}>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={() => setisPublish(true)}
                                                    >
                                                        Publish
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={() => doUpdateStatus(1)}
                                                    >
                                                        Send for review
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={() => doUpdateStatus(0)}
                                                    >
                                                        Save Draft
                                                    </Button>
                                                </div>
                                            }
                                        </Grid>
                                    </Grid> : null
                                }

                            </Grid>
                        </Grid>

                    </div>
                }

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown

                    aria-labelledby="confirmation-dialog-title"
                    open={isClone}
                >
                    <DialogTitle id="confirmation-dialog-titlexrx">
                        Enter your new news letter name, please ?
                    </DialogTitle>
                    {isLoading ? <Loader/> : null}
                    <DialogContent dividers>
                        <TextField
                            placeholder="Enter letter name"
                            multiline
                            fullWidth
                            // onChange={e =>
                            //   setValues({ ...values, moduleToCloneName: e.target.value })
                            // }
                            onChange={handleTitlehange}
                            value={createNewsLetter.title}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={doClose} color="primary">
                            Cancel
                        </Button>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                newsLetterStatus
                                onClick={() => {
                                    console.log(createNewsLetter)
                                    //sethtml(createNewsLetter.structure.html)
                                    //setdesignJSON(createNewsLetter.structure.designjson)
                                    submitData(createNewsLetter.newsLetterStatus, "copy")
// onClick={handleCloneButtonClick}
                                }}
                            >
                                Clone
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    scroll="body"
                    open={isPublish}
                    classes={{paper: classes.modal}}
                >
                    <DialogTitle id="confirmation-dialog-titlexrx">
                        <span style={{fontSize: 16}}>Select Date /Time you would like toSelect Date /Time you would like to schedule the newsletter to publish to group <b>{((createNewsLetter.group || []).map(x => x.groupname) || []).join(", ") || ""}</b>:</span>
                    </DialogTitle>
                    {isLoading ? <Loader/> : null}

                    <div style={{textAlign: 'center', margin: '25px 0px'}}>
                        <KeyboardDateTimePicker
                            style={{padding: 5, background: "rgb(158, 208, 249)"}}
                            value={createNewsLetter.publish_date || new Date()}
                            onChange={selectPublishDate}
                            // label="Keyboard with error handler"
                            format="MM/DD/YYYY h:mm A"
                        />
                    </div>

                    <DialogActions>
                        <Button onClick={() => setisPublish(false)} color="primary">
                            Cancel
                        </Button>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                newsLetterStatus
                                disabled={!createNewsLetter.publish_date}
                                onClick={() => {
                                    createNewsLetter.newsLetterId !== '' ?
                                        doUpdateStatusNew(2) :
                                        submitDataNew(2,"", html, designJSON)
                                }}
                                // onClick={handleCloneButtonClick}
                            >
                                Schedule
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={isDeleteModal}

                >
                    <DialogTitle id="confirmation-dialog-titlexrx">
                        Delete NewsLetter
                    </DialogTitle>
                    {isLoading ? <Loader/> : null}
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this newsletter ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setisDeleteModal(false)} color="primary">
                            Cancel
                        </Button>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                newsLetterStatus
                                onClick={() => doDelete()}
                                // onClick={handleCloneButtonClick}
                            >
                                Delete
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={modal.opemModal}
                    onClose={handleClose}
                >
                    <div style={modalStyle} className={classes.modalPaper}>
                        <CardMedia
                            className={classes.ModalImage}
                            image={`${awsConfig.media_url}public/${modal.imageurl}`}
                            title="Contemplative Reptile"
                        />

                    </div>
                </Modal>
                {/* Email html Start */}
                {createNewsLetter &&

                    <div style={{display: "none"}}>
                        <html id="newsletterview">

                        <head>
                            <meta content="width=device-width, initial-scale=1" name="viewport"/>
                            <style
                                jsx>{`.container {
                                    border-radius: 5px;
                                    background-color: #f2f2f2;

                                }

                                    .row:after {
                                    content: "";
                                    display: table;
                                    clear: both;
                                }

                                    .column {
                                    float: left;
                                    width: 30%;
                                    margin-top: 6px;
                                    padding: 20px;
                                }

                                    .column1 {
                                    float: left;
                                    width: 100%;
                                    margin-top: 6px;
                                    padding: 20px;
                                }

                                    rt .ql-align-center {
                                    text-align: center !important;
                                    padding: 10px;
                                }

                                    rt .ql-align-right {
                                    text-align: right !important;
                                    padding: 10px;
                                }

                                    rt .ql-align-left {
                                    text-align: left !important;
                                    padding: 10px;
                                }

                                    rt p {
                                    padding: 10px;
                                }

                                    .ql-align-center {
                                    text-align: center !important;
                                    padding: 10px;
                                }

                                    .ql-align-right {
                                    text-align: right !important;
                                    padding: 10px;
                                }

                                    .ql-align-left {
                                    text-align: left !important;
                                    padding: 10px;
                                }

                                    p {
//padding: 10px;
                                }

                                    .mainDiv {
                                    padding: 20px;
                                    width: 98%
                                }

                                    .combineDiv {
                                    display: flex;
                                }

                                    .firstDiv {
                                    width: 30%
                                }

                                    .secondDiv {
                                    width: 70%;
                                    margin-left: 50px
                                }

                                    .imageDiv {
                                    margin-left: 50px
                                }

                                    .imageClass {
                                    height: 200px;
                                    width: 200px
                                }

                                    .displayFlex {
                                    display: flex;
                                }

                                    @media only screen and (min-width: 200px) and (max-width: 768px) {
                                    .mainDiv {
                                    padding: 0px;
                                    width: 85%
                                }

                                    .combineDiv {
                                    display: block;
                                }

                                    .firstDiv {
                                    width: 100%
                                }

                                    .secondDiv {
                                    width: 100%;
                                    margin-left: 0px;
                                    margin-top: 15px
                                }

                                    .imageDiv {
                                    margin-left: 0px
                                }

                                    .imageClass {
                                    height: 130px;
                                    width: 130px
                                }

                                    .column {
                                    width: 100%;
                                    margin-top: 0;
                                }

                                    .column1 {
                                    width: 100%;
                                    margin-top: 0;
                                }

                                    .displayFlex {
                                    display: block;
                                }
                                }

                                    rt h1, h2, h3, h4, h5, h6 {
                                    color: rgba(0, 0, 0, 0.85);
                                }

                                    h1, h2, h3, h4, h5, h6 {
                                    color: rgba(0, 0, 0, 0.85);
                                }`}</style>
                        </head>
                        <body style={{padding: 10}}>

                        <div>
                            <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="mcnImageBlock"
                                   style={{minWidth: '100%', borderCollapse: 'collapse'}}
                            >
                                <tbody className="mcnImageBlockOuter">
                                <tr>
                                    <td valign="top"
                                        style={{padding: 9}}
                                        className="mcnImageBlockInner">
                                        <table align="left" width="100%" border="0" cellPadding="0" cellSpacing="0"
                                               className="mcnImageContentContainer"
                                               style={{minWidth: '100%', borderCollapse: 'collapse'}}>
                                            <tbody>
                                            <tr>
                                                <td className="mcnImageContent" valign="top"
                                                    style={{
                                                        paddingRight: 9,
                                                        paddingLeft: 9,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        textAlign: 'center'
                                                    }}
                                                >

                                                    <a href="#">
                                                        <img
                                                            align="center"
                                                            // alt="DuMOL"
                                                            src={awsConfig.school_logo}
                                                            width="150"
                                                            style={{
                                                                maxWidth: 150,
                                                                paddingBottom: 0,
                                                                display: 'inline',
                                                                verticalAlign: 'bottom',
                                                                border: 0,
                                                                height: 'auto',
                                                                outline: 'none',
                                                                textDecoration: 'none'
                                                            }}
                                                            className="mcnImage"
                                                        />
                                                    </a>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div style={{
                                                        color: "rgba(0, 0, 0, 0.85)",
                                                        fontWeight: 500,
                                                        fontSize: "15px",
                                                        marginTop: 15,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div>
                                                            <img
                                                                src={createNewsLetter.profileImage ? `${awsConfig.media_url}public/${createNewsLetter.profileImage}` : profilePlaceholder}
                                                                onError={e => (e.target.src = profilePlaceholder)}
                                                                alt="Profile image"
                                                                style={{
                                                                    float: 200,
                                                                    borderRadius: "100%",
                                                                    border: "1px solid whitesmoke",
                                                                    width: "100%",
                                                                    height: 50,
                                                                    maxWidth: 50,
                                                                    marginRight: 15
                                                                }}
                                                                // className="imageClass"
                                                            />
                                                        </div>
                                                        <div
                                                            style={{marginTop: 15}}>{((createNewsLetter.group || []).map(x => x.groupname) || []).join(", ")},
                                                            ( {createNewsLetter.publish_date ? moment(createNewsLetter.publish_date).format('LL') : ''} )
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {/*<tr>
<td>
<div style={{color: "rgba(0, 0, 0, 0.85)", fontWeight: 500, fontSize: "15px", marginTop: 15}}>
{ createNewsLetter.date ? moment(createNewsLetter.date).format('LL') : '' }
</div>
</td>
</tr>*/}
                                            <tr>

                                                <td valign="top" className="mcnTextContent"
                                                    style={{ /* padding: '0 18px 9px', */
                                                        color: '#222222',
                                                        fontFamily: 'Adobe Calson Pro, Garamond, Times, Times New Roman, serif',
                                                        fontSize: 14,
                                                        lineHeight: '150%',
                                                        wordBreak: 'break-word',
                                                        textAlign: 'left'
                                                    }}
                                                >

                                                    <div /* className="column1" */
                                                        style={{
                                                            background: createNewsLetter.background ? createNewsLetter.background : 'none',
                                                            padding: 10,
                                                            marginTop: 15
                                                        }}
                                                    >
                                                        {addMenu.newsletter &&
                                                            addMenu.newsletter.length > 0 &&
                                                            addMenu.newsletter.map((item, i) => {
                                                                return (
                                                                    <div key={i.toString()} style={{marginBottom: 5}}>
                                                                        {item.type === 'TITLE' && item.textTitle &&
                                                                            <div style={{
                                                                                border: 0,
                                                                                borderRadius: 5,
                                                                                fontWeight: 'bold',
                                                                                color: '#fff',
                                                                                backgroundColor: createNewsLetter.titleBackground,
                                                                                fontSize: 16,
                                                                                padding: "0px 10px"
                                                                            }}>
                                                                                <rt>
                                                                                    {item.textTitle ? ReactHtmlParser(item.textTitle) : ''}
                                                                                </rt>
                                                                            </div>
                                                                        }
                                                                        {item.type === 'DESCRIPTION' && item.textTitle &&
                                                                            <div style={{
                                                                                padding: "0px 10px",
                                                                                background: '#fff',
                                                                                borderRadius: 3
                                                                            }}>
                                                                                {item.textTitle ? ReactHtmlParser(item.textTitle) : ''}
                                                                            </div>
                                                                        }

                                                                        {item.type !== 'DESCRIPTION' && item.type !== 'TITLE' &&
                                                                            <div style={{
                                                                                marginTop: 20,
                                                                                textAlign: 'center',
                                                                                padding: 10
                                                                            }}
                                                                                // className="imageDiv"
                                                                            >
                                                                                {item.multipleImage &&
                                                                                    item.multipleImage.map((imgitem, j) => (
                                                                                        <div key={j.toString()} style={{
                                                                                            display: 'inline-block',
                                                                                            background: '#FFFFFF',
                                                                                            marginLeft: 15,
                                                                                            padding: 5,
                                                                                            marginTop: 10
                                                                                        }}
                                                                                             className="imageClass"
                                                                                        >
                                                                                            <a className="mcnImage" target="_blank"
                                                                                               href={`${awsConfig.media_url}public/${imgitem.showImage}`}>
                                                                                                <img
                                                                                                    // className="imageClass"
                                                                                                    src={`${awsConfig.media_url}public/${imgitem.showImage}`}
                                                                                                    title="Content Image"
                                                                                                    height="100%"
                                                                                                    width="100%"
                                                                                                    alt='Content Image'
                                                                                                />
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}

                                                                                {item.type === 'VIDEOS' &&
                                                                                    <video
                                                                                        style={{
// width: '90%',
                                                                                            height: 250,
                                                                                            marginLeft: 50,
                                                                                        }}
                                                                                        controls
                                                                                    >
                                                                                        <source
                                                                                            src={`${awsConfig.media_url}public/${item.showVideo}`}
                                                                                            // type={item.showVideo}
                                                                                        />
                                                                                        Your browser does not support HTML5 video.
                                                                                    </video>
                                                                                }
                                                                                {item.type === 'DOCUMENTS' &&
                                                                                    <div style={{
                                                                                        textAlign: 'center',
                                                                                        fontSize: 30,
                                                                                        padding: 5,
                                                                                        marginLeft: 20,
                                                                                        marginRight: 20,
                                                                                        borderRadius: "10px"
                                                                                    }}>
                                                                                        <a
                                                                                            download={`attachment-${i}`}
                                                                                            href={(item && item.doc && `${awsConfig.media_url}public/${item.doc}`) || ((item.val || []).length && item.val[0] && `${awsConfig.media_url}public/${item.val[0]}`) || ""}
                                                                                            title={`attachment-${i}`}
                                                                                            target="_blank"
                                                                                            style={{color: '#007ad9'}}
                                                                                        >
                                                                                            <Description
                                                                                                style={{
                                                                                                    fontSize: 50,
                                                                                                    height: 50,
                                                                                                    width: 50
                                                                                                }}
                                                                                            />
                                                                                        </a>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }


                                                                    </div>
                                                                )
                                                            })}
                                                    </div>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                        </body>
                        </html>
                    </div>
                }

                {/* Email html End */}

            </div>

        </div>
    );
}


const mapStateToProps = state => ({
    school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
    role: (state.currentUser.newUserInfo.user.role[Object.keys(state.currentUser.newUserInfo.user.role)]) || "",
    groupcache: (state.currentUser.newUserInfo.groupcache) || {},
    usergroupcache: (state.currentUser.newUserInfo.usergroupcache) || {},
    usercache: (state.currentUser.newUserInfo.usercache) || {},
    usersList: (state.currentUser.newUserInfo.usersList) || [],
    userid: (state.currentUser.newUserInfo.user.id),
    currentUser: state.currentUser.newUserInfo.user || {},
    isTeacher: state.currentUser.newUserInfo.isTeacher || false,
    isAdmin: state.currentUser.newUserInfo.isAdmin || false,
    communications: state.currentUser.newUserInfo.communications || false,
    isParent: state.currentUser.newUserInfo.isParent || false,
    newUserInfo: state.currentUser.newUserInfo
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetter)
