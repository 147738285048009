import React from 'react';
import moment from "moment";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Radio, Avatar, Button, Checkbox, Spin } from 'antd';
import { Container, Grid, Card, CardHeader, IconButton } from "@material-ui/core";
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import {
    ImageUpload,
    TextLabelInput,
    SelectLabelInput,
    DateLabelInput,
    NumberLabelInput,
    TimeLabelInput,
    getDays,
    getFileUrl,
    uploadFile,
    getColor,
    openNotificationWithIcon,
    getSchoolId,
    SelectParentInput,
    getUserDetailsByUserId, SelectUserRoleInput, getUserRole
} from "../../../GlobalComponents/GlobalFields";
import { validateData } from "../../../GlobalComponents/validation";
import { countryOptionList, parentsRoleList, primaryInfo,studentEnrollmentList, studentStatusList, relationList, parentsRelation, ethnicityList } from "../../../GlobalComponents/optionValues";
import SecondaryAddress from "../../../GlobalComponents/SecondaryAddress";
import {createChild, createUser} from "../../../../ApiServices";
import "../../../school.css"
import * as routes from "../../../../../../routes/path";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class CreateStudent extends React.Component {
    state = {
        isSaving: false,
        isParent1Searching: false,
        isParent2Searching: false,
        activeTab: 1,
        parent1Type: 'existing',
        parent2Type: 'existing',
        selectedParent1Id: '',
        selectedParent2Id: '',
        selectedParent1Info: {},
        selectedParent2Info: {},
        studentFiled: {
            firstName: '', lastName: '', dateOfBirth: null, gender: '', status: 'Active', profile_photo: 'Full Day',
            primaryLanguage: "", ethnicity: "", homeLanguage: "", homeRoom: "", othersRoom: [], studentEnrollment : ''
        },
        studentErrors: {},
        familyList: [],
        familyFiled: {firstName: '', lastName: '', email: '', mobile: '', relation: "", profile_photo: '', isPickup: ''},
        familyError: {},
        medicalField: {primaryDoctor: '', primaryDoctorContact: '', currentMedications: '', allergies: '', },
        medicalErrors: {},
        parentFiled1: {firstName: '', lastName: '', email: '', mobile: '', isPickup: '', relation: '', profile_photo: '', role: [], isNewsletterReviewer: false},
        parents1Errors: {},
        parentFiled2: {firstName: '', lastName: '', email: '', mobile: null, isPickup: '', relation: '',  profile_photo: '', role: [], isNewsletterReviewer: false},
        parents2Errors: {},
        scheduleFields: {startDate: null, endDate: null, startTime: null, endTime: null, selectedDays: {}, classType: 'inClass'},
        scheduleList: [],
        scheduleError: {},
        primaryAddress: {address1: '', address2: '', address3: '', city: '', state: '', postalCode: '',  country: ''},
        primaryAddressErrors: {},
        selectedFamilyIndex: null,
        selectedScheduleIndex: null,
        secondaryAddressList: []
    };

    handleChangeTime = (time, key) => {
        this.setState({
            scheduleFields: {
                ...this.state.scheduleFields,
                [key]: time
            }
        })
    };

    handelOnChange = (e, type) =>{
        const {studentFiled, parentFiled1, parentFiled2, familyFiled, medicalField, primaryAddress, scheduleFields} = this.state
        let values = {}
        if(type === "student"){
            values = {
                studentFiled: {
                    ...studentFiled,
                    [e.target.name]: e.target.value
                }
            }
        }else if(type === "parent1"){
            values = {
                parentFiled1: {
                    ...parentFiled1,
                    [e.target.name]: e.target.value
                }
            }
        }else if(type === "parent2"){
            values = {
                parentFiled2: {
                    ...parentFiled2,
                    [e.target.name]: e.target.value
                }
            }
        }else if(type === "family"){
            values = {
                familyFiled: {
                    ...familyFiled,
                    [e.target.name]: e.target.value
                }
            }
        }else if(type === "medical"){
            values = {
                medicalField: {
                    ...medicalField,
                    [e.target.name]: e.target.value
                }
            }
        }else if(type === "scheduleFields"){
            values = {
                scheduleFields: {
                    ...scheduleFields,
                    selectedDays: {
                        ...scheduleFields.selectedDays,
                        [e.target.name]: e.target.checked
                    }
                }
            }
        }else if(type === "primary"){
            values = {
                primaryAddress: {
                    ...primaryAddress,
                    [e.target.name]: e.target.value
                }
            }
        }
        this.setState({...values})
    }

    handelFamilySubmit = () => {
        const { familyFiled, familyList, selectedFamilyIndex } = this.state

        let validationErrors = {};
        Object.keys(familyFiled).forEach(name => {
            const error = validateData(name, familyFiled[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            this.setState({ familyError: validationErrors });
            return;
        }
        if(selectedFamilyIndex === 0 || selectedFamilyIndex){
            familyList[selectedFamilyIndex] = {...familyFiled, role: "family", SetasFinalPassword: "Y",username: familyFiled.email}
        } else {
            familyList.push({...familyFiled, role: "family", SetasFinalPassword: "Y",username: familyFiled.email})
        }

        this.setState({
            familyList,
            familyError: {},
            familyFiled: {},
            selectedFamilyIndex: null
        })
    }

    addStudentSchedule = () => {
        const { scheduleFields, scheduleList, selectedScheduleIndex } = this.state

        let validationErrors = {};
        Object.keys(scheduleFields).forEach(name => {
            if(name === 'startTime' || name === 'endTime') return;
            if (!(scheduleFields && scheduleFields[name]) && name !== "endDate" && name !== "selectedDays") {
                validationErrors[name] = "Required";
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            this.setState({ scheduleError: validationErrors });
            return;
        }
        if(selectedScheduleIndex === 0 || selectedScheduleIndex){
            scheduleList[selectedScheduleIndex] = scheduleFields
        } else {
            scheduleList.push(scheduleFields)
        }

        this.setState({
            scheduleList,
            scheduleError: {},
            scheduleFields: {startDate: null, endDate: null, startTime: null, endTime: null, selectedDays: {}, classType: 'inClass'},
            selectedScheduleIndex: null
        })
    }

    handelReview = async () => {
        const {studentFiled, parentFiled1, parentFiled2, familyList, primaryAddress, secondaryAddressList, scheduleList, medicalField, selectedParent1Info, selectedParent2Info,
            parent1Type, parent2Type, selectedParent1Id, selectedParent2Id} = this.state
        let studentFiledErrors = {};
        let parentFiled1Errors = {};
        let parentFiled2Errors = {};

        Object.keys(studentFiled).forEach(name => {
            const error = validateData(name, studentFiled[name]);
            if (error && error.length > 0) {
                studentFiledErrors[name] = error;
            }
        });

        let validationErrors = {};
        Object.keys(primaryAddress).forEach(name => {
            const error = validateData(name, primaryAddress[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });

        this.setState({
            studentErrors: studentFiledErrors,
            primaryAddressErrors: validationErrors
        });

        if((studentFiledErrors && Object.keys(studentFiledErrors).length) || (validationErrors && Object.keys(validationErrors).length)) {
            return openNotificationWithIcon('error', 'Fields required!')
        }

        Object.keys(parentFiled1).forEach(name => {
            if(selectedParent1Id && parent1Type === 'existing') return;
            const error = validateData(name, parentFiled1[name]);
            if (error && error.length > 0) {
                parentFiled1Errors[name] = error;
            }
        });

        Object.keys(parentFiled2).forEach(name => {
            if(selectedParent2Id && parent2Type === 'existing') return;
            const error = validateData(name, parentFiled2[name]);
            if (error && error.length > 0) {
                parentFiled2Errors[name] = error;
            }
        });

        this.setState({
            parents1Errors: parentFiled1Errors,
            parents2Errors: parentFiled2Errors,
        });

        if((parentFiled1Errors && Object.keys(parentFiled1Errors).length) && (parentFiled2Errors && Object.keys(parentFiled2Errors).length)) {
            return openNotificationWithIcon('error', 'At least one parent required!')
        }


        this.setState({isSaving: true})

        let group_ids = []
        if((studentFiled.othersRoom || []).length) {
            if((studentFiled.othersRoom || []).includes(studentFiled.homeRoom)) {
                group_ids = studentFiled.othersRoom
            } else {
                group_ids = (studentFiled.othersRoom || []).concat([studentFiled.homeRoom])
            }
        } else {
            group_ids = [studentFiled.homeRoom]
        }

        const payload = {
            attributes: {
                scheduleList,
                primaryAddress,
                secondaryAddressList,
                medicalField,
                ...studentFiled
            },
            auth_users: familyList,
            group_ids,
            parents: [],
            school_id: getSchoolId()
        }

        if(selectedParent1Id && parent1Type === 'existing') {
            payload.parents = [selectedParent1Id]
        } else if(parent1Type === 'create' && parentFiled1.email && (parentFiled1.firstName || parentFiled1.lastName)) {
            const parent1Payload = {
                role: {[getSchoolId()]: getUserRole(parentFiled1.role)},
                child_ids: [],
                username: parentFiled1.email,
                attributes: {
                    ...parentFiled1
                }
            }
            const userResponse = await createUser(parent1Payload)
            if(userResponse && userResponse.status === "success") {
                payload.parents = [userResponse.user_id]
            } else {
                this.setState({isSaving: false})
                const message = userResponse.message === "User already exists in system." ?
                    "Email already exists in System for another user. Please use different email" : userResponse.message
               return openNotificationWithIcon('error', message || 'Something went wrong')
            }
        }

        if(selectedParent2Id && parent2Type === 'existing') {
            payload.parents = (payload.parents || []).length ? payload.parents.concat([selectedParent2Id]) : [selectedParent2Id]
        } else if(parentFiled2 === 'create' && parentFiled2.email && (parentFiled2.firstName || parentFiled2.lastName)) {
            const parent2Payload = {
                role: {[getSchoolId()]: getUserRole(parentFiled2.role)},
                child_ids: [],
                username: parentFiled2.email,
                attributes: {
                    ...parentFiled2
                }
            }
            const userResponse = await createUser(parent2Payload)
            if(userResponse && userResponse.status === "success") {
                payload.parents = (payload.parents || []).length ? payload.parents.concat([userResponse.user_id]) : [userResponse.user_id]
            } else {
                if(!(payload.parents || []).length) {
                    openNotificationWithIcon('error', userResponse.message || 'Something went wrong')
                } else {
                    this.setState({isSaving: false})
                    const message = userResponse.message === "User already exists in system." ?
                        "Email already exists in System for another user. Please use different email" : userResponse.message
                    return openNotificationWithIcon('error', message || 'Something went wrong')
                }
            }
        }

        if((payload.parents || []).length) {
            const response = await createChild(payload)
            if(response && response.status === "success") {
                openNotificationWithIcon('success', response.message)
                setTimeout(() => {
                    this.props.history.push(routes.STUDENT_DASHBOARD)
                    this.setState({isSaving: false})
                }, 1000)
            } else {
                openNotificationWithIcon('error', response.message || 'Something went wrong')
                this.setState({isSaving: false})
            }
        } else {
            this.setState({isSaving: false})
        }
    }

    handelSelect = (key, val, type) => {
        let select = {}
        const {studentFiled, parentFiled1, parentFiled2, familyFiled, primaryAddress} = this.state
        if(type === "student"){
            select = {
                studentFiled: {
                ...studentFiled,
                    [key]: val
                }
            }
        }else if(type === "parent1"){
            select = {
                parentFiled1: {
                    ...parentFiled1,
                    [key]: val
                }
            }
        }else if(type === "parent2"){
            select = {
                parentFiled2: {
                    ...parentFiled2,
                    [key]: val
                }
            }
        }else if(type === "family"){
            select = {
                familyFiled: {
                    ...familyFiled,
                    [key]: val
                }
            }
        }else if(type === "primary"){
            select = {
                primaryAddress: {
                    ...primaryAddress,
                    [key]: val
                }
            }
        }else if(type === "userInfo") {
            const addressInfo = primaryInfo.find(p => p.name === val)
            select = {
                primaryAddress:{
                    ...addressInfo
                },
                [key]: val
            }
        }
        this.setState({...select})
    }

    handelNumber = (val, type) =>{
       let number = {}
       if(type === "parent1"){
           number = {
                parentFiled1: {
                    ...this.state.parentFiled1,
                    "mobile": val
                }
            }
       } else if(type === "parent2"){
          number = {
               parentFiled2: {
                   ...this.state.parentFiled2,
                   "mobile": val
               }
           }
       } else if(type === "family"){
          number = {
               familyFiled: {
                   ...this.state.familyFiled,
                   "mobile": val
               }
           }
       } else if(type === "medical"){
          number = {
              medicalField: {
                   ...this.state.medicalField,
                   "primaryDoctorContact": val
               }
           }
       }
       this.setState({...number})
    }

    handelOnDateChange = (key, date, val, type) =>{
        const {scheduleFields, studentFiled} = this.state
        let dateValues = {}
        if(type === "student"){
            dateValues = {
                studentFiled: {
                    ...studentFiled,
                    [key]: key === "dateOfBirth" ? date : val
                }
            }
        }else if(type === "scheduleFields"){
            dateValues = {
                scheduleFields: {
                    ...scheduleFields,
                    [key]: date
                }
            }
        }
        this.setState({...dateValues})
    }

    handleRemoveAdded =(index, key) => {
        const array = this.state[key]
        array.splice(index,1);
        this.setState({
            [key]: array
        })
    }

    handelMultiSelect = (key, val, type) => {
        let multiSelect = {}
        const {studentFiled} = this.state
        if(type === "student"){
            multiSelect = {
                studentFiled: {
                    ...studentFiled,
                    [key]: val
                }
            }
        }
        this.setState({
            ...multiSelect
        })
    }

    onProfileChange = async (info, type) =>{
        const {studentFiled, parentFiled1, parentFiled2, familyFiled} = this.state
        let isLoading = {}
        if (info.file.status === 'uploading') {
            if(type === "parent1"){
                isLoading = { isImgUploadParent1: true}
            }else if(type === "parents2Errors"){
                isLoading = { isImgUploadParent2: true }
            }else if(type === "student"){
                isLoading = { isImgUploadStudent: true }
            }else if(type === "family"){
                isLoading = { isImgUploadFamily: true }
            }
            this.setState({...isLoading})
            return;
        }

        if (info.file.status === 'done') {
            let key = await uploadFile (info.file.originFileObj);
            let imageurl = await getFileUrl (key);
            let filed = {}
            if(type === "parent1"){
                filed = {
                    parentFiled1: {
                        ...parentFiled1,
                        profile_photo: imageurl
                    },
                    isImgUploadParent1: false
                }
            }else if(type === "parent2"){
                filed = {
                    parentFiled2: {
                        ...parentFiled2,
                        profile_photo: imageurl
                    },
                    isImgUploadParent2: false
                }
            }else if(type === "student"){
                filed = {
                    studentFiled: {
                        ...studentFiled,
                        profile_photo: imageurl
                    },
                    isImgUploadStudent: false
                }
            }else if(type === "family"){
                filed = {
                    familyFiled: {
                        ...familyFiled,
                        profile_photo: imageurl
                    },
                    isImgUploadFamily: false
                }
            }
            this.setState({...filed})
        }
    }

    handelSecoundaryAdd = (item) =>{
        const {secondaryAddressList} = this.state
        secondaryAddressList.push(item)
        this.setState({
            secondaryAddressList
        })
    }

    onGetParent1Details = async () => {
        let { selectedParent1Id, parentFiled1 } = this.state
        this.setState({
            isParent1Searching: true
        })
        const data = await getUserDetailsByUserId(selectedParent1Id)
        if(data) {
            const { firstName, lastName, email, mobile, relation, isNewsletterReviewer, profile_photo, isPickup } = data.attributes || {}

            let roleArray = []
            const object = (data.role || {})[getSchoolId()] || {}
            Object.keys(object || {}).forEach(x => {
                if(data.role[getSchoolId()][x]) {
                    roleArray.push(x)
                }
            })

            parentFiled1.firstName = firstName
            parentFiled1.lastName = lastName
            parentFiled1.email = email
            parentFiled1.mobile = mobile
            parentFiled1.relation = relation
            parentFiled1.isNewsletterReviewer = isNewsletterReviewer
            parentFiled1.profile_photo = profile_photo
            parentFiled1.isPickup = isPickup
            parentFiled1.role = roleArray
        }
        this.setState({
            parentFiled1,
            selectedParent1Info: data || {},
            isParent1Searching: false
        })
    }

    onGetParent2Details = async () => {
        let { selectedParent2Id, parentFiled2 } = this.state
        this.setState({
            isParent2Searching: true
        })
        const data = await getUserDetailsByUserId(selectedParent2Id)
        if(data) {
            const { firstName, lastName, email, mobile, relation, isNewsletterReviewer, profile_photo, isPickup } = data.attributes || {}

            let roleArray = []
            const object = (data.role || {})[getSchoolId()] || {}
            Object.keys(object || {}).forEach(x => {
                if(data.role[getSchoolId()][x]) {
                    roleArray.push(x)
                }
            })

            parentFiled2.firstName = firstName
            parentFiled2.lastName = lastName
            parentFiled2.email = email
            parentFiled2.mobile = mobile
            parentFiled2.relation = relation
            parentFiled2.isNewsletterReviewer = isNewsletterReviewer
            parentFiled2.profile_photo = profile_photo
            parentFiled2.isPickup = isPickup
            parentFiled2.role = roleArray
        }
        this.setState({
            parentFiled2,
            selectedParent2Info: data || {},
            isParent2Searching: false
        })
    }

    onParent1TypeChange = (e) => {
        this.setState({
            parent1Type: e.target.value,
            parentFiled1: {firstName: '', lastName: '', email: '', mobile: '', isPickup: '', relation: '', profile_photo: '', role: [], isNewsletterReviewer: false},
            selectedParent1Id: '',
            selectedParent1Info: {}
        })
    }

    onParent2TypeChange = (e) => {
        this.setState({
            parent2Type: e.target.value,
            parentFiled2: {firstName: '', lastName: '', email: '', mobile: '', isPickup: '', relation: '', profile_photo: '', role: [], isNewsletterReviewer: false},
            selectedParent2Id: '',
            selectedParent2Info: {}
        })
    }

    render() {
        const { classes, currentUser: { newUserInfo: { groupcache: groupList = [], usersList } } } = this.props;
        const { familyError, selectedFamilyIndex, selectedScheduleIndex, parents1Errors, parents2Errors, studentErrors, parentFiled1, parentFiled2, familyFiled, medicalField, studentFiled, familyList, isParent1Searching, isParent2Searching,
                isImgUploadStudent, isImgUploadFamily, isImgUploadParent1, isImgUploadParent2, scheduleFields, primaryAddress, primaryAddressErrors, userInfo, scheduleError, scheduleList, isSaving, parent1Type, parent2Type, selectedParent1Id, selectedParent2Id, selectedParent1Info, selectedParent2Info } = this.state;
        const { selectedDays: { mon, tue, wed, thu, fri, sat, sun } } = scheduleFields || {}
        return (
            <div className={`student ${classes.root}`}>
                <div>
                    <div className="text-center mb-40">
                        <h3><b>Add Student</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 mt-100 shadow">
                        <div style={{marginTop: "-120px", textAlign: 'center'}}>
                            <ImageUpload
                                isImgUpload={isImgUploadStudent}
                                imageUrl={studentFiled.profile_photo}
                                handleImage={(info)=> this.onProfileChange(info, 'student')}/>
                        </div>

                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={6}>
                                <TextLabelInput
                                   name="firstName"
                                   value={studentFiled.firstName}
                                   onChange={(e)=> this.handelOnChange(e, 'student')}
                                   placeholder="First Name"
                                   label="First Name"
                                   required
                                   error={studentErrors && studentErrors.firstName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="lastName"
                                    value={studentFiled.lastName}
                                    onChange={(e)=> this.handelOnChange(e, 'student')}
                                    placeholder="Last Name"
                                    label="Last Name"
                                    required
                                    error={studentErrors && studentErrors.lastName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateLabelInput
                                    label="Date Of Birth"
                                    name="dateOfBirth"
                                    onChange={(date, dateString)=> this.handelOnDateChange('dateOfBirth', dateString, date, 'student')}
                                    placeholder="Date Of Birth"
                                    // required
                                    format="MM/DD/YYYY"
                                    value={studentFiled && studentFiled.dateOfBirth ? moment(studentFiled.dateOfBirth) : null}
                                    error={studentErrors && studentErrors.dateOfBirth}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Gender"
                                    name="gender"
                                    onChange={(val)=>this.handelSelect("gender", val, 'student')}
                                    placeholder="Select a Gender"
                                    // required
                                    options={["Male", "Female", "Other"] || []}
                                    value={studentFiled && studentFiled.gender}
                                    error={studentErrors && studentErrors.gender}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Status"
                                    name="status"
                                    onChange={(val)=> this.handelSelect("status", val, 'student')}
                                    placeholder="Select a Status"
                                    required
                                    options={studentStatusList || []}
                                    value={studentFiled && studentFiled.status}
                                    error={studentErrors && studentErrors.status}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Enrollment"
                                    name="studentEnrollment"
                                    onChange={(val)=> this.handelSelect("studentEnrollment", val, 'student')}
                                    placeholder="Select a Status"
                                    required
                                    options={studentEnrollmentList || []}
                                    value={studentFiled && studentFiled.studentEnrollment}
                                    error={studentErrors && studentErrors.studentEnrollment}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="primaryLanguage"
                                    value={studentFiled.primaryLanguage}
                                    onChange={(e)=> this.handelOnChange(e, 'student')}
                                    placeholder="Primary language"
                                    label="Primary language"
                                    error={studentErrors && studentErrors.primaryLanguage}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="homeLanguage"
                                    value={studentFiled.homeLanguage}
                                    onChange={(e)=> this.handelOnChange(e, 'student')}
                                    placeholder="Home language"
                                    label="Home language"
                                    error={studentErrors && studentErrors.homeLanguage}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Ethnicity"
                                    name="ethnicity"
                                    onChange={(val)=>this.handelSelect("ethnicity", val, 'student')}
                                    placeholder="Select a Ethnicity"
                                    options={ethnicityList || []}
                                    value={studentFiled && studentFiled.ethnicity}
                                    error={studentErrors && studentErrors.ethnicity}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Home Room"
                                    name="homeRoom"
                                    onChange={(val)=> this.handelSelect("homeRoom", val, 'student')}
                                    placeholder="Select a home room"
                                    options={groupList || {}}
                                    required
                                    value={studentFiled && studentFiled.homeRoom}
                                    error={studentErrors && studentErrors.homeRoom}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Others Room"
                                    mode="multiple"
                                    name="othersRoom"
                                    onChange={(val) => this.handelMultiSelect("othersRoom", val, 'student')}
                                    placeholder="Others Room"
                                    options={groupList || {}}
                                    // required
                                    value={studentFiled && studentFiled.othersRoom}
                                    error={studentErrors && studentErrors.othersRoom}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Students Schedule</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={6}>

                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <DateLabelInput
                                            label="Start date"
                                            name="startDate"
                                            onChange={(date, dateString) => this.handelOnDateChange('startDate', dateString, date, 'scheduleFields')}
                                            placeholder="Start date"
                                            // required
                                            // format="MM/DD/YYYY"
                                            value={scheduleFields && scheduleFields.startDate ? moment(scheduleFields.startDate) : null}
                                            error={scheduleError && scheduleError.startDate}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateLabelInput
                                            label="End date"
                                            name="endDate"
                                            onChange={(date, dateString) => this.handelOnDateChange('endDate', dateString, date,'scheduleFields')}
                                            placeholder="End date"
                                            format="MM/DD/YYYY"
                                            value={scheduleFields && scheduleFields.endDate ? moment(scheduleFields.endDate) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimeLabelInput
                                            label="Start Time"
                                            name="startTime"
                                            onChange={(time)=> this.handleChangeTime(time,'startTime')}
                                            placeholder="Start Time"
                                            // required
                                            value={scheduleFields && scheduleFields.startTime}
                                            error={scheduleError && scheduleError.startTime}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimeLabelInput
                                            label="End Time"
                                            name="endTime"
                                            onChange={(time) => this.handleChangeTime(time,'endTime')}
                                            placeholder="End Time"
                                            // required
                                            value={scheduleFields && scheduleFields.endTime}
                                            error={scheduleError && scheduleError.endTime}
                                        />
                                    </Grid>
                                </Grid>

                                <div className="mt-24">
                                    <Radio.Group onChange={(event) => this.handleChangeTime(event.target.value, "classType")} value={scheduleFields && scheduleFields.classType}>
                                        <Radio value={'inClass'}>In class</Radio>
                                        <Radio value={'virtual'}>Virtual</Radio>
                                    </Radio.Group>
                                </div>

                                <div className="w-100 mt-12">
                                    <div className="multiExpLblBlk font-bold text-10">
                                        Days of the week
                                    </div>

                                    <div className="weekDays-selector my-xl-2">
                                        <input onChange={(e)=> this.handelOnChange(e, 'scheduleFields')}
                                               type="checkbox"
                                               name="mon"
                                               id="weekday-mon"
                                               className="weekday"
                                               checked={mon || false}
                                        />
                                        <label htmlFor="weekday-mon">Mo</label>
                                        <input onChange={(e)=> this.handelOnChange(e, 'scheduleFields')}
                                               type="checkbox"
                                               name="tue"
                                               id="weekday-tue"
                                               className="weekday"
                                               checked={tue || false}
                                        />
                                        <label htmlFor="weekday-tue">Tu</label>
                                        <input onChange={(e)=> this.handelOnChange(e, 'scheduleFields')}
                                               type="checkbox"
                                               name="wed"
                                               id="weekday-wed"
                                               className="weekday"
                                               checked={wed || false}
                                        />
                                        <label htmlFor="weekday-wed">We</label>
                                        <input onChange={(e)=> this.handelOnChange(e, 'scheduleFields')}
                                               type="checkbox"
                                               name="thu"
                                               id="weekday-thu"
                                               className="weekday"
                                               checked={thu || false}
                                        />
                                        <label htmlFor="weekday-thu">Th</label>
                                        <input onChange={(e)=> this.handelOnChange(e, 'scheduleFields')}
                                               type="checkbox"
                                               name="fri"
                                               id="weekday-fri"
                                               className="weekday"
                                               checked={fri || false}
                                        />
                                        <label htmlFor="weekday-fri">Fr</label>
                                        <input onChange={(e)=> this.handelOnChange(e, 'scheduleFields')}
                                               type="checkbox"
                                               name="sat"
                                               id="weekday-sat"
                                               className="weekday"
                                               checked={sat || false}
                                        />
                                        <label htmlFor="weekday-sat">Sa</label>
                                        <input onChange={(e)=> this.handelOnChange(e, 'scheduleFields')}
                                               type="checkbox"
                                               name="sun"
                                               id="weekday-sun"
                                               className="weekday"
                                               checked={sun || false}
                                        />
                                        <label htmlFor="weekday-sun">Su</label>
                                    </div>

                                </div>

                                <div className="text-right">
                                    <Button className="add-button" onClick={this.addStudentSchedule} >
                                        { (selectedScheduleIndex === 0 || selectedScheduleIndex) ? "Update" : "Add" }
                                    </Button>
                                </div>

                            </Grid>

                            <Grid item xs={6}>
                                <div className="p-card-title">
                                    Added Schedule
                                </div>
                                <div>
                                    { (scheduleList || []).map((fields, index) => {
                                        return(
                                            <div key={index.toString()}>
                                                <Card className="shadow rounded-1rem mt-8">
                                                    <CardHeader
                                                        action={
                                                            <IconButton aria-label="settings" onClick={() => this.handleRemoveAdded(index, 'scheduleList')}>
                                                                <ClearTwoToneIcon />
                                                            </IconButton>
                                                        }
                                                        title={
                                                            <div onClick={() => this.setState({scheduleFields: fields, selectedScheduleIndex: index})} className="fs-15 cursor-pointer">
                                                                <div className="fs-16">
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={6}>
                                                                            <span className="fw-600">Start date:</span> {fields.startDate && moment(fields.startDate).format("MM/DD/YYYY")}
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <span className="fw-600">End date:</span> {fields.endDate && moment(fields.endDate).format("MM/DD/YYYY")}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div className="fs-16">
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={6}>
                                                                            <span className="fw-600">Start Time:</span> {fields.startTime && moment(fields.startTime, ["HH:mm"]).format("hh:mm A")}
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <span className="fw-600">End Time:</span> {fields.endTime && moment(fields.endTime, ["HH:mm"]).format("hh:mm A")}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div className="fs-16">
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <span  className="fw-600">Selected Days:</span>
                                                                            { fields.selectedDays &&
                                                                            Object.keys(fields.selectedDays).map((day, i) => fields.selectedDays[day] ? <span key={i.toString()} style={{marginRight: 5}}>{`${getDays(day)}${Object.keys(fields.selectedDays).length - 1 === i ? "" : ","}`}</span> : null)
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </Grid>

                        </Grid>
                    </Container>
                </div>
                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Primary Address</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        {/*<SelectLabelInput
                            label="Select"
                            name="userInfo"
                            onChange={(val)=>this.handelSelect("userInfo", val, 'userInfo')}
                            placeholder="Select"
                            options={primaryInfo.map(p => p.name) || []}
                            value={userInfo}
                        />*/}
                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="address1"
                                    value={primaryAddress.address1}
                                    onChange={(e) => this.handelOnChange(e, 'primary')}
                                    placeholder="Address1"
                                    label="Address 1"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.address1}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="address2"
                                    value={primaryAddress.address2}
                                    onChange={(e)=>this.handelOnChange(e, 'primary')}
                                    placeholder="Address2"
                                    label="Address 2"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="address3"
                                    value={primaryAddress.address3}
                                    onChange={(e)=>this.handelOnChange(e, 'primary')}
                                    placeholder="Address3"
                                    label="Address 3"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="city"
                                    value={primaryAddress.city}
                                    onChange={(e)=>this.handelOnChange(e, 'primary')}
                                    placeholder="City"
                                    label="City"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.city}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="state"
                                    value={primaryAddress.state}
                                    onChange={(e)=>this.handelOnChange(e, 'primary')}
                                    placeholder="State"
                                    label="State"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.state}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="postalCode"
                                    value={primaryAddress.postalCode}
                                    onChange={(e)=>this.handelOnChange(e, 'primary')}
                                    placeholder="Postal Code"
                                    label="Postal Code"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.postalCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Country"
                                    name="country"
                                    onChange={(val)=>this.handelSelect("country", val, 'primary')}
                                    placeholder="Select a country"
                                    options={countryOptionList || []}
                                    value={primaryAddress && primaryAddress.country}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Secondary Address</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        <SecondaryAddress handelSecoundaryAdd={this.handelSecoundaryAdd}/>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Parents</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        <Grid container spacing={6} >
                            <Grid item xs={6}>
                                <div className="rounded-1rem bg-white shadow" style={{padding: 20, marginBottom: 20}}>
                                    <Radio.Group onChange={this.onParent1TypeChange} value={parent1Type}>
                                        <Radio value={'existing'}>Select Existing Parent</Radio>
                                        <Radio value={'create'}>Create New Parent</Radio>
                                    </Radio.Group>
                                    {
                                        parent1Type === 'existing' ?
                                            <div style={{marginTop: 15}}>
                                                <SelectParentInput
                                                    label="Select Parent"
                                                    name="country"
                                                    onChange={(value) => this.setState({selectedParent1Id: value})}
                                                    placeholder="Select a Parent"
                                                    options={usersList || []}
                                                    value={selectedParent1Id || ''}
                                                    style={{width: 300}}
                                                />
                                                <div style={{marginTop: 15}}>
                                                    <Button
                                                        disabled={!selectedParent1Id || isParent1Searching}
                                                        onClick={this.onGetParent1Details}
                                                        type='primary'
                                                        loading={isParent1Searching}
                                                    >
                                                        View parent Details
                                                    </Button>
                                                </div>
                                            </div> : null
                                    }
                                </div>
                                {
                                    parent1Type === 'create' || selectedParent1Info.id ?
                                        <>
                                            <ImageUpload
                                                isImgUpload={isImgUploadParent1}
                                                imageUrl={parentFiled1.profile_photo}
                                                handleImage={(info)=> this.onProfileChange(info, 'parent1')}
                                                disabled={selectedParent1Info.id && parent1Type === 'existing'}
                                            />
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="firstName"
                                                    value={parentFiled1.firstName}
                                                    onChange={(e)=>this.handelOnChange(e, 'parent1')}
                                                    placeholder="First Name"
                                                    label="First Name"
                                                    required
                                                    error={!parentFiled1.firstName && parents1Errors && parents1Errors.firstName}
                                                    disabled={selectedParent1Info.id && parent1Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="lastName"
                                                    value={parentFiled1.lastName}
                                                    onChange={(e)=>this.handelOnChange(e, 'parent1')}
                                                    placeholder="Last Name"
                                                    label="Last Name"
                                                    required
                                                    error={!parentFiled1.lastName && parents1Errors && parents1Errors.lastName}
                                                    disabled={selectedParent1Info.id && parent1Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="email"
                                                    value={parentFiled1.email}
                                                    onChange={(e) => this.handelOnChange(e, 'parent1')}
                                                    placeholder="Email"
                                                    label="Email"
                                                    required
                                                    error={!parentFiled1.email && parents1Errors && parents1Errors.email}
                                                    disabled={selectedParent1Info.id && parent1Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <SelectUserRoleInput
                                                    label="Role"
                                                    name="role"
                                                    onChange={(value) => this.handelOnChange({target: { name: "role", value }}, 'parent1')}
                                                    // placeholder="Select a role"
                                                    required
                                                    value={parentFiled1.role}
                                                    error={!(parentFiled1.role || []).length && parents1Errors && parents1Errors.role}
                                                    disabled={selectedParent1Info.id && parent1Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <NumberLabelInput
                                                    name="mobile"
                                                    value={parentFiled1.mobile}
                                                    onChange={(num)=>this.handelNumber(num, 'parent1')}
                                                    placeholder="Mobile"
                                                    label="Mobile"
                                                    error={parents1Errors && parents1Errors.mobile}
                                                    disabled={selectedParent1Info.id && parent1Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <SelectLabelInput
                                                    label="Relation"
                                                    name="relation"
                                                    onChange={(e)=> this.handelSelect('relation', e, 'parent1')}
                                                    placeholder="Select a relation"
                                                    options={parentsRelation || []}
                                                    value={parentFiled1 && parentFiled1.relation}
                                                    error={parents1Errors && parents1Errors.relation}
                                                    disabled={selectedParent1Info.id && parent1Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <div className="multiExpLblBlk font-bold fs-14">
                                                    Approved for Pickup
                                                </div>
                                                <div>
                                                    <Radio.Group name="isPickup" onChange={(e)=>this.handelOnChange(e, 'parent1')} value={parentFiled1.isPickup}
                                                                 disabled={selectedParent1Info.id && parent1Type === 'existing'}>
                                                        <Radio value="yes">Yes</Radio>
                                                        <Radio value="no">No</Radio>
                                                    </Radio.Group>
                                                </div>
                                                <span className="text-red form-control-sm fs-8">{parents1Errors && parents1Errors.isPickup}</span>
                                            </div>
                                        </> : null
                                }

                            </Grid>
                            <Grid item xs={6}>
                                <div className="rounded-1rem bg-white shadow" style={{padding: 20, marginBottom: 20}}>
                                    <Radio.Group onChange={this.onParent2TypeChange} value={parent2Type}>
                                        <Radio value={'existing'}>Select Existing Parent</Radio>
                                        <Radio value={'create'}>Create New Parent</Radio>
                                    </Radio.Group>
                                    {
                                        parent2Type === 'existing' ?
                                            <div style={{marginTop: 15}}>
                                                <SelectParentInput
                                                    label="Select Parent"
                                                    name="country"
                                                    onChange={(value) => this.setState({selectedParent2Id: value})}
                                                    placeholder="Select a Parent"
                                                    options={usersList || []}
                                                    value={selectedParent2Id || ''}
                                                    style={{width: 300}}
                                                />
                                                <div style={{marginTop: 15}}>
                                                    <Button
                                                        disabled={!selectedParent2Id || isParent2Searching}
                                                        onClick={this.onGetParent2Details}
                                                        type='primary'
                                                        loading={isParent2Searching}
                                                    >
                                                        View parent Details
                                                    </Button>
                                                </div>
                                            </div> : null
                                    }
                                </div>

                                {
                                    parent2Type === 'create' || selectedParent2Info.id ?
                                        <>
                                            <ImageUpload
                                                isImgUpload={isImgUploadParent2}
                                                imageUrl={parentFiled2.profile_photo}
                                                handleImage={(info)=> this.onProfileChange(info, 'parent2')}
                                                disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                            />
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="firstName"
                                                    value={parentFiled2.firstName}
                                                    onChange={(e)=>this.handelOnChange(e, 'parent2')}
                                                    placeholder="First Name"
                                                    label="First Name"
                                                    required
                                                    error={!parentFiled2.firstName && parents2Errors && parents2Errors.firstName}
                                                    disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="lastName"
                                                    value={parentFiled2.lastName}
                                                    onChange={(e)=>this.handelOnChange(e, 'parent2')}
                                                    placeholder="Last Name"
                                                    label="Last Name"
                                                    required
                                                    error={!parentFiled2.lastName && parents2Errors && parents2Errors.lastName}
                                                    disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <TextLabelInput
                                                    name="email"
                                                    value={parentFiled2.email}
                                                    onChange={(e)=>this.handelOnChange(e, 'parent2')}
                                                    placeholder="Email"
                                                    label="Email"
                                                    required
                                                    error={!parentFiled2.lastName && parents2Errors && parents2Errors.email}
                                                    disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <SelectUserRoleInput
                                                    label="Role"
                                                    name="role"
                                                    onChange={(value) => this.handelOnChange({target: { name: "role", value }}, 'parent2')}
                                                    // placeholder="Select a role"
                                                    required
                                                    value={parentFiled2.role}
                                                    error={!(parentFiled2.role || []).length && parents2Errors && parents2Errors.role}
                                                    disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <NumberLabelInput
                                                    name="mobile"
                                                    value={parentFiled2.mobile}
                                                    onChange={(num)=>this.handelNumber(num, 'parent2')}
                                                    placeholder="Mobile"
                                                    label="Mobile"
                                                    error={parents2Errors && parents2Errors.mobile}
                                                    disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                                />
                                            </div>
                                            <div className="p-card-title">
                                                <SelectLabelInput
                                                    label="Relation"
                                                    name="relation"
                                                    onChange={(e)=> this.handelSelect('relation', e, 'parent2')}
                                                    placeholder="Select a relation"
                                                    options={parentsRelation || []}
                                                    value={parentFiled2 && parentFiled2.relation}
                                                    error={parents2Errors && parents2Errors.relation}
                                                    disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                                />
                                            </div>

                                            <div className="p-card-title">
                                                <div className="multiExpLblBlk font-bold fs-14">
                                                    Approved for Pickup
                                                </div>
                                                <div>
                                                    <Radio.Group
                                                        name="isPickup"
                                                        onChange={(e) => this.handelOnChange(e, 'parent2')}
                                                        value={parentFiled2.isPickup}
                                                        disabled={selectedParent2Info.id && parent2Type === 'existing'}
                                                    >
                                                        <Radio value="yes">Yes</Radio>
                                                        <Radio value="no">No</Radio>
                                                    </Radio.Group>
                                                </div>
                                                <span className="text-red form-control-sm fs-8">{parents2Errors && parents2Errors.isPickup}</span>
                                            </div>
                                        </> : null
                                }

                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Family </b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        <Grid container spacing={6} >
                            <Grid item xs={6}>
                                <ImageUpload
                                    isImgUpload={isImgUploadFamily}
                                    imageUrl={familyFiled.profile_photo}
                                    handleImage={(info)=> this.onProfileChange(info, 'family')}
                                />
                                <div className="p-card-title">
                                    <TextLabelInput
                                        name="firstName"
                                        value={familyFiled.firstName}
                                        onChange={(e)=>this.handelOnChange(e, 'family')}
                                        placeholder="First Name"
                                        label="First Name"
                                        // required
                                        error={familyError && familyError.firstName}
                                    />
                                </div>
                                <div className="p-card-title">
                                    <TextLabelInput
                                        name="lastName"
                                        value={familyFiled.lastName}
                                        onChange={(e)=>this.handelOnChange(e, 'family')}
                                        placeholder="Last Name"
                                        label="Last Name"
                                        // required
                                        error={familyError && familyError.lastName}
                                    />
                                </div>
                                <div className="p-card-title">
                                    <TextLabelInput
                                        name="email"
                                        value={familyFiled.email}
                                        onChange={(e)=>this.handelOnChange(e, 'family')}
                                        placeholder="Email"
                                        label="Email"
                                        // required
                                        error={familyError && familyError.email}
                                    />
                                </div>
                                <div className="p-card-title">
                                    <NumberLabelInput
                                        name="mobile"
                                        value={familyFiled.mobile}
                                        onChange={(num)=>this.handelNumber(num, 'family')}
                                        placeholder="Mobile"
                                        label="Mobile"
                                        // required
                                        error={familyError && familyError.mobile}
                                    />
                                </div>
                                <div className="p-card-title">
                                    <SelectLabelInput
                                        label="Relation"
                                        name="relation"
                                        onChange={(e)=> this.handelSelect('relation', e, 'family')}
                                        placeholder="Select a relation"
                                        options={relationList || []}
                                        value={familyFiled.relation}
                                    />
                                </div>
                                <div className="p-card-title">
                                    <div className="multiExpLblBlk font-bold fs-14">
                                        Approved for Pickup
                                    </div>
                                    <div>
                                        <Radio.Group name="isPickup" onChange={(e)=>this.handelOnChange(e, 'family')} value={familyFiled.isPickup}>
                                            <Radio value="yes">Yes</Radio>
                                            <Radio value="no">No</Radio>
                                        </Radio.Group>
                                    </div>
                                    <span className="text-red form-control-sm fs-8">{familyError && familyError.isPickup}</span>
                                </div>
                                <div className="text-right">
                                    <Button className="add-button" onClick={this.handelFamilySubmit} >
                                        { (selectedFamilyIndex === 0 || selectedFamilyIndex) ? "Update" : "Add" }
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-card-title">
                                    Added-Family
                                </div>
                                <div>
                                    { (familyList || []).map((family, index) => {
                                        return(
                                            <div key={index.toString()}>
                                                <Card className="shadow rounded-1rem mt-8">
                                                    <CardHeader
                                                        avatar={
                                                            <div onClick={() => this.setState({familyFiled: family, selectedFamilyIndex: index})} className="cursor-pointer">
                                                                {
                                                                    family.profile_photo ? <Avatar src={family.profile_photo} aria-label="recipe" style={{backgroundColor: getColor(index)}} /> :
                                                                        <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                            {`${(family && family.firstName || "").charAt(0).toUpperCase()}${(family && family.lastName || "").charAt(0).toUpperCase()}`}
                                                                        </Avatar>
                                                                }
                                                            </div>
                                                        }
                                                        action={
                                                            <IconButton aria-label="settings" onClick={() => this.handleRemoveAdded(index, 'familyList')}>
                                                                <ClearTwoToneIcon />
                                                            </IconButton>
                                                        }
                                                        title={<div onClick={() => this.setState({familyFiled: family, selectedFamilyIndex: index})} className="fa-15 cursor-pointer">{`${family.firstName} ${family.lastName}`}</div>}
                                                        subheader={<span onClick={() => this.setState({familyFiled: family, selectedFamilyIndex: index})} className="cursor-pointer">{family.relation}</span>}
                                                    />
                                                </Card>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Medical Report</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        <Grid container spacing={6} >
                            <Grid item xs={6}>
                                <div className="p-card-title">
                                    <TextLabelInput
                                        name="primaryDoctor"
                                        value={medicalField.primaryDoctor}
                                        onChange={(e)=>this.handelOnChange(e, 'medical')}
                                        placeholder="Primary Doctor Name"
                                        label="Primary Doctor Name"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-card-title">
                                    <NumberLabelInput
                                        name="primaryDoctorContact"
                                        value={medicalField.primaryDoctorContact}
                                        onChange={(num)=>this.handelNumber(num, 'medical')}
                                        placeholder="Primary Doctor Contact"
                                        label="Primary Doctor Contact"
                                    />

                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-card-title">
                                    <TextLabelInput
                                        name="currentMedications"
                                        value={medicalField.currentMedications}
                                        onChange={(e)=>this.handelOnChange(e, 'medical')}
                                        placeholder="Current Medications"
                                        label="Current Medications"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="p-card-title">
                                    <TextLabelInput
                                        name="allergies"
                                        value={medicalField.allergies}
                                        onChange={(e)=>this.handelOnChange(e, 'medical')}
                                        placeholder="Allergies"
                                        label="Allergies"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div className="mt-20">
                    <Container>
                        <div className="p-card-title text-center">
                            <Button size="large" onClick={() => this.props.history.push(routes.STUDENT_DASHBOARD)}>Cancel</Button>&nbsp;
                            <Button
                                size="large"
                                className="review-button"
                                onClick={this.handelReview}
                                loading={isSaving}
                            >
                                Save
                            </Button>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

CreateStudent.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, null)(withStyles(styles)(CreateStudent))