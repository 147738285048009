import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";

import DropZoneField from "../../common/reduxForm/RenderDropZoneField";
import "../css/GeneralDetails.css";
import PrevImg from "../../../assets/images/Placeholder.png";
import RenderRichTextEditor from "../../common/reduxForm/RenderRichTextEditor";
import RenderTextField from "../../common/reduxForm/RenderTextField";
import RenderTimePicker from "../../common/reduxForm/RenderTimePicker";
import RenderDatePicker from "../../common/reduxForm/RenderDatePicker";
import RenderSwitch from "../../common/reduxForm/RenderSwitch";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  control: {
    padding: theme.spacing(1) * 2
  },
  textField: {
    width: "100%"
  },
  rickTextEditor: {
    height: 50 * theme.spacing(1),
    overflow: "auto"
  },
  imageUpload: {
    paddingTop: theme.spacing(1) * 2
  }
});

class GeneralDetailsForm extends Component {
  state = {
    files: [],
    titleError: false,
    titleText: "",
    isErrorLocation: false,
    imagePreview: PrevImg,
    showLoader: false,
    imageFile: []
  };

  render() {
    const { classes, isMeeting, isVolunteer, generalDetailsForm } = this.props;
    //const { flag } = isMeeting ? isMeeting : isVolunteer;
    console.log("PROPS", this.props)
    const isDisabled = !!(generalDetailsForm || {}).id || false
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.control}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <div className="overlay">
                  <Field
                    name={`attributes.event_img_url`}
                    component={DropZoneField}
                    type="file"
                    accept="image/*"
                    imagePrev={true}
                    multiple={false}
                    linkName="Browse Image"

                  />
                </div>
              </Grid>
              {!(isMeeting || isVolunteer) && <Grid item xs={6}>
                <FormLabel className="formLabel">Start Date</FormLabel>
                <Field
                  name="start_date"
                  component={RenderDatePicker}
                  label="Start date"
                  className={classes.textField}
                  disabled={isDisabled}
                />
              </Grid>}
              {!(isMeeting || isVolunteer) && <Grid item xs={6}>
                <FormLabel className="formLabel">End Date</FormLabel>
                <Field
                  name="end_date"
                  component={RenderDatePicker}
                  label="End date"
                  className={classes.textField}
                  disabled={isDisabled}
                />
              </Grid>}

              {!(isMeeting || isVolunteer) && !(generalDetailsForm.attributes || {}).all_day_event &&
                <Grid item xs={6}>
                  <FormLabel className="formLabel">Start Time</FormLabel>
                  <Field
                    name="start_time"
                    component={RenderTimePicker}
                    label="Start Time"
                    className={classes.textField}
                    disabled={isDisabled}
                  />
                </Grid>
              }
              {!(isMeeting || isVolunteer) && !(generalDetailsForm.attributes || {}).all_day_event &&
                <Grid item xs={6}>
                  <FormLabel className="formLabel">End Time</FormLabel>
                  <Field
                    name="end_time"
                    component={RenderTimePicker}
                    label="End Time"
                    className={classes.textField}
                    disabled={isDisabled}
                  />
                </Grid>
              }
              {!(isMeeting || isVolunteer) &&
                <Grid item xs={12}>
                  <Field
                    name={`attributes.all_day_event`}
                    label='All Day Event'
                    component={RenderSwitch}
                    isDisabled={isDisabled}
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel className="formLabel">Event Title</FormLabel>
                  <Field
                    label="Enter title"
                    name={`attributes.event_title`}
                    component={RenderTextField}
                    className={classes.textField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormLabel className="formLabel">Event Location</FormLabel>
                <Field
                  label="Enter Location"
                  name={`attributes.location`}
                  component={RenderTextField}
                  className={classes.textField} />
              </Grid>

              <Grid item xs={12}>
                <Field
                  label="Description"
                  name={`attributes.event_description`}
                  component={RenderRichTextEditor}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="overlay-attachment">
                  <Field
                    name={`attributes.attachments`}
                    component={DropZoneField}
                    type="file"
                    imagePrev={false}
                    multiple={true}
                    linkName="Upload Attachment"
                  />
                </div>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(GeneralDetailsForm));
