import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Storage } from "aws-amplify";
import classnames from "classnames";
import { isEmpty as _isEmpty, split } from 'lodash'
import Moment from 'react-moment';
import Button from '@material-ui/core/Button';

import * as routes from '../../../routes/path';
import CalImg from "../../../assets/images/calendar-2.png";
import AlarmImg from "../../../assets/images/alarm-clock.png";

import CustomTabs from "./CustomTabs";
import EditRsvp from "./EditRsvp";
import Message from "./Message";
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {MyDocument} from "./MyDocument";
import { PDFViewer } from '@react-pdf/renderer';


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  card: {
    display: "flex",
    minHeight: "300px"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    height: 400,
    margin: "-50px 0px",
  },
  coverContent: {
    padding: 25
  },
  rsvpIcon: {
    padding: "20px 0px 0px 15px",
    height: 70,
  },
  rsvpDateTime: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    flexDirection: "row-reverse",
    textAlign: "right"
  },
  rsvpDateTimeChild: {
    flex: "0 0 calc(70% - 10px)"
  },
  rsvpDateTimeChildimg: {
    flex: "0 0 calc(30% - 10px)"
  },
});

class UpdateRsvp extends Component {
  state = {
    image: "https://picsum.photos/200/300/?blur",
    open: false,
    value: 0,
    event_id: '',
  };

  componentDidMount() {
    this.setEventValue();
  }

  componentWillReceiveProps(nextProps) {
    if (!_isEmpty(nextProps.event)) {
      this.getImage(nextProps)
    }
  }

  setEventValue = () => {
    this.setEventId();
    this.props.resetStepperActiveStep();
  }

  getImage = async (props) => {
    const event = props.event;
    if (event.img_url) {
      const img = await Storage.get(event.img_url[0].key, { level: "public" });
      this.setState({ image: img });
    }
  };

  setEventId() {
    const event_id = split(this.props.location.pathname, '/')[3];
    this.props.getEventById.request({ event_id })
    // this.props.fetchEventResponseAction.request({ event_id })
    this.setState({ event_id })
  }

  handleEditDetail = (e) => {
    const { event = {} } = this.props
    this.props.setInitialValue(event);
    this.props.history.push(routes.MANAGE_RSVP_EVENT + '/' + event.event_id)
  }

  sendMessage = (event) => {
    this.props.openDialogAction('MESSAGE_OPEN')

  };

  handleEditRecord = (event) => (args) => {
    this.setState({ selectedRow: event });
    this.props.openDialogAction('RSVP_RESPONSE_EDIT')
  };

  handlePreview = () => {
    this.setState({ open: true, data: {} });
  };

  render() {
    const { classes, event, dialogProps, currentUser = {} } = this.props;
    const { open, typeOfDialog } = dialogProps
    const { attributes: { event_title = "", all_day_event = false, event_img_url = [], limit = "" } = {} } = event;
    return (
      <div className={classes.root} >
        <Grid container>
          <Grid item xs={12} className={classes.control}>
            <Grid container spacing={5}>
              {event && (
                <Grid item xs={8} className="centerdiv">
                  <Card className="cardComponent">
                    <Grid container>
                      <Grid item xs={6}>
                        <CardMedia
                          className={classes.cover}
                          image={this.state.image}
                        />
                      </Grid>
                      <Grid item xs={6} className={classes.coverContent}>
                        <Typography
                          color="primary"
                          variant="h6"
                          className="eventDialogTitleRSVP"
                        >
                          {event_title}
                        </Typography>
                        <Typography component="div" className={classes.rsvpDateTime}>
                          <div className={classes.rsvpDateTimeChildimg}>
                            <img
                              className={classes.rsvpIcon}
                              alt="previcon"
                              src={CalImg}
                            />
                          </div>
                          <div className={classes.rsvpDateTimeChild}>
                            <Moment format="ddd D, MMMM YYYY">
                              {event.start_date}
                            </Moment>
                            <br />
                            <Moment format="ddd D, MMMM YYYY">
                              {event.end_date}
                            </Moment>
                          </div>
                        </Typography>
                        <Typography component="div" className={classes.rsvpDateTime}>
                          <div className={classes.rsvpDateTimeChildimg}>
                            <img
                              className={classes.rsvpIcon}
                              alt="previcon"
                              src={AlarmImg}
                            />
                          </div>
                          <div className={classes.rsvpDateTimeChild}>
                            {
                              all_day_event ?
                                <div style={{marginTop: 8}}>All day event</div> :
                                <>
                                  <Moment format="h:mm a">
                                    {event.start_time}
                                  </Moment>
                                  <br />
                                  <Moment format="h:mm a">
                                    {event.end_time}
                                  </Moment>
                                </>
                            }
                          </div>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}

              <Grid item xs={10} className="centerBottomDiv">
                <Card className="cardComponent">
                  <div className={classes.details}>
                    <CardContent
                      className={classnames(classes.content, "prevContainer")}
                    >
                      <Grid item xs={8} className="displayData">
                        <div className="rsvp-around button-section">
                          <Button
                            variant="outlined"
                            color="secondary"
                            className="editbutton"
                            value="yes"
                            onClick={this.handleEditDetail}
                          >
                            Edit Details
                          </Button>

                          {/*<Button
                            variant="outlined"
                            color="primary"
                            value="maybe"
                            className="editbutton"
                            onClick={this.sendMessage}
                          >
                            Message Guests
                          </Button>*/}
                        </div>
                      </Grid>

                      <Grid container justify="space-around" className={classes.alignment} >
                        <div>
                          Limit: {limit}
                        </div>
                      </Grid>

                      <Grid item xs={12} className="displayTab">
                        <CustomTabs
                          rowsRsvp={this.props.rowsRsvp || []}
                          rsvpFilterValue={this.props.rsvpFilterValue}
                          handleRsvpFilterAction={this.props.handleRsvpFilterAction}
                          handleRsvpSearchAction={this.props.handleRsvpSearchAction}
                          handleEditRecord={this.handleEditRecord}
                          handlePreview={this.handlePreview}
                        />
                      </Grid>
                    </CardContent>
                  </div>
                </Card>
              </Grid>

            </Grid>

          </Grid>
        </Grid>

        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          fullWidth
          fullScreen
        >
          <DialogContent>
            <PDFViewer style={{ width: '100%', height: '100%' }}>
              <MyDocument
                rowsRsvp={(this.props.rowsRsvp || []).filter(x => x.will_attend === "Yes") || []}
                adult={0}
                children={0}
              />
            </PDFViewer>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={() => this.setState({open: false})}
                color="primary"
            >
              Cancel
            </Button>
              <Button
                  onClick={() => this.setState({open: false})}
                  color="primary"
              >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {typeOfDialog === "RSVP_RESPONSE_EDIT" &&
          <EditRsvp
            open={open}
            onClose={this.props.closeDialogAction}
            updateRsvpEventResponseAction={this.props.updateRsvpEventResponseAction}
            row={this.state.selectedRow}
            currentUser={currentUser || {}}
            setEventValue={this.setEventValue}
          />
        }

        {typeOfDialog === "MESSAGE_OPEN" &&
          <Message
            open={open}
            // onClose={this.props.closeDialogAction}
            history={this.props.history}
            currentUser={currentUser || {}}
            closeDialogAction={this.props.closeDialogAction}
            event_id={event && event.event_id}
            row={this.props.rowsRsvp}
          />
        }
      </div >
    );
  }
}


export default withStyles(styles)(UpdateRsvp)
