import moment from "moment";
import { EVENT_TYPES } from '../enums'
// import { getCurrentSchoolId } from '../../utils/utils'

const GENERIC_VALUE = {
    attributes: {
        event_title: "",
        event_description: "",
        event_img_url: [{key:'1562189344301',name:'default.jpg'}],
        location: "",
        all_day_event: true,
        notify_days_before_event: [],
        notify_users: false,
        event_type: EVENT_TYPES.Generic_Event,
        groups: [],
        child_ids: [],
        attachments: [],
        event_color: "#e1efeb",
        groupParentSelection: ""
    },
    start_time: moment(new Date(), "HH:mm").toDate(),
    end_time: moment(new Date(), "HH:mm").toDate(),
    start_date: moment(new Date(), "HH:mm").toDate(),
    end_date: moment(new Date(), "HH:mm").toDate(),
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    // school_id: getCurrentSchoolId()
}

const RSVP_VALUE = {
    attributes: {
        event_title: "",
        event_description: "",
        event_img_url: [{key:'1562189344301',name:'default.jpg'}],
        location: "",
        all_day_event: true,
        notify_days_before_event: [],
        notify_users: false,
        event_type: EVENT_TYPES.RSVP_Event,
        limit: "",
        groups: [],
        child_ids: [],
        attachments: [],
        event_color: "#e1efeb",
        groupParentSelection: ""
    },
    start_time: moment(new Date(), "HH:mm").toDate(),
    end_time: moment(new Date(), "HH:mm").toDate(),
    start_date: moment(new Date(), "HH:mm").toDate(),
    end_date: moment(new Date(), "HH:mm").toDate(),
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    // school_id: getCurrentSchoolId()
}

const MEETING_VALUE = {
    parentTeacherResponse: [],
    attributes: {
        event_title: "",
        event_description: "",
        event_img_url: [{key:'1562189344301',name:'default.jpg'}],
        location: "",
        all_day_event: true,
        notify_days_before_event: [],
        notify_users: false,
        event_type: EVENT_TYPES.Parent_Teacher_Event,
        groups: [],
        child_ids: [],
        attachments: [],
        event_color: "#e1efeb",
        draft: false,
        schedule: {},
        groupParentSelection: ""
    },
    start_time: moment(new Date(), "HH:mm").toDate(),
    end_time: moment(new Date(), "HH:mm").toDate(),
    start_date: moment(new Date(), "HH:mm").toDate(),
    end_date: moment(new Date(), "HH:mm").toDate(),
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    // school_id: getCurrentSchoolId()
}

const VOLUNTEER_VALUE = {
    attributes: {
        event_title: "",
        event_description: "",
        event_img_url: [{key:'1562189344301',name:'default.jpg'}],
        location: "",
        all_day_event: true,
        notify_days_before_event: [],
        notify_users: false,
        event_type: EVENT_TYPES.Volunteer_Event,
        groups: [],
        child_ids: [],
        attachments: [],
        event_color: "#e1efeb",
        draft: false,
        schedule: {},
        groupParentSelection: ""
    },
    notifyOneDayAgo: false,
    notifyTwoDayAgo: false,
    notifyThreeDayAgo: false,
    volunteertype: '1',
    daterangetype: '1',
    volunteer: [],
    slotTime: [],
    dates:{},
}

export function getEventInitialValue(eventType) {
    switch (eventType) {
        case EVENT_TYPES.Generic_Event:
            return GENERIC_VALUE
        case EVENT_TYPES.RSVP_Event:
            return RSVP_VALUE
        case EVENT_TYPES.Parent_Teacher_Event:
            return MEETING_VALUE
        case EVENT_TYPES.Volunteer_Event:
            return VOLUNTEER_VALUE
        default:
            return GENERIC_VALUE
    }
}