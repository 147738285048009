import React,{useState,useEffect} from "react";
import BadgeAvatar from "./BadgeAvatar"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import "./index.css"

const useStyles = makeStyles((theme) => ({
    badgeIcon: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        backgroundColor: "green",
        borderRadius: 20,
        color: "#fff",
      },
      inline: {
        display: 'inline',
      },
}));
{/* id="custom-scroll" */}
export default function GroupListSelector({groups={},selectedGroupAction,selectedGroup}){
    const classes = useStyles();
    return(
        <div className="group-list-container">
            <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                style={{fontWeight: "bold"}}
                color="textPrimary">
                Select a Class
            </Typography>
            <div className="group-list-scrollview">
                {Object.values(groups).map(group=> {

                    if (!group.attributes.is_archive) {
                        return (
                            <BadgeAvatar
                                name={`${group.attributes.groupname}`}
                                renderbadge={() => {
                                    return selectedGroup?.id === group.id ? (
                                        <Check className={classes.badgeIcon}/>) : null
                                }}
                                height={50}
                                width={50}
                                avatar_photo={group.attributes.profile_photo}
                                avatar_name={group.attributes.groupname}
                                item={group}
                                selectItemAction={(group) => {
                                    selectedGroupAction(group);
                                }}
                            />
                        )
                    }
                })}
            </div>
        </div>
    )
}