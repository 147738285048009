import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import Moment from 'react-moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete"
import { getCurrentSchoolId, getTimeFormat, getDateFormat } from "../../../utils/utils"

const styles = theme => ({
  dateTimeCard: {
    padding: "0 0 20px 0",
    boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: "12px"
  },
  dateTitme: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#05b9a4",
    padding: 20
  },
  slotTime: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000",
    border: "1px solid rgba(143, 143, 150, 0.38)",
    borderRadius: "50px",
    padding: "0px 10px",
    textAlign: "center"
  },
  centerContent: {
    width: "100%",
    margin: "20px auto",
  },
  table: {
    minWidth: 620,
    margin: "10px 35px",
    width: "95% !important"
  },
  customthead: {
    fontSize: 16
  },
  innercell: {
    // borderBottom: "0px",
    width: "200px",
    padding: "5px"
  }
});

class PreviewMeeting extends Component {
    state = {
        loading: false,
        openSignup: false,
        responseIndex: "",
        subIndex: "",
        anchorElSignUp: null,
        slotDetails: {}
    };

    getChildList = (child_ids) => {
        const { data } = this.props
        const list = (data || []).filter(x => ((child_ids || []).includes(x.id))) || []
        return list || []
    }

    isParentChecked = (users) => {
        const { currentUser } = this.props
        const isParent = (users || []).some(user => ((user || []).includes(currentUser.id))) || false
        return isParent || false
    }

    handleSignup = (event, index, subIndex, details) => {
        this.setState({
            anchorElSignUp: event.currentTarget,
            responseIndex: index,
            subIndex: subIndex,
            openSignup: true,
            slotDetails: details
        })
    };

    getStripedStyle = (index) => {
        return { background: index % 2 ? '#f4f4f4' : 'white' };
    }

    closeSignup = () => {
        this.setState({
            openSignup: false
        })
    };

    sendSignupResponse = (value) => {
        const { currentUser = {}, event = {}, setEventResponse, getEventResponse } = this.props
        const { loading, slotDetails } = this.state

        if (!loading) {
            this.setState({
                loading: true,
                openSignup: true
            })
            setTimeout(() => {
                this.setState({
                    loading: false,
                    responseIndex: "",
                    subIndex: ""
                })
            }, 4000);
            if (value) {
                const event_date = getDateFormat(slotDetails.event_date)
                const startTime = getTimeFormat(slotDetails.start_time)
                const endTime = getTimeFormat(slotDetails.end_time)
                const body = {
                    school_id: getCurrentSchoolId(currentUser),
                    for_user: currentUser.id,
                    event_id: event.id,
                    new_date: event_date,
                    new_time: `${startTime}-${endTime}`,
                    child_id: value.id,
                    child_id_remove:""
                }
                setEventResponse.request(body)
                setTimeout(() => getEventResponse(), 2000)
            }

            this.setState({
                openSignup: false
            })
        }
    }

    removeChild = (childId, time, event_date) => {
        const { currentUser = {}, event = {}, removeChildEventAction, getEventResponse } = this.props

        const eventDate = getDateFormat(event_date)
        const startTime = getTimeFormat(time.start_time)
        const endTime = getTimeFormat(time.end_time)

        const body = {
            school_id: Number(getCurrentSchoolId(currentUser)),
            for_user: currentUser.id,
            event_id: String(event.id),
            new_date: eventDate,
            new_time: `${startTime}-${endTime}`,
            // child_id: "",
            child_id_remove: String(childId)
        }
        //console.log({body})
        removeChildEventAction.request(body)
        setTimeout(() => getEventResponse(), 2000)
    }

    render() {
        const { classes, event: { parentTeacherResponse = [] } = {}, readOnly, data, isAdmin } = this.props
        const { loading, responseIndex, subIndex, openSignup, anchorElSignUp } = this.state
        return(
            <>
                {(parentTeacherResponse || []).map((date, index) => (
                    <Grid container key={index}>
                        <Grid item xs={10} className={classes.centerContent}>
                            <Grid container spacing={10}>
                                <Grid item xs={12} className="centerdatetime">
                                    <Card className={classes.dateTimeCard}>
                                        <Grid container>
                                            <Grid item xs={4} className={classes.dateTitme}>
                                                <Moment format="ddd D, MMMM YYYY" date={date.event_date} />
                                            </Grid>
                                        </Grid>
                                        <Table className={classes.table} fixedheader="false" style={{ width: "auto", tableLayout: "auto" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.customthead}>#</TableCell>
                                                    <TableCell align="left" className={classes.customthead}>Spots</TableCell>
                                                    <TableCell align="left" className={classes.customthead}>Participant</TableCell>
                                                    <TableCell align="left" className={classes.customthead}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(date.slotTime || []).map((time, i) => (
                                                    <TableRow key={i} style={this.getStripedStyle(i)}>
                                                        <TableCell component="th" scope="row">
                                                            {i}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Moment format="hh:mm a" date={time.start_time} />
                                                            -
                                                            <Moment format="hh:mm a" date={time.end_time} />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                (this.getChildList(time.child_ids || []) || []).map((child, childIndex) =>
                                                                    (
                                                                        <span key={childIndex}>
                                                                            {child.firstName} {child.lastName}
                                                                            { (this.isParentChecked((time.users || [])) || isAdmin) &&
                                                                                <Tooltip title="Delete">
                                                                                    <IconButton aria-label="Delete" size="small" onClick={() => this.removeChild(child.id, time, date.event_date)} >
                                                                                        <DeleteIcon fontSize="small"  />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                        </span>
                                                                    )
                                                                )
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">

                                                            {
                                                                (responseIndex === index && subIndex === i) && loading ?
                                                                    <Button
                                                                        variant="outlined"
                                                                        className={loading ? "fullButton" : "signupbutton"}
                                                                        disabled={loading}
                                                                    >
                                                                        Loading...
                                                                    </Button> :
                                                                    <Button
                                                                        variant="outlined"
                                                                        // className={isSlotFull(userResponse, time) ? "fullButton" : "signupbutton"}
                                                                        className={(time.child_ids || []).length ? "fullButton" : "signupbutton"}
                                                                        onClick={(event) => this.handleSignup(event, index, i, {...time, event_date: date.event_date})}
                                                                        // disabled={(readOnly ? true : isSlotFull(userResponse, time) ? true : false)}
                                                                        disabled={(readOnly ? true : (time.child_ids || []).length ? true : false)}
                                                                    >
                                                                        {/*{isSlotFull(userResponse, time) ? "Full" : "Sign up"}*/}
                                                                        {(time.child_ids || []).length ? "Full" : isAdmin ? "Assign" : "Sign up"}
                                                                    </Button>
                                                            }

                                                        </TableCell>
                                                    </TableRow>
                                                )

                                                )}
                                            </TableBody>
                                        </Table>
                                        <Popover
                                            id="render-props-popover"
                                            open={openSignup}
                                            anchorEl={anchorElSignUp}
                                            onClose={this.closeSignup}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "center"
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center"
                                            }}
                                        >
                                            <List>
                                                {(data || []).map((item, index) => (
                                                    <ListItem key={index} button onClick={() => this.sendSignupResponse(item)}>
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                className={classes.avatar}
                                                                src={item.profile_photo || ""}
                                                            >
                                                                {(item.firstName || " ")[0]}{(item.lastName || " ")[0]}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={`${item.firstName}${item.lastName}`} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Popover>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </>
        )
    }
}

PreviewMeeting.propTypes = {
    event: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool
}

PreviewMeeting.defaultProps = {
    readOnly: false,
    currentUser: {},
    eventResponse: { userResponse: [] }
}

export default withRouter(withStyles(styles)(PreviewMeeting));


// function PreviewMeeting(props) {
//   const {
//     classes, event, data, readOnly, setEventResponse, removeChildEventAction,
//     eventResponse: { userResponse = [] }, currentUser
//   } = props;
//
//   const [openSignup, setOpenSignup] = React.useState(false);
//   const [anchorElSignUp, setAnchorElSignUp] = React.useState(null);
//   const [slotId, setSlotId] = React.useState('');
//   const [responseIndex, setResponseIndex] = React.useState('');
//   const [subIndex, setSubIndex] = React.useState('');
//   const [loading, setLoading] = React.useState(false);
//
//   const timer = React.useRef();
//     React.useEffect(() => {
//         return () => {
//             clearTimeout(timer.current);
//         };
//     }, []);
//
//   const handleSignup = (event, index, subIndex, slotId) => (event) => {debugger
//     setSlotId(slotId)
//     setResponseIndex(slotId)
//     setSubIndex(slotId)
//     setAnchorElSignUp(event.currentTarget);
//     setOpenSignup(true)
//   };
//
//   const removeChild = (child, time) => (e) => {
//     const events = event
//     const body = {
//       slotId: time.slotId,
//       child_id: child.childid,
//       eventid: events.event_id,
//     }
//     removeChildEventAction.request(body)
//   }
//
//   function closeSignup() {
//     setOpenSignup(false)
//   };
//
//   function getStripedStyle(index) {
//     return { background: index % 2 ? '#f4f4f4' : 'white' };
//   }
//
//   function sendSignupResponse(value) {
//       const events = event
//       if (!loading) {
//           setLoading(true);
//           setSlotId(slotId)
//           setOpenSignup(true)
//           timer.current = setTimeout(() => {
//               setLoading(false);
//               setSlotId('')
//           }, 6000);
//           if (value) {
//               const body = {
//                   // eventid: events.event_id,
//                   // event_type: events.event_type,
//                   // slotId,
//                   // child_id: value.childid,
//
//                   school_id: getCurrentSchoolId(currentUser),
//                   for_user: "f514dfa6-21cf-3e01-bbf6-b34a35e45b6c",
//                   event_id: "%s",
//                   new_date: "2020-05-20",
//                   new_time: "12:00-14:00",
//                   child_id: 1,
//                   child_id_remove:""
//
//               }
//               setEventResponse.request(body)
//           }
//           setOpenSignup(false)
//       }
//   }
//
//   const getChildList = (child_ids) => {
//       const list = (data || []).filter(x => ((child_ids || []).includes(x.id))) || []
//       return list || []
//   }
//
//   const isParentChecked = (users) => {
//       const isParent = (users || []).some(user => ((user || []).includes(currentUser.id))) || false
//       return isParent || false
//   }
//
//   console.log("=====================", {slotId}, {event}, {props})
//   return (
//     <>
//       {event.parentTeacherResponse.map((date, index) => (
//         <Grid container key={index}>
//           <Grid item xs={10} className={classes.centerContent}>
//             <Grid container spacing={10}>
//               <Grid item xs={12} className="centerdatetime">
//                 <Card className={classes.dateTimeCard}>
//                   <Grid container>
//                     <Grid item xs={4} className={classes.dateTitme}>
//                       <Moment format="ddd D, MMMM YYYY" date={date.event_date} />
//                     </Grid>
//                   </Grid>
//                   <Table className={classes.table} fixedheader="false" style={{ width: "auto", tableLayout: "auto" }}>
//                     <TableHead>
//                       <TableRow>
//                         <TableCell className={classes.customthead}>#</TableCell>
//                         <TableCell align="left" className={classes.customthead}>Spots</TableCell>
//                         <TableCell align="left" className={classes.customthead}>Participant</TableCell>
//                         <TableCell align="left" className={classes.customthead}></TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {date.slotTime.map((time, i) =>
//                         <TableRow key={i} style={getStripedStyle(i)}>
//                           <TableCell component="th" scope="row">
//                             {i}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Moment format="hh:mm a" date={time.start_time} />
//                             -
//                             <Moment format="hh:mm a" date={time.end_time} />
//                           </TableCell>
//                           <TableCell align="left">
//                               {
//                                   (getChildList(time.child_ids || []) || []).map((child, childIndex) =>
//                                       (
//                                           <span key={childIndex}>
//                                           {child.firstName} {child.lastName}
//                                           {isParentChecked((time.users || [])) &&
//                                               <Tooltip title="Delete">
//                                                   <IconButton aria-label="Delete" size="small" onClick={removeChild(child, time)} >
//                                                       <DeleteIcon fontSize="small"  />
//                                                   </IconButton>
//                                               </Tooltip>
//                                           }
//                                     </span>)
//                                   )
//                               }
//                           </TableCell>
//                           <TableCell align="left">
//
//                               {
//                                   (time.slotId === slotId) && loading ?
//                                       <Button
//                                           variant="outlined"
//                                           className={loading ? "fullButton" : "signupbutton"}
//                                           disabled={loading}
//                                       >
//                                           Loading...
//                                       </Button> :
//                                       <Button
//                                           variant="outlined"
//                                           // className={isSlotFull(userResponse, time) ? "fullButton" : "signupbutton"}
//                                           className={(time.child_ids || []).length ? "fullButton" : "signupbutton"}
//                                           onClick={handleSignup(index, i)}
//                                           // disabled={(readOnly ? true : isSlotFull(userResponse, time) ? true : false)}
//                                           disabled={(readOnly ? true : (time.child_ids || []).length ? true : false)}
//                                       >
//                                           {/*{isSlotFull(userResponse, time) ? "Full" : "Sign up"}*/}
//                                           {(time.child_ids || []).length ? "Full" : "Sign up"}
//                                       </Button>
//                               }
//
//                           </TableCell>
//                         </TableRow>
//                       )}
//                     </TableBody>
//                   </Table>
//                   <Popover
//                     id="render-props-popover"
//                     open={openSignup}
//                     anchorEl={anchorElSignUp}
//                     onClose={closeSignup}
//                     anchorOrigin={{
//                       vertical: "top",
//                       horizontal: "center"
//                     }}
//                     transformOrigin={{
//                       vertical: "top",
//                       horizontal: "center"
//                     }}
//                   >
//                     <List>
//                       {data && data.map((item, index) => (
//                         <ListItem key={index} button onClick={() => sendSignupResponse(item)}>
//                           <ListItemAvatar>
//                             <Avatar
//                               className={classes.avatar}
//                               src={item.profile_photo || ""}
//                             >
//                               {(item.firstName || " ")[0]}{(item.lastName || " ")[0]}
//                             </Avatar>
//                           </ListItemAvatar>
//                           <ListItemText primary={`${item.firstName}${item.lastName}`} />
//                         </ListItem>
//                       ))}
//                     </List>
//                   </Popover>
//                 </Card>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       ))}
//     </>
//   )
// }
//
// PreviewMeeting.propTypes = {
//   event: PropTypes.shape({}).isRequired,
//   readOnly: PropTypes.bool
// }
//
// PreviewMeeting.defaultProps = {
//   readOnly: false,
//     currentUser: {},
//   eventResponse: { userResponse: [] }
// }
//
// export default withRouter(withStyles(styles)(PreviewMeeting));