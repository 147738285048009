import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Card, withStyles, Button, Container} from "@material-ui/core";
import Image from "../../assets/images/welcome/360.png"
import * as routes from "../../routes/path";
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import {
    ImageUpload,
    TextLabelInput,
    SelectLabelInput,
    DateLabelInput,
    NumberLabelInput,
    TimeLabelInput,
    openNotificationWithIcon
} from "../../component/SchoolProfile/components/GlobalComponents/GlobalFields"

import InputAdornment from '@material-ui/core/InputAdornment';
import {
    fade,
} from '@material-ui/core/styles';
import {Auth} from "aws-amplify";
import MuiAlert from "@material-ui/lab/Alert";
import AppHeader from "./appheader";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        padding: '5em'
    },
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 1000,

    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 32,
        color: '#6E75FE',
    },
    SigupActionBox: {
        flexDirection: 'column',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 50,
        alignContent: 'center',
        marginBottom: 15,
        width : 600
    },
    textField: {
        paddingBottom : 20,
        width: 600,
        borderWidth : 1,
    },
    button : {
        backgroundColor : '#6E75FE',
        alignItems: 'center',
        paddingLeft : 50,
        paddingRight : 50,
        width : 600,
        color : 'white'
    },
    label : {
        fontWeight: "bold"
    },
    OutlinedInput : {
        borderWidth: '1px',
        borderColor: 'green !important'
    }
});


const SignupCode = ({classes,props}) => {
    const [code, setcode] = useState('')
    const [password, setpassword] = useState('')
    const [password2, setpassword2] = useState('')
    const [email, setemail] = useState('')
    const [error, setError] = useState(false)
    const [errorMessage, seterrorMessage] = useState('')
    let history = useHistory()
    function showError(){
        if (error) {
            return (
                <Alert severity="error">{errorMessage}</Alert>
            )
        }
    }

    useEffect(() => {

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (password2 !== password) {
                return false;
            }
            return true;
        });
        // Specify how to clean up after this effect:
        return function cleanup() {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        };
    });

    const submitClick = () =>{

        Auth.signIn(email, code)
            .then(data => {
                    console.log(data,"signed in")
                    Auth.currentAuthenticatedUser()
                        .then(user => {
                            console.log('Confirmed Logged in ', user)
                            Auth.changePassword(user, code, password)

                        })
                        .then(data => {
                            console.log('WOOHOOOOO')
                            console.log(data)
                            openNotificationWithIcon('success', "Registration Successful ! Please use your registered credentials to login")
                            history.push(routes.SIGN_IN)

                        })
                        .catch(err => {
                            console.log('Errrrroooorrr')
                            console.log(err)
                            //dispatch({type: 'LOGIN_CHANGE_PASSWORD_REJECTED', payload: {message : "Error Setting Password. Please try again"}})

                        });

            })
            .catch(err => {
                console.log(err,"error")
                setError(true)
                seterrorMessage('Invalid Email/Code combination or your code has expired.')
            });



    }

    return (
        <div>
            <AppHeader/>
            <Container className={classes.main}>

            <div className="text-center mt-2">
                <div className={classes.header}>Please enter the Invite Code sent via Email</div>
            </div>
            {showError()}
            <div className={classes.SigupActionBox}>
                <ValidatorForm
                    onSubmit={submitClick}
                    onError={(errors) => {
                        console.log(errors)
                    }}
                >
                    <TextValidator required
                                   label="Email" id="standard-required" className={classes.textField}
                                   fullWidth
                                   onChange={(e) => setemail(e.target.value)}
                                   name="email"
                                   value={email}
                                   InputProps={{
                                       className : classes.OutlinedInput

                                   }}
                                   variant="outlined"
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">

                                           </InputAdornment>
                                       ),
                                   }}
                                   validators={['required', 'isEmail']}
                                   errorMessages={['Email field is required', 'Email is not valid']}
                                   />

                    <TextValidator required
                                   label="Code" id="standard-required" className={classes.textField}
                                   fullWidth
                                   onChange={(e) => setcode(e.target.value)}
                                   name="code"
                                   InputProps={{
                                       className : classes.OutlinedInput

                                   }}
                                   variant="outlined"
                                   value={code}
                                   InputProps={{
                                       startAdornment: (
                                           <InputAdornment position="start">

                                           </InputAdornment>
                                       ),
                                   }}
                                   validators={['required', ]}
                                   errorMessages={['The Code is required', 'The Code is required']}
                    />


                    <TextValidator
                        label="Password"
                        className={classes.textField}
                        fullWidth
                        onChange={(e) => setpassword(e.target.value)}
                        InputProps={{
                            className : classes.OutlinedInput,
                            startAdornment: (
                                <InputAdornment position="start">

                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        name="Password"
                        type="Password"
                        validators={['required']}
                        errorMessages={['This field is required']}
                        value={password}
                    />
                    <TextValidator
                        label="Confirm Password"
                        className={classes.textField}
                        fullWidth
                        onChange={(e) => setpassword2(e.target.value)}
                        InputProps={{
                            className : classes.OutlinedInput,
                            startAdornment: (
                                <InputAdornment position="start">

                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        name="repeatPassword"
                        type="password"
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={['password mismatch', 'This field is required']}
                        value={password2}
                    />
                <Button
                    variant="contained"
                    type={'submit'}
                    className={classes.button}

                >
                    Send
                </Button>
                </ValidatorForm>
            </div>
        </Container>
        </div>
    );

}


export default withStyles(styles)(SignupCode);