import React from 'react';
import moment from "moment";
import {Storage} from "aws-amplify";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Button, Input, List, message, Spin} from 'antd';
import {Container, Grid} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';
import {countryOptionList, primaryInfo, staffRoleList, teacherLevelList} from "../../../GlobalComponents/optionValues";
import {
    capitalizeFirstLetter,
    DateLabelInput,
    getUserRole,
    getSchoolId,
    ImageUpload,
    NumberLabelInput,
    openNotificationWithIcon,
    SelectLabelInput,
    TextLabelInput, SelectUserRoleInput
} from "../../../GlobalComponents/GlobalFields";
import * as routes from "../../../../../../routes/path";
import { createUser } from "../../../../ApiServices";
import "../../../school.css"

const { TextArea } = Input;

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class CreateStaff extends React.Component {
    state = {
        isSaving: false,
        activeTab: 1,
        staffFiled: {
            firstName: '',
            lastName: '',
            profile_photo: '',
            hours: '',
            role: [],
            notes: '',
            email: '',
            mobile: '',
            teacherLevel: '',
            groups: [],
            homeRoom: '',
            certifications: [],
            dateOfBirth: null,
            hireDate: null
        },
        staffErrors: {},
        isImgUploadStudent: false,
        certificationsList: [],
        isImgUpload: "",
        primaryAddress: {
            address1: '', address2: '', address3: '', city: '',
            state: '', postalCode: '',  country: ''
        },
        primaryAddressErrors: {}
    };

    handelOnChange = (e) =>{
        const {staffFiled} = this.state
        this.setState({
            staffFiled: {
                ...staffFiled,
                [e.target.name]: e.target.value
            }
        })
    }

    onChangePrimaryAddress = (e) =>{
        const {primaryAddress} = this.state
        this.setState({
            primaryAddress: {
                ...primaryAddress,
                [e.target.name]: e.target.value
            }
        })
    }

    selectPrimaryAddress = (val, key) =>{
        const { primaryAddress } = this.state
        let select = {}
        if(key === 'userInfo'){
            const addressInfo = primaryInfo.find(p => p.name === val)
            select = {
                primaryAddress:{
                    ...addressInfo
                },
                [key]: val
            }
        }else if(key === "primary"){
            select = {
                primaryAddress: {
                    ...primaryAddress,
                    [key]: val
                }
            }
        }
        this.setState({...select})
    }

    handelNumber = (key, val) =>{
        const {staffFiled} = this.state
        this.setState({
            staffFiled: {
                ...staffFiled,
                [key]: val
            }
        })
    }

    validateStaff = (name, value) => {
        switch (name) {
            case "firstName":
                if (!value) {
                    return "First name is Required";
                } else {
                    return "";
                }
            case "lastName":
                if (!value) {
                    return "Last name is Required";
                } else {
                    return "";
                }
            // case "dateOfBirth":
            //     if (!value) {
            //         return "Date Of Birth is Required";
            //     } else {
            //         return "";
            //     }
            case "role":
                if (!(value || []).length) {
                    return "Role is Required";
                } else {
                    return "";
                }
            case "homeRoom":
                if (!value) {
                    return "Home room is Required";
                } else {
                    return "";
                }
            // case "certificationsList":
            //     if (!value.length) {
            //         return "At least 1 Certificate is Required";
            //     } else {
            //         return "";
            //     }
            case "teacherLevel":
                if (!value) {
                    return "Teacher level is Required";
                } else {
                    return "";
                }
            // case "hireDate":
            //     if (!value) {
            //         return "Hire Date is Required";
            //     } else {
            //         return "";
            //     }
            // case "hours":
            //     if (!value) {
            //         return "Hours is Required";
            //     } else {
            //         return "";
            //     }
            // case "address1":
            //     if (!value) {
            //         return "Address1 is Required";
            //     } else {
            //         return "";
            //     }
            // case "city":
            //     if (!value) {
            //         return "City is Required";
            //     } else {
            //         return "";
            //     }
            // case "state":
            //     if (!value) {
            //         return "State is Required";
            //     } else {
            //         return "";
            //     }
            // case "postalCode":
            //     if (!value) {
            //         return "Postal Code is Required";
            //     } else {
            //         return "";
            //     }
            case "email":
                if (!value) {
                    return "Email is Required";
                } else if (
                    !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                ) {
                    return "Enter a valid email address";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    };

    handelReview = async () =>{
        const {staffFiled, primaryAddress, certificationsList} = this.state

        let staffFiledErrors = {};
        let primaryAddErrors = {};
        staffFiled.certificationList = certificationsList
        Object.keys(staffFiled).forEach(name => {
            if((staffFiled.role || []).includes("admin") && name === "teacherLevel") return
            const error = this.validateStaff(name, staffFiled[name]);
            if (error && error.length > 0) {
                staffFiledErrors[name] = error;
            }
        });

        Object.keys(primaryAddress).forEach(name => {
            const error = this.validateStaff(name, primaryAddress[name]);
            if (error && error.length > 0) {
                primaryAddErrors[name] = error;
            }
        });

        this.setState({
            primaryAddressErrors: primaryAddErrors,
            staffErrors: staffFiledErrors
        });

        if((Object.keys(staffFiledErrors).length > 0) || (Object.keys(primaryAddErrors).length > 0)){
            return openNotificationWithIcon('error', 'Fields required!')
        }

        this.setState({isSaving: true})

        const { firstName, lastName, profile_photo, hours, role, notes, email, mobile, teacherLevel, homeRoom, groups, dateOfBirth, hireDate, certificationList } = staffFiled

        let group_ids = []
        if((groups || []).length) {
            if((groups || []).includes(homeRoom)) {
                group_ids = groups
            } else {
                group_ids = (groups || []).concat([homeRoom])
            }
        } else {
            group_ids = [homeRoom]
        }

        const payload = {
            role: {[getSchoolId()]: getUserRole(role)},
            // school_ids: [getSchoolId()],
            child_ids: [],
            group_ids,
            username: email,
            attributes: {
                firstName, lastName, profile_photo, hours, notes, email, mobile, teacherLevel, groups, homeRoom, dateOfBirth, hireDate, certificationList, primaryAddress
            }
        }

        const response = await createUser(payload)
        console.log({payload, response})
        if(response && response.status === "success") {
            openNotificationWithIcon('success', response.message)
            setTimeout(() => {
                this.setState({isSaving: false})
                this.props.history.push(routes.STAFF_DASHBOARD)
            }, 1000)
        } else {
            this.setState({isSaving: false})
            const message = response.message === "User already exists in system." ?
                "Email already exists in System for another user. Please use different email" : response.message
            openNotificationWithIcon('error', message || 'Something went wrong')
        }

    }

    handelSelect = (key, val) =>{
        const { staffFiled } = this.state
        this.setState({
            staffFiled: {
                ...staffFiled,
                [key]: val
            }
        })
    }

    handelOnDateChange = (key, date) =>{
        const {staffFiled} = this.state
        this.setState({
            staffFiled: {
                ...staffFiled,
                [key]: date
            }
        })
    }

    handelMultiSelect = (key, val) => {
        const {staffFiled} = this.state
        this.setState({
            staffFiled: {
                ...staffFiled,
                [key]: val
            }
        })
    }

    uploadFile = async (file) => {
        let key = '';
        const name = `${moment ().format ('x')}`;
        await Storage.put (name, file, {
            contentType: file.type,
        }).then (
            resp => {
                key = resp.key;
            },
            err => {
                console.log (err);
            }
        );
        return key;
    }

    getFileUrl = async (key) => {
        let url = '';
        url = await Storage.get (key);
        const URL = url.split("?")[0]
        return (URL || url);
    }

    onImageUplaod = async (info) =>{
        const {staffFiled} = this.state
        if (info.file.status === 'uploading') {
            this.setState({
                isImgUpload: true
            })
            return;
        }

        if (info.file.status === 'done') {
            let key = await this.uploadFile (info.file.originFileObj);
            let imageurl = await this.getFileUrl (key);
            this.setState({
                staffFiled: {
                    ...staffFiled,
                    profile_photo: imageurl
                },
                isImgUpload: false
            })
        }
    }

    addCertification = () => {
        const {certificationsList, staffFiled} = this.state
        if(staffFiled && staffFiled.certifications.length){
            certificationsList.push(staffFiled.certifications)
            this.setState({
                certificationsList,
                staffFiled:{
                    ...staffFiled,
                    certifications: ""
                }
            });
        }
    };

    deleteCertification = i => {
        const {certificationsList} = this.state
        certificationsList.splice(i,1)
        this.setState({
            certificationsList,
        });
    };

    render() {
        const { classes, currentUser: { newUserInfo: { groupcache: groupList = {} } } } = this.props;
        const { staffFiled, staffErrors, isImgUpload, certificationsList, primaryAddress, primaryAddressErrors, isSaving } = this.state;
        return (
            <div className={`student ${classes.root}`}>
                <div>
                    <div className="text-center mb-40">
                        <h3><b>Add Staff</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 mt-100 shadow">
                        <div style={{marginTop: "-120px", textAlign: 'center'}}>
                            <ImageUpload
                                isImgUpload={isImgUpload}
                                imageUrl={staffFiled.profile_photo}
                                handleImage={(info)=> this.onImageUplaod(info)}
                            />
                        </div>

                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="firstName"
                                    value={staffFiled.firstName}
                                    onChange={this.handelOnChange}
                                    placeholder="First Name"
                                    label="First Name"
                                    required
                                    error={staffErrors && staffErrors.firstName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="lastName"
                                    value={staffFiled.lastName}
                                    onChange={this.handelOnChange}
                                    placeholder="Last Name"
                                    label="Last Name"
                                    required
                                    error={staffErrors && staffErrors.lastName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="email"
                                    value={staffFiled.email}
                                    onChange={this.handelOnChange}
                                    placeholder="Email"
                                    label="Email"
                                    required
                                    error={staffErrors && staffErrors.email}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Home Room"
                                    name="homeRoom"
                                    onChange={(value)=> this.handelOnChange({target: { name: "homeRoom", value }})}
                                    placeholder="Select a home room"
                                    options={groupList || {}}
                                    required
                                    value={staffFiled.homeRoom}
                                    error={staffErrors && staffErrors.homeRoom}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectUserRoleInput
                                    label="Role/Access"
                                    name="role"
                                    onChange={(value) => this.handelSelect("role", value)}
                                    placeholder="Select a role"
                                    value={staffFiled && staffFiled.role}
                                    required
                                    error={staffErrors && staffErrors.role}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Teacher Level"
                                    name="teacherLevel"
                                    onChange={(val)=> this.handelSelect("teacherLevel", (val || "").toLowerCase())}
                                    placeholder="Select a Teacher Level"
                                    required
                                    options={teacherLevelList || []}
                                    value={capitalizeFirstLetter(staffFiled && staffFiled.teacherLevel) }
                                    error={staffErrors && staffErrors.teacherLevel}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <NumberLabelInput
                                    className="w-100"
                                    placeholder="Mobile"
                                    name="mobile"
                                    value={staffFiled.mobile}
                                    onChange={(value)=> this.handelOnChange({target: {name: "mobile", value}})}
                                    label="Mobile"
                                    error={staffErrors && staffErrors.mobile}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DateLabelInput
                                    label="Date Of Birth"
                                    name="dateOfBirth"
                                    onChange={(date, dateString)=> this.handelOnDateChange("dateOfBirth", dateString)}
                                    value={staffFiled && staffFiled.dateOfBirth ? moment(staffFiled.dateOfBirth) : null}
                                    placeholder="Date Of Birth"
                                    // required
                                    error={staffErrors && staffErrors.dateOfBirth}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Group"
                                    mode="multiple"
                                    name="groups"
                                    // required
                                    onChange={(val) => this.handelMultiSelect("groups", val)}
                                    placeholder="Group"
                                    options={groupList || {}}
                                    value={staffFiled && staffFiled.groups}
                                    error={staffErrors && staffErrors.groups}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Primary Address</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        {/*<SelectLabelInput
                            label="Select"
                            name="userInfo"
                            onChange={(val)=>this.selectPrimaryAddress(val, "userInfo")}
                            placeholder="Select"
                            options={primaryInfo.map(p => p.name) || []}
                            value={userInfo}
                        />*/}
                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="address1"
                                    value={primaryAddress.address1}
                                    onChange={this.onChangePrimaryAddress}
                                    placeholder="Address1"
                                    label="Address 1"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.address1}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="address2"
                                    value={primaryAddress.address2}
                                    onChange={this.onChangePrimaryAddress}
                                    placeholder="Address2"
                                    label="Address 2"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="address3"
                                    value={primaryAddress.address3}
                                    onChange={this.onChangePrimaryAddress}
                                    placeholder="Address3"
                                    label="Address 3"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="city"
                                    value={primaryAddress.city}
                                    onChange={this.onChangePrimaryAddress}
                                    placeholder="City"
                                    label="City"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.city}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="state"
                                    value={primaryAddress.state}
                                    onChange={this.onChangePrimaryAddress}
                                    placeholder="State"
                                    label="State"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.state}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="postalCode"
                                    value={primaryAddress.postalCode}
                                    onChange={this.onChangePrimaryAddress}
                                    placeholder="Postal Code"
                                    label="Postal Code"
                                    // required
                                    error={primaryAddressErrors && primaryAddressErrors.postalCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectLabelInput
                                    label="Country"
                                    name="country"
                                    onChange={(val)=> this.setState({primaryAddress: {...this.state.primaryAddress, country: val}})}
                                    placeholder="Select a country"
                                    options={countryOptionList || []}
                                    value={primaryAddress && primaryAddress.country}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Notes</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={12}>
                                <TextArea
                                    value={staffFiled.notes}
                                    name="notes"
                                    placeholder="notes"
                                    onChange={this.handelOnChange}
                                    rows={4}/>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Official Details</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 shadow">
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <TextLabelInput
                                    name="certifications"
                                    value={staffFiled.certifications}
                                    onChange={this.handelOnChange}
                                    placeholder='Add Certification'
                                    label='Certifications'
                                    // required
                                    error={staffErrors.certificationsList}
                                    addonAfter={<span onClick={this.addCertification}>+</span>}
                                />
                                <br/>
                                {
                                    certificationsList.length > 0 &&
                                    <List
                                        size="large"
                                        header={<b>Certifications List</b>}
                                        bordered
                                        dataSource={certificationsList}
                                        renderItem={(item, index) => (
                                        <List.Item actions={[<CancelIcon color="primary" onClick={()=>this.deleteCertification(index)}/>]}>
                                            <List.Item.Meta title={item}/>
                                        </List.Item>
                                        )}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <DateLabelInput
                                    label="Hire Date"
                                    name="hireDate"
                                    onChange={(date, dateString)=> this.handelOnDateChange("hireDate", dateString)}
                                    placeholder="Hire Date"
                                    // required
                                    value={staffFiled && staffFiled.hireDate ? moment(staffFiled.hireDate) : null}
                                    error={staffErrors && staffErrors.hireDate}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <NumberLabelInput
                                    name="hours"
                                    value={staffFiled.hours}
                                    onChange={(val)=>this.handelNumber("hours", val)}
                                    placeholder="Hours"
                                    label="Professional Development Hours"
                                    // required
                                    addonAfter="Hrs"
                                    error={staffErrors && staffErrors.hours}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div className="mt-20">
                    <Container>
                        <div className="p-card-title text-center">
                            <Button size="large" onClick={() => this.props.history.push(routes.STAFF_DASHBOARD)}>Cancel</Button>&nbsp;
                            <Button
                                size="large"
                                className="review-button"
                                onClick={this.handelReview}
                                loading={isSaving}
                            >
                                Save
                            </Button>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

CreateStaff.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps,null)(withStyles(styles)(CreateStaff))