import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cloneDeep from "lodash/cloneDeep";
import {Button, Select, List, Avatar, Icon, Tooltip, Menu, Dropdown, Tag, Spin, Checkbox, Modal} from 'antd';
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import {
    TextLabelInput,
    getColor,
    getSchoolId,
    openNotificationWithIcon,
    getUserDetailsByUserId,
    SelectUserRoleInput,
    showGroups
} from "../GlobalComponents/GlobalFields";
import "../school.css"
import "./staff.css"
import {CSVLink} from "react-csv";
import authService from "../../../../service/auth";
import {newUserInfoSuccess} from "../../../../redux/actions/current-user";
import * as routes from "../../../../routes/path";
import {deleteUser, inviteUser} from "../../ApiServices";

const { Option } = Select;

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});


class StaffDashboard extends React.Component {

    state = {
        staffList: [],
        selected: [],
        selectedGroup: "all",
        searchKeys: "",
        selectedRole: "",
        orderBy: "",
        isActive: "quick",
        isLoading: false,
        isInviting: false
    }

    componentWillMount(){
        this.fetchStaffsList()
    }

    fetchStaffsList = async () => {
        this.setState({isLoading: true})
        const newUserResponse = await authService.getNewUserDetail();
        if(newUserResponse && newUserResponse.data) {
            this.props.onSetUserInfo(newUserResponse.data)
        }
        const { currentUser: { usersList } } = this.props || {};
        let staffList = []
        usersList.forEach(user => {
            const isTeacher = ((((user || {}).role) || {})[getSchoolId()] || {}).teacher
            const isAdmin = ((((user || {}).role) || {})[getSchoolId()] || {}).admin
            if(isTeacher || isAdmin) {
                const roles = this.getRoles(user.role)
                staffList.push({...user, roles})
            }
        })
        this.setState({
            staffList,
            isLoading: false
        })
    }

    getAllStaffList = () => {

        const {staffList, searchKeys, selectedGroup, orderBy, selectedRole} = this.state
        let dataItem = []

        if(!searchKeys && !selectedRole && selectedGroup === 'all'){
            dataItem = cloneDeep(staffList)
        } else {
            let duplicateData = cloneDeep(staffList)
            dataItem = (duplicateData || []).filter(p => {
                const { firstName = "", lastName = "", email_id = "" } = p || {}
                return((firstName || "").toString().toLowerCase().includes(searchKeys.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(searchKeys.toLowerCase()) || (email_id || "").toString().toLowerCase().includes(searchKeys.toLowerCase()))
            })
            if(selectedGroup !== 'all') {
                dataItem = (dataItem || []).filter(child => (child.group_ids || []).some(group => selectedGroup === group));
            }
            if(selectedRole) {
                dataItem = (dataItem || []).filter(child => (child.roles || []).some(role => selectedRole === role));
            }
        }

        if(orderBy) {
            dataItem = (dataItem || []).sort((a, b) => (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0))
        }

        return dataItem
    }

    addStaffMenu = () => {
        const { isActive  } = this.state
        return(
            <Menu className="add-student-menu">
                <div>
                    <div className="header-menu">

                        <Grid container spacing={6} className="grid-menu">
                            <Grid
                                item xs={6}
                                className={isActive === "quick" ? "active" : "inactive"}
                                onClick={() => this.setState({isActive: 'quick'})}
                            >
                                Add Multiple Staffs
                            </Grid>
                            <Grid
                                item xs={6}
                                className={isActive === "detail" ? "active" : "inactive"}
                                onClick={() => this.setState({isActive: 'detail'})}
                            >
                                Add Staff
                            </Grid>
                        </Grid>

                    </div>

                    {
                        isActive === 'quick' ?
                            <div className="student-button">
                                <Button type="primary" onClick={() => this.props.history.push(routes.ADD_STAFF)}>Add Multiple Staffs</Button>
                            </div> :
                            <div className="student-button">
                                <Button type="primary" onClick={() => this.props.history.push('/admin/staff/edit/new')}>Add Staff with Details</Button>
                            </div>
                    }

                </div>
            </Menu>
        )
    }

    onDownloadList = () => {
        const { currentUser: { groupcache: groupList = [] } } = this.props;
        const array = this.getAllStaffList()
        let list = []
        array.forEach(staff => {
            const { firstName, lastName, group_ids, teacherLevel, mobile, email_id, roles } = staff || {}
            let groups = []
            {Array.isArray(group_ids) && (group_ids || []).map((group, ind) => {
                if(groupList[group] === undefined){ return  }
                groups.push(groupList[group].attributes.groupname || "")
            })}

            list.push({
                firstName,
                lastName,
                mobile: mobile || "-",
                email: email_id || "-",
                groups,
                role: roles,
                teacherLevel: teacherLevel || "-"
            })
        })
        return list
    }

    onInviteUser = async (record) => {
        let { staffList } = this.state
        const childIndex = (staffList || []).findIndex(x => x.id === record.id)

        if(childIndex !== -1) {
            staffList[childIndex].isInviting = true
        }
        this.setState({
            staffList
        })

        if(record.id) {
            const response = await getUserDetailsByUserId(record.id)

            if(response && Object.keys(response).length) {
                const payload = {
                    school_id: getSchoolId(),
                    username: response.attributes.email || ""
                }

                const invite = await inviteUser(payload)
                if(invite && invite.status === "success") {
                    openNotificationWithIcon('success', 'invitation sent successfully')
                    staffList[childIndex].isInviting = false
                    this.setState({
                        staffList
                    })
                    this.fetchStaffsList()
                } else {
                    openNotificationWithIcon('error', invite.message || 'something went wrong')
                    staffList[childIndex].isInviting = false
                    this.setState({
                        staffList
                    })
                }
            }
        }
    }

    onSelect = (id ) => {
        let { selected } = this.state
        if((selected || []).includes(id)) {
            selected = (selected || []).filter(x => x !== id)
        } else {
            selected.push(id)
        }
        this.setState({
            selected
        })
    }

    onDeleteStaffs = () => {
        const { selected } = this.state
        Modal.confirm({
            title: `Are you sure you want to delete ${(selected || []).length} users?`,
            onOk: async () => {

                let isDeleted = 0
                for (let i in selected) {
                    const payload = {
                        school_id: getSchoolId(),
                        user_id: selected[i]
                    }
                    const response = await deleteUser(payload)
                    if(response && response.status === "success") {
                        isDeleted++
                    } else {
                        openNotificationWithIcon('error', response.message || 'Something went wrong')
                    }
                }

                if(isDeleted) {
                    openNotificationWithIcon('success', "users deleted successfully")
                    this.setState({
                        selected: []
                    })
                    this.fetchStaffsList()
                } else {
                    openNotificationWithIcon('error', 'Something went wrong')
                }
            },
            onCancel() {},
        });
    }

    onDeleteStaff = (id) => {
        Modal.confirm({
            title: `Are you sure you want to delete this user?`,
            onOk: async () => {
                const payload = {
                    school_id: getSchoolId(),
                    user_id: id
                }
                const response = await deleteUser(payload)
                if(response && response.status === "success") {
                    openNotificationWithIcon('success', response.message || "user deleted successfully")
                    this.setState({
                        selected: []
                    })
                    this.fetchStaffsList()
                } else {
                    openNotificationWithIcon('error', response.message || 'Something went wrong')
                }
            },
            onCancel() {},
        });
    }

    onInviteMultipleUsers = () => {
        const { selected } = this.state
        const staffList = this.getAllStaffList()
        let payload = []

        for (let i in selected) {
            const index = (staffList || []).findIndex(x => x.id === selected[i])
            if(index !== -1) {
                if(!(staffList[index] || {}).invited && (staffList[index] || {}).email_id) {
                    payload.push({
                        school_id: getSchoolId(),
                        username: staffList[index].email_id || ""
                    })
                }
            }
        }
        console.log({payload})

        Modal.confirm({
            title: `Are you sure you want to invite ${(payload || []).length} users?`,
            onOk: async () => {

                this.setState({
                    isInviting: true
                })

                let isInvite = 0
                let isFail = 0
                for (let i in payload) {
                    const response = await inviteUser(payload[i])
                    if(response && response.status === "success") {
                        isInvite++
                    } else {
                        isFail++
                        openNotificationWithIcon('error', response.message || 'Something went wrong')
                    }
                }

                if(isInvite) {
                    openNotificationWithIcon('success', `${isInvite} users invited successfully`)
                    this.setState({
                        selected: [],
                        isInviting: false
                    })
                    this.fetchStaffsList()
                } else {
                    openNotificationWithIcon('error', 'Something went wrong')
                    this.setState({
                        isInviting: false
                    })
                }
            },
            onCancel() {},
        });
    }

    onAllSelect = (e) => {
        let selected = []
        if(e.target.checked) {
            selected = (this.getAllStaffList() || []).map(x => x.id)
        } else {
            selected = []
        }
        this.setState({
            selected
        })
    }

    getRoles = (role) => {
        let roleArray = []
        const object = (role || {})[getSchoolId()] || {}
        Object.keys(object || {}).forEach(x => {
            if(role[getSchoolId()][x]) {
                roleArray.push(x)
            }
        })

        return roleArray
    }

    render() {
        const { classes, currentUser: { groupcache: groupList = [] } } = this.props;
        const {searchKeys, isLoading, orderBy, selected, selectedRole} = this.state

        const headers = [
            { label: "Staff First Name", key: "firstName" },
            { label: "Staff Last Name", key: "lastName" },
            { label: "Staff Email", key: "email" },
            { label: "Staff Phone Number", key: "mobile" },
            { label: "Staff Groups", key: "groups" },
            { label: "Staff Role", key: "role" },
            { label: "Staff Teacher", key: "teacherLevel" }
        ];

        return (
            <div className="staff-dashboard">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <div className="back">
                                <Tooltip title={"Back To Admin Dashboard"} placement="bottomLeft">
                                    <Icon
                                        type="left"
                                        className="color-white cursor-pointer"
                                        onClick={() => this.props.history.push(routes.MAIN_DASHBOARD)}
                                    />
                                </Tooltip>
                            </div>
                            <h3 className="mb-0 color-white">Staff List</h3>
                        </div>
                        <div>
                            <Dropdown overlay={this.addStaffMenu} trigger={['click']} placement="bottomLeft" overlayClassName="footer-menu">
                                <Button size="large">Add Staff</Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={`student ${classes.root} content`}>
                    <div>
                        <div className="p-0">
                            <div className="flex pb-3">
                                <Select
                                    // size={'large'}
                                    placeholder={'Select Class'}
                                    style={{width: 225}}
                                    className={'mr-4'}
                                    onChange={(value) => this.setState({selectedGroup: value})}
                                >
                                    <Option value={'all'}>All Classes</Option>
                                    {
                                        groupList && Object.keys(groupList).map((key, index) => {
                                            const name = (groupList[key] && groupList[key].attributes && groupList[key].attributes.groupname) || ""
                                            return(
                                                <Option key={index.toString()} value={groupList[key].id}>{name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                                <TextLabelInput
                                    placeholder="Search by Name/Email"
                                    prefix={<Icon type="search" />}
                                    className="mr-4"
                                    style={{width: 225}}
                                    value={searchKeys}
                                    onChange={(e) => this.setState({searchKeys: e.target.value})}
                                />

                                <SelectUserRoleInput
                                    className="mr-4"
                                    name="role"
                                    mode="default"
                                    onChange={(value) => this.setState({selectedRole: value || ""})}
                                    allowClear={true}
                                    placeholder="Select role"
                                    value={selectedRole || []}
                                    style={{width: 225}}
                                />

                                <CSVLink data={this.onDownloadList() || []} headers={headers} className="mt-1 mr-12" filename={"Staff.csv"}>
                                    <Icon type="download" style={{fontSize: 30, color: '#3cc89c'}}/>
                                </CSVLink>

                                {
                                    (selected || []).length ?
                                        <div className="mt-1 mr-12" >
                                            <Icon type="delete" style={{ fontSize: '25px', color: '#cc2224' }} onClick={this.onDeleteStaffs}/>
                                        </div> : null
                                }

                                {
                                    (selected || []).length ?
                                        <div className="mt-1 mr-12 green-button" >
                                            <Button shape="round" className="mr-3" onClick={this.onInviteMultipleUsers}>
                                                <span style={{color: '#3cc89c'}}>Invite Users</span>
                                            </Button>
                                        </div> : null
                                }

                            </div>
                        </div>

                        <div className="flex justify-between">
                            <div className="flex" style={{alignItems: 'center', marginBottom: 10}}>
                                <div style={{marginRight: 15, fontSize: 15}}>Order by:</div>
                                <div className="cursor-pointer" onClick={() => this.setState({orderBy: 'firstName'})} style={orderBy === 'firstName' ? {fontWeight: 600}: {}}>First Name</div>
                                <div className="cursor-pointer" style={orderBy === 'lastName' ? {fontWeight: 600, borderLeft: '1px solid', marginLeft: 10, paddingLeft: 10} : {borderLeft: '1px solid', marginLeft: 10, paddingLeft: 10}} onClick={() => this.setState({orderBy: 'lastName'})}>Last Name</div>
                            </div>
                            <div>
                                {(this.getAllStaffList() || []).length} Staffs Total
                            </div>
                        </div>

                        { (selected || []).length ? <div style={{fontSize: 15}}>{(selected || []).length} Selected</div> : null }
                        <Grid className="rounded-1rem bg-white shadow" style={{padding: 20}}>
                            <div className="student-table-header" style={{background: '#ebeff4', padding: '0px 10px'}}>
                                <List.Item>
                                    <div style={{width: 50}}>
                                        <Checkbox
                                            checked={(selected || []).length && (selected || []).length === (this.getAllStaffList() || []).length}
                                            onChange={this.onAllSelect}
                                        />
                                    </div>
                                    <List.Item.Meta title={"Staff"}/>
                                    <List.Item.Meta title={"Role/Teacher Level"}/>
                                    <List.Item.Meta title={"User Registration"}/>
                                    <List.Item.Meta title={"Groups"}/>
                                    <div style={{width: 50}}>{" "}</div>
                                </List.Item>
                            </div>
                            <Grid item xs={12}>
                                <div /* className="staff-dashboard-infinite-container" */ >
                                    <List
                                        className="demo-loadmore-list"
                                        itemLayout="horizontal"
                                        loading={isLoading}
                                        dataSource={this.getAllStaffList()}
                                        renderItem={(item, index) => {
                                            const { profile_photo = "", profileImage = "", firstName, lastName, email, group_ids = [], roles = {}, teacherLevel = "", invited = false, active = false } = item || {}
                                            return(
                                                <List.Item>
                                                    <div style={{width: 50, paddingLeft: 15}}>
                                                        <Checkbox checked={(selected || []).includes(item.id)} onChange={() => this.onSelect(item.id)} />
                                                    </div>
                                                    <List.Item.Meta
                                                        avatar={
                                                            (profile_photo || profileImage) ?
                                                                <Avatar src={profile_photo || profileImage} /> :
                                                                <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                    {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                                </Avatar>
                                                        }
                                                        title={
                                                            <div className="mt-3 fs-16 cursor-pointer" onClick={() => this.props.history.push(`/admin/staff/view/${item.id}`)}>{`${firstName} ${lastName}`}</div>
                                                        }
                                                        description={email}
                                                    />
                                                    <List.Item.Meta
                                                        title={
                                                            <div>
                                                                <span>{ (roles || []).map((x, index) => `${(x || "").charAt(0).toUpperCase() + (x || "").slice(1)}${(roles || []).length - 1 === index ? "" : ", "}`) }</span> /
                                                                <span>{ (teacherLevel || "") ? (teacherLevel || "").charAt(0).toUpperCase() + (teacherLevel || "").slice(1) : "-" }</span>
                                                            </div>
                                                        }
                                                    />
                                                    <List.Item.Meta
                                                        title={
                                                            <div key={index.toString()} style={index === 0 ? {marginBottom: 10} : {}}>
                                                                {
                                                                    item.isInviting === true ?
                                                                        <Spin/> :
                                                                        <>
                                                                            {
                                                                                invited && active ?
                                                                                    <span>Active</span> :
                                                                                    invited ?
                                                                                        <>
                                                                                            <div className="color-green">Invitation Sent</div>
                                                                                            <Button size='small' onClick={() => this.onInviteUser(item, index)}>Resend Invite</Button>
                                                                                        </> :
                                                                                        <Button size='small' onClick={() => this.onInviteUser(item, index)}>Invite</Button>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        }
                                                    />
                                                    <List.Item.Meta
                                                        title={showGroups(group_ids || [], groupList || [])}
                                                    />
                                                    <div style={{width: 50, paddingRight: 15}}>
                                                        <Icon type="delete" style={{ fontSize: '25px', color: '#cc2224' }} onClick={() => this.onDeleteStaff(item.id)} />
                                                    </div>
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

StaffDashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(StaffDashboard))