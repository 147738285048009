import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

class CheckboxList extends React.Component {
    state = {
        checked: [],
    };

    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked,
        });
        
        const newList = this.props.list.filter((i,j) => j === newChecked.filter(k => k === j)[0])
        this.props.passRefUpward(newList)
    };

    render() {
        const { classes, list } = this.props;
        return (
            <List className={classes.root}>
                {list.map((item, i) => (
                    <ListItem key={i} role={undefined} dense button onClick={this.handleToggle(i)}>
                        <Checkbox
                            checked={this.state.checked.indexOf(i) !== -1}
                            tabIndex={-1}
                            disableRipple
                        />
                        <ListItemAvatar>
                            <Avatar
                                alt={item.firstname}
                                src={item.pictureurl}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={`${item.firstname} ${item.lastname}`} />
                    </ListItem>
                ))}
            </List>
        );
    }
}

CheckboxList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxList);
