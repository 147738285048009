import React, { Fragment } from 'react';
import LinesEllipsis from 'react-lines-ellipsis'

class TextEllipses extends React.Component{
    state = {
        useEllipsis: true,
    }

    onTextClick = (e) => {
        e.preventDefault();
        this.setState({ useEllipsis: false })
    }

    renderTextWithLinks = (text) => {
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        // Replace URLs with clickable links
        const textWithLinks = text.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });

        // Create a React element with dangerouslySetInnerHTML to render the HTML
        return <div dangerouslySetInnerHTML={{ __html: textWithLinks }} />;
    };
    render() {
        const { useEllipsis } = this.state;
        const { text } = this.props;

        return (
            <Fragment>
                {useEllipsis ? (
                    <div>
                        {text && text.length < 250 ? (
                            this.renderTextWithLinks(text)
                        ) : (
                            <div>
                                {this.renderTextWithLinks(text.substring(0, 250))}
                                <a onClick={this.onTextClick} style={{ color: 'blue' }}>
                                    ...more
                                </a>
                            </div>
                        )}
                    </div>
                ) : (
                    this.renderTextWithLinks(text)
                )}
            </Fragment>
        );
    }
    render2() {
        const { useEllipsis } = this.state;
        const {text} = this.props;
        return(
            <Fragment>
                { useEllipsis
                ? (
                    <div>
                        { (text || " ").length < 250 ? (text || " ").split("\n").map(m => m ? <div>{m}</div> : <br/>) :
                           <div>
                               {(text || " ").substring(0, 250).split("\n").map(m => m ? <div>{m}</div> : <br/>) }
                               <a onClick={() => this.setState({useEllipsis: false})} style={{color: "blue"}}>...more</a>
                           </div>
                        }
                    </div>
                ) : (text || " ").split("\n").map(m => m ? <div>{m}</div> : <br/>)}
            </Fragment>
        )
    }
}

export default TextEllipses;