import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    List, Grid
} from '@material-ui/core';
// import classNames from 'classnames';
import CreateComment from './CreateComment';
import grey from '@material-ui/core/colors/grey';
import CommentCard from './CommentCard';

const propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    commentTextGrid: {
        backgroundColor: grey[100],
        borderRadius: '5px',
        padding: '5px',
        width: '90%'
    },
    padding0: {
        padding: 0,
    }

});

class CommentBox extends React.Component {

    sendComment = (text) => {
        const query = {
            postid: this.props.postid,
            comment: text,
            groupId: this.props.groupId || ""
        }
        this.props.saveCommentAction.request(query);
    }

    render() {
        const { comments, currentUser, updateCommentAction, deleteCommentAction, getUserInfo, postid } = this.props;
        return (
            <Fragment>
                <Grid
                    container
                    direction="column"
                    elevation={0}>

                    {comments.length >= 0 && (
                        <List>
                            {comments.map((comment) => (
                                <CommentCard
                                    key={comment.id}
                                    comment={comment}
                                    updateCommentAction={updateCommentAction}
                                    deleteCommentAction={deleteCommentAction}
                                    currentUser={currentUser}
                                    getUserInfo={getUserInfo}
                                    postid={postid}
                                />
                            ))}
                        </List>
                    )}

                    {<CreateComment
                        currentUser={currentUser}
                        sendComment={this.sendComment}
                    />}

                </Grid>
            </Fragment>
        );
    }

}

CommentBox.propTypes = propTypes;

export default withStyles(styles)(CommentBox);



