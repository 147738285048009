import React, {useRef, Fragment} from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import {isArray} from 'util';
import {
    Grid,
    TextField,
    makeStyles,
    Typography,
    Button,
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Avatar from '@material-ui/core/Avatar';
import * as PropTypes from 'prop-types';
import './mycss.css';

import {SEMESTER_STATUS, getSemesterStatus} from '../../../../shared/semester';
import {getSchoolId} from "../../../SchoolProfile/components/GlobalComponents/GlobalFields";
import {getStudentCheckinData} from "../../../SchoolProfile/ApiServices";
import TextArea from "antd/es/input/TextArea";

const useStyles = makeStyles(theme => ({
    selectedMark: {
        backgroundColor: '#706d6d',
    },

    unselectedMark: {
        backgroundColor: '#ffffff',
        border: '1px solid darkgray !important;',
        color: '#000000',
    },
    stopMarker: {
        backgroundColor: "lightgray !important;"
    },
    subTitle: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
}));

function SkillMarks(props) {
    const {
        module,
        semesters,
        category,
        onHandleChangeMark,
        generateMarksGrid,
        handleOnStudentGradesChanged,
        studentGrades,
        currentMarks,
        semesterDetails,
        isAdmin,
        isTeacher,
        isParent,
        groupDetails,
        childId,
        indexProp,
        handleCommentSave,
      handleAttendanceSave,
        groupData,
        handleMarkComplete,
        history,
    } = props;
    const classes = useStyles();

    const [EditIconFlag, setEditIconFlag] = React.useState([]);
    const [grades, setGrades] = React.useState([]);
    const [firstMarkSemester, setfirstMarkSemester] = React.useState([]);
    const [edit, setEdit] = React.useState(false);
    const [attendanceedit, setAttendanceEdit] = React.useState(false);
    const [comments, setComments] = React.useState([]);
    const [attendances, setAttendances] = React.useState([]);
    const [attendanceOpen, setAttendanceOpen] = React.useState(false)
    const [attendance, setAttendance] = React.useState({
        attendance: {},
        details: {
            year: -1,
            semester: -1,
        },
    });
    const [attendanceCount, setattendanceCount] = React.useState({})

    const [comment, setComment] = React.useState({
        comment: '',
        details: {
            year: -1,
            semester: -1,
        },
    });
    const [open, setOpen] = React.useState(false);
    const textFieldRef = useRef(null);
    React.useEffect(() => {
        const commentsState = [];
        const attendancesState = [];
        for (
            let i = 1;
            i <= groupData.studentSemesterDetails.module_detail.years;
            i++
        ) {
            for (
                let j = 1;
                j <= groupData.studentSemesterDetails.module_detail.slices;
                j++
            ) {
                // console.log('Pusing->',i,j,groupData.studentSemesterDetails.year, groupData.studentSemesterDetails.slice)

                commentsState.push({
                    comment: '',
                    details: {
                        year: i,
                        semester: j,
                        category: category.name,
                        subCategory: category.subCategory
                    },
                });
                if (category.name === 'Attendance') {

                    attendancesState.push({
                        attendance: {},
                        details: {
                            year: i,
                            semester: j,
                            category: category.name,
                            subCategory: category.subCategory
                        },
                    });
                }
            }
        }
        // console.log('----------------')
        // console.log(commentsState)
        // console.log('----------------')

        if (isArray(groupData.marks)) {
            groupData.marks.forEach(element => {
              console.log('element.marks', element.marks)
                for (var key in element.marks) {
                  console.log('key', key)
                    if (key === category.name && category.subCategory) {
                        if (((element.marks[key] || {})[category.subCategory] || {}).hasOwnProperty('comment')) {
                            commentsState.forEach((singleComment, index) => {
                                // console.log(singleComment)
                                if (
                                    singleComment.details.year === element.year &&
                                    singleComment.details.semester === element.slice &&
                                    singleComment.details.category === category.name &&
                                    singleComment.details.subCategory === category.subCategory
                                ) {
                                    commentsState[index] = {
                                        comment: element.marks[key][category.subCategory].comment[0],
                                        details: {
                                            year: element.year,
                                            semester: element.slice,
                                            category: category.name,
                                            subCategory: category.subCategory
                                        },
                                    };
                                }
                            });
                        } else {
                            commentsState.forEach((singleComment, index) => {
                                if (
                                    singleComment.details.year === element.year &&
                                    singleComment.details.semester === element.slice &&
                                    singleComment.details.category === category.name &&
                                    singleComment.details.subCategory === category.subCategory
                                ) {
                                    commentsState[index] = {
                                        comment: '',
                                        details: {
                                            year: element.year,
                                            semester: element.slice,
                                            category: category.name,
                                            subCategory: category.subCategory
                                        },
                                    };
                                }
                            });
                        }

                        if (key === 'Attendance') {
                          console.log('attendance', element.marks[key][category.subCategory])
                          if (((element.marks[key] || {})[category.subCategory] || {}).hasOwnProperty('attendance')) {
                            attendancesState.forEach((singleAttendance, index) => {
                              // console.log(singleComment)
                              if (
                                  singleAttendance.details.year === element.year &&
                                  singleAttendance.details.semester === element.slice &&
                                  singleAttendance.details.category === category.name &&
                                  singleAttendance.details.subCategory === category.subCategory
                              ) {
                                attendancesState[index] = {
                                  attendance: element.marks[key][category.subCategory]?.attendance === undefined  ? {} : element.marks[key][category.subCategory].attendance,
                                  details: {
                                    year: element.year,
                                    semester: element.slice,
                                    category: category.name,
                                    subCategory: category.subCategory
                                  },
                                };
                              }
                            });
                          }
                        }
                    }
                }
            });
        }
        console.log('attendancestate', attendancesState)
        setComments(commentsState);
        setAttendances(attendancesState);

        //* ***** INITIAL MARK AS COMPLETE **** */
        let _firstMarkSemester = null;
        // console.log('------ INIT BEGIN ------')
        // console.log(semesters)
        semesters.map((semestersOfYears, i) => {
            semestersOfYears.map((semester, index) => {
                const semesterStatus = getSemesterStatus(
                    module,
                    childId,
                    i + 1,
                    index + 1,
                );
                if (
                    semesterStatus === 'In Progress' ||
                    semesterStatus === 'Ready for Marking'
                ) {
                    if (_firstMarkSemester == null) {
                        // console.log('Setting .....')
                        // console.log(i, index)
                        _firstMarkSemester = [i, index];
                    }
                }
            });
        });
        setfirstMarkSemester(_firstMarkSemester);
        // console.log(_firstMarkSemester)
        // console.log(firstMarkSemester)
        // console.log('------ INIT END ------')
    }, []);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const setAttendanceSummary = async (id) => {
        const payload = {
            school_id: getSchoolId(),
            child_id: id
        }

        let totalDays = 0
        let absent = 0
        let tardy = 0
        let present = 0
        let virtual = 0
        let unmarked = 0
        const response = await getStudentCheckinData(payload)
        if(response && response.status === "success") {

            const absentArray = ["excusedabsence", "unexcusedabsence"]
            const tardyArray = ["excusedtardy", "unexcusedtardy"]

            response.data.forEach(x => {
                const array = x.attendance && Object.keys(x.attendance || {})
                const value = ((array || []).length && array[0]) || ""
                if(absentArray.includes(value)) {
                    absent++
                } else if(tardyArray.includes(value)) {
                    tardy++
                } else if(value === "in" || value === "checkedout") {
                    present++
                } else if(value === "virtual") {
                    virtual++
                }else{
                    console.log(x)
                    unmarked++
                }
                totalDays++
            })

        }

        setattendanceCount( {
                totalDays,
                absent,
                tardy,
                present,
                virtual,
                unmarked
            })
        console.log(attendanceCount, {
            totalDays,
            absent,
            tardy,
            present,
            virtual,
            unmarked
        })
    }
    const handleAttendanceClickOpen = (e, year, semester, fullSemester) => {
        setAttendanceSummary(childId)
        const semesterStatus = getSemesterStatus(
            module,
            childId,
            year + 1,
            semester + 1,
        );
        if (
            semesterStatus === SEMESTER_STATUS.complete ||
            semesterStatus === SEMESTER_STATUS.published ||
            (!fullSemester.is_current && !isAdmin && !module.teacher_override)
        ) {
            setEditIconFlag(false);
        } else {
            setEditIconFlag(true);
        }
        attendances.forEach(att => {
            if (
                att.details.year === year + 1 &&
                att.details.semester === semester + 1
            ) {
                console.log('Opening Att with Data ', att)

                setAttendance(att);
                setEdit(!Object.keys(att.attendance).length > 0);
                setAttendanceOpen(true);
            }
        });
    };
    const handleClickOpen = (e, year, semester, fullSemester) => {
        const semesterStatus = getSemesterStatus(
            module,
            childId,
            year + 1,
            semester + 1,
        );
        if (
            semesterStatus === SEMESTER_STATUS.complete ||
            semesterStatus === SEMESTER_STATUS.published ||
            (!fullSemester.is_current && !isAdmin && !module.teacher_override)
        ) {
            setEditIconFlag(false);
        } else {
            setEditIconFlag(true);
        }
        comments.forEach(cmnt => {
            if (
                cmnt.details.year === year + 1 &&
                cmnt.details.semester === semester + 1
            ) {
                setComment(cmnt);
                setEdit(!cmnt.comment.length > 0);
                setOpen(true);
            }
        });
    };

    const handleSaveComment = () => {
        const cmnts = comments;
        cmnts.forEach(cmnt => {
            if (comment.details === cmnt.details) {
                cmnt.comment = comment.comment;
            }
        });
        console.log(cmnts)
        console.log(comment)
      console.log(comment)
      setComments(cmnts);
        handleCommentSave(category.name, comment);
        setOpen(false);
    };

    const handleSaveAttendance = () => {
      console.log('@@@@@@@@@@@@@@@@@@@@@')
      const atts = attendances;
      console.log(atts)
      atts.forEach(att => {
            if (attendance.details.year === att.details.year
                && attendance.details.semester === att.details.semester
                && attendance.details.category === att.details.category
                && attendance.details.subCategory === att.details.subCategory) {
                att.attendance = attendance.attendance;
            }
        });
        setAttendances(atts);
        console.log(attendances)
        handleAttendanceSave(attendance);
        setAttendanceOpen(false);
    }

    function handleClose() {
        setOpen(false);
    }

    function handleAttendanceClose() {
        setAttendanceOpen(false);
    }
    const handleComplete = (e, year, semester) => {
        console.log(year, semester);
        console.log(grades);
        console.log(semesters);
        //* ** Get Current Semester  *****/
        let startNoting = false;
        const semesterstoNote = [];
        console.log('Starting from ', firstMarkSemester);
        for (let checkyr = firstMarkSemester[0]; checkyr <= year; checkyr++) {
            const numsems =
                checkyr == year ? semester : semesters[checkyr].length - 1;
            console.log(`Number of Semesters : ${numsems}`);
            for (let checksm = firstMarkSemester[1]; checksm <= numsems; checksm++) {
                console.log(`Checking ->${checkyr} ${checksm}`);
                if (semesters[checkyr][checksm].className !== undefined) {
                    startNoting = true;
                }
                if (startNoting) {
                    semesterstoNote.push([checkyr, checksm]);
                }
            }
        }
        console.log(semesterstoNote);
        console.log('+++++++++++++++');
        console.log(firstMarkSemester);

        /*if (semesterstoNote.length == 0) {
          handleMarkComplete([{ year: year + 1, slice: semester + 1 }], 2, true);
        }
        semesterstoNote.forEach(value => {
          handleMarkComplete(
            [{ year: value[0] + 1, slice: value[1] + 1 }],
            2,
            true,
          );
        });*/

        handleMarkComplete([{year: year + 1, slice: semester + 1}], 2, true);

        // handleMarkComplete([{ year: year + 1, slice: semester + 1 }], 2);
    };

    const search = (key, value, inputArray) => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < inputArray.length; i++) {
            if (inputArray[i][key] === value) {
                return inputArray[i];
            }
        }
    };

    const onCommentChange = e => {
        setComment({comment: e.target.value, details: comment.details});
        // handleCommentChange(category, cmmt);
    };

    const onAttendanceChangeTotal = e => {
      console.log('onAttendance', e.target.value, attendance)
      let att = attendance
      console.log(att)
      att.attendance.total = e.target.value
      setAttendance(att)
      console.log(attendance)
    }
  const onAttendanceChangeTardy = e => {
    console.log('onAttendance', e.target.value, attendance)
    let att = attendance
    console.log(att)
    att.attendance.tardy = e.target.value
    setAttendance(att)
    console.log(attendance)

  }
  const onAttendanceChangeExcusedAbsence = e => {
    console.log('onAttendance', e.target.value, attendance)
    let att = attendance
    console.log(att)
    att.attendance.excusedabsence = e.target.value
    setAttendance(att)
    console.log(attendance)
  }

    const onAttendanceChangeUnExcusedAbsence = e => {
        console.log('onAttendance', e.target.value, attendance)
        let att = attendance
        console.log(att)
        att.attendance.unexcusedabsence = e.target.value
        setAttendance(att)
        console.log(attendance)
    }
  const onAttendanceChangePresent = e => {
    let att = attendance
    att.attendance.present = e.target.value
    setAttendance(att)

  }

  const onAttendanceChangePortfolio = e => {
    let att = attendance
    att.attendance.portfolio = e.target.value
    setAttendance(att)

  }


  const handleChangeMark = (
        e,
        year,
        semester,
        grade,
        ref,
        topYearIndex,
        gridIndex,
        skill,
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const currentYear = year.index + 1;
        const currentSemester = semester.index + 1;

        const semesterStatus = getSemesterStatus(
            module,
            childId,
            currentYear,
            currentSemester,
        );

        for (
            let i = 0;
            i < grades[gridIndex][year.index][semester.index].length;
            i++
        ) {
            if (i !== grade.index) {
                grades[gridIndex][year.index][semester.index][i].is_selected = false;
                grades[gridIndex][year.index][semester.index][i].className =
                    classes.unselectedMark;
            }
        }

        const isSelected =
            grades[gridIndex][year.index][semester.index][grade.index].is_selected;

        grades[gridIndex][year.index][semester.index][
            grade.index
            ].is_selected = !isSelected;
        grades[gridIndex][year.index][semester.index][
            grade.index
            ].className = isSelected ? classes.unselectedMark : classes.selectedMark;
        grades[gridIndex][year.index][semester.index][grade.index].skill = skill;

        setGrades([...grades]);

        onHandleChangeMark(
            [...grades],
            gridIndex,
            grades[gridIndex][year.index][semester.index][grade.index],
            year.index + 1,
            semester.index + 1,
            !isSelected,
        );
    };

    const getAttendanceFunc = (year, semester) => {
        let att;
        // console.log(comments)
        // console.log(year, semester)
        attendances.forEach(attendance => {
            if (
                attendance.details.year === year + 1 &&
                attendance.details.semester === semester + 1
            ) {
                att = attendance;
            }
        });
        return att;
    };

    const getCommentsFunc = (year, semester) => {
        let cmt;
        // console.log(comments)
        // console.log(year, semester)
        comments.forEach(cmnt => {
            if (
                cmnt.details.year === year + 1 &&
                cmnt.details.semester === semester + 1
            ) {
                cmt = cmnt;
            }
        });
        return cmt;
    };

    const myAddEditAttendanceFunc = (year, semester) => {
        let s;

        attendances.forEach(attendance => {

            if (
                attendance.details.year === year + 1 &&
                attendance.details.semester === semester + 1
            ) {

                s = Object.keys(attendance.attendance).length > 0 ? 'Edit' : 'Add';
            }
        });
        return s;
    };
    const myAddEditFunc = (year, semester) => {
        let s;
        comments.forEach(cmnt => {
            if (
                cmnt.details.year === year + 1 &&
                cmnt.details.semester === semester + 1
            ) {
                s = cmnt.comment.length > 0 ? 'Edit' : 'Add';
            }
        });
        return s;
    };

    // console.log(myAddEditFunc(1, 1));

    React.useEffect(() => {
        // setGrades(studentGrades);
    }, [studentGrades]);

    React.useEffect(() => {
        const gradesToGenerate = [];

        // console.log(generateMarksGrid)
        // console.log('***********')
        for (let i = 0; i < generateMarksGrid.length; i++) {
            gradesToGenerate[i] = [];
            for (let j = 0; j < generateMarksGrid[i].years.length; j++) {
                gradesToGenerate[i][j] = [];
                for (
                    let k = 0;
                    k < generateMarksGrid[i].years[j].semesters.length;
                    k++
                ) {
                    gradesToGenerate[i][j][k] = Object.keys(category.scale.grades).map(
                        gradeMark => {
                            const marked =
                                gradeMark === generateMarksGrid[i].years[j].semesters[k].mark;

                            return {
                                mark: gradeMark,
                                className: marked
                                    ? classes.selectedMark
                                    : classes.unselectedMark,
                                is_selected: marked,
                                ref: React.createRef(),
                            };
                        },
                    );
                }
            }
        }

        setGrades(gradesToGenerate);

        handleOnStudentGradesChanged(gradesToGenerate);
        // }, [generateMarksGrid, currentMarks, semesterDetails, groupDetails]);
    }, [generateMarksGrid]);

    function showAttendanceSection(category) {

      if (category.name === 'Attendance') {
            return (
                <TableRow>
                    <TableCell
                        rowSpan={1}
                        className="mystrip"
                        colSpan={4}
                        style={{
                            fontSize: '20px',
                            alignItems: 'center',
                        }}
                    >
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    paddingLeft: '10px',
                }}
            >
              <Typography variant="subtitle1" style={{fontSize: '12px'}}>
                Attendance Markings
              </Typography>
            </span>
                    </TableCell>
                    {semesters.map((semestersOfYears, i) =>
                        semestersOfYears.map((semester, index) => {
                            const semesterStatus = getSemesterStatus(
                                module,
                                childId,
                                i + 1,
                                index + 1,
                            );
                            const showAttendance = () => {
                                // console.log('semesterStatus =>' + semesterStatus)
                                if (
                                    semesterStatus === 'In Progress' ||
                                    semesterStatus === 'Ready for Marking'
                                ) {
                                    return (
                                        <Typography
                                            variant="subtitle1"
                                            style={{fontWeight: 'bold', fontSize: '10px'}}
                                        >
                                            {myAddEditAttendanceFunc(i, index)}
                                        </Typography>
                                    );
                                }
                                const att = getAttendanceFunc(i, index);
                                // console.log(att)
                                if (att !== undefined && Object.keys(att.attendance).length > 0) {
                                    return (
                                        <Typography
                                            variant="subtitle1"
                                            style={{fontWeight: 'bold', fontSize: '10px'}}
                                        >
                                            View
                                        </Typography>
                                    );
                                }
                                return null;
                            };
                            return (
                                <TableCell
                                    scope="col"
                                    align="center"
                                    className={
                                        semesterStatus === 'In Progress' || 'Ready for Marking'
                                            ? `makeStyles-CommentContainer mystrip`
                                            : `mystrip`
                                    }
                                    key={`xx${index}`}
                                    style={{fontWeight: 'bold', padding: '5px', border: 'none'}}
                                >
                                    <Button
                                        color="primary"
                                        size="small"
                                        key={`xx${index}`}
                                        semester={index}
                                        year={i}
                                        onClick={e => handleAttendanceClickOpen(e, i, index, semester)}
                                    >
                                        {showAttendance()}
                                    </Button>
                                </TableCell>
                            );
                        }),
                    )}
                </TableRow>
            )
        } else return "";
    }

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle
                    id="responsive-dialog-title"
                    style={{
                        paddingBottom: '10px',
                        backgroundColor: '#3c3989',
                    }}
                >
          <span
              style={{
                  color: 'white',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                  fontSize: '16px',
              }}
          >
            Comment
            <span>
              {EditIconFlag && (
                  <EditIcon
                      fontSize="medium"
                      style={{
                          paddingLeft: '5px',
                          paddingBottom: '3px',
                          color: 'white',
                      }}
                      onClick={() => {
                          setEdit(!edit);
                      }}
                  />
              )}
            </span>
          </span>
                </DialogTitle>
                <DialogContent
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        disabled={!edit}
                        autoFocus
                        multiline
                        margin="dense"
                        id="comment"
                        label=""
                        type="text"
                        fullWidth
                        value={comment.comment}
                        onChange={onCommentChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={handleClose}
                        style={{
                            fontWeight: 'bold',
                            color: '#3c3989',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            style={{fontSize: '10px', fontWeight: 'bold'}}
                        >
                            Cancel
                        </Typography>
                    </Button>
                    {edit && (
                        <Button
                            color="primary"
                            onClick={handleSaveComment}
                            className="mymodalBtn"
                            style={{
                                fontWeight: 'bold',
                                backgroundColor: '#3c3989',
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                style={{fontSize: '10px', fontWeight: 'bold'}}
                            >
                                Save
                            </Typography>
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog fullScreen={fullScreen}
                    fullWidth
                    open={attendanceOpen}
                    onClose={handleAttendanceClose}
                    aria-labelledby="responsive-dialog-title">
                      <DialogTitle
                          id="responsive-dialog-title"
                          style={{
                              paddingBottom: '10px',
                              backgroundColor: '#3c3989',
                          }}
                      >
                            <span
                                style={{
                                    color: 'white',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                }}
                            >
                              Attendance
                              <span>
                                {EditIconFlag && (
                                    <EditIcon
                                        fontSize="medium"
                                        style={{
                                            paddingLeft: '5px',
                                            paddingBottom: '3px',
                                            color: 'white',
                                        }}
                                        onClick={() => {
                                          console.log('attendance',attendance, attendanceedit)
                                          setAttendanceEdit(!attendanceedit);
                                            if (textFieldRef.current) {
                                                textFieldRef.current.focus();
                                            }
                                        }}
                                    />
                                )}
                              </span>
                            </span>
                      </DialogTitle>

                  <DialogContent
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                  >
                    <TextField
                        disabled={!attendanceedit}
                        autoFocus
                        multiline={false}
                        margin="dense"
                        id="comment"
                        label="Total Number of Days"
                        type="text"
                        fullWidth
                        inputProps={{
                            maxLength: 3,
                            pattern: '[0-9]*',
                        }}
                        inputRef={textFieldRef}
                        variant={'outlined'}
                        defaultValue={attendance.attendance.total}
                        onChange={onAttendanceChangeTotal}
                    />
                    <TextField
                        disabled={!attendanceedit}

                        multiline={false}
                        margin="dense"
                        variant={'outlined'}
                        id="comment"
                        label="Total Number of Days Excused Absence"
                        type="text"
                        inputProps={{
                            maxLength: 3,
                            pattern: '[0-9]*',
                        }}
                        fullWidth
                        defaultValue={attendance.attendance.excusedabsence}
                        onChange={onAttendanceChangeExcusedAbsence}
                    />
                      <TextField
                          disabled={!attendanceedit}

                          multiline={false}
                          margin="dense"
                          variant={'outlined'}
                          id="comment"
                          inputProps={{
                              maxLength: 3,
                              pattern: '[0-9]*',
                          }}
                          label="Total Number of Days Unexcused Absence"
                          type="text"
                          fullWidth
                          defaultValue={attendance.attendance.unexcusedabsence}
                          onChange={onAttendanceChangeUnExcusedAbsence}
                      />
                    <TextField
                        disabled={!attendanceedit}

                        multiline={false}
                        margin="dense"
                        id="comment"
                        variant={'outlined'}
                        inputProps={{
                            maxLength: 3,
                            pattern: '[0-9]*',
                        }}
                        label="Total Number of Days Tardy"
                        type="text"
                        fullWidth
                        defaultValue={attendance.attendance.tardy}
                        onChange={onAttendanceChangeTardy}
                    />
                    <TextField
                        disabled={!attendanceedit}

                        multiline={false}
                        variant={'outlined'}
                        margin="dense"
                        inputProps={{
                            maxLength: 3,
                            pattern: '[0-9]*',
                        }}
                        id="comment"
                        label="Total Number of Days Present"
                        type="text"
                        fullWidth
                        defaultValue={attendance.attendance.present}
                        onChange={onAttendanceChangePresent}
                    />
                    <TextField
                        disabled={!attendanceedit}

                        multiline={false}
                        margin="dense"
                        variant={'outlined'}
                        id="comment"
                        label="Portfolio Link (begin with http:// or https://)"
                        type="text"
                        fullWidth
                        defaultValue={attendance.attendance.portfolio}
                        onChange={onAttendanceChangePortfolio}
                    />
                      <div>
                      <Fragment>Recorded Attendance in System :  <br/></Fragment>
                      <Fragment>Total Days : {attendanceCount.totalDays}  </Fragment>
                          <Fragment>Excused Absences : {attendanceCount.absent} </Fragment>
                          <Fragment>UnExcused Absences : {attendanceCount.unmarked}  </Fragment>
                          <Fragment>Tardy : {attendanceCount.tardy}  </Fragment>
                          <Fragment>Present : {attendanceCount.present}   </Fragment>
                      </div>
                  </DialogContent>

                <DialogActions>
                    {/*<Button color="primary" onClick={handleAttendanceClose}>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    <Button color="primary" onClick={handleSaveAttendance}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {indexProp === 0 && (
                <TableHead>
                    <TableRow style={{border: 'none'}}>
                        <TableCell
                            rowSpan={2}
                            colSpan={4}
                            style={{
                                background: '#F8F8F8 0% 0% no-repeat padding-box',
                                border: 'none',
                            }}
                        >
              <span
                  style={{
                      paddingLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                  }}
              >
                <Typography
                    style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                    }}
                >
                  {" "}
                </Typography>
              </span>
                        </TableCell>
                        {semesters.map((semestersOfYears, year) =>
                                semestersOfYears.map((semester, index) => {
                                    const semesterStatus = getSemesterStatus(
                                        module,
                                        childId,
                                        year + 1,
                                        index + 1,
                                    );
                                    let statusText =
                                        semesterStatus === 'Complete'
                                            ? 'Completed'
                                            : semesterStatus === 'Published To Parents'
                                                ? 'Published to parents'
                                                : semesterStatus === SEMESTER_STATUS.complete ||
                                                semesterStatus === SEMESTER_STATUS.published ||
                                                (!semester.isCurrent && isParent && !module.teacher_override)
                                                    ? 'Not Started'
                                                    : 'Open for editing';

                                    // if (semester.isCurrent) {
                                    //   statusText = 'Open for editing';
                                    // }
                                    // console.log('==========================')
                                    // console.log(`Semester Status =>${semesterStatus}`);
                                    // console.log(semester)
                                    // console.log(statusText)
                                    // console.log('semester.isCurrent->' + semester.isCurrent)
                                    // console.log('==========================')
                                    return (
                                        <TableCell
                                            scope="col"
                                            align="center"
                                            className={
                                                statusText === 'Open for editing'
                                                    ? 'makeStyles-currentSemesterHeader'
                                                    : statusText === 'Published to parents'
                                                        ? 'makeStyles-pubishedSemesterHeader'
                                                        : 'makeStyles-completedSemesterHeader'
                                            }
                                            key={`xx${index}`}
                                            style={{
                                                fontWeight: 'bold',
                                                padding: '5px',
                                                color: 'black',
                                                fontSize: '14px',
                                                background: '#F8F8F8 0% 0% no-repeat padding-box',
                                                border: 'none',
                                            }}
                                        >
                                            Year {year + 1} <br/>
                                            Semester {index + 1}
                                            <span>
                      <Typography style={{fontSize: '9px'}}>
                        {statusText}
                      </Typography>
                    </span>
                                        </TableCell>
                                    );
                                }),
                        )}
                    </TableRow>
                </TableHead>
            )}

            <TableBody>
                <TableRow
                    style={{
                        color: 'white',
                        // backgroundColor: '#bdbdbd',
                        // width: '100%',
                    }}
                    className="bax"
                >
                    <TableCell
                        colSpan={
                            99
                            // semesterDetails.module_detail.slices *
                            // semesterDetails.module_detail.years
                        }
                        style={{border: 'none', backgroundColor: '#eeeeee'}}
                    >
                        {
                            category.subOrder_id === 0 ?
                                <Grid item md={12}>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.subTitle}
                                        style={{
                                            fontWeight: 'bold',
                                            marginTop: '7px',
                                            marginBottom: '7px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginLeft: '0px',
                                            color: 'black',
                                            fontSize: '20px',
                                            paddingLeft: '10px',
                                            textTransform: "uppercase"
                                        }}
                                    >
                                        {category.name || ""}
                                        {/*{`Category: ${category.name || ""} || Sub Category: ${category.subCategory || ""}`}*/}
                                    </Typography>
                                </Grid> : null
                        }
                        <Grid item md={12}>
                            <Typography
                                variant="subtitle1"
                                className={classes.subTitle}
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: '7px',
                                    marginBottom: '7px',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    marginLeft: '0px',
                                    color: 'black',
                                    fontSize: '14px',
                                    paddingLeft: '10px',
                                }}
                            >
                                {category.subCategory || ""}
                                {/*{`Category: ${category.name || ""} || Sub Category: ${category.subCategory || ""}`}*/}
                            </Typography>
                        </Grid>
                    </TableCell>
                </TableRow>
                {generateMarksGrid.map((markGrid, gridIndex) => (
                    <React.Fragment>
                        <TableRow key={markGrid.skill} className="mystrip">
                            <TableCell
                                component="td"
                                scope="row"
                                colSpan={4}
                                className="mystrip"
                                style={{
                                    padding: '5px',
                                    // border: '2px',
                                    // borderColor: 'white',
                                }}
                            >
                <span
                    style={{
                        textAlign: 'left',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingLeft: '10px',
                    }}
                >
                  <Typography variant="subtitle1" style={{fontSize: '12px'}}>
                    {markGrid.skill}
                  </Typography>
                </span>
                            </TableCell>

                            {markGrid.years.map((year, topYearIndex) =>
                                year.semesters.map((yearSemesters, yearSemestersIndex) => {
                                    const semesterStatus = getSemesterStatus(
                                        module,
                                        childId,
                                        topYearIndex + 1,
                                        yearSemestersIndex + 1,
                                    );
                                    const statusText =
                                        semesterStatus === 'Complete'
                                            ? 'Completed'
                                            : semesterStatus === 'Published To Parents'
                                                ? 'Published to parents'
                                                : semesterStatus === SEMESTER_STATUS.complete ||
                                                semesterStatus === SEMESTER_STATUS.published ||
                                                (!yearSemesters.isCurrent && isParent && !module.teacher_override)
                                                    ? 'Not Started'
                                                    : 'Open for editing';
                                    // console.log(module.modul + '<->' + yearSemestersIndex + '=' + semesterStatus + '<->' + statusText)


                                    let className =
                                        semesterStatus === 'In Progress' ||
                                        statusText === 'Open for editing'
                                            ? `makeStyles-currentSemesterContainer`
                                            : semesterStatus === 'Published To Parents'
                                                ? `makeStyles-publishedSemesterContainer`
                                                : (!yearSemesters.isCurrent && isParent && !module.teacher_override)
                                                    ? `makeStyles-currentSemesterContainer`
                                                    : `makeStyles-completedSemesterContainer`;

                                    // if (yearSemesters.isCurrent) {
                                    //   className = 'makeStyles-currentSemesterContainer';
                                    // }
                                    // console.log(
                                    //   `${yearSemestersIndex}<->${
                                    //     module.modul
                                    //   }<->${semesterStatus}<->${className}`,
                                    // );
                                    return (
                                        <React.Fragment>
                                            <TableCell
                                                align="center"
                                                className={`${className} ${markGrid.stop_marker === `${topYearIndex + 1},${yearSemestersIndex + 1}` ? classes.stopMarker : ""}`}
                                                // className={`${yearSemesters.marksClassName} mystrip`}
                                            >
                                                <Grid container alignItems="center" justify="center">
                                                    {grades[gridIndex] &&
                                                        grades[gridIndex][topYearIndex] &&
                                                        grades[gridIndex][topYearIndex][
                                                            yearSemestersIndex
                                                            ] &&
                                                        grades[gridIndex][topYearIndex][
                                                            yearSemestersIndex
                                                            ].map((g, gradeIndex) => {
                                                            const avtaarClassName =
                                                                semesterStatus === 'In Progress' ||
                                                                semesterStatus === 'Ready for Marking' ||
                                                                semesterStatus === 'Open for editing'
                                                                    ? g.className
                                                                    : [g.className, 'mydisabled'];
                                                            // console.log('----------------')
                                                            // console.log('--=>' + g.className)
                                                            // console.log(semesterStatus)
                                                            // console.log(avtaarClassName)
                                                            // console.log('----------------')
                                                            //console.log("======================", { isTeacher, yearSemesters, module})
                                                            return (
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        paddingLeft: '3px',
                                                                        marginBottom: '3px',
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        data-m="awesome-matar"
                                                                        // ref={g.ref}
                                                                        data-sindex={yearSemesters.index}
                                                                        data-mark={g.mark}
                                                                        data-semester={gradeIndex}
                                                                        className={avtaarClassName}
                                                                        // className={g.className}
                                                                        onClick={e => {
                                                                            //  console.log('Clicked .....')
                                                                            //  console.log(yearSemesters.isCurrent)
                                                                            //  console.log(module.teacher_override)

                                                                            if (
                                                                                isAdmin || isTeacher
                                                                            ) {
                                                                                handleChangeMark(
                                                                                    e,
                                                                                    {
                                                                                        grid: markGrid,
                                                                                        index: topYearIndex,
                                                                                    },
                                                                                    {
                                                                                        semester: yearSemesters,
                                                                                        index: yearSemestersIndex,
                                                                                    },
                                                                                    {grade: g, index: gradeIndex},
                                                                                    g.ref,
                                                                                    topYearIndex,
                                                                                    gridIndex,
                                                                                    markGrid.skill,
                                                                                );
                                                                            }
                                                                        }}
                                                                        style={{
                                                                            width: '22px',
                                                                            height: '22px',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <text style={{fontSize: '12px'}}>
                                                                            {g.mark}
                                                                        </text>
                                                                    </Avatar>
                                                                </Grid>
                                                            );
                                                        }, semesterStatus)}
                                                </Grid>
                                            </TableCell>
                                        </React.Fragment>
                                    );
                                }),
                            )}
                        </TableRow>
                    </React.Fragment>
                ))}
                {showAttendanceSection(category)}
                <TableRow>
                    <TableCell
                        rowSpan={2}
                        className="mystrip"
                        colSpan={4}
                        style={{
                            fontSize: '20px',
                            alignItems: 'center',
                        }}
                    >
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    paddingLeft: '10px',
                }}
            >
              <Typography variant="subtitle1" style={{fontSize: '12px'}}>
                Comments
              </Typography>
            </span>
                    </TableCell>
                    {semesters.map((semestersOfYears, i) =>
                        semestersOfYears.map((semester, index) => {
                            const semesterStatus = getSemesterStatus(
                                module,
                                childId,
                                i + 1,
                                index + 1,
                            );
                            const showComments = () => {

                                if (
                                    semesterStatus === 'In Progress' ||
                                    semesterStatus === 'Ready for Marking'
                                ) {
                                    return (
                                        <Typography
                                            variant="subtitle1"
                                            style={{fontWeight: 'bold', fontSize: '10px'}}
                                        >
                                            {myAddEditFunc(i, index)}
                                        </Typography>
                                    );
                                }
                                const cmt = getCommentsFunc(i, index);
                                // console.log(cmt)
                                if (cmt !== undefined && cmt.comment.length > 0) {
                                    return (
                                        <Typography
                                            variant="subtitle1"
                                            style={{fontWeight: 'bold', fontSize: '10px'}}
                                        >
                                            View
                                        </Typography>
                                    );
                                }
                                return null;
                            };
                            return (
                                <TableCell
                                    scope="col"
                                    align="center"
                                    className={
                                        semesterStatus === 'In Progress' || 'Ready for Marking'
                                            ? `makeStyles-CommentContainer mystrip`
                                            : `mystrip`
                                    }
                                    key={`xx${index}`}
                                    style={{fontWeight: 'bold', padding: '5px', border: 'none'}}
                                >
                                    <Button
                                        color="primary"
                                        size="small"
                                        key={`xx${index}`}
                                        semester={index}
                                        year={i}
                                        onClick={e => handleClickOpen(e, i, index, semester)}
                                    >
                                        {showComments()}
                                    </Button>
                                </TableCell>
                            );
                        }),
                    )}
                </TableRow>
            </TableBody>

        </>
    );
}

SkillMarks.propTypes = {
    module: PropTypes.any,
    semesters: PropTypes.any,
    category: PropTypes.any,
    categoryIndex: PropTypes.any,
    onHandleChangeMark: PropTypes.func,
    handlePhotosChange: PropTypes.func,
    generateMarksGrid: PropTypes.any,
    handleOnStudentGradesChanged: PropTypes.func,
    studentGrades: PropTypes.any,
    currentMarks: PropTypes.any,
    semesterDetails: PropTypes.any,
    isAdmin: PropTypes.any,
    isTeacher: PropTypes.any,
    isParent: PropTypes.any,
    groupDetails: PropTypes.any,
    childId: PropTypes.number,
    prevComments: PropTypes.func,
    handleGoalsChange: PropTypes.func,
    handleCommentChange: PropTypes.func,
    categoryComments: PropTypes.array,
    categoryGoals: PropTypes.array,
};

export default SkillMarks;
