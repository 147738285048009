import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";
import { Grid, withStyles, Container } from "@material-ui/core";
import {Button, Select, Spin, DatePicker} from "antd";
import cloneDeep from "lodash/cloneDeep";
import PieChart from "react-minimal-pie-chart"
import {getFormattedDate, getSchoolId} from "../GlobalComponents/GlobalFields";
import * as routes from "../../../../routes/path";
import {newUserInfoSuccess} from "../../../../redux/actions/current-user";
import authService from "../../../../service/auth";
import {getChildAttendance, getStaffAttendance} from "../../ApiServices";
import "../school.css"

const { Option } = Select

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class MainDashboard extends Component {
    constructor(props){
        super(props)
        this.state = {
            isCounting: false, // true,,
            isLoading: true,
            isAttendanceCounting: false,
            allStudentsList: [],
            count: {},
            attendanceCount: {
                student: {},
                staff: {}
            },
            searchKeys: "",
            selectedClass: "all",
            pagination: {
                page: 1,
                pageSize: 7
            },
            selectedDate: new Date()
        }
    }

    async componentWillMount() {
        this.getCounts()
    }

    getCounts = async () => {
        this.setState({
            isLoading: true,
            isCounting: true
        })

        const newUserResponse = await authService.getNewUserDetail();
        if(newUserResponse && newUserResponse.data) {
            this.props.onSetUserInfo(newUserResponse.data)
        }

        const { currentUser } = this.props
        const { groupList, childList, usersList } = currentUser || {}

        let staffs = 0
        let parents = 0

        usersList.forEach(user => {
            const isParent = ((((user || {}).role) || {})[getSchoolId()] || {}).parent
            const isTeacher = ((((user || {}).role) || {})[getSchoolId()] || {}).teacher
            const isAdmin = ((((user || {}).role) || {})[getSchoolId()] || {}).admin
            if(isTeacher || isAdmin) {
                staffs ++
            }
            if(isParent) {
                parents ++
            }
        })
        this.setState({
            count: {
                students: (childList || []).length,
                groups: (groupList || []).length,
                staffs,
                parents
            },
            isLoading: false,
            isCounting: false
        })
        this.onGetAttendance()
    }

    onGetAttendance = async () => {
        const { currentUser: { groupList = [] } } = this.props
        const { selectedClass } = this.state

        this.setState({
            isAttendanceCounting: true
        })

        const payload = {
            school_id: getSchoolId(),
            att_date: getFormattedDate(this.state.selectedDate),
            group_ids: selectedClass === 'all' ? (groupList || []).map(x => x.id) : [selectedClass]
        }

        let student = {}
        let staff = {}

        const response = await getChildAttendance(payload)
        if(response && response.status === "success") {
            student = this.getAttendanceCounts(response.data || [])
        }

        const staffRes = await getStaffAttendance(payload)
        if(staffRes && staffRes.status === "success") {
            staff = this.getAttendanceCounts(staffRes.data || [])
        }

        this.setState({
            isAttendanceCounting: false,
            attendanceCount: {
                student,
                staff
            }
        })
    }

    getAttendanceCounts = (attendanceList) => {
        let totalDays = 0
        let absent = 0
        let tardy = 0
        let present = 0
        let virtual = 0
        let unmarked = 0

        const absentArray = ["excusedabsence", "unexcusedabsence"]
        const tardyArray = ["excusedtardy", "unexcusedtardy"]

        attendanceList.forEach(x => {
            const array = x.attendance && Object.keys(x.attendance || {})
            const value = ((array || []).length && array[0]) || ""
            if(absentArray.includes(value)) {
                absent++
            } else if(tardyArray.includes(value)) {
                tardy++
            } else if(value === "in" || value === "checkedout") {
                present++
            } else if(value === "virtual") {
                virtual++
            } else{
                console.log('attendanceList', value, x)
                unmarked++
            }
            totalDays++
        })

        return { totalDays, absent, present, tardy, virtual , unmarked}
    }

    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => this.onGetAttendance())
    }

    render() {
        const { classes, currentUser: { groupcache: groupList = {} } } = this.props
        const { selectedClass, isCounting, count, selectedDate, attendanceCount, isAttendanceCounting } = this.state
        const { students: students = 0, groups: groups = 0, parents: parents = 0, staffs: staffs = 0  } = count || {}
        const {
            student: { absent: studentAbsent = 0, present: studentPresent = 0, tardy: studentTardy = 0, virtual: studentVirtual = 0, unmarked : studentUnmarked = 0 },
            staff: { absent: staffAbsent = 0, present: staffPresent = 0, tardy: staffTardy = 0, virtual: staffVirtual = 0, unmarked : staffUnmarked = 0 }
        } = attendanceCount || {}

        //console.log({attendanceCount, studentAbsent, studentPresent, studentTardy, studentVirtual, staffAbsent, staffPresent, staffTardy, staffVirtual})

        return(
            <Container className={`main-dashboard student ${classes.root}`}>
                <div className="green-button text-center mb-3">
                    {/*<Button shape="round" onClick={() => this.props.history.push(routes.GROUPS_DASHBOARD)}>
                        Overview
                    </Button>*/}
                    <Button shape="round" size="large" className="mr-3" onClick={() => this.props.history.push(routes.GROUPS_DASHBOARD)}>
                        <span style={{color: '#3cc89c'}}>Manage Classes</span>
                    </Button>
                    <Button shape="round" size="large" className="mr-3" onClick={() => this.props.history.push(routes.STUDENT_DASHBOARD)}>
                        <span style={{color: '#3cc89c'}}>Manage Students</span>
                    </Button>
                    <Button shape="round" size="large" onClick={() => this.props.history.push(routes.STAFF_DASHBOARD)}>
                        <span style={{color: '#3cc89c'}}>Manage Staff</span>
                    </Button>
                </div>
                <Grid container className="justify-center">
                    <Grid item xs={6} className="rounded-1rem bg-white shadow" style={{padding: '15px 15px 10px 15px'}}>
                        <h3 className="text-center"><b> School Roster </b></h3>
                        <Container>
                            <div >
                                <div className="button-title-icons">
                                    <h5 className="fs-16">Students</h5>
                                    <h5><b> {isCounting ? <Spin/> : students} </b></h5>
                                </div>
                                <div className="button-title-icons">
                                    <h5 className="fs-16">Parents</h5>
                                    <h5><b> {isCounting ? <Spin/> : parents} </b></h5>
                                </div>
                                <div className="button-title-icons">
                                    <h5 className="fs-16">Staff</h5>
                                    <h5><b> {isCounting ? <Spin/> : staffs} </b></h5>
                                </div>
                                <div className="button-title-icons">
                                    <h5 className="fs-16">Groups</h5>
                                    <h5><b> {isCounting ? <Spin/> : groups} </b></h5>
                                </div>
                            </div>
                        </Container>
                    </Grid>
                </Grid>

                <div className="rounded-1rem bg-white p-40 shadow" style={{marginTop: 50}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className="fs-25 ml-2"><b>View Attendance Across</b></div>
                        <div>

                            <Select
                                placeholder={'Select Class'}
                                className="ml-2"
                                style={{width: 225}}
                                value={selectedClass}
                                onChange={(value) => this.onChange({target: {name: 'selectedClass', value}})}
                            >
                                <Option value={'all'}>All School</Option>
                                {
                                    groupList && Object.keys(groupList).map((key, index) => {
                                        const name = (groupList[key] && groupList[key].attributes && groupList[key].attributes.groupname) || ""
                                        return(
                                            <Option key={index} value={groupList[key].id}>{name}</Option>
                                        )
                                    })
                                }
                            </Select>

                        </div>
                        <div className="fs-25 ml-2"><b>On</b></div>
                        <DatePicker
                            className="ml-2"
                            format={'LL'}
                            allowClear={false}
                            onChange={(date, dateString) => this.onChange({target: {name: 'selectedDate', value: dateString}})}
                            value={selectedDate ? moment(selectedDate, 'LL') : null}
                            disabledDate={this.disabledDate}
                        />
                    </div>
                    <Grid container spacing={6} style={{paddingTop: 40}}>
                        <Grid item xs={6}>
                            <div className="text-center">

                                <h4>Student</h4>

                                <div className="flex items-center mb-2">
                                    <span className="chart-tags bg-present"/>
                                    <span className="fs-15">Present</span>
                                </div>
                                <div className="flex items-center mb-2">
                                    <span className="chart-tags bg-absent"/>
                                    <span className="fs-15">Absent</span>
                                </div>
                                <div className="flex items-center mb-2">
                                    <span className="chart-tags bg-tardy"/>
                                    <span className="fs-15">Tardy</span>
                                </div>
                                <div className="flex items-center mb-2">
                                    <span className="chart-tags bg-virtual"/>
                                    <span className="fs-15">Virtual</span>
                                </div>
                                <div className="flex items-center mb-2">
                                    <span className="chart-tags bg-unmarked"/>
                                    <span className="fs-15">UnMarked</span>
                                </div>

                                {
                                    isAttendanceCounting ? <Spin/> :
                                        <div>
                                            {
                                                (studentPresent || studentAbsent || studentTardy || studentVirtual || studentUnmarked) ?
                                                    <PieChart
                                                        animate={false}
                                                        animationDuration={500}
                                                        animationEasing="ease-out"
                                                        cx={50}
                                                        cy={50}
                                                        data={[
                                                            {
                                                                color: '#3cc89c',
                                                                title: 'Present',
                                                                value: studentPresent
                                                            },
                                                            {
                                                                color: '#d12323',
                                                                title: 'Absent',
                                                                value: studentAbsent
                                                            },
                                                            {
                                                                color: 'orange',
                                                                title: 'Tardy',
                                                                value: studentTardy
                                                            },
                                                            {
                                                                color: '#8787fb',
                                                                title: 'Virtual',
                                                                value: studentVirtual
                                                            },
                                                            {
                                                                color: '#000000',
                                                                title: 'Unmarked',
                                                                value: studentUnmarked
                                                            },

                                                        ]}
                                                        label

                                                        labelStyle={{
                                                            fill: '#121212',
                                                            fontFamily: 'sans-serif',
                                                            fontSize: '5px'
                                                        }}
                                                        lengthAngle={360}
                                                        lineWidth={15}
                                                        onClick={undefined}
                                                        onMouseOut={undefined}
                                                        onMouseOver={undefined}
                                                        paddingAngle={0}
                                                        radius={50}
                                                        rounded
                                                        startAngle={0}
                                                        style={{
                                                            height: '250px'
                                                        }}
                                                        viewBoxSize={[
                                                            100,
                                                            100
                                                        ]}
                                                    /> :
                                                    <div>Student attendance not available</div>
                                            }
                                        </div>
                                }

                            </div>
                        </Grid>

                        <Grid item xs={6}>

                            <div className="text-center">
                                <h4>Staff</h4>

                                <div className="flex items-center justify-end mb-2">
                                    <span className="chart-tags bg-present"/>
                                    <span className="fs-15" style={{width: 60}}>Present</span>
                                </div>
                                <div className="flex items-center justify-end mb-2">
                                    <span className="chart-tags bg-absent"/>
                                    <span className="fs-15" style={{width: 60}}>Absent</span>
                                </div>
                                <div className="flex items-center justify-end mb-2">
                                    <span className="chart-tags bg-tardy"/>
                                    <span className="fs-15" style={{width: 60}}>Tardy</span>
                                </div>
                                <div className="flex items-center justify-end mb-2">
                                    <span className="chart-tags bg-virtual"/>
                                    <span className="fs-15" style={{width: 60}}>Virtual</span>
                                </div>
                                <div className="flex items-center justify-end mb-2">
                                    <span className="chart-tags bg-unmarked"/>
                                    <span className="fs-15">UnMarked</span>
                                </div>
                                {
                                    isAttendanceCounting ? <Spin/> :
                                        <div>
                                            {
                                                (staffPresent || staffAbsent || staffTardy || staffVirtual || staffUnmarked) ?
                                                    <PieChart
                                                        animate={false}
                                                        animationDuration={500}
                                                        animationEasing="ease-out"
                                                        cx={50}
                                                        cy={50}
                                                        data={[
                                                            {
                                                                color: '#3cc89c',
                                                                title: 'Present',
                                                                value: staffPresent
                                                            },
                                                            {
                                                                color: '#d12323',
                                                                title: 'Absent',
                                                                value: staffAbsent
                                                            },
                                                            {
                                                                color: 'orange',
                                                                title: 'Tardy',
                                                                value: staffTardy
                                                            },
                                                            {
                                                                color: '#8787fb',
                                                                title: 'Virtual',
                                                                value: staffVirtual
                                                            },
                                                            {
                                                                color: '#000000',
                                                                title: 'Unmarked',
                                                                value: staffUnmarked
                                                            }
                                                        ]}
                                                        label
                                                        labelPosition={50}
                                                        labelStyle={{
                                                            fill: '#121212',
                                                            fontFamily: 'sans-serif',
                                                            fontSize: '5px'
                                                        }}
                                                        lengthAngle={360}
                                                        lineWidth={15}
                                                        onClick={undefined}
                                                        onMouseOut={undefined}
                                                        onMouseOver={undefined}
                                                        paddingAngle={0}
                                                        radius={50}
                                                        rounded
                                                        startAngle={0}
                                                        style={{
                                                            height: '250px'
                                                        }}
                                                        viewBoxSize={[
                                                            100,
                                                            100
                                                        ]}
                                                    /> :
                                                    <div>Staff attendance not available</div>
                                            }
                                        </div>
                                }
                            </div>

                        </Grid>
                    </Grid>
                </div>
            </Container>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

MainDashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(MainDashboard))