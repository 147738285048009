import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Auth } from 'aws-amplify';
import {
    TextField, withStyles, Button, Typography,
    Card, CardActions, Avatar, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Container
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/LockOutlined';
import { connect } from 'react-redux';
import { updateByPropertyName } from '../utils/utils';
import authService from '../service/auth';
import { userInfoSuccess, fetchAuthUserSuccess } from '../redux/actions/current-user';
import awsConfig from '../utils/aws-config';
import * as Routes from '../routes/path';
import CacheBuster from "../component/cachebuster";
import Loader from "../assets/jss/components/Loader";
import AppHeader from '../component/Signup/appheader'
import InputAdornment from "@material-ui/core/InputAdornment";
import {TextValidator} from "react-material-ui-form-validator";
import LogoImage from '../assets/images/login.png'
const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        //background: 'url(https://source.unsplash.com/user/360memos/likes/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 600,
        minHeight : 500,
        marginTop: '3em',
        padding: '2em'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    errorMessage: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20
    },
    logoDiv : {
        justifyContent: 'center',
        display: 'flex',

    },
    logoimage : {
        position: 'relative',
        borderColor: '#6E75FE',
        height: 300,
        width : 300,
        maxHeight: 500,
        borderWidth: '1px',
    },
    button : {
        backgroundColor : '#6E75FE',
        alignItems: 'center',
        paddingLeft : 50,
        paddingRight : 50,
        width : 620,
        color : 'white',
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        },
    },
});

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (<TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
/>);


const INITIAL_STATE = {
    userName: '',
    password: '',
    errorMessage: '',
    resetErrorMessage: '',
    open: false,
    email: '',
    code: '',
    new_password: '',
    confirm_password: '',
    resetError: '',
    isMailSend: false
}

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = INITIAL_STATE
    }
    onTextChange = (event) => {
        const { name, value } = event.target;
        this.setState(updateByPropertyName(name, value));
        if (name === 'email') {
            this.setState({ isMailSend: false })
        }
        if (name === 'code' || name === 'new_password') {
            this.setState({ resetErrorMessage: '' })
        }
    }
    onFormSubmit = async (data) => {
        const { dispatch, history, location: { state: { redirectUrl = "" } = {} } = {} } = this.props;
        this.setState({isLoader: true})
        try {
            const { userName, password } = data;
            const authResponse = await authService.authenticateUserApi(userName, password);
            if (authResponse.signInUserSession) {
                const { signInUserSession } = authResponse;

                const response = await authService.getNewUserDetail();
                if (response.data && response.status === "success") {
                    dispatch(fetchAuthUserSuccess(authResponse))
                    dispatch(userInfoSuccess(response.data))
                    localStorage.setItem('hmUser', JSON.stringify(signInUserSession))
                    if((redirectUrl || "").includes("event")) {
                        history.push(redirectUrl)
                    } else {
                        history.push(Routes.ROOT);
                    }
                    this.setState({
                        isLoader: false
                    })
                } else {
                    this.setState(updateByPropertyName('errorMessage', response.errorMessage || response || "", {isLoader: false}));
                    throw response.errorMessage
                }
            } else {
                this.setState(updateByPropertyName('errorMessage', authResponse.message,{isLoader: false}));
                throw authResponse.message
            }
        } catch (error) {
            this.setState({
                isLoader: false
            })
        }
    }
    handleClickOpenModel = () => {
        this.setState({ open: true, email: '' });
    };
    handleCloseModel = () => {
        this.setState({
            open: false,
            isMailSend: false,
            resetError: '',
            resetErrorMessage: '',
            email: '',
            code: '',
            new_password: '',
        });
    };

    resetPassword = (event) => {
        event.preventDefault();
        const { email } = this.state;

        Auth.forgotPassword(email)
            .then(data => {
                this.setState({ isMailSend: true, resetError: '' })
            })
            .catch(err => {
                this.setState({ resetError: err.message || err || "" })
            });
    }

    changePassword = async (event) => {
        const { email, code, new_password } = this.state;
        // try {
        //     event.preventDefault();
        //
        //     if (email && code && new_password) {
        //         const result = await authService.forgotPasswordSubmit(email, code, new_password);
        //         this.setState({ isMailSend: false, open: false, resetErrorMessage: '' })
        //         console.log(result);
        //     } else {
        //         console.log(email, code, new_password)
        //     }
        // } catch (error) {
        //     this.setState({ resetErrorMessage: error.message })
        // }

        Auth.forgotPasswordSubmit(email, code, new_password)
            .then(data => {
                console.log(data)
                this.setState({ isMailSend: false, open: false, resetErrorMessage: '' })
            })
            .catch(err => {
                console.log(err)
                this.setState({ resetErrorMessage: err.message || err || "" })
            });

    }

    render() {
        const { classes, handleSubmit } = this.props;
        const { errorMessage, email, code, new_password, confirm_password, isMailSend, resetErrorMessage, isLoader, resetError } = this.state;
        return (
            <div>
                {isLoader ? <Loader/> : null}
                <AppHeader/>
                <div  className={classes.main}>
                <div className={classes.card}>
                    <div className={classes.header}>{awsConfig.app_header}</div>
                    <div className={classes.logoDiv}>
                   <img src={LogoImage}/>
                    </div>
                     <form onSubmit={handleSubmit(this.onFormSubmit)}>
                        <div className={classes.errorMessage} style={{color: "red"}}> {errorMessage}</div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">

                                            </InputAdornment>
                                        ),
                                    }}name="userName"
                                    component={renderInput}
                                    label="Username"
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">

                                            </InputAdornment>
                                        ),
                                    }}name="password"
                                    component={renderInput}
                                    label="Password"
                                    type="password"
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="#6E75FE"
                                className={classes.button}
                                fullWidth
                            >
                                SIGN IN
                            </Button>
                        </CardActions>
                    </form>
                    <div style={{marginLeft : 15}}>
                        By clicking Sign In, I agree to the <a href={'https://www.360memos.com/terms'}>Terms & Conditions and Privacy Policy</a>
                    </div>
                    <div style={{marginLeft : 250}}>
                        <button  style={{backgroundColor : 'white', color : 'blue'}} className="btn" onClick={this.handleClickOpenModel}>Forget Password</button>
                        {/*<button className="btn btn-link" onClick={() => this.props.history.push('/signup')}>Register</button>*/}
                    </div>
                </div>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleCloseModel}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isMailSend ? "Please enter the code you received via Email" : "To reset password, please enter your registered email"}
                    </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            value={email}
                            name="email"
                            label="Email Address"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">

                                    </InputAdornment>
                                ),
                            }}type="email"
                            onChange={this.onTextChange}
                            fullWidth
                        />
                        <Typography color="error">{resetError || ""}</Typography>
                        {isMailSend && (
                            <Fragment>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    value={code}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">

                                            </InputAdornment>
                                        ),
                                    }}name="code"
                                    label="Code"
                                    type="number"
                                    onChange={this.onTextChange}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    value={new_password}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">

                                            </InputAdornment>
                                        ),
                                    }}name="new_password"
                                    label="New Password"
                                    type="password"
                                    onChange={this.onTextChange}
                                    fullWidth
                                />
                                <TextField
                                    margin="dense"
                                    value={confirm_password}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">

                                            </InputAdornment>
                                        ),
                                    }}name="confirm_password"
                                    label="Confirm Password"
                                    type="password"
                                    onChange={this.onTextChange}
                                    fullWidth
                                />
                            </Fragment>
                        )}
                        <Typography color="error">{resetErrorMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseModel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={isMailSend ? this.changePassword : this.resetPassword} color="primary" disabled={isMailSend ? new_password !== confirm_password : false}>
                            {isMailSend ? 'Change Password' : 'Reset Password'}
                        </Button>
                    </DialogActions>

                </Dialog>


                <CacheBuster>
                    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                        if (loading) return null;
                        if (!loading && !isLatestVersion) {
                            // You can decide how and when you want to force reload
                            refreshCacheAndReload();
                        }

                        return (
                            <div className="App">

                                <p>
                                        version - <code>v{global.appVersion}</code>
                                    </p>

                            </div>
                        );
                    }}
                </CacheBuster>
                </div>
            </div>
        );
    }
}

const loginForm = reduxForm({
    form: 'loginForm',
    enableReinitialize: true,
})(Login)

const mStyles = withStyles(styles)(loginForm);

const mapStateToProps = state => ({ state });

export default connect(mapStateToProps)(mStyles);

