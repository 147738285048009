import React from 'react';
import {connect} from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import {Input, List, Modal, Spin, Icon, Tag} from 'antd'
import {Grid, Container} from "@material-ui/core";
import {
    getFileUrl,
    ImageUpload,
    SelectLabelInput,
    TextLabelInput,
    uploadFile,
    DateLabelInput,
    openNotificationWithIcon,
    getSchoolId,
    capitalizeFirstLetter,
    NumberLabelInput,
    SelectUserRoleInput,
    getUserRole,
} from "../../../GlobalComponents/GlobalFields";
import moment from "moment/moment";
import {
    countryOptionList,
    teacherLevelList
} from "../../../GlobalComponents/optionValues";
import { updateUser } from "../../../../ApiServices";

const { TextArea } = Input;

class StaffEditProfileModal extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            fields: {},
            staffDetails: {},
            errors: {},
            isImageUploading: false,
            isSaving: false,
            certification: '',
            role: [],
            groups: []
        }
    }

    componentWillMount() {
        const { attributes = {}, staffDetails = {} } = this.props
        //console.log("attributesattributes",attributes)
        let role = []
        let groups = []
        const object = (staffDetails.role || {})[getSchoolId()] || {}
        Object.keys(object || {}).forEach(x => {
            if(staffDetails.role[getSchoolId()][x]) {
                role.push(x)
            }
        })
        {(staffDetails.group_ids || []).map(group => {
            if(group === attributes.homeRoom) return
            groups.push(group)
        })}
        this.setState({
            fields: cloneDeep(attributes),
            staffDetails,
            role,
            groups
        })
    }

    onProfileChange = async (info) => {
        const { fields } = this.state
        if (info.file.status === 'uploading') {
            this.setState({isImageUploading: true})
            return;
        }

        if (info.file.status === 'done') {
            let key = await uploadFile (info.file.originFileObj);
            let imageURL = await getFileUrl (key);
            this.setState({
                fields: {
                    ...fields,
                    profile_photo: imageURL
                },
                isImageUploading: false
            })
        }
    }

    onChange = (event) => {
       const { fields } = this.state
       const {name, value} = event.target
       this.setState({
           fields: {
               ...fields,
               [name]: value
           }
       })
    }

    onUpdateProfile = async () => {
        const { partEdit } = this.props
        const { fields, staffDetails, role, groups } = this.state

        let object = {}
        if(!fields.homeRoom && partEdit === 1) {
            object.homeRoomError = true
        } else {
            object.isSaving = true
        }

        this.setState({
            ...object
        })
        if(object.homeRoomError) {
            return openNotificationWithIcon('error', 'Fields required!')
        }

        let payload = {...staffDetails, attributes: fields, username: staffDetails.attributes.email, user_id: staffDetails.id}

        if(partEdit === 1) {
            let group_ids = []
            if((groups || []).length) {
                if((groups || []).includes(fields.homeRoom)) {
                    group_ids = groups
                } else {
                    group_ids = (groups || []).concat([fields.homeRoom])
                }
            } else {
                group_ids = [fields.homeRoom]
            }
            payload.group_ids = group_ids
            // payload.attributes.groups = fields.groups
            payload.attributes.homeRoom = fields.homeRoom
        }

        payload.role = {
        ...payload.role,
            [getSchoolId()]: getUserRole(role)
        }

        const response = await updateUser(payload)
        if(response && response.status === "success") {
            openNotificationWithIcon('success', response.message)
            this.setState({
                isSaving: false
            })
            this.props.onUpdateProfile(payload)
        } else {
            openNotificationWithIcon('error', response.message || 'Something went wrong')
            this.setState({
                isSaving: false
            })
        }
    }

    addCertification = () => {
        const { fields, certification} = this.state
        if(!certification) return;
        if(!(fields.certificationList || []).length) {
            fields.certificationList = []
        }
        fields.certificationList.push(certification)
        this.setState({
            fields,
            certification: ""
        });
    };

    deleteCertification = (index) => {
        const { fields } = this.state
        fields.certificationList.splice(index,1)
        this.setState({
            fields,
        });
    };

    onAddressChange = (event) => {
        const { name, value } = event.target
        let { fields } = this.state
        this.setState({
            fields: {
                ...fields,
                primaryAddress: {
                    ...fields.primaryAddress,
                    [name]: value
                }
            }
        })
    }

    render() {
        const { isOpen, handleModal, currentUser: { newUserInfo: { groupcache: groupList = [] } }, partEdit } = this.props
        const { fields, errors, isImageUploading, isSaving, certification, homeRoomError = false, role = [], groups = [] } = this.state
        const { image, profile_photo, firstName, lastName, email, mobile, dateOfBirth, homeRoom, teacherLevel, notes, certificationList, hireDate, hours, primaryAddress = {} } = fields || {}
        const { address1 = "", address2 = "", address3 = "", city = "", state = "", postalCode = "",  country = "" } = primaryAddress || {}
        return(
            <Modal
                title="Edit Profile "
                width={750}
                visible={isOpen}
                onOk={this.onUpdateProfile}
                onCancel={() => handleModal(null)}
                okText={"Save"}
                okButtonProps={isSaving ? {loading: <Spin />} : {}}
            >
                <div>
                    <Container>

                        {
                            partEdit === 1 ?
                                <div>
                                    <ImageUpload
                                        isImgUpload={isImageUploading}
                                        imageUrl={profile_photo || image}
                                        handleImage={this.onProfileChange}
                                    />
                                </div> : null
                        }

                        <Grid container spacing={6} style={{marginTop: 0}}>

                            {
                                partEdit === 1 ?
                                    <>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="firstName"
                                                value={firstName}
                                                onChange={this.onChange}
                                                placeholder="First Name"
                                                label="First Name"
                                                required
                                                error={errors && errors.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="lastName"
                                                value={lastName}
                                                onChange={this.onChange}
                                                placeholder="Last Name"
                                                label="Last Name"
                                                required
                                                error={errors && errors.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="email"
                                                value={email}
                                                onChange={this.onChange}
                                                placeholder="Email"
                                                label="Email"
                                                required
                                                disabled
                                                error={errors && errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Home Room"
                                                name="homeRoom"
                                                onChange={(value) => this.onChange({target: {name: "homeRoom", value}})}
                                                placeholder="Select a home room"
                                                options={groupList || {}}
                                                value={homeRoom || ""}
                                                required
                                                error={homeRoomError ? "Home room required" : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectUserRoleInput
                                                label="Role/Access"
                                                name="role"
                                                onChange={(value) => this.setState({role: value})}
                                                placeholder="Select a role"
                                                value={role || []}
                                                required
                                                error={errors && errors.role}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Teacher Level"
                                                name="teacherLevel"
                                                onChange={(value) => this.onChange({target: {name: "teacherLevel", value: (value || "").toLowerCase()}})}
                                                placeholder="Select a Teacher Level"
                                                required
                                                options={teacherLevelList || []}
                                                value={capitalizeFirstLetter(teacherLevel) }
                                                error={errors && errors.teacherLevel}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberLabelInput
                                                className="w-100"
                                                placeholder="Mobile"
                                                name="mobile"
                                                value={mobile}
                                                onChange={(value)=> this.onChange({target: {name: "mobile", value}})}
                                                label="Mobile"
                                                // required
                                                error={errors && errors.mobile}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DateLabelInput
                                                label="Date Of Birth"
                                                name="dateOfBirth"
                                                onChange={(date, dateString) => this.onChange({target: {name: "dateOfBirth", value: dateString}})}
                                                value={dateOfBirth ? moment(dateOfBirth) : null}
                                                placeholder="Date Of Birth"
                                                // required
                                                error={errors && errors.dateOfBirth}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Group"
                                                mode="multiple"
                                                name="groups"
                                                // required
                                                onChange={(value) => this.setState({groups: value})}
                                                placeholder="Group"
                                                options={groupList || {}}
                                                value={groups}
                                                error={errors && errors.groups}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="multiExpLblBlk font-bold text-10">Notes</div>
                                            <TextArea
                                                value={notes}
                                                name="notes"
                                                placeholder="notes"
                                                onChange={this.onChange}
                                                rows={4}/>
                                        </Grid>
                                    </> : null
                            }

                            {
                                partEdit === 2 ?
                                    <>
                                        <Grid item xs={12}>
                                            <TextLabelInput
                                                name="certification"
                                                value={certification}
                                                onChange={(event) => this.setState({certification: event.target.value})}
                                                placeholder='Add Certification'
                                                label='Certifications'
                                                required
                                                // error={staffErrors.certificationsList}
                                                addonAfter={<span onClick={this.addCertification}>+</span>}
                                            />
                                            <br/>
                                            {
                                                (certificationList || []).length > 0 &&
                                                    <List
                                                        size="small"
                                                        header={<b>Certifications List</b>}
                                                        bordered
                                                        dataSource={certificationList}
                                                        renderItem={(item, index) => (
                                                            <List.Item
                                                            >
                                                                <List.Item.Meta title={item}/>
                                                                <div>
                                                                    <Icon
                                                                        type="delete"
                                                                        className="color-red fs-18 cursor-pointer"
                                                                        onClick={() => this.deleteCertification(index)}
                                                                    />
                                                                </div>
                                                            </List.Item>
                                                        )}
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DateLabelInput
                                                label="Hire Date"
                                                name="hireDate"
                                                onChange={(date, dateString) => this.onChange({
                                                    target: {
                                                        name: "hireDate",
                                                        value: dateString
                                                    }
                                                })}
                                                placeholder="Hire Date"
                                                required
                                                value={hireDate ? moment(hireDate) : null}
                                                // error={staffErrors && staffErrors.hireDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberLabelInput
                                                name="hours"
                                                value={hours}
                                                onChange={(value) => this.onChange({target: {name: "hours", value}})}
                                                placeholder="Hours"
                                                label="Professional Development Hours"
                                                required
                                                addonAfter="Hrs"
                                                // error={staffErrors && staffErrors.hours}
                                            />
                                        </Grid>
                                    </> : null
                            }

                            {
                                partEdit === 3 ?
                                    <Grid container spacing={6} >
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="address1"
                                                value={address1 || ""}
                                                onChange={this.onAddressChange}
                                                placeholder="Address1"
                                                label="Address 1"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="address2"
                                                value={address2 || ""}
                                                onChange={this.onAddressChange}
                                                placeholder="Address2"
                                                label="Address 2"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="address3"
                                                value={address3 || ""}
                                                onChange={this.onAddressChange}
                                                placeholder="Address3"
                                                label="Address 3"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="city"
                                                value={city || ""}
                                                onChange={this.onAddressChange}
                                                placeholder="City"
                                                label="City"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="state"
                                                value={state || ""}
                                                onChange={this.onAddressChange}
                                                placeholder="State"
                                                label="State"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextLabelInput
                                                name="postalCode"
                                                value={postalCode || ""}
                                                onChange={this.onAddressChange}
                                                placeholder="Postal Code"
                                                label="Postal Code"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectLabelInput
                                                label="Country"
                                                name="country"
                                                onChange={(value) => this.onAddressChange({target: {name: 'country', value}})}
                                                placeholder="Select a country"
                                                options={countryOptionList || []}
                                                value={country || ""}
                                            />
                                        </Grid>

                                    </Grid> : null
                            }

                        </Grid>

                    </Container>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser
})

export default connect(mapStateToProps, null)(StaffEditProfileModal)