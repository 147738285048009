import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {connect} from "react-redux";
import _ from "lodash";
import {Select} from 'antd'
import recordPlannerService from '../../../service/recordplanner'
const Option = {Select}

const Container = styled.div`
  width: 100%;
  height: 45px;
  background-color: white;
  display: flex;
  position: absolute;
`;

const SelectClass = styled.select`
  width: fit-content;
  margin: auto 1%;
  padding: 0 0.5em;
  background-color: white;
  cursor: pointer;
`;

const Button = styled.div`
  width: fit-content;
  margin: auto 1%;
  padding: 0 0.5em;
  background-color: white;
  height: 80%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  ${(props) =>
          props.disabled &&
          `
      opacity: 0.5;
    `}
`;

const DayPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-color: white;
  z-index: 1;
  color : blue;
  cursor: pointer;
`;

const DateView = styled.div`
  padding: 0 0.5em;
`;

const Topbar = ({
                    datesData,
                    setSelectedDays,
                    selectedDays,
                    getWeekDays,
                    getWeekRange,groupcache, setClass
                }) => {
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(parseInt(localStorage.getItem('p_gnum')));
    const [selectedDateRange, setSelectedDateRange] = useState(datesData[0]);
    const [hoverRange, setHoverRange] = useState(null);
    const [showCalendar, toggleCalendar] = useState(false);

    const handleDayChange = (date) => {
        setSelectedDays(getWeekDays(getWeekRange(date).from));
        // toggleCalendar(!showCalendar);
    };

    const handleDayEnter = (date) => {
        setHoverRange(getWeekRange(date));
        // toggleCalendar(!showCalendar);
    };

    useEffect(()=> {
        let grps = []
        let grps2 = []
        Object.keys(groupcache).map((grpid)=> {
            if (!groupcache[grpid].attributes.is_archive && groupcache[grpid].attributes.is_recordplan) {
                grps.push({id: grpid, value: groupcache[grpid].attributes.groupname})
            }
        })

        grps =  _.orderBy(grps,  ['value'], ['asc'])

        setClasses(grps)
        setSelectedClass(parseInt(localStorage.getItem('p_gnum')))
        setClass(parseInt(localStorage.getItem('p_gnum')))

    }, [])

    const handleDayLeave = () => {
        setHoverRange(null);
        // toggleCalendar(!showCalendar);
    };

    const handleWeekClick = (weekNumber, days, e) => {
        setSelectedDays(days);
        // toggleCalendar(!showCalendar);
    };

    const onClick = (e) => {
        // if (e.target.childNodes[0].data === 'Next') {
        //   let index = this.props.datesData.indexOf(this.state.selectedDateRange);
        //   this.setState({
        //     ...this.state,
        //     selectedDateRange: this.props.datesData[index + 1],
        //   });
        // } else {
        //   let index = this.props.datesData.indexOf(this.state.selectedDateRange);
        //   this.setState({
        //     ...this.state,
        //     selectedDateRange: this.props.datesData[index - 1],
        //   });
        // }
    };

    const onDateRangeChange = (e) => {
        // this.setState({...this.state,})
    };
    console.log('selectedClass', selectedClass)
    const daysAreSelected = selectedDays.length > 0;
    return (
        <Container>
            <div style={{marginTop : 10, marginLeft : 3, marginRight : 5}}>Groups : </div>
            <Select style={{marginTop : 5, width : 300}}

                defaultValue={selectedClass.toString()}
                onChange={(value) => {
                    setClass(value)  ;
                    localStorage.setItem('p_gnum', value);
                    setSelectedClass(value);
                }}>
                {classes.map((_class, index) => (
                    <Option value={_class.id} key={_class.id}>
                        {_class.value}
                    </Option>
                ))}
            </Select>
            <DayPickerWrapper>
                <DateView
                    onClick={() => {
                        toggleCalendar(!showCalendar);
                    }}>
                    {moment(selectedDays[0]).format('LL')} –{' '}
                    {moment(selectedDays[6]).format('LL')}
                </DateView>
                {showCalendar && (
                    <>
                        <DayPicker
                            selectedDays={selectedDays}
                            showWeekNumbers
                            showOutsideDays
                            modifiers={{
                                hoverRange,
                                selectedRange: daysAreSelected && {
                                    from: selectedDays[0],
                                    to: selectedDays[6],
                                },
                                hoverRangeStart: hoverRange && hoverRange.from,
                                hoverRangeEnd: hoverRange && hoverRange.to,
                                selectedRangeStart: daysAreSelected && selectedDays[0],
                                selectedRangeEnd: daysAreSelected && selectedDays[6],
                            }}
                            onDayClick={handleDayChange}
                            onDayMouseEnter={handleDayEnter}
                            onDayMouseLeave={handleDayLeave}
                            onWeekClick={handleWeekClick}
                            dayPickerProps={{
                                month: new Date(2018, 10),
                                showWeekNumbers: true,
                                todayButton: 'Today',
                            }}
                        />
                        <button onClick={() => {
                            toggleCalendar(!showCalendar);
                        }}>Close</button>
                    </>
                )}
            </DayPickerWrapper>
            {/*<Button*/}
            {/*    onClick={() => {*/}
            {/*        console.log('By Date');*/}
            {/*    }}>*/}
            {/*    By date*/}
            {/*</Button>*/}
            {/*<Button disabled>Child boxes</Button>*/}
            {/* <p>hi</p> */}
            <Helmet>
                <style>{`
            .SelectedWeekExample .DayPicker-Month {
              border-collapse: separate;
            }
            .SelectedWeekExample .DayPicker-WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .DayPicker-Day {
              outline: none;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange {
              background-color: #EFEFEF !important;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange {
              background-color: #fff7ba !important;
              border-top-color: #FFEB3B;
              border-bottom-color: #FFEB3B;
              border-left-color: #fff7ba;
              border-right-color: #fff7ba;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
              background-color: #FFEB3B !important;
              border-left: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
              background-color: #FFEB3B !important;
              border-right: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
            .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
              border-radius: 0 !important;
              color: black !important;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
              border-radius: 0 !important;
            }
          `}</style>
            </Helmet>
        </Container>
    );
};

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)

