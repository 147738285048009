import React from "react"
import {connect} from "react-redux";
import {Avatar, Button, List, Spin} from "antd";
import {getColor, getSchoolId, openNotificationWithIcon} from "../../../../../GlobalComponents/GlobalFields";
import {Grid} from "@material-ui/core";
import CreateParent from "../../../../../Parents/components/CreateParent";
import {inviteUser, getUserDetails} from "../../../../../../ApiServices";


class Parent extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading: true,
            searchKeys: "",
            childList: []
        }
    }

    async componentWillMount() {
        this.onSetChildList()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.onSetChildList()
    }

    onSetChildList = () => {
        let { selectedParent = [], currentUser: { newUserInfo: { childList = [] } } } = this.props || {};
        childList = childList.filter(x => selectedParent.some(y =>  (x.parents || []).includes(y.id)))
        this.setState({
            isLoading: false,
            selectedParent,
            childList
        })
    }

    fetchStudentsList = () => {
        this.props.fetchGroupData()
    }

    getUserDetails = async (userId) => {
        const payload = {
            school_id: getSchoolId(),
            user_id: userId
        }
        const userResponse = await getUserDetails(payload)
        if(userResponse && userResponse.status === "success") {
            return userResponse.data
        }
        return {}
    }

    onInviteUser = async (record, index) => {
        let { childList } = this.state
        const childIndex = (childList || []).findIndex(x => x.id === record.id)
        const key = index === 0 ? "parent1" : "parent2"
        if(childIndex !== -1) {
            childList[childIndex][key] = true
        }
        this.setState({
            childList
        })

        if(record.parents[index]) {
            const response = await this.getUserDetails(record.parents[index])

            if(response && Object.keys(response).length) {
                const payload = {
                    school_id: getSchoolId(),
                    username: response.attributes.email || ""
                }

                const invite = await inviteUser(payload)
                if(invite && invite.status === "success") {
                    openNotificationWithIcon('success', 'invitation sent successfully')
                    childList[childIndex][key] = false
                    this.setState({
                        childList
                    })
                    this.fetchStudentsList()
                } else {
                    openNotificationWithIcon('error', invite.message || 'something went wrong')
                    childList[childIndex][key] = false
                    this.setState({
                        childList
                    })
                }
            }
        }
    }

    render() {
        const { currentUser: { newUserInfo: { usercache } }, parentLoad, selectedStudent } = this.props;
        const { isLoading, childList, selectedParent } = this.state
        return(
            <div>
                <Grid className="rounded-1rem bg-white shadow mt-8 parent-content">
                    <div className="data-table-header">
                        <List.Item>
                            <List.Item.Meta title={"Student Name"}/>
                            <List.Item.Meta title={"Parent Name"}/>
                            <List.Item.Meta title={"User Registration"}/>
                        </List.Item>
                    </div>
                    <Grid item xs={12}>
                        <div className="student-dashboard-infinite-container">
                            <List
                                className="demo-loadmore-list"
                                loading={isLoading || parentLoad}
                                itemLayout="horizontal"
                                dataSource={selectedStudent || []}
                                renderItem={(item, index) => {
                                    const { image = "", profile_photo = "", firstName = "", lastName = "" } = item || {}
                                    return(
                                        <List.Item className="cursor-pointer">
                                            <List.Item.Meta
                                                avatar={
                                                    (profile_photo || image) ?
                                                        <Avatar src={profile_photo || image} /> :
                                                        <Avatar
                                                            aria-label="recipe"
                                                            style={{backgroundColor: getColor(index)}}
                                                        >
                                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                        </Avatar>
                                                }
                                                title={
                                                    <div
                                                        className="mt-3 fs-16"
                                                        onClick={() => this.props.history.push(`/student/view/${item.id}`)}
                                                    >
                                                        {firstName} {lastName}
                                                    </div>
                                                }
                                            />

                                            <List.Item.Meta
                                                title={
                                                    <div>
                                                        {
                                                            (item.parents || []).map((val, ind) => {
                                                                // if(!(selectedParent || []).includes(val)) {
                                                                //     return
                                                                // }
                                                                const currentParent = (usercache && usercache[val]) || {}
                                                                const { firstName = "", lastName = "", profile_photo = "", image = "" } = currentParent || {}
                                                                return(
                                                                    <div key={ind}>
                                                                        <div className="inline-flex" onClick={() => this.props.history.push(`/parent/view/${val}`)}>
                                                                            {
                                                                                (profile_photo || image) ?
                                                                                    <Avatar src={profile_photo || image} style={{width: 35, height: 35}}/> :
                                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index), width: 35, height: 35, lineHeight: 2.5}}>
                                                                                        {`${(firstName && firstName.toString() || "").charAt(0).toUpperCase()}${(lastName && lastName.toString() || "").charAt(0).toUpperCase()}`}
                                                                                    </Avatar>
                                                                            }
                                                                            <p className="m-2">{`${firstName && firstName.toString() || ""} ${lastName && lastName.toString() || ""}`}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {((item.parents || []).length === 0 || (item.parents || []).length === 1) && <CreateParent length={(item.parents || []).length || 0} childId={item.id} fetchStudentsList={this.fetchStudentsList}/>}
                                                    </div>
                                                }
                                            />

                                            <List.Item.Meta
                                                title={
                                                    <div>
                                                        {
                                                            (item.parents || []).map((val, index) => {
                                                                const currentParent = (usercache && usercache[val]) || {}
                                                                const { invited = false, active = false } = currentParent || {}
                                                                return(
                                                                    <div key={index.toString()} style={index === 0 ? {marginBottom: 5} : {}}>
                                                                        {
                                                                            (item.parent1 === true && index === 0 || item.parent2 === true && index === 1) ?
                                                                                <Spin/> :
                                                                                <>
                                                                                    {
                                                                                        invited && active ?
                                                                                            <span>Active</span> :
                                                                                            invited ?
                                                                                                <>
                                                                                                    <div className="color-green">Invitation Sent</div>
                                                                                                    <Button size='small' onClick={() => this.onInviteUser(item, index)}>Resend Invite</Button>
                                                                                                </> :
                                                                                                <Button size='small' onClick={() => this.onInviteUser(item, index)}>Invite</Button>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            />

                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

export default connect(mapStateToProps,null)(Parent)