import React from "react"
import {Button, Table } from 'antd';
import {Grid} from "@material-ui/core";
import {SelectLabelInput, TextLabelInput} from "./GlobalFields";
import {countryOptionList, primaryInfo} from "./optionValues";
import {validateData} from "./validation";

class SecondaryAddress extends React.Component{
    state = {
        secondaryAddressFields: {
            address1: '',
            address2: '',
            address3: '',
            city: '',
            state: '',
            postalCode: '',
            country: ''
        },
        secondaryAddressErrors: {},
        secondaryAddressList: []
    }

    handelOnChange = (e, type) =>{
        const { secondaryAddressFields } = this.state
        let values = {}
        if(type === "primary"){
            values = {
                secondaryAddressFields: {
                    ...secondaryAddressFields,
                    [e.target.name]: e.target.value
                }
            }
        }
        this.setState({...values})
    }

    handelSelect = (key, val, type) =>{
        let select = {}
        const { secondaryAddressFields } = this.state
        if(type === "primary"){
            select = {
                secondaryAddressFields: {
                    ...secondaryAddressFields,
                    [key]: val
                }
            }
        }else if(type === "userInfo") {
            const addressInfo = primaryInfo.find(p => p.name === val)
            select = {
                secondaryAddressFields:{
                    ...addressInfo
                },
                [key]: val
            }
        }
        this.setState({...select})
    }

    handelSecondarySubmit = () =>{
        const { secondaryAddressFields, secondaryAddressList } = this.state

        let validationErrors = {};
        Object.keys(secondaryAddressFields).forEach(name => {
            const error = validateData(name, secondaryAddressFields[name]);
            if (error && error.length > 0) {
                validationErrors[name] = error;
            }
        });

        if (Object.keys(validationErrors).length > 0) {
            this.setState({ secondaryAddressErrors: validationErrors });
            return;
        }

        if(secondaryAddressFields.address1 && secondaryAddressFields.city && secondaryAddressFields.postalCode && secondaryAddressFields.state){
            this.props.handelSecoundaryAdd(secondaryAddressFields)
            secondaryAddressList.push(secondaryAddressFields)
            this.setState({
                secondaryAddressFields: {},
                secondaryAddressErrors: {},
                secondaryAddressList: secondaryAddressList || [],
            })
        }
    }

    render(){
        const {secondaryAddressFields, secondaryAddressErrors, secondaryAddressList} = this.state
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address1',
            },
            {
                title: 'City',
                dataIndex: 'city',
            },
            {
                title: 'State',
                dataIndex: 'state',
            },
            {
                title: 'Postal Code',
                dataIndex: 'postalCode',
            },
        ];

        return(
            <div>
                <Grid container spacing={6} style={{marginTop: 0}}>
                    <Grid item xs={6}>
                        <div className="p-card-title">
                            <TextLabelInput
                                name="address1"
                                value={secondaryAddressFields.address1}
                                onChange={(e)=>this.handelOnChange(e, 'primary')}
                                placeholder="Address1"
                                label="Address 1"
                                // required
                                error={secondaryAddressErrors && secondaryAddressErrors.address1}
                            />
                        </div>
                        <div className="p-card-title">
                            <TextLabelInput
                                name="address2"
                                value={secondaryAddressFields.address2}
                                onChange={(e)=>this.handelOnChange(e, 'primary')}
                                placeholder="Address2"
                                label="Address 2"
                            />
                        </div>
                        <div className="p-card-title">
                            <TextLabelInput
                                name="address3"
                                value={secondaryAddressFields.address3}
                                onChange={(e)=>this.handelOnChange(e, 'primary')}
                                placeholder="Address3"
                                label="Address 3"
                            />
                        </div>
                        <div className="p-card-title">
                            <TextLabelInput
                                name="city"
                                value={secondaryAddressFields.city}
                                onChange={(e)=>this.handelOnChange(e, 'primary')}
                                placeholder="City"
                                label="City"
                                // required
                                error={secondaryAddressErrors && secondaryAddressErrors.city}
                            />
                        </div>
                        <div className="p-card-title">
                            <TextLabelInput
                                name="state"
                                value={secondaryAddressFields.state}
                                onChange={(e)=>this.handelOnChange(e, 'primary')}
                                placeholder="State"
                                label="State"
                                // required
                                error={secondaryAddressErrors && secondaryAddressErrors.state}
                            />
                        </div>
                        <div className="p-card-title">
                            <TextLabelInput
                                name="postalCode"
                                value={secondaryAddressFields.postalCode}
                                onChange={(e)=>this.handelOnChange(e, 'primary')}
                                placeholder="Postal Code"
                                label="Postal Code"
                                // required
                                error={secondaryAddressErrors && secondaryAddressErrors.postalCode}
                            />
                        </div>
                        <div className="p-card-title">
                            <SelectLabelInput
                                label="Country"
                                name="country"
                                onChange={(val)=>this.handelSelect("country", val, 'primary')}
                                placeholder="Select a country"
                                options={countryOptionList || []}
                                value={secondaryAddressFields && secondaryAddressFields.country}
                            />
                        </div>
                        <br/>
                        <div className="text-right">
                            <Button className="add-button" onClick={this.handelSecondarySubmit}>
                                Add
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="p-card-title">
                            Added Secondary Address
                        </div>
                        <div>
                            <Table columns={columns} dataSource={secondaryAddressList} size="middle" />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default SecondaryAddress







