import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import AsyncImage from "../../common/reduxForm/AsyncImage";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { split } from 'lodash'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SignupDialog from './SignupDialog';
import * as Route from "../../../routes/path";
import Moment from "react-moment";
import { Tooltip, Avatar, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import { Popconfirm } from "antd";
import renderHTML from 'react-render-html';
import { getCurrentSchoolId, getTimeFormat, getDateFormat } from "../../../utils/utils";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    cover: {
        height: 400,
        margin: "-50px 0px",
    },
    sidebarNotes: {
        margin: '10px',
        border: '1px solid',
        width: '92%',
        padding: '10px',
        minHeight: '130px'
    },
    sidebarSection: {
        margin: '10px',
    },
    control: {
        marginTop: 10,
        minHeight: 250
    },
    centerContent: {
        width: "100%",
        margin: "20px auto",
    },
    dateTimeCard: {
        padding: "0 0 20px 0",
        boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 0.15)",
        borderRadius: "12px"
    },
    dateTitme: {
        fontSize: 18,
        fontWeight: "bold",
        color: "#05b9a4",
        padding: "10px 10px 10px 40px"
    },
    table: {
        minWidth: 620,
        // margin: "10px",
        // width: "95% !important"
    },
    customthead: {
        fontSize: 16
    },
    avatar: {
        float: "left",
        margin: "2px",
        fontSize: "14px",
        height: "20px",
        width: "20px",
    },
    innercell: {
        // borderBottom: "0px"
        width: "200px",
        padding: "5px"
    }
});

function isSlotFull(userResponse, slotData) {
    const isFull = userResponse.find(res => res.slotId === slotData.slotId)
        ? userResponse.find(res => res.slotId === slotData.slotId)
            .childs.length > 0 : false

    return isFull
}

class SignupParentTeacher extends Component {

    state = {
        image: "https://picsum.photos/200/300/?blur",
        value: 0,
        event_id: '',
        dates: [],
        open: false,
        anchorEl: null,
        slotDetails: {}
    }

    componentDidMount() {
        this.setEventId();
        this.props.resetStepperActiveStep();
    }

    setEventId() {
        const event_id = split(this.props.location.pathname, '/')[3];
        this.props.getEventById.request({ event_id });
        // this.props.fetchEventResponseAction.request({ event_id })
        this.setState({ event_id })
    }

    getStripedStyle = (index) => {
        return { background: index % 2 ? '#f4f4f4' : 'white' };
    }

    editDetails = () => {
        const { event } = this.props
        this.props.setInitialValue(event)
        this.props.history.push(Route.MEETING_EVENT + '/' + (event.event_id || event.id));
    }

    removeChild = (childId, time) => {
        const { removeChildEventAction, event = {}, currentUser = {} } = this.props;

        const eventDate = getDateFormat(time.event_date)
        const startTime = getTimeFormat(time.start_time)
        const endTime = getTimeFormat(time.end_time)

        const body = {
            school_id: getCurrentSchoolId(currentUser),
            for_user: currentUser.id,
            event_id: event.id,
            new_date: eventDate,
            new_time: `${startTime}-${endTime}`,
            // child_id: "",
            child_id_remove: childId
        }

        removeChildEventAction.request(body)
    }

    assignChild = (slot) => (e) => {
        this.setState({ open: true, slotDetails: slot });
    }

    handleDeleteSlot = (slotId) => (e) => {
        const { deleteTimeSlot, event } = this.props;
        const body = {
            eventid: event[0].event_id,
            slotId,
        }
        deleteTimeSlot.request(body);
    }

    onClose = (value) => {
        this.setState({ open: false });
        const { setEventResponse, event = {}, currentUser } = this.props;
        const { slotDetails = {} } = this.state;

        if (value) {

            const event_date = getDateFormat(slotDetails.event_date)
            const startTime = getTimeFormat(slotDetails.start_time)
            const endTime = getTimeFormat(slotDetails.end_time)

            const body = {
                school_id: getCurrentSchoolId(currentUser),
                for_user: currentUser.id,
                event_id: event.id,
                new_date: event_date,
                new_time: `${startTime}-${endTime}`,
                child_id: value.id,
                child_id_remove:""
            }
            setEventResponse.request(body)

            this.setState({
                slotDetails: {}
            })
        }
    }

    getChildList = (child_ids) => {
        const { childList } = this.props
        const list = (childList || []).filter(x => ((child_ids || []).includes(x.id))) || []
        return list || []
    }

    render() {
        const { classes, event, eventResponse: { userResponse = [] }, childList = [] } = this.props;
        const { open } = this.state;
        const { attributes: { event_title = "", location = "", event_description = "", event_img_url = [] } = {} } = event || {};

        return (
            <div className={classes.root} >
                {event &&
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12} className={classes.control}>
                            <Grid container spacing={5}>
                                <Grid item xs={4}>
                                    <Card className="cardComponent">
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} className="centerdiv">
                                                <AsyncImage className={classes.cover} src={(event_img_url || []).length && event_img_url[0].key || ""} />
                                            </Grid>
                                            <Grid item xs={12} className={classes.sidebarSection}>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogTitle"
                                                >
                                                    {event_title}
                                                </Typography>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogSubTitle"
                                                >
                                                    Location - {location}
                                                </Typography>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    className="meetingDialogSubTitle"
                                                >
                                                    Notes -
                                                </Typography>
                                                <div className={classes.sidebarNotes}>
                                                    {event_description && <div className={classes.description}> {renderHTML(event_description)} </div>}
                                                </div>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    className="editMeetingbutton meetingEditButton"
                                                    value="yes"
                                                    onClick={this.editDetails}
                                                >
                                                    Edit Details
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography
                                        color="primary"
                                        variant="h6"
                                        className="meetingDialogTitle"
                                    >
                                        Conference Spots Summary
                                        </Typography>

                                    {(event.parentTeacherResponse || []).map((date, index) => (
                                        <Grid container key={index}>
                                            <Grid item xs={12} className={classes.centerContent}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={12} className="centerdatetime">
                                                        <Card className={classes.dateTimeCard}>
                                                            <Grid container>
                                                                <Grid item xs={4} className={classes.dateTitme}>
                                                                    <Moment format="ddd D, MMMM YYYY" date={date.event_date} />
                                                                </Grid>
                                                            </Grid>
                                                            <Table className={classes.table} fixedheader="false" style={{ width: "auto", tableLayout: "auto" }}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.customthead}>Time</TableCell>
                                                                        <TableCell className={classes.customthead}>Filled</TableCell>
                                                                        <TableCell className={classes.customthead}>Participant</TableCell>
                                                                        <TableCell className={classes.customthead}>Action</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {date.slotTime.map((time, index) =>
                                                                        <TableRow key={index} style={{ ...this.getStripedStyle(index) }}>
                                                                            <TableCell className={classes.innercell}>
                                                                                <Moment format="hh:mm a" date={time.start_time} />
                                                                                -
                                                                                <Moment format="hh:mm a" date={time.end_time} />

                                                                            </TableCell>
                                                                            <TableCell className={classes.innercell} >
                                                                                {`${(time.child_ids || []).length ? (this.getChildList(time.child_ids) || []).length : 0} Out of 1`}
                                                                            </TableCell>
                                                                            <TableCell className={classes.innercell} >
                                                                                <div style={{ display: "flex" }}>
                                                                                    { !!(this.getChildList(time.child_ids) || []).length && (this.getChildList(time.child_ids) || []).map((child, index) =>
                                                                                        (
                                                                                            <div key={child.childid} style={{ display: "flex" }}>
                                                                                                <Tooltip title={`${child.firstName || ""} ${child.lastName}`}>
                                                                                                    <Avatar >
                                                                                                        {(child.firstName || " ")[0].toUpperCase()}
                                                                                                    </Avatar>
                                                                                                </Tooltip>
                                                                                                <Popconfirm
                                                                                                    title="Are you sure delete this child?"
                                                                                                    onConfirm={() => this.removeChild(child.id, {...time, event_date: date.event_date})}
                                                                                                    okText="Yes"
                                                                                                    cancelText="No"
                                                                                                >
                                                                                                    <i className="fas fa-minus-circle removeicon" />
                                                                                                </Popconfirm>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className={classes.innercell} >
                                                                                <Tooltip title="Assign">
                                                                                    <IconButton
                                                                                        aria-label="Edit"
                                                                                        size="small"
                                                                                        onClick={this.assignChild({...time, event_date: date.event_date})}
                                                                                        // disabled={isSlotFull(userResponse, time)}
                                                                                        disabled={!!(time.child_ids || []).length}
                                                                                    >
                                                                                        <EditIcon fontSize="small" />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                <Popconfirm
                                                                                    title="Are you sure delete this slot?"
                                                                                    onConfirm={this.handleDeleteSlot(time.slotId)}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <Tooltip title="Delete">
                                                                                        <IconButton
                                                                                            aria-label="Delete"
                                                                                            size="small"
                                                                                            disabled
                                                                                        >
                                                                                            <DeleteIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Popconfirm>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}
                <SignupDialog
                    open={open}
                    onClose={this.onClose}
                    data={childList || []}
                />
            </div >
        );
    }
}

export default withStyles(styles)(SignupParentTeacher);
