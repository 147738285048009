import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Avatar, Button, Icon, List, Spin, Tag, Tooltip} from 'antd';
import { Container, Grid } from "@material-ui/core";
import {getColor, getSchoolId, ProfileAvatar} from "../../GlobalComponents/GlobalFields";
import {getUserDetails} from "../../../ApiServices";
import CreateParentModal from "../../Parents/components/CreateParent/CreateParentModal";
import "../../school.css"
import "../../Parents/parent.css"
import authService from "../../../../../service/auth";
import {newUserInfoSuccess} from "../../../../../redux/actions/current-user";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class ParentProfile extends React.Component {
    state = {
        profile_photo: "",
        isImgUpload: false,
        isOpen: false,
        isLoading: false,
        parentDetails: {},
        partEdit: null,
        isParentModal: false,
        updateParentDetail: {},
        partners: [],
        childList: [],
        siblingList: []
    };

    componentWillMount() {
        this.getUsersInfo()
    }

    getUsersInfo = async () => {
        this.setState({
            isLoading: true
        })
        const newUserResponse = await authService.getNewUserDetail();
        if(newUserResponse && newUserResponse.data) {
            this.props.onSetUserInfo(newUserResponse.data)
        }
        const { currentUser: { user = {} } } = this.props;

        const parentDetails = user
        this.getChildList(user)

        this.setState({
            parentDetails,
            isLoading: false
        })
    }

    getChildList = (data) => {
        const { currentUser: { childrencache = {}, usercache = {} } } = this.props

        let childList = []
        let partners = []
        let siblings = []
        data.child_ids.forEach(child => {
            const { parents } = childrencache && childrencache[child] || {}
            if(childrencache && childrencache[child] && Object.keys(childrencache[child]).length) {
                childList.push(childrencache[child])

                if((parents || []).length) {

                    parents.forEach(parent => {
                        if(parent !== data.id) {
                            if(usercache && usercache[parent] && Object.keys(usercache[parent]).length) {
                                partners.push(usercache[parent])

                                if(usercache[parent] && (usercache[parent].child_ids || []).length) {
                                    usercache[parent].child_ids.forEach(id => {
                                        if(!data.child_ids.includes(id) && childrencache[id] && (childrencache[id] || []).length) {
                                            // siblings.push(childrencache[id])
                                            childList.push(childrencache[id])
                                        }
                                    })
                                }
                            }
                        }
                    })
                }
            }
        })

        this.setState({
            childList,
            partners,
            siblings
        })
    }

    onUpdateProfile = (staffData) => {
        this.setState({
            parentDetails: staffData || {},
            isOpen: false,
            partEdit: null
        })
        this.getUsersInfo()
    }

    closeModal = () => {
        this.setState({
            isParentModal: false,
            updateParentDetail: {}
        })
    }

    getCreatedParentDetails = () => {
        this.closeModal()
        this.getUsersInfo()
    }

    render() {
        const { classes, currentUser: { groupcache: groupList = {} } } = this.props;
        const { parentDetails, isLoading, isParentModal, updateParentDetail, partners, childList, siblings } = this.state
        const { attributes, role = {}, group_ids = [] } = parentDetails || {}
        const { firstName = "", lastName = "", email = "", profile_photo = "", image = "", mobile = "", dateOfBirth = "", relation = "", isPickup = "" } = attributes || {}

        let roleArray = []
        const object = role[getSchoolId()] || {}
        Object.keys(object || {}).forEach(x => {
            if(role[getSchoolId()][x]) {
                roleArray.push(x)
            }
        })

        return (
            <div className="user-profile">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            {
                                (profile_photo || image) ?
                                    <Avatar src={profile_photo || image} size={50}/> :
                                    <Avatar size={50} style={{backgroundColor: 'white'}}>
                                        <span className="color-black">
                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                        </span>
                                    </Avatar>
                            }
                            <h3 className="mb-0 color-white ml-20">{firstName || ""} {' '} {lastName || ""}</h3>
                        </div>
                    </div>
                </div>
                <div className={`student ${classes.root} content`}>
                    {
                        isLoading ? <div className="text-center"> <Spin/> </div> :
                            <div>
                                <Grid container spacing={6}>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Container className="rounded-1rem bg-white p-4 shadow">
                                            <Grid container spacing={6} style={{marginTop: 0}}>
                                                <Grid item xs={12}>
                                                    <div>
                                                        { isParentModal &&
                                                        <CreateParentModal
                                                            isModal={isParentModal}
                                                            closeModal={this.closeModal}
                                                            childId={null}
                                                            getCreatedParentDetails={this.getCreatedParentDetails}
                                                            updateParentDetail={updateParentDetail || {}}
                                                        />
                                                        }
                                                        <div className="text-right">
                                                            <Icon
                                                                type="edit"
                                                                style={{paddingLeft: 10, paddingRight: 5}}
                                                                theme="filled"
                                                                className="edit-icon"
                                                                onClick={() => this.setState({updateParentDetail: parentDetails, isParentModal: true})}
                                                            />
                                                        </div>
                                                        <Grid container spacing={3} /* className="mt-20" */ >
                                                            <Grid item xs>
                                                                <div className="flex">
                                                                    <div className="fw-600 w-150">First Name :</div>
                                                                    <div>{firstName || "-"}</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs>
                                                                <div className="flex">
                                                                    <div className="fw-600 w-150">Last Name :</div>
                                                                    <div>{lastName || "-"}</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs>
                                                                <div className="flex">
                                                                    <div className="fw-600 w-150">Email :</div>
                                                                    <div>{email || "-"}</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs>
                                                                <div className="flex">
                                                                    <div className="fw-600 w-150">Role :</div>
                                                                    <div>{ (roleArray || []).map((x, index) => `${(x || "").charAt(0).toUpperCase() + (x || "").slice(1)}${(roleArray || []).length - 1 === index ? "" : ", "}`) }</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs>
                                                                <div className="flex">
                                                                    <div className="fw-600 w-150">Date Of Birth :</div>
                                                                    <div>{dateOfBirth || "-"}</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs>
                                                                <div className="flex">
                                                                    <div className="fw-600 w-150">Mobile :</div>
                                                                    <div>{mobile || "-"}</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs>
                                                                <div className="flex">
                                                                    <div className="fw-600 w-150">Relation :</div>
                                                                    <div>{ (relation || "") ? (relation || "").charAt(0).toUpperCase() + (relation || "").slice(1) : "-" }</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs>
                                                                <div className="flex">

                                                                    <div className="fw-600 w-150">Approved for Pickup :</div>
                                                                    <div>{ isPickup || "" }</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Container className="rounded-1rem bg-white p-40 shadow">

                                            <Grid container spacing={6} style={{marginTop: 0}}>

                                                {
                                                    ( partners || [] ).map((item, index) => {
                                                        const { firstName = "", lastName = "", profile_photo = "", email_id = "", mobile = "", invited, relation } = item || {}
                                                        return(
                                                            <Grid key={index} item xs={6} style={{borderRight: index === 0 ? "1px solid #eee" : ""}}>
                                                                <div style={{marginTop: "-100px", textAlign: 'center'}}>
                                                                    {
                                                                        (profile_photo || image) ?
                                                                            <ProfileAvatar
                                                                                size={90}
                                                                                src={profile_photo || image}
                                                                            /> :
                                                                            <ProfileAvatar
                                                                                size={90}
                                                                                text={`${firstName.toString().charAt(0).toUpperCase()} ${lastName.toString().charAt(0).toUpperCase()}`}
                                                                            />
                                                                    }
                                                                    <div className="fs-18 fw-600">
                                                                        <span>{firstName || ""} {lastName || ""}</span>
                                                                        {/*<Icon type="edit" theme="filled" className="edit-icon" style={{marginLeft: 5}} onClick={() => this.setState({updateParentDetail: item, isParentModal: true})}/>*/}
                                                                    </div>
                                                                    <span style={{color: '#c2c2c2'}}>
                                                                    Partner
                                                                </span>
                                                                </div>
                                                                <div className="pb-4 mt-20 flex">
                                                                    <div style={{width: 35}}>
                                                                        <i className="fa fa-envelope fa-lg color-active" aria-hidden="true"/>
                                                                    </div>
                                                                    <span>{email_id || "-"}</span>
                                                                </div>
                                                                <div className="pb-4 flex">
                                                                    <div style={{width: 35}}>
                                                                        <i className="fa fa-phone fa-rotate-90 fa-lg color-active" aria-hidden="true"/>
                                                                    </div>
                                                                    <span>{mobile || "-"}</span>
                                                                </div>
                                                                <div className="pb-4 flex">
                                                                    <div style={{width: 35}}>
                                                                        <i className="fa fa-bus fa-lg color-active" aria-hidden="true"/>
                                                                    </div>
                                                                    <span>{"-" || "Approved for Pickup"}</span>
                                                                </div>

                                                            </Grid>
                                                        )
                                                    })
                                                }

                                            </Grid>

                                            {
                                                !(partners || []).length ?
                                                    <Grid item xs={12}>
                                                        <div>No Spouse available.</div>
                                                    </Grid> : null
                                            }

                                        </Container>
                                    </Grid>

                                </Grid>

                                <br/><br/>

                                <Grid container spacing={6} >
                                    <Grid item xs={6}>
                                        <h4>Children's</h4>
                                        <Container className="rounded-1rem bg-white p-40 shadow">
                                            <Grid container spacing={6}>
                                                <Grid item xs={12}>
                                                    {
                                                        (childList || []).length ?
                                                            <List
                                                                itemLayout="horizontal"
                                                                dataSource={childList}
                                                                renderItem={(item, index) => (
                                                                    <List.Item onClick={() => this.props.history.push(`/student/view/${item.id}`)} >
                                                                        <List.Item.Meta
                                                                            avatar={
                                                                                <div className="cursor-pointer">
                                                                                    {
                                                                                        item.profile_photo ? <Avatar src={item.profile_photo} aria-label="recipe" style={{backgroundColor: getColor(index)}} /> :
                                                                                            <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                                                {`${(item && item.firstName || "").charAt(0).toUpperCase()}${(item && item.lastName || "").charAt(0).toUpperCase()}`}
                                                                                            </Avatar>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            title={<div className="fs-15 cursor-pointer">{item.firstName} {item.lastName}</div>}
                                                                            description={<span className="cursor-pointer">{item.status}</span>}
                                                                        />
                                                                    </List.Item>
                                                                )}
                                                            /> :
                                                            <div>No Child's available.</div>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Grid>
                                    <Grid item xs={6} className="mt-3">
                                        <h4>Group's</h4>
                                        <Container className="rounded-1rem bg-white p-40 shadow">
                                            <Grid container spacing={6}>
                                                <Grid item xs={12}>
                                                    <div>
                                                        {(group_ids || []).map((group, ind) => {
                                                            if(groupList[group] === undefined){ return  }
                                                            const { attributes } = (groupList && groupList[group]) || {}
                                                            return(
                                                                <Tag className="student-profile-tags" style={{marginBottom: 5}} key={ind}>{attributes.groupname.replace("_", " ")}</Tag>
                                                            )
                                                        })}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Grid>
                                </Grid>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

ParentProfile.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ParentProfile))