import React from 'react';
const Iframe = (props) => {
    let iframe_ref = null;
    const writeHTML = (frame) => {
        if (!frame) {
            return;
        }
        iframe_ref = frame;
        let doc = frame.contentDocument;
        console.log('frame.contentWindow.document',frame.contentWindow.document)
        console.log('doc',doc)
        doc.open();
        doc.write(props.content);
        doc.close();
        console.log('frame.contentWindow.document.body.scrollHeight', frame.contentWindow.document.body.scrollHeight )
        console.log('frame.contentWindow.document.body.offsetHeight', frame.contentWindow.document.body.offsetHeight )
        console.log('frame.contentWindow.document.documentElement.scrollHeight', frame.contentWindow.document.documentElement.scrollHeight)
        console.log('frame.contentWindow.document.documentElement.offsetHeight', frame.contentWindow.document.documentElement.offsetHeight )


        //frame.style.display = "block";
        frame.style.width = '100%';
        frame.style. overflow= "auto"
        frame.style.height =  `${frame.contentWindow.document.documentElement.scrollHeight*1.25}px`;


    };
    return (
        <iframe src="about:blank"
                scrolling="yes" frameBorder="0"   ref={writeHTML}
        />
    );
};
export default Iframe;