import React, { Component } from 'react'
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Collapse, Button as ANTDButton } from 'antd'
import {Typography, withStyles, Grid, Paper, InputBase, Button, Chip, TextField, IconButton, Avatar, Dialog, DialogContent, ListItem,
    ListItemAvatar, List, ListItemText, ListItemSecondaryAction, DialogActions, DialogTitle, Snackbar} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import {AddModuleStyles} from "./style";
import scaleService from '../service/scale';
import moduleService from '../service/module';
import * as Routes from '../../../../routes/path';
import {getCurrentSchoolId} from "../../../../utils/utils";
import { SortableContainer, SortableItem, arrayMove } from "./CategoryDraggable"
import SelectGroupModal from "./components/SelectGroupModal"
import TimelineModal from "./components/TimelineModal"
import ScaleModal from "./components/ScaleModal"
import './styles.css'

const { Panel } = Collapse
const useStyles = theme => AddModuleStyles(theme);

class AddModule extends Component {
    constructor(props){
        super(props)
        this.state = {
            values: {
                skillTitle: '',
                name: '',
                scaleName: '',
                scaleId: '',
                age: '',
                date: '',
                multiline: 'Controlled',
                currency: 'EUR',
                assignTeacherDialogIsOpened: false,
                teachers: [],
                isAddCategoryDialogOpened: false,
                newCategoryName: '',
                grades: [],
                scales: [],
                groups: [],
                num_partitions: '',
                num_years: '',
                isNumOfYearsDialogOpened: false,
                isNumOfPartitionsDialogOpened: false,
                dates: [],
                api: [],
                module_name: '',
                searchGroupText: '',
                categories: [],
                scaleToDelete: {},
                deleteScaleDialogOpen: false,
                checked: false,
            },
            errorFeedback: {
                open: false,
                message: '',
            },
            isEditCategory: false,
            isCategoryMatch: false,
            isSkillMatch: false,
            editCategoryIndex: null,
            selectedCategory: "",
            skillName: "",
            activeKey: 0,
            isAddSkillDialogOpened: false,
            moduleId: props.match.params.moduleId || "",
            groupDetails: {
                scales: [],
                error: false,
                loading: false,
                module: {},
                moduleId: 0,
                childId: 0,
                marks: [],
                toClone: {},
                studentMarksDetails: {
                    module: {
                        course_breakdown: {},
                    },
                    scales: [],
                },
                studentToGetMarksDetails: {
                    sdModuleId: 0,
                    sdChildId: 0,
                },
                studentSemesterDetails: {
                    year: 0,
                    slice: 0,
                    module_detail: {
                        years: 0,
                        slices: 0,
                    },
                },
                sModuleId: 0,
                sStudentId: 0,
            },
            subCategoryObject: {
                isSubCategoryModal: false,
                name: ""
            },
            scales: [],
            isGroupModal: false,
            isTimelineModal: false,
            isScaleModal: false,
            isScaleSaving: false
        }
    }

    async componentDidMount() {
        await this.onGetScalesList()

        if(this.props.match.params.moduleId){
            this.getGroupDetails(this.props.match.params.moduleId)
        }
    }

    onGetScalesList = async () => {
        const response = await scaleService.getScales();
        if(response.status === "success") {
            this.setState({
                scales: cloneDeep(response && response.data || []),
                values: {
                    ...this.state.values,
                    grades: [],
                    scaleName: '',
                    scaleId: '',
                }
            }, () => {
                const { values: { categories = [] } = {}, scales } = this.state
                categories.forEach(x => {
                    const index = (scales || []).findIndex(
                        s => parseInt(s.id) === parseInt(x.selectedId),
                    )
                    if (index !== -1) {
                        x.grade = scales[index];
                        x.scale = scales[index];
                        x.scale_id = scales[index].id;
                        x.selectedId = scales[index].id;
                    }
                })
                this.setState({
                    values: {
                        ...this.state.values,
                        categories
                    },
                    isScaleSaving: false,
                    isScaleModal: false
                })
            })
        } else {
            this.setState({
                scales: [],
                isScaleSaving: false
            })
        }

    }

    getGroupDetails = async (moduleId) => {
        const { scales } = this.state
        let generatedCategories = [];
        let moduleName = '';
        let numOfYears = 0;
        let numOfPartitions = 0;
        let moduleDates = [];
        let generatedGroups = [];
        let checkBox = false;

        const response = await moduleService.getModuleById(moduleId)
        if (response.status === "success") {

            const groupDetails = response.data
            if (module && scales && groupDetails.course_breakdown && moduleId && moduleId > 0) {
                const categories = groupDetails.course_breakdown;
                moduleName = groupDetails.modul;
                checkBox = groupDetails.teacher_override;
                numOfYears = groupDetails.years;
                numOfPartitions = groupDetails.slices;
                moduleDates = []; // Object.values(groupDetails.module.dates);
                if (categories.num_partitions) {
                    delete categories.num_partitions;
                }
                if (categories.num_years) {
                    delete categories.num_years;
                }
                generatedCategories = Object.entries(categories).map(([category, extra]) => {
                    const categoryObj = {};
                    try {
                        const scale = scales.filter(
                            s => parseInt(s.id) === parseInt(extra.scale_id),
                        )[0];
                        categoryObj.name = category;
                        categoryObj.order_id = extra.order_id;

                        const subCategoryArray = []
                        Object.entries(extra).map(([subCategory, skills]) => {

                            if(subCategory === "scale_id" || subCategory === "order_id") {
                                return
                            }

                            subCategoryArray.push({name: subCategory, order_id: skills.order_id, skills: this.onGetSkills(skills)})
                        })

                        categoryObj.subCategories = subCategoryArray.sort((a, b) => a.order_id - b.order_id)

                        // categoryObj.skills = Object.keys(extra.breakdown).map(child => ({
                        //     name: child,
                        //     mark: extra.breakdown[child],
                        // }));
                        if (scale) {
                            categoryObj.grade = scale;
                            categoryObj.scale = scale;
                            categoryObj.scale_id = scale.id;
                            categoryObj.selectedId = scale.id;
                        }
                    } catch (e) {}
                    return categoryObj;
                });
                generatedGroups = groupDetails.group_ids || []
            }
        }
        this.setState({
            values: {
                ...this.state.values,
                module_name: moduleName,
                // scales: scales,
                groups: generatedGroups,
                num_years: numOfYears,
                num_partitions: numOfPartitions,
                dates: moduleDates,
                checked: checkBox,
                categories: [...(generatedCategories || []).sort((a, b) => a.order_id - b.order_id)],
            },
            selectedCategory: (generatedCategories || []).length && generatedCategories[0].name || ""
        });
    }

    onGetSkills = (skills) => {
        const notInclude = ["order_id", "stop_marker"]
        let skillArray = Object.entries(skills).map(([skillname, marks]) => ({name: skillname, mark: marks?.marks || "", order_id: marks?.order_id || "", stop_marker: !notInclude.includes(skillname) ? marks.stop_marker : ""})).filter(x => !notInclude.includes(x.name))
        skillArray = skillArray.sort((a, b) => a.order_id - b.order_id)
        return skillArray.map(x => ({name: x.name, mark: x.mark, stop_marker: x.stop_marker}))
    }

    handleOpenNewCategoryDialog = () => {
        this.setState({
            values: {
                ...this.state.values,
                isAddCategoryDialogOpened: true
            }
        });
    };

    handleAddSkillNew = (subCategoryIndex, isEdit) => {
        let { values, skillName, selectedCategory, editSkillIndex } = this.state;
        const { categories } = values || {};

        let isSkillMatch = false

        if (skillName) {
            const index = (categories || []).findIndex(x => x.name === selectedCategory)
            if(index !== -1) {
                const subIndex = (categories[index].subCategories[subCategoryIndex].skills || []).findIndex(x => (x.name || "").toLowerCase() === (skillName || "").toLowerCase())
                if(subIndex !== -1) {
                    isSkillMatch = true
                } else {
                    if(isEdit) {
                        categories[index].subCategories[subCategoryIndex].skills[editSkillIndex].name = skillName
                    } else {
                        categories[index].subCategories[subCategoryIndex].skills.push({name: skillName, mark: ""})
                    }
                    skillName = ""
                }
            }
            this.setState({
                values: {
                    ...values,
                    categories
                },
                isSkillMatch,
                skillName
            }, () => !isSkillMatch && this.skillDialogClose());
        }
    };

    handleDeleteSkill = (subCatIndex, skillIndex) => {
        const { values, selectedCategory } = this.state;
        const { categories } = values || {};

        const index = (categories || []).findIndex(x => x.name === selectedCategory)
        if(index !== -1) {
            categories[index].subCategories[subCatIndex].skills.splice(skillIndex, 1);
        }

        this.setState({
            values: {
                ...values,
                categories
            }
        });
    };

    handleDeleteSubCategory = skillIndex => {
        const { values, selectedCategory } = this.state;
        const { categories } = values || {};

        const index = (categories || []).findIndex(x => x.name === selectedCategory)
        if(index !== -1) {
            categories[index].subCategories.splice(skillIndex, 1);
        }

        this.setState({
            values: {
                ...values,
                categories
            }
        });
    };

    handleSelectedScaleChanged = category => {
        const { values, scales } = this.state;
        const { categories } = values || {};
        // const index = categories.indexOf(category);
        categories[categories.indexOf(category)] = category;

        categories.forEach(x => {
            const index = (scales || []).findIndex(
                s => parseInt(s.id) === parseInt(x.selectedId),
            )
            if (index !== -1) {
                x.grade = scales[index];
                x.scale = scales[index];
                x.scale_id = scales[index].id;
                x.selectedId = scales[index].id;
            }
        })

        this.setState({
            values: {
                ...values,
                categories
            }
        });
    };

    onDeleteCategoryNew = category => {
        const { values } = this.state;
        const { categories } = values || {};
        const index = (categories || []).findIndex(x => x.name === category)
        if(index !== -1) {
            categories.splice(index, 1);
        }
        this.setState({
            values: {
                ...values,
                categories
            }
        });
    };

    onEditCategory = (index, value) => {
        this.setState({
            isEditCategory: true,
            editCategoryIndex: index,
            values: {
                ...this.state.values,
                isAddCategoryDialogOpened: true,
                newCategoryName: value
            }
        })
    }

    onEditSubCategory = (index, name) => {
        this.setState({
            subCategoryObject: {
                subCategoryIndex: index,
                isSubCategoryModal: true,
                name
            }
        })
    }

    onEditSkillNew = (index, skillIndex, value) => {
        this.setState({
            subCategoryIndex: index,
            editSkillIndex: skillIndex,
            isAddSkillDialogOpened: true,
            skillName: value
        })
    }

    skillDialogClose = () => {
        this.setState({
            subCategoryIndex: "",
            editSkillIndex: "",
            isAddSkillDialogOpened: false,
            skillName: ""
        })
    }

    handleOnDeleteScale = (scale) => {
        this.setState({
            values: {
                ...this.state.values,
                deleteScaleDialogOpen: true,
                scaleToDelete: scale
            }
        });
    }

    handleEditScale = (scale) => {
        this.setState({
            values: {
                ...this.state.values,
                scaleName: scale.name,
                scaleId: scale.id,
                grades: Object.entries(scale.grades).map(value => ({
                    mark: value[0],
                    description: value[1],
                })),
            }
        }, () => this.onScaleModalChange());
    }

    handleOnSaveScale = async () => {
        const { currentUser } = this.props;
        const { values } = this.state;
        const { grades, scaleId } = values || {};
        if (values.scaleName === ""){
            alert('Please give a valid Scale Title')
            return
        }

        this.setState({
            isScaleSaving: true
        })
        const scale = {
            name: values.scaleName,
            grades: {},
            scale_id: scaleId,
            id: scaleId,
            school_id: getCurrentSchoolId(currentUser)
        };
        grades.forEach(grade => {
            scale.grades[grade.mark] = grade.description;
        });

        try {
            const scaleResp = await scaleService.addScale(scale);
            if (scaleResp.status === 'error') {
                let message = '';
                if (scaleResp.message.includes('Duplicate ')) {
                    message = 'thre is already saved scale with the same name.';
                } else {
                    const { message } = scaleResp;
                }
                throw new Error(message);
            }
            this.onGetScalesList()
        } catch (e) {
            this.setState({
                errorFeedback: {
                    open: true,
                    message: e.message,
                }
            });
        }
    }

    handleCancel = () => {
        this.props.history.push(Routes.ProgressReport);
    }

    handleDone = async () => {
        const { history, currentUser } = this.props;
        const { values, moduleId, scales } = this.state;
        const { groups, grades, module_name, categories } = values || {};
        const groupsIds = groups || []; // groups.map(v => v.id);
        const allScales = {};
        const courseBreakdown = {};
        grades.forEach(grade => {
            allScales[grade.mark] = grade.description;
        });
        let hasError = false;
        categories.forEach((category, index) => {
            const skills = {};
            const subCategory = {};
            // category.skills.forEach(skill => {
            //     skills[skill.name] = skill.mark;
            // });
            category.subCategories.forEach((skill, i) => {
                skill.skills.forEach((x, skillIndex) => {
                    subCategory[skill.name] = {...subCategory[skill.name], [x.name]: { marks: x.mark, order_id: skillIndex, stop_marker: x.stop_marker || "" }, order_id: i, stop_marker: ""}
                })
            });
            console.log({subCategory})
            courseBreakdown[category.name] = { breakdown: {}, scale_id: 0 };
            // courseBreakdown[category.name].breakdown = skills;
            courseBreakdown[category.name] = subCategory;
            courseBreakdown[category.name].order_id = index;
            courseBreakdown[category.name].scale_id = category.selectedId;
            if (
                Object.values(courseBreakdown[category.name]).length > 0 &&
                category.selectedId !== 0 && !category.selectedId > 0
            ) {
                hasError = true;
            } else if (
                Object.values(courseBreakdown[category.name]).length <= 0 &&
                category.selectedId !== 0 && !category.selectedId > 0
            ) {
                courseBreakdown[category.name].scale_id = (scales || []).length && scales[0].id || "";
            }
        });
        if (hasError) {
            this.setState({
                errorFeedback: {
                    ...this.state.errorFeedback,
                    open: true,
                    message: 'Please, select Scale if you added skills to category',
                }
            });
            return;
        }
        const moduleToSave = {
            status: 1,
            group_ids: groupsIds,
            module_name,
            course_breakdown: courseBreakdown,
            years: values.num_years,
            slices: values.num_partitions,
            teacher_override: values.checked,
            school_id: getCurrentSchoolId(currentUser)
        };
        console.log(moduleToSave);

        try {
            const addModuleResp = await moduleService.addModule(moduleToSave);
            if (addModuleResp.status === 'error') {
                throw new Error(addModuleResp.message);
            }
            if (
                moduleId &&
                moduleId > 0 &&
                addModuleResp.message ===
                'Only slice will be updated for this module as this module has marks assigned to it'
            ) {
                this.setState({
                    errorFeedback: {
                        ...this.state.errorFeedback,
                        open: true,
                        message: addModuleResp.message,
                    }
                });
                setTimeout(() => {
                    history.push(Routes.ProgressReport);
                }, 3000);
            } else {
                history.push(Routes.ProgressReport);
            }
        } catch (e) {
            this.setState({
                errorFeedback: {
                    ...this.state.errorFeedback,
                    open: true,
                    message: e.message,
                }
            });
        }
    }

    handleAssignNewGroup = group => {
        const { values } = this.state;
        const { groups } = values || {};
        groups.push(group);
        this.setState({ values: { ...values, groups } });
    }

    handleUnassignGroup = group => {
        const { values } = this.state;
        let { groups } = values || {};
        // groups.splice(groups.indexOf(group), 1);
        groups = (groups || []).filter(x => x !== group)
        this.setState({ values: { ...values, groups } });
    };

    handleClose = () => {
        this.setState({values: {...this.state.values, assignTeacherDialogIsOpened: false }});
    }

    handleNewCategoryClose = () => {
        this.setState({
            values: {
                ...this.state.values,
                isAddCategoryDialogOpened: false
            },
            isEditCategory: false,
            isCategoryMatch: false,
            editCategoryIndex: null,
        });
    };

    handleAddCategoryDialogInput = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleAddCategory();
        }
    }

    handleAddCategory = () => {
        const { values, isEditCategory, editCategoryIndex } = this.state;
        const { categories } = values || {};
        if (values.newCategoryName && values.newCategoryName !== '') {
            const isMatch = (categories || []).findIndex(x => (x.name || "").toLowerCase() === (values.newCategoryName || "").toLowerCase())
            if(isMatch !== -1) {
                this.setState({
                    isCategoryMatch: true
                })
            } else {
                if(isEditCategory && editCategoryIndex !== null) {
                    categories[editCategoryIndex].name = values.newCategoryName
                } else {
                    // categories.push({ name: values.newCategoryName, skills: [] });
                    categories.push({ name: values.newCategoryName, subCategories: [] });
                }
                this.setState({
                    values: {
                        ...values,
                        newCategoryName: '',
                        categories,
                        isAddCategoryDialogOpened: false,
                    },
                    isEditCategory: false,
                    isCategoryMatch: false,
                    editCategoryIndex: null
                });
            }
        }
    }

    handleNumberOfYearsCloseKeyPress = e => {
        if (e.key === 'Enter') {
            this.setState({values: { ...this.state.values, num_years: e.target.value } });
            this.handleNumberOfYearsClose();
        }
    }

    handleNumberOfYearsClose = () => {
        this.setState({values: { ...this.state.values, isNumOfYearsDialogOpened: false }});
    };

    handlePartitionsNumChanged = e => {
        let dates = [];
        const num = e.target.value;
        dates = Array.from({ length: num });
        for (let index = 0; index < dates.length; index++) {
            const element = {
                start_date: moment(new Date()).add(index, 's'),
                end_date: moment(new Date()).add(index, 's'),
            };
            dates[index] = element;
        }
        this.setState({values: { ...this.state.values, num_partitions: num, dates }});
    }

    handleNumberOfPartitionsCloseKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleNumberOfPartitionsClose();
        }
    }

    handleNumberOfPartitionsClose = () => {
        this.setState({values: { ...this.state.values, isNumOfPartitionsDialogOpened: false }});
    }

    handleCancelDeleteScaleDialog = () => {
        this.setState({values: { ...this.state.values, deleteScaleDialogOpen: false }});
    }

    handleDeleteScaleConfirmed = async () => {
        const { currentUser } = this.props;
        let { values } = this.state;
        const { scaleToDelete } = values || {};
        try {
            const deleteScaleResp = await scaleService.deleteScale({scale_id: scaleToDelete.id, school_id: getCurrentSchoolId(currentUser)});
            if(deleteScaleResp.status === "success") {
                // const response = await scaleService.getScales();
                // if (response.status === 'success') {
                //     values = {...values, scales: response && response.data || []}
                // }
                this.onGetScalesList()
            } else if (deleteScaleResp.message === 'Scale id cannot be deleted as it is already being used by a module.') {
                this.setState({
                    errorFeedback: {
                        open: true,
                        message: 'This Scale is being used by other modules, hence cannot be deleted. Please remove from all modules to delete it ',
                    }
                });
            } else {
                if (deleteScaleResp.status === 'error') {
                    throw new Error(deleteScaleResp.message);
                }
            }

            this.setState({values: {...values, deleteScaleDialogOpen: false} });
        } catch (err) {
            console.log({err})
            this.setState({
                errorFeedback: {
                    open: true,
                    message: err.message,
                }
            });
        }
    }

    onMoveCategories = (index, from, to) => {
        const { categories } = this.state.values

        if(index !== null) {
            const array = categories[index].skills.splice(from, 1)[0];
            categories[index].skills.splice(to, 0, array);
        } else {
            const array = categories.splice(from, 1)[0];
            categories.splice(to, 0, array);
        }
        this.setState({
            values: {
                ...this.state.values,
                categories
            }
        })
    }

    onDraggingCategory = ({ oldIndex, newIndex }) => {
        this.setState(({ values }) => ({
            values: {
                ...this.state.values,
                categories: arrayMove(values.categories, oldIndex, newIndex)
            }
        }));
    };

    onDraggingSubCategories = ({ oldIndex, newIndex }) => {
        const { selectedCategory, values } = this.state
        const { categories } = values || {}

        const index = (values.categories || []).findIndex(x => x.name === selectedCategory)
        if(index !== -1) {
            const subCategory = categories[index].subCategories
            values.categories[index].subCategories = arrayMove(subCategory, oldIndex, newIndex)
        }

        this.setState(({
            values
        }));
    };

    onDraggingSkills = ({ oldIndex, newIndex }, subCategoryIndex) => {
        const { selectedCategory, values } = this.state
        const { categories } = values || {}

        const index = (values.categories || []).findIndex(x => x.name === selectedCategory)
        if(index !== -1) {
            const skills = categories[index].subCategories[subCategoryIndex].skills
            values.categories[index].subCategories[subCategoryIndex].skills = arrayMove(skills, oldIndex, newIndex)
        }

        this.setState({
            values
        });
    };

    onSelectCategory = (category) => {
        this.setState({
            selectedCategory: category
        })
    }

    getSkillsList = (subCategoryIndex) => {
        const { selectedCategory, values } = this.state
        const index = (values.categories || []).findIndex(x => x.name === selectedCategory)
        if(index !== -1) {
            const skills = values.categories[index] && values.categories[index].subCategories[subCategoryIndex].skills || []
            return skills || []
        }
        return []
    }

    getSubCategoryList = () => {
        const { selectedCategory, values } = this.state
        const index = (values.categories || []).findIndex(x => x.name === selectedCategory)
        if(index !== -1) {
            const subCategories = values.categories[index] && values.categories[index].subCategories || []
            return subCategories || []
        }
        return []
    }

    isCategorySelected = () => {
        const { selectedCategory, values } = this.state

        let isSelected = false
        const index = (values.categories || []).findIndex(x => x.name === selectedCategory)
        if(index !== -1) {
            isSelected = true
        }
        return isSelected
    }

    onModalChange = () => {
        const { subCategoryObject } = this.state
        this.setState({
            subCategoryObject: {
                ...subCategoryObject,
                isSubCategoryModal: !subCategoryObject.isSubCategoryModal,
                name: ""
            }
        })
    }

    onSubCategorySave = () => {
        const { values, subCategoryObject: { subCategoryIndex = "", name = "" } = {}, selectedCategory } = this.state;
        const { categories } = values || {};
        const categoryIndex = (categories || []).findIndex(x => x.name === selectedCategory)
        if(categoryIndex !== -1) {
            if(subCategoryIndex !== "") {
                categories[categoryIndex].subCategories[subCategoryIndex].name = name
            } else {
                categories[categoryIndex].subCategories.push({name: name, skills: []})
            }
        }
        this.setState({
            values,
            subCategoryObject: {
                isSubCategoryModal: false,
                name: ""
            }
        })
    }

    onGroupModalChange = () => {
        this.setState({
            isGroupModal: !this.state.isGroupModal
        })
    }

    onSetValues = (object) => {
        const { values } = this.state;
        this.setState({
            values: {
                ...values,
                ...object
            }
        })
    }

    onTimelineModalChange = () => {
        this.setState({
            isTimelineModal: !this.state.isTimelineModal
        })
    }

    onScaleModalChange = () => {
        const { isScaleModal, values } = this.state
        let object = {}
        if(isScaleModal) {
            object = {
                values: {
                    ...values,
                    scaleName: "",
                    scaleId: "",
                    grades: []
                }
            }
        }
        this.setState({
            ...object,
            isScaleModal: !this.state.isScaleModal
        })
    }

    onScaleSave = (object) => {
        const { values } = this.state;
        this.setState({
            values: {
                ...values,
                ...object
            }
        }, () => this.handleOnSaveScale())
    }

    getYearAndSemList = () => {
        const { values: { num_years = "", num_partitions = "" } = {} } = this.state

        let list = []

        for (let year = 1; year <= num_years; year++) {
            for (let sem = 1; sem <= num_partitions; sem++) {
                list.push({label: `Year ${year} Sem ${sem}`, value: `${year},${sem}`})
            }
        }

        list.push({label: "none", value: ""})

        return list || []
    }

    onMarkerChange = (subIndex, skillIndex, value) => {
        const { values, selectedCategory } = this.state;
        const { categories } = values || {};

        const categoryIndex = (categories || []).findIndex(x => x.name === selectedCategory)
        if(categoryIndex !== -1) {
            categories[categoryIndex].subCategories[subIndex].skills[skillIndex].stop_marker = value
        }

        this.setState({
            values: {
                ...values,
                categories
            }
        });
    };

    render() {
        const { classes, groupList } = this.props
        const { values, errorFeedback, isEditCategory, isCategoryMatch, selectedCategory, skillName, isSkillMatch, isAddSkillDialogOpened, subCategoryObject, subCategoryIndex, activeKey, scales, isGroupModal, isTimelineModal, isScaleModal, isScaleSaving } = this.state
        const { groups = [], num_years = "", num_partitions = "", module_name = "" } = values || {}
        console.log(this.state)
        return(
            <div>

                { isGroupModal ? <SelectGroupModal onClose={this.onGroupModalChange} selectedGroups={values.groups || []} onSetValues={this.onSetValues} /> : null }
                { isTimelineModal ? <TimelineModal onClose={this.onTimelineModalChange} num_years={values.num_years || ""} num_partitions={values.num_partitions || ""} onSetValues={this.onSetValues} /> : null }
                { isScaleModal ? <ScaleModal onClose={this.onScaleModalChange} scaleName={values.scaleName || ""} grades={values.grades || []} isScaleSaving={isScaleSaving} onScaleSave={this.onScaleSave} /> : null }

                <Grid
                    container
                    direction="row"
                    width="100%"
                    style={{ paddingLeft: '10px' }}
                >
                    <Typography
                        variant="h6"
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                    >
                        New Template
                    </Typography>
                </Grid>

                <Grid container direction="row" width="100%">
                    <Grid item md={12} xs={12} direction="column">
                        <Paper style={{ padding: '20px', marginTop: '10px' }}>
                            <div className="flex justify-between items-center">
                                <Typography className="flex">
                                    <span style={{fontWeight: "bold", width: 145}}>Selected Timeline</span>:
                                    <Typography className="ml-2">{`Year : ${values.num_years || "-"}, Semester : ${values.num_partitions || "-"}`}</Typography>
                                </Typography>
                                <div>
                                    <ANTDButton className="mr-3" type="primary" onClick={this.onGroupModalChange}>Select Group</ANTDButton>
                                    <ANTDButton className="mr-3" type="primary" onClick={this.onTimelineModalChange}>Set Timeline</ANTDButton>
                                    <ANTDButton type="primary" onClick={this.onScaleModalChange} >Add New Scale</ANTDButton>
                                </div>
                            </div>
                            <Typography className="flex">
                                <span style={{fontWeight: "bold", width: 145}}>Selected Groups</span>:
                                {(values.groups || []).map((id, index) => {
                                    const { attributes: { groupname = "" } = {} } = (groupList || []).find(group => id === group.id) || {}
                                    return(
                                        <span className={`mr-1 ${index === 0 ? "ml-2" : ""}`}>{groupname || ""}{values.groups.length === index + 1 ? "" : ","}</span>
                                    )
                                })}
                            </Typography>
                            <Typography className="mt-1 flex">
                                <span style={{fontWeight: "bold", width: 145}}>Scales Available</span>:
                                <Grid direction="row" className="flex">
                                    {
                                        (scales || []).map(scale => (
                                            <Grid
                                                item
                                                key={scale.name}
                                                // style={{ marginBottom: '5px' }}
                                            >
                                                <Chip
                                                    className={classes.badges}
                                                    variant="outlined"
                                                    color="primary"
                                                    label={scale.name}
                                                    onDelete={() => this.handleOnDeleteScale(scale)}
                                                    onClick={() => this.handleEditScale(scale)}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <div /* className={classes.root} */>

                    <form className={classes.container} noValidate autoComplete="off">
                        <Grid container direction="row" item width="100%" spacing={2}>
                            <Grid
                                container
                                md={12}
                                xs={12}
                                direction="column"
                                justify="flex-start"
                                item
                            >
                                <Paper style={{ padding: '20px', marginTop: '10px' }}>
                                    <Grid item>
                                        <Grid container direction="column">
                                            <Paper
                                                style={{
                                                    backgroundColor: '#f2f0eb',
                                                    padding: '8px',
                                                    paddingLeft: '15px',
                                                    opacity: 0.7,
                                                    borderRadius: '6px',
                                                    color: '#000000',
                                                }}
                                                className={classes.inputFullWidth}
                                            >
                                                <InputBase
                                                    autoFocus
                                                    label="Dense"
                                                    className={classes.fw}
                                                    value={values.module_name}
                                                    onChange={e =>
                                                        this.setState({ values : { ...values, module_name: e.target.value } })
                                                    }
                                                    placeholder="Module Name"
                                                    style={{
                                                        textDecorationColor: '#000000',
                                                    }}
                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="row" item width="100%" spacing={2} className="mt-20">
                                        <Grid
                                            container
                                            md={5}
                                            xs={12}
                                            direction="column"
                                            justify="flex-start"
                                            item
                                        >
                                            <SortableContainer onSortEnd={this.onDraggingCategory} useDragHandle>
                                                {(values.categories || []).map((value, index) =>(
                                                    <SortableItem
                                                        key={`item-${index}`}
                                                        index={index}
                                                        indexNumber={index}
                                                        type="category"
                                                        isCategory={true}
                                                        value={value}
                                                        onDeleteCategory={this.onDeleteCategoryNew}
                                                        onEditCategory={this.onEditCategory}
                                                        onSelectCategory={this.onSelectCategory}
                                                        selectedCategory={selectedCategory}
                                                        isSelected={selectedCategory === value.name}
                                                        grades={scales}
                                                        selectControl={classes.selectControl}
                                                        onCategoryScaleChanged={this.handleSelectedScaleChanged}
                                                    />
                                                ))}
                                            </SortableContainer>
                                            <Grid item >
                                                <Button
                                                    // variant="subtitle2"
                                                    color="primary"
                                                    className={classes.button}
                                                    onClick={this.handleOpenNewCategoryDialog}
                                                >
                                                    <AddIcon fontSize="small" />
                                                    <Typography
                                                        style={{ fontSize: '10px', fontWeight: 'bold' }}
                                                    >
                                                        Add Category
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            md={7}
                                            xs={12}
                                            direction="column"
                                            justify="flex-start"
                                            item
                                        >
                                            {
                                                this.isCategorySelected() ? (
                                                    (this.getSubCategoryList() || []).length ?
                                                        <>
                                                            <SortableContainer onSortEnd={this.onDraggingSubCategories} useDragHandle>
                                                                {
                                                                    (this.getSubCategoryList() || []).map((value, index) => (
                                                                        <>
                                                                            <SortableItem
                                                                                key={`item-${index}`}
                                                                                index={index}
                                                                                indexNumber={index}
                                                                                isSubCategory={true}
                                                                                value={value}
                                                                                onDeleteCategory={this.handleDeleteSubCategory}
                                                                                onEditSubCategory={this.onEditSubCategory}
                                                                                onSelectCategory={() => ({})}
                                                                            />

                                                                            <Collapse accordion className="ml-5 mb-3" activeKey={activeKey} onChange={(key) => this.setState({activeKey: key})}>
                                                                                <Panel header={`${value.name} Skills`} key={index}>
                                                                                    <SortableContainer onSortEnd={(e) => this.onDraggingSkills(e, index)} useDragHandle>
                                                                                        {
                                                                                            (this.getSkillsList(index) || []).length ? (this.getSkillsList(index) || []).map((value, i) => (
                                                                                                <>
                                                                                                    <SortableItem
                                                                                                        key={`skill-item-${i}`}
                                                                                                        index={i}
                                                                                                        indexNumber={i}
                                                                                                        isSkill={true}
                                                                                                        subCategoryIndex={index}
                                                                                                        value={value}
                                                                                                        onDeleteCategory={this.handleDeleteSkill}
                                                                                                        onEditSkillNew={this.onEditSkillNew}
                                                                                                        getYearAndSemList={this.getYearAndSemList}
                                                                                                        onMarkerChange={this.onMarkerChange}
                                                                                                        onSelectCategory={() => ({})}
                                                                                                    />
                                                                                                </>
                                                                                            )) : <div className="text-center">No skills found</div>
                                                                                        }
                                                                                    </SortableContainer>
                                                                                    <Paper
                                                                                        style={{
                                                                                            backgroundColor: '#f2f0eb',
                                                                                            padding: '8px',
                                                                                            paddingLeft: '15px',
                                                                                            opacity: 0.9,
                                                                                            borderRadius: '6px',
                                                                                            color: '#000000',
                                                                                        }}
                                                                                        className={`mt-3`}
                                                                                    >
                                                                                        <InputBase
                                                                                            label="Dense"
                                                                                            className={classes.fw}
                                                                                            placeholder="Add Skill"
                                                                                            onChange={e => this.setState({ skillName: e.target.value })}
                                                                                            value={skillName}
                                                                                            onKeyPress={(e) => {
                                                                                                if(e.key === 'Enter') {
                                                                                                    this.handleAddSkillNew(index, false)
                                                                                                }
                                                                                            }}
                                                                                            style={{ textDecorationColor: '#000000' }}
                                                                                        />
                                                                                    </Paper>
                                                                                </Panel>
                                                                            </Collapse>

                                                                        </>
                                                                    ))
                                                                }
                                                            </SortableContainer>
                                                            <>
                                                                { !!isSkillMatch ? <small className="color-red ml-5">Please enter unique skill</small> : null }
                                                            </>
                                                        </> :
                                                        null
                                                ) : !!(values.categories || []).length && <div className="text-center">Please Select any category</div>
                                            }

                                            {
                                                this.isCategorySelected() ?
                                                    <Grid item>
                                                        <Button
                                                            // variant="subtitle2"
                                                            color="primary"
                                                            className={classes.button}
                                                            onClick={this.onModalChange}
                                                        >
                                                            <AddIcon fontSize="small"/>
                                                            <Typography
                                                                style={{fontSize: '10px', fontWeight: 'bold'}}
                                                            >
                                                                Add Sub Category
                                                            </Typography>
                                                        </Button>
                                                    </Grid> : null
                                            }

                                        </Grid>
                                    </Grid>

                                </Paper>
                            </Grid>

                        </Grid>{' '}
                    </form>

                    <Grid container justify="flex-end" style={{ marginTop: '50px' }}>
                        <Grid item md={1}>
                            <Button onClick={this.handleCancel}>Cancel</Button>
                        </Grid>
                        <Grid item md={1}>
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                onClick={this.handleDone}
                                disabled={!(module_name && num_years && num_partitions && (groups || []).length)}
                            >
                                Done
                            </Button>
                        </Grid>
                    </Grid>

                </div>

                <Dialog
                    className={`${classes.teachersDialog} widthc`}
                    disableBackdropClick
                    disableEscapeKeyDown
                    open={values.assignTeacherDialogIsOpened}
                    fullWidth

                    // onClose={handleClose}
                >
                    <DialogContent>
                        <form className={classes.container}>
                            <Grid container direction="row">
                                <Grid
                                    item
                                    md={9}
                                    style={{
                                        // marginBottom: theme.spacing(3),
                                        paddingTop: '30px',
                                        paddingBottom: '10px',
                                    }}
                                >
                                    <Paper
                                        className={`${classes.searchRoot} widthAuto-xs`}
                                        style={{
                                            padding: '2px 4px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: 300,
                                            height: 40,
                                            backgroundColor: '#CECECE',
                                            opacity: 0.45,
                                            color: 'black',

                                            //  marginBottom: theme.spacing(3),
                                        }}
                                    >
                                        <InputBase
                                            className={classes.input}
                                            placeholder="Search By Name"
                                            value={values.searchGroupText}
                                            onChange={e =>
                                                this.setState({ values: { ...values, searchGroupText: e.target.value } })
                                            }
                                        />
                                        <IconButton
                                            className={classes.iconButton}
                                            aria-label="Search"
                                        >
                                            <SearchIcon fontSize="small" />
                                        </IconButton>
                                    </Paper>

                                </Grid>
                                <Grid item md={12}>
                                    <div className={classes.demo}>
                                        <List>

                                            {(this.props.groupList || []).map(group => (
                                                <ListItem
                                                    key={group.id}
                                                    style={{
                                                        borderBottom: '0.6px solid #EFEFEF',
                                                        paddingLeft: '0px',
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar>{group.attributes.groupname.substring(0, 1)} </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={group.attributes.groupname} />
                                                    <ListItemSecondaryAction>
                                                        {!(values.groups || []).some(
                                                            el => el === group.id,
                                                        ) ? (
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="Delete"
                                                                onClick={() => this.handleAssignNewGroup(group.id)}
                                                            >
                                                                <AddIcon
                                                                    style={{
                                                                        background: '#212B9B',
                                                                        borderRadius: '50%',
                                                                        color: 'white',
                                                                        height: '20px',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="Added"
                                                                onClick={() => this.handleUnassignGroup(group)}
                                                            >
                                                                <CheckIcon
                                                                    style={{
                                                                        background: '#212B9B',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        color: 'white',
                                                                        height: '20px',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))}
                                            {values.api &&
                                            (values.searchGroupText !== ''
                                                    ? values.api.filter(group =>
                                                        group.name
                                                            .toLowerCase()
                                                            .includes(values.searchGroupText.toLowerCase()),
                                                    )
                                                    : values.api
                                            ).map(group => (
                                                <ListItem
                                                    key={group.id}
                                                    style={{
                                                        borderBottom: '0.6px solid #EFEFEF',
                                                        paddingLeft: '0px',
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar>{group.name.substring(0, 1)} </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={group.name} />
                                                    <ListItemSecondaryAction>
                                                        {!values.groups.some(
                                                            el => el.name === group.name,
                                                        ) ? (
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="Delete"
                                                                onClick={() => this.handleAssignNewGroup(group)}
                                                            >
                                                                <AddIcon
                                                                    style={{
                                                                        background: '#212B9B',
                                                                        borderRadius: '50%',
                                                                        color: 'white',
                                                                        height: '20px',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="Added"
                                                                onClick={() => this.handleUnassignGroup(group)}
                                                            >
                                                                <CheckIcon
                                                                    style={{
                                                                        background: '#212B9B',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        color: 'white',
                                                                        height: '20px',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleClose}
                            color="primary"
                            style={{ backgroundColor: '#3f51b5', color: 'white' }}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    style={{ minWidth: '35%', minHeight: '30%' }}
                    className={classes.Dailogategory}
                    open={values.isAddCategoryDialogOpened}
                    onClose={this.handleNewCategoryClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        style={{ fontSize: '14/28px', fontWeight: 'bold' }}
                    >
                        { isEditCategory ? "Edit Category" : "Add new Category" }
                    </DialogTitle>
                    <DialogContent>

                        <Paper className="modalPaper inputNoShadow ">
                            <InputBase
                                className="modalInput"
                                style={{
                                    color: 'black',
                                    paddingTop: '5.1px',
                                }}
                                placeholder="Category Name"
                                onKeyPress={e => this.handleAddCategoryDialogInput(e)}
                                value={values.newCategoryName}
                                onChange={e =>
                                    this.setState({values: { ...values, newCategoryName: e.target.value } })
                                }
                            />
                        </Paper>
                        { isCategoryMatch ? <small className="color-red">Please enter unique category name</small> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleNewCategoryClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            className="modalBtn"
                            onClick={this.handleAddCategory}
                            color="primary"
                            style={{ backgroundColor: '#3f51b5', color: 'white' }}
                        >
                            { isEditCategory ? "Edit" : "Add" }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    style={{ minWidth: '35%', minHeight: '30%' }}
                    className={classes.Dailogategory}
                    open={(subCategoryObject || {}).isSubCategoryModal}
                    onClose={this.handleNewCategoryClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        style={{ fontSize: '14/28px', fontWeight: 'bold' }}
                    >
                        Add Sub Category
                    </DialogTitle>
                    <DialogContent>

                        <Paper className="modalPaper inputNoShadow ">
                            <InputBase
                                className="modalInput"
                                style={{
                                    color: 'black',
                                    paddingTop: '5.1px',
                                }}
                                placeholder="Sub Category"
                                onKeyPress={e => {
                                    if(e.key === 'Enter' && e.target.value) {
                                        this.onSubCategorySave()
                                    }
                                }}
                                value={subCategoryObject.name}
                                onChange={e => this.setState({subCategoryObject: { ...subCategoryObject, name: e.target.value } })}
                            />
                        </Paper>
                        { isCategoryMatch ? <small className="color-red">Please enter unique sub category name</small> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onModalChange} color="primary">
                            Cancel
                        </Button>
                        <Button
                            className="modalBtn"
                            onClick={this.onSubCategorySave}
                            color="primary"
                            style={{ backgroundColor: '#3f51b5', color: 'white' }}
                        >
                            { isEditCategory ? "Edit" : "Add" }
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    style={{ minWidth: '35%', minHeight: '30%' }}
                    className={classes.Dailogategory}
                    open={isAddSkillDialogOpened}
                    onClose={this.skillDialogClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        style={{ fontSize: '14/28px', fontWeight: 'bold' }}
                    >
                        Edit Skill
                    </DialogTitle>
                    <DialogContent>

                        <Paper className="modalPaper inputNoShadow ">
                            <InputBase
                                className="modalInput"
                                style={{
                                    color: 'black',
                                    paddingTop: '5.1px',
                                }}
                                placeholder="Skill Name"
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter') {
                                        this.handleAddSkillNew(subCategoryIndex, true)
                                    }
                                }}
                                value={skillName}
                                onChange={e => this.setState({skillName: e.target.value})}
                            />
                        </Paper>
                        { isSkillMatch ? <small className="color-red">Please enter unique skill name</small> : null }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.skillDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            className="modalBtn"
                            onClick={(e) => this.handleAddSkillNew(subCategoryIndex,true)}
                            color="primary"
                            style={{ backgroundColor: '#3f51b5', color: 'white' }}
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={values.isNumOfYearsDialogOpened}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new-category-namex"
                            label="Enter Number of years"
                            type="number"
                            fullWidth
                            value={values.num_years}
                            onChange={e => this.setState({ values: { ...values, num_years: e.target.value }})}
                            onKeyPress={e => this.handleNumberOfYearsCloseKeyPress(e)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleNumberOfYearsClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleNumberOfYearsClose} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={values.isNumOfPartitionsDialogOpened}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="new-category-partitions"
                            label="Enter Number of Partitions"
                            type="number"
                            fullWidth
                            style={{ height: '100%' }}
                            value={values.num_partitions}
                            onChange={e => this.handlePartitionsNumChanged(e)}
                            onKeyPress={e => this.handleNumberOfPartitionsCloseKeyPress(e)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleNumberOfPartitionsClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleNumberOfPartitionsClose} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={values.deleteScaleDialogOpen}
                >
                    <DialogTitle id="confirmation-dialog-title">
                        Are you sure you want to delete this scale ?
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="caption">{values.scaleToDelete.name}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancelDeleteScaleDialog} color="primary">
                            Cancel
                        </Button>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleDeleteScaleConfirmed}
                            >
                                Delete
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={errorFeedback.open}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ errorFeedback: {...errorFeedback, open: false} })}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{errorFeedback.message}</span>}
                    action={[
                        <Button
                            key="undo"
                            color="secondary"
                            size="small"
                            onClick={() => this.setState({ errorFeedback: {...errorFeedback, open: false} })}
                        >
                            Hide
                        </Button>,
                    ]}
                />

            </div>
        )
    }
}


AddModule.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo.user,
    groupList: state.currentUser.newUserInfo.groupList,
})

// export default AddModule
const enhance = compose(
    withRouter,
    withStyles(useStyles, 'some style'),
    connect(mapStateToProps, null),
)

export default enhance(AddModule);