import { fade, makeStyles } from '@material-ui/core/styles';
export const NewsLetterStyles = theme => ({
    root: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      // background: '#f5f5f5',
      borderRadius: '10px',
      minHeight: 'calc(100vh - 250px)'

    },
    modal: {
        // minHeight: '50vh',
        // minWidth: '50vh',
        maxWidth: '35vh'
    },
    title: {
        marginBottom: theme.spacing(2),
        // marginLeft: theme.spacing(2),
        // fontFamily: 'Helvetica Neue',
        color:'#000000',
        // fontSize: '20px',
        fontWeight:'bold',
        // textAlign:'center'
      },
    card: {
        // minWidth: 600,
        borderRadius: '10px',
        marginBottom:15,
        overflow:'unset !important' ,


    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    group: {
        margin: theme.spacing(3, 0),
        // textAlign:'center',
        display: 'inherit',
        // fontFamily: 'Helvetica Neue',
        fontSize:18,
        color: '#000000',
    },
    mediaimage: {
        // width:"100%",
        width:200,
        height:200
    },
    previewMediaImage: {
        // width:"100%",
        width:224,
        height:224
    },
    divider: {
        margin: `${10}px 0`
    },
    fab: {
        margin: theme.spacing(1),
    },
    actionButtons: {
        position: 'absolute',
        right: 10,
        zIndex:9999999
    },
    button: {
        margin: theme.spacing(1),
    },
    addbutton :{
      margin: theme.spacing(1),
      display:'inline-table',
      fontSize:10,
      lineHeight:0,
      color:'#999'
    },
    addTitle :{
      fontSize:12,
      margin:0,
      textTransform: 'capitalize',
      marginTop:5
    },
    input: {
        display: 'none',
    },
    bottomAppbar:{
        background: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    marginTop40:{
        marginTop:40
    },
    dateTitle :{
      // fontFamily: 'Helvetica Neue',
      fontSize:18,
      color: '#000000',
    },
    attachmentPreview :{
        textAlign: 'center',
        fontSize:30,
        padding:5,
        marginLeft:0,
        marginRight:0,
        borderRadius:"10px"
    },
    datepicker: {
        marginLeft:20,
        marginTop: 12
    },
    Listcard: {
        // fontFamily: 'Helvetica Neue',
        margin: "auto",
        transition: "0.3s",
        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.3) !important" ,
        marginTop:20,
        background:'#FFFFFF',
        borderRadius: '15px',
        marginLeft:20,
        marginRight:20
      },
    media: {
        paddingTop: "56.25%"
      },
    content: {
        textAlign: "left",
        padding:  10,
        // fontFamily: 'Helvetica Neue',

      },
    divider: {
        margin: `${ 3}px 0`
      },
    heading: {
        fontWeight: 500,
        fontSize:18,
        color: '#000000',
        // fontFamily: 'Helvetica Neue',
        margin:0
      },
    subheading: {
        // lineHeight: 1.8,
        fontSize:16,
        color: '#9D9D9D',
        // fontFamily: 'Helvetica Neue',

      },
    tags :{
        fontSize:16,
        color: '#000000',
        // fontFamily: 'Helvetica Neue',
        margin:0,
        fontWeight: "bold",

      },
    avatar: {
        display: "inline-block",
        border: "2px solid white",
        "&:not(:first-of-type)": {
          marginLeft: 2
        }
      },
    description:{
        color: '#000000',
        fontSize:16,
        // fontFamily: 'Helvetica Neue',

      },
    boldText:{
        fontWeight: "bold",
      },
    createButton: {
        // fontFamily: 'Helvetica Neue',
        fontWeight: "bold",
        textTransform: 'capitalize',
        fontSize:15,
        color: '#350FD3'

      },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        // '&:hover': {
        //   backgroundColor: fade(theme.palette.common.white, 0.25),
        // },
        marginLeft: 10,
        // width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
    searchIcon: {
        width: theme.spacing(7),
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize:16,
        color: '#bbb',
        top:7
      },
    inputRoot: {
        // color: 'inherit',
        background:'#FFFFFF',
        borderRadius: '5px',
      },
    inputInput: {
        color: '#000000',
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 200,
          '&:focus': {
            width: 200,
          },
        },
      },
    selectField:{
        width:100,
        background:'#EFEFEF',
        borderRadius: '5px',


      },
    tabpadding: {
        padding: theme.spacing(0, 2),
        color: '#000000',
        // fontFamily: 'Helvetica Neue',
        fontWeight: "bold",
        textTransform: 'capitalize',
        fontSize:16,


      },
    
    marginbutton: {
        margin: theme.spacing(2),
      },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        minWidth: 200,
        margin:'0px 20px',
        background:'#FFFFFF'
      },
    rootList:{
        color: '#FFFFFF',
        // fontFamily: 'Helvetica Neue',
        fontWeight: "bold",
        textTransform: 'capitalize',
        fontSize:16,
        // background:'#3f51b5;',
        minHeight:40,
        // textAlign:'center',
        borderRadius:5,
        // lineHeight:0,
        marginRight:10,
        marginLeft:0,
        marginTop:-20,
        paddingLeft:20
      },
    topictitle:{
        paddingTop:'5px',
        
      },
    saveButtons: {
        position: 'relative',
        float: 'right',
        fontSize:15

    },
    editTopic:{
      position: 'relative',
      float: 'right',
      color: '#FFFFFF',
      right:30

    },
    cardlistcontent:{
       paddingTop:0,
       paddingBottom:0,
      
    },
    itemcenter:{
      textAlign:'center'
    },
    previewcard:{
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      // fontFamily: 'Helvetica Neue',
      margin: "auto",
      transition: "0.3s",
      boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.3) !important" ,
      marginTop:20,
      background:'#FFFFFF',
      borderRadius: '15px',
      padding:30,
      minHeight: 'calc(100vh - 250px)'
      // marginLeft:20,
      // marginRight:20,

    },

    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    cover: {
      width: 151,
    },
    nopadding: {
      padding:0
    },
    cardContainer:{
      width: "80%"

      
    },
    borderRadius10:{
      borderRadius: '10px',
    },
    topcard: {
      display: 'flex',
      boxShadow:'none !important' ,
      background:'transparent',
      overflow:'unset !important' ,
     },
    details: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft:30
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 251,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    papers: {
        padding: theme.spacing(2),
        // textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    modalPaper: {
        position: 'absolute',
        width: 600,
        height: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    ModalImage:{
      width: 500,
      height: 500,
    },
    topicdescription:{
      paddingLeft:50,
      fontSize:16,
      marginTop:-25
    },
    
    addMenuButton:{
      padding: 0,
    },
    rightArrow: {
      position:'absolute',
      left: 27,
      color:'#bbb'

    },
    removeButton: {
      float:"right",
      padding:0,
      color:'#FF0000'
    },
    editButton: {
      float:"right",
      padding:0,
      color:'#3319B3',
      height : 20,
        marginTop : 10,
      backgroundColor : 'white',
        border: "1px solid #3319B3",
        marginBottom : 10
            },
    imageContainer: {
      // marginLeft:50,
      // marginTop:20,
      textAlign:'center',
      padding:10

    },
    imageContent: {
      display: 'inline-block',
      background:'#FFFFFF',
      marginLeft: 15,
      padding:5,
      marginTop:10

    },
    previewDescription:{
        padding: "0px 20px",
        fontSize:16,
        marginBottom: 8,
        background:'#FFFFFF',
        borderRadius:5
    },
    previewrootList:{
      color: '#FFFFFF',
      // fontFamily: 'Helvetica Neue',
      fontWeight: "bold",
      textTransform: 'capitalize',
      fontSize:16,
      // background:'#3f51b5;',
      minHeight:40,
      borderRadius:5,
      // lineHeight:0,
      // marginLeft:50,
      padding: "0 20px",
      marginBottom:10

    },
    marginRight20:{
      marginRight:20
    },
    avatarImage:{
      width:200,
      height:200,
      borderRadius:100,
      border: "1px solid whitesmoke"
    },
    avataruploadButton:{
        top:-30,
        left:50,
        color : "#999"
    },

    descriptionBox:{
        paddingLeft:10,
        marginRight:10,
        borderRadius:5,
        border: "1px dashed gray",
        background: "#fff"
    },
    profileImageContainer:{
      textAlign:'center',
      paddingTop:30
    },
    groupSelect:{
      marginLeft: 30,
      width:200,
      color:'#000',
      zIndex:1021
    },
    selectColorButton:{
      // float:'right',
      marginTop:12,
      // marginLeft:30,

    },
    topicFixDescription:{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp:4,
      WebkitBoxOrient:'vertical',
   
    },
    groupImage:{
      width: 50,
      position: 'absolute',
      borderRadius:30
    },
    datepickerFilter: {
      width: 200,
      height:35,
      borderRadius:5,
      border:0,
      marginLeft:10,
      paddingLeft:10
    },
    datePickerFilter: {
        width: "100%",
        height:35,
        borderRadius:5,
        border:0,
        // marginLeft:10,
        marginTop: 9,
        paddingLeft:10,
        background: "rgb(245, 245, 245)"
    },
    criteriatitle: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      // fontFamily: 'Helvetica Neue',
      color:'#000000',
      fontSize: '20px',
      fontWeight:'bold',
      textAlign:'center'
    },
    flexInline:{
      display: 'inline-flex',

    },
    resetButton: {
      // fontFamily: 'Helvetica Neue',
      fontWeight: "bold",
      textTransform: 'capitalize',
      fontSize:20,
      color: '#350FD3',
      padding:10,
      marginTop:-10,
      marginLeft:20

    },

  });
  