import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, withStyles, Fab, Container, Button} from "@material-ui/core";
import ImageNewSchool from "../../assets/images/signupschool.png"
import ImageSignupCode from "../../assets/images/signupcode.png"
import * as routes from "../../routes/path";
import ButtonBase from '@material-ui/core/ButtonBase';
import TextArea from "antd/es/input/TextArea";
import AppHeader from './appheader'
import Image from "../../assets/images/welcome/360.png";
const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        padding: '5em'
    },
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 32,
        color: '#6E75FE',
    },
    SigupActionBox: {
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop : 50,
        alignContent: 'center',

    },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    image: {
        position: 'relative',
        borderColor: '#6E75FE',
        height: 200,
        borderWidth: '1px',

    },
    actionButton : {
        position: 'relative',
        borderColor: '#6E75FE',
        height: 300,
        borderWidth: '1px',
        '&:hover': {
            backgroundColor: '#EBECFF',
            color: 'black',
        },
    },
    button : {
        backgroundColor : '#6E75FE',
        alignItems: 'center',
        paddingLeft : 50,
        paddingRight : 50,
        width : 620,
        color : 'white',
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        },
    },
});

const EducatorSignup = ({classes,}) => {


        return (
            <div>
                <AppHeader/>
            <Container className={classes.main}>

                <div className="text-center mt-2">
                    <div className={classes.header}>What would you like to do ?</div>
                </div>
                <div className={classes.SigupActionBox}>
                    <Link to={routes.SIGN_UP_EDUCATOR_NEW}>
                    <Card style={{marginRight: 10}}>
                        <ButtonBase
                            focusRipple
                            className={classes.actionButton}
                            style={{
                                width: 300,
                                borderRadius: '5px',
                                border: 'solid 2px #6E75FE',
                            }}
                            variant="outlined"
                        > <div class="transparent-container">
                            <div class="inline-div">
                                <img className={classes.image} src={ImageNewSchool} />
                            </div>
                            <div className="inline-div">
                                Create a New School
                            </div>


                        </div>
                        </ButtonBase>
                    </Card>
                    </Link>
                    <Link to={routes.SIGN_UP_CODE}>
                    <Card style={{marginLeft: 10}}>
                        <ButtonBase
                            focusRipple
                            className={classes.actionButton}
                            style={{
                                width: 300,
                                borderRadius: '5px',
                                border: 'solid 2px #6E75FE',
                            }}
                        >
                            <div className="transparent-container">
                                <div className="inline-div">
                                    <img className={classes.image} src={ImageSignupCode}/>
                                </div>
                                <div className="inline-div">
                                   Join with a Code
                                </div>


                            </div>
                        </ButtonBase>
                    </Card>
                    </Link>
                </div>
                <div style={{marginTop : 20}}>
                    <Link  to={routes.SIGN_IN}>
                    <Button
                        variant="contained"
                        type={'submit'}
                        className={classes.button}

                    >
                        I  have an account
                    </Button>
                    </Link>
                </div>
            </Container>
            </div>
        );

}


export default withStyles(styles)(EducatorSignup);