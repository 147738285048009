import React from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react';
import "video-react/dist/video-react.css";
import DownloadButton from './DownloadButton';
import awsConfig from "../../utils/aws-config"

const VideoPlayer = (props) => {

   function onPlay(e){
       const {postViewAction,data} = props;
       if(postViewAction){
        postViewAction.request(data);
       }
    }

    return (
        <Player
            poster={props.posterUrl}
            src={`${awsConfig.media_url}${props.videoUrl}`}
            onPlay={onPlay}

        >
            <BigPlayButton position="center" />
            <ControlBar autoHide={false}>
                <DownloadButton order={7} />
            </ControlBar>
        </Player>
    );
};

export default VideoPlayer;
