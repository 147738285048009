import React from "react";
import { Upload, Icon, message } from "antd";
import { Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import awsConfig from "./../../../../utils/aws-config"

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
const resizeFile = (file) => new Promise(resolve => {
  Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
  uri => {
    resolve(uri);
  },
  'blob'
  );
});

export default class UploadImage extends React.Component {
  state = {
    loading: false,
    imageUrl: null
  };
  componentDidMount(){
    this.setState({imageUrl:this.props.imgUrl,loading:false});
  }
  handleChange = info => {
    this.setState({ loading: true });
    this.props.imageUrlAction({loading:true,dataUrl:null});
    // if (info.file.status === "uploading") {
    //   this.setState({ loading: true });
    //   return;
    // }
    // if (info.file.status === "done") {
      getBase64(info.file.originFileObj, async imageUrl =>{ 
        const dataUrl = await this.handleDrop(info.file.originFileObj);
        this.setState({imageUrl,loading: false},() => {
          this.props.imageUrlAction({loading:this.state.loading,dataUrl});
        })
      });
    // }
  };

  async handleDrop(acceptedFiles) {
    const image = await resizeFile(acceptedFiles);
    let file=await this.uploadS3(image);
    const dataUrl = `${awsConfig.media_url}public/${file.key}`;
    //console.log(dataUrl,file,acceptedFiles,"handleDropinfo");
    return dataUrl;
  }

  async uploadS3(file) {
    const path = Date.now();
    const response = await Storage.put(path, file, { contentType: file.type });
    //console.log(response,path,file,"handleDropinfo");
    return Object.assign({ key: response.key, name: file.name });
  }
  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload image</div>
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        accept="image/x-png,image/jpeg"
        className="avatar-uploader"
        showUploadList={false}
        // beforeUpload={beforeUpload}
        onChange={this.handleChange}
        disabled={this.props.previewmode}
      >
        {imageUrl ? (
          <img src={imageUrl} alt={this.props.imgAlt||"Avatar"} className="avatar-img" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}