import React from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {IconButton, MenuItem, Select} from '@material-ui/core';
import {sortableContainer, sortableHandle, sortableElement} from "react-sortable-hoc";
import {Icon} from "antd";
import "./styles.css";

const DragHandle = sortableHandle(() => (
    <span className="dragHandler">
    <Icon type="drag" />
  </span>
));


export const SortableItem = sortableElement(({ value, indexNumber, isSkill, onDeleteCategory, onEditCategory, onSelectCategory, isSelected, onEditSkillNew, grades, selectControl, onCategoryScaleChanged, subCategoryIndex, isSubCategory, isCategory, onEditSubCategory, getYearAndSemList, onMarkerChange }) => {

    const onChange = (scale) => {
        value.selectedId = scale.id;
        onCategoryScaleChanged(value);
    }

    return(
        <div className={"dragElement"} onClick={() => onSelectCategory(value.name)} style={isSelected ? {background: 'rgb(158, 208, 249)'} : null}>
            <b>{value.name}</b>
            <div>
                { isCategory ?
                    <Select
                        value={value.grade || "Select Scale"}
                        onChange={ev => onChange(ev.target.value)}
                        className={selectControl}
                        defaultValue="Select Scale"
                    >
                        <MenuItem value="Select Scale" disabled><span style={{color: "gray"}}>Select Scale</span></MenuItem>
                    {(grades || []).map(scale => (
                        <MenuItem
                            key={scale.name}
                            component="a"
                            button
                            value={scale}
                            di
                        >
                            <span>{scale.name}</span>
                        </MenuItem>
                    ))}
                    </Select> : null
                }
                { isSkill ?
                    <Select
                        value={value.stop_marker}
                        onChange={ev => onMarkerChange(subCategoryIndex, indexNumber, ev.target.value)}
                        className={selectControl}
                        defaultValue="Mastered By"
                    >
                        <MenuItem value="Mastered By" disabled><small style={{color: "gray"}}>Mastered By</small></MenuItem>
                    {(getYearAndSemList() || []).map((value, index) => (
                        <MenuItem
                            key={index.toString()}
                            component="a"
                            button
                            value={value.value}
                        >
                            <small>{value.label}</small>
                        </MenuItem>
                    ))}
                    </Select> : null
                }
                <IconButton
                    edge="end"
                    aria-label="Delete"
                    onClick={() => isSkill ? onDeleteCategory(subCategoryIndex, indexNumber) : onDeleteCategory(isSubCategory ? indexNumber : value.name)}
                    style={{ paddingTop: '10px' }}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
                <span style={{marginRight: 10}}>
                <IconButton
                    edge="end"
                    aria-label="Delete"
                    onClick={() => isSkill ? onEditSkillNew(subCategoryIndex, indexNumber, value.name) : isSubCategory ? onEditSubCategory(indexNumber, value.name) : onEditCategory(indexNumber, value.name)}
                    style={{ paddingTop: '10px' }}
                >
                    <EditIcon fontSize="small" />
                </IconButton>
            </span>
                <DragHandle />
            </div>
        </div>
    )
});

export const SortableContainer = sortableContainer(({ children }) => {
    return <div className="dragContainer">{children}</div>;
});

const arrayMoveMutate = (array, from, to) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

export const arrayMove = (array, from, to) => {
    array = array.slice();
    arrayMoveMutate(array, from, to);
    return array;
};
