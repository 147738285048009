import { createSelector } from 'reselect';
import { isUserParent, isUserTeacher } from "../../utils/utils"
import store from "../store";

const groupSelector = state => state.currentUser.newUserInfo.groupcache;

const convertGroupOptions = createSelector(
    [groupSelector], (groupObject = {}) => {

        if (Object.keys(groupObject).length > 0) {
            let groups = Object.values(groupObject)

            const currentUser = store.getState().currentUser.newUserInfo.user

            if(isUserParent(currentUser) || isUserTeacher(currentUser)) {
                groups = (groups || []).filter(x => !x.attributes.is_public)
            }
            groups = (groups || []).filter(x => !x.attributes.is_archive)
            return groups.map(group => ({ label: group.attributes.groupname, value: group.id }))
        }
        return []
    }
)

export const MangePostSelector = {
    convertGroupOptions
};