import React,{useState,useEffect,useRef} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const default_profile="/static/images/avatar/1.jpg";
const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    cursor:"pointer",
    margin: 8,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));
 {/* <SmallAvatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
export default function BadgeAvatar({renderbadge,height,width,name,avatar_photo=default_profile,avatar_name="",selectItemAction,item={}}) {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={()=>selectItemAction(item)}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{margin:0}}
        badgeContent={renderbadge()}>
        <Avatar alt={`${avatar_name}`} src={`${avatar_photo}`} style={{width: width,height: height,margin:0}}/>
      </Badge>
      <Typography variant="caption" display="block" gutterBottom style={{fontSize:9,textAlign: "center",margin:0,fontWeight:500}}>
        {name}
      </Typography>
    </div>
  );
}
