import { GET_HISTORY, POST_COMMENT} from "../actions/observation";
import {
    REQUEST,
    SUCCESS,
    FAILURE
} from "../actions/common";

const INITIAL_STATE = {
    studentHistory: []
}

const observation = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case POST_COMMENT[SUCCESS]:
            return {
                ...state,
                studentHistory: []
            }

        case GET_HISTORY[SUCCESS]:
            return {
                ...state,
                studentHistory:action.payload.data
            }
        default:
            return state
    }
}

export default observation;