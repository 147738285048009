import React from "react"
import moment from "moment";
import {connect} from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {Table, InputNumber, Radio, Icon, Select, Input, Modal, Tooltip, DatePicker, Button} from "antd";
import {createChild, getChildAttendance, getStaffAttendance, createStudentCheckin, createStudentPreCheckin, createStaffCheckin, createStaffPreCheckin } from "../../../../../ApiServices"
import {getSchoolId, getFormattedDate, openNotificationWithIcon} from "../../../../GlobalComponents/GlobalFields";
import { preCheckinObject } from "../../../../GlobalComponents/optionValues";
import CompleteImage from "../../../../../../../assets/images/complete.jpg";
import PendingImage from "../../../../../../../assets/images/triangle.png";

const { TextArea } = Input
const { Option } = Select

class Attendance extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
            isModal: false,
            attendanceProcess: false,
            commentObject: {},
            students: [],
            duplicateStudents: [],
            staffs: [],
            duplicateStaffs: [],
            errorIndex: [],
            selectedDate: new Date()
        }
    }

    async componentWillMount() {
        this.getChildsAttendanceInfo(this.props)
    }

    getChildsAttendanceInfo = async (props) => {
        const { groupId, selectedStudent = [], selectedStaff = [] } = props || {}
        this.setState({
            isLoading: true
        })

        const att_date = getFormattedDate(this.state.selectedDate)

        const payload = {
            school_id: getSchoolId(),
            att_date: att_date,
            group_ids: [groupId]
        }

        let students = []
        let staffs = []

        const response = await getChildAttendance(payload)
        if(response && response.status === "success") {

            selectedStudent.forEach(x => {
                let body = {}
                const index = (response.data || []).findIndex(y => y.child_id === x.id)
                if(index !== -1) {
                    body.attendance = response.data[index].attendance || {}
                    body.pre_checkin = response.data[index].pre_checkin && Object.keys(response.data[index].pre_checkin || {}).length ? response.data[index].pre_checkin : cloneDeep(preCheckinObject)
                } else {
                    body.attendance = {}
                    body.pre_checkin = cloneDeep(preCheckinObject)
                }
                students.push({ ...x, ...body, isStudent: true})
            })
        }

        const staffRes = await getStaffAttendance(payload)
        if(staffRes && staffRes.status === "success") {

            selectedStaff.forEach(x => {
                // const isTeacher = ((((x || {}).role) || {})[getSchoolId()] || {}).teacher
                // if(isTeacher) {
                    let body = {}
                    const index = (staffRes.data || []).findIndex(y => y.staff_id === x.id)
                    if(index !== -1) {
                        body.attendance = staffRes.data[index].attendance || {}
                        body.pre_checkin = staffRes.data[index].pre_checkin && Object.keys(staffRes.data[index].pre_checkin || {}).length ? staffRes.data[index].pre_checkin : cloneDeep(preCheckinObject)
                    } else {
                        body.attendance = {}
                        body.pre_checkin = cloneDeep(preCheckinObject)
                    }
                    staffs.push({ ...x, ...body, isStaff: true})
                // }
            })
        }

        this.setState({
            students: cloneDeep(students),
            duplicateStudents: cloneDeep(students),
            staffs: cloneDeep(staffs),
            duplicateStaffs: cloneDeep(staffs),
            isLoading: false
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
       if(this.props.groupId !== nextProps.groupId) {
           this.getChildsAttendanceInfo(nextProps)
       }
    }

    getImage = (string) => {
        let src = ""
        if(string) {
            src = "data:image/png;base64," + string
        }
        return src
    }

    onStudentAttendanceChange = (value, id) => {
        const { students } = this.state
        const index = (students || []).findIndex(x => x.id === id)
        if(index !== -1) {
            students[index].attendance = {
                [value]: {
                    covid: {
                        attendance: {
                            status: value
                        },
                        droppedOffMember: {}
                    }
                }
            }
        }
        this.setState({
            students
        })
    }

    onStaffAttendanceChange = (value, id) => {
        const { staffs } = this.state
        const index = (staffs || []).findIndex(x => x.id === id)
        if(index !== -1) {
            staffs[index].attendance = {
                [value]: {
                    attendance: {
                        status: value,
                        comment: ""
                    },
                    attendancetime: "",
                    signature: "",
                }
            }
        }
        this.setState({
            staffs
        })
    }

    onTemperatureChange = (value, id, isStudent) => {
        const { students, staffs } = this.state

        const array = isStudent ? students : staffs

        const index = (array || []).findIndex(x => x.id === id)
        if(index !== -1) {
            array[index].pre_checkin.covid.temperature = value
        }
        this.setState({
            students,
            staffs
        })
    }

    onCovidChange = (e, key, id, isStudent) => {
        const { students, staffs } = this.state

        const array = isStudent ? students : staffs

        const index = (array || []).findIndex(x => x.id === id)
        if(index !== -1) {
            array[index].pre_checkin.covid.covid_detail[String(key)].description = ""
            array[index].pre_checkin.covid.covid_detail[String(key)].valid = e.target.value
        }

        this.setState({
            students,
            staffs
        })
    }

    getChildren = (record, key, pastDisabled) => {
        const { currentUser: { isTeacher = false, user: { id = "" }  } } = this.props
        const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
        let disable = false
        if(record.isStaff && record.id !== id && isTeacher) {
            disable = true
        }
        return (
            <div className="flex align-item-center">
                <Radio.Group value={valueObj.valid} onChange={(e) => this.onCovidChange(e, key, record.id, record.isStudent)} disabled={disable || pastDisabled}>
                    <Radio value={false}>No</Radio>
                    <Radio value={true}>Yes</Radio>
                </Radio.Group>
                {
                    valueObj.valid ?
                        <div>
                            <Tooltip title={valueObj.description} >
                                <Icon type="message" theme="filled" className="cursor-pointer color-red" onClick={() => this.onCommentModalChange(key, record.id, record.isStudent, disable || pastDisabled)} />
                            </Tooltip>
                        </div> : null
                }
            </div>
        )
    }

    getAttendanceList = () => {
        const { students, staffs } = this.state

        const studentArray = cloneDeep(students)
        const staffArray = cloneDeep(staffs)

        let array = []
        if((staffArray || []).length) {
            array.push({mainLabel: 'Staffs', isStaff: true, isStudent: false})
            array = array.concat(staffArray)
        }
        if((studentArray || []).length) {
            array.push({mainLabel: 'Students', isStaff: false, isStudent: true})
            array = array.concat(studentArray)
        }

        return (array || []).map((x, index) => ({...x, rowKey: index}))
    }

    getColumns = () => {
        const { currentUser: { isTeacher = false, user: { id = "" } } } = this.props
        let pastDisabled = false
        if(getFormattedDate(this.state.selectedDate) !== getFormattedDate(new Date()) && isTeacher) {
            pastDisabled = true
        }
        return [
            {
                title: '',
                width: 150,
                fixed: 'left',
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: <div className="fs-15 fw-600 text-center">
                                {record.mainLabel}
                            </div>,
                            props: { colSpan: 13 }
                        }
                    }

                    let url = ""

                    if(record.isStudent) {
                        if(isTeacher) {
                            url = `/student/attendance/${record.id}`
                        } else {
                            url = `/admin/student/attendance/${record.id}`
                        }
                    } else {
                        if(isTeacher) {
                            url = `/staff/attendance/${record.id}`
                        } else {
                            url = `/admin/staff/attendance/${record.id}`
                        }
                    }
                    const covidObject = (((record.pre_checkin || {}).covid || {}).covid_detail || {}) || {}
                    const array = Object.keys(covidObject || {}).map(x => covidObject[x].valid)
                    return(
                        <div
                            className="cursor_pointer"
                            onClick={() => this.props.history.push({
                                pathname: url,
                                state: {
                                    firstName: record.firstName,
                                    lastName: record.lastName,
                                    // groups: group_ids,
                                    profile_photo: record.profile_photo,
                                    id: record.id
                                }
                            })}
                            style={{color: '#1890ff', fontWeight: 500}}
                        >
                            {`${record.firstName || ""} ${record.lastName || ""}`}
                            { (array || []).includes(true) ? <img src={PendingImage} style={{width: 18}} /> : null }
                        </div>
                    )
                },
            },
            {
                title: 'Attendance marked at school',
                width: 200,
                fixed: 'left',
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const array = record.attendance && Object.keys(record.attendance || {})
                    const value = ((array || []).length && array[0]) || ""
                    let disable = false
                    if(record.isStaff && record.id !== id && isTeacher) {
                        disable = true
                    }
                    return(
                        <div>
                            <Select
                                style={{width: 150}}
                                value={value}
                                onChange={(value) => record.isStudent ? this.onStudentAttendanceChange(value, record.id) : this.onStaffAttendanceChange(value, record.id)}
                                disabled={disable || pastDisabled}
                            >
                                <Option value="in">Present</Option>
                                <Option value="virtual">Virtual</Option>
                                <Option value="excusedtardy">Excused Tardy</Option>
                                <Option value="unexcusedtardy">Unexcused Tardy</Option>
                                <Option value="excusedabsence">Excused Absence</Option>
                                <Option value="unexcusedabsence">Unexcused Absence</Option>
                            </Select>
                        </div>
                    )
                },
            },
            {
                title: 'Dropped off By',
                width: 200,
                fixed: 'left',
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const array = record.attendance && Object.keys(record.attendance || {})
                    const value = ((array || []).length && array[0]) || ""
                    let disable = false
                    if(record.isStaff && record.id !== id && isTeacher) {
                        disable = true
                    }
                    return(
                        <div>
                            {
                                record.isStudent ?
                                    <Select
                                        style={{width: 150}}
                                        value={value}
                                        onChange={(value) => record.isStudent ? this.onStudentAttendanceChange(value, record.id) : this.onStaffAttendanceChange(value, record.id)}
                                        disabled={disable || pastDisabled}
                                    >
                                        {
                                            // (record.auth_users || []).map((x, index) => (<Option value={index}>{x.firstName || ""}{" "}{x.lastName || ""}</Option>))
                                        }
                                    </Select> : null
                            }
                        </div>
                    )
                },
            },
            {
                title: 'Parents Decision',
                width: 200,
                fixed: 'left',
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const value = (((record.pre_checkin || {}).covid || {}).status || {}).status || ""
                    return(
                        <div>
                            <Select
                                style={{width: 150}}
                                value={value}
                                // onChange={(value) => this.onAttendanceChange(value, index)}
                                disabled
                            >
                                <Option value="inclass">In Class</Option>
                                <Option value="virtual">Virtual</Option>
                                <Option value="excusedabsence">Excused Absence</Option>
                            </Select>
                        </div>
                    )
                },
            },
            {
                title: 'Temperature',
                width: 120,
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const value = ((record.pre_checkin || {}).covid || {}).temperature || ""
                    let disable = false
                    if(record.isStaff && record.id !== id && isTeacher) {
                        disable = true
                    }
                    return(
                        <div>
                            <InputNumber
                                style={{width: 75}}
                                value={value}
                                onChange={(e) => this.onTemperatureChange(e, record.id, record.isStudent)}
                                disabled={disable || pastDisabled}
                            />
                            { (this.state.errorIndex || []).includes(record.id) ? <small className="color-red">Required</small> : null }
                        </div>
                    )
                },
            },
            {
                title: 'Fever Reducing Medication Administered?',
                width: 200,
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const key = "0"
                    return(
                        this.getChildren(record, key, pastDisabled)
                    )
                }
            },
            {
                title: 'Have you or anyone in your household travelled to a state or country listed on the Covid-19 travel advisory list in the last 14 days?',
                width: 200,
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const key = "1"
                    return(
                        this.getChildren(record, key, pastDisabled)
                    )
                }
            },
            {
                title: 'Close contact with Anyone Diagnosed with COVID-19 in the Past 14 Days?',
                width: 200,
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const key = "2"
                    return(
                        this.getChildren(record, key, pastDisabled)
                    )
                }
            },
            {
                title: 'Child/Staff Symptoms(s)?',
                children: [
                    {
                        title: 'Cough, Shortness of Breath, Trouble Breathing',
                        width: 200,
                        render: (record) => {
                            if(record.mainLabel) {
                                return {
                                    children: [],
                                    props: { colSpan: 0 }
                                }
                            }
                            const key = "3"
                            return(
                                this.getChildren(record, key, pastDisabled)
                            )
                        }
                    },
                    {
                        title: 'headache, Fever, Muscle pain, Chills, Repeated Shaking with Chills, New Loss of Taste or Smell',
                        width: 200,
                        render: (record) => {
                            if(record.mainLabel) {
                                return {
                                    children: [],
                                    props: { colSpan: 0 }
                                }
                            }
                            const key = "4"
                            return(
                                this.getChildren(record, key, pastDisabled)
                            )
                        }
                    },
                ],
            },
            {
                title: 'Household Member Symptoms(s)?',
                children: [
                    {
                        title: 'Cough, Shortness of Breath, Trouble Breathing',
                        width: 200,
                        render: (record) => {
                            if(record.mainLabel) {
                                return {
                                    children: [],
                                    props: { colSpan: 0 }
                                }
                            }
                            const key = "5"
                            return(
                                this.getChildren(record, key, pastDisabled)
                            )
                        }
                    },
                    {
                        title: 'headache, Fever, Muscle pain, Chills, Repeated Shaking with Chills, New Loss of Taste or Smell',
                        width: 200,
                        render: (record) => {
                            if(record.mainLabel) {
                                return {
                                    children: [],
                                    props: { colSpan: 0 }
                                }
                            }
                            const key = "6"
                            return(
                                this.getChildren(record, key, pastDisabled)
                            )
                        }
                    },
                ],
            },
            {
                title: 'Form Filled By',
                width: 150,
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const value = (record.pre_checkin || {}).check_in_by || ""
                    return(
                        <div>
                            { value }
                        </div>
                    )
                }
            },
            {
                title: 'Signature',
                width: 130,
                render: (record) => {
                    if(record.mainLabel) {
                        return {
                            children: [],
                            props: { colSpan: 0 }
                        }
                    }
                    const value = ((record.pre_checkin || {}).covid || {}).signature || ""
                    return(
                        <div>
                            <img src={this.getImage(value)} style={{width: 55}} />
                        </div>
                    )

                }
            }
        ];
    }

    commentModal = () => {
        const { isModal, commentObject } = this.state
        if(!isModal) return;
        return(
            <Modal
                title="Comment"
                visible={isModal}
                onOk={this.onCommentSave}
                onCancel={this.onCommentModalChange}
                width={400}
                okButtonProps={{disabled: !(commentObject.description || "") || commentObject.isDisabled}}
            >
                <div>
                    <TextArea
                        rows={4}
                        name="description"
                        value={commentObject.description}
                        onChange={(e) => this.setState({commentObject: {...commentObject, description: e.target.value}})}
                        disabled={commentObject.isDisabled || false}
                    />
                </div>
            </Modal>
        )
    }

    onCommentModalChange = (key, id, isStudent, isDisabled) => {
        const { students, staffs, isModal } = this.state

        const array = isStudent ? students : staffs

        const index = (array || []).findIndex(x => x.id === id)

        let commentObject = {}
        if(!isModal && index !== -1) {
            commentObject.index = index
            commentObject.key = String(key)
            commentObject.description = array[index].pre_checkin.covid.covid_detail[String(key)].description || ""
            commentObject.isStudent = isStudent || false
            commentObject.isDisabled = isDisabled || false
        }
        this.setState({
            isModal: !isModal,
            commentObject
        })
    }

    onCommentSave = () => {
        const { commentObject, students, staffs } = this.state
        const { index, key, description, isStudent } = commentObject || {}
        const array = isStudent ? students : staffs
        array[index].pre_checkin.covid.covid_detail[key].description = description || ""
        this.setState({
            students,
            staffs,
            commentObject: {},
            isModal: false
        })
    }

    onDateChange = (value) => {
        this.setState({
            selectedDate: value
        }, () => this.getChildsAttendanceInfo(this.props))
    }

    onSubmit = async () => {

        const { checkin, preCheckin, errorIndex } = this.getChanges()

        if((errorIndex || []).length) {
            openNotificationWithIcon('error', 'Temperature is required')
            return this.setState({errorIndex})
        } else {
            this.setState({errorIndex: []})
        }

        this.setState({
            attendanceProcess: true,
            isLoading: true
        })

        let success = 0
        let error = 0

        if((checkin || []).length) {
            for(let i in checkin) {
                const response = checkin[i].child_id ? await createStudentCheckin(checkin[i]) : await createStaffCheckin(checkin[i])
                if(response && response.status === "success") {
                    success++
                } else {
                    error++
                }
            }
        }

        if((preCheckin || []).length) {
            for(let i in preCheckin) {
                const response = preCheckin[i].child_id ? await createStudentPreCheckin(preCheckin[i]) : await createStaffPreCheckin(preCheckin[i])
                if(response && response.status === "success") {
                    success++
                } else {
                    error++
                }
            }
        }

        this.setState({
            attendanceProcess: false
        })

        this.getChildsAttendanceInfo(this.props)

    }

    getChanges = (isDisabled) => {
        const { students, staffs, duplicateStudents, duplicateStaffs, selectedDate } = this.state

        let checkin = []
        let preCheckin = []
        let errorIndex = []

        students.forEach((x, index) => {
            if(JSON.stringify(x.attendance) !== JSON.stringify(duplicateStudents[index].attendance)) {

                const array = x.attendance && Object.keys(x.attendance || {})
                const value = ((array || []).length && array[0]) || ""
                const temperature = ((x.pre_checkin || {}).covid || {}).temperature || ""

                if(value === "in" && !temperature) {
                    errorIndex.push(x.id)
                }

                checkin.push({
                    school_id: getSchoolId(),
                    child_id: x.id,
                    attendance: x.attendance,
                    att_date: getFormattedDate(selectedDate)
                })
            }
            if(JSON.stringify(x.pre_checkin) !== JSON.stringify(duplicateStudents[index].pre_checkin)) {
                preCheckin.push({
                    school_id: getSchoolId(),
                    child_id: x.id,
                    pre_checkin: x.pre_checkin,
                    att_date: getFormattedDate(selectedDate)
                })
            }
        })

        staffs.forEach((x, index) => {
            if(JSON.stringify(x.attendance) !== JSON.stringify(duplicateStaffs[index].attendance)) {

                const array = x.attendance && Object.keys(x.attendance || {})
                const value = ((array || []).length && array[0]) || ""
                const temperature = ((x.pre_checkin || {}).covid || {}).temperature || ""

                if(value === "in" && !temperature) {
                    errorIndex.push(x.id)
                }

                checkin.push({
                    school_id: getSchoolId(),
                    staff_id: x.id,
                    attendance: x.attendance,
                    att_date: getFormattedDate(selectedDate)
                })
            }
            if(JSON.stringify(x.pre_checkin) !== JSON.stringify(duplicateStaffs[index].pre_checkin)) {
                preCheckin.push({
                    school_id: getSchoolId(),
                    staff_id: x.id,
                    pre_checkin: x.pre_checkin,
                    att_date: getFormattedDate(selectedDate)
                })
            }
        })

        if(isDisabled) {
            return ((checkin || []).length && (preCheckin || []).length ? checkin.concat(preCheckin) : (checkin || []).length ? checkin : (preCheckin || []).length ? preCheckin : []) || []
        }
        return { checkin, preCheckin, errorIndex }
    }

    isCompleted= () => {
        const list = this.getAttendanceList()
        let length = 0

        list.forEach(x => {
            const array = x.attendance && Object.keys(x.attendance || {})
            const value = ((array || []).length && array[0]) || ""
            if(value || x.mainLabel) {
                length++
            }
        })
        return length === (list || []).length
    }

    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    render() {
        const { parentLoad } = this.props
        const { isLoading, selectedDate, attendanceProcess } = this.state
        return(
            <div>
                { this.commentModal() }
                <div className="text-center mb-4 flex justify-end">
                    {
                        (this.getAttendanceList() || []).length ?
                            <div className="flex align-item-center ws-nowrap">
                                <img src={this.isCompleted() ? CompleteImage : PendingImage} style={{width: 30}} />
                                <span className="ml-2" style={{fontSize: 20}}>
                                    { this.isCompleted() ? "Completed" : <span style={{color: 'red'}}>Pending</span> }
                                </span>
                            </div> : null
                    }

                    <div className="w-100-p">
                        <DatePicker
                            format={'LL'}
                            allowClear={false}
                            onChange={(date, dateString) => this.onDateChange(dateString)}
                            value={selectedDate ? moment(selectedDate, 'LL') : null}
                            disabledDate={this.disabledDate}
                        />
                    </div>
                </div>
                <Table
                    columns={this.getColumns()}
                    rowKey="rowKey"
                    // className="pt-12"
                    loading={isLoading || parentLoad}
                    pagination={false}
                    dataSource={this.getAttendanceList() || []}
                    bordered={true}
                    header
                    scroll={{ x: 2500 }}
                    sticky
                />
                {
                    (this.getAttendanceList() || []).length ?
                        <div className="mt-4">
                            <Button
                                type="primary"
                                onClick={this.onSubmit}
                                disabled={!(this.getChanges(true) || []).length || isLoading || false}
                                loading={attendanceProcess}
                            >
                                Submit
                            </Button>
                        </div> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

export default connect(mapStateToProps,null)(Attendance)