export const relationOptionList = ["Parent", "Family", "Approved Pickup", "Emergrncy Contact"]
export const countryOptionList = ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Canada", "USA"]
export const staffRoleList = ["Teacher", "Admin", "Teacher + Parent", "Admin + Parent"]
export const userRoleList = [{label: "Admin", value: "admin"}, {label: "Teacher", value: "teacher"}, {label: "Parent", value: "parent"}, {label: "Communications", value: "communications"}, {label: "PTA", value: "pta"}]
export const teacherLevelList = ["Lead", "Assistant"]
export const parentsRoleList = ["Parent", "Parent + PTA", "Parent + Teacher"]
export const primaryInfo = [
    {name: "raj", address1: "sarthana", address2: '-', address3: '-', city: 'surat', state: 'guj', postalCode: '123456',  country: 'Afghanistan'},
    {name: "ravi", address1: "bardoli", address2: '-', address3: '-', city: 'amreli', state: 'raj', postalCode: '456789',  country: 'Albania'},
    {name: "kavya", address1: "gopinath", address2: '-', address3: '-', city: 'rajkot', state: 'tamil', postalCode: '123456',  country: 'Angola'},
]
// export const colourList = ['Red', 'Orange', 'Green', 'Blue', 'Purple', 'Maroon', 'Navy', 'Black', 'Magenta', 'Silver']
export const studentStatusList = ["Lead", "Toured", "Applied", "Waitlist", "Active", "Inactive", "Graduated", "Removed", "Duplicate", "Departed"]
export const studentEnrollmentList = ["Full Day", "Half Day", "Extended"]
export const relationList = ["Family", "Approved Pickup", "Emergency Contact"]
export const parentsRelation = ["Father", "Mother"]
export const ethnicityList = ["Caucasian", "African American", "Latino/Hispanic American", "Asian", "Native American", "Middle Eastern", "Multi-racial", "Other", "Pacific Islander American", "Persian American", "Arab-American", "Hispanic-American",
    "South Asian American", "Guyanese", "Latino-American", "East Asian American", "European American", "Brazilian/Portuguese", "International", "Asian American", "Middle Eastern American", "Multiracial American"]

export const colourList = [   "#F7A5A2",
    "#F7C3A2",
    "#F7E0A2",
    "#EEF7A2",
    "#D1F7A2",
    "#B4F7A2",
    "#A2F7B0",
    "#A2F7D4",
    "#A2F7F0",
    "#A2DEF7",
    "#A2ADF7",
    "#B3A2F7",
    "#CFA2F7",
    "#ECA2F7",
    "#F7A2D8",
    "#F7A2B5",
    "#F7A2A2"]

export default {
    relationOptionList,
    countryOptionList,
    primaryInfo,
    staffRoleList
};

export const preCheckinObject = {
    covid: {
        status: {
            status: "",
            comment: ""
        },
        signature: "",
        temperature: "",
        covid_detail: {
            "0": {
                "valid": false,
                "description": ""
            },
            "1": {
                "valid": false,
                "description": ""
            },
            "2": {
                "valid": false,
                "description": ""
            },
            "3": {
                "valid": false,
                "description": ""
            },
            "4": {
                "valid": false,
                "description": ""
            },
            "5": {
                "valid": false,
                "description": ""
            },
            "6": {
                "valid": false,
                "description": ""
            },
            "7": {
                "valid": false,
                "description": ""
            }
        }
    }
}