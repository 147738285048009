import moment from "moment";
import {isPTAEvent} from "../shared/enums";

export const updateByPropertyName = (propertyName, value) => ({ [propertyName]: value });

export  function toDataURL(url, callback) {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onload = function () {
        const fileReader = new FileReader();
        fileReader.onloadend = function () {
            callback(fileReader.result);
        }
        fileReader.readAsDataURL(httpRequest.response);
    };
    httpRequest.open('GET', url,true);
    httpRequest.responseType = 'blob';
    httpRequest.send();
}

export const getCurrentSchoolId = (user = {}) => {
    return Object.keys(user.role)[0] || ""
}

export const getUserRole = (user, role) => {
    const roleList = user.role || {}

    if(roleList && Object.keys(roleList).length && role === "admin") {
        return roleList[getCurrentSchoolId(user)].admin || false
    }
    if(roleList && Object.keys(roleList).length && role === "teacher") {
        return roleList[getCurrentSchoolId(user)].teacher || false
    }
    if(roleList && Object.keys(roleList).length && role === "parent") {
        return roleList[getCurrentSchoolId(user)].parent || false
    }
    if(roleList && Object.keys(roleList).length && role === "communications") {
        return roleList[getCurrentSchoolId(user)].communications || false
    }
    return false
}

export const isUserParent = (user) => {
    let role = false
    const roleList = user.role || {}

    if(roleList && Object.keys(roleList).length) {
        role = (roleList[getCurrentSchoolId(user)].admin === false && roleList[getCurrentSchoolId(user)].teacher === false && roleList[getCurrentSchoolId(user)].parent === true)
    }
    return role
}

export const isUserTeacher = (user) => {
    let role = false
    const roleList = user.role || {}

    if(roleList && Object.keys(roleList).length) {
        role = (roleList[getCurrentSchoolId(user)].admin === false && roleList[getCurrentSchoolId(user)].teacher === true)
    }
    return role
}

export const convertScheduleTime = (scheduledTime) => {
    const _tmp = new Date(scheduledTime.replace(/['"]+/g, '') + "+00:00");
    let hour = 0
    let ampm = ""
    if (_tmp.getHours() >= 12) {
        hour = _tmp.getHours() === 12 || _tmp.getHours() === 0 ? 12 : _tmp.getHours() - 12;
        ampm = 'PM';
    } else {
        hour = _tmp.getHours() === 0 ? 12 : _tmp.getHours();
        ampm = 'AM';
    }
    let minutes = _tmp.getMinutes() < 10 ? '0' + _tmp.getMinutes() : _tmp.getMinutes();
    return getShortMonthName(_tmp.getMonth() + 1) + ' ' + _tmp.getDate() + ' ' + _tmp.getFullYear() + ' ' + hour + ':' + minutes + ' ' + ampm;
}

export const getShortMonthName = (monthint) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
    return monthNames[monthint - 1]
}

export const getPostTime = (post_time) => {
    const dt = convertScheduleTime(post_time)
    return moment(dt).fromNow()
}
export function ObjectArraytoIndexArray(ObjArray) {
    var idxArray = [];
    if (ObjArray === null) return [];
    Object.keys(ObjArray).forEach(function (key) {
      idxArray.push(ObjArray[key]);
    });
    return idxArray;
}

export const transformUserArrayToObject=(userArray=[])=>{
    let userObj={};
    if(userArray&&userArray.length!==0){
        userArray.forEach(user=>{
            userObj={...userObj,[user.id]:user};
        })
    }
    return userObj;
}

export const default_group="https://www.applozic.com/resources/sidebox/css/app/images/mck-icon-group.png"
export const default_user="https://www.clipartkey.com/mpngs/m/152-1520367_user-profile-default-image-png-clipart-png-download.png"
export const MESSAGES_PER_REFRESH=()=>15;
export const S3_PROFILE_URL=()=>"https://s3.amazonaws.com/hmsdev-profile-photos/";

export const getLastDateTimeMessage=(orderBy)=>{
    if(orderBy===""||orderBy===undefined||orderBy===null){
        return null;
    }
    let dt = moment(orderBy);
    let dt2 = moment(orderBy);
    let date = (dt2.endOf('day') < moment().endOf('day')) ?  dt.format('MMM D'): dt.format('h:mm A');
    return date;
}

export const updateUseridInUserObj=(userObj={})=>{
    let newUserObj={};
    Object.values(userObj).forEach(user=>{
        newUserObj={ ...newUserObj,[user.id]:{...user,userid:user.id}}
    });
    return newUserObj;
}
export const updateUseridInUsername=(userObj={},usercache)=>{
    let newUserObj={};
    Object.values(userObj).forEach(user=>{
        newUserObj={ ...newUserObj,[user.username]:{...user,userid:user.username,...usercache[user.username]}}
    });
    return newUserObj;
}

function convertToMoment(dates) {
    if (Array.isArray(dates)) {
        const result = dates.map(date => ({
            ...date,
            event_date: moment.utc(date.event_date).toDate(),
            slotTime: Array.isArray(date.slotTime) ?
                date.slotTime.map(slot => ({
                    ...slot,
                    start_time: moment(slot.start_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    end_time: moment(slot.end_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    spot: slot.spots || [],
                })) : [],
        }))
        return result
    }
    return []
}

export const onSetupEvent = (event) => {

    const { attributes: { event_img_url = "", groups, child_ids, notify_days_before_event, schedule = {}, event_type = "", attachments } } = event || {}
    const selected = (groups || []).length ? "group" : (child_ids || []).length ? "parents" : ""

    const object = {
        ...event,
        attributes: {
            ...event.attributes,
            groupParentSelection: selected,
            event_img_url: [{ key: event_img_url || "1562189344301", name:'default.jpg' }],
            attachments: (attachments || []).map((attachment, index) => ({
                key: attachment,
                name: `attachment${index + 1}`
            }))
        },

        start_date: event.start_date_time || "",
        end_date: event.end_date_time || "",
        start_time: event.start_date_time || "",
        end_time: event.end_date_time || "",
        volunteer: event.volunteerDetail ? convertToMoment(event.volunteerDetail.volunteer) : [],
        parentTeacherResponse: event.parentTeacherResponse ? convertToMoment(event.parentTeacherResponse) : [],
        // user_response: findRsvpEvent ? findRsvpEvent.user_response : null,

        event_id: event.id,
        notifyOneDayAgo: (notify_days_before_event || []).includes(1) || false,
        notifyTwoDayAgo: (notify_days_before_event || []).includes(2) || false,
        notifyThreeDayAgo: (notify_days_before_event || []).includes(3) || false
    }

    if (isPTAEvent(event_type)) {

        let arrays = []

        Object.keys(schedule).forEach(t => {
            let aa = {slotTime: []}
            aa.event_date = moment(t, moment.defaultFormat).toDate() // moment(t).format()

            Object.keys(schedule[t]).forEach(x => {
                const c = (x || "").split("-")
                const _time = moment(c[0], 'HH:mm')
                const _time1 = moment(c[1], 'HH:mm')
                aa.slotTime.push({start_time: moment(_time).format(), end_time: moment(_time1).format(), ...schedule[t][x]})
            })
            arrays.push(aa)
        })
        const dateList = (arrays || []).map(x => moment(x.event_date))

        object.parentTeacherResponse = arrays
        object.start_date_time = moment.min(dateList).toISOString()
        object.end_date_time = moment.max(dateList).toISOString()
    }

    return object
}

export const getEventsChild = (childrencache = {}, type, ids = [], currentUser) => {

    let array = []

    if(type === "childs") {
        ids.forEach(id => {
            if(childrencache && childrencache[id] && Object.keys(childrencache[id]).length) {
                array.push(childrencache[id])
            }
        })
    } else if(type === "groups") {

        ids.forEach(id => {
            Object.keys(childrencache).forEach(childId => {
                if((childrencache[childId].group_ids || []).includes(id) && !(array || []).some(x => x.id.toString() === childId.toString())) {
                    array.push(childrencache[childId])
                }
            })
        })
    }

    return array

}

export const getTimeFormat = (time) => moment(time).format("HH:mm") || ""
export const getDateFormat = (date) => moment(date).format("YYYY-MM-DD") || ""

export const getRandomString = () => {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    const string_length = 12;
    let randomString = '';
    for (let i=0; i<string_length; i++) {
        const rNum = Math.floor(Math.random() * chars.length);
        randomString += chars.substring(rNum,rNum+1);
    }
    return randomString
}