import React from 'react';
import styled from 'styled-components';
import {Draggable} from 'react-beautiful-dnd';
import Select from 'react-select';
import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
// import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
// import '@szhsin/react-menu/dist/index.css';
// import dots from './dots.png';
import "../../../css/plan.css";
import "./index.css";
import Studentchip from './studentchip'
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Button, Chip, TextField} from '@material-ui/core';
import TextArea from 'antd/es/input/TextArea';
import DayPicker, {DateUtils} from 'react-day-picker';
import {Modal as AntModal, Breadcrumb, Checkbox, Row, Col, Tag, Popconfirm, Popover, Collapse, Spin} from 'antd'
import moment from 'moment'
import 'react-day-picker/lib/style.css';
import _ from "lodash";
import recordPlannerService from "../../../service/recordplanner";
import {connect} from "react-redux";
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {StudentAvatar} from "./studentAvatar";
import ActionBar from './actionBar'
import CheckBoxDialog from "../../CreatePost/CheckBoxDialog";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Link from '@material-ui/core/Link'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {Checkbox as MUICheckbox} from '@material-ui/core/Checkbox';
import LoadingOverlay from 'react-loading-overlay';
import {
    PlusSquareFilled
} from '@ant-design/icons';

const {Panel} = Collapse;

const MainContainer = styled.div`
  border: 1px solid lightgrey;:;
  margin-bottom: 8px;
  border-radius: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const Container = styled.div`
  border-radius: 2px;
  padding: 8px;

  // text-decoration: line-thro;
  background-color: ${(props) => (props.isDragging ? 'lightgreen' : 'white')};
  display: flex;
  flex-direction: row;
  position: relative;
`;

const StudentContainer = styled.div`
  border-radius: 2px;
  padding: 2px;

  // text-decoration: line-thro;
  background-color: ${(props) => (props.isDragging ? 'lightgreen' : 'white')};
  display: flex;
  flex-direction: row;
  position: relative;
`;


const Input = styled.input`
  margin: 5px;
`;

const CustomMenu = styled.div`

`;

const EditTicket = styled.div`
  flex: 1;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const animatedComponents = makeAnimated();

class Task extends React.Component {
    constructor(props) {
        super(props);
        console.log('In task ')

        this.inputRef = React.createRef();
        this.state = {
            edit: false,
            isLoading: false,
            showEditor: false,
            showSpecificDates: false,
            showRangeDates: false,
            isCheckModalVisible: false,
            loadingOthers: false,
            multipleDatesAddModal: false,
            selectedStudentstoAdd: [],
            coldate: this.props.task.coldate == null ? [] : [this.props.task.coldate],
            completedDates: this.props.task.completedDates == null ? [] : this.props.task.completedDates,
            ticket: {
                content: this.props.task.content,
                childs: []
            },
            textDecoration: 'none',
            showModal: false,
            options: [
                {value: 'I', label: 'I'},
                {value: 'P', label: 'P'},
                {value: 'M', label: 'M'},
            ],
            selectedOption: {value: 'I', label: 'I'},
            markingData: []
        };
    }

    onCheckChange() {
        console.log(this.props, this.state)
        if (this.props.task.type === 'task') {
            this.setState({...this.state, textDecoration: 'line-through'});
        } else {
            this.setState({isCheckModalVisible: true})
            console.log('Lesson !!!!')
            // console.log('task', this.props)
            // if (this.props.mycoldate != null) {
            //     let coldate = this.state.coldate
            //     const selectedIndex2 = coldate.findIndex(selectedDay =>
            //         selectedDay == this.props.mycoldate
            //     );
            //     coldate.splice(selectedIndex2, 1)
            //     this.setState({coldate})
            //     this.saveSkillData(coldate)
            // }
        }
    };

    handleSelectChange = (e) => {
        this.setState((prev) => ({
            ...prev,
            selectedOption: e,
        }));
    };

    handleMenu = (value) => {
        console.log(value);
        if (value === 'Edit') {
            this.setState({...this.state, edit: true})
        } else if (value === 'Archive') {
            this.handleDelete();
        }
    }

    handleContentChange = (e) => {
        console.log(e.target.value)
        this.setState({...this.state, ticket: {...this.state.ticket, content: e.target.value}})
    }

    updateTicket = () => {
        this.setState({...this.state, edit: false});
        this.props.task.content = this.state.ticket.content;
    }

    onClose = () => {
        this.setState({...this.state, edit: false, ticket: {...this.state.ticket, content: this.props.task.content}});
    }

    handleDelete = () => {
        console.log(this.props.task);
        this.props.onDelete(this.props.task)
    }

    cancelDateModal() {
        this.setState({showEditor: false})
    }

    getSelectedDays() {
        let dtArray = []
        if (this.state.coldate == null) {
            return []
        }
        console.log('this.state', this.state)
        this.state.coldate.forEach((dt) => {
            dtArray.push(new Date(dt))
        })
        console.log(dtArray)
        return dtArray
    }

    handleDayClick(day, {selected}) {
        console.log(day, selected, this.state.coldate)
        let dt = moment(day).format('L')
        const coldate = this.state.coldate
        if (selected) {
            const selectedIndex2 = coldate.findIndex(selectedDay =>
                selectedDay == dt
            );
            if (selectedIndex2 !== -1) {
                coldate.splice(selectedIndex2, 1)
            }
        } else {
            coldate.push(dt)
        }
        this.setState({coldate});
    }

    saveData() {
        console.log(this.props.task)

        var selectedDates = Array.isArray(this.state.coldate) ? this.state.coldate : [this.state.coldate]
        let data = []
        console.log('selectedDates', selectedDates, this.state.coldate)
        this.props.task.students.forEach((student) => {
            let studentid = student.value
            let completedDates = this.props.studentCompleteDateHash[this.props.task.skillId][studentid] == undefined ? [] : this.props.studentCompleteDateHash[this.props.task.skillId][studentid]
            //Check if there is a completed date for this child
            selectedDates.forEach((dt) => {
                const selectedIndex = completedDates.findIndex(selectedDay =>
                    selectedDay == dt
                );
                if (selectedIndex !== -1) {
                    completedDates.splice(selectedIndex, 1)
                }
            })
            completedDates = _.uniq(completedDates, false)
            completedDates = completedDates.filter(function (el) {
                return el.length > 0
            });
            //Check if there is a other dates for this child
            let activedates = this.props.studentDateHash[this.props.task.skillId][studentid] == undefined ? [] : this.props.studentDateHash[this.props.task.skillId][studentid]
            activedates = activedates.concat(selectedDates)
           console.log('activedates',activedates)
            activedates = _.uniq(activedates, false)
            activedates = activedates.filter(function (el) {
                if (el !== null)
                return el.length > 0
            });
            console.log('this.props.studentRatingHash[this.props.task.skillId]', this.props.studentRatingHash, this.props.studentRatingHash[this.props.task.skillId])
            let rating = this.props.studentRatingHash[this.props.task.skillId] === undefined ? 'F' :
                this.props.studentRatingHash[this.props.task.skillId][studentid] === undefined ? 'F' :
                    this.props.studentRatingHash[this.props.task.skillId][studentid]

            var entry = {
                "school_id": this.props.school_id[0],
                "dashboard_id": this.props.task.dashboard_id,
                "child_id": studentid,
                "skill_id": this.props.task.skillId,
                "flag": 1,
                "rating": rating,
                "dates": activedates === null ? "UNPLANNED" : activedates,
                "completeddates": completedDates == null ? [] : completedDates,
                "comment": "",
                "group_id" : this.props.task.group_id
            }
            console.log('entry=>>', entry)
            data.push(entry)
        })
        console.log('data', data)
        this.setState({coldate: [this.props.mycoldate]});
        recordPlannerService.addDashboardEntry(data).then((ret) => {
            if (ret.status === 'success') {
                console.log(ret)
                this.props.refreshData()
            } else {
            }
        })
    }


    async saveSkillData(coldate = this.state.coldate, completedDate = [],
                        students = _.map(this.props.task.students, 'value')) {
        this.setState({isLoading: true})
        console.log('Students ...', students)
        if (students.length == 0) {
            alert('Error Saving Student Data. No Student Found !')
            return
        }
        var entry = {
            "school_id": this.props.school_id[0],
            "dashboard_id": this.props.task.dashboard_id,
            "child_ids": students,
            "skill_id": this.props.task.skillId,
            "schedule": coldate,
            "completeddates": completedDate
        }

        console.log('entry=>>', entry)

        const ret = await recordPlannerService.setStudentSchedule(entry)
        console.log(ret)
        if (ret.status === 'success') {
            this.props.refreshData()

        } else {
            alert('Error Saving Rating. Please try again !')
        }
        this.setState({isLoading: false})
    }


    async removeStudentfromPlan(studentid, refreshData = true, IsCloseStudent) {
        let dates = IsCloseStudent ? [] : this.props.task.coldate
        let rating = this.getRating(studentid);
        var completeddates = this.props.task.completedDates === null ? [] : this.props.task.completedDates
        console.log('completeddates', completeddates, this.props.mycoldate)
        if (!IsCloseStudent && this.props.mycoldate !== null) {
            completeddates.push(this.props.mycoldate.toString())
        }
        let flag = 0
        console.log('completeddates', completeddates)
        console.log('studentCompleteDateHash', this.props.studentCompleteDateHash)
        return await this.updateStudentData(studentid, flag, rating, completeddates, dates, refreshData);
    }

    getRating(studentid) {
        let rating = this.props.studentRatingHash[this.props.task.skillId] === undefined ? 'F' :
            this.props.studentRatingHash[this.props.task.skillId][studentid] === undefined ? 'F' :
                this.props.studentRatingHash[this.props.task.skillId][studentid]
        return rating;
    }

    async updateStudentData(studentid, flag, rating, completeddates, coldates, refreshData) {
        coldates = Array.isArray(coldates) ? coldates : [coldates]
        var entry = {
            "school_id": this.props.school_id[0],
            "dashboard_id": this.props.task.dashboard_id,
            "child_id": studentid,
            "skill_id": this.props.task.skillId,
            "flag": flag,
            "rating": rating,
            "dates": coldates,
            "completeddates": completeddates == null ? [] : completeddates,
            "comment": "",
            "group_id" : this.props.task.group_id
        }
        console.log('entry=>>', entry)

        const ret = await recordPlannerService.addDashboardEntry(entry)
        console.log(ret)
        if (ret.status === 'success') {
            if (refreshData) {
                this.props.refreshData()
            }
            return "Done"
        } else {
            alert('Error Saving Rating. Please try again !')

        }
    }

    itemRender(route, params, routes, paths) {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
            <span style={{color: 'blue'}} key={route}>{route}</span>
        ) : (
            <span key={route}>{route}</span>
        );
    }

    closeCheckModal() {
        this.setState({isCheckModalVisible: false})
        this.props.refreshData()
    }

    checkedonaddStudent(checked, studentId) {
        let students = this.state.selectedStudentstoAdd
        let index = students.indexOf(studentId)
        if (index === -1) {
            if (checked) {
                students.push(studentId)
            }
        } else {
            if (!checked) {
                students.splice(index, 1)
            }
        }
        this.setState({selectedStudentstoAdd: students})

    }

    getStudentList() {
        let otherstudentscache = this.props.childgroupcache[this.props.task.group_id]
        let otherstudent = []
        let curstudentids = Object.keys(_.keyBy(this.props.task.students, 'value'))
        console.log('props', this.props)
        Object.keys(otherstudentscache).map((studentid) => {
            if (!curstudentids.includes(studentid.toString())) {
                let student = otherstudentscache[studentid]
                // console.log('student', student)
                let item = (<Row>
                    <Checkbox onChange={(evt) => {
                        this.checkedonaddStudent(evt.target.checked, studentid)
                    }}>
                        {student.firstName + ' ' + student.lastName}</Checkbox></Row>)
                otherstudent.push(item)
            }
        })
        console.log('otherstudent', otherstudent)

        return (
            <div>
                <Col>{otherstudent}</Col>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={() => {
                        if (this.props.task.coldate !== null && this.props.task.coldate.length > 0) {
                            console.log('this.props', this.props)
                            this.addStudentToTask(true)
                            //this.setState({multipleDatesAddModal: true})
                        } else {
                            this.addStudentToTask(true)
                        }

                    }}>Add Students</Button>
                </div>
            </div>
        )
    }

    async addStudentToTask(singleDate) {
        let dates = null
        let mycoldate = this.props.mycoldate === null ? [] : [this.props.mycoldate]
        console.log('mycoldate',mycoldate)
        if (singleDate) {
            dates = mycoldate
        } else {
            let existingdates = this.props.studentDateHash?.this.props.task.skillId?.studentid === undefined ? [] : this.props.studentDateHash?.this.props.task.skillId?.studentid
            let completeddates = this.props.studentCompleteDateHash?.this.props.task.skillId?.studentid === undefined ? [] : this.props.studentDateHash?.this.props.task.skillId?.studentid

            dates = this.props.task.coldate
        }

        console.log('Students to Add', this.state.selectedStudentstoAdd)
        console.log('dates', dates)


        let data = []

        for (var i = 0; i < this.state.selectedStudentstoAdd.length; i++) {
            let studentid = this.state.selectedStudentstoAdd[i]
            let rating = this.getRating(studentid)
            let {completedDates, activedates} = this.getDatesAfterAddition(studentid);

            console.log('activedates', activedates)
            console.log('activedates == null',activedates[0] == [null])
            var entry = {
                "school_id": this.props.school_id[0],
                "dashboard_id": this.props.task.dashboard_id,
                "child_id": studentid,
                "skill_id": this.props.task.skillId,
                "flag": 1,
                "rating": rating,
                "dates": activedates == null ? "UNPLANNED" : activedates,
                "completeddates": completedDates == null ? [] : completedDates,
                "comment": "",
                "group_id" : this.props.task.group_id
            }
            console.log('entry=>>', entry)
            data.push(entry)
        }
        const ret = await recordPlannerService.addDashboardEntry(data)
        console.log(ret)
        this.props.refreshData()
        this.setState({multipleDatesAddModal: false, selectedStudentstoAdd: []})
    }

    closeStudent(studentId) {
        console.log('removing student ', studentId)
        console.log('studentDateHash', this.props.studentDateHash)
        let dates = this.getScheduleDates(studentId);
        console.log('Dates', dates)
        if (dates !== null) {
            let index = dates.indexOf(this.props.mycoldate)
            if (index !== -1) {
                dates.splice(index, 1)
            }
        }
        console.log('Dates', dates)
        if (dates == null || dates.length === 0) {
            this.removeStudentfromPlan(studentId, false, true)
        } else {
            this.saveSkillData(dates, null, [studentId])
        }

        //this.props.refreshData()

    }


    getScheduleDates(studentId) {
        let dates = this.props.studentDateHash[this.props.task.skillId] === undefined ? null :
            this.props.studentDateHash[this.props.task.skillId][studentId] === undefined ? null :
                this.props.studentDateHash[this.props.task.skillId][studentId]
        return dates;
    }

    renderDates() {
        let dts = []
        if (this.props.task.coldate === null) return null
        this.props.task.coldate.map((dt) => {
            console.log(dt)
            let dt2 = moment(dt).format('MMM D')
            dts.push(dt2)
        })
        console.log('dts', dts)
        return (
            <div style={{verticalAlign: 'middle', display: 'flex'}}>
                <AccessTimeIcon style={{fontSize: 12, marginTop: 2, color: '#d826e2'}}></AccessTimeIcon>
                <s1pan style={{fontSize: 10, color: '#a1a7b5'}}>{dts.join(',')}</s1pan>
            </div>
        )
    }

    async markCompleteforDay() {
        if (this.props.isCompleted) {
            console.log('this.props', this.props)

            console.log('Hard to Implement !')
        } else {
            console.log('this.props', this.props)
            if (this.props.mycoldate != null) {
                let data = []
                this.props.task.students.forEach((student) => {
                    let studentid = student.value
                    let rating = this.props.studentRatingHash[this.props.task.skillId] === undefined ? 'F' :
                        this.props.studentRatingHash[this.props.task.skillId][studentid] === undefined ? 'F' :
                            this.props.studentRatingHash[this.props.task.skillId][studentid]


                    let {completedDates, activedates} = this.getDatesAfterRemoval(studentid);

                    let flag = activedates.length === 0 ? 0 : 1
                    var entry = {
                        "school_id": this.props.school_id[0],
                        "dashboard_id": this.props.task.dashboard_id,
                        "child_id": studentid,
                        "skill_id": this.props.task.skillId,
                        "flag": flag,
                        "rating": rating,
                        "dates": activedates=== null ? "UNPLANNED" : activedates,
                        "completeddates": completedDates == null ? [] : completedDates,
                        "comment": "",
                        "group_id" : this.props.task.group_id

                    }
                    data.push(entry)
                })

                const ret = await recordPlannerService.addDashboardEntry(data)
                console.log(ret)
                if (ret.status === 'success') {
                    this.props.refreshData()
                } else {
                    alert('Error Saving Rating. Please try again !')

                }

            }
        }
    }

    getDatesAfterRemoval(studentid, addtoCompletionDate = true) {
        let completedDates = this.props.studentCompleteDateHash[this.props.task.skillId][studentid] == undefined ? [] : this.props.studentCompleteDateHash[this.props.task.skillId][studentid]
        if (addtoCompletionDate) {
            completedDates.push(this.props.mycoldate)
        }
        completedDates = _.uniq(completedDates, false)

        let activedates = this.props.studentDateHash[this.props.task.skillId][studentid] == undefined ? [] : this.props.studentDateHash[this.props.task.skillId][studentid]
        const selectedIndex = activedates.findIndex(selectedDay =>
            selectedDay == this.props.mycoldate
        );
        if (selectedIndex !== -1) {
            activedates.splice(selectedIndex, 1)
        }
        activedates = _.uniq(activedates, false)
        return {completedDates, activedates};
    }

    getDatesAfterAddition(studentid) {
        let completedDates = this.props.studentCompleteDateHash[this.props.task.skillId][studentid] == undefined ? [] : this.props.studentCompleteDateHash[this.props.task.skillId][studentid]
        const selectedIndex = completedDates.findIndex(selectedDay =>
            selectedDay == this.props.mycoldate
        );
        if (selectedIndex !== -1) {
            completedDates.splice(selectedIndex, 1)
        }
        completedDates = _.uniq(completedDates, false)

        let activedates = this.props.studentDateHash[this.props.task.skillId][studentid] == undefined ? [] : this.props.studentDateHash[this.props.task.skillId][studentid]
        activedates = activedates == null ? ['UNPLANNED'] : activedates
        activedates.push(this.props.mycoldate == null ? 'UNPLANNED' :this.props.mycoldate )
        activedates = _.uniq(activedates, false)
        return {completedDates, activedates};
    }

    render() {
        const styles = {
            containerStyle: {
                textDecoration: this.state.textDecoration,
            },
        };

        const {containerStyle} = styles;
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '70%',
                height: 'fit-content',
            },
        };
        console.log('In task ', this.props.task.coldate)
        console.log('In task ', this.props.task)
        let taskBGColor = this.props.isCompleted ? "lightgray" : "white"
        return (
            <>
                <MainContainer style={{backgroundColor: 'white'}}>
                    <Draggable draggableId={this.props.task.id + "::" + this.props.column_id}
                               key={this.props.task.id + "::" + this.props.column_id}
                               index={this.props.index}
                               isDragDisabled={this.props.isCompleted}>
                        {(provided, snapshot) => (

                            <Container
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                isDragging={snapshot.isDragging}>

                                <div style={{flexGrow: 1}}>
                                    <div style={{display: 'flex'}}>
                                        {(this.props.task.coldate !== null && this.props.task.coldate !== "") && this.props.isCompleted == false &&
                                        <Checkbox style={{marginRight: 5}}
                                                  checked={this.props.isCompleted}
                                                  onChange={() => this.markCompleteforDay()}/>
                                        }
                                        <Breadcrumb key={this.props.task.content.join('') + '-' + this.props.task.coldate.replace('/', '-)')} separator=">"
                                                    itemRender={this.itemRender} routes={this.props.task.content}>
                                        </Breadcrumb>

                                        {/*{this.props.task.type !== "lesson" &&*/}
                                        {/*<CustomMenu>*/}
                                        {/*  <LongMenu handleMenu={this.handleMenu}/>*/}
                                        {/*</CustomMenu>*/}
                                        {/*}*/}
                                    </div>
                                    {/*{!this.props.isCompleted && this.renderDates()}*/}
                                </div>

                            </Container>
                        )}
                    </Draggable>
                    <AntModal title="Select Lesson Plan Dates" visible={this.state.showEditor} onOk={() => {
                        this.saveData()
                        this.cancelDateModal()
                    }} onCancel={this.cancelDateModal.bind(this)}>
                        {/*<div>Dates</div>*/}
                        {/*{this.state.coldate.map((dt) => {*/}
                        {/*    return (<Chip label={dt}></Chip>)*/}
                        {/*})}*/}

                        <div style={{justifyContent: 'space-evenly', display: 'flex', marginTop: 20}}>
                            <Button className="blue-button" onClick={() => {
                                this.setState({showSpecificDates: true, showRangeDates: false})
                            }}>Select Specific Dates</Button>
                            {/*<Button className="blue-button" onClick={()=> {this.setState({showSpecificDates : false, showRangeDates : true})}}>Select Date Range</Button>*/}

                        </div>
                        {(this.state.showRangeDates || this.state.showSpecificDates) &&
                        <DayPicker
                            selectedDays={this.getSelectedDays()}
                            onDayClick={this.handleDayClick.bind(this)}
                        />

                        }
                    </AntModal>

                    <div style={{display: 'inline-block'}}>
                        {/*<AvatarGroup max={13 }>*/}
                        {this.props.task.type === 'lesson' && this.props.task.students && this.props.task.students.map(student => {
                            let studentid = 'student-' + student.value + "::" + this.props.task.id + "::" + this.props.column_id
                            let rating = this.getRating(student.value);
                            console.log('Scale', rating, student.value)
                            return (
                                <Draggable draggableId={studentid} index={this.props.index} key={studentid}>
                                    {(provided, snapshot) => (
                                        <div style={{display: 'flex'}}>
                                            {this.props.task.coldate !== null && this.props.isCompleted &&
                                            <Checkbox style={{marginRight: 5}}
                                                      checked={this.props.isCompleted}
                                                      onChange={() => this.undocompleteforStudent(student.value)}/>
                                            }
                                            <StudentContainer
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                isDragging={snapshot.isDragging}>
                                                <Studentchip
                                                    closeable={!this.props.isCompleted}
                                                    hash={this.props.studentRatingHash}
                                                    skillId={this.props.task.skillId}
                                                    closeStudent={() => this.closeStudent(student.value)}
                                                    noOverLay={true}
                                                    displayText={true}
                                                    rating={rating}
                                                    label={student.label} taskObj={this.props.task}
                                                    studentid={student.value}
                                                    studentObj={this.props.childcache[student.value]}
                                                    refreshData={() => this.props.refreshData()}/>
                                            </StudentContainer>
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })}
                        {/*</AvatarGroup>*/}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>

                        {(!this.props.isCompleted ) && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {this.props.task.coldate !== "" && <Popover content={this.getStudentList()} trigger="click">
                                <IconButton onClick={() => {
                                    this.setState({})
                                }}>
                                    <PersonAddIcon style={{width: 18, height: 18, color: 'gray'}}/>
                                </IconButton>
                            </Popover>}
                            <Dialog
                                open={this.state.multipleDatesAddModal}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle
                                    id="responsive-dialog-title">{"Add Students to which Dates ?"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Add Students for all Days or just
                                        to {moment(this.props.mycoldate).format('LL')}?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={() => this.addStudentToTask(false)} color="primary">
                                        Add to All dates
                                    </Button>
                                    <Button onClick={() => this.addStudentToTask(true)} color="primary" autoFocus>
                                        Add to {moment(this.props.mycoldate).format('LL')} Only
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <IconButton onClick={() => {
                                this.deleteTaskforDay()
                            }}>
                                <DeleteIcon style={{width: 18, height: 18, color: 'gray'}}/>
                            </IconButton>

                            <IconButton onClick={() => {
                                this.onCheckChange()
                            }}>
                                <EditIcon style={{width: 18, height: 18, color: 'gray'}}/>
                            </IconButton>

                            <IconButton onClick={() => {
                                console.log('Clicl !!')
                                this.setState({showEditor: true})
                            }
                            }>
                                <DateRangeIcon style={{width: 18, height: 18, color: 'gray'}}/>
                            </IconButton>
                        </div>}
                    </div>
                </MainContainer>
                <AntModal title={this.props.task.content.join('->') + ' (Update Status)'}
                          visible={this.state.isCheckModalVisible}
                          onOk={() => this.closeCheckModal()}
                          onCancel={() => this.closeCheckModal()}
                          width={800}
                          height={window.document.body.clientHeight - 300}
                          centered={true}
                          footer={[
                              // this.props.mycoldate !== null && <Button key="Ok" type={'Primary'} onClick={() => this.closeTaskForDay()}>
                              //     ARCHIVE
                              // </Button>,
                              <Button key="Ok" type={'Primary'} onClick={() => this.closeCheckModal()}>
                                  CLOSE
                              </Button>]}

                >
                    {this.getContentforModal()}
                </AntModal>
            </>
        );
    }

    async getMarkings(templateid,group_id) {
        const body = {
            "dashboard_id": templateid,
            "school_id": this.props.school_id[0],
            "group_id" : group_id,
        }
        const data = await recordPlannerService.getDashboardMarkings(body)
        //console.log(data)
        return data['status'] === 'success' ? data['data'] : []
    }


    getContentforModal() {
        var getRow = (studentData, isFlagged) => {
            console.log('studentData', studentData)

            var markcache = {}
            if (!isFlagged) {
                console.log(this.state.markingData)
                markcache = _.groupBy(this.state.markingData, 'child_id')
                console.log('markcache', markcache)
            }
            return studentData.map((student) => {
                let rating = this.props.studentRatingHash[this.props.task.skillId] === undefined ? 'F' :
                    this.props.studentRatingHash[this.props.task.skillId][student.value] === undefined ? 'F' :
                        this.props.studentRatingHash[this.props.task.skillId][student.value]
                let studentObj = this.props.childcache[student.value]
                if (!isFlagged) {
                    if (markcache[student.value] === undefined) {
                        rating = '-'
                    } else {
                        let marks = _.keyBy(markcache[student.value], 'skill_id')
                        rating = marks[this.props.task.skillId] === undefined ? '-' : marks[this.props.task.skillId].rating
                    }

                }
                return (
                    <>
                        <Row style={{marginTop: 20}}>
                            <Col span={6}>
                                <div>
                                    <StudentAvatar studentObj={studentObj} studentName={student.label}/>
                                    <span style={{marginLeft: 4}}>{student.label}</span>
                                </div>

                            </Col>
                            <Col span={18}>
                                <ActionBar skillid={this.props.task.skillId}
                                           refreshData={() => {
                                           }}
                                           studentRatingHash={this.props.studentRatingHash}
                                           studentid={student.value}
                                           coldates={this.props.task.coldate}
                                           divMode={true}
                                           group_id={this.props.task.group_id}
                                           selectedOption={rating}
                                           dashboard_id={this.props.task.dashboard_id}>
                                </ActionBar>
                            </Col>
                        </Row>
                    </>
                )
            })

        }

        // let otherstudentscache = this.props.childgroupcache[this.props.task.group_id]
        let otherstudent = []
        // let curstudentids = Object.keys(_.keyBy(this.props.task.students, 'value') )
        // console.log('props', this.props)
        // Object.keys(otherstudentscache).map((studentid)=>{
        //     if (!curstudentids.includes(studentid.toString())) {
        //         let student = otherstudentscache[studentid]
        //         //console.log('student', student)
        //         let item = {value: student.id, label: student.firstName + ' ' + student.lastName}
        //         otherstudent.push(item)
        //     }
        // })
        // console.log('otherstudent', otherstudent)
        return (
            <div>
                <div>Date Selected : {this.props.mycoldate === null ? "Unplanned" : this.props.mycoldate}</div>
                {getRow(this.props.task.students, true)}
                <Collapse style={{marginTop: 10}} onChange={() => {
                    this.setState({loadingOthers: true})
                    this.getMarkings(this.props.task.dashboard_id, parseInt(this.props.task.group_id)).then((response) => {
                        console.log('response', response)
                        this.setState({
                            markingData: response,
                            loadingOthers: false
                        })
                    })
                }}>
                    {false && <Panel header="Add Students" key="1">
                        {this.state.loadingOthers ? <Spin spinning={true}/> :
                            getRow(otherstudent, false)}
                    </Panel>}
                </Collapse>
            </div>
        )
    }

     deleteTaskforDay() {
        console.log('this.props', this.props)
            let data = []
            this.props.task.students.forEach((student) => {
                let studentid = student.value
                let rating = this.props.studentRatingHash[this.props.task.skillId] === undefined ? 'F' :
                    this.props.studentRatingHash[this.props.task.skillId][studentid] === undefined ? 'F' :
                        this.props.studentRatingHash[this.props.task.skillId][studentid]


                let {completedDates, activedates} = this.getDatesAfterRemoval(studentid, false);
                if (this.props.mycoldate === null){
                    activedates = []
                }
                let flag = activedates.length === 0 ? 0 : 1
                var entry = {
                    "school_id": this.props.school_id[0],
                    "dashboard_id": this.props.task.dashboard_id,
                    "child_id": studentid,
                    "skill_id": this.props.task.skillId,
                    "flag": flag,
                    "rating": rating,
                    "dates": activedates === null ? "UNPLANNED" : activedates,
                    "completeddates": completedDates == null ? [] : completedDates,
                    "comment": "",
                    "group_id" : this.props.task.group_id
                }
                data.push(entry)
            })

            recordPlannerService.addDashboardEntry(data).then((ret)=>{
                console.log(ret)
                if (ret.status === 'success') {
                    this.props.refreshData()
                } else {
                    alert('Error Saving Rating. Please try again !')

                }
            }).catch((err)=>alert('Error Deleting Task'))



    }

    //
    // closeTaskForDay() {
    //     this.setState({isCheckModalVisible: false})
    //     //this.props.refreshData()
    //     if (this.props.mycoldate != null) {
    //         let coldate = this.state.coldate
    //         const selectedIndex2 = coldate.findIndex(selectedDay =>
    //             selectedDay == this.props.mycoldate
    //         );
    //         if (selectedIndex2 !== -1) {
    //             coldate.splice(selectedIndex2, 1)
    //         }
    //         console.log(coldate)
    //         this.setState({coldate})
    //         this.saveSkillData(coldate)
    //     } else {
    //         console.log('Deleting in Unplanned')
    //         console.log('students ...', this.props.task.students)
    //         this.MarkAllStudentsasUnflagged();
    //     }
    // }

    async MarkAllStudentsasUnflagged() {

        let childs_ids = _.map(this.props.task.students, 'value')
        this.removeStudentfromPlan(childs_ids, true, false)
        console.log(this.props.task.students, childs_ids)
        console.log('Calling Refresh !!!')
    }

    undocompleteforStudent(studentid) {
        if (this.props.mycoldate != null) {
            let students = _.map(this.props.task.students, 'value')
            let coldate = this.props.studentDateHash[this.props.task.skillId][studentid]
            if (coldate == undefined) return
            let completedDates = this.state.completedDates
            console.log(coldate, completedDates)

            const selectedIndex2 = completedDates.findIndex(selectedDay =>
                selectedDay == this.props.mycoldate
            );
            if (selectedIndex2 !== -1) {
                completedDates.splice(selectedIndex2, 1)
            }


            coldate.push(this.props.mycoldate)
            coldate = _.uniq(coldate, false)
            completedDates = _.uniq(completedDates, false)
            console.log(coldate, completedDates)
            this.setState({coldate, completedDates})
            //this.saveSkillData(coldate, completedDates,[studentid])
            let rating = this.props.studentRatingHash[this.props.task.skillId] === undefined ? 'F' :
                this.props.studentRatingHash[this.props.task.skillId][studentid] === undefined ? 'F' :
                    this.props.studentRatingHash[this.props.task.skillId][studentid]

            this.updateStudentData(studentid, 1, rating, completedDates, coldate, true);
        }
    }
}


// import React from 'react';


const
    options = [
        'Edit',
        'Archive',
        // 'Move',
        // 'Copy',
        // 'Ganymede',
        // 'Hangouts Call',
        // 'Luna',
        // 'Oberon',
        // 'Phobos',
        // 'Pyxis',
        // 'Sedna',
        // 'Titania',
        // 'Triton',
        // 'Umbriel',
    ];

const
    ITEM_HEIGHT = 48;

function

LongMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        // console.log(e)
        props.handleMenu(e);
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{padding: 0}}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} onClick={() => handleClose(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

const
    mapStateToProps = state => ({
        school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
        childcache: state.currentUser.newUserInfo.childrencache || {},
        childgroupcache: state.currentUser.newUserInfo.childgroupcache
    })

const
    mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)

(
    Task
)