import React, {Component, useState, useEffect} from 'react';
import '../../css/plan.css';
import _ from 'lodash'
// import Board from 'react-trello';
// import {NewCardComponent} from './components/createCard';
import {DragDropContext} from 'react-beautiful-dnd';
import initialData from './components/sample-data';
import Column from './components/column';
import Topbar from './components/topbar';
import moment from 'moment';
import styled from 'styled-components';
import recordPlannerService from '../../service/recordplanner'
import {connect} from "react-redux";
import LoadingOverlay from 'react-loading-overlay';

import {Select} from 'antd';

const StyledLoader = styled(LoadingOverlay)`
  .MyLoader_overlay {
    background: rgba(224, 225, 224, 0.5);
  }
`
const Container = styled.div`
  display: flex;
`;

const Screen = styled.div`
  background-color: aliceblue;
  overflow-x: scroll;
  ::-webkit-scrollbar {display:none;}
`;

const { Option } = Select

const Plan = (props) => {
    const [tasks, setTasks] = useState(initialData.tasks);
    const [columns, setColumns] = useState({});
    const [columnOrder, setColumnOrder] = useState(initialData.columnOrder);
    const [currentWeek, setCurrentWeek] = useState(4);
    const [selectedGroupId, setselectedGroupId] = useState(parseInt(localStorage.getItem('p_gnum')))
    const [studentDateHash, setstudentDateHash] = useState({})
    const [studentCompleteDateHash, setstudentCompleteDateHash] = useState({})
    const [studentRatingHash, setstudentRatingHash] = useState({})
    const [mytasks, setmytasks] = useState([])
    const [flaggedTasks, setflaggedTasks] = useState([])
    const [completedTasks, setcompletedTasks] = useState([])
    const [isLoading, setisLoading] = useState(false)

    const [lessonsHash, setLessonsHash] = useState({})
    const [formattedStudents, setFormattedStudents] = useState([])

    const getWeekDays = (weekStart) => {
        const days = [weekStart];
        for (let i = 1; i < 7; i += 1) {
            days.push(moment(weekStart).add(i, 'days').toDate());
        }
        return days;
    };

    const getWeekRange = (date) => {
        return {
            from: moment(date).startOf('week').toDate(),
            to: moment(date).endOf('week').toDate(),
        };
    };


    const deleteTask = (e) => {
        let tasksObj = {...tasks};
        //    tasks.filter(task=>task.id !== e.id)
        for (let taskNo in tasksObj) {
            console.log(tasksObj[taskNo])
            if (tasksObj[taskNo].id === e.id) {
                delete tasksObj[taskNo];
            }
        }
        setTasks(tasksObj)
        console.log(e, tasksObj);
    };

    const [selectedDays, setSelectedDays] = useState(
        getWeekDays(getWeekRange(new Date()).from)
    );

    const [showDate, setShowDate] = useState([{title: '', title2: ''}]);

    async function getFlaggedStudents() {
        let body = {
            "school_id": props.school_id[0],
            "group_id": parseInt(selectedGroupId)
        }
        let ret = await recordPlannerService.getFlaggedStudents(body)

        setisLoading(false)
        return ret
    }

    async function getTasks() {
        let body = {
            "school_id": props.school_id[0],
            "group_id": selectedGroupId
        }
        let ret = await recordPlannerService.getTasks(body)
        return ret
    }

    function processsetShowDate() {
        setShowDate([
            {
                title: moment(selectedDays[1]).format('ll'),
                title2: moment(selectedDays[1]).format('dddd'),
                coldate: moment(selectedDays[1]).format('L')
            },
            {
                title: moment(selectedDays[2]).format('ll'),
                title2: moment(selectedDays[2]).format('dddd'),
                coldate: moment(selectedDays[2]).format('L')
            },
            {
                title: moment(selectedDays[3]).format('ll'),
                title2: moment(selectedDays[3]).format('dddd'),
                coldate: moment(selectedDays[3]).format('L')
            },
            {
                title: moment(selectedDays[4]).format('ll'),
                title2: moment(selectedDays[4]).format('dddd'),
                coldate: moment(selectedDays[4]).format('L')
            },
            {
                title: moment(selectedDays[5]).format('ll'),
                title2: moment(selectedDays[5]).format('dddd'),
                coldate: moment(selectedDays[5]).format('L')
            },
        ]);
    }

    function setSkillHashFromTemplates(templates) {
        var _skillshash = {}
        var skillArray = []

        function _flatten(node, keyArray, key, templateId) {
            if (typeof node !== "object") {
                // We reached a string
                keyArray.push(key)
                var formattedValue = keyArray.join(" > ")
                _skillshash[node] = {
                    topic: keyArray,
                    formatted_topic: formattedValue,
                    dashboard_id: templateId
                }

            } else {
                if (key !== '')
                    keyArray.push(key)
            }
            if (typeof node == "object") {
                if (Array.isArray(node)) {
                    node.forEach((obj) => {
                        Object.keys(obj).forEach((key) => {
                            return _flatten(obj[key], keyArray.slice(0), key, templateId)
                        })
                    })
                } else {

                    Object.keys(node).forEach((key) => {
                        return _flatten(node[key], keyArray, key, templateId)
                    })
                }
            }
        }

        templates.forEach((template) => {
            let ret = _flatten(template['template'], skillArray, '', template['id'])
            skillArray = []
        })

        return _skillshash
    }


    async function saveSkillData(taskObj) {

        let students = _.map(taskObj.students, 'value')
        if (students.length == 0) {
            alert('Error Saving Student Data. No Student Found !')
        }
        var entry = {
            "school_id": props.school_id[0],
            "dashboard_id": taskObj.dashboard_id,
            "child_ids": students,
            "skill_id": taskObj.skillId,
            "schedule": taskObj.coldate,
            "completeddates": taskObj.completedDates,
            "group_id" : selectedGroupId,

        }
        console.log('entry=>>', entry)
        console.log('taskObj=>>', taskObj)

        const ret = await recordPlannerService.setStudentSchedule(entry)
        console.log(ret)
        if (ret.status === 'success') {
            refreshFlaggedStudentData()
        } else {
            alert('Error Saving Rating. Please try again !')
        }
    }


    const hashCode = function (s) {
        var h = 0, l = s.length, i = 0;
        if (l > 0)
            while (i < l)
                h = (h << 5) - h + s.charCodeAt(i++) | 0;
        return h;
    };

    function setTasksFromTemplates(flaggedData, _skillshash) {

        let Tasks = {}
        let CompletedTasks = {}
        let _skillsDateHash = {}
        let _studentDateHash = {}
        let _studentCompleteDateHash = {}
        let _studentRatingHash = {}
        let index = 0
        flaggedData.forEach((flaggedRow) => {
            if (_skillshash[flaggedRow.skill_id] !== undefined) {
                console.log('flaggedRow', flaggedRow)
                var taskObj = null
                var completedtaskObj = null
                let coldates = flaggedRow.schedule?.length > 0 ? flaggedRow.schedule : null
                let completeddates = flaggedRow.completed?.length > 0 ? flaggedRow.completed : null

                let taskIndex = flaggedRow.skill_id //+'-' +  hashCode(coldatesstr).toString()
                console.log('taskIndex', taskIndex)
                flaggedRow.schedule = flaggedRow.schedule == null ? [] : flaggedRow.schedule
                flaggedRow.completed = flaggedRow.completed == null ? [] : flaggedRow.completed
                flaggedRow.schedule.forEach((scheduledt) => {

                    if (Tasks[scheduledt] === undefined) {
                        Tasks[scheduledt] = {}
                    }
                    if (Tasks[scheduledt][taskIndex] === undefined) {
                        let key = 'task-' + index.toString()
                        taskObj = {
                            deleted: false,
                            id: taskIndex,
                            type: 'lesson',
                            isCompleted: false,
                            skillId: flaggedRow.skill_id,
                            students: [],
                            dashboard_id: _skillshash[flaggedRow.skill_id].dashboard_id,
                            coldate: scheduledt,
                            completedDates: completeddates,
                            content: _skillshash[flaggedRow.skill_id].topic,
                            group_id: selectedGroupId
                        }
                    } else {
                        taskObj = Tasks[scheduledt][taskIndex]
                    }
                    let childname = props.childcache[flaggedRow.child_id] != undefined ? props.childcache[flaggedRow.child_id].firstName + ' ' + props.childcache[flaggedRow.child_id].lastName : 'UNKNOWN'
                    let student = {value: flaggedRow.child_id, label: childname}
                    if (flaggedRow.flag === 1) {
                        taskObj.students.push(student)
                        Tasks[scheduledt][taskIndex] = taskObj
                    }

                })
                if (flaggedRow.schedule.length === 0 && flaggedRow.flag === 1) {
                    if (Tasks['UNPLANNED'] === undefined) {
                        Tasks['UNPLANNED'] = {}
                    }

                    if (Tasks['UNPLANNED'][taskIndex] === undefined) {
                        taskObj = {
                            deleted: false,
                            id: taskIndex,
                            type: 'lesson',
                            isCompleted: false,
                            skillId: flaggedRow.skill_id,
                            students: [],
                            dashboard_id: _skillshash[flaggedRow.skill_id].dashboard_id,
                            coldate: '',
                            completedDates: completeddates,
                            content: _skillshash[flaggedRow.skill_id].topic,
                            group_id: selectedGroupId
                        }
                    } else {
                        taskObj = Tasks['UNPLANNED'][taskIndex]
                    }

                    //Add Child


                    let childname = props.childcache[flaggedRow.child_id] != undefined ? props.childcache[flaggedRow.child_id].firstName + ' ' + props.childcache[flaggedRow.child_id].lastName : 'UNKNOWN'
                    let student = {
                        value: flaggedRow.child_id,
                        label: childname
                    }
                    taskObj.students.push(student)

                    Tasks['UNPLANNED'][taskIndex] = taskObj
                }
                //********** Completed Tasks *********************
                flaggedRow.completed.forEach((completedt) => {
                    if (CompletedTasks[completedt] === undefined) {
                        CompletedTasks[completedt] = {}
                    }
                    console.log('FOUND COMPLETED TASKS !!!!')
                    let completeddatesstr = completeddates == null ? "" : completeddates.join('').replaceAll('/', '-')
                    let completedTaskIndex = 'C-' + flaggedRow.skill_id// + '-' + hashCode(completeddatesstr).toString()
                    console.log('completedTaskIndex', completedTaskIndex)
                    if (CompletedTasks[completedt][completedTaskIndex] === undefined) {
                        let key = 'task-' + index.toString()
                        completedtaskObj = {
                            deleted: false,
                            id: completedTaskIndex,
                            type: 'lesson',
                            isCompleted: true,
                            skillId: flaggedRow.skill_id,
                            students: [],
                            dashboard_id: _skillshash[flaggedRow.skill_id].dashboard_id,
                            completedDates: completeddates,
                            coldate: completeddates,
                            content: _skillshash[flaggedRow.skill_id].topic,
                            group_id: selectedGroupId
                        }
                    } else {
                        completedtaskObj = CompletedTasks[completedt][completedTaskIndex]
                    }


                    //Add Child
                    let childname = props.childcache[flaggedRow.child_id] != undefined ? props.childcache[flaggedRow.child_id].firstName + ' ' + props.childcache[flaggedRow.child_id].lastName : 'UNKNOWN'
                    let student = {
                        value: flaggedRow.child_id,
                        label: childname
                    }
                    if (completeddatesstr !== null) {
                        completedtaskObj.students.push(student)
                    }

                    CompletedTasks[completedt][completedTaskIndex] = completedtaskObj
                })

                console.log("-->", Tasks)
                let coldatesArray = coldates == null ? ['unplanned'] : coldates

                index = index + 1
            }
            //******** Create Student/Skill/Date Hash ********
            if (_studentDateHash[flaggedRow.skill_id] === undefined) {
                _studentDateHash[flaggedRow.skill_id] = {}
                _studentCompleteDateHash[flaggedRow.skill_id] = {}
            }
            _studentDateHash[flaggedRow.skill_id][flaggedRow.child_id] = flaggedRow.schedule
            _studentCompleteDateHash[flaggedRow.skill_id][flaggedRow.child_id] = flaggedRow.completed
            //******** Create Student/Rating Hash ********
            if (_studentRatingHash[flaggedRow.skill_id] === undefined) {
                _studentRatingHash[flaggedRow.skill_id] = {}
            }
            _studentRatingHash[flaggedRow.skill_id][flaggedRow.child_id] = flaggedRow.rating
        })
        console.log('Tasks', Tasks)
        console.log('CompletedTasks', CompletedTasks)
        setflaggedTasks(Tasks);
        setcompletedTasks(CompletedTasks)
        // Tasks = _.values(Tasks)
        // Tasks = _.keyBy(Tasks, 'id')
        // CompletedTasks = _.values(CompletedTasks)
        // CompletedTasks = _.keyBy(CompletedTasks, 'id')
        console.log('Tasks', Tasks)
        console.log('CompletedTasks', CompletedTasks)
        console.log('_studentDateHash', _studentDateHash)
        setstudentDateHash(_studentDateHash)
        setstudentRatingHash(_studentRatingHash)
        setstudentCompleteDateHash(_studentCompleteDateHash)


    }

    useEffect(() => {
        let cols = {}
        console.log('----------------')
        const array1 = [1, 2, 3, 4, 5, 6];
        array1.map(columnId => {
            let colid = "column-" + columnId.toString()
            console.log('-', colid)
            var column = {
                id: colid,
                title: 'Unplanned',
                title2: '',
                taskIds: [],
                coldate: null
            }
            cols[colid] = column
        })
        setColumns(cols)
    }, [])

    const formatStudents = () => {
        var studentscache = props.childgroupcache[selectedGroupId]
        var students = [];
        Object.keys(studentscache).map((studentid) => {
            var student = studentscache[studentid];
            students.push(<Option key={studentid}>{student.firstName + ' ' + student.lastName}</Option>);
        })
        setFormattedStudents(students);
    };

    function refreshFlaggedStudentData() {
        getFlaggedStudents().then(data => {
            console.log('data', data)
            if (data['status'] === 'success') {
                let _skillshash = setSkillHashFromTemplates(data['templates'])
                setTasksFromTemplates(data['data'], _skillshash)
                formatStudents();
                setLessonsHash(_skillshash);
            }
	     else{
                setTasksFromTemplates([], {})
            }
        })
    }

    function refreshTaskData(){
        getTasks().then(data => {
            console.log('data', data)
            if (data['status'] === 'success') {
                let _mytasks = data['data']
                setmytasks(data['data'])
            }
        })
    }

    useEffect(() => {
        processsetShowDate();
        if (selectedGroupId !== null) {
            setisLoading(true)
            refreshFlaggedStudentData();
           // refreshTaskData();
        }
    }, [selectedGroupId])

    useEffect(() => {
        processsetShowDate();
    }, [selectedDays]);


    const getDatesAfterMove = (taskobj, studentid, fromDt, toDt) => {
        //Delete Completed if previously completed
        let completedDates = studentCompleteDateHash[taskobj.skillId][studentid] == undefined ? [] : studentCompleteDateHash[taskobj.skillId][studentid]
        console.log('completedDates', completedDates)
        const selectedIndex2 = completedDates.findIndex(selectedDay =>
            selectedDay == toDt
        );

        console.log('selectedIndex2', selectedIndex2)
        if (selectedIndex2 !== -1) {
            completedDates.splice(selectedIndex2, 1)
        }
        console.log('completedDates', completedDates)
        completedDates = _.uniq(completedDates, false)
        // Move date ....
        let activedates = studentDateHash[taskobj.skillId][studentid] == undefined ? [] : studentDateHash[taskobj.skillId][studentid]
        const selectedIndex = activedates.findIndex(selectedDay =>
            selectedDay == fromDt
        );
        if (selectedIndex !== -1) {
            activedates.splice(selectedIndex, 1)
        }
        activedates.push(toDt)
        activedates = _.uniq(activedates, false)
        return {completedDates, activedates};
    }


    const onDragEnd = (result) => {
        const {destination, source, draggableId} = result;
        console.log(destination, source, draggableId)
        console.log(columns)
        console.log(flaggedTasks)

        if (!destination) {
            return;
        }
        if (!source) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            return;
        }

        let tasks = Object.assign({}, flaggedTasks)
        let columnDate = columns[destination.droppableId].coldate
        let oldcolumnDate = columns[source.droppableId].coldate === null ? "UNPLANNED" : columns[source.droppableId].coldate
        console.log('oldcolumnDate', oldcolumnDate)
        console.log('columnDate', columnDate)

        if (draggableId.startsWith('student-')) {
            //STUDENT is DRAGGED !!!
            console.log('----------- STUDENT !!!!', draggableId.split("::"))
            let taskid = draggableId.split("::")[1]
            let studentid = draggableId.split("::")[0].replace('student-', '')
            console.log(taskid, studentid)
            if (tasks[oldcolumnDate][taskid] !== undefined) {
                var taskobj = tasks[oldcolumnDate][taskid]
                let skillid = taskobj.skillId
                console.log(skillid, columnDate)
                console.log('taskobj', taskobj)
                let coldates = Array.isArray(taskobj.coldate) ? taskobj.coldate : [taskobj.coldate]
                coldates = Object.assign([], coldates)
                console.log('coldates', coldates)
                let data = []


                let rating = studentRatingHash[taskobj.skillId] === undefined ? 'F' :
                    studentRatingHash[taskobj.skillId][studentid] === undefined ? 'F' :
                        studentRatingHash[taskobj.skillId][studentid]

                let {completedDates, activedates} = getDatesAfterMove(taskobj, studentid, oldcolumnDate, columnDate);

                let flag = activedates.length === 0 ? 0 : 1
                var entry = {
                    "school_id": props.school_id[0],
                    "dashboard_id": taskobj.dashboard_id,
                    "child_id": [studentid],
                    "skill_id": taskobj.skillId,
                    "flag": flag,
                    "rating": rating,
                    "dates": activedates,
                    "completeddates": completedDates == null ? [] : completedDates,
                    "comment": "",
                    "group_id" : selectedGroupId,
                }


                console.log(entry)
                recordPlannerService.addDashboardEntry(entry).then((ret)=>{
                    console.log('Done ...', ret)
                    if (ret.status === 'success') {
                        refreshFlaggedStudentData()
                    } else {
                        alert('Error processing the move. Please try again !')

                    }
                })


            }


        } else {
            //TASK is DRAGGED !!!

            console.log(draggableId, tasks)
            let _draggableId = draggableId.split('::')[0]
            if (tasks[oldcolumnDate][_draggableId] !== undefined) {
                console.log('moving from ' + oldcolumnDate + ' to ' + columnDate)
                // let coldates = Object.assign([], tasks[oldcolumnDate][_draggableId].coldate)
                // let index = coldates.indexOf(oldcolumnDate)
                // coldates.splice(index, 1)
                // coldates.push(columnDate)
                var taskobj = tasks[oldcolumnDate][_draggableId]
                let data = []
                taskobj.students.forEach((student) => {
                    let studentid = student.value
                    let rating = studentRatingHash[taskobj.skillId] === undefined ? 'F' :
                        studentRatingHash[taskobj.skillId][studentid] === undefined ? 'F' :
                            studentRatingHash[taskobj.skillId][studentid]

                    let {
                        completedDates,
                        activedates
                    } = getDatesAfterMove(taskobj, studentid, oldcolumnDate, columnDate);

                    let flag = activedates.length === 0 ? 0 : 1
                    var entry = {
                        "school_id": props.school_id[0],
                        "dashboard_id": taskobj.dashboard_id,
                        "child_id": studentid,
                        "skill_id": taskobj.skillId,
                        "flag": flag,
                        "rating": rating,
                        "dates": activedates,
                        "completeddates": completedDates == null ? [] : completedDates,
                        "comment": "",
                        "group_id" : selectedGroupId,
                    }
                    data.push(entry)
                })
                console.log(data)
                recordPlannerService.addDashboardEntry(data).then((ret)=>{
                    console.log('Done ...', ret)
                    if (ret.status === 'success') {
                        refreshFlaggedStudentData()
                    } else {
                        alert('Error processing the move. Please try again !')

                    }
                })

            }
        }
    };


    //console.log('columnOrder', columnOrder)
    return (
        <Screen>

            <Topbar
                datesData={[]}
                setSelectedDays={setSelectedDays}
                selectedDays={selectedDays}
                getWeekDays={getWeekDays}
                getWeekRange={getWeekRange}
                groupcache={props.groupcache}
                setClass={setselectedGroupId}
            />
            {isLoading &&
            <StyledLoader
                active={isLoading}
                spinner
                classNamePrefix='MyLoader_'
                text='Loading your content...'
            >
                <div style={{height : 800, backgroundColor : 'gray'}}></div>
            </StyledLoader>}
            <>

                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Container>

                        {Object.keys(columns).map((columnId, ind) => {
                            console.log(columnId, ind)
                            var column = columns[columnId]
                            //console.log('showDate', showDate)

                            if (ind) column.title = showDate[ind - 1]?.title;
                            if (ind) column.title2 = showDate[ind - 1]?.title2;
                            if (ind) column.coldate = showDate[ind - 1]?.coldate;
                            //***** Assign Flagged Tasks to Columns ********

                            //console.log('flaggedTasks', flaggedTasks)
                            let coldt = column.coldate == null ? "UNPLANNED" : column.coldate
                            let tasks = flaggedTasks[coldt] === undefined ? {} : flaggedTasks[coldt]
                            //console.log(flaggedTasks, tasks, coldt, column.coldate)
                            // tasks = _.filter(tasks, function (o){
                            //   let columndate = column.coldate === null ? "" : column.coldate.toString()
                            //   let taskdate =  o.coldate === null ? [] : o.coldate
                            //   return (columndate === "" && o.coldate === null)
                            //         || (taskdate.findIndex(x => x == columndate.toString()) > -1)
                            // })
                            // //***** Assign Completed Tasks to Columns ********
                            let ctasks = completedTasks[column.coldate] === undefined ? {} : completedTasks[column.coldate]
                            // console.log('completedTasks', completedTasks)
                            // ctasks = _.filter(completedTasks, function (o){
                            //   let columndate = column.coldate === null ? "" : column.coldate.toString()
                            //   let taskdate =  o.completedDates === null ? [] : o.completedDates
                            //   return (columndate === "" && o.coldate === null)
                            //       || (taskdate.findIndex(x => x == columndate.toString()) > -1)
                            // })
                            console.log('Tasks', tasks, ctasks, column.coldate)
                            return (
                                <Column
                                    key={column.id}
                                    column={column}
                                    studentDateHash={studentDateHash}
                                    studentRatingHash={studentRatingHash}
                                    studentCompleteDateHash={studentCompleteDateHash}
                                    coldate={column.coldate}
                                    tasks={_.values(tasks)}
                                    mytasks={mytasks}
                                    completedTasks={_.values(ctasks)}
                                    onDelete={deleteTask}
                                    refreshData={() => refreshFlaggedStudentData()}
                                    selectedDays={selectedDays}
                                    groupId={selectedGroupId}
                                    formattedStudents={formattedStudents}
                                    lessonsHash={lessonsHash}
                                />
                            );
                        })}
                    </Container>
                </DragDropContext>
            </>
        </Screen>
    );
};

const mapStateToProps = state => ({
    school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
    groupcache: (state.currentUser.newUserInfo.groupcache) || {},
    childcache: state.currentUser.newUserInfo.childrencache || {},
    childgroupcache: state.currentUser.newUserInfo.childgroupcache || {},
    userInfo: state.currentUser.newUserInfo
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Plan)
