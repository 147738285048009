import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Card, withStyles, Fab, Container, Button} from "@material-ui/core";
import Image from "../../assets/images/welcome/360.png"
import * as routes from "../../routes/path";
import ButtonBase from '@material-ui/core/ButtonBase';
import AppHeader from "./appheader";
import ImageParent from "../../assets/images/signupparent.png"
import ImageTeacher from "../../assets/images/signupteacher.png"
import ImageNewSchool from "../../assets/images/signupschool.png";
const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

        padding: '5em'
    },
    card: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    header: {
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 32,
        color: '#6E75FE',
    },
    SigupActionBox: {
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop : 50,
        alignContent: 'center',

    },
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,

    },
    focusVisible : {

    },
    image: {
        position: 'relative',
        borderColor: 'gray',
        borderSize: 1,
        height: 200,

    },
        actionButton : {
            position: 'relative',
            borderColor: '#6E75FE',
            height: 300,
            borderWidth: '1px',
            color : 'black',
            '&:hover': {
                backgroundColor: '#EBECFF',
                color: 'black',
            },
        },
        button : {
        backgroundColor : '#6E75FE',
        alignItems: 'center',
        paddingLeft : 50,
        paddingRight : 50,
        width : 620,
        color : 'white',
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            },
    },
});

const SIGNUP_SCREEN = {
    MAIN : 0, EDUCATOR : 1, PARENT  :2
}

const Signup = ({classes}) => {
  return (
      <div>
          <AppHeader/>
          <Container className={classes.main}>

                <div className="text-center mt-2">
                    <div className={classes.header}>What Best Describes You ?</div>
                </div>
                <div className={classes.SigupActionBox}>
                    <Card style={{marginRight: 10}}>
                        <Link to={routes.SIGN_UP_EDUCATOR}>
                            <ButtonBase
                                focusRipple
                                className={classes.actionButton}
                                focusVisibleClassName={classes.focusVisible}
                                style={{
                                    width: 300,
                                    borderRadius: '5px',
                                    border: 'solid 2px #6E75FE',

                                }}
                            >
                                <div className="transparent-container">
                                    <div className="inline-div">
                                        <img className={classes.image} src={ImageTeacher}/>
                                    </div>
                                    <div className="inline-div">
                                        I am an Educator
                                    </div>


                                </div>
                            </ButtonBase>
                        </Link>
                    </Card>
                    <Card style={{marginLeft: 10}}>
                        <Link to={routes.SIGN_UP_CODE}>
                        <ButtonBase
                            focusRipple
                            className={classes.actionButton}
                            focusVisibleClassName={classes.focusVisible}
                            style={{
                                width: 300,
                                borderRadius: '5px',
                                border: 'solid 2px #6E75FE',
                            }}
                        >
                            <div className="transparent-container">
                                <div className="inline-div">
                                    <img className={classes.image} src={ImageParent}/>
                                </div>
                                <div className="inline-div">
                                    I am a Parent
                                </div>


                            </div>
                        </ButtonBase>
                        </Link>
                    </Card>
                </div>
                <div style={{marginTop : 20}}>
                    <Link  to={routes.SIGN_IN}>
                        <Button
                            variant="contained"
                            type={'submit'}
                            className={classes.button}

                        >
                            I  have an account
                        </Button>
                    </Link>
                </div>
            </Container>
      </div>
        );

}


export default withStyles(styles)(Signup);