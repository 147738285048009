import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import * as  routes from '../routes/path';
import AdminRoutes from '../routes/AdminRoutes';
import ParentRoutes from '../routes/ParentRoutes'
import { fetchAuthUserSuccess, newUserInfoSuccess } from '../redux/actions/current-user';
import authService from '../service/auth';
import OtherRoutes from '../routes/OtherRoutes';
import { fetchUserCatch } from '../redux/actions/cache';

import userAvatar from '../assets/images/user-avtar.png';
import Badge from '@material-ui/core/Badge';
import { getUserRole } from "../utils/utils"
import {firebaseInit} from "./../utils/firebase-config"
import { getSchoolId } from '../component/SchoolProfile/components/GlobalComponents/GlobalFields';
import NewNotification from "./../utils/newNotification";
import awsConfig from "../utils/aws-config"
import {Button, PageHeader} from 'antd';
import CacheBuster from "../component/cachebuster";
import CustomizedSnackbars from "../component/common/CustomizedSnackbars";
import {LESSON} from "../routes/path";

const drawerWidth = 240;

const adminRoute = [
    { name: "News Feed", url: routes.ROOT },
    { name: "News Letter", url: routes.NEWSLETTER },
    { name: "Briefcase", url: routes.BRIEFCASE },
    { name: "Chat", url: routes.CHATS },
    { name: "Events", url: routes.EVENT },
    { name: "Progress Report", url: routes.ProgressReport },
    // { name: "Progress Report Admin", url: routes.ProgressReportAdmin },
    { name: "School Profile", url: routes.MAIN_DASHBOARD },
    { name: "My Profile", url: routes.TEACHER_PROFILE },
    { name: "Pre Checkin Form", url: routes.TEACHER_PRE_CHECKIN_FORM },
    { name: "Lesson Planning", url: routes.LESSON },
    // { name: "Plan", url: routes.PLAN_LESSSONS },

    /*{ name: "My Classes", url: routes.CLASSES_DASHBOARD },
    { name: "My Profile", url: routes.TEACHER_PROFILE },
    { name: "Pre Checkin Form", url: routes.TEACHER_PRE_CHECKIN_FORM },*/
];

const teacherRoute = [
    { name: "News Feed", url: routes.ROOT },
    { name: "News Letter", url: routes.NEWSLETTER },
    { name: "Briefcase", url: routes.BRIEFCASE },
    { name: "Chat", url: routes.CHATS },
    { name: "Events", url: routes.EVENT },
    { name: "Progress Report", url: routes.ProgressReport },
    { name: "My Classes", url: routes.CLASSES_DASHBOARD },
    { name: "My Profile", url: routes.TEACHER_PROFILE },
    { name: "Pre Checkin Form", url: routes.TEACHER_PRE_CHECKIN_FORM },
    { name: "Lesson Planning", url: routes.LESSON },
    // { name: "Plan", url: routes.PLAN_LESSSONS },
]

const parentRoute = [
	{name: 'News Feed', url: routes.ROOT},
	{name: 'News Letter', url: routes.NEWSLETTER},
	{name: 'Chat', url: routes.CHATS},
	{name: 'Events', url: routes.EVENT},
	{name: 'Progress Report', url: routes.ProgressReport},
	{name: 'My Profile', url: routes.PARENT_PROFILE},
	{name: 'Pre Checkin Form', url: routes.PRE_CHECKIN_FORM},
	// {name: 'Plan', url: routes.PLAN},
];

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: '#000',
        backgroundColor: '#fff',
        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.16)'
    },
    header: {
        flexGrow: 1,
        font: ' 15px/28px "Lato", sans-serif',
    },
    name:{
        font: '700 15px/28px "Lato", sans-serif',
        textTransform: 'capitalize',
    },
    rightContainer: {
        marginRight: 12,
    },
    smallAvatar: {
        width: 30,
        height: 30,
    },
    logoButton: {
        width:'50px',
        marginLeft: 16,
        marginRight: 36,
        cursor: 'pointer',
        transform: 'rotate(2deg)'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(1) * 7 + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(1) * 9 + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(1) * 3,
    },
    chatContent:{
        flexGrow: 1,
    },
    newEvent: {
        flex: 5
    }
});

const INITIAL_STATE = {
    openDrawer: true,
    anchorElProfileMenu: null,
    anchorElNotificationMenu: null,
    open: false,
    routeName: 'News Feed',
    isLoading: false
}

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    async componentWillMount() {
        const { dispatch } = this.props;
        const signInUserSession = JSON.parse(localStorage.getItem('hmUser'));
        if (signInUserSession) {
            this.setState({
                isLoading: true
            })
            dispatch(fetchAuthUserSuccess(signInUserSession));

            const newUserResponse = await authService.getNewUserDetail();
            // const userResponse = await authService.getUserDetail();
            if ((newUserResponse.data || {}).user && Object.keys((newUserResponse.data || {}).user || {}).length && !getUserRole((newUserResponse.data || {}).user, 'parent')){
                dispatch(fetchUserCatch.request());
            }
           
            if (newUserResponse.data) {
                dispatch(newUserInfoSuccess(newUserResponse.data));
                //console.log(newUserResponse.data.user)
                await firebaseInit(newUserResponse.data.user);
                // dispatch(userInfoSuccess(userResponse));
            }
            this.setState({
                isLoading: false
            })
        }
    }

    handleProfileMenu = event => {
        this.setState({ anchorElProfileMenu: event.currentTarget });
    };

    handleProfileClose = () => {
        this.setState({ anchorElProfileMenu: null });
    };

    onClickLogout = (event) => {
        const { dispatch } = this.props;
        dispatch({type : 'LOGOUT_USER'})
        localStorage.removeItem('hmUser');
        this.props.history.push('/signin');
    }

    render() {
        const { classes, currentUser: { newUserInfo: { user = {}, isTeacher = false, isAdmin = false, isParent = false } } } = this.props;
        const { anchorElProfileMenu, isLoading } = this.state;
        const openProfileMenu = Boolean(anchorElProfileMenu);
        const { firstName = "", lastName = "", profile_photo = "", id = "" } = user.attributes || {}
        const menuList = isAdmin ? adminRoute : isTeacher ? teacherRoute : isParent ? parentRoute : []
        if(isLoading) {
            return (
                <div className="text-center mt-60">
                    <Spin/>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <NewNotification/>
                <div style={{width: '100%'}}>
                    <div className="layout-header">
                        <PageHeader
                            className="load"
                            title={
                                <div style={{  display: 'flex',}}>
                                    <span><img src={awsConfig.app_logo} alt="school_logo" className="header-school-logo" /></span>
                                    <div style={{marginTop : 5}}>
                                    <span style={{fontSize: 30}}>{awsConfig.school_name}</span>
                                    </div>
                                </div>
                            }
                            style={{
                                border: '1px solid rgb(235, 237, 240)',
                                width: '100%',
                                padding: "0px 24px",
                            }}
                            extra={[
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <span className="fs-16">Welcome <span className={classes.name}> {firstName || ""} </span></span>
                                        <IconButton
                                            aria-owns={openProfileMenu ? 'menu-appbar' : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleProfileMenu}
                                            color="inherit"
                                        >
                                            {
                                                profile_photo ?
                                                    <Avatar
                                                        className={classes.smallAvatar}
                                                        alt={firstName}
                                                        src={profile_photo}
                                                    /> :
                                                    <Avatar aria-label="Recipe" className={classes.avatar} src={userAvatar} style={{height: 30, width: 30}}/>
                                            }

                                        </IconButton>
                                        <Popper
                                            id="menu-appbar"
                                            open={openProfileMenu}
                                            anchorEl={anchorElProfileMenu}
                                            onClose={this.handleProfileClose}
                                            transition
                                            disablePortal
                                            style={{zIndex:'1'}}>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    id="menu-list-grow"
                                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={this.handleProfileClose}>
                                                            <MenuList>
                                                                <MenuItem onClick={this.handleProfileClose}>
                                                                    {firstName || ""} {lastName || ""}
                                                                </MenuItem>
                                                                <MenuItem onClick={this.onClickLogout}>LogOut</MenuItem>
                                                                <MenuItem>
                                                                    <CacheBuster>
                                                                        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                                                                            console.log("=========================================", global, global.appVersion)
                                                                            if (loading) return null;
                                                                            if (!loading && !isLatestVersion) {
                                                                                // You can decide how and when you want to force reload
                                                                                refreshCacheAndReload();
                                                                            }

                                                                            return (
                                                                                <div className="App">

                                                                                        <div>
                                                                                            version - <code>v{global.appVersion}</code>
                                                                                        </div>

                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </CacheBuster>
                                                                </MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                        <div>
                                            <img className={classes.logoButton} src={awsConfig.app_logo} alt="Logo" style={{marginLeft: 0, marginRight: 0}} />
                                        </div>
                                    </div>
                                    {/*<div style={{textAlign: 'end'}}><small>Powered By 360 Memos</small></div>*/}
                                </div>
                            ]}
                        >
                            {
                                (menuList || []).map((x, index) => {
                                    return(
                                        <span className="mr-4 fs-18" key={index.toString()}>
                                            <Link to={x.url} onClick={() => this.setState({routeName: x.name})} style={x.url === window.location.pathname ? {color: '#e6c500', fontWeight: 700} : {color: 'white'}}>
                                                {x.name==="Chat"?(<Badge badgeContent={this.props.totalnotifications} color="secondary">{`${x.name}`}</Badge>) : `${x.name}`}
                                            </Link>
                                        </span>
                                    )
                                })
                            }
                        </PageHeader>
                    </div>
                    {
                        window.location.pathname.includes("/admin/") && !window.location.pathname.includes(routes.MAIN_DASHBOARD) && isAdmin ?
                            <div className="green-button text-center mb-3 mt-3">
                                <Button shape="round" size="large" className="mr-3" onClick={() => this.props.history.push(routes.MAIN_DASHBOARD)}>
                                    <span style={{color: '#3cc89c'}}>Overview</span>
                                </Button>
                                <Button shape="round" size="large" className="mr-3" onClick={() => this.props.history.push(routes.GROUPS_DASHBOARD)}>
                                    <span style={{color: '#3cc89c'}}>Manage Classes</span>
                                </Button>
                                <Button shape="round" size="large" className="mr-3" onClick={() => this.props.history.push(routes.STUDENT_DASHBOARD)}>
                                    <span style={{color: '#3cc89c'}}>Manage Students</span>
                                </Button>
                                <Button shape="round" size="large" onClick={() => this.props.history.push(routes.STAFF_DASHBOARD)}>
                                    <span style={{color: '#3cc89c'}}>Manage Staff</span>
                                </Button>
                            </div> : null
                    }

                    <div className="content">
                        <main className={!(window.location.pathname.includes(routes.CHATS))?classes.content:classes.chatContent} style={{overflow: 'auto', background: 'rgb(241, 243, 249)', minHeight: 'calc(100vh - 155px)'}}>
                            { getUserRole(user, 'teacher') && !getUserRole(user, 'admin') && <Route component={OtherRoutes} path={routes.ROOT} />}
                            { getUserRole(user, 'admin') && <Route component={OtherRoutes} path={routes.ROOT} />}
                            { getUserRole(user, 'admin') && <Route component={AdminRoutes} path={routes.ROOT} />}
                            { getUserRole(user, 'parent') && !getUserRole(user, 'admin') && !getUserRole(user, 'teacher') && <Route component={ParentRoutes} path={routes.ROOT} />}
                        </main>
                    </div>
                </div>
                <CustomizedSnackbars/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    pageLoaderCount: state.component.pageLoaderCount,
    toast: state.component.toast,
    chatrooms:state.chatrooms,
    totalnotifications:state.chatrooms.totalnotifications,
});




export default connect(mapStateToProps)(withStyles(styles)(Layout));