import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { API } from 'aws-amplify';
import _ from 'lodash';
import {Icon, Spin, Tooltip, Button as AntdButton} from 'antd';
import {
    Grid,
    Typography,
    withStyles,
    Paper,
    InputBase,
    IconButton,
    Avatar,
    FormControl,
    InputLabel,
    Select,
    FilledInput,
    MenuItem,
    Radio,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Checkbox,
    Button,
    RadioGroup,
    FormControlLabel
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './styles.css';
import {
    SEMESTER_STATUS,
    SEMESTER_STATUS_COLORS,
    getSemesterStatus,
} from '../../../../shared/semester';
import moduleService from '../service/module';
import * as Routes from "../../../../routes/path";

const useStyles = theme => ({
    selectGroup: {
        width: '100%',
    },
    paper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',

        backgroundColor: '#CECECE',
        opacity: 0.45,
        color: 'black',

        // width: '90%',
        // width: 350,
        // marginBottom: theme.spacing(3),
    },
    searchRoot: {
        padding: '2px 4px',
    },
    rdborder: {
        borderLeft: '2px solid #EFEFEF',
        textAlign: 'center',
        paddingLeft: theme.spacing(3),
    },
    xxfilter: {
        paddingLeft: '20px',
    },
    input: {
        margin: 0,
        marginLeft: 8,
        flex: 1,
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold'
    },
    iconButton: {
        padding: 10,
        color: 'black',
    },
    regularFont: {
        fontSize: '10px',
    },
    boldFont: {
        fontSize: '12px',
        fontWeight: 'bold',
    },
    avt: {
        width: 30,
        height: 30,
        marginRight: '10px',
    },
    // root: {
    //     display: 'flex',
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(1),
    //   },
    title: {
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
        // marginLeft: theme.spacing(2),
        marginLeft: '0px',
        marginRight: '0px',
    },
    progressRoot: {
        flexGrow: 1,
        maxWidth: 180,
        paddingTop: theme.spacing(0.5),
    },
    item: {
        marginBottom: theme.spacing(3),
    },
    innerCard: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    progressText: {
        paddingLeft: theme.spacing(1),
    },
    customProgress: {},
    childAvatar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: 'none',
    },

    semesterStatus: {
        fontSize: '0.8rem',
    },
});

class GroupsStudents extends Component {
    constructor(props){
        super(props)
        this.state = {
            values: {
                search: '',
                markFilter: '',
                selectedGroup: {
                    name: '',
                },
                module: {
                    module: '',
                },
                childIds: [],
                allSelected: false,
            },
            isLoading: false
        }
    }

    componentDidMount() {
        const { params } = this.props.match
        if(params.moduleId){
            this.fetchAllData(params.moduleId)
        }
    }

    getMarkedStatus = () => {
        const { values } = this.state
        if (values.module.groups) {
            const currentInfo = Object.values(values.module.groups).filter(g =>
                g.name.toLowerCase().includes(values.selectedGroup.name.toLowerCase()),
            );

            const childsInfo = currentInfo.reduce(
                (acc, val) => acc.concat(val.childids),
                [],
            );

            const markedStatus = childsInfo.reduce(
                (acc, val) => acc + val.summary.length,
                0,
            );

            const ratio = childsInfo
                ? Number(
                markedStatus /
                (values.module.years * values.module.slices * childsInfo.length),
            ).toFixed(2) * 100
                : 0;

            return Number(ratio).toFixed(2);
        }

        return '';
    };

    handlePublish = async () => {
        const { values } = this.state
        const { childIds } = values;
        const { moduleId } = this.props.match.params;

        const promises = childIds.map(async childId => {
            try {
                const init = {
                    body: {
                        child_id: Number(childId),
                        clazz_id: Number(moduleId),
                    },
                };
                await API.post('update_semester', '', init);
            } catch (e) {
                console.log('error!!');
            }
        });

        const result = await Promise.all(promises);

        this.setState({
            values: {
                ...values,
                childIds: [],
            }
        });

        this.fetchAllData(moduleId);
    };

    fetchAllData = async (module_id) => {
        this.setState({
            isLoading: true
        })

        const response = await moduleService.getModuleById(module_id)
        if(response && response.status === "success") {
            const data = response.data || {}
            const sGroup = Object.values(data.groups).filter(
                group => group.name.toLowerCase() === this.props.match.params.groupName.toLowerCase(),
            )[0];

            this.setState({
                values: {
                    ...this.state.values,
                    module: data,
                    selectedGroup: sGroup
                },
                isLoading: false
            });
        } else {
            this.setState({
                isLoading: false
            });
        }
    };

    handleAllSelection = e => {
        if (e.target.checked) {
            const students = this.getStudents();
            const childIds = students.map(student => student.childid);

            this.setState({
                values: {
                    ...this.state.values,
                    childIds,
                    allSelected: true,
                }
            });
        } else {
            this.setState({
                values: {
                    ...this.state.values,
                    allSelected: false,
                    childIds: [],
                }
            });
        }
    }

    getStudents = () => {
        const { values } = this.state
        return Object.values(values.module.groups)
            .filter(g =>
                g.name.toLowerCase().includes(values.selectedGroup.name.toLowerCase()),
            )
            .map(group =>
                Object.values(group.childids).filter(
                    s =>
                        (s.firstname.toLowerCase().includes(values.search.toLowerCase()) ||
                            s.lastname.toLowerCase().includes(values.search.toLowerCase())) &&
                        s.status.toLowerCase().includes(values.markFilter.toLowerCase()),
                ),
            )
            .reduce((result, item) => result.concat(item), []);
    };

    handleChildSelection = e => {
        const { values } = this.state
        const childId = Number(e.target.value);
        const { childIds } = values;

        if (e.target.checked && !childIds.includes(childId)) {
            this.setState({
                values: {
                    ...values,
                    childIds: childIds.concat([childId]),
                }
            });
        } else if (!e.target.checked && childIds.includes(childId)) {
            this.setState({
                values: {
                    ...values,
                    childIds: childIds.reduce(
                        (result, id) => (id === childId ? result : result.concat([id])),
                        [],
                    ),
                }
            });
        }
    };

    handleGoToStudentDetails = (id, ss, color) => {
        const { moduleId } = this.props.match.params
        const studentName = `${ss.firstname} ${ss.lastname}`;
        this.props.history.push({
            pathname: `/groups/details/${moduleId}/${id}/${studentName}`,
            state: { backgroundColor: color },
        });
    };

    renderSemesterStatus = (childId, student, color) => {
        const { values } = this.state
        const { classes } = this.props
        const { module } = values;

        return Array.from({
            length: values.module.years * values.module.slices,
        }).map((_, index) => {
            const status = getSemesterStatus(
                module,
                childId,
                (index + 1) % values.module.slices === 0
                    ? (index + 1) / values.module.slices
                    : Math.floor((index + 1) / values.module.slices) + 1,
                (index % values.module.slices) + 1,
            );

            return (
                <TableCell
                    scope="col"
                    align="center"
                    key={`xx${index}`}
                    onClick={() => this.handleGoToStudentDetails(childId, student, color)}
                    style={{ minWidth: '100px ' }}
                >
                    <Typography
                        className={classes.semesterStatus}
                        style={{ color: SEMESTER_STATUS_COLORS[status] }}
                    >
                        {status === SEMESTER_STATUS.ready ? '' : status}
                    </Typography>
                </TableCell>
            );
        });
    };

    renderSemesters = () => {
        const { values } = this.state
        const { role, classes } = this.props
        return (
            <TableHead style={{ overflowX: 'auto' }}>
                <TableRow style={{ backgroundColor: '#fafafa' }}>
                    { role === 'admin' && <TableCell /> }
                    <TableCell
                        scope="col"
                        align="left"
                        // colSpan={1}
                        // className={classes.childAvatar}
                    />

                    {Array.from({
                        length: values.module.years,
                    }).map((yearIndex, index) => (
                        <TableCell
                            align="center"
                            key={index + 1}
                            scope="colgroup"
                            colSpan={values.module.slices}
                            className={classes.tableCellHead}
                        >
                            <Typography
                                variant="subtitle1"
                                style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}
                            >
                                Year {index + 1}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>

                <TableRow style={{ backgroundColor: '#5D7EB0' }}>
                    { role === 'admin' && (
                        <TableCell
                            rowSpan={1}
                            className={classes.tableCell}
                            padding="checkbox"
                        >
                            <Checkbox
                                checked={values.allSelected}
                                onChange={this.handleAllSelection}
                                // color="white"
                                style={{ color: 'white', fontWeight: 'bold' }}
                                inputProps={{
                                    'aria-label': 'secondary checkbox',
                                }}
                            />
                        </TableCell>
                    )}
                    <TableCell
                        scope="col"
                        align="center"
                        // colSpan={1}
                        className={`text-l ${role}`}
                    >
                        <Typography
                            style={{ marginLeft: '12px', color: 'white' }}
                            className={classes.boldFont}
                        >
                            Student Name
                        </Typography>
                    </TableCell>
                    {Array.from({
                        length: values.module.years * values.module.slices,
                    }).map((_, index) => (
                        <TableCell scope="col" align="center" key={`xx${index}`}>
                            <Typography
                                variant="subtitle1"
                                style={{ color: 'white' }}
                                className={classes.boldFont}
                            >
                                Semester {(index % values.module.slices) + 1}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    getStudentList = () => {
        const { groupList = [] } = this.props
        const { values: { module: { groups } = {}, selectedGroup: { name = "" }, search = "", markFilter = "" } = {} } = this.state

        const groupId = (groupList || []).find(x => (name || "").toLowerCase() ===  (x.attributes.groupname || "").toLowerCase())

        let list = []

        groups &&
        Object.values(groups)
            .filter(g => g.name.toLowerCase() == name.toLowerCase(),
            )
            .forEach(group =>
                Object.values(group.childids)
                    .forEach(s =>
                        {
                            if(
                                (s.firstname
                                        .toLowerCase()
                                        .includes(search.toLowerCase()) ||
                                    s.lastname
                                        .toLowerCase()
                                        .includes(search.toLowerCase())) &&
                                s.status
                                    .toLowerCase()
                                    .includes(markFilter.toLowerCase()) &&
                                (name && this.getChildGroups(s.childid, (groupId || {}).id))
                            ) {
                                list.push(s)
                            }
                        }
                    ))
        console.log('list', list)
        list = _.orderBy(list, ['firstname'],['asc']);
        console.log('list', list)
        return list || []

    }

    getChildGroups = (childId, groupId) => {
        const { childrenCache } = this.props

        const data = childrenCache && childrenCache[childId] || {}
        return ((data.group_ids || []).some(x => (x || "").toString() === (groupId || "").toString())) || false
    }

    renderChilds = (color) => {
        const { values } = this.state
        const { role, classes } = this.props
        return (
            <TableBody>
                { (this.getStudentList() || [])
                    .map(student => (
                        <TableRow>
                            { role === 'admin' && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={values.childIds.includes(student.childid)}
                                        onChange={this.handleChildSelection}
                                        value={student.childid}
                                        color="primary"
                                        size="small"
                                        inputProps={{
                                            'aria-label': 'secondary checkbox',
                                        }}
                                    />
                                </TableCell>
                            )}

                            <TableCell
                                scope="col"
                                align="center"
                                style={{ minWidth: '200px', width: '200px' }}
                                // colSpan={1}
                                // className={classes.childAvatar}
                                className={role}
                                onClick={() =>
                                    this.handleGoToStudentDetails(student.childid, student, color)
                                }
                            >
                              <span
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                  }}
                              >
                                <Avatar className={classes.avt}>
                                  {student.firstname.substring(0, 1)}
                                </Avatar>
                                <Typography
                                    // className={classes.regularFont}
                                    variant="subtitle1"
                                    className={classes.regularFont}
                                >
                                  {`${student.firstname} ${student.lastname}`}
                                </Typography>
                              </span>
                            </TableCell>

                            {this.renderSemesterStatus(student.childid, student, color)}
                        </TableRow>
                    ))
                }
            </TableBody>
        );
    };

    render() {
        const { classes, role, location: { state: { backgroundColor = "" } } = {}, isAdmin, history } = this.props
        const { values, isLoading } = this.state
        console.log({values})
        return(
            <div className="report-student-list">

                {
                    isAdmin ?
                        <div className="green-button text-center mb-5">
                            <AntdButton shape="round" size="large" className="mr-3" onClick={() => history.push(Routes.ProgressReport)}>
                                <span style={{color: '#3cc89c'}}>Create/Mark Progress Report</span>
                            </AntdButton>
                            <AntdButton shape="round" size="large" onClick={() => history.push(Routes.ProgressReportAdmin)}>
                                <span style={{color: '#3cc89c'}}>Publish/Email Progress Report</span>
                            </AntdButton>
                        </div> : null
                }

                <div className="group-student">

                    <div className="main-header" style={{background: backgroundColor}}>
                        <div className="header-content">
                            <div className="header-content1">
                                <div className="back">
                                    <Tooltip title={"Back"} placement="bottomLeft">
                                        <Icon
                                            type="left"
                                            className="color-white cursor-pointer"
                                            onClick={() => window.history.back()}
                                        />
                                    </Tooltip>
                                </div>
                                <h3 className="mb-0 color-white">{values.module.modul}</h3>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>

                    <div className="content">

                        <Grid container direction="row" className={classes.root} width="100%">

                            <Grid container direction="row" item xs={12} width="100%">
                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    className="mb-10-xs"
                                    style={{ maxHeight: '46px' }}
                                >
                                    <FormControl
                                        variant="filled"
                                        className={classes.selectGroup}
                                        style={{ height: '100%' }}
                                    >
                                        <InputLabel htmlFor="filled-age-simple">
                                            <Typography
                                                variant="subtitle1"
                                                style={{ fontSize: '10px', fontWeight: 'bold' }}
                                            >
                                                Select Group
                                            </Typography>
                                        </InputLabel>
                                        <Select
                                            className={`${classes.select} ${classes.regularFont}`}
                                            value={values.selectedGroup}
                                            onChange={e =>
                                                this.setState({ values: {...values, selectedGroup: e.target.value }})
                                            }
                                            input={
                                                <FilledInput
                                                    className={classes.regularFont}
                                                    name="age"
                                                    id="filled-age-simple"
                                                    value={values.search}
                                                    onChange={e =>
                                                        this.setState({ values: {...values, search: e.target.value }})
                                                    }
                                                />
                                            }
                                        >
                                            <MenuItem className={classes.regularFont} value={{ name: '' }}>
                                                <em>All</em>
                                            </MenuItem>
                                            {values.module.groups &&
                                            Object.values(values.module.groups)
                                            // .filter(g =>
                                            //   g.name.toLowerCase().includes(values.search.toLowerCase()),
                                            // )
                                                .map(group => (
                                                    <MenuItem
                                                        key={group.name}
                                                        value={group}
                                                        className={classes.regularFont}
                                                    >
                                                        {group.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid
                                    item
                                    md={3}
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    className={`${classes.searchContainer} mb-10-xs`}
                                >
                                    <Paper className={`${classes.paper} search-xs`}>
                                        <InputBase
                                            className={classes.input}
                                            value={values.search}
                                            onChange={e => this.setState({ values: {...values, search: e.target.value }})}
                                            placeholder="Search by student name"
                                            inputProps={{ 'aria-label': 'Search Google Maps' }}
                                            style={{ color: 'black', opacity: 1 }}
                                        />
                                        <IconButton className={classes.iconButton} aria-label="Search">
                                            <SearchIcon fontSize="small" />
                                        </IconButton>
                                    </Paper>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction="row"
                                    width="100%"
                                    md={5}
                                    xs={12}
                                    className="radio-container-xs mb-10-xs"
                                    style={{ justifyContent: 'flex-start' }}
                                >
                                    <RadioGroup
                                        style={{
                                            width: '80%',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                        }}
                                        className="af-start"
                                        aria-label="position"
                                        name="position"
                                        value={values.markFilter}
                                        onChange={e =>
                                            this.setState({ values: {...values, markFilter: e.target.value }})
                                        }
                                        row
                                    >
                                        <Typography
                                            style={{
                                                fontWeight: 'bold',
                                                color: '#3f51b5',
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginRight: '16px',
                                                marginBottom: '0px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            Filter By
                                        </Typography>
                                        <FormControlLabel
                                            value=""
                                            control={
                                                <Radio className={classes.regularFont} color="primary" />
                                            }
                                            label="All"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Complete"
                                            control={
                                                <Radio color="primary" className={classes.regularFont} />
                                            }
                                            label="Complete"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="In Progress"
                                            control={
                                                <Radio color="primary" className={classes.regularFont} />
                                            }
                                            label="In Progress"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </Grid>

                                <Grid
                                    item
                                    md={2}
                                    xs={12}
                                    style={{
                                        marginBottom: '0px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                    className={`${classes.item} f-start mb-10-xs`}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                                    >
                                        Class status - {this.getMarkedStatus()}%{' '}
                                        <span
                                            style={{
                                                fontSize: '12px ',
                                                fontWeight: 'bold',
                                                color: 'green',
                                            }}
                                        >
                                        Marked
                                    </span>
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                container
                                xs={12}
                                direction="row"
                                width="100%"
                                className="f-start"
                                style={{ justifyContent: 'flex-end', marginBottom: '20px' }}
                            >

                                { role === 'admin' && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={this.handlePublish}
                                        className="publishBtn"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            style={{ fontSize: '10px', fontWeight: 'bold' }}
                                        >
                                            Publish to Parent(s)
                                        </Typography>
                                    </Button>
                                )}

                            </Grid>

                        </Grid>

                        {
                            isLoading ? <div className="text-center mt-60"><Spin/></div> :
                                <Paper className="w-100-p" style={{padding: '0px'}}>
                                    {
                                        (this.getStudentList() || []).length ?
                                            <Table className="progress-report-table">
                                                {this.renderSemesters()}
                                                {this.renderChilds(backgroundColor)}
                                            </Table> :
                                            <div className="text-center p-5">No students found!</div>
                                    }

                                </Paper>
                        }

                    </div>

                </div>
            </div>
        )
    }
}

GroupsStudents.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    role: (state.currentUser.userInfo.role) || "",
    groupList: state.currentUser.newUserInfo.groupList || [],
    childrenCache: state.currentUser.newUserInfo.childrencache || [],
    isAdmin: state.currentUser.newUserInfo.isAdmin
})

const enhance = compose(
    withRouter,
    withStyles(useStyles, 'some style'),
    connect(mapStateToProps, null),
)

export default enhance(GroupsStudents);
