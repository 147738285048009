import React, { useEffect, useState } from 'react';
import awsConfig from "../../../utils/aws-config"


function AsyncImage(props) {
  const [src, setSrc] = useState('')

  useEffect(() => {
    getImage(props.src);
  }, [props.src])

  async function getImage(imgId) {
    // const src = await Storage.get(imgId, { level: "public" });
    // setSrc(src)
    setSrc(`${awsConfig.media_url}${(imgId || "").includes("public/") ? imgId : `public/${imgId}`}`)
  };
  
  return (
    <img
      {...props}
      src={src}
      alt={props.alt}
    />
  )

}

AsyncImage.defaultProps = {
  src: '',
  alt: ''
};

export default AsyncImage;