import React, { Component, Fragment } from 'react'
import {
    Transfer, Avatar, Form, Input, Button, Row, Select, Card,
    Col, Popover, notification, Checkbox, Spin, message, List
} from 'antd';
import { connect } from 'react-redux';
import studentService from '../service/student';
import { fetchChildListSuccess } from '../redux/actions/child';
import groupService from '../service/group';
import authService from '../service/auth';
import { userInfoSuccess } from '../redux/actions/current-user';


const FormItem = Form.Item;
const { Option } = Select;
// const TabPane = Tabs.TabPane;

const Content = (props) => (
    <div>
        {props.parents.map(parent =>
            <p key={parent}>{parent}</p>
        )}
    </div>
);


function sortFunction(a, b) {
    const nameA = a.firstname.toUpperCase();
    const nameB = b.firstname.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}

const INITIAL_STATE = {
    mockData: [],
    targetKeys: [],
    students: [],
    groups: [],
    isLoading: false,
    transferType: 'student'
}

class GroupBulk extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
    }

    componentDidMount() {
        this.fetchStudents();
    }


    fetchStudents = async () => {
        try {
            const { dispatch, match: { params: { groupname } } } = this.props;
            this.setState({ isLoading: true });
            const { data: groupStudent } = await studentService.getStudentList(groupname);
            const { data: allStudents } = await studentService.getStudentList('all');
            dispatch(fetchChildListSuccess(allStudents));
            const child = groupStudent.map(group => group.childid);
            const mockData = allStudents
                .sort((a, b) => sortFunction(a, b))
                .map(student => { return { ...student, key: student.childid }; });

            const targetKeys = allStudents
                .filter(student => child.includes(student.childid))
                .map(student => student.childid)

            this.setState({ mockData, targetKeys, groupname, isLoading: false, students: mockData });

        } catch (error) {
            message.error('something not right!')
            this.setState({ isLoading: false });
        }
    }

    /*    fetchGroup = async () => {
           try {
               const data = await groupService.getGroupList();
               const mockData = [];
               data.forEach(group => {
                   group.key = group.groupname;
                   mockData.push(group)
               });
               this.setState({ groups: mockData })
           } catch (error) {
     
           }
       } */

    changeTransfer = (e) => {
        const { students, groups } = this.state;
        const targetKeys = [];
        const mockData = e === 'student' ? students : groups;
        this.setState({ transferType: e, mockData, targetKeys });
    }

    handleTransferChange = (targetKeys) => {
        this.setState({ targetKeys });
    }

    renderStudentRow = (item) => {
        const customLabel = (
            <Fragment>
                <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', margin: 5 }}>{item.firstname[0]}</Avatar>
                <span>{item.firstname} {item.lastname}</span>
                <Popover content={(<Content {...item} />)} title="Parent">
                    <Button style={{ float: "right" }} type="primary" shape="circle" icon="home" />
                </Popover>
            </Fragment>
        )
        return {
            label: customLabel,
            value: item.firstname + item.lastname,
        };
    }

    renderGroupRow = (item) => {
        const customLabel = (
            <Fragment>
                <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf', margin: 5 }}>{item.groupname[0]}</Avatar>
                <span>{item.groupname.split('_').join(' ')}</span>
            </Fragment>
        )

        return {
            label: customLabel,
            value: item.groupname,
        };
    }

    handleSubmit = (e) => {
        try {
            e.preventDefault();
            const { targetKeys, transferType } = this.state;
            const { dispatch, form, history } = this.props;

            form.validateFields(async (err, values) => {
                if (!err) {
                    console.log(values)
                    if (targetKeys.length > 0) {
                        this.setState({ isLoading: true });
                        const attendance_mode = values.attendance_mode ? 1 : 0;
                        const body = {
                            ...values,
                            groupid: this.props.match.params.groupname,
                            attendance_mode,
                        }
                        transferType === 'student' ? body.students = targetKeys : body.selectedgroups = targetKeys;
                        await groupService.addGroupsStudentsAdd(body);
                        // const userResponse = await authService.getUserDetail();
                        // if (userResponse.Username) {
                        //     dispatch(userInfoSuccess(userResponse))
                        // }
                        this.setState({ isLoading: false });
                        message.success('success');
                        history.goBack()
                    }
                    else {
                        notification.warning({
                            message: 'Invalid Info',
                            description: 'at least one Student or group required',
                        });
                    }
                }
            });

        } catch (error) {
            message.error('something not right!')
            this.setState({ isLoading: false });
        }
    }

    selectHandleChange = (value) => {
        const { students } = this.state;
        if (value === 'all') {
            this.setState({ mockData: students });
        } else {
            const data = students && students.filter(child => child.groups.some(group => group === value));
            this.setState({ mockData: data });
        }
    }

    filterOption = (inputValue, option) => (
        option.firstname.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.lastname.toLowerCase().includes(inputValue.toLowerCase())
    )


    render() {
        const { groups, form: { getFieldDecorator } } = this.props;
        const { transferType, students, mockData, targetKeys, groupname, isLoading } = this.state;
        const group = groups[groupname];

        const finalList = students.filter(student => targetKeys.includes(student.childid))

        return (
            <div>
                <div>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <Form onSubmit={this.handleSubmit}>
                            <Card title="Group Setting"
                                bordered={false}
                                className="card"
                                extra={
                                    <Fragment>
                                        <Button type="primary" htmlType="submit" disabled={isLoading} style={{ margin: ' -5px 5px' }}>Submit</Button>
                                        <Button type="default" onClick={() => { this.props.history.goBack() }} style={{ margin: ' -5px 5px' }}>Cancel</Button>
                                    </Fragment>
                                }
                            >
                                <Row type="flex" gutter={16} align="bottom">
                                    <Col span={6}>
                                        <FormItem label="Group Name" >
                                            {getFieldDecorator('groupname', {
                                                initialValue: group ? group.GroupName : '',
                                                rules: [{ required: true, message: 'Please input your groupname!' }],
                                            })(
                                                <Input placeholder="Group Name" disabled />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={6}>
                                        <FormItem label="Description"  >
                                            {getFieldDecorator('desc', {
                                                initialValue: group ? group.Description : '',
                                                rules: [{ required: true, message: 'Please input your Description!' }],
                                            })(
                                                <Input placeholder="Description" />
                                            )}
                                        </FormItem>
                                    </Col>

                                    <Col span={4}>
                                        <FormItem>
                                            {getFieldDecorator('attendance_mode', {
                                                valuePropName: 'checked',
                                                initialValue: false,
                                                rules: [{
                                                    required: true
                                                }]
                                            })(
                                                <Checkbox >Attendance Mode</Checkbox>
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Card>

                            <Row >
                                <Col span={8}>
                                    <Card
                                        title="Group Members"
                                        bordered={false}
                                        className="card"
                                        extra={<span>{finalList.length} Members</span>}
                                    >
                                        <div className="demo-infinite-container">
                                            <List
                                                dataSource={finalList}
                                                renderItem={item => (
                                                    <List.Item key={item.key}>
                                                        <List.Item.Meta
                                                            avatar={<Avatar>{item.firstname[0]}</Avatar>}
                                                            title={<button className="btn btn-link">{item.firstname}{item.lastname}</button>}
                                                            description=''
                                                        />
                                                        <div>
                                                            <Popover content={(<Content {...item} />)} title="Parent">
                                                                <Button type="primary" shape="circle" icon="home" />
                                                            </Popover>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            />
                                        </div >
                                    </Card>
                                </Col>

                                <Col span={16}>
                                    <Card
                                        title="Edit Group Members"
                                        bordered={false} className="card"
                                        extra={
                                            <Select
                                                showSearch
                                                defaultValue="all"
                                                style={{ width: 350, margin: '-5px' }}
                                                placeholder="Select a Group"
                                                optionFilterProp="children"
                                                onChange={this.selectHandleChange}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                            >
                                                <Option value="all">All Group</Option>
                                                {groups && Object.keys(groups).map(key =>
                                                    <Option value={groups[key].groupid} key={groups[key].groupid}>
                                                        {groups[key].GroupName}
                                                    </Option>
                                                )}
                                            </Select>
                                        }
                                    >

                                        <FormItem>
                                            <Transfer
                                                dataSource={mockData}
                                                targetKeys={targetKeys}
                                                showSearch
                                                filterOption={(inputValue, option) => this.filterOption(inputValue, option)}
                                                listStyle={{
                                                    width: 350,
                                                    height: 515,
                                                    margin: 4
                                                }}
                                                lazy={{ height: 45, offset: 45 }}
                                                onSearchChange={(d, e) => console.log(d, e)}
                                                onChange={this.handleTransferChange}
                                                render={transferType === 'student' ? this.renderStudentRow : this.renderGroupRow}
                                            />
                                        </FormItem>
                                    </Card>
                                </Col>

                            </Row>
                        </Form>
                    </Spin>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    groups: state.currentUser.userInfo.Groups || []
});

const WrappedForm = Form.create()(GroupBulk);
const WrappedConnect = connect(mapStateToProps)(WrappedForm)


export default WrappedConnect;