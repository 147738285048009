import React from "react"
import {connect} from "react-redux";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import {Table, InputNumber, Radio, Icon, Select, Input, Modal, Tooltip, Avatar, Dropdown, Button, Col, Row} from "antd";
import {Grid, Paper, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {getFormattedDate, getSchoolId, openNotificationWithIcon} from "../../../GlobalComponents/GlobalFields";
import { preCheckinObject } from "../../../GlobalComponents/optionValues";
import {
    createStaffCheckin,
    createStaffPreCheckin,
    getStaffCheckinData
} from "../../../../ApiServices";
import TriangleImage from "../../../../../../assets/images/triangle.png";

const { TextArea } = Input
const { Option } = Select

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

const colStyles = {
    flexBasis: "20%",
    width: "20%"
};


class StaffAttendance extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            isModal: false,
            attendanceProcess: false,
            staffs: [],
            duplicateStaffs: [],
            staffDetails: {},
            commentObject: {},
            filterType: "all"
        }
    }

    async componentWillMount() {
        this.getAttendanceInfo()
    }

    getAttendanceInfo = async () => {
        this.setState({
            loading: true
        })

        const { location } = this.props;
        const { firstName = "", lastName = "", profile_photo = "", id = ""} = location.state || {}

        const payload = {
            school_id: getSchoolId(),
            staff_id: id
        }

        let staffs = []

        const response = await getStaffCheckinData(payload)
        if(response && response.status === "success") {

            response.data.forEach(x => {
                let body = {}
                body.pre_checkin = x.pre_checkin && Object.keys(x.pre_checkin || {}).length ? x.pre_checkin : cloneDeep(preCheckinObject)
                staffs.push({ ...x, ...body})
            })
        }

        const att_date = moment(new Date()).format("YYYY-MM-DD")

        if(!(staffs || []).some(x => x.att_date === att_date)) {
            staffs.push({
                att_date,
                attendance: {},
                staff_id: id,
                pre_checkin: cloneDeep(preCheckinObject)
            })
        }

        this.setState({
            staffs: cloneDeep(staffs).reverse(),
            duplicateStaffs: cloneDeep(staffs).reverse(),
            staffDetails: {
                firstName,
                lastName,
                profile_photo,
                id
            } || {},
            loading: false
        })
    }

    onCovidChange = (e, key, index) => {

        if(String(key) === "1" && e.target.value) {
            let that = this
            Modal.error({
                title: 'Please contact the school before coming in to share more details.',
                onOk() {
                    let { staffs } = that.state

                    staffs[index].pre_checkin.covid.covid_detail[String(key)].description = ""
                    staffs[index].pre_checkin.covid.covid_detail[String(key)].valid = e.target.value

                    that.setState({
                        staffs
                    })
                },
                onCancel() {
                    console.log("--")
                }
            })
        } else {
            let { staffs } = this.state

            staffs[index].pre_checkin.covid.covid_detail[String(key)].description = ""
            staffs[index].pre_checkin.covid.covid_detail[String(key)].valid = e.target.value

            this.setState({
                staffs
            })
        }
    }

    onTemperatureChange = (value, index) => {
        const { staffs } = this.state
        staffs[index].pre_checkin.covid.temperature = value
        this.setState({
            staffs
        })
    }

    getChildren = (record, key, index) => {
        const { currentUser: { isParent, isTeacher } } = this.props
        const valueObj = (((record.pre_checkin || {}).covid || {}).covid_detail || {})[key] || {}
        const att_date = moment(new Date()).format("MM-DD-YYYY")
        const isDisabled = att_date !== moment(record.att_date).format("MM-DD-YYYY") && isTeacher
        return (
            <div className="flex align-item-center">
                <Radio.Group value={valueObj.valid} onChange={(e) => this.onCovidChange(e, key, index)} disabled={isDisabled}>
                    <Radio value={false}>No</Radio>
                    <Radio value={true}>Yes</Radio>
                </Radio.Group>
                {
                    valueObj.valid ?
                        <div>
                            <Tooltip title={valueObj.description} >
                                <Icon type="message" theme="filled" className="cursor-pointer color-red" onClick={() => this.onCommentModalChange(key, index, isDisabled)} />
                            </Tooltip>
                        </div> : null
                }
            </div>
        )
    }

    onAttendanceChange = (value, index, fromSchool) => {
        let { staffs } = this.state
        if(fromSchool) {
            staffs[index].attendance = {
                [value]: {
                    attendance: {
                        status: value,
                        comment: ""
                    },
                    attendancetime: "",
                    signature: "",
                }
            }
        } else {
            staffs[index].pre_checkin = {
                ...staffs[index].pre_checkin,
                covid: {
                    ...staffs[index].pre_checkin.covid,
                    status: {
                        status: value,
                        comment: ""
                    },
                }
            }
        }
        this.setState({
            staffs
        })
    }

    getColumns = () => {
        const { currentUser: { isParent, isTeacher } } = this.props
        const disabled = isTeacher
        return [
            {
                title: 'Date',
                fixed: 'left',
                width: 130,
                render: (record) => {
                    const covidObject = (((record.pre_checkin || {}).covid || {}).covid_detail || {}) || {}
                    const array = Object.keys(covidObject || {}).map(x => covidObject[x].valid)
                    return(
                        <div className="ws-nowrap">
                            {record.att_date ? moment(record.att_date).format("MM-DD-YYYY") : "" || ""}
                            { (array || []).includes(true) ? <img src={TriangleImage} style={{width: 18}} /> : null }
                        </div>
                    )
                },
            },
            {
                title: 'Attendance marked at school',
                width: 200,
                fixed: 'left',
                render: (record, row, index) => {
                    const array = record.attendance && Object.keys(record.attendance || {})
                    const value = ((array || []).length && array[0]) || ""
                    const att_date = moment(new Date()).format("MM-DD-YYYY")
                    const isDisabled = att_date !== moment(record.att_date).format("MM-DD-YYYY")
                    return(
                        <div>
                            <Select
                                style={{width: 150}}
                                value={value}
                                onChange={(value) => this.onAttendanceChange(value, index, true)}
                                disabled={isDisabled && disabled}
                            >
                                <Option value="in">Present</Option>
                                <Option value="virtual">Virtual</Option>
                                <Option value="excusedtardy">Excused Tardy</Option>
                                <Option value="unexcusedtardy">Unexcused Tardy</Option>
                                <Option value="excusedabsence">Excused Absence</Option>
                                <Option value="unexcusedabsence">Unexcused Absence</Option>
                            </Select>
                        </div>
                    )
                },
            },
            {
                title: 'Decision for today',
                width: 200,
                fixed: 'left',
                render: (record, row, index) => {
                    const value = (((record.pre_checkin || {}).covid || {}).status || {}).status || ""
                    const att_date = moment(new Date()).format("MM-DD-YYYY")
                    const isDisabled = att_date !== moment(record.att_date).format("MM-DD-YYYY")
                    return(
                        <div>
                            <Select
                                style={{width: 150}}
                                value={value}
                                onChange={(value) => this.onAttendanceChange(value, index, false)}
                                disabled={isDisabled && disabled}
                            >
                                <Option value="inclass">In Class</Option>
                                <Option value="virtual">Virtual</Option>
                                <Option value="excusedabsence">Excused Absence</Option>
                            </Select>
                        </div>
                    )
                },
            },
            {
                title: 'Temperature',
                width: 120,
                render: (record, row, index) => {
                    const value = ((record.pre_checkin || {}).covid || {}).temperature || ""
                    const att_date = moment(new Date()).format("MM-DD-YYYY")
                    const isDisabled = att_date !== moment(record.att_date).format("MM-DD-YYYY")
                    return(
                        <div>
                            <InputNumber
                                style={{width: 75}}
                                value={value}
                                onChange={(value) => this.onTemperatureChange(value, index)}
                                disabled={isDisabled && disabled}
                            />
                            { (this.state.errorIndex || []).includes(index) ? <small className="color-red">Required</small> : null }
                        </div>
                    )
                },
            },
            {
                title: 'Fever Reducing Medication Administered?',
                width: 200,
                render: (record, text, index) => {
                    const key = "0"
                    return(
                        this.getChildren(record, key, index)
                    )
                }
            },
            {
                title: 'Have you or anyone in your household travelled to a state or country listed on the Covid-19 travel advisory list in the last 14 days?',
                width: 200,
                render: (record, text, index) => {
                    const key = "1"
                    return(
                        this.getChildren(record, key, index)
                    )
                }
            },
            {
                title: 'Close contact with Anyone Diagnosed with COVID-19 in the Past 14 Days?',
                width: 200,
                render: (record, text, index) => {
                    const key = "2"
                    return(
                        this.getChildren(record, key, index)
                    )
                }
            },
            {
                title: 'Child/Staff Symptoms(s)?',
                children: [
                    {
                        title: 'Cough, Shortness of Breath, Trouble Breathing',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "3"
                            return(
                                this.getChildren(record, key, index)
                            )
                        }
                    },
                    {
                        title: 'headache, Fever, Muscle pain, Chills, Repeated Shaking with Chills, New Loss of Taste or Smell',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "4"
                            return(
                                this.getChildren(record, key, index)
                            )
                        }
                    },
                ],
            },
            {
                title: 'Household Member Symptoms(s)?',
                children: [
                    {
                        title: 'Cough, Shortness of Breath, Trouble Breathing',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "5"
                            return(
                                this.getChildren(record, key, index)
                            )
                        }
                    },
                    {
                        title: 'headache, Fever, Muscle pain, Chills, Repeated Shaking with Chills, New Loss of Taste or Smell',
                        width: 200,
                        render: (record, text, index) => {
                            const key = "6"
                            return(
                                this.getChildren(record, key, index)
                            )
                        }
                    },
                ],
            },
            {
                title: 'Form Filled By',
                width: 150,
                render: (record) => {
                    const value = (record.pre_checkin || {}).check_in_by || ""
                    return(
                        <div>
                            { value }
                        </div>
                    )
                }
            },
            {
                title: 'Signature',
                width: 130,
                render: (record) => {
                    const value = ((record.pre_checkin || {}).covid || {}).signature || ""
                    return(
                        <div>
                            <img src={this.getImage(value)} style={{width: 55}} />
                        </div>
                    )

                }
            }
        ];
    }

    getImage = (string) => {
        let src = ""
        if(string) {
            src = "data:image/png;base64," + string
        }
        return src
    }

    commentModal = () => {
        const { isModal, commentObject } = this.state
        const { description, isDisabled } = commentObject || {}
        if(!isModal) return;
        return(
            <Modal
                title="Comment"
                visible={isModal}
                onOk={this.onCommentSave}
                onCancel={this.onCommentModalChange}
                width={400}
                okButtonProps={{disabled: !(description || "") || isDisabled }}
            >
                <div>
                    <TextArea
                        rows={4}
                        name="description"
                        value={description}
                        onChange={(e) => this.setState({commentObject: {...commentObject, description: e.target.value}})}
                        disabled={isDisabled || false}
                    />
                </div>
            </Modal>
        )
    }

    onCommentModalChange = (key, index, isDisabled) => {
        const { isModal, staffs } = this.state
        let commentObject = {}
        if(!isModal) {
            commentObject.index = index
            commentObject.key = String(key)
            commentObject.description = staffs[index].pre_checkin.covid.covid_detail[String(key)].description || ""
            commentObject.isDisabled = isDisabled
        }
        this.setState({
            isModal: !isModal,
            commentObject
        })
    }

    onCommentSave = () => {
        const { commentObject, staffs } = this.state
        staffs[commentObject.index].pre_checkin.covid.covid_detail[commentObject.key].description = commentObject.description || ""
        this.setState({
            staffs,
            commentObject: {},
            isModal: false
        })
    }

    getAttendanceList = () => {
        const { staffs } = this.state

        const studentArray = cloneDeep(staffs)

        return (studentArray || []).map((x, index) => ({...x, rowKey: index}))
    }

    getCounts = () => {
        let totalDays = 0
        let absent = 0
        let tardy = 0
        let present = 0
        let virtual = 0

        const absentArray = ["excusedabsence", "unexcusedabsence"]
        const tardyArray = ["excusedtardy", "unexcusedtardy"]

        this.state.duplicateStaffs.forEach(x => {
            const array = x.attendance && Object.keys(x.attendance || {})
            const value = ((array || []).length && array[0]) || ""
            if(absentArray.includes(value)) {
                absent++
            } else if(tardyArray.includes(value)) {
                tardy++
            } else if(value === "in" || value === "checkedout") {
                present++
            } else if(value === "virtual") {
                virtual++
            }
            totalDays++
        })

        return { totalDays, absent, present, tardy, virtual }
    }

    getChanges = (isDisabled) => {
        const { staffs, duplicateStaffs } = this.state

        let checkin = []
        let preCheckin = []
        let errorIndex = []

        staffs.forEach((x, index) => {

            const array = x.attendance && Object.keys(x.attendance || {})
            const value = ((array || []).length && array[0]) || ""
            const temperature = ((x.pre_checkin || {}).covid || {}).temperature || ""

            if(JSON.stringify(x.attendance) !== JSON.stringify(duplicateStaffs[index].attendance)) {

                if(value === "in" && !temperature) {
                    errorIndex.push(index)
                }

                checkin.push({
                    school_id: getSchoolId(),
                    staff_id: x.staff_id,
                    attendance: x.attendance,
                    att_date: getFormattedDate(x.att_date)
                })
            }
            if(JSON.stringify(x.pre_checkin) !== JSON.stringify(duplicateStaffs[index].pre_checkin)) {

                if(value === "in" && !temperature && !errorIndex.includes(index)) {
                    errorIndex.push(index)
                }

                preCheckin.push({
                    school_id: getSchoolId(),
                    staff_id: x.staff_id,
                    pre_checkin: x.pre_checkin,
                    att_date: getFormattedDate(x.att_date)
                })
            }
        })

        if(isDisabled) {
            return ((checkin || []).length && (preCheckin || []).length ? checkin.concat(preCheckin) : (checkin || []).length ? checkin : (preCheckin || []).length ? preCheckin : []) || []
        }
        return { checkin, preCheckin, errorIndex }
    }

    onSubmit = async () => {

        const { checkin, preCheckin, errorIndex } = this.getChanges()

        if((errorIndex || []).length) {
            openNotificationWithIcon('error', 'Temperature is required')
            return this.setState({errorIndex})
        } else {
            this.setState({errorIndex: []})
        }

        this.setState({
            attendanceProcess: true,
            isLoading: true
        })

        let success = 0
        let error = 0

        if((checkin || []).length) {
            for(let i in checkin) {
                const response = await createStaffCheckin(checkin[i])
                if(response && response.status === "success") {
                    success++
                } else {
                    error++
                }
            }
        }

        if((preCheckin || []).length) {
            for(let i in preCheckin) {
                const response = await createStaffPreCheckin(preCheckin[i])
                if(response && response.status === "success") {
                    success++
                } else {
                    error++
                }
            }
        }

        this.setState({
            attendanceProcess: false,
            isLoading: false
        })

        this.getAttendanceInfo()

    }

    render() {
        const { classes } = this.props
        const { loading, staffDetails, filterType, attendanceProcess } = this.state
        const { firstName = "", lastName = "", profile_photo = "" } = staffDetails || {}
        return(
            <div className="student-dashboard">
                <div className="main-header">
                    <div className="header-content">
                        <div className="header-content1">
                            <div className="back">
                                <Tooltip title={"Back"} placement="bottomLeft">
                                    <Icon
                                        type="left"
                                        className="color-white cursor-pointer"
                                        onClick={() => window.history.back()}
                                    />
                                </Tooltip>
                            </div>
                            {
                                profile_photo ?
                                    <Avatar src={profile_photo} size={50}/> :
                                    <Avatar size={50} style={{backgroundColor: 'white'}}>
                                        <span className="color-black">
                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                        </span>
                                    </Avatar>
                            }
                            <h3 className="mb-0 color-white ml-20">{firstName || ""} {' '} {lastName || ""}</h3>
                        </div>
                    </div>
                </div>
                { this.commentModal() }
                <div className={`student content`}>

                    <Row type="flex" gutter={16}>
                        <Col style={{ ...colStyles }}>
                            <Paper className={`${classes.paper} ${filterType === "all" && "purple-bg text-white"} cursor-pointer`} onClick={()=>this.setState({filterType: "all"})}>
                                <b>Total Days</b><br/>
                                <b>{(this.getCounts() || {}).totalDays}</b>
                            </Paper>
                        </Col>
                        <Col style={{ ...colStyles }}>
                            <Paper className={`${classes.paper} ${filterType === "Absent" && "purple-bg text-white"} cursor-pointer`} onClick={()=>this.setState({filterType: "Absent"})}>
                                <b>Absent</b><br/>
                                <b>{(this.getCounts() || {}).absent}</b>
                            </Paper>
                        </Col>
                        <Col style={{ ...colStyles }}>
                            <Paper className={`${classes.paper} ${filterType === "Present" && "purple-bg text-white"} cursor-pointer`} onClick={()=>this.setState({filterType: "Present"})}>
                                <b>Present</b><br/>
                                <b>{(this.getCounts() || {}).present}</b>
                            </Paper>
                        </Col>
                        <Col style={{ ...colStyles }}>
                            <Paper className={`${classes.paper} ${filterType === "Virtual" && "purple-bg text-white"} cursor-pointer`} onClick={()=>this.setState({filterType: "Virtual"})}>
                                <b>Virtual</b><br/>
                                <b>{(this.getCounts() || {}).virtual}</b>
                            </Paper>
                        </Col>
                        <Col style={{ ...colStyles }}>
                            <Paper className={`${classes.paper} ${filterType === "Tardy" && "purple-bg text-white"} cursor-pointer`} onClick={()=>this.setState({filterType: "Tardy"})}>
                                <b>Tardy</b><br/>
                                <b>{(this.getCounts() || {}).tardy}</b>
                            </Paper>
                        </Col>
                    </Row>

                    <div className="pt-12">
                        <Table
                            columns={this.getColumns()}
                            rowKey="rowKey"
                            // className="pt-12"
                            loading={loading}
                            pagination={false}
                            dataSource={this.getAttendanceList() || []}
                            bordered={true}
                            header
                            scroll={{ x: 2350 }}
                            sticky
                        />
                        {
                            (this.getAttendanceList() || []).length ?
                                <div className="mt-4">
                                    <Button
                                        type="primary"
                                        onClick={this.onSubmit}
                                        disabled={!(this.getChanges(true) || []).length || loading || false}
                                        loading={attendanceProcess}
                                    >
                                        Submit
                                    </Button>
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    currentUser: state.currentUser.newUserInfo || {}
})

StaffAttendance.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps,null)(withStyles(styles)(StaffAttendance))