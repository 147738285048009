import React, { useState } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { DateTimePicker as MaterialDateTimePicker } from "@material-ui/pickers"
import AccessTime from "@material-ui/icons/AccessTime";


const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(0.1),
        color: 'darkblue',
        cursor: "pointer",
        fontSize:"12px"
    },
}));

const DatePicker = (props) => {
    const { input, dateFormat } = props;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Chip
                icon={<AccessTime style={{fontSize: "medium"}} />}
                label="Schedule Post"
                variant="outlined"
                color="primary"
                className={classes.chip}
                onClick={() => setIsOpen(true)}
            />

            <MaterialDateTimePicker
                style={{ display: "none" }}
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
                format={dateFormat}
                {...input}
            />
        </>
    );
};

DatePicker.DefautProps = {

    dateFormat: "d MMM yyyy"
}

export default DatePicker;