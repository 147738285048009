import React, { Component } from 'react'
import { withStyles } from "@material-ui/core/styles";
import { Grid,Chip } from '@material-ui/core';
import PDFIcon from "@material-ui/icons/DockTwoTone";


const styles = theme => ({
    root: {
        paddingLeft: theme.spacing(1) * 2
    },
    cardContainer: {
        marginTop: theme.spacing(1) * 2
    },
    chip: {
        margin: theme.spacing(1),
        color: 'darkblue'
    },
    
});

class PDFList extends Component {
    render() {
        const { classes, fields: list } = this.props;

        return (
            <Grid container className={classes.root} >
                {list.map((field, index, fields) =>
                    <Grid className={classes.cardContainer} item key={index} >
                        <Chip
                            icon={<PDFIcon />}
                            label={field.name || field.filename || `Doc${index+1}`}
                            onDelete={() => { fields.remove(index) }}
                            className={classes.chip}
                            variant="outlined"
                        />
                    </Grid>
                )}

            </Grid>
        )
    }
}

export default withStyles(styles)(PDFList);