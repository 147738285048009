import React from "react"
import {connect} from "react-redux";
import {Avatar, Button, List, Spin} from "antd";
import {getColor, getSchoolId, openNotificationWithIcon} from "../../../../GlobalComponents/GlobalFields";
import {Grid} from "@material-ui/core";
import CreateParent from "../../../../Parents/components/CreateParent";
import {inviteUser, getUserDetails, updateGroup} from "../../../../../ApiServices";
import _ from 'lodash'
import { Modal, Button as AntdButton } from 'antd';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';


class Parent extends React.Component {
    constructor(props){
        super(props)

        // Find Orphans
        this.state = {
            isLoading: true,
            searchKeys: "",
            showOrphanRecords : false,
            childList: [],
            parentList : []
        }

    }

    async componentWillMount() {
        this.onSetChildList()
        this.findOrphanParents(this.props)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.onSetChildList()
        this.findOrphanParents(nextProps);
    }

    findOrphanParents(someprops) {
        let parentList = Object.assign([], someprops.selectedParent)
        console.log('Original parentList', parentList)
        someprops.selectedStudent.forEach((student) => {
            student.parents.forEach((parentid) => {
                parentList = _.remove(parentList, function (parent) {
                    return parent.id !== parentid
                })
            })
        })
        console.log('New OrphanList', parentList)
        parentList = _.remove(parentList, function (parent) {
            return !(parent.role[someprops.school_id].teacher == true || parent.role[someprops.school_id].admin == true)
        })
        this.setState({parentList})
    }

    onSetChildList = () => {
        let { selectedParent = [], currentUser: { newUserInfo: { childList = [] } } } = this.props || {};
        childList = childList.filter(x => selectedParent.some(y =>  (x.parents || []).includes(y.id)))
        this.setState({
            isLoading: false,
            selectedParent,
            childList
        })
    }

    fetchStudentsList = () => {
        this.props.fetchGroupData()
    }

    updateGroup = async (groupId, userId) => {
        let attributes = this.props.currentUser.newUserInfo.groupcache[groupId].attributes
        console.log('attributes', attributes)
        if (attributes === undefined){
            return
        }
        const payload = {
            attributes : attributes,
            school_id: getSchoolId(),
            group_id: groupId,
            added_children_ids: [],
            deleted_children_ids: [],
            added_user_ids: [],
            deleted_user_ids: [userId]
        }
        console.log('payload', payload)
        const userResponse = await updateGroup(payload)
        console.log('userResponse', userResponse)
        if(userResponse && userResponse.status === "success") {
            let parentList = _.remove(this.state.parentList, function (parent){
                return parent.id !== userId
            })
            let showOrphanRecords =  parentList.length !== 0
            this.setState({parentList, showOrphanRecords})

            return userResponse.data
        }
        return {}
    }

    getUserDetails = async (userId) => {
        const payload = {
            school_id: getSchoolId(),
            user_id: userId
        }
        const userResponse = await getUserDetails(payload)
        if(userResponse && userResponse.status === "success") {
            return userResponse.data
        }
        return {}
    }

    onInviteUser = async (record, index) => {
        let { childList } = this.state
        const childIndex = (childList || []).findIndex(x => x.id === record.id)
        const key = index === 0 ? "parent1" : "parent2"
        if(childIndex !== -1) {
            childList[childIndex][key] = true
        }
        this.setState({
            childList
        })

        if(record.parents[index]) {
            const response = await this.getUserDetails(record.parents[index])

            if(response && Object.keys(response).length) {
                const payload = {
                    school_id: getSchoolId(),
                    username: response.attributes.email || ""
                }

                const invite = await inviteUser(payload)
                if(invite && invite.status === "success") {
                    openNotificationWithIcon('success', 'invitation sent successfully')
                    childList[childIndex][key] = false
                    this.setState({
                        childList
                    })
                    this.fetchStudentsList()
                } else {
                    openNotificationWithIcon('error', invite.message || 'something went wrong')
                    childList[childIndex][key] = false
                    this.setState({
                        childList
                    })
                }
            }
        }
    }

    render() {
        const { currentUser: { newUserInfo: { usercache } }, parentLoad, selectedStudent } = this.props;
        const { isLoading, childList } = this.state
        let sortedselectedStudent = _.orderBy(selectedStudent, 'firstName', 'asc')
        console.log(sortedselectedStudent)
        var parentList = this.state.parentList

        return(
            <div>
                {parentList.length > 0 &&<div>
                    <Modal title={'Parents entitled to Class but having no kids registered'}
                           visible={this.state.showOrphanRecords}
                           onOk={()=>this.setState({showOrphanRecords : false})}
                           onCancel={()=>this.setState({showOrphanRecords : false})}

                    >
                        {
                            console.log('MODAL', parentList)
                        }
                        {parentList.length > 0 && parentList.map(parent=>{
                            return ( <div>{parent.firstName} {parent.lastName}({parent.email_id})
                                <IconButton color="primary" aria-label="Delete" component="span" onClick={()=>{ this.deleteUserfromGroup(parent.id)}}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>)
                        })}
                    </Modal>
                    <div style={{marginTop : 10, marginLeft : 10}}>
                        <Button onClick={()=>this.setState({showOrphanRecords : true})} variant="contained" color="secondary">Orphan Parents</Button>
                    </div>
                </div>
                }
                <Grid className="rounded-1rem bg-white shadow mt-8 parent-content">
                    <div className="data-table-header">
                        <List.Item>
                            <List.Item.Meta title={"Student Name"}/>
                            <List.Item.Meta title={"Parent Name"}/>
                            <List.Item.Meta title={"User Registration"}/>
                        </List.Item>
                    </div>

                    <Grid item xs={12}>
                        <div /* className="student-dashboard-infinite-container" */ >
                            <List
                                className="demo-loadmore-list"
                                loading={isLoading || parentLoad}
                                itemLayout="horizontal"
                                dataSource={sortedselectedStudent || []}
                                renderItem={(item, index) => {
                                    const { image = "", profile_photo = "", firstName = "", lastName = "" } = item || {}
                                    return(
                                        <List.Item className="cursor-pointer">
                                            <List.Item.Meta
                                                avatar={
                                                    (profile_photo || image) ?
                                                        <Avatar src={profile_photo || image} /> :
                                                        <Avatar
                                                            aria-label="recipe"
                                                            style={{backgroundColor: getColor(index)}}
                                                        >
                                                            {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                        </Avatar>
                                                }
                                                title={
                                                    <div
                                                        className="mt-3 fs-16"
                                                        onClick={() => this.props.history.push(`/admin/student/view/${item.id}`)}
                                                    >
                                                        {firstName} {lastName}
                                                    </div>
                                                }
                                            />

                                            <List.Item.Meta
                                                title={
                                                    <div>
                                                        {
                                                            (item.parents || []).map((val, ind) => {
                                                                // if(!(selectedParent || []).includes(val)) {
                                                                //     return
                                                                // }
                                                                const currentParent = (usercache && usercache[val]) || {}
                                                                const { firstName = "", lastName = "", profile_photo = "", image = "" } = currentParent || {}
                                                                return(
                                                                    <div key={ind}>
                                                                        <div className="inline-flex" onClick={() => this.props.history.push(`/admin/parent/view/${val}`)}>
                                                                            {
                                                                                (profile_photo || image) ?
                                                                                    <Avatar src={profile_photo || image} style={{width: 35, height: 35}}/> :
                                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index), width: 35, height: 35, lineHeight: 2.5}}>
                                                                                        {`${(firstName && firstName.toString() || "").charAt(0).toUpperCase()}${(lastName && lastName.toString() || "").charAt(0).toUpperCase()}`}
                                                                                    </Avatar>
                                                                            }
                                                                            <p className="m-2">{`${firstName && firstName.toString() || ""} ${lastName && lastName.toString() || ""}`}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {((item.parents || []).length === 0 || (item.parents || []).length === 1) && <CreateParent length={(item.parents || []).length || 0} childId={item.id} fetchStudentsList={this.fetchStudentsList}/>}
                                                    </div>
                                                }
                                            />

                                            <List.Item.Meta
                                                title={
                                                    <div>
                                                        {
                                                            (item.parents || []).map((val, index) => {
                                                                const currentParent = (usercache && usercache[val]) || {}
                                                                const { invited = false, active = false } = currentParent || {}
                                                                return(
                                                                    <div key={index.toString()} style={index === 0 ? {marginBottom: 5} : {}}>
                                                                        {
                                                                            (item.parent1 === true && index === 0 || item.parent2 === true && index === 1) ?
                                                                                <Spin/> :
                                                                                <>
                                                                                    {
                                                                                        invited && active ?
                                                                                            <span>Active</span> :
                                                                                            invited ?
                                                                                                <>
                                                                                                    <div className="color-green">Invitation Sent</div>
                                                                                                    <Button size='small' onClick={() => this.onInviteUser(item, index)}>Resend Invite</Button>
                                                                                                </> :
                                                                                                <Button size='small' onClick={() => this.onInviteUser(item, index)}>Invite</Button>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            />

                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

            </div>
        )
    }

    deleteUserfromGroup(userid) {
        console.log('Delete user ' + userid + ' from group ' + this.props.groupId)
        this.updateGroup(this.props.groupId, userid)
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser,
    school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
})

export default connect(mapStateToProps,null)(Parent)