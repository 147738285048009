import {
    takeEvery,
    fork,
    all,
    put,
    select,
    call
} from 'redux-saga/effects';
import moment from 'moment';
import eventService from '../../service/event';

import {
    ADD_EVENT,
    addEvent,
    GET_EVENT,
    RSVP_RESPONSE_TYPE,
    UPDATE_RSVP_RESPONSE_TYPE,
    getEvent,
    resetStepperActiveStep,
    getEventById,
    GET_EVENT_BY_ID,
    GET_EVENT_RESPONSE,
    fetchEventResponse,
    FETCH_RSVP_EVENT_BY_USER,
    fetchUserRSVPEvent,
    DELETE_EVENT,
    deleteEvent,
    SET_EVENT_RESPONSE,
    setEventResponse,
    TRANSFER_OWNERSHIP,
    DELETE_SLOT_TIME,
    FETCH_FILTER_BY_USER,
    fetchFilterEvent,
    REMOVE_CHILD_EVENT,
    handleFormInitalValue
} from '../actions/event';

import {
    showToastMessage, setPageLoaderFinish, setPageLoaderStart,
} from '../actions/component';

import { REQUEST } from '../actions/common';
import * as routes from "../../routes/path";
import { closeDialogBox } from '../actions/component';
import {isGenericEvent, isPTAEvent, isRSVPEvent} from "../../shared/enums";
import {getCurrentSchoolId, onSetupEvent, getTimeFormat, getDateFormat} from "../../utils/utils";

function combineTimeAndDate(date, time) {
    const _date = moment.utc(`${date}`).format('YYYY-MM-DD')
    const _time = moment(`${time}`).format('HH:mm:ss')
    return moment(`${_date} ${_time}`, 'YYYY-MM-DD HH:mm:ss').toDate()
}

function transformEvent(data) {
    const event = Object.assign({}, data)
    console.log("============47=========", {event})

    const { start_date, end_date, start_time, end_time, notifyOneDayAgo, notifyTwoDayAgo, notifyThreeDayAgo, event_id, id, attributes: { groups, child_ids, attachments = [], event_img_url = [], groupParentSelection = "", all_day_event = false, event_type, limit = "" } = {} } = event || {}

    let object = event
    let notify_days_before_event = []
    if(notifyOneDayAgo) {
        notify_days_before_event.push(1)
    }
    if(notifyTwoDayAgo) {
        notify_days_before_event.push(2)
    }
    if(notifyThreeDayAgo) {
        notify_days_before_event.push(3)
    }
    object.attributes = {
        ...object.attributes,
        event_img_url: (event_img_url || []).length ? event_img_url[0].key : "",
        notify_days_before_event,
        groups: groupParentSelection === 'group' ? groups : [],
        child_ids: groupParentSelection === 'parents' ? child_ids : [] ,
        attachments: (attachments || []).map(attachment => attachment.key) || []
    }

    delete object.attributes.groupParentSelection
    delete object.start_date
    delete object.end_date
    delete object.start_time
    delete object.end_time
    delete object.notifyOneDayAgo
    delete object.notifyTwoDayAgo
    delete object.notifyThreeDayAgo

    // if(event_id || id) {
    //     delete object.school_id
    // }

    if(isGenericEvent(event_type)) {

        object.start_date_time = all_day_event ? start_date : combineTimeAndDate(start_date, start_time)
        object.end_date_time = all_day_event ? end_date : combineTimeAndDate(end_date, end_time)

        localStorage.setItem("defaultDate", object.start_date_time)

        delete object.volunteer
        delete object.parentTeacherResponse
        delete object.user_response
        return object
    }

    if(isRSVPEvent(event_type)) {

        object.attributes.limit = limit ? Number(limit) : 1000000
        object.start_date_time = all_day_event ? start_date : combineTimeAndDate(start_date, start_time)
        object.end_date_time = all_day_event ? end_date : combineTimeAndDate(end_date, end_time)

        localStorage.setItem("defaultDate", object.start_date_time)

        delete object.volunteer
        delete object.parentTeacherResponse
        delete object.user_response
        return object
    }

    if(isPTAEvent(event_type)) {

        let schedule = {}

        object.parentTeacherResponse.forEach((date, index) => {
            const evtDate = getDateFormat(date.event_date)
            schedule[evtDate] = {...schedule[evtDate]}
            object.parentTeacherResponse[index].slotTime.forEach(slot => {

                const startTime = getTimeFormat(slot.start_time)
                const endTime = getTimeFormat(slot.end_time)
                schedule[evtDate] = {
                    ...schedule[evtDate],
                    [`${startTime}-${endTime}`]: {...schedule[evtDate][`${startTime}-${endTime}`], count: slot.count, users: slot.users }
                }
            })
        })

        object.attributes.schedule = schedule

        const dateList = (object.parentTeacherResponse || []).map(x => moment(x.event_date))
        object.start_date_time = moment.min(dateList).toISOString()
        object.end_date_time = moment.max(dateList).toISOString()

        delete object.volunteer
        delete object.parentTeacherResponse
        delete object.user_response
        return object
    }

    if (event.parentTeacherResponse && event.parentTeacherResponse.length > 0) {
        //const dates = event.dates.length > 0 ? event.dates.map(date => moment(date).format("D-MM-YYYY")) : []
        // event.event_dates = dates;

        const parentTeacherResponse = event.parentTeacherResponse.length > 0 ? event.parentTeacherResponse.map((date, index) => {
            var rObj = {};
            rObj["event_date"] = moment(date.event_date).toISOString();
            rObj["slotTime"] = event.parentTeacherResponse[index].slotTime.length > 0 ? event.parentTeacherResponse[index].slotTime.map(slot => {
                var tempSlot = {};
                tempSlot["start_time"] = moment(slot.start_time).toISOString();
                tempSlot["end_time"] = moment(slot.end_time).toISOString();
                return tempSlot;
            }) : [];
            return rObj;
        }) : []
        event.parentTeacherSlots = parentTeacherResponse;
        delete event["parentTeacherResponse"];
    }

    // const attachments = event.attachments.length > 0 ? event.attachments.map(attachment => attachment.key) : [];
    const img_url = event.img_url[0] ? event.img_url[0].key : "";
    event.attachments = attachments;
    event.img_url = img_url;

    return event;
}

function* createEvent(action) {
    try {
        const currentUser = yield select(state => state.currentUser.newUserInfo.user);
        const event = yield select(state => state.event.formInitialValue);
        const _event = yield transformEvent({...event, attributes: {...event.attributes, draft: action.query.history.isDraft}});
        let response = {};
        if (_event.event_id) {
            response = yield eventService.updateEvent(_event);
        } else {
            _event.school_id = getCurrentSchoolId(currentUser)
            response = yield eventService.addEvent(_event);
        }
        if (response.status === "success") {
            action.query.history.push(routes.EVENT);
            yield put(showToastMessage({ message: response.message, type: 'success' }));
            yield put(addEvent.success());
            yield (put(resetStepperActiveStep()))
        }
    } catch (error) {
        console.log(error.message);
        yield put(addEvent.failure(error.message));
    }
}

function* deleteEventS(action) {
    try {
        const response = yield eventService.deleteEvent(action.query);
        if (response && response.status === "success") {
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
            yield put(deleteEvent.success());
            // yield put(fetchUserRSVPEvent.request());
            yield put(getEvent.request());
        }
    } catch (error) {
        console.log(error.message);
        yield put(deleteEvent.failure(error.message));
    }
}

function* getEventList(action) {
    try {
        yield put(setPageLoaderStart());
        const results = yield eventService.getEventList();
        yield put(getEvent.success(results));
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Event list fetched successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(getEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function convertToMoment(dates) {
    if (Array.isArray(dates)) {
        const result = dates.map(date => ({
            ...date,
            event_date: moment.utc(date.event_date).toDate(),
            slotTime: Array.isArray(date.slotTime) ?
                date.slotTime.map(slot => ({
                    ...slot,
                    start_time: moment(slot.start_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    end_time: moment(slot.end_time, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
                    spot: slot.spots || [],
                })) : [],
        }))
        return result
    }
    return []
}

function* getEventByEventId(action) {
    try {
        yield put(setPageLoaderStart());
        const event = yield eventService.getEventById(action.query.event_id);
        if(event && event.status === "error") {

        } else {

            const object = onSetupEvent(event)

            yield put(getEventById.success(object));
            if(action.query.isEdit) {
                yield (put(handleFormInitalValue(object)))
            }
            yield put(setPageLoaderFinish());
        }

        //yield put(showToastMessage({message: 'Event fetched successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(getEventById.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* setRSVPEventResponse(action) {
    try {
        const { event_id } = action.query;
        yield put(setPageLoaderStart());
        const response = yield eventService.setRsvpEventResponse(action.query);
        if (response && response.status === "success") {
            // yield put(getEvent.request());
            // yield put(fetchUserRSVPEvent.request());
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
        }
        yield put(setPageLoaderFinish());
        yield put(showToastMessage({ message: response.message || 'RSVP updated successfully', type: 'success' }));
    } catch (error) {
        console.log(error);
        yield put(getEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* removeSignup(action) {
    try {
        yield put(setPageLoaderStart());
        const response = yield eventService.removeChildFromSlot(action.query);
        if (response && response.status === "success") {
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
            // yield put(fetchUserRSVPEvent.request());
            // yield put(getEvent.request());
            yield put(fetchEventResponse.request({ event_id: action.query.event_id }));
            yield put(getEventById.request({ event_id: action.query.event_id }))
        }

    } catch (error) {
        yield put(setEventResponse.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* sendEventResponse(action) {
    try {
        yield put(setPageLoaderStart());
        const response = yield eventService.setRsvpEventResponse(action.query);
        if (response && response.status === "success") {
            // const body = { filter: "upcomming" }
            // yield put(fetchFilterEvent.request(body));
            // yield put(fetchUserRSVPEvent.request());
            yield put(fetchEventResponse.request({ event_id: action.query.event_id }));
            yield put(getEventById.request({ event_id: action.query.event_id }))
        }

    } catch (error) {
        yield put(setEventResponse.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* updateRsvpResponse(action) {
    try {
        yield put(setPageLoaderStart());
        const response = yield eventService.updateRsvpEventResponse(action.query);
        const success = response && response.status === "success"
        if (success) {
            const data = {
                query: {
                    event_id: action.query.event_id
                }
            }
            yield put(closeDialogBox())
            yield call(getSelectedEventResponse, data)
        }
        yield put(setPageLoaderFinish());
        yield put(showToastMessage({ message: response.message, type: success ? 'success' : 'error' }));
    } catch (error) {
        console.log(error);
        yield put(getEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* getSelectedEventResponse(action) {
    try {
        if(action.query && action.query.event_id){
            yield put(setPageLoaderStart());
            const response = yield eventService.getSelectedEvent(action.query.event_id);
            if (response && response.status === "error") {
                const error = { message: 'Fetch Failed' }
                throw error
            } else {
                yield put(fetchEventResponse.success(response));
            }
            yield put(setPageLoaderFinish());
        }
        //yield put(showToastMessage({message: 'Get event successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(fetchEventResponse.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* getUserRSVPEvent() {
    try {
        yield put(setPageLoaderStart());
        const response = yield eventService.getUserResponse();
        if (response && response.status === "error") {
            const error = { message: 'Fetch Failed' }
            throw error
        } else {
            yield put(fetchUserRSVPEvent.success(response));
        }
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Get RSVP successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(fetchUserRSVPEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* getRSVPFilterEvent(action) {
    try {
        yield put(setPageLoaderStart());
        const { result, count } = yield eventService.getUserResponse(action.query);
        if (result) {
            yield put(fetchFilterEvent.success({ result, count }));
        } else {
            const error = { message: 'Fetch Failed' }
            throw error
        }
        yield put(setPageLoaderFinish());
        //yield put(showToastMessage({message: 'Get RSVP successfully', type: 'success'}));
    } catch (error) {
        console.log(error);
        yield put(fetchFilterEvent.failure(error.message));
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* transferOwnerShip(action) {
    try {
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.trasnferOwnerShip(action.query);
        if (statusCode === 200) {
            const { results } = yield eventService.getEventList();
            yield put(getEvent.request());
            yield put(showToastMessage({ message: 'Owner changed successfully', type: 'success' }));
        }
    } catch (error) {
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

function* applyDeleteTimeSlot(action) {
    try {
        yield put(setPageLoaderStart());
        const { statusCode } = yield eventService.deleteEventSlot(action.query);
        if (statusCode === 200) {
            const { results } = yield eventService.getEventList();
            yield put(getEvent.request());
            yield put(getEventById.request({ event_id: action.query.eventid }))
            yield put(showToastMessage({ message: 'Delete Slot', type: 'success' }));
        }
    } catch (error) {
        yield put(showToastMessage({ message: error.message, type: 'error' }));
    }
}

/**  all watcher */

function* watchGetEvent() {
    yield takeEvery(GET_EVENT[REQUEST], getEventList)
}
function* watchGetEventById() {
    yield takeEvery(GET_EVENT_BY_ID[REQUEST], getEventByEventId)
}
function* watchCreateEvent() {
    yield takeEvery(ADD_EVENT[REQUEST], createEvent)
}

function* watcherDeleteEvent() {
    yield takeEvery(DELETE_EVENT[REQUEST], deleteEventS)
}

function* watchSetRSVPEventResponse() {
    yield takeEvery(RSVP_RESPONSE_TYPE[REQUEST], setRSVPEventResponse)
}

function* watchUpdateRSVPEventResponse() {
    yield takeEvery(UPDATE_RSVP_RESPONSE_TYPE[REQUEST], updateRsvpResponse)
}

function* watchGetSelectedEventResponse() {
    yield takeEvery(GET_EVENT_RESPONSE[REQUEST], getSelectedEventResponse)
}

function* watcherGetUserRSVPEvent() {
    yield takeEvery(FETCH_RSVP_EVENT_BY_USER[REQUEST], getUserRSVPEvent)
}

function* watcherGetRSVPFilterEvent() {
    yield takeEvery(FETCH_FILTER_BY_USER[REQUEST], getRSVPFilterEvent)
}

function* watcherSendEventResponse() {
    yield takeEvery(SET_EVENT_RESPONSE[REQUEST], sendEventResponse)
}

function* watcherRemoveSignup() {
    yield takeEvery(REMOVE_CHILD_EVENT[REQUEST], removeSignup)
}

function* watcherTransferOwnerResponse() {
    yield takeEvery(TRANSFER_OWNERSHIP[REQUEST], transferOwnerShip)
}

function* watcherDeleteTimeSlot() {
    yield takeEvery(DELETE_SLOT_TIME[REQUEST], applyDeleteTimeSlot)
}

export default function* rootVolunteer() {
    yield all([
        fork(watchCreateEvent),
        fork(watchGetEvent),
        fork(watcherDeleteEvent),
        fork(watchGetEventById),
        fork(watchGetSelectedEventResponse),
        fork(watchSetRSVPEventResponse),
        fork(watcherSendEventResponse),
        fork(watcherRemoveSignup),
        fork(watchUpdateRSVPEventResponse),
        fork(watcherGetUserRSVPEvent),
        fork(watcherTransferOwnerResponse),
        fork(watcherDeleteTimeSlot),
        fork(watcherGetRSVPFilterEvent)
    ]);
}