import {
    createSelector
} from 'reselect';

const getDraftPosts = state => state.briefcase.draft.posts;
const getSchedulePosts = state => state.briefcase.schedule.posts;
const getReviewPosts = state => state.briefcase.review.posts;
const getUserCache = state => state.currentUser.newUserInfo.usercache || {};

const getDraftPostsWithUser = createSelector(
    [getDraftPosts, getUserCache], (posts = [], userCache) => {
        if ((posts || []).length && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.user_comments && Object.values(post.user_comments || []).map((comment, index) => ({
                        ...comment,
                        id: Object.keys(post.user_comments)[index] || "",
                        user: userCache[comment.user_id]
                    })),
                    // user: userCache[post.user]
                };
            });
        }
        return [];
    }
)

const getSchedulePostsWithUser = createSelector(
    [getSchedulePosts, getUserCache], (posts = [], userCache) => {
        if ((posts || []).length && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.user_comments && Object.values(post.user_comments || []).map((comment, index) => ({
                        ...comment,
                        id: Object.keys(post.user_comments)[index] || "",
                        user: userCache[comment.user_id]
                    })),
                    // user: userCache[post.user]
                };
            });
        }
        return [];
    }
)

const getReviewPostsWithUser = createSelector(
    [getReviewPosts, getUserCache], (posts = [], userCache) => {
        if ((posts || []).length && Object.keys(userCache).length) {
            return posts.map(post => {
                return {
                    ...post,
                    comments: post.user_comments && Object.values(post.user_comments || []).map((comment, index) => ({
                        ...comment,
                        id: Object.keys(post.user_comments)[index] || "",
                        user: userCache[comment.user_id]
                    })),
                    // user: userCache[post.user]
                };
            });
        }
        return [];
    }
)


export const BriefCaseSelector = {
    getDraftPostsWithUser,
    getSchedulePostsWithUser,
    getReviewPostsWithUser
};