import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CheckboxField from "./CheckboxField"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { getRoomType } from '../ChatSidebar/Header';

const default_profile="/static/images/avatar/1.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    height: "42vh",
  },
  inline: {
    display: 'inline',
  },
}));

export default function UserListSelector({users={},selectedUsersAction,selectedGroup={},unselectedUser={},searchText="",roomType="",me={}}) {
  const classes = useStyles();
  const [selectedUsers,setSelectedUsers]=useState([])
  const [newUser,setNewUser]=useState([])
  
  useEffect(()=>{
    let tempusers=Object.values(users).filter(user=>(user.id!==me.id&&{...user,isSelect:false}));
    setNewUser(tempusers);
  },[users])

  useEffect(()=>{
    let userList=[]
    if(newUser.length!==0){
      let newTempUser = newUser.map(x=>{
        var result=selectedUsers.filter(a1=> a1.id==x.id);
        if(result.length>0) {x.isSelect=result[0].isSelect;}
        return x })
      newTempUser.forEach(user=>{
        if(user?.group_ids.includes(selectedGroup?.id)){
          if(user?.isSelect){
            userList=[...userList,{...user}]
          } else{
            userList=[...userList,{...user,isSelect:false}]
          }
        }
      })
      setSelectedUsers(userList);
    }
  },[selectedGroup,users,newUser])

  useEffect(()=>{
    if(Object.keys(unselectedUser).length!==0){
      if(getRoomType.group===roomType){
        handleSelectGroupUser({...unselectedUser,isSelect:true});
      } else{
        handleSelectSingleUser({...unselectedUser,isSelect:true})
      }
    }
  },[unselectedUser])

  const handleSelectGroupUser=(selectuser)=>{
    let userList=[]
    selectedUsers.forEach(user=>{
      if(user.id===selectuser.id){
        if(!selectuser.isSelect){
          userList=[...userList,{...user,isSelect:true}]
        } else{
          userList=[...userList,{...user,isSelect:false}]
        }
      } else{
        userList=[...userList,{...user}]
      }
    });
    // console.log(userList)
    selectedUsersAction(userList.filter(user=>(user.isSelect)));
    setSelectedUsers(userList);
  }
  const handleSelectSingleUser=(selectuser)=>{
    let userList=[]
    selectedUsers.forEach(user=>{
      if(user.id===selectuser.id){
        if(!selectuser.isSelect){
          userList=[...userList,{...user,isSelect:true}]
        } else{
          userList=[...userList,{...user,isSelect:false}]
        }
      } else{
        userList=[...userList,{...user,isSelect:false}]
      }
    });
    // console.log(userList)
    selectedUsersAction(userList.filter(user=>(user.isSelect)));
    setSelectedUsers(userList);
  }

  const handleSelectUser=(user)=>{
    if(getRoomType.group===roomType){
      handleSelectGroupUser(user);
    } else{
      handleSelectSingleUser(user)
    }
  }
  return (
    <div>
       <Typography
          component="span"
          variant="body2"
          style={{margin: 10,fontWeight: "bold"}}
          className={classes.inline}
          color="textPrimary">
          Select a Member
        </Typography>
    <List className={classes.root} id="custom-scroll">
      {selectedUsers.map(user => (
        `${user.firstName} ${user.lastName}`.toLowerCase().indexOf(searchText.toLowerCase()) != -1 && (
        <div key={user.id}>
        <ListItem 
          alignItems="center" 
          button 
          onClick={()=>handleSelectUser(user)}>
        <ListItemAvatar>
          <Avatar alt={`${user.firstName} ${user.lastName}`} src={user.profile_photo===""?default_profile:user.profile_photo} />
        </ListItemAvatar>
        <ListItemText primary={`${user.firstName} ${user.lastName}`}/>
          <ListItemSecondaryAction>
              <CheckboxField user={user} selectUserAction={(user)=>handleSelectUser(user)}/>
          </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
        </div>)
      ))}
    </List>
    </div>
  );
}