export const AddModuleStyles = theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: '#FAFAFA 0% 0% no-repeat padding-box',
  },
  topTitle: {
    marginTop: theme.spacing(4),
    top: '146px',
    height: '33px',
    textAlign: 'left',
    fontSize: '22px/28px',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#121210',
    opacity: 1,
  },

  Title: {
    fontSize: '22px/28px',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#000000',
    opacity: 1,
  },

  // leftIcon: {
  //   textAlign: 'left',
  //   font: 'Regular 18px/22px Helvetica Neue',
  //   letterSpacing: 0,
  //   color: '#212B9B',
  //   opacity: 1,
  // },
  button: {
    textAlign: 'left',
    height: '40px',
    fontSize: '12px/20px',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#212B9B',
    opacity: 1,
  },
  searchContainer: {
    marginTop: theme.spacing(2),
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  tableDatePicker: {
    maxWidth: '150px',
  },
  formControl: {
    width: '100%',
    minWidth: 120,
  },
  teachersDialog: {
    minWidth: '400px',
  },
  teacherSelect: {
    width: '100%',
  },
  container: {
    display: 'flex',
    direction: 'row',
    justify: 'space-between',
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inputFullWidth: {
    marginTop: 16,
    width: '100%',
    minWidth: '100%',
  },
  fw: {
    width: '100%',
    minWidth: '100%',
  },
  // card: {
  //   display: 'flex',
  //   minHeight: '100vh',
  //   width: '40vw',
  //   borderRadius: '10px',
  //   paddingBottom: '12px',
  //   paddingTop: '12px',
  // },
  menu: {
    width: 200,
  },
  subTitle: {
    color: '#CECECE',
    fontSize: '14px/18px',
    marginBottom: theme.spacing(1),
  },
  badges: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  markContainer: {
    height: '60px',
  },
  mark: {
    paddingTop: '8px',
    paddingBottom: '8px',
    width: '60px',
    height: '30px',
    backgroundColor: '#f2f0eb',
    opacity: 1,
  },
  tableRoot: {
    backgroundColor: '#ededed',
  },
  selectControl: {
    margin: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // minWidth: '70%',
    alignSelf: 'right',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#000000',
  }
});
