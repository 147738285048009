import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';


import "../../../css/plan.css";
import "./index.css";
import CancelIcon from '@material-ui/icons/Cancel';
import {Popover, Tooltip, Input, Modal, Spin} from 'antd';
import IconButton from '@material-ui/core/IconButton';
import {PushpinFilled, PushpinOutlined} from '@ant-design/icons';
import { Button, Chip, TextField } from '@material-ui/core';
import {BulbTwoTone, CommentOutlined, HistoryOutlined} from '@ant-design/icons';
import SaveIcon from '@material-ui/icons/Save';
import Scale from '../Scale'
import TextArea from 'antd/es/input/TextArea';
import recordPlannerService from "../../../service/recordplanner";
import {connect} from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
const Container = styled.div`
  width: 100%;
  background-color: #E0F1F5;
  border: 1px solid #2D798B;
  padding-left: 2px;
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
`;

class ActionBar extends React.Component {
    constructor(props) {
        super(props)
        console.log('props', props)
        this.state = {
            popupvisible : false,
            commentText : '',
            selectedOption : props.selectedOption,
            flag : true
        }
    }

    getContent(){

        let scaleButton = (scale) => {

            var _borderWidth = 'thin'
            var _borderColor = 'lightgray'
            var _backgroundColor = 'white'
            let _color = scale.color

            if (scale.rating == this.state.selectedOption){
                console.log('===', scale.rating, this.state.selectedOption, scale.rating == this.state.selectedOption)
                 _borderWidth = 'thick'
                 _borderColor = '#494946'
                 _backgroundColor = scale.color
                _color = 'white'
            }

            if (scale.flag) {

                return (
                    <Tooltip title={scale.name}>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox color="primary" checked={this.state.flag}
                                               onChange={(evt) => {
                                                   this.setState({flag : evt.target.checked})
                                                   this.btnClickedHandler(null, evt.target.checked)

                                               }}
                                               icon={<PushpinOutlined  style={{fontSize : '20px', color : 'orangered'}}/>}
                                               checkedIcon={<PushpinFilled  style={{fontSize : '20px', color : 'orangered'}} />}
                                               ontSize="small"/>}
                            label=""
                            style={{padding : 0}}
                            labelPlacement="top"
                        />

                    </Tooltip>
                )
            }
            return (
                <Tooltip title={scale.name} key={scale.name}>
                    <Button variant="outlined" style={{color: _color,
                        backgroundColor : _backgroundColor}}
                            onClick={() => {
                                this.btnClickedHandler(scale, )
                            }}>{scale.rating}</Button>
                </Tooltip>
            )
        }
        let buttonData = Scale.map((scale) => {
            return scaleButton(scale)
        })
        let divStyle = this.props?.divMode ? {justifyContent : 'space-around', display : 'flex'} : {}

        return (
            <div>
                <div style={divStyle}>
                    {buttonData}
                    <Tooltip title={'Add Comment'}>
                        <Button onClick={() => {
                            this.setState({commentvisible: !this.state.commentvisible})
                        }}
                                style={{backgroundColor: 'white', color: this.state.commentText.length > 0 ?
                                        'red' : 'gray', border: 0}}>
                            <CommentOutlined/>
                        </Button>
                    </Tooltip>

                </div>
                {this.state.commentvisible &&
                <div style={{marginTop: 5, justifyContent : 'flex-end', display : 'flex', marginRight : 20}}>
                    <TextArea rows={2} style={{width: 500}} placeholder="Enter Comments here"
                              defaultValue={this.state.commentText}
                              onChange={this.commentChanged}/>
                    <IconButton color="primary" onClick={() => {
                        this.saveComment()
                    }}
                                aria-label="Save Comment" component="span">
                        <SaveIcon/>
                    </IconButton>

                </div>
                }

            </div>
        )
    }
    commentChanged = (e) => {
        this.setState({
            commentText: e.target.value
        })
    }

    saveComment() {
        console.log(this.props)
        console.log(this.state)
        console.log('this.props.flag',this.state.flag)
        console.log('this.props.flag===true',this.state.flag === true)
        var entry = {
            "school_id": this.props.school_id[0],
            "dashboard_id": this.props.dashboard_id,
            "skill_id": this.props.skillid,
            "flag": this.state.flag === true ? 1 : 0,
            "child_id": this.props.studentid,
            "comment": this.state.commentText,
            "rating": this.state.selectedOption,
            "dates" : [this.props.coldates],
            "group_id" : this.props.group_id
        }

        this.saveSkillData(entry)
        this.setState({
            commentvisible : false
        })
    }

    async saveSkillData(entry) {

        console.log('entry=>>', entry)

        const ret = await recordPlannerService.addDashboardEntry(entry)
        console.log(ret)
        if (ret.status === 'success') {
            this.props.refreshData()
        } else {
            alert('Error Saving Rating. Please try again !')
        }
    }


    btnClickedHandler(scale, clickedFlag=this.state.flag) {
        console.log('Setting value to ', scale)
        console.log('ptops', this.props)
        let rating =scale === null ? this.props.selectedOption : scale.rating
        var entry = {
            "school_id": this.props.school_id[0],
            "dashboard_id": this.props.dashboard_id,
            "skill_id": this.props.skillid,
            "flag": clickedFlag ? 1 : 0,
            "child_id": this.props.studentid,
            "comment": this.state.commentText,
            "dates" : (scale === null && clickedFlag) ?  null :
                        this.props.coldates === null ? null : [this.props.coldates] ,
            "rating": rating,
            "group_id" : this.props.group_id
        }
        console.log(entry)
        this.saveSkillData(entry)
        this.setState({
            popupvisible: false,
            selectedOption : rating
        })

    }

    handleVisibleChange = visible => {
        console.log('visible', visible)
        this.setState({
            popupvisible : visible
        })
    };

    render(){
        if (this.props.divMode){
            return (<div>{this.getContent()}</div>)
        }
        return (
                    <Popover
                        visible={this.state.popupvisible}
                        trigger="click"
                        onVisibleChange={this.handleVisibleChange} content={this.getContent()}>
                        {this.props.children}
                    </Popover>
                        )
    }

}

const mapStateToProps = state => ({
    school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar)

