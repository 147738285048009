import React,{useState,useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Typography from '@material-ui/core/Typography';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import CreateRoomContent from "./../CreateRoomDialog/CreateRoomContent"
import UploadImage from "./../CreateRoomDialog/UploadImage"
import {ConfirmationDialog} from "./../ChatSidebar/ChatSidebar"
import "./index.css"
import { getRoomType } from '../ChatSidebar/Header';
import {default_group,default_user,getLastDateTimeMessage} from "./../../../../utils/utils"
import { getSchoolId } from '../../../SchoolProfile/components/GlobalComponents/GlobalFields';

const styles = (theme) => ({
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    closeButton: {},
    userListRoot:{
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listitemText:{
        fontSize: "16px",
        fontWeight: 500,
    }
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        ) : null}
        <Typography variant="h5" style={{fontWeight: "bold"}}>{children}</Typography>
        <span style={{width:60}}></span>
      </MuiDialogTitle>
    );
});
  
const UserListPreview = withStyles(styles)((props) => {
    const { children, classes, onClose,chatusers,groupPhoto,isGroupChat,name,owner,roomkey,me,addPeopleAction, ...other } = props;
    return (
      <List dense className={classes.userListRoot}>
        {isGroupChat&& (owner===me.id) &&(
        <>
        <ListItem button onClick={addPeopleAction}>
            <IconButton 
              aria-label="show 17 new notifications" 
              color="inherit" 
              style={{marginRight:"-8px",background:"rgba(0,0,0,0.05)",padding:"8px"}} 
              className="iconbutton">      
                <AddCircleOutlinedIcon style={{height:24,width:24}}/>
            </IconButton>
            <ListItemText className="profilelistitemText" style={{fontSize:"16px",fontWeight:500}} primary={`Add people`} style={{marginLeft: "24px"}}/>
        </ListItem>
        <Divider variant="inset" component="li" />
        </>)
        }
        {Object.values(chatusers).map(user => {
          return (
            <div key={user.username}>
            <ListItem key={user.username} button>
              <ListItemAvatar>
                <Avatar
                  alt={`${user.display_name}`}
                  src={`${user.profile_photo}`}
                  />
              </ListItemAvatar>
              <ListItemText className="profilelistitemText" style={{fontSize: "16px",fontWeight: 500}} id={user.display_name} primary={`${user.display_name}`}/>
            </ListItem>
             <Divider variant="inset" component="li" />
            </div>
          );
        })}
      </List>
    );
});

const ProfileOptions = withStyles(styles)((props) => {
    const { children, classes, onClose,isGroupChat, ...other } = props;
    const labelId = `checkbox-list-secondary-label`;
    return (
      <List dense className={classes.userListRoot}>
        {isGroupChat &&(
        <>
          <ListItem button style={{height:"50px"}}>
              <ShareOutlinedIcon style={{height:24,width:24}}/>
              <ListItemText className="profilelistitemText" style={{fontSize:"16px",fontWeight:500}} id={labelId} primary={`Share link to join group`} style={{marginLeft: "24px"}}/>
          </ListItem>
          <Divider variant="inset" component="li" />
        </>)}
      </List>
    );
});
export default function ProfileDialog({open,onCloseAction,chatroomDetail={},me={},users={},groups={},handleCreateRoomAction,leaveChatAction,deleteChatAction}) {
    const [groupName,setGroupName]=useState("");
    const [isGroupNameEdit,setIsGroupNameEdit]=useState(false);
    const [showAddPeopleDialog,setShowAddPeopleDialog]=useState(false);
    const [groupImageUrlObj,setGroupImageUrlObj]=useState({})
    const [showConfirmationDialog, setShowConfirmationDialog] = React.useState({open:false,title:"",message:"",buttontitle:""});
    console.log("ProfileDialog",chatroomDetail,me)
    const {details:{groupPhoto,isGroupChat,name,owner,users:chatusers},key:roomkey}=chatroomDetail;
    useEffect(()=>{
      let photoUrl=""
      if(!isGroupChat){
        let [user]=Object.values(chatusers).filter(user=>(user.username!==me.id));
        setGroupName(user?.display_name);
        if(user?.profile_photo){
          photoUrl=user?.profile_photo;
        } else{
          photoUrl=default_user;
        }
      } else{
        if(groupPhoto) photoUrl=groupPhoto;
        else photoUrl=default_group;
      }
      setGroupImageUrlObj({dataUrl:photoUrl});
      setGroupName(name);
      if(!isGroupChat){
        
      }
    },[chatroomDetail])
    const handleOnClose=()=>{
      onCloseAction();
      setShowAddPeopleDialog(false);
    }
    const handleUpdateProfile=()=>{
      handleCreateRoomAction({},groupName,groupImageUrlObj?.dataUrl,getRoomType.addPeople,roomkey,{})
      handleOnClose();
    }
  return (
    <>
      <ConfirmationDialog 
        open={showConfirmationDialog.open} 
        handleClose={()=>{setShowConfirmationDialog({...showConfirmationDialog,open:false})}} 
        confirmationDialogAction={()=>{
          if(isGroupChat){
            leaveChatAction(getSchoolId(),roomkey,me,true,chatusers);
          } else{
            deleteChatAction(getSchoolId(),roomkey,me,chatusers);
          }
          setShowConfirmationDialog({...showConfirmationDialog,open:false});
        }}
        title={showConfirmationDialog.title}
        message={showConfirmationDialog.message}
        buttontitle={showConfirmationDialog.buttontitle}
    />
      <Dialog
        open={open}
        onClose={handleOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="profile-container create-room-container"
        >
        {!showAddPeopleDialog?( 
        <>
        <DialogTitle id="customized-dialog-title" onClose={handleOnClose}>
          Profile
        </DialogTitle>
        <DialogContent style={{display:"flex",flexDirection:"column",alignItems:"center",overflow: "auto",padding:0}} id="custom-scroll">
            <div style={{width: "100%"}} className="avatar">
                <UploadImage imageUrlAction={(imgUrlObj)=>{setGroupImageUrlObj(imgUrlObj)}} imgUrl={groupImageUrlObj?.dataUrl} imgAlt={groupName} previewmode={!isGroupChat}/>
            </div>
            <div>
            {(!isGroupNameEdit)?(
              <div style={{display: "flex",justifyContent: "center",alignItems: "center",fontWeight: "bold",}}>
                <Typography variant="h4" gutterBottom style={{fontWeight:"bold"}}>{groupName}</Typography>
                {isGroupChat&&<IconButton aria-label="show 17 new notifications" color="inherit" style={{marginRight: "-28px"}} className="iconbutton" onClick={()=>setIsGroupNameEdit(!isGroupNameEdit)}>
                        <CreateOutlinedIcon />
                </IconButton>}
            </div>):(
              <div className="group-name-field" style={{textAlign:"center"}}>
					<TextField 
						id="standard-basic" 
						label=""
						value={groupName}
						onChange={(e)=>{setGroupName(e.target.value)}}
						placeholder={"Group name"}
						style={{fontSize:20,width:300,minWidth:150,padding: 10,}}
            />
                     <IconButton aria-label="show 17 new notifications" color="inherit" className="iconbutton" onClick={()=>setIsGroupNameEdit(!isGroupNameEdit)}>
                        <CloseIcon />
                    </IconButton>
                </div>)}
            </div>
            {isGroupChat&&<Typography variant="body1" className="adjust-allignments" style={{color:"rgba(0,0,0,0.5)"}} gutterBottom>{`Created by ${me.id===owner?"you":chatusers[owner]?.display_name}`}</Typography>}
            <div style={{width:"100%"}}>
                    {/* <ProfileOptions isGroupChat={isGroupChat}/> */}
                    {isGroupChat&&(
                      <div>
                        <Typography variant="body1" className="adjust-allignments" gutterBottom style={{fontWeight:"500"}}>{`${Object.keys(chatusers||{}).length} PARTICIPANTS`}</Typography>
                        <UserListPreview 
                          chatusers={chatusers} 
                          addPeopleAction={()=>{setShowAddPeopleDialog(true)}} 
                          isGroupChat={isGroupChat}
                          owner={owner}
                          roomkey={roomkey}
                          name={name}
                          groupPhoto={groupPhoto}
                          me={me}
                        /> 
                      </div>)}
                    <div style={{margin:"10px 20px 10px"}}>
                    <Typography 
                        variant="span" 
                        className="adjust-allignments" 
                        gutterBottom 
                        style={{cursor:"pointer",color:"red",fontWeight:"bold",fontSize: "18px",padding:"0px"}} 
                        onClick={()=>{
                          if(isGroupChat){
                            setShowConfirmationDialog({
                              open:true,
                              title:"Leave Group?",
                              message:"Are you sure you want to leave this group?",
                              buttontitle:"Leave"
                            })
                          } else{
                            setShowConfirmationDialog({
                              open:true,
                              title:"Delete conversation?",
                              message:"This will permanently delete the conversation history.",
                              buttontitle:"Delete"
                            })
                          }
                        }}>
                        {isGroupChat?"Leave group":"Delete conversation"}
                    </Typography>                
                    </div>
            </div>
        </DialogContent>
        {isGroupChat&&<DialogActions style={{padding:"16px 24px"}}>
          <Button onClick={handleOnClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateProfile} color="primary" autoFocus disabled={groupImageUrlObj?.loading}>
            Update
          </Button>
        </DialogActions>}
      </>
        ):(
          <CreateRoomContent
          toggleAction={handleOnClose}
          users={users}
          groups={groups}
          roomType={getRoomType.group}
          startIndex={1}
          previewmode={getRoomType.addPeople}
          handleCreateRoomAction={(userObj,groupName,dataUrl,previewmode)=>{
            handleCreateRoomAction(userObj,groupName,dataUrl,previewmode,roomkey,chatusers)
          }}
          me={me}
          groupPhoto={groupPhoto}
          oldgroupName={groupName}
          />
          )}
      </Dialog>
      </>
  );
}