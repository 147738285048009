import React, { Component, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

import UpdatePost from '../../container/UpdatePost';
import CreatePost from '../../container/CreatePost';
import PostFilter from '../../container/PostFilter';

import FeedCard, { FakeCard } from './FeedCard';
import PinnedPost from '../PinnedPost/PinnedPost';
import moment from "moment";

const styles = theme => ({
  scrollContent: {
    height: '75vh',
    paddingRight: "10px",
    paddingLeft: "15px",
    overflowX: 'hidden',
    overflowY: 'hidden',
    '&:hover': {
      overflowY: "scroll",
      // background: "#f00",
   },
    '&:first-child': {
      paddingLeft: 0,
    },
  },
  mb10: {
    marginBottom: '10px'
  },
  fontBold: {
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
});

class FeedPost extends Component {

  loadPostItems(page) {
    const { fetchPostAction, currentUser: { newUserInfo: { groupList: userGroups = [] } }, post: { query: _query } } = this.props;
    const groups = (userGroups || []).map(group => group.id);
    if (!!groups.length) {
      const query = {
        'page': page - 1,
        'itemsperpage': 10,
        // 'groups': [], // _query.groups.length > 0 ? _query.groups : groups,
        "is_pinned": false,
        "in_draft": false,
        "in_review": false,
        "is_scheduled": false
      }
      fetchPostAction.request(query);
    }
  }

  loadPinnedPostItems(page) {
    const { fetchPinnedPostAction, currentUser: { newUserInfo: { groupList: userGroups = [] } }, pinned: { query: _query } } = this.props;
    const groups = (userGroups || []).map(group => group.id);
    if (!!groups.length) {
      const query = {
        'page': page - 1,
        'itemsperpage': 10,
        // 'groups': [], // _query.groups.length > 0 ? _query.groups : groups,
        "is_pinned": true,
        "in_draft": false,
        "in_review": false,
        "is_scheduled": false
      }
      fetchPinnedPostAction.request(query);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.body.style.overflowY = "hidden";
    this.loadPostItems(1);
    this.loadPinnedPostItems(1);
  }

  UNSAFE_componentWillReceiveProps() {
    document.body.style.overflowY = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflowY = "auto";
  }

  getUserInfo = (id, type) => {
    const { currentUser: { newUserInfo: { usercache = {} } } } = this.props;
    const { profile_photo = "", firstName = "", lastName = "" } = usercache && usercache[id] || {}
    if(type === "image" && profile_photo) {
      return profile_photo || ""
    }
    if(type === "display_name" && (firstName || lastName)) {
      return `${firstName || ""} ${lastName || ""}`
    }
    return ""
  }

  getChildInfo = (id, type) => {
    const { currentUser: { newUserInfo: { childrencache = {} } } } = this.props;
    const { profile_photo = "", firstName = "", lastName = "" } = childrencache && childrencache[id] || {}
    if(type === "image" && profile_photo) {
      return profile_photo || ""
    }
    if(type === "display_name" && (firstName || lastName)) {
      return `${firstName || ""} ${lastName || ""}`
    }
    return ""
  }

  convertScheduleTime = (scheduledTime) => {
    const _tmp = new Date(scheduledTime.replace(/['"]+/g, '') + "+00:00");
    let hour = 0
    let ampm = ""
    if (_tmp.getHours() >= 12) {
      hour = _tmp.getHours() === 12 || _tmp.getHours() === 0 ? 12 : _tmp.getHours() - 12;
      ampm = 'PM';
    } else {
      hour = _tmp.getHours() === 0 ? 12 : _tmp.getHours();
      ampm = 'AM';
    }
    let minutes = _tmp.getMinutes() < 10 ? '0' + _tmp.getMinutes() : _tmp.getMinutes();
    return this.getShortMonthName(_tmp.getMonth() + 1) + ' ' + _tmp.getDate() + ' ' + _tmp.getFullYear() + ' ' + hour + ':' + minutes + ' ' + ampm;
  }

  getShortMonthName = (monthint) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
    return monthNames[monthint - 1]
  }

  getPostTime = (post_time) => {
    const dt = this.convertScheduleTime(post_time)
    return moment(dt).fromNow()
  }

  getGroupName = (id) => {
    const { currentUser: { newUserInfo: { groupcache = {} } } } = this.props;
    let value = ""
    if(id && groupcache[id] && Object.values(groupcache[id]).length) {
      value = groupcache[id].attributes.groupname
    }
    return value
  }

  render() {
    const {
      classes,
      post: {
        feeds,
        query: { hasMoreItems },
      },
      pinned: {
        feeds: pinnedfeeds,
        query: { hasMoreItems: pinnedhasMoreItems },
      },
      currentUser,
      saveCommentAction,
      updateCommentAction,
      deleteCommentAction,
      deletePostAction,
      likePostAction,
      unlikePostAction,
      cache,
      getUserCatch,
      currentUser: { newUserInfo: { groupList: userGroups = [] } }
    } = this.props;
    const postItems = [], pinnedItems = [];
    const fake = Array.from({ length: 2 }, (e, index) => (<FakeCard key={index} />));
    feeds && feeds.length && feeds.sort(function (a, b) {
      return a.row_num - b.row_num;
    });
    feeds.forEach((feed, i) => {
      postItems.push(
        <FeedCard
          key={i}
          data={feed}
          showPopOver
          currentUser={currentUser}
          openDialogAction={this.props.openDialogAction}
          formInitializeAction={this.props.formInitializeAction}
          deletePostAction={deletePostAction}
          saveCommentAction={saveCommentAction}
          updateCommentAction={updateCommentAction}
          deleteCommentAction={deleteCommentAction}
          likePostAction={likePostAction}
          unlikePostAction={unlikePostAction}
          postViewAction={this.props.postViewAction}
          userList={cache && cache.userCache}
          getUserInfo={this.getUserInfo}
          getPostTime={this.getPostTime}
          getGroupName={this.getGroupName}
          getChildInfo={this.getChildInfo}
        />);
    });

    pinnedfeeds.forEach((feed, i) => {
      pinnedItems.push(
        <PinnedPost
          key={i}
          loadPinPost={this.loadPinnedPostItems.bind(this)}
          data={feed}
          openDialogAction={this.props.openDialogAction}
          formInitializeAction={this.props.formInitializeAction}
          deletePostAction={deletePostAction}
          currentUser={currentUser}
          userList={cache && cache.userCache}
          getUserInfo={this.getUserInfo}
          getPostTime={this.getPostTime}
          getGroupName={this.getGroupName}
        />
      );
    });

    if (userGroups.length !== 0) {
      return (
        <Fragment>
          <Grid container style={{justifyContent: 'center', background: 'rgb(241 243 249)'}}>
            <Grid item id="custom-scroll" className={classes.scrollContent}>
              <CreatePost />
              <PostFilter />
              <UpdatePost loadPinPost={this.loadPinnedPostItems.bind(this)}/>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadPostItems.bind(this)}
                hasMore={hasMoreItems}
                useWindow={false}
                threshold={500}
              >
                {(feeds || []).length ? postItems : fake}
              </InfiniteScroll>
            </Grid>
            <Grid item id="custom-scroll" className={classes.scrollContent}>
              <Grid item className={classes.mb10}>
                <Typography variant="h6" className={classes.fontBold}>
                  Pinned Posts
                </Typography>
              </Grid>
              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadPinnedPostItems.bind(this)}
                hasMore={pinnedhasMoreItems}
                useWindow={false}
                threshold={500}
              >
                {(pinnedfeeds || []).length ? pinnedItems : null}
              </InfiniteScroll>

            </Grid>
          </Grid>
        </Fragment>
      )
    }
    return (<div />)
  }
}
export default withStyles(styles)(FeedPost);
