import React from 'react';
import moment from "moment";
import {Storage, API} from "aws-amplify";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { withStyles } from '@material-ui/core/styles';
import {Avatar, Button, Switch, List, Icon, Input, Tooltip, Select, Spin} from 'antd';
import { Container, Grid } from "@material-ui/core";
import {
    ImageUpload,
    TextLabelArea,
    TextLabelInput,
    getColor,
    openNotificationWithIcon,
    getSchoolId
} from "../../../GlobalComponents/GlobalFields";
import { createGroup } from "../../../../ApiServices"
import "../../../school.css"
import * as routes from "../../../../../../routes/path";
import authService from "../../../../../../service/auth";
import {newUserInfoSuccess} from "../../../../../../redux/actions/current-user";

const { Option } = Select

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class CreateGroup extends React.Component {
    state = {
        groupField: {
            groupname: '',
            description: '',
            attendance: false,
            profile_photo: ""
        },
        isImgUpload: false,
        groupErrors: {},
        errors: {},
        allStudentsList: [],
        allUsersList: [],
        selectedStudentsKeys: [],
        selectedUsersKeys: [],
        currentStudentsList: [],
        currentUsersList: [],
        childSearchKeys: "",
        userSearchKeys: "",
        searchSelectedChildKeys: "",
        searchSelectedUserKeys: "",
        selectGroupForChild: "all",
        selectGroupForUser: "all"
    };

    async componentWillMount(){
        const { currentUser: { newUserInfo : { childList = [], usersList = [] } } } = this.props;
        this.setState({
            allStudentsList: childList,
            allUsersList: usersList
        })
    }

    handelOnChange = (e) =>{
        const {groupField} = this.state
        this.setState({
            groupField:{
                ...groupField,
                [e.target.name]: e.target.value
            }
        })
    }

    handelAttendance = (item) =>{
        const {groupField} = this.state
        this.setState({
            groupField:{
                ...groupField,
                attendance: item
            }
        })
    }

    handelReview = async () => {
        const { groupField, currentStudentsList, currentUsersList, errors } = this.state
        const { groupname, description, profile_photo, attendance } = groupField || {}

        if(!groupname){
            errors.groupname = true
        } else {
            delete errors.groupname
        }

        if(!description){
            errors.description = true
        } else {
            delete errors.description
        }

        this.setState({
            errors
        })
        if(errors && Object.keys(errors).length > 0){
            return openNotificationWithIcon('error', 'Fields required!')
        }

        this.setState({isSaving: true})

        const payload = {
            attributes: {
                description,
                groupname,
                profile_photo
            },
            // id: 3,
            school_id: getSchoolId(),
            child_ids: (currentStudentsList || []).map(child => child.id),  // pass selected child ids
            user_ids: (currentUsersList || []).map(child => child.id)  // pass selected user ids
        }

        const response = await createGroup(payload)
        if(response && response.status === "success") {
            openNotificationWithIcon('success', response.message)
            const newUserResponse = await authService.getNewUserDetail();
            if(newUserResponse && newUserResponse.data) {
                this.props.onSetUserInfo(newUserResponse.data)
            }
            setTimeout(() => {
                this.props.history.push(routes.GROUPS_DASHBOARD)
                this.setState({isSaving: false})
            }, 1000)
        } else {
            openNotificationWithIcon('error', response.message || 'Something went wrong')
            this.setState({isSaving: false})
        }
    }

    uploadFile = async (file) => {
        let key = '';
        const name = `${moment ().format ('x')}`;
        await Storage.put (name, file, {
            contentType: file.type,
        }).then (
            resp => {
                key = resp.key;
            },
            err => {
                console.log (err);
            }
        );
        return key;
    }

    getFileUrl = async (key) => {
        let url = '';
        url = await Storage.get (key);
        const URL = url.split("?")[0]
        return (URL || url);
    }

    image = async (info, type) =>{
        const {groupField} = this.state
        if (info.file.status === 'uploading') {
            this.setState({
                isImgUpload: true
            })
            return;
        }

        if (info.file.status === 'done') {
            let key = await this.uploadFile (info.file.originFileObj);
            let imageurl = await this.getFileUrl (key);
            this.setState({
                groupField: {
                    ...groupField,
                    profile_photo: imageurl
                },
                isImgUpload: false
            })
        }
    }

    handelChildSwitch = (key) =>{
        const { selectedStudentsKeys } = this.state
        const isAvailabal = selectedStudentsKeys && selectedStudentsKeys.find(l => l.id === key.id)
        if(isAvailabal){
            const data = selectedStudentsKeys.filter(p => p.id !== key.id)
            this.setState({
                selectedStudentsKeys: data || []
            })
        }else {
            selectedStudentsKeys.push(key)
            this.setState({
                selectedStudentsKeys
            })
        }
    }

    handelUserSwitch = (key) =>{
        const { selectedUsersKeys } = this.state
        const isAvailabal = selectedUsersKeys && selectedUsersKeys.find(l => l.id === key.id)
        if(isAvailabal){
            const data = selectedUsersKeys.filter(p => p.id !== key.id)
            this.setState({
                selectedUsersKeys: data || []
            })
        }else {
            selectedUsersKeys.push(key)
            this.setState({
                selectedUsersKeys
            })
        }
    }

    handleChildSelectAll = () => {
        const allStudentsList = this.getAllStudentsList()
        this.setState({
            selectedStudentsKeys: allStudentsList || []
        })
    }

    handleUserSelectAll = () => {
        const allUsersList = this.getAllUsersList()
        this.setState({
            selectedUsersKeys: allUsersList || []
        })
    }

    handleAddSelectedChild = () => {
        const {selectedStudentsKeys, allStudentsList, currentStudentsList} = this.state
        let studentsList = currentStudentsList || []
        if(selectedStudentsKeys.length === allStudentsList.length){
            this.setState({
                currentStudentsList: selectedStudentsKeys || [],
                selectedStudentsKeys: [],
                allStudentsList: []
            })
        }else {
            selectedStudentsKeys.forEach(p => {
                let ind = allStudentsList.findIndex(k => k.id === p.id)
                allStudentsList.splice(ind, 1)
                studentsList.push(p)
            })
            this.setState({
                currentStudentsList: studentsList,
                selectedStudentsKeys: [],
                allStudentsList
            })
        }
    }

    handleAddSelectedUser = () => {
        const {selectedUsersKeys, allUsersList, currentUsersList} = this.state
        let usersList = currentUsersList || []
        if(selectedUsersKeys.length === allUsersList.length){
            this.setState({
                currentUsersList: selectedUsersKeys || [],
                selectedUsersKeys: [],
                allUsersList: []
            })
        }else {
            selectedUsersKeys.forEach(p => {
                let ind = allUsersList.findIndex(k => k.id === p.id)
                allUsersList.splice(ind, 1)
                usersList.push(p)
            })
            this.setState({
                currentUsersList: usersList,
                selectedUsersKeys: [],
                allUsersList
            })
        }
    }

    handleRemoveSelectedChild = (item) => {
        const {allStudentsList, currentStudentsList} = this.state
        allStudentsList.push(item)
        let ind = currentStudentsList.findIndex(k => k.id === item.id)
        currentStudentsList.splice(ind, 1)
        this.setState({
            allStudentsList,
            currentStudentsList
        })
    }

    handleRemoveSelectedUser = (item) => {
        const {allUsersList, currentUsersList} = this.state
        allUsersList.push(item)
        let ind = currentUsersList.findIndex(k => k.id === item.id)
        currentUsersList.splice(ind, 1)
        this.setState({
            allUsersList,
            currentUsersList
        })
    }

    getAllStudentsList = () => {
        const {allStudentsList, childSearchKeys, currentStudentsList, selectGroupForChild} = this.state
        let dataItem = []

        if(!childSearchKeys && selectGroupForChild === 'all'){
            dataItem = currentStudentsList.length === allStudentsList.length ? [] : allStudentsList
        } else {
            let duplicateData = cloneDeep(allStudentsList)
            dataItem = (duplicateData || []).filter(p => {
                const { firstName = "", lastName = "" } = p || {}
                return((firstName || "").toString().toLowerCase().includes(childSearchKeys.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(childSearchKeys.toLowerCase()))
            })
            if(selectGroupForChild !== 'all') {
                dataItem = (dataItem || []).filter(child => child.group_ids.some(group => selectGroupForChild === group));
            }
        }

        dataItem = (dataItem || []).sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0))

        return dataItem
    }

    getAllUsersList = () => {
        const {allUsersList, userSearchKeys, currentUsersList, selectGroupForUser} = this.state
        let dataItem = []

        if(!userSearchKeys && selectGroupForUser === 'all'){
            dataItem = currentUsersList.length === allUsersList.length ? [] : allUsersList
        } else {
            let duplicateData = cloneDeep(allUsersList)
            dataItem = (duplicateData || []).filter(p => {
                const { firstName = "", lastName = "" } = p || {}
                return((firstName || "").toString().toLowerCase().includes(userSearchKeys.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(userSearchKeys.toLowerCase()))
            })
            if(selectGroupForUser !== 'all') {
                dataItem = (dataItem || []).filter(child => child.group_ids.some(group => selectGroupForUser === group));
            }
        }

        dataItem = (dataItem || []).sort((a, b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0))

        return dataItem
    }

    getSelectedChildList = () =>{
        const { currentStudentsList, searchSelectedChildKeys } = this.state
        let dataItemList = []
        if(!searchSelectedChildKeys){
            return currentStudentsList
        }
        const duplicateData = cloneDeep(currentStudentsList)
        dataItemList = (duplicateData || []).filter(p => {
            const { firstName = "", lastName = "" } = p || {}
            return((firstName || "").toString().toLowerCase().includes(searchSelectedChildKeys.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(searchSelectedChildKeys.toLowerCase()))
        })
        return dataItemList
    }

    getSelectedUserList = () =>{
        const { currentUsersList, searchSelectedUserKeys } = this.state
        let dataItemList = []
        if(!searchSelectedUserKeys){
            return currentUsersList
        }
        const duplicateData = cloneDeep(currentUsersList)
        dataItemList = (duplicateData || []).filter(p => {
            const { firstName = "", lastName = "" } = p || {}
            return((firstName || "").toString().toLowerCase().includes(searchSelectedUserKeys.toLowerCase()) || (lastName || "").toString().toLowerCase().includes(searchSelectedUserKeys.toLowerCase()))
        })
        return dataItemList
    }

    render() {
        const { classes, currentUser: { newUserInfo : { groupcache: groupList = [] } } } = this.props;
        const { groupField, isImgUpload, groupErrors, selectedStudentsKeys, selectedUsersKeys, errors, isSaving } = this.state;
        const selectedChildKeys = selectedStudentsKeys.map(p => p.id) || []
        const selectedUserKeys = selectedUsersKeys.map(p => p.id) || []
        return (
            <div className={`student ${classes.root}`}>
                <div>
                    <div className="text-center mb-40">
                        <h3><b>Add Groups</b></h3>
                    </div>
                    <Container className="rounded-1rem bg-white p-40 mt-100 shadow w-50">
                        <div style={{marginTop: "-120px", textAlign: 'center'}}>
                            <ImageUpload
                                isImgUpload={isImgUpload}
                                imageUrl={groupField.profile_photo}
                                handleImage={(info)=> this.image(info, 'student')}/>
                        </div>

                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={6}>
                                <TextLabelInput
                                    name="groupname"
                                    value={groupField.groupname}
                                    onChange={this.handelOnChange}
                                    placeholder="Group Name"
                                    label="Group Name"
                                    required
                                    error={errors && errors.groupname ? "Required" : ""}
                                />
                            </Grid>
                            <br/>
                            <Grid item xs={12}>
                                <TextLabelArea
                                    value={groupField.description}
                                    name="description"
                                    label="Group Description"
                                    placeholder="Description"
                                    onChange={this.handelOnChange}
                                    required
                                    rows={4}
                                    error={errors && errors.description ? "Required" : ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="input-group">
                                    <div className="multiExpLblBlk font-bold text-10">
                                        Attendance
                                    </div>
                                    <Switch
                                        className="ml-2"
                                        name="attendance"
                                        onChange={this.handelAttendance}
                                        checked={groupField.attendance}/>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Add child to group</b></h3>
                    </div>
                    <Container>
                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={7}>
                                <div className={'flex justify-between'}>
                                    <h4>Add</h4>
                                    <Select
                                        placeholder={'Select Group'}
                                        style={{width: '35%'}}
                                        onChange={(value) => this.setState({selectGroupForChild: value})}
                                    >
                                        <Option value={'all'}>All Groups</Option>
                                        {
                                            groupList && Object.keys(groupList).map((key, index) => {
                                                const name = (groupList[key] && groupList[key].attributes && groupList[key].attributes.groupname) || ""
                                                return(
                                                    <Option key={index.toString()} value={groupList[key].id}>{name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                                <Container className="rounded-1rem bg-white p-4 shadow">
                                    <Input className="w-100 mb-2"
                                           placeholder="Search"
                                           onChange={(e) => this.setState({childSearchKeys: e.target.value})}/>
                                    <div className="demo-infinite-container">
                                        <List
                                            dataSource={this.getAllStudentsList()}
                                            renderItem={(item, index) => {
                                                const { id, profile_photo = "", firstName = "", lastName = "" } = item || {}
                                                return(
                                                    <List.Item key={id} onClick={() => this.handelChildSwitch(item)}>
                                                        <List.Item.Meta
                                                            avatar={
                                                                profile_photo ?
                                                                    <Avatar src={profile_photo} /> :
                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                        {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                                    </Avatar>
                                                            }
                                                            title={<div className="text-12 pt-3">{firstName} {lastName}</div>}
                                                        />
                                                        <div>
                                                            <Switch
                                                                onChange={() => this.handelChildSwitch(item)}
                                                                checked={selectedChildKeys.includes(id)}
                                                            />
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                        >
                                        </List>
                                    </div>
                                    <div className="text-right pt-12">
                                        <Button
                                            onClick={this.handleChildSelectAll}
                                            disabled={selectedStudentsKeys.length === this.getAllStudentsList().length}
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            onClick={()=>this.setState({selectedStudentsKeys: []})}
                                            className="ml-2"
                                            disabled={!selectedStudentsKeys.length}
                                        >
                                            Deselect All
                                        </Button>
                                        <Button
                                            className="ml-2 review-button"
                                            disabled={!selectedStudentsKeys.length}
                                            onClick={this.handleAddSelectedChild}
                                        >
                                            Add Selected ({selectedStudentsKeys.length || 0})
                                        </Button>
                                    </div>
                                </Container>
                            </Grid>
                            <br/>
                            <Grid item xs={5}>
                                <h4>Current Child</h4>
                                <Container className="rounded-1rem bg-white p-4 shadow">
                                    <Input
                                        className="w-100 mb-2"
                                        placeholder="Search"
                                        onChange={(e) => this.setState({searchSelectedChildKeys: e.target.value})}/>
                                    <div className="demo-infinite-container">
                                        <List
                                            dataSource={this.getSelectedChildList()}
                                            renderItem={(item, index) => {
                                                const { id, profile_photo = "", firstName = "", lastName = "" } = item || {}
                                                return(
                                                    <List.Item key={id}>
                                                        <List.Item.Meta
                                                            avatar={
                                                                profile_photo ?
                                                                    <Avatar src={profile_photo} /> :
                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                        {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                                    </Avatar>
                                                            }
                                                            title={<div className="text-12 pt-3">{firstName} {lastName}</div>}
                                                        />
                                                        <Tooltip title={(item.parents || []).map(x => <div>{x}</div>)}> <div className={'mr-4'}><Icon type="info-circle" style={{fontSize: 16}} theme="filled" /></div> </Tooltip>
                                                        <div><Icon type="delete"  style={{fontSize: 16}} onClick={()=>this.handleRemoveSelectedChild(item)} className="text-danger" theme="filled" /></div>
                                                    </List.Item>
                                                )
                                            }}
                                        >
                                        </List>
                                    </div>
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <div className="text-center mt-20 mb-20">
                        <h3><b>Add user to group</b></h3>
                    </div>
                    <Container>
                        <Grid container spacing={6} style={{marginTop: 0}}>
                            <Grid item xs={7}>
                                <div className={'flex justify-between'}>
                                    <h4>Add</h4>
                                    <Select
                                        placeholder={'Select Group'}
                                        style={{width: '35%'}}
                                        onChange={(value) => this.setState({selectGroupForUser: value})}
                                    >
                                        <Option value={'all'}>All Groups</Option>
                                        {
                                            groupList && Object.keys(groupList).map((key, index) => {
                                                const name = (groupList[key] && groupList[key].attributes && groupList[key].attributes.groupname) || ""
                                                return(
                                                    <Option key={index.toString()} value={groupList[key].id}>{name}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                                <Container className="rounded-1rem bg-white p-4 shadow">
                                    <Input
                                        className="w-100 mb-2"
                                        placeholder="Search"
                                        onChange={(e) => this.setState({userSearchKeys: e.target.value})}
                                    />
                                    <div className="demo-infinite-container">
                                        <List
                                            dataSource={this.getAllUsersList()}
                                            renderItem={(item, index) => {
                                                const { id, profile_photo = "", firstName = "", lastName = "" } = item || {}
                                                return(
                                                    <List.Item key={id} onClick={() => this.handelUserSwitch(item)}>
                                                        <List.Item.Meta
                                                            avatar={
                                                                profile_photo ?
                                                                    <Avatar src={profile_photo} /> :
                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                        {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                                    </Avatar>
                                                            }
                                                            title={<div className="text-12 pt-3">{firstName} {lastName}</div>}
                                                        />
                                                        <div>
                                                            <Switch
                                                                onChange={() => this.handelUserSwitch(item)}
                                                                checked={selectedUserKeys.includes(id)}
                                                            />
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                        >
                                        </List>
                                    </div>
                                    <div className="text-right pt-12">
                                        <Button
                                            onClick={this.handleUserSelectAll}
                                            disabled={selectedUsersKeys.length === this.getAllUsersList().length}
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            onClick={() => this.setState({selectedUsersKeys: []})}
                                            className="ml-2"
                                            disabled={!selectedUsersKeys.length}
                                        >
                                            Deselect All
                                        </Button>
                                        <Button
                                            className="ml-2 review-button"
                                            disabled={!selectedUsersKeys.length}
                                            onClick={this.handleAddSelectedUser}
                                        >
                                            Add Selected ({selectedUsersKeys.length || 0})
                                        </Button>
                                    </div>
                                </Container>
                            </Grid>
                            <br/>
                            <Grid item xs={5}>
                                <h4>Current Child</h4>
                                <Container className="rounded-1rem bg-white p-4 shadow">
                                    <Input
                                        className="w-100 mb-2"
                                        placeholder="Search"
                                        onChange={(e) => this.setState({searchSelectedUserKeys: e.target.value})}
                                    />
                                    <div className="demo-infinite-container">
                                        <List
                                            dataSource={this.getSelectedUserList()}
                                            renderItem={(item, index) => {
                                                const { id, profile_photo = "", firstName = "", lastName = "" } = item || {}
                                                return(
                                                    <List.Item key={id}>
                                                        <List.Item.Meta
                                                            avatar={
                                                                profile_photo ?
                                                                    <Avatar src={profile_photo} /> :
                                                                    <Avatar aria-label="recipe"  style={{backgroundColor: getColor(index)}}>
                                                                        {`${(firstName || "").toString().charAt(0).toUpperCase()}${(lastName || "").toString().charAt(0).toUpperCase()}`}
                                                                    </Avatar>
                                                            }
                                                            title={<div className="text-12 pt-3">{firstName} {lastName}</div>}
                                                        />
                                                        <Tooltip
                                                            title={(item.parents || []).map(x => <div>{x}</div>)}
                                                        >
                                                            <div className={'mr-4'}><Icon type="info-circle" style={{fontSize: 16}} theme="filled" /></div>
                                                        </Tooltip>
                                                        <div>
                                                            <Icon
                                                                type="delete"
                                                                style={{fontSize: 16}}
                                                                onClick={() => this.handleRemoveSelectedUser(item)}
                                                                className="text-danger"
                                                                theme="filled"
                                                            />
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                        >
                                        </List>
                                    </div>
                                </Container>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <div>
                    <Container>
                        <div className="p-card-title text-center mt-20">
                            <Button size="large">Cancel</Button>&nbsp;
                            <Button size="large" className="review-button" onClick={this.handelReview}>{ isSaving ? <Spin style={{marginRight: 10}}/> : null } Submit</Button>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    currentUser: state.currentUser
})

const mapDispatchToProps = (dispatch) => {
    return {
        onSetUserInfo: (data) => {
            dispatch(newUserInfoSuccess(data))
        }
    }
}

CreateGroup.propTypes = {
    classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CreateGroup))