import React from 'react';
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Cancel';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import deepOrange from '@material-ui/core/colors/deepOrange';
import deepPurple from '@material-ui/core/colors/deepPurple';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { change } from 'redux-form'
import {Input} from "@material-ui/core";

const styles = {
    avatar: {
        margin: "10px 10px 10px 25px",
    },
    avatarLable: {
        margin: "10px 20px",
    },
    orangeAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepOrange[500],
    },
    purpleAvatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: deepPurple[500],
    },
    groupSection: {
        margin: '25px 0px',
    },
    noSpace: {
        margin: 0,
        padding: 0
    },
    selectionSection: {
        borderRight: '1px solid #0093AF',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
    },
    scrollingWrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        minHeight: '150px',
    },
    card: {
        flex: '0 0 auto',
    },
    backgroundParents: {
        backgroundColor: "rgba(0, 0, 0, .05);",
    },
    rowC: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dialogContent: {
        padding: 0,
        overflow: 'scroll',
        overflowX: "hidden"
    },
    titleStyle: {
        textAlign: "center",
        color: "#000",
    },
    checkBox: {
        color: 'rgb(73,80,183)',
        '&$checked': {
            color: 'rgb(73,80,183)',
        },
    },
    checked: {},
};

class GroupParentsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
            option: [],
            groupSelection: '',
            name: [],
            GroupName: '',
            parents: [],
            inputParentNames: [],
            selectedGroup: "",
            searchText: "",
            groupList: [],
            parentlist: [],
        };
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    componentDidMount = () => {
        /*Pre load parent when open dialog*/
        this.setState({
            parents: this.props.groups[0].students,
            selectedGroup: this.props.groups[0].id,
            parentlist: this.props.parentlist
        });
    }

    handleToggle = (id) => {
        let { parentlist } = this.state;

        if ((parentlist || []).includes(id)) {
            parentlist = (parentlist || []).filter(x => x !== id)
        } else {
            parentlist.push(id);
        }

        this.setState({ parentlist });
    };

    handleCancel = () => {
        this.props.onClose(this.props.value);
    };

    handleOk = () => {
        this.props.saveParent(this.state.parentlist)
        this.props.onClose(this.state.value);
    };

    handleChange = key => (event, value) => {
        this.setState({ [key]: value, });
    };

    handleSelectGroup = (id) => {
        let selectedGroups = (this.props.groups || {}).find(x => x.id === id)
        this.setState({ selectedGroup: id, parents: selectedGroups.students || [] });
    };

    handleTextChange = (e) => {
        const { parents } = this.state
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (parents.length) {
                this.onSearch()
            }
        })
    }

    onSearch = () => {
        const { searchText, parents } = this.state
        if (searchText) {
            const searchList = parents.filter(obj => ["display_name"].some(key => obj[key] && obj[key].toLowerCase().includes(searchText.toLowerCase())))
            this.setState({
                searchList
            })
        }
    }

    getStudentName = (id) => {
        const { currentUser: { newUserInfo: { childrencache = {} } = {} } = {} } = this.props
        const { firstName = "", lastName = "" } = childrencache && childrencache[id] || {}
        if(firstName || lastName) {
            return `${firstName || ""} ${lastName}`
        }
        return ""
    }

    getStudentsList = () => {
        const { parents, searchText } = this.state;
        let array = cloneDeep(parents || [])

        if(searchText) {
            array = (parents || []).filter(obj => ["firstName", "lastName"].some(key => obj[key] && obj[key].toLowerCase().includes(searchText.toLowerCase())))
        }

        array && array.sort(function (a, b) {
            const genreA = (a.firstName || "").toUpperCase();
            const genreB = (b.firstName || "").toUpperCase();

            let comparison = 0;
            if (genreA > genreB) {
                comparison = 1;
            } else if (genreA < genreB) {
                comparison = -1;
            }
            return comparison;
        });

        return array
    }

    render() {
        const { groups, classes, open, onClose, disabled } = this.props;
        const { selectedGroup, parentlist, searchText } = this.state;
        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                fullWidth={true}
                open={open}
                onClose={onClose}
                >

                <DialogTitle id="confirmation-dialog-title">Group/Students Selection</DialogTitle>

                <Grid container justify="flex-start" alignItems="center" className={classes.scrollingWrapper}>
                    {groups.map((option, index) => (
                        <div
                            key={index}
                            onClick={() => this.handleSelectGroup(option.id)}
                            className={classes.card}
                            style={option.id === selectedGroup ? {background: "#9ed0f9", borderRadius: 12} : null}
                        >
                            <Avatar className="groupAvtar">{(option.groupName).charAt(0)}</Avatar>
                            <Typography className="groupAvatarLable">
                                {
                                    option.groupName.length <= 13 ?
                                        option.groupName :
                                        option.groupName.substring(0, 10) + "..."}
                            </Typography>
                        </div>
                    ))}
                </Grid>

                <Divider variant="middle" />
                <DialogContent className="dialogContent">
                    <div className={classes.rowC}>
                        <Grid item xs={6}>
                            <Typography color="primary" variant="h6" className={classes.titleStyle}>
                                List of Students from Group
                            </Typography>
                            <Typography color="primary" variant="h6" className={classes.titleStyle}>
                                <Input
                                    className={classes.w100}
                                    name="searchText"
                                    value={searchText}
                                    placeholder="Search"
                                    autoComplete="off"
                                    margin="none"
                                    disableUnderline
                                    onChange={this.handleTextChange}
                                />
                            </Typography>
                            <List className="parentList">
                                {(this.getStudentsList() || []).map(parent => (
                                    <ListItem key={parent.id} role={undefined} dense button
                                        onClick={() => this.handleToggle(parent.id)} disabled={disabled}>
                                        <ListItemAvatar>
                                            <Avatar className="avatarColor">{(parent.firstName || "").charAt(0)}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText className="parentName" primary={`${parent.firstName || ""} ${parent.lastName || ""}` || ""} />
                                        <Checkbox icon={<CheckCircleOutline />}
                                            checkedIcon={<CheckCircle />}
                                            classes={{
                                                root: classes.checkBox,
                                                checked: classes.checked,
                                            }}
                                            checked={(parentlist || []).includes(parent.id)}
                                            tabIndex={-1}
                                            disableRipple
                                            disabled={disabled}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={6} className={classes.backgroundParents}>
                            <Typography color="primary" variant="h6" className={classes.titleStyle}>
                                All selected Students
                            </Typography>
                            <List className="parentList">
                                {parentlist.map(parent => (
                                    <ListItem key={parent} role={undefined} dense button>
                                        <ListItemAvatar>
                                            <Avatar className="avatarColor">P</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText className="parentName" primary={(this.getStudentName(parent) || "")} />
                                        <ListItemSecondaryAction>
                                            <IconButton aria-label="Remove" onClick={() => this.handleToggle(parent)} disabled={disabled}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    </div>
                </DialogContent>
                <Divider variant="middle" />
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleOk} color="primary" disabled={disabled}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => state


const mapDispatchToProps = dispatch => {
    return {
        saveParent: parent => dispatch(change("generalDetailsForm", `attributes.child_ids`, parent))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GroupParentsDialog));