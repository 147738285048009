import React, { Fragment } from 'react';
import * as routes from './path';
import { Route } from 'react-router-dom';
import Welcome from '../container/Home';
import Login from '../container/login';
import ComingSoon from '../container/ComingSoon';
import Event from '../container/Event';
import ManageGenericEvent from '../container/ManageGenericEvent';
import ManageRsvp from '../container/ManageRsvp';
import ManageMeeting from '../container/ManageMeeting';
import ManageVoluntter from '../container/ManageVolunteer';
import UpdateRsvp from '../container/UpdateRsvp';
import SignupMeeting from '../container/SignupMeeting';
import BriefCase from '../container/BriefCase';


// import NewsLetter from '../container/NewsLetter';
import NewsLetter from "../component/NewNewsLetter";
import Chat from "../container/Chat";
// import EditNewsLetter from '../container/EditNewsLetter';
import SignupVolunteer from '../container/SignupVolunteer';
import ProgressReport from '../component/ProgressReport';
import AddModule from '../component/ProgressReport/components/AddModule';
import GroupsList from '../component/ProgressReport/components/GroupsList';
import GroupStudents from '../component/ProgressReport/components/GroupStudents';
import GroupDetails from '../component/ProgressReport/components/GroupDetails';
import Photos from '../container/photos'
import ClassDashboard from '../component/SchoolProfile/components/TeacherView/Class/Dashboard'
import ClassProfile from '../component/SchoolProfile/components/TeacherView/Class/components/GroupProfile'
import StudentProfile from '../component/SchoolProfile/components/Students/components/StudentProfile';
import StaffProfile from '../component/SchoolProfile/components/Staffs/components/StaffProfile';
import ParentProfile from '../component/SchoolProfile/components/Parents/components/ParentProfile';
// import CurrentParentProfile from '../component/SchoolProfile/components/ParentsView/MyProfile';
import CurrentTeacherProfile from '../component/SchoolProfile/components/TeacherView/MyProfile';
import StudentAttendanceForTeacher from '../component/SchoolProfile/components/Students/components/StudentProfile/NewAttendance';
import StaffAttendanceForTeacher from '../component/SchoolProfile/components/Staffs/components/StaffProfile/StaffAttendance';

import awsConfig from '../utils/aws-config';
import PreCheckin from "../component/SchoolProfile/components/TeacherView/PreCheckin";
import PlanLesson from "../component/Lessons/Plan";
import RecordLesson from "../component/Lessons/Record";
import Lesson from "../component/Lessons/Lesson";


export default function OtherRoutes() {
    return (
        <Fragment>
            <Route component={ComingSoon} path={routes.PORTFOLIO} exact />
            <Route component={NewsLetter} path={routes.NEWSLETTER} exact />
            <Route component={Chat} path={routes.CHATS} exact />
            {/*<Route component={EditNewsLetter} path={routes.NEWSLETTER_CREATE} exact />*/}
            <Route component={Welcome} path={routes.ROOT} exact />
            <Route component={BriefCase} path={routes.BRIEFCASE} exact />
            <Route component={Event} path={routes.EVENT} exact />
            <Route component={ManageGenericEvent} path={routes.MANAGE_EVENT} exact />
            <Route component={ManageGenericEvent} path={routes.EDIT_MANAGE_EVENT} exact />
            <Route component={UpdateRsvp} path={routes.EDIT_RSVP_EVENT} />
            <Route component={ManageRsvp} path={routes.MANAGE_RSVP_EVENT} exact />
            <Route component={ManageRsvp} path={routes.EDIT_MANAGE_RSVP_EVENT} exact />
            <Route component={ManageMeeting} path={routes.MEETING_EVENT} exact />
            <Route component={ManageMeeting} path={routes.EDIT_MEETING_EVENT} exact />
            <Route component={SignupMeeting} path={routes.MEETING_EVENT_SIGNUP} exact />
            <Route component={SignupMeeting} path={routes.EDIT_MEETING_EVENT_SIGNUP} exact />
            <Route component={ManageVoluntter} path={routes.VOLUNTEER_EVENT} exact />
            <Route component={SignupVolunteer} path={routes.VOLUNTEER_EVENT_SIGNUP} />
            <Route component={Photos} path={routes.Photos} exact />
            {/*<Route component={() => {
                        window.open(awsConfig.app_report_url,"_blank")
                        
                        return null;
                    }}
                    path={routes.ProgressReport} exact />*/}

            <Route component={ClassDashboard} path={routes.CLASSES_DASHBOARD} exact />
            <Route component={ClassProfile} path={routes.CLASSES_VIEW} exact />
            <Route component={StudentProfile} path={routes.CLASSES_STUDENT_VIEW} exact />
            <Route component={StaffProfile} path={routes.CLASSES_STAFF_VIEW} exact />
            <Route component={ParentProfile} path={routes.CLASSES_PARENT_VIEW} exact />
            <Route component={CurrentTeacherProfile} path={routes.PARENT_PROFILE} exact />
            <Route component={StaffAttendanceForTeacher} path={routes.STAFF_ATTENDANCE_VIEW_FOR_TEACHER} exact />
            <Route component={StudentAttendanceForTeacher} path={routes.STUDENT_ATTENDANCE_VIEW_FOR_TEACHER} exact />
            <Route component={PreCheckin} path={routes.TEACHER_PRE_CHECKIN_FORM} exact />
            {/*<Route component={CurrentParentProfile} path={routes.TEACHER_PROFILE} exact />*/}

            <Route component={ProgressReport} path={routes.ProgressReport} exact />
            <Route component={AddModule} path={routes.ADD_MODULE_ROUTE} exact />
            <Route component={AddModule} path={routes.EDIT_MODULE_ROUTE} exact />
            <Route component={GroupsList} path={routes.GROUPS_LIST} exact />
            <Route component={GroupStudents} path={routes.GROUPS_STUDENTS} exact />
            <Route component={GroupDetails} path={routes.GROUP_DETAILS} exact />
                <Route component={Lesson} path={routes.LESSON} exact />

        </Fragment>
    );
}