import React,{useState} from "react";
import Avatar from '@material-ui/core/Avatar';
import moment from "moment";
import Linkify from 'react-linkify';
import { getLastDateTimeMessage } from "../../../../utils/utils";
import ImageLightbox from "./../ImageLightbox/ImageLightbox"
import "video-react/dist/video-react.css";
import { Player } from 'video-react';
import "./index.css";

function ChatBubble({message={},me={}}){
  const [showLightboxPreview,setShowLightboxPreview]=useState(false)
  const [imageslist,setImageslist]=useState([])
    const alignUser = message.user._id === me.id;
    const linkDecorator = (href, text, key) => (
      <a
        href={href}
        className={alignUser? "text-link text-link-right": "text-link text-link-left"}
        key={key}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        {text}
      </a>
    );
    const getPhotoArray=(images=[])=>{
      let photos=[];
        images.forEach((img,idx)=>{
          if(idx===0){
            photos=[...photos,{src: img,width: 4,height: 3}]
          } else{
            photos=[...photos,{src: img,width: 1,height: 1}]
          }
        })
        //console.log(photos,images,"getPhotoArray")
      return photos;  
  }
 const _renderVideo = (videoUrl) => {
    // console.log(this.state.videoFileUrl,this.state.videoFilePath,"handleAttachFiles")
    return (
      videoUrl && (
        <div className='image-gallery-video' style={{background:"#fff",height:"300px",width:"300px"}}>
            <Player
                className='video-react'
                playsInline
                fluid={false}
                src={videoUrl}
                height={300}
                width={300}
                style={{marginBottom: "5px",borderRadius: "5px"}}
            />
        </div>)
    )
}
    return(
      <div className={alignUser? "bubble-container bubble-container-right":"bubble-container bubble-container-left"} key={message._id}>
        <ImageLightbox 
          isOpen={showLightboxPreview} 
          images={imageslist}
          onCloseAction={()=>{setShowLightboxPreview(false)}}
        />
        <div className="avatar">
          <Avatar alt={message.user.name} src={message.user.avatar} heigth={40} width={40} className="icon"/>
        </div>
        <div className="chat-bubble-parent">
            <div style={{ fontSize:10}}>{message.user.name}</div>
            {message?.image? (
            <div className={alignUser? "chat-bubble chat-bubble-image chat-bubble-right": "chat-bubble chat-bubble-image chat-bubble-left"}>
                  <div className="image-root" onClick={()=>{
                    setShowLightboxPreview(!showLightboxPreview);
                    setImageslist(message.image);
                    }}>
                    <img
                      src={message?.image[0]}
                      className="images"
                      alt="image"
                  />
                  {message.image.length>1&&<h3 className="heading">{`+${message.image.length-1}`}</h3>}
                </div>
            <Linkify componentDecorator={linkDecorator}>
              <div className="text">{message.text}</div>
            </Linkify>
              <div className={alignUser?"time time-right":"time time-left"}>{getLastDateTimeMessage(message.orderBy)}</div>
            </div>
          ):(message?.video)?(
            <div className={alignUser? "chat-bubble chat-bubble-image chat-bubble-right": "chat-bubble chat-bubble-image chat-bubble-left"}>
                  <div className="image-root" onClick={()=>{}}>
                  {_renderVideo(message?.video[0])}
                </div>
            <Linkify componentDecorator={linkDecorator}>
              <div className="text">{message.text}</div>
            </Linkify>
              <div className={alignUser?"time time-right":"time time-left"}>{getLastDateTimeMessage(message.orderBy)}</div>
            </div>
          ):(
            <div className={alignUser? "chat-bubble chat-bubble-right": "chat-bubble chat-bubble-left"}>
                <Linkify componentDecorator={linkDecorator}>
                  <div className="text">{message.text}</div>
                </Linkify>
                  <div className={alignUser?"time time-right":"time time-left"}>{getLastDateTimeMessage(message.orderBy)}</div>
              </div>
                )}
        </div>
      </div>
    )
}
export default ChatBubble;