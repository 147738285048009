import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import CreateRoomContent from "./CreateRoomContent"
import "./index.css"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
	containerRoomDialog:{
		width:600,
		minHeight: 500,
	},
	dialogContent:{
		padding:0,
	}
}));

function CreateRoomDialog({open,toggleAction,users={},groups={},roomType="",handleCreateRoomAction,me={},startIndex=0,previewmode=""}) {
	const classes = useStyles();
  	return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
		onClose={toggleAction}
		className="create-room-container"
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
	   <CreateRoomContent
	   		open={open}
	   		toggleAction={toggleAction}
			users={users}
			groups={groups}
			roomType={roomType}
			startIndex={startIndex}
			previewmode={previewmode}
			handleCreateRoomAction={handleCreateRoomAction}
			me={me}
	   />
      </Dialog>
  );
}

export default CreateRoomDialog;
