export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const SIGN_UP_CODE = '/signup/code';
export const SIGN_UP_EDUCATOR = '/signup/educator';
export const SIGN_UP_EDUCATOR_NEW = '/signup/educator/new';
export const ROOT = '/';
export const WELCOME = '/welcome';

export const BRIEFCASE = '/briefcase';
export const PORTFOLIO = '/portfolio';

export const CHATS = '/chats';
export const NEWSLETTER = '/newsletter';
export const NEWSLETTER_CREATE = '/newsletter/edit';

export const EVENT = '/event';
export const MANAGE_EVENT = '/event/manage';
export const EDIT_MANAGE_EVENT = '/event/manage/:event_id';
export const MANAGE_RSVP_EVENT = '/event/rsvpmanage';
export const EDIT_MANAGE_RSVP_EVENT = '/event/rsvpmanage/:event_id';
export const EDIT_RSVP_EVENT = '/event/editrsvp';
export const MEETING_EVENT = '/event/meeting';
export const EDIT_MEETING_EVENT = '/event/meeting/:event_id';
export const MEETING_EVENT_SIGNUP = '/event/editmeeting';
export const EDIT_MEETING_EVENT_SIGNUP = '/event/editmeeting/:event_id';
export const VOLUNTEER_EVENT_SIGNUP = '/event/editvolunteer';
export const VOLUNTEER_EVENT = '/event/volunteer';

// export const VOLUNTEER = '/volunteer';
// export const VOLUNTEER_ADD = '/volunteer/add';
// export const RSVP_ADD = '/volunteer/add_rsvp';

/**admin routes start */
export const ADMIN = '/admin';
export const DASHBOARD = '/admin/dashboard';
export const USER = '/admin/student';
export const USERS = '/admin/students';
export const USERS_ADD = '/admin/student/add';
export const USERS_EDIT = '/admin/student/edit';

export const PARENTS = '/admin/parent';
export const PARENTS_ADD = '/admin/parent/add';
export const PARENTS_EDIT = '/admin/parent/edit';

export const STAFF = '/admin/staff';
export const STAFF_ADD = '/admin/staff/add';
export const STAFF_EDIT = '/admin/staff/edit';

export const GROUP = '/admin/group';
export const GROUPBULK = '/admin/group/bulk/:groupname';
export const GROUPINFO = '/admin/group/:groupname';
export const StudentSummary = '/admin/student/summary';
export const StaffSummary = '/admin/staff/summary';
export const Photos = '/photos';
export const ProgressReportAdmin = '/reportadmin/';
export const ProgressReport = '/report/';
/** admin routes end */

/** new admin routes */
export const MAIN_DASHBOARD = '/admin/dashboard1';

export const STUDENT_DASHBOARD = '/admin/student/dashboard';
export const STUDENT_EDIT = '/admin/student/edit/:id';
export const STUDENT_VIEW = '/admin/student/view/:id';
export const ATTENDANCE_VIEW = '/admin/student/attendance/:id';
export const ADD_STUDENT = '/admin/student/add-student';

export const STAFF_DASHBOARD = '/admin/staff/dashboard';
export const STAFFS_EDIT = '/admin/staff/edit/:id';
export const STAFF_VIEW = '/admin/staff/view/:id';
export const STAFF_ATTENDANCE_VIEW = '/admin/staff/attendance/:id';
export const ADD_STAFF = '/admin/student/add-staff';

export const PARENT_VIEW = '/admin/parent/view/:id';

export const GROUPS_DASHBOARD = '/admin/groups/dashboard';
export const GROUPS_EDIT = '/admin/groups/edit/:id';
export const GROUPS_VIEW = '/admin/groups/view/:id';

/** Teacher routes */

export const CLASSES_DASHBOARD = '/classes/dashboard';
export const CLASSES_VIEW = '/classes/view/:id';
export const CLASSES_STUDENT_VIEW = '/student/view/:id';
export const CLASSES_STAFF_VIEW = '/staff/view/:id';
export const CLASSES_PARENT_VIEW = '/parent/view/:id';
export const STAFF_ATTENDANCE_VIEW_FOR_TEACHER = '/staff/attendance/:id';
export const STUDENT_ATTENDANCE_VIEW_FOR_TEACHER = '/student/attendance/:id';
export const TEACHER_PRE_CHECKIN_FORM = '/pre_checkin_form';
// export const RECORD_LESSSONS = '/lessons/record';
// export const PLAN_LESSSONS = '/lessons/plan';
export const LESSON = '/lessons/lesson'



export const TEACHER_PROFILE = '/profile';
export const PARENT_PROFILE = '/profile';
export const PRE_CHECKIN_FORM = '/pre_checkin_form';
export const PARENT_STUDENT_VIEW = '/student/view/:id';
export const STUDENT_ATTENDANCE_VIEW_FOR_PARENT = '/student/attendance/:id';

/** Progress Report Route **/
export const HOME_ROUTE = '/';
export const ADD_MODULE_ROUTE = '/modules/add-module';
export const EDIT_MODULE_ROUTE = '/modules/edit-module/:moduleId';
export const GROUP_DETAILS = '/groups/details/:moduleId/:childId/:studentName';
export const NO_PARAMS_GROUP_DETAILS = '/groups/details';
export const GROUPS_LIST = '/groups/list/:moduleId';
export const NEWS_LETTER = '/newsletter';
export const GROUPS_STUDENTS = '/groups/students/:moduleId/:groupName';
export const LOGIN = '/auth/Login';
export const VIEW_STUDENTS = '/reports/students';

export const PLAN = '/plan';
