import React, { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import {fade, withStyles,makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import InputBase from '@material-ui/core/InputBase';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForward from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import ArrowBack from '@material-ui/icons/ArrowBack';
import UserListSelector from "./UserListSelector"
import GroupListSelector from "./GroupListSelector"
import UserListView from "./UserListView"
import UploadImage from "./UploadImage"
import {getRoomType} from "./../ChatSidebar/Header"
import { ObjectArraytoIndexArray,transformUserArrayToObject } from "../../../../utils/utils";
import "./index.css"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = (theme) => ({
	root: {
	  margin: "2px 0px",
	  padding: "4px 12px",
	  display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
	},
	closeButton: {
	//   position: 'absolute',
	//   right: theme.spacing(1),
	//   top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
		  backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		margin: "0px 0px 4px",
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		width: '100%',
		margin: "0px 0px 4px",
		},
	  },
	  searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	  },
	  inputRoot: {
		color: 'inherit',
		width: "100%",
	  },
	  inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		height: 28,
    	border: "1px solid #ccc",
    	borderRadius:20,
    	width: "100%",
		[theme.breakpoints.up('md')]: {
			width: "100%",
		},
	  },
});
const DialogTitle = withStyles(styles)((props) => {
	const { children, classes,previewmode, onClose,selectedUsers,groupStepper,roomType,groupStepperAction,handleCreateRoomAction, ...other } = props;
	const handleClose=()=>{
		if(!(roomType===getRoomType.group&&groupStepper===1)){
			onClose();
		} else if(!(roomType===getRoomType.group&&groupStepper===1 &&previewmode!=="addPeople")){
			onClose();
		} else{
			groupStepperAction()
		}
	}
	return (
	  <MuiDialogTitle disableTypography className={classes.root} {...other}>
		{(onClose) ? (
		  <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
			{!(roomType===getRoomType.group&&groupStepper===1 &&previewmode!=="addPeople")?<CloseIcon />:<ArrowBack/>}
		  </IconButton>
		) : null}
		<Typography variant="h5" style={{fontWeight:"bold"}}>{children}</Typography>
		{((roomType===getRoomType.group&&groupStepper===1)||(roomType===getRoomType.private))&&(
			<Button variant="contained" disabled={!selectedUsers.length} color="primary" onClick={handleCreateRoomAction} style={{borderRadius:20}}>Done</Button>
		)}
		{(roomType===getRoomType.group&&groupStepper===0)&&<span style={{width:60}}></span>}
	  </MuiDialogTitle>
	);
});

const SearchBarField = withStyles(styles)((props) => {
	const { children, classes, onClose,searchtextAction,searchText="", ...other } = props;
	return (
		<div className={classes.search}>
		<div className={classes.searchIcon}>
		  <SearchIcon />
		</div>
		<InputBase
			placeholder="Search…"
			value={searchText}
			onChange={(e)=>{searchtextAction(e.target.value)}}
		  	classes={{
			  root: classes.inputRoot,
			  input: classes.inputInput,
			}}
			inputProps={{ 'aria-label': 'search' }}
		/>
	  </div>
	);
});
const useStyles = makeStyles((theme) => ({
	containerRoomDialog:{
		width:600,
		minHeight: 500,
	},
	dialogContent:{
		padding:0,
		// overflow:"hidden"
	}
  }));

function CreateRoomContent({open,toggleAction,users={},groups={},roomType="",handleCreateRoomAction,me={},startIndex=0,previewmode="",oldgroupName,groupPhoto=""}) {
	const classes = useStyles();
	const [selectedUsers,setSelectedUsers]=useState([])
	const [selectedGroup,setSelectedGroup]=useState({})
	const [unselectedUser,setUnSelectedUser]=useState({})
	const [searchText,setSearchText]=useState("")
	const [groupStepper,setGroupStepper]=useState(0)
	const [groupName,setGroupName]=useState("")
	const [groupImageUrlObj,setGroupImageUrlObj]=useState({})

	useEffect(()=>{
		setGroupStepper(startIndex);
		setGroupName(oldgroupName);
		setGroupImageUrlObj({dataUrl:groupPhoto})
	},[])
	const handleClosedDialog=()=>{
		toggleAction();
		setSelectedUsers([]);
		setSelectedGroup({});
		setUnSelectedUser({});
		setGroupStepper(0);
		setGroupName("");
		setSearchText("")
	}
	const renderSelectMemberView=()=>{
		return(
			<div>
				<div className="fixed-content">
				<SearchBarField
					searchText={searchText}
					searchtextAction={(value)=>{setSearchText(value)}}
				/>
				<UserListView 
					selectedUsers={selectedUsers} 
					selectedGroup={selectedGroup} 
					unSelectUserAction={(user)=>{setUnSelectedUser(user)}}
				/>
				<GroupListSelector 
					groups={groups} 
					selectedGroupAction={(group)=>setSelectedGroup(group)}
					selectedGroup={selectedGroup}
				/>
			</div>

			<UserListSelector 
				users={users} 
				selectedUsersAction={(users)=>{setSelectedUsers(users)}} 
				selectedGroup={selectedGroup}
				unselectedUser={unselectedUser}
				searchText={searchText}
				roomType={roomType}
				me={me}
			/>
			</div>
		)
	} 
	const renderAddGroupDetailView=()=>{
		return(
			<div style={{textAlign:"center",justifyContent:"center"}}>	
				<UploadImage imageUrlAction={(imgUrlObj)=>{setGroupImageUrlObj(imgUrlObj)}}/>
				<div className="group-name-field" style={{textAlign:"center"}}>
					<TextField 
						id="standard-basic" 
						label=""
						value={groupName}
						onChange={(e)=>{setGroupName(e.target.value)}}
						placeholder={"Group name"}
						style={{fontSize:20,width:300,minWidth:150,padding: 10,}}
					/>
				</div>
				<Fab color="primary" disabled={groupName===""||groupImageUrlObj.loading} style={{position:"absolute",bottom:10,right:10}} aria-label="add" onClick={()=>{setGroupStepper(groupStepper+1)}}>
        			<ArrowForward />
     			</Fab>
			</div>
		)
	}
	const renderCreateGroupRoom=()=>{
		switch (groupStepper) {
			case 0:
				return renderAddGroupDetailView();
			case 1:
				return renderSelectMemberView()
			default:
				return null;
		}
	}

  	return (
     <>
	   <DialogTitle 
		   id="customized-dialog-title" 
		   onClose={handleClosedDialog}
		   roomType={roomType}
		   previewmode={previewmode}
		   groupStepper={groupStepper}
		   groupStepperAction={()=>{setGroupStepper(groupStepper-1)}}
		   handleCreateRoomAction={()=>{
			const userObj= transformUserArrayToObject(selectedUsers);
			handleCreateRoomAction(userObj,groupName,groupImageUrlObj.dataUrl,previewmode);
			handleClosedDialog();
		   }}
		   selectedUsers={selectedUsers}
		>
          {roomType===getRoomType.group?"Group Chat":"Private Chat"}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
			{roomType===getRoomType.group?renderCreateGroupRoom():renderSelectMemberView()}
        </DialogContent>
    </>
  );
}

export default CreateRoomContent;
