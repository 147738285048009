import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableHead, TextField, Typography, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailIcon from '@material-ui/icons/Email';
import EditIcon from '@material-ui/icons/Edit';
import TablePaginationActionsWrapped from '../../common/TablePaginationActions ';
import PDFIcon from "@material-ui/icons/PictureAsPdf";


const CustomTableCell = withStyles(theme => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1) * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  margin: {
    margin: theme.spacing(1) * 0.5,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));


function ShowStatus(props) {
  const { status: { will_attend = "", children, adults } } = props
  return (
    <>
      {will_attend === 'Yes' ?
        <span> {will_attend} <br /> {children} children {adults} adult </span>
        :
        <span>{will_attend}</span>}
    </>
  )
}

function EnhancedTable(props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState(props.rowsRsvp || []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, (rows || []).length - page * rowsPerPage);
  const option = props.selected === "Yes" || props.selected === "Maybe" || props.selected === "No" || props.selected === "No Reply"

  useEffect(() => {
    setRows(props.rowsRsvp)
  }, [props.rowsRsvp])

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  function handleFindGuest(event) {
    props.handleRsvpSearchAction(event.target.value)
  }

  let adult = 0
  let children = 0
  props.rowsRsvp.map(r => {
    adult += r.adults
    children += r.children
  })

  return (
    <>

      <Typography variant="subtitle1">
        {rows.length} record found
      </Typography>

      { option ?
          <Typography variant="subtitle1" style={{textAlign: "center"}}>
            { props.selected === "Yes" ?
              <div>
                <div style={{fontWeight: 'bold'}}>Total {props.selected}</div>
                <div>Adult = {adult}</div>
                <div>Children = {children}</div>
              </div> : <div style={{fontWeight: 'bold'}}>Total {props.selected} : {rows.length}</div> }
          </Typography> : null
      }

      { props.selected === "Yes" ?
          <Typography variant="subtitle1" style={{textAlign: "right"}}>
             <PDFIcon style={{ fontSize: "xx-large", color: "blue" }} onClick={props.handlePreview}/> </Typography> : null
      }

      <TextField
        id="findGuest"
        className={classes.textField}
        placeholder="Find Guest"
        margin="normal"
        variant="outlined"
        onChange={handleFindGuest}
      />


      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell align="left">Name</CustomTableCell>
                <CustomTableCell align="left">Email/Phone</CustomTableCell>
                <CustomTableCell align="left">Status</CustomTableCell>
                <CustomTableCell align="center">Actions</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                <TableRow key={row.id}>
                  <CustomTableCell align="left" >
                    {row.name} <br /> {row.childName}
                  </CustomTableCell>
                  <CustomTableCell align="left" >
                    {row.email || ""}
                  </CustomTableCell>
                  <CustomTableCell align="left" >
                    <ShowStatus status={row} />
                  </CustomTableCell>
                  <CustomTableCell align="center" >
                    <>
                      {/* <IconButton aria-label="Email" className={classes.margin}>
                      <EmailIcon fontSize="small" />
                    </IconButton> */}
                      <IconButton aria-label="Edit" className={classes.margin} onClick={props.handleEditRecord(row)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton aria-label="Delete" className={classes.margin}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </>
                  </CustomTableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    </>
  );
}

export default EnhancedTable;