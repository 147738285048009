import { Tabs } from 'antd';
import React, { Component, useState, useEffect, useRef } from 'react';
import Record from './Record'
import Plan from './Plan'
import initialData from "./components/sample-data";
const { TabPane } = Tabs;

function callback(key) {
    console.log(key);
}

const Lesson = () => {


    const [activekey, setactivekey] = useState('planTab')
    const [tabKey, setTabKey] = useState('1')
    const recordTabRef = useRef(null)
    const changeTab = (val)=>{
        console.log('Tab Changes ...', val)
        setactivekey(val)
        setTabKey(Date.now() + '')
        console.log(tabKey)
    }

    return(
    <Tabs activeKey={activekey} tabPosition={'top'} onChange={changeTab} key={tabKey}>

        <TabPane tab="Plan" key="planTab"  style={{width : '100%', height : window.innerHeight}}>
            <Plan />
        </TabPane>
        <TabPane tab="Record" key="recordTab" style={{width : '100%', height : window.innerHeight}}>
            <Record />
        </TabPane>
    </Tabs>
);
}

export default Lesson;