import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import CheckboxList from "./CheckboxList";

function CheckBoxDialog(props) {
  const {
    isOpen,
    title,
    list,
    passRefUpward,
    handleCancel,
    handleOk
  } = props;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={isOpen}
      aria-labelledby="form-dialog-title2"
    >
      <DialogTitle id="confirmation-dialog-title2">{title}</DialogTitle>
      <DialogContent>
        <CheckboxList list={list} passRefUpward={passRefUpward} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CheckBoxDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  passRefUpward: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired
};

export default CheckBoxDialog;
