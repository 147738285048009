import {
    combineReducers
} from 'redux';
import {
    reducer as formReducer
} from 'redux-form';
import currentUser from './current-user';
import child from './child';
import parent from './parent';
import staff from './staff';
import user from './user';
import attendance from './attendance';
import cache from './cache';
import feed from './feed-post';
import postManage from './post-manage';
import briefcase from './breifcase';
import event from './event'
import component from './component'
import filterpost from './filter-post'
import newsletter from "./newsletter";
import photos from './photos';
import reportadmin from './reportadmin'
import chatrooms from './chat'
import observation from './observation';

const appReducer = combineReducers({
    form: formReducer,
    currentUser,
    child,
    parent,
    staff,
    user,
    attendance,
    cache,
    feed,
    postManage,
    briefcase,
    event,
    component,
    filterpost,
    newsletter,
    photos,
    reportadmin,
    chatrooms,
    observation
});

const rootReducer = (state, action) => {
   
    if (action.type === 'LOGOUT_USER') {
       
      state = undefined
    }
   
    return appReducer(state, action)
  }

export default rootReducer;