import React,{Fragment, useEffect,useState} from "react";
import firebase from 'firebase'
import ChatSidebar from "./components/ChatSidebar/ChatSidebar";
import ChatWindow from "./components/ChatWindow/ChatWindow";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Hub } from 'aws-amplify';
import FirebaseApiInstance from '../../service/chat';
import { getSchoolId } from './../SchoolProfile/components/GlobalComponents/GlobalFields';
import _ from 'lodash';
import Loader from "../../assets/jss/components/Loader";
import { ObjectArraytoIndexArray,updateUseridInUsername,updateUseridInUserObj } from "../../utils/utils";
import { getRoomType } from "./components/ChatSidebar/Header";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#ffffff",
    },
  }));

function Chat(props){
    const classes = useStyles();
    const {currentUser:{newUserInfo:{usercache,groupcache,user}},chatrooms}=props;
    const {isloadingMessages,rooms,isLoading,notifications}=chatrooms;
    const [roomdetail,setRoomdetail]=useState({})
    const [roomKey,setRoomKey]=useState({});
    let myDetail = {
      id : user.id,
      userid: user.id,
      username : user.id,
      display_name: `${user.attributes.firstName} ${user.attributes.lastName}`,
      profile_photo: user.attributes.profile_photo,
      firstName:user.attributes.firstName,
      lastName:user.attributes.lastName,
  }
    useEffect(()=>{
      Hub.listen('StartPrivateChat', (data) => {
        console.log(data,"StartPrivateChat")
        onStartPrivateChatfromOtherComponents(data);
        console.log('A new Chat event was raised ! ');
    })
    Hub.listen('StartGroupChat', (data) => {
        console.log(data,"StartGroupChat")
        onStartGroupChatfromOtherComponents(data.payload)
        console.log('A new Chat event was raised ! ');
    })
    props.startRoomListenerAction(getSchoolId(),user);
    getFirebaseTokenPopup()
    },[]);
    useEffect(()=>{
      if(Object.keys(roomdetail).length){
        setTimeout(()=>{
          FirebaseApiInstance.clearChatReadNotifications(getSchoolId(),myDetail,roomdetail.key)
        },2000)
      }
    });

    const getFirebaseTokenPopup= async()=>{
      const messaging = firebase.messaging();
      messaging.getToken().then(async (currentToken) => {
          if (currentToken) {
            let response= await FirebaseApiInstance.registerDeviceforNotification(currentToken);
            console.log(currentToken,response,"currentToken");
          } else {
            // Show permission request.
            console.log("currentToken");
            // Show permission UI.
         
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err,"currentToken");
        });
  }
    const onStartPrivateChatfromOtherComponents=(data)=>{
      let selectedUser = data.payload.user
      console.log('Got Request to start chat with ', selectedUser)
      onstartChat(selectedUser)
  }
  const onStartGroupChatfromOtherComponents = ({groupName, imageUrl, selectedUsers})=>{
    console.log(groupName, imageUrl, users)
     var users = ObjectArraytoIndexArray(selectedUsers);
     let roomkey = FirebaseApiInstance.startGroupChat(getSchoolId(), users, myDetail, groupName, imageUrl);
     console.log('got group roomkey' + roomkey)
     console.log(selectedUsers)
 }

 const onstartChat = async (selectedUsers={},groupName="",groupImageUrl="") => {
  console.log('Selected ....')
  console.log(Object.keys(selectedUsers))
  if (Object.keys(selectedUsers).length === 0) return
  var users = ObjectArraytoIndexArray(selectedUsers);
  let userid = Object.keys(selectedUsers)
  console.log('Single Person Chat',myDetail)

  if (Object.keys(selectedUsers).length === 1){
      let roomkey = await FirebaseApiInstance.startPrivateChat(getSchoolId(), users[0], myDetail)
      console.log(roomkey)
      console.log(selectedUsers)
      setRoomKey(rooms[roomkey]);
      let roomName = selectedUsers[userid].display_name === undefined ? selectedUsers[userid].firstName + ' ' + selectedUsers[userid].lastName : selectedUsers[userid].display_name;
  }else {
      console.log('Multiple Person Chat')
      let roomName = groupName;
      let roomPhoto = groupImageUrl;
      let roomkey = await FirebaseApiInstance.startGroupChat(getSchoolId(), users, myDetail, roomName, roomPhoto);
      console.log(roomkey)
      setRoomKey(rooms[roomkey]);
  }
}
    const handleCreateRoom=(userObj,groupName,groupImageUrl,previewmode,roomkey,oldUsers)=>{
      const newUsers = updateUseridInUserObj(userObj);
      if(previewmode===getRoomType.addPeople){
        const tempoldUsers = updateUseridInUsername(oldUsers,usercache);
        // console.log(getSchoolId(),roomkey,myDetail,groupName,groupImageUrl,newUsers,tempoldUsers,"updateGroupChatDetails")
        // props.updateGroupChatAction(getSchoolId(),roomkey,myDetail,groupName,groupImageUrl,newUsers,tempoldUsers);
        FirebaseApiInstance.updateGroupChatDetails(getSchoolId(),roomkey,myDetail,groupName,groupImageUrl,newUsers,tempoldUsers);
      } else{
        onstartChat(newUsers,groupName,groupImageUrl);
      }
    }

    const handleSendMessage=(roomkey, message, users, sendNotification=false,attachFiles=[],isVideo=false)=>{
      // console.log(roomkey, message, users, sendNotification,attachFiles.length,isVideo,"handleSendAttachFiles");
      if(attachFiles.length===0){
        props.sendChatMessageAction(getSchoolId(), roomkey, message, users, myDetail, sendNotification);
      } else{
        props.finishPickPhotoviaAWS(getSchoolId(), roomkey, users, myDetail, attachFiles, isVideo);
      }
    }

    return(
      (isloadingMessages||isLoading)?<Loader/>:(
        <Fragment>
        <Grid container className={classes.root}>
        <ChatSidebar 
          users={usercache}
          groups={groupcache}
          handleCreateRoomAction={handleCreateRoom}
          chatrooms={chatrooms}
          me={user}
          selectChatroomAction={(roomdetail)=>{
            setRoomdetail(roomdetail);
            setTimeout(()=>{
              FirebaseApiInstance.clearChatReadNotifications(getSchoolId(),myDetail,roomdetail.key)
            },2000)
          }}
          deleteChatAction={(chatroom)=>{
            const {key:roomkey,details:{users}}=chatroom; 
            props.deleteChat(getSchoolId(),roomkey,myDetail,users);
          }}
          roomInfoAction={()=>{}}
          leaveChatAction={(school_id, roomkey, me, isGroupChat,users)=>{
            props.leaveChat(school_id, roomkey, me, isGroupChat,users);
          }}
          goToChatroom={roomKey}
        />
        <ChatWindow
          chatroomDetail={rooms[roomdetail.key]}
          me={user}
          sendMessageAction={handleSendMessage}
          users={usercache}
          groups={groupcache}
          handleCreateRoomAction={handleCreateRoom}
          chatrooms={chatrooms}
          selectChatroomAction={(roomdetail)=>{
            setRoomdetail(roomdetail);
          }}
          leaveChatAction={(school_id, roomkey, me, isGroupChat,users)=>{
            props.leaveChat(school_id, roomkey, me, isGroupChat,users);
          }}
          deleteChatAction={(school_id, roomkey, me, users)=>{
            props.deleteChat(school_id, roomkey, me, users);
          }}
        />
        </Grid>
        </Fragment>
      )
    )
}

export default Chat;