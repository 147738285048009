import React, {Component, PureComponent} from 'react';
import {render} from 'react-dom';
import {AgGridReact, AgGridColumn} from '@ag-grid-community/react';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import './recordlessonstyle.css'
import {makeStyles} from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    DialogContentText,
    Grid,
    Typography,
    Button,
    Divider
} from '@material-ui/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import recordPlannerService from '../../service/recordplanner'
import {LicenseManager} from "ag-grid-enterprise";
import {Select, Modal, Spin, Tooltip, Input} from "antd";
import {connect} from "react-redux";
import skillCellRenderer from './components/skillCellRenderer'
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';
import * as routes from "../../routes/path"
import moment from "moment";
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';


import Scale from './Scale'
//import columnHeaderRenderer from './components/columnHeaderRenderer'
const {TextArea} = Input;
const {Option} = Select


LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-12_September_2021_[v2]_MTYzMTQwMTIwMDAwMA==5df1ca0151ef6b4099560ddecdafba8d")


class RecordLesson extends Component {

    constructor(props) {
        super(props);
        console.log('props', props)
        var groupList = _.values(_.filter(props.groupcache, function (o) {
            return !o.attributes.is_archive && o.attributes.is_recordplan
        }))
        groupList = _.orderBy(groupList, function (o) {
            return o.attributes.groupname
        }, ['asc'])
        let grpid = parseInt(localStorage.getItem('p_gnum'));
        this.state = {
            gridApi: null,
            gridColumnApi: null,
            selectedGroup: grpid,
            childrenList: {},
            gridData: [],
            gridOptions: {},
            Templates: {},
            selectedTemplate: {},
            studentMarkings: [],
            newskillCatName: '',
            historyVisible: false,
            rightclickParam: null,
            showNamePrompt: false,
            showDeletePrompt: false,
            createRootLevelNode: false,
            templateneedsRefresh: false,
            loadingHistory: false,
            skillsHash: {},
            auditData: [],
            modalTitle: '',
            isFullAudit: true,
            commentText: '',
            selectedStudent: -1,
            groupList: groupList,
            coldefs : [],
            loadingData : false

        }
        this.gridOptions = {}
        this.scaleHash = _.keyBy(Scale, 'rating')
    }

    onGridReady = (params) => {
        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi
        })
        console.log('On Grid Ready !')
    };


    componentDidMount() {
        let grpid = this.state.selectedGroup
        let grplist = Object.keys(_.keyBy(this.state.groupList, function(o){
            return parseInt(o.id)
        }))
        console.log('before grpid', grpid)
        console.log('grplist', grplist, (!grplist.includes(grpid.toString())))

        if (grpid === null || (!grplist.includes(grpid.toString())) ) {
            grpid = this.state.groupList[Object.keys(this.state.groupList)[0]]?.id
        }
        console.log('grpid', grpid)
        this.changeClass(grpid)
    }


    onCellValueChanged = (event) => {

        if (event.colDef.field != undefined) {
            this.saveSkillData(event.data.id, event.colDef.field, event.data)
        }

    };

    canEdit = (params) => {
        return params.node.childrenAfterFilter.length === 0;
    };

    onFilterTextBoxChanged = () => {
        this.state.gridApi.setQuickFilter(document.getElementById('filter-text-box').value);
    };



    deleteSkillCat = () => {

        let removenode = {}
        let skill = []
        if (this.state.rightclickParam.node.data !== undefined) { //Is not Group
            removenode = this.state.rightclickParam.node.data
            //rowToAdd.skill.pop()
        } else {
            let node = this.state.rightclickParam.node
            skill.push(node.key)
            while (node.parent.key !== null) {
                skill.push(node.parent.key)
                node = node.parent
            }

            skill = skill.reverse()
            removenode = {skill}
        }
        //console.log('removenode', removenode)
        let status = this.gridOptions.api.applyTransaction({
            remove: [removenode]
        })
        //params.api.refreshClientSideRowModel('filter')
        this.setState({
            rightclickParam: null,
            templateneedsRefresh: true,
            showDeletePrompt: false
        })


        //console.log(status)
    }

    AddNodetoTemplate = () => {
        let rowToAdd = {};
        var guid = uuidv4()
        //console.log('Skill Name', newskillCatName)

        if (this.state.rightclickParam.node.data !== undefined) { //Is not Group
            //console.log('in heree')
            //console.log('params.node.data', rightclickParam.node.data)
            rowToAdd = JSON.parse(JSON.stringify(this.state.rightclickParam.node.data))
            //rowToAdd.skill.pop()
        } else {
            if (this.state.rightclickParam.node.parent.level == -1 && this.state.createRootLevelNode) {
                //console.log('Roooooot !!!!')
                rowToAdd = {skill: [], id: guid}

            } else {
                let node = this.state.rightclickParam.node
                rowToAdd = {skill: [], id: guid}
                rowToAdd.skill.push(node.key)
                while (node.parent.key !== null) {
                    rowToAdd.skill.push(node.parent.key)
                    node = node.parent
                }

                rowToAdd.skill = rowToAdd.skill.reverse()
            }
        }

        rowToAdd.skill.push(this.state.newskillCatName)
        rowToAdd['id'] = guid

        //console.log('rowToAdd', rowToAdd)


        let status = this.state.rightclickParam.api.applyTransaction({
            add: [{skill: rowToAdd.skill, id: guid}]
        })
        this.setState({
            templateneedsRefresh: true
        })

        //console.log(status)

        this.state.rightclickParam.api.refreshClientSideRowModel('filter')


        this.setState({
            newskillCatName: '',
            createRootLevelNode: false,
            rightclickParam: null,
            showNamePrompt: false
        })

    }

    handleDeleteAction(params) {
        this.setState({
            rightclickParam: params,
            showDeletePrompt: true
        })

    }

    handleRightClickAction(params) {
        this.setState({
            rightclickParam: params,
            showNamePrompt: true
        })


    }


    updatedByRenderer(params) {

        let nameObj = params.usercache[params.value]
        let name = nameObj === undefined ? '- UNKNOWN - ' :
            nameObj.firstName + ' ' + nameObj.lastName
        return (<div>{name}</div>)
    }

    dateTimeRender(param) {
        let dt = param.value
        let dtf = moment.utc(dt).local().format('llll')
        return dtf
    }

    scheduleRender(param) {
        let schedule = param.value
        //console.log('schedule', schedule)
        if (schedule == null) return ""
        let dates = schedule
        if (dates == undefined) return ""
        //console.log('dates', dates)
        return dates.join(',')

    }

    ratingRender(params) {
        console.log('params', params)
        let cellValue = params.value
        let bgColor = params.scaleHash[cellValue]?.color === undefined ? 'white' : params.scaleHash[cellValue].color
        if (params.value == 'F') {
            return (
                <Tooltip title={params.scaleHash['F'].name}>
                    <div style={{
                        backgroundColor: bgColor, color: 'blue',
                        border: 0, alignSelf: 'center', marginLeft: '16px',
                        width: '40px', height: '40px'
                    }}>
                        <EmojiObjectsIcon style={{color: '#EAD673'}}/>
                    </div>
                </Tooltip>
            )
        }

        return (
            <Tooltip title={params.scaleHash[cellValue] == undefined ? 'UNKNOWN' : params.scaleHash[cellValue]?.name}>
                <div style={{
                    backgroundColor: bgColor, color: 'white',
                    width: '40px', height: '40px', textAlign: 'center'
                }}>
                    {cellValue == "" ? "-" : cellValue}
                </div>
            </Tooltip>
        )
    }

    skillRender(params) {
        let skillname = params.skillsHash[params.value] === undefined ? 'UNKNOWN' : params.skillsHash[params.value]
        return (
            <div>
                {skillname
                }
            </div>
        )
    }

    saveComment() {
        let data = {}
        data[this.state.selectedStudent] = {
            'rating': '',
            'comment': this.state.commentText,
            'flag': 0
        }
        this.saveSkillData(-1, this.state.selectedStudent, data).then(() => {
            this.closeHistory()
        })

    }


    renderHistory() {
        //console.log('Templates[value]', selectedTemplate)

        if (this.state.loadingHistory) {
            return <Spin/>
        }
        if (this.state.auditData.length == 0) {
            return <div><p/><span>
                No History Found !</span></div>
        } else {
            //console.log('hash', skillsHash)
            let divHeight = this.state.isFullAudit ? window.document.body.clientHeight - 300 : 550
            let divWidth = this.state.isFullAudit ? window.innerWidth - 150 : window.innerWidth - 150
            let showCommentBox = () => {
                if (!this.state.isFullAudit) return null
                return (
                    <div style={{padding: 10}}>
                        <div style={{height: 80}}>
                            <TextArea rows={2} placeholder="Add Comment" onChange={(e) => {
                                this.setState({commentText: e.target.value})
                            }}/>
                            <Button style={{width: 150, height: 30, marginTop: 10, alignSelf: 'right'}}
                                    onClick={() => {
                                        this.saveComment()
                                    }}
                                    disabled={this.state.commentText.length === 0}
                                    variant="contained" color="primary" on>Save Comment</Button>
                        </div>

                    </div>
                )
            }
            return (
                <div>
                    {showCommentBox()}

                    <div style={{height: divHeight, width: divWidth}}>
                        {this.state.isFullAudit && <div style={{marginTop: '50px'}}>
                            <Divider style={{marginTop: '10px'}} component="li"/>
                            <li>
                                <Typography
                                    color="blue"

                                    display="block"
                                    variant="button"
                                >
                                    AUDIT HISTORY
                                </Typography>
                            </li>
                        </div>
                        }
                        <AgGridReact className="ag-theme-material"
                                     frameworkComponents={{
                                         'updatedByRenderer': this.updatedByRenderer,
                                         'ratingRender': this.ratingRender,
                                         'dateTimeRender': this.dateTimeRender,
                                         'skillRender': this.skillRender,
                                         'scheduleRender': this.scheduleRender
                                     }}
                                     overlayLoadingTemplate={
                                         '<span className="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                                     }
                                     colResizeDefault={true}
                                     rowData={this.state.auditData || []}
                        >
                            <AgGridColumn headerName={'Date/Time'}
                                          field={'AT_TS'}
                                          sortable={true}
                                          cellRenderer="dateTimeRender"
                                          filter={true}/>
                            {this.state.isFullAudit &&
                            <AgGridColumn headerName={'Lesson'}
                                          field={'skill_id'}
                                          sortable={true}
                                          cellRenderer="skillRender"
                                          cellRendererParams={{
                                              skillsHash: this.state.skillsHash
                                          }}
                                          filter={true}/>
                            }
                            <AgGridColumn headerName={'Rating'}
                                          field={'rating'}
                                          cellRenderer="ratingRender"
                                          cellRendererParams={{
                                              scaleHash: this.scaleHash
                                          }}
                                          width={120}
                                          sortable={false}
                                          filter={true}/>
                            <AgGridColumn headerName={'Comment'}
                                          field={'comment'}
                                          style={{lineHeight: '25px'}}
                                          sortable={true}
                                          wrapText={true}
                                          width={250}
                                          autoHeight={true}
                                          autoWidth={true}
                                          filter={true}/>
                            <AgGridColumn headerName={'Planned Date'}
                                          field={'schedule'}
                                          style={{lineHeight: '25px'}}
                                          sortable={true}
                                          wrapText={true}
                                          width={200}
                                          cellRenderer="scheduleRender"
                                          autoHeight={true}
                                          autoWidth={true}
                                          filter={true}/>
                            <AgGridColumn headerName={'By'}
                                          field={'updated_by'}
                                          sortable={true}
                                          cellRenderer="updatedByRenderer"
                                          cellRendererParams={{
                                              usercache: this.props.usercache
                                          }}
                                          filter={true}/>

                        </AgGridReact>
                    </div>
                </div>
            )
        }
    }

    async DebugButton() {
        //console.log(this.state.gridOptions)
        //console.log(this.state.gridOptions.api.rowModel.rowsToDisplay)
    }

    async saveSkillData(skillid, studentid, data) {
        //console.log(this.state.gridOptions)
        //console.log(this.state.gridOptions.api.rowModel.rowsToDisplay)
        //console.log('Templates[value]', selectedTemplate)
        var entry = {
            "school_id": this.props.school_id[0],
            "dashboard_id": this.state.selectedTemplate.id,
            "skill_id": skillid,
            "flag": data[studentid]?.flag ? 1 : 0,
            "child_id": studentid,
            "comment": data[studentid] === undefined ? '' : data[studentid]?.comment,
            "rating": data[studentid] === undefined ? '' : data[studentid]?.rating,
            "group_id": this.state.selectedGroup
        }
        console.log('entry=>>', entry)
        console.log('data=>>', data)
        const ret = await recordPlannerService.addDashboardGrade(entry)
        console.log(ret)
        if (ret.status === 'success') {
            // settemplateneedsRefresh(false)
        } else {
            alert('Error Saving Rating. Please try again !')
        }
    }

    async saveTemplate() {

        let template = {}
        let templateData = this.gridOptions.api.rowModel.rowsToDisplay
        let tempArray = []

        templateData.forEach((node) => {
            if (node.parent.key == null) {
                tempArray = []
            }
            //console.log('tempArray', tempArray, node.parent.key, tempArray.indexOf(node.parent.key))
            tempArray = tempArray.slice(0, tempArray.indexOf(node.parent.key) + 1)

            if (node.hasChildren()) {
                tempArray.push(node.key)
            } else {
                let foo = tempArray
                foo.push(node.key)
                //console.log('foo', foo)
                template = _.set(template, foo, node.data.id)
            }

        })
        //console.log('template', template)
        let classtemplate = this.state.selectedTemplate
        classtemplate['template'] = template
        classtemplate['school_id'] = this.props.school_id[0]
        classtemplate['groups'] = [this.state.selectedGroup]
        classtemplate['dashboard_id'] = classtemplate['id'] // needs to be fixed in backend
        //console.log('classtemplate', classtemplate)

        const data = await recordPlannerService.addDashboardTemplates(classtemplate)
        //console.log(data)
        if (data.status === 'success') {
            this.setState({templateneedsRefresh: false})
            this.onSelectTemplate(this.state.selectedTemplate.id)
        } else {
            alert('Error Saving Template. Please try again !')
        }
    }


    getContextMenuItems = (params) => {
        //console.log('params', params)
        //console.log('params.node.childrenAfterFilter', params.node.childrenAfterFilter)
        //console.log('params.api.rowModel.rowsToDisplay', params.api.rowModel.rowsToDisplay)
        if (params.column.colId !== 'ag-Grid-AutoColumn') return
        const result = [];
        if (params.node.parent.level == -1) {
            result.push({
                name: 'Add Root Category/Lesson',
                action: function () {
                    this.setState({createRootLevelNode: true})
                    this.handleRightClickAction(params);

                },
            })
        }
        result.push({
            name: 'Add Category/Lesson to ' + params.value,
            action: function () {
                this.setState({createRootLevelNode: false})
                this.handleRightClickAction(params);
            }

        })
        if (params.node.childrenAfterFilter.length == 0) {
            result.push({
                name: 'Delete ' + params.value,
                action: function () {
                    this.handleDeleteAction(params)

                },
            })
        }
        return result
    }

    gradeCellRender = (params) => {
        if (params.data === undefined) {
            return ""
        }
        return params.data[params.colDef.field]?.grade || ''

    }


    cellGradeStyle = params => {
        return {textAlign: 'center'};

    };

    formatGridData(data) {

        var skillArray = []
        var _griddata = []
        var indexArray = []

        function _flatten(_skillArray, obj, o_key) {
            //console.log('-->',_skillArray, obj, o_key)
            if (typeof obj !== "object") {
                //console.log('reached a num ...', obj, o_key)
                //console.log('obj', obj)
                indexArray.push(parseInt(obj))
                var _row = {}
                _skillArray.push(o_key)
                _row['skill'] = _skillArray
                _row['id'] = obj

                //console.log('row->', _row, _skillArray.join('-'))
                _griddata.push(_row)

            } else {
                //console.log('Adding to Skill Array ', o_key, _skillArray.join('-'))
                _skillArray.push(o_key)
            }

            if (typeof obj == "object") {

                if (Array.isArray(obj)) {
                    //console.log('starting recursion  for Array')
                    obj.forEach((obj) => {
                        Object.keys(obj).forEach((key) => {
                            return _flatten(_skillArray.slice(0), obj[key], key)
                        })
                    })
                } else {
                    //console.log('starting recursion  for Object')
                    Object.keys(obj).forEach((key) => {
                        return _flatten(_skillArray.slice(0), obj[key], key)
                    })
                }

            }
        }


        Object.keys(data).forEach((key) => {
            let ret = _flatten([], data[key], key)
            skillArray = []
        })
        console.log('_griddata', _griddata)


        //console.log('Refreshing Grid')
        this.setState({
            gridData: _griddata
        })
        //this.gridOptions.api.setRowData(_griddata)


    }


    async getTemplates(group_id) {
        const body = {
            "group_id": group_id,
            "school_id": this.props.school_id[0]
        }
        const data = await recordPlannerService.getDashboardTemplates(body)
        //console.log(data)
        return data['status'] === 'success' ? data['data'] : []
    }

    async getMarkings(templateid, group_id) {
        const body = {
            "dashboard_id": templateid,
            "school_id": this.props.school_id[0],
            "group_id" : group_id,
        }
        const data = await recordPlannerService.getDashboardMarkings(body)
        //console.log(data)
        return data['status'] === 'success' ? data['data'] : []
    }


    changeClass(value) {
        var _childrenList = this.props.childgroupcache[value]
        console.log('selected Class', value)
        this.setState({
            loadingData : true
        })
        let coldefs = []
        _childrenList && Object.keys(_childrenList).map((key, index) => {
            //console.log( _childrenList[key])
            if (_childrenList[key].status === "Active") {
                let childname = _childrenList[key].firstName + ' ' + _childrenList[key].lastName.charAt(0)
                console.log('childnane', childname)
                let coldef = {
                    field: key,
                    key: key,
                    headerName: childname,
                    maxWidth: 40,
                    suppressSizeToFit: false,
                    singleClickEdit: true,
                    suppressMenu: true,
                    headerComponent: 'columnHeaderRenderer',
                    headerComponentParams:
                        {
                            showFullAuditHistory: this.showFullAuditHistory
                        },

                    cellRenderer: 'skillCellRenderer',
                    cellRendererParams: {
                        showAuditHistory: (studentid, studentname, skillid, skillname) => {
                            //console.log('Called ....')
                            this.setState({
                                isFullAudit: false,
                                modalTitle: 'History - ' + skillname + ' lesson for ' + studentname
                            })
                            this.showAuditHistory(studentid, skillid)
                        }


                    }
                }
                coldefs.push(coldef)
            }

        })

        console.log('coldefs', coldefs)
        localStorage.setItem('p_gnum', value);
        this.setState({
            selectedGroup: value,
            childrenList: _childrenList,
                coldefs : coldefs
        })

        console.log('this.props.childgroupcache[value]', this.props.childgroupcache[value])

        this.getTemplates(value).then((Templates) => {
            let _templates = _.keyBy(Templates, 'id')
            _templates = _.orderBy(_templates, ['name'], ['asc'])
            this.setState({
                Templates: _templates,
                selectedTemplate: {},

            })
            //DISABLE NEXT LINE DUE TO BUG
            //this.(Object.keys(_templates)[0])
            //this.(Object.keys(_templates)[0])
            //console.log('_templates', _templates)
        }).catch(()=>{
            this.setState({
                loadingData : false
            })
        })
    }

    onSelectTemplate(value) {
        console.log('Template', this.state.Templates)
        console.log('value', value)
        if (value == undefined) {
            this.setState({
                selectedTemplate: {},
                gridData: [],
                loadingData : false
            })
            return
        }
        let _Templates = _.keyBy(this.state.Templates, 'id')
        if (Object.keys(_Templates).length > 0 && _Templates[value] !== undefined) {
            console.log('this.state.Templates[value]', _Templates[value])
            this.setState({
                loadingData : true,
                selectedTemplate: _Templates[value],

            })

            this.formatGridData(_Templates[value].template)
            this.getMarkings(value, this.state.selectedGroup).then((markings) => {
                console.log('markings', markings)
                let rowData = this.gridOptions.rowData
                let newRows = []
                let _skillhash = {"-1": "- Feedback -"}
                console.log(rowData)
                let studentMarkingsBySkill = _(markings).groupBy('skill_id').value();
                console.log('studentMarkingsBySkill', studentMarkingsBySkill)
                if (markings.length > 0) {
                    rowData.forEach((row) => {
                        _skillhash[row.id] = row.skill[row.skill.length - 1]
                        console.log(row)
                        let studentinSkill = _(studentMarkingsBySkill[row.id]).keyBy('child_id').mapValues(v => _.pick(v, ['comment', 'rating', 'flag'])).value()
                        console.log('studentinSkill', studentinSkill)
                        row = _.assign(row, studentinSkill)
                        newRows.push(row)
                    })
                    this.setState({
                        skillsHash: _skillhash,
                        studentMarkings: markings

                    })
                    console.log('_skillhash', _skillhash)
                    console.log('newRows', newRows)
                    this.gridOptions.api.setRowData(newRows)
                    this.gridOptions.api.refreshCells()

                }


            })
            //console.log('this.state.Templates[v', gridOptions)
            this.gridOptions.api.refreshHeader();
            this.setState({
                loadingData : false,

            })
        }

    }

    getRowStyle = params => {
        if (params.node.group) {
            return {background: 'lightgray'};
        }
    };

    columnHeaderRenderer = (params) => {
        // console.log('render ->' + params.displayName )
        return (
            <div className={'ag-header-cell-label'} style={{cursor: 'pointer', width: '40px', height: '150px'}}
                 onClick={() => params.showFullAuditHistory(params.column.colId, params.displayName, null)}>
                <span className={'ag-header-cell-text'}>{params.displayName}</span>
            </div>
        )
    }

    showAuditHistory = (studentid, skill_id = null) => {
        this.setState({
            historyVisible: true,
            loadingHistory: true,
            selectedStudent: studentid
        })

        //console.log('this.state.Templates[value]', this.state.selectedTemplate )
        this.getAuditHistory(studentid).then((responseData) => {

            if (skill_id !== null) {
                responseData = _.filter(responseData, function (o) {
                    return o.skill_id == skill_id;
                });
            } else {
                let skills = this.state.skillsHash
                responseData = _.filter(responseData, function (o) {
                    return o.skill_id in skills;
                });
            }
            responseData = _.reverse(responseData)

            this.setState({loadingHistory: false, auditData: responseData})

        }, this)


    }
    showFullAuditHistory = (studentid, studentname, skillid) => {
        //console.log('Called ....')
        this.setState({
            modalTitle: 'Observation for ' + studentname,
            isFullAudit: true,

        })
        this.showAuditHistory(studentid, skillid)

    }

    async getAuditHistory(child_id) {
        //console.log('this.state.Templates[value]', this.state.selectedTemplate)
        const body = {
            "dashboard_id": this.state.selectedTemplate.id,
            "school_id": this.props.school_id[0],
            "child_id": child_id
        }
        const data = await recordPlannerService.getAuditHistory(body)
        //console.log(data)
        return data['status'] === 'success' ? data['data'] : []
    }

    closeHistory() {
        this.setState({
            historyVisible: false,
            selectedStudent: -1
        })

    }

    refreshData(){
        console.log('refreshing Data !!!')
    }

    render() {
        console.log('inrender')
        console.log('this.state.gridData ', this.state.gridData)
        console.log('this.props.recordTabActive', this.props.tabActive)
        let grp_id = this.state.selectedGroup === null ? this.state.groupList[0]?.id :
            parseInt(this.state.selectedGroup)

        console.log('selecting group ', grp_id, this.state.selectedGroup)
        return (

            <div style={{
                height: '75%',
                position: 'absolute',
                width: '98%',
                overflow: 'hidden',
                paddingLeft: '20px',
                paddingTop: '10px'
            }}>
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className="ag-theme-alpine"
                >

                    <div style={{display: "flex", marginLeft: 10}}>
                        <div style={{marginTop: 10, marginRight: 10}}>Class</div>
                        <div><Select
                            placeholder={'Select Class'}
                            style={{width: 225}}
                            className={'mr-4'}
                            defaultValue={grp_id}
                            onChange={(value) => this.changeClass(value)}
                        >
                            {
                                this.state.groupList && this.state.groupList.map((groupItem) => {
                                    const name = (groupItem && groupItem.attributes && groupItem.attributes.groupname) || ""
                                    return (
                                        <Option key={groupItem.id} value={groupItem.id}>{name}</Option>
                                    )

                                })
                            }
                        </Select></div>
                        <div style={{marginTop: 10, marginRight: 10}}>Topic</div>
                        <div><Select
                            placeholder={'Select Template'}
                            style={{width: 225}}
                            className={'mr-4'}
                            defaultValue={this.state.Templates[0]?.id === undefined ? null : this.state.Templates[0]?.id}
                            value={this.state.selectedTemplate?.id}
                            onChange={(value) => this.onSelectTemplate(value)}
                        >
                            {
                                Object.keys(this.state.Templates).length > 0 && this.state.Templates.map(template => {
                                    {
                                        return (
                                            <Option key={template.id} value={template.id}>{template.name}</Option>
                                        )
                                    }
                                })
                            }
                        </Select></div>
                        {this.state.templateneedsRefresh &&
                        <div style={{justifyContent: 'flex-end'}}>
                            <Button style={{width: 150, height: 30}}
                                    onClick={this.saveTemplate}
                                    variant="contained" color="secondary">
                                Save Template
                            </Button>
                        </div>
                        }
                        {/*<div style={{justifyContent: 'flex-end'}}>*/}
                        {/*    <Button style={{width: 150, height: 30}}*/}
                        {/*            onClick={DebugButton}*/}
                        {/*            variant="contained" color="secondary">*/}
                        {/*        Save Data*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>
                    {/*<Spin tip="Loading..." spinning={this.state.loadingData} style={{marginTop : 100}}>*/}
                    {/*</Spin>*/}
                    <span style={{display : this.state.selectedTemplate?.id !== undefined ? 'inline' : 'none' }}>
                    <AgGridReact
                        getRowStyle={this.getRowStyle}
                        rowHeight={40}

                        columnDefs={this.state.coldefs}
                        modules={[ClientSideRowModelModule,
                            ClientSideRowModelModule,
                            RichSelectModule,
                            MenuModule,
                            ColumnsToolPanelModule]}
                        frameworkComponents={{
                            skillCellRenderer: skillCellRenderer,
                            columnHeaderRenderer: this.columnHeaderRenderer
                        }}
                        headerHeight={150}
                        enableRangeSelection={true}
                        rowData={this.state.gridData || []}
                        overlayLoadingTemplate={
                            '<span className="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                        }
                        defaultColDef={{
                            flex: 1,
                            resizable: false,
                        }}
                        gridOptions={this.gridOptions}
                        showToolPanel={false}
                        allowContextMenuWithControlKey={true}

                        autoGroupColumnDef={{
                            headerName: '',
                            maxWidth: 300,
                            cellRendererParams: {suppressCount: true},
                            suppressMenu: true,

                        }}
                        treeData={true}
                        animateRows={true}
                        groupDefaultExpanded={-1}
                        getDataPath={function (data) {
                            return data.skill;
                        }}
                        onGridReady={this.onGridReady}
                        onCellValueChanged={this.onCellValueChanged}
                    >
                    </AgGridReact>
                    </span>
                    <Modal title={this.state.modalTitle} visible={this.state.historyVisible}
                           onOk={() => this.closeHistory()}
                           onCancel={() => this.closeHistory()}
                           width={this.state.isFullAudit ? window.innerWidth - 100 : window.innerWidth - 100}
                           height={this.state.isFullAudit ? window.document.body.clientHeight - 100 : 600}
                           centered={true}
                           footer={[
                               <Button key="Cancel" type={'Primary'} onClick={() => this.closeHistory()}>
                                   Close
                               </Button>]}

                    >
                        {this.renderHistory()}

                    </Modal>
                    <Dialog open={this.state.showNamePrompt} onClose={() => this.setState({showNamePrompt: false})}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Enter a Lesson/Category Name</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please enter a Lesson/Category Name
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Lesson/Category Name"
                                type="text"
                                fullWidth
                                onChange={(e) => this.setState({newskillCatName: e.target.value})}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({
                                    showNamePrompt: false,
                                    rightclickParam: null,
                                    newskillCatName: ''
                                })

                            }} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.AddNodetoTemplate} color="primary">
                                Done
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.showDeletePrompt}
                        onClose={() => {
                            this.setState({showDeletePrompt: false})
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete Lesson/Category ?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this Lesson/Category.
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                YOU WILL LOOSE ALL YOUR MARKINGS MADE ON THIS Lesson !
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({showDeletePrompt: false})} color="primary">
                                No
                            </Button>
                            <Button onClick={this.deleteSkillCat} color="primary" autoFocus>
                                YES, PLEASE DELETE
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

            </div>
        );
    }

};

const mapStateToProps = state => ({
    school_id: Object.keys(state.currentUser.newUserInfo.user.role) || 0,
    groupcache: (state.currentUser.newUserInfo.groupcache) || {},
    usercache: (state.currentUser.newUserInfo.usercache) || {},
    childgroupcache: state.currentUser.newUserInfo.childgroupcache
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(RecordLesson)

